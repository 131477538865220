import { useAccount } from "wagmi";
import { type BigIntish } from "@looksrare/utils";
import { ConnectWalletModalGuard, Modal } from "@looksrare/uikit";
import { type ModalProps } from "@chakra-ui/react";
import { ChainId } from "@looksrare/config";
import { type PtbContractName } from "../../../types";
import { useCaveRoundInfo } from "../../../hooks";
import { EnterCave } from "./EnterCave";
import { useEnterCaveStore } from "./state";
import { usePreflightCheck } from "./shared";
import { CaveInfoLoader } from "./Loading";

interface EnterCaveModalProps {
  chainId: ChainId;
  caveOnChainId: BigIntish;
  contractName: PtbContractName;
  isOpen: ModalProps["isOpen"];
  onClose: ModalProps["onClose"];
  onEnter?: () => void;
}

export const EnterCaveModal = ({
  chainId,
  caveOnChainId,
  contractName,
  isOpen,
  onClose,
  onEnter,
}: EnterCaveModalProps) => {
  const { isConnected } = useAccount();
  const caveInfoQuery = useCaveRoundInfo({ caveOnChainId, contract: contractName }, { enabled: isOpen });
  const [resetModal, updatePreflightCheck] = useEnterCaveStore((state) => [
    state.resetModal,
    state.updatePreflightCheck,
  ]);

  // Check "enter" cave prerequisites
  usePreflightCheck(chainId, {
    onSuccess: (preFlightCheck) => {
      updatePreflightCheck(preFlightCheck);
    },
    enabled: !!isConnected,
  });

  const handleClose = () => {
    onClose();
    resetModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} modalContentProps={{ overflow: "hidden" }} closeOnOverlayClick={false}>
      {(() => {
        if (caveInfoQuery.isLoading) {
          return <CaveInfoLoader />;
        }
        if (!!caveInfoQuery.data) {
          return (
            <ConnectWalletModalGuard>
              <EnterCave
                chainId={chainId}
                onClose={handleClose}
                onEnter={onEnter}
                caveOnChainId={caveInfoQuery.data.cave.onChainId}
                enterAmount={caveInfoQuery.data.cave.enterAmount}
                protocolFeeBp={caveInfoQuery.data.cave.protocolFeeBp}
                currency={caveInfoQuery.data.cave.currency}
                playersPerRound={caveInfoQuery.data.cave.playersPerRound}
                contractName={contractName}
              />
            </ConnectWalletModalGuard>
          );
        }
        return null;
      })()}
    </Modal>
  );
};
