import { ReactNode } from "react";
import {
  forwardRef,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Popover as ChakraPopover,
  PopoverProps as ChakraPopoverProps,
  PopoverContentProps,
  Portal,
  PopoverArrowProps,
  PopoverBodyProps,
  PopoverArrow,
} from "@chakra-ui/react";

export interface PopoverProps extends ChakraPopoverProps {
  label: ReactNode;
  contentProps?: PopoverContentProps;
  arrowProps?: PopoverArrowProps;
  bodyProps?: PopoverBodyProps;
  renderInPortal?: boolean;
  disabled?: boolean;
  children?: ReactNode;
}

export const Popover = forwardRef<PopoverProps, "div">(
  ({ label, children, contentProps, disabled, renderInPortal = false, bodyProps, arrowProps, ...props }, ref) => {
    if (disabled) {
      return <>{children}</>;
    }

    const popoverContent = (
      <PopoverContent ref={ref} {...contentProps}>
        <PopoverArrow bg="ui-inverse" {...arrowProps} />
        <PopoverBody {...bodyProps}>{label}</PopoverBody>
      </PopoverContent>
    );

    return (
      <ChakraPopover trigger="hover" arrowSize={16} gutter={20} isLazy {...props}>
        <PopoverTrigger>{children}</PopoverTrigger>
        {renderInPortal ? <Portal>{popoverContent}</Portal> : popoverContent}
      </ChakraPopover>
    );
  }
);
