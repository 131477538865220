import { Icon, IconProps } from "../Icon";

const Eye = (props: IconProps) => (
  <Icon viewBox="0 0 49 48" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.3335 30C27.6472 30 30.3335 27.3137 30.3335 24C30.3335 20.6863 27.6472 18 24.3335 18C21.0198 18 18.3335 20.6863 18.3335 24C18.3335 27.3137 21.0198 30 24.3335 30ZM24.3333 7.5C34.3265 7.87579 43.1374 14.1626 46.7434 23.49C46.8625 23.8196 46.8625 24.1804 46.7434 24.51C43.1374 33.8374 34.3265 40.1242 24.3333 40.5C14.3402 40.1242 5.5293 33.8374 1.92335 24.51C1.8042 24.1804 1.8042 23.8196 1.92335 23.49C5.5293 14.1626 14.3402 7.87579 24.3333 7.5ZM14.5835 24C14.5835 29.3848 18.9487 33.75 24.3335 33.75C29.7149 33.7417 34.0752 29.3814 34.0835 24C34.0835 18.6152 29.7183 14.25 24.3335 14.25C18.9487 14.25 14.5835 18.6152 14.5835 24Z"
      fill="currentColor"
    />
  </Icon>
);

export default Eye;
