import { useCallback } from "react";
import { Address } from "viem";
import { usePublicClient, useWalletClient } from "wagmi";
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query";
import { type Currency, Addresses, ProtocolFeesDistributorAbi } from "@looksrare/config";
import { NoPublicClientError, useAddressesByNetwork } from "@looksrare/utils";
import { UserRakebacksInput, getUserRakebackData, getUserRakebacks } from "@/queries/rakeback";

export const supportedRakebackCurrencies = ["YOLO", "ETH"] as const;
const DEFAULT_PAGINATION = { first: 15 };

const getRakebackContractInfo = (addresses: Addresses, currency: Currency) => {
  const address = (() => {
    switch (currency) {
      case "ETH":
        return addresses.PROTOCOL_FEES_DISTRIBUTOR_ETH;
      case "YOLO":
        return addresses.PROTOCOL_FEES_DISTRIBUTOR_YOLO;
      default:
        throw new Error(`Unsupported rakeback currency: ${currency}`);
    }
  })();

  return {
    address,
    abi: ProtocolFeesDistributorAbi,
  };
};

export const useUserRakebackData = (address: Address, options?: { enabled: boolean }) => {
  return useQuery({
    queryKey: ["userRakebackData", address],
    queryFn: async () => getUserRakebackData(address),
    ...options,
  });
};

export const useUserRakebacks = (input: UserRakebacksInput, options?: { enabled: boolean }) => {
  return useInfiniteQuery({
    queryKey: ["userRakebacks", input],
    queryFn: async ({ pageParam }) => await getUserRakebacks({ ...input, pagination: pageParam }),
    getNextPageParam: (lastPage) => {
      const pagination = input.pagination ?? DEFAULT_PAGINATION;

      if (!lastPage || lastPage.rakebacks.history.length < pagination.first) {
        return undefined;
      }

      return {
        ...pagination,
        cursor: lastPage.lastRoundId,
      };
    },
    initialPageParam: DEFAULT_PAGINATION,
    ...options,
  });
};

export const useInvalidateUserRakebacks = (input?: UserRakebacksInput) => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["userRakebacks", input] });
  }, [input, queryClient]);
};

export const useClaimRakeback = (currency: Currency) => {
  const addresses = useAddressesByNetwork();

  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const contractInfo = getRakebackContractInfo(addresses, currency);

  return useCallback(
    async (amount: bigint, proof: Address[]) => {
      if (!publicClient) {
        throw new NoPublicClientError();
      }
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      const [account] = await walletClient.getAddresses();

      const { request } = await publicClient.simulateContract({
        ...contractInfo,
        functionName: "claim",
        args: [amount, proof],
        account,
      });

      return walletClient.writeContract(request);
    },
    [contractInfo, publicClient, walletClient]
  );
};

export const usePausedContractCurrencySymbols = () => {
  const addresses = useAddressesByNetwork();

  const publicClient = usePublicClient();

  return useQuery({
    queryKey: ["isRakebackPaused"],
    queryFn: async () => {
      if (!publicClient) {
        throw new NoPublicClientError();
      }

      const pausedSymbols = await Promise.all(
        supportedRakebackCurrencies.map(async (currencySymbol) => {
          const isPaused = await publicClient.readContract({
            ...getRakebackContractInfo(addresses, currencySymbol),
            functionName: "paused",
          });

          return isPaused ? currencySymbol : undefined;
        })
      );

      return pausedSymbols.filter(Boolean) as Currency[];
    },
  });
};

export const getIsRakebackFullyPaused = (pausedContractCurrencySymbols: Currency[] | undefined) => {
  return pausedContractCurrencySymbols?.length === supportedRakebackCurrencies.length;
};
