import { useState, useCallback, useEffect } from "react";
import { useAccount, useWalletClient } from "wagmi";
import noop from "lodash/noop";
import { JwtScope } from "../types";
import { isAuthorized } from "../auth";
import { useSignAndLoginIfJwtIsInvalid } from "./auth";
import { useOnAccountChange } from "./useOnAccountChange";
import { usePreviousValue } from "./usePreviousValue";

export enum SignInStatus {
  INACTIVE, // Initial state, handler has not been called
  SIGNING, // In the process of signing in
  HAS_VALID_TOKEN, // Has a valid JWT
  SIGN_FAILED, // Attempted sign in but failed e.g. user declined signature
  ERROR, // An error was thrown
}

interface SignInHandlerParams {
  onAuthSuccess?: (jwt?: string, args?: any) => void;
  onAuthFailure?: (args?: any) => void;
  options?: {
    jwtScope?: JwtScope;
  };
}

export const useSignInHandler = (params?: SignInHandlerParams) => {
  const { onAuthSuccess, onAuthFailure = noop, options } = params || {};
  const { address } = useAccount();
  const { data: walletClient } = useWalletClient();
  const signAndLoginIfJwtIsInvalid = useSignAndLoginIfJwtIsInvalid();
  const [txStatus, setSignInStatus] = useState<SignInStatus>(() => {
    return isAuthorized(address, options?.jwtScope) ? SignInStatus.HAS_VALID_TOKEN : SignInStatus.INACTIVE;
  });

  const signInHandler = useCallback(
    async (args?: any) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      const [signer] = await walletClient.getAddresses();
      if (address && signer) {
        try {
          setSignInStatus(SignInStatus.SIGNING);
          const jwt = await signAndLoginIfJwtIsInvalid(walletClient, address);

          if (jwt) {
            onAuthSuccess?.(jwt, args);
            setSignInStatus(SignInStatus.HAS_VALID_TOKEN);
          } else {
            setSignInStatus(SignInStatus.SIGN_FAILED);
            onAuthFailure(args);
          }
        } catch (error) {
          console.error("There was an error signing in:", error);
          setSignInStatus(SignInStatus.ERROR);
        }
      } else {
        setSignInStatus(SignInStatus.ERROR);
      }
    },
    [walletClient, address, onAuthSuccess, onAuthFailure, signAndLoginIfJwtIsInvalid]
  );

  return {
    signInHandler,
    txStatus,
    isInactive: txStatus === SignInStatus.INACTIVE,
    isSigning: txStatus === SignInStatus.SIGNING,
    isUnauthorized: txStatus === SignInStatus.SIGN_FAILED,
    hasValidToken: txStatus === SignInStatus.HAS_VALID_TOKEN,
    isError: txStatus === SignInStatus.ERROR,
  };
};

interface SignatureRequiredParams extends SignInHandlerParams {
  onWalletPrompt?: () => void;
}
/**
 * Manage states related to isConnected and isAuthorized. Views and functions that require a signature can use this hook to
 * display the appropriate UI based on these states.
 * @note Prefer this hook over `useSignInHandler` to handle reactivity of the connected wallet changing
 */
export const useSignatureRequired = (params?: SignatureRequiredParams) => {
  const { onAuthSuccess = noop, onAuthFailure = noop, onWalletPrompt = noop, options } = params || {};
  const { data: walletClient } = useWalletClient();
  const { address, isConnected } = useAccount();
  const jwtScope = options?.jwtScope;

  const isConnectedWalletAuthorized = isAuthorized(address, jwtScope);
  const [signInSuccess, setSignInSuccess] = useState(isConnectedWalletAuthorized);

  const signInHandlerReturn = useSignInHandler({
    onAuthSuccess: () => {
      onAuthSuccess();
      // update state variable to trigger re-render
      setSignInSuccess(true);
    },
    onAuthFailure,
    options,
  });
  const { signInHandler } = signInHandlerReturn;

  // When the connected wallet changes, update the state variable
  useOnAccountChange(() => {
    setSignInSuccess(isAuthorized(address, jwtScope));
  });

  /**
   * As the signature becomes required, fetch the signer and sign in
   */
  const isSignatureRequired = !!address && !signInSuccess;
  const previousIsSignatureRequired = usePreviousValue(isSignatureRequired);
  useEffect(() => {
    if (
      !!walletClient?.account.address && // ensure wallet client is ready
      isSignatureRequired &&
      !previousIsSignatureRequired
    ) {
      const init = async () => {
        // @TODO Check this
        signInHandler();
      };
      init();
    }
  }, [address, isSignatureRequired, previousIsSignatureRequired, signInHandler, walletClient?.account]);

  /**
   * As the tx status changes from anything to "isSigning", call onWalletPrompt
   */
  const previousIsSigning = usePreviousValue(signInHandlerReturn.isSigning);
  useEffect(() => {
    if (signInHandlerReturn.isSigning && !previousIsSigning) {
      onWalletPrompt();
    }
  }, [signInHandlerReturn.isSigning, previousIsSigning, onWalletPrompt]);

  return {
    isConnected,
    isAuthorized: signInSuccess,
    ...signInHandlerReturn,
  };
};
