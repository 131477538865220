import { Icon, IconProps } from "../Icon";

const PriceRangeFill = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16ZM11 11C11 9.89543 11.8954 9 13 9H15V6H17V9H21V11H13V15H19C20.1046 15 21 15.8954 21 17V21C21 22.1046 20.1046 23 19 23H17V26H15V23H11V21H19V17H13C11.8954 17 11 16.1046 11 15V11Z"
      fill="currentColor"
    />
  </Icon>
);

export default PriceRangeFill;
