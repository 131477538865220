import React, { useEffect, useState, useRef } from "react";
import { Box, HStack, Stack } from "@chakra-ui/react";
import { format } from "date-fns";
import { Chart as HighchartsChart, TooltipFormatterCallbackFunction, TooltipFormatterContextObject } from "highcharts";
import ReactDOM from "react-dom";
import { formatNumberToLocale, formatCompactNumber } from "@looksrare/utils";
import { Text } from "../Text";
import { EthHalfIcon } from "../Icons";
import { HighchartsTooltipFormatterFunction } from "./HighCharts";

const generateTooltipId = (chartId: number) => `highcharts-custom-tooltip-${chartId}`;

interface Props {
  chart: HighchartsChart | null;
  tooltipFormatter: HighchartsTooltipFormatterFunction;
  withContainer: boolean;
}

export const HighchartsTooltipHeader = ({ timestamp }: { timestamp: number }) => {
  if (!timestamp) {
    return null;
  }

  const date = format(timestamp, "MMM dd, yyyy");
  const time = format(timestamp, "H:mm");

  return (
    <HStack mb={2} spacing={1}>
      <Text textStyle="helper" color="text-03" bold>
        {date}
      </Text>
      <Text textStyle="helper" color="text-03">
        {time}
      </Text>
    </HStack>
  );
};

interface HighchartsTooltipItemProps {
  label: string | JSX.Element;
  value: string | JSX.Element;
  inline?: boolean;
}

export const HighchartsTooltipLabel = ({ label }: { label: string }) => {
  return (
    <Text textStyle="helper" color="text-03">
      {label}
    </Text>
  );
};

export const HighchartsTooltipValue = ({ value }: { value: string }) => {
  return (
    <Text textStyle="detail" color="text-02" bold>
      {value}
    </Text>
  );
};

export const HighchartsTooltipCaption = ({ value }: { value: string }) => {
  return (
    <Text textStyle="caption" color="text-03">
      {value}
    </Text>
  );
};

export const HighchartsTooltipItem = ({ label, value, inline = false }: HighchartsTooltipItemProps) => {
  const tooltipContent = (
    <>
      {typeof label === "string" ? <HighchartsTooltipLabel label={label} /> : label}
      {typeof value === "string" ? <HighchartsTooltipValue value={value} /> : value}
    </>
  );

  const mb = inline ? 1 : 2;

  if (inline) {
    return (
      <HStack spacing={1} mb={mb}>
        {tooltipContent}
      </HStack>
    );
  }

  return (
    <Stack spacing={1} mb={mb}>
      {tooltipContent}
    </Stack>
  );
};

interface HighchartsTooltipEthValueItemProps {
  label: string | JSX.Element;
  ethValue?: number;
  usdValue?: number;
  inline?: boolean;
}

export const HighchartsTooltipEthValueItem = ({
  label,
  ethValue,
  usdValue,
  inline,
}: HighchartsTooltipEthValueItemProps) => {
  const formattedEthValue = ethValue ? formatNumberToLocale(ethValue, 0, 2) : "-";
  const formattedUsdValue = usdValue ? `($${formatCompactNumber(usdValue)})` : "";

  return (
    <HighchartsTooltipItem
      label={label}
      value={
        <HStack spacing={1}>
          <EthHalfIcon boxSize={4} />
          <HighchartsTooltipValue value={formattedEthValue} />
          <HighchartsTooltipCaption value={formattedUsdValue} />
        </HStack>
      }
      inline={inline}
    />
  );
};

export const HighchartsTooltipSeparator = ({ ...props }) => {
  return <Box borderBottom="1px solid" borderBottomColor="border-01" mb={2} {...props} />;
};

export const HighchartsTooltip = ({ chart, tooltipFormatter, withContainer = true }: Props) => {
  const isInit = useRef(false);
  const [context, setContext] = useState<TooltipFormatterContextObject | null>(null);

  useEffect(() => {
    if (chart) {
      const formatter: TooltipFormatterCallbackFunction = function (this: TooltipFormatterContextObject) {
        // Ensures that tooltip DOM container is rendered before React portal is created.
        if (!isInit.current && !!this.point) {
          isInit.current = true;

          chart.tooltip.refresh.apply(chart.tooltip, [this.point]);
          chart.tooltip.hide(0);
        }

        setContext(this);

        return `<div class="${generateTooltipId(chart.index)}" style="width: fit-content;" />`;
      };

      chart.update({
        tooltip: {
          formatter,
          useHTML: true,
        },
      });
    }
  }, [chart]);

  // There's a possibility that Highcharts creates duplicate elements (with same chart.index)
  // Reference the last node that was created
  const nodes = chart ? document.getElementsByClassName(generateTooltipId(chart.index)) : undefined;
  const node = nodes && nodes[nodes.length - 1];

  if (!node || !context) {
    return null;
  }

  const tooltipContent = tooltipFormatter(context);
  if (!tooltipContent) {
    return null;
  }

  const tooltip = withContainer ? (
    <Box bgColor="ui-01" boxShadow="lg" border="1px solid" borderColor="border-01" borderRadius="mini" p={3}>
      {tooltipContent}
    </Box>
  ) : (
    tooltipContent
  );

  return <>{ReactDOM.createPortal(tooltip, node)}</>;
};
