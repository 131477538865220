import { useEffect, useState } from "react";
import { DataLayerAllowedValues, SupportedDataLayerVariables } from "./types";

export const useSendAnalyticsEvent = (): ((values: DataLayerAllowedValues) => void) => {
  const preprocessData = (newValues: DataLayerAllowedValues): Record<string, any> => {
    const processed: Record<string, any> = { ...newValues };
    for (const [key, value] of Object.entries(processed)) {
      if (typeof value === "string" && value.startsWith("0x")) {
        // Remove 0x from addresses or Google Analytics will interpret and transform as a decimal number e.g. 1.34e18
        // doesn't apply to contract addresses because of their length
        processed[key] = value.replace("0x", "");
      }
    }
    // GA doesn't seem to properly support nested objects. tokensTradeData would otherwise become "[object Object]"
    // when reading it in bigQuery
    const fieldsToStringify: (keyof SupportedDataLayerVariables)[] = [
      "tokensTradeDataLr",
      "tokensTradeDataOs",
      "tokensTradeDataBlur",
      "tokensTradeDataLrSeaport",
    ];

    fieldsToStringify.forEach((field) => {
      if (field in processed) {
        try {
          processed[field] = JSON.stringify(processed[field]);
        } catch (error) {
          console.error(`Failed stringifying dataLayer.${field}`, error);
        }
      }
    });

    return processed;
  };

  const preprocessAndSendEvent = (data: DataLayerAllowedValues) => {
    if (typeof window.gtag === "function") {
      const processedData = preprocessData(data);
      const { event, ...rest } = processedData;
      window.gtag("event", event, rest);
    } else {
      console.error("no gtag configured");
    }
  };

  return preprocessAndSendEvent;
};
