import { Flex, FlexProps, HStack } from "@chakra-ui/react";
import { getExplorerLink, useCurrentChainId } from "@looksrare/utils";
import { IconProps } from "../Icons";
import { ExternalLink } from "../Link";
import { Text, TextProps } from "../Text";
import { TransactionStepStatusIcon } from "./StatusIcons";
import { type TransactionStatus } from "./types";

interface Props extends FlexProps {
  status: TransactionStatus;
  text: string;
  textProps?: TextProps;
  icon?: React.FC<IconProps>;
}

// If transaction hash is defined, chainId must be defined as well
type WithTransactionHash = { transactionHash: string };
type WithoutTransactionHash = { transactionHash?: never };

export type TransactionStepRowProps = Props & (WithoutTransactionHash | WithTransactionHash);

export const TransactionStepRow = ({
  status,
  icon: Icon,
  text,
  textProps,
  transactionHash,
  ...props
}: TransactionStepRowProps) => {
  const currentChainId = useCurrentChainId();
  const [statusIconColor, statusTextColor] = (() => {
    switch (status) {
      case "pending":
        return ["link-01", "text-01"];
      case "error":
        return ["text-error", "text-01"];
      case "skipped":
      case "done":
        return ["text-disabled", "text-03"];
      default:
        return ["text-03", "text-03"];
    }
  })();

  return (
    <Flex justifyContent="space-between" width="100%" alignItems="center" {...props}>
      <HStack align="center">
        {Icon && <Icon boxSize={4} />}
        <Text noOfLines={1} color={statusTextColor} textStyle="detail" {...textProps}>
          {text}
        </Text>
        {!!transactionHash && (
          <ExternalLink
            href={getExplorerLink(transactionHash, "transaction", currentChainId)}
            textStyle="helper"
            color={statusTextColor}
          />
        )}
      </HStack>
      <TransactionStepStatusIcon ml={2} status={status} color={statusIconColor} boxSize={4} />
    </Flex>
  );
};
