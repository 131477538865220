import { Button, Flex, FlexProps, HStack, Input, InputProps, useNumberInput } from "@chakra-ui/react";
import { MinusIcon, PlusIcon } from "../../Icons";
import { ButtonProps } from "../Button";

interface IncrementButtonProps extends Omit<FlexProps, "onChange"> {
  value: number | string;
  onChange: (valueAsString: string, valueAsNumber: number) => void;
  step?: number;
  min?: number;
  max?: number;
  precision?: number;
  inputProps?: InputProps;
  buttonProps?: ButtonProps;
  showMaxButton?: boolean;
}

export const IncrementButton = ({
  value,
  step = 1,
  min = 1,
  max = 100,
  precision = 0,
  onChange,
  buttonProps,
  inputProps,
  showMaxButton = false,
  ...props
}: IncrementButtonProps) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    value,
    step,
    min,
    max,
    precision,
    onChange,
    isValidCharacter: (_value: string) => /^[0-9]$/.test(_value),
  });
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      borderWidth="1px"
      borderColor="border-02"
      borderRadius="button"
      overflow="hidden"
      {...props}
    >
      <Button
        colorScheme="secondary"
        variant="ghost"
        borderRightRadius={0}
        {...getDecrementButtonProps()}
        {...buttonProps}
      >
        <MinusIcon />
      </Button>
      <Input maxWidth="65px" height="48px" textAlign="center" {...getInputProps()} bg="transparent" {...inputProps} />
      <HStack spacing={0} minWidth={showMaxButton ? "6rem" : "none"}>
        <Button
          colorScheme="secondary"
          variant="ghost"
          borderLeftRadius={0}
          borderRightRadius={showMaxButton ? 0 : undefined}
          {...getIncrementButtonProps()}
          {...buttonProps}
        >
          <PlusIcon />
        </Button>
        {showMaxButton && (
          <Button
            colorScheme="secondary"
            variant="ghost"
            pr={6}
            borderLeftRadius={0}
            onClick={() => onChange(max.toString(), max)}
            isDisabled={value === max}
          >
            Max
          </Button>
        )}
      </HStack>
    </Flex>
  );
};
