import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const TokenYolo = (props: IconProps) => {
  const [id] = useState(uniqueId());

  return (
    <Icon viewBox="0 0 96 96" {...props}>
      <g clipPath={`url(#${id})`}>
        <circle cx="48" cy="48" r="48" fill="#CCFD07" />
        <path
          d="M55.5099 26.0272H72.3892L56.1456 75.2355H39.2663L45.4106 56.7208H33.122L23.5876 26.0272H40.255L47.3175 50.8777L55.5099 26.0272Z"
          fill="#1F2A37"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default TokenYolo;
