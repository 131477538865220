import { zeroAddress, type Address } from "viem";
import { useAccount, useBalance } from "wagmi";
import times from "lodash/times";
import { Box, Flex, HStack, ModalBody, type ModalProps, Skeleton } from "@chakra-ui/react";
import { MoneyBagIcon, Text, TicketRedeemablesIcon } from "@looksrare/uikit";
import { ChainId } from "@looksrare/config";
import {
  type BigIntish,
  formatToSignificant,
  multiplyWeiByNumber,
  useCoinPrices,
  formatUsd,
  fromDecimals,
  useEthBalance,
  isAddressEqual,
} from "@looksrare/utils";
import { CurrencyValueDisplay } from "../../CurrencyValueDisplay";
import { useActiveRoundOnChainId, useCurrencyLabel } from "../../../hooks";
import { EnterCaveHeader, Row, TextDetail } from "./shared";
import { useEnterCaveStore } from "./state";
import { ApprovalStep } from "./ApprovalStep";
import { SendStep } from "./SendStep";
import { LoginStep } from "./LoginStep";

interface TransactionViewProps {
  chainId: ChainId;
  currency: Address;
  enterAmount: BigIntish;
  winningsToClaim?: bigint;
  onRetryLogin: () => void;
  onRetryTmStep: () => void;
  onRetryErc20Approval: () => void;
  onRetryTransaction: () => void;
  onClose: ModalProps["onClose"];
}

export const TransactionView = ({
  chainId,
  currency,
  enterAmount,
  winningsToClaim = 0n,
  onRetryLogin,
  onRetryTmStep,
  onRetryErc20Approval,
  onRetryTransaction,
  onClose,
}: TransactionViewProps) => {
  const { address } = useAccount();
  const [numberOfRounds] = useEnterCaveStore((state) => [state.numberOfRounds, state.steps]);

  const isEthCurrency = isAddressEqual(zeroAddress, currency);
  const { data: balance } = useBalance({ address, token: currency, query: { enabled: !isEthCurrency } });
  const { data: ethBalanceInWei } = useEthBalance(address!, { enabled: !!address && isEthCurrency }, chainId);

  const coinPriceQuery = useCoinPrices();
  const currencyLabel = useCurrencyLabel(currency);
  const activeRoundQuery = useActiveRoundOnChainId();

  const tokenBalance = (() => {
    if (isEthCurrency) {
      return ethBalanceInWei || "0";
    }
    return balance ? balance.value : "0";
  })();

  const totalValue = multiplyWeiByNumber(enterAmount, numberOfRounds);
  const totalValueUsdDisplay = coinPriceQuery.data
    ? formatUsd(parseFloat(fromDecimals(totalValue)) * coinPriceQuery.data[currencyLabel.toLowerCase()].price)
    : "-";

  const roundId = Number(activeRoundQuery.data || "0");
  const totalRoundList = times(numberOfRounds).map((roundNum) => roundId + roundNum);

  return (
    <>
      <EnterCaveHeader onClose={onClose}>Checkout</EnterCaveHeader>
      <ModalBody bg="ui-bg" p={6}>
        <HStack spacing={4} mb={4}>
          <TicketRedeemablesIcon transform="rotate(90deg)" boxSize={12} />
          <Box flex={1}>
            <Text bold textStyle="display-body">
              {numberOfRounds === 1 ? `${numberOfRounds} Round` : `${numberOfRounds} Rounds`}
            </Text>
            <HStack>
              <Text bold color="text-03" textStyle="detail">
                Round:
              </Text>
              {activeRoundQuery.isLoading ? (
                <Skeleton height={4} width="60px" />
              ) : (
                <Text color="text-03" textStyle="detail">
                  {totalRoundList.join(", ")}
                </Text>
              )}
            </HStack>
          </Box>
          <Flex flexDirection="column" alignItems="end">
            <CurrencyValueDisplay
              currency={currency}
              total={formatToSignificant(totalValue, 6)}
              textProps={{ textStyle: "display-body", bold: true }}
            />
            <TextDetail>{totalValueUsdDisplay}</TextDetail>
          </Flex>
        </HStack>
        {winningsToClaim > 0n && (
          <Flex
            gap={2}
            alignItems="center"
            px={4}
            py={3}
            borderRadius="button"
            border="1px solid"
            borderColor="border-01"
          >
            <MoneyBagIcon color="text-01" />
            <Text textStyle="detail">
              {`${formatToSignificant(
                winningsToClaim,
                6
              )} ${currencyLabel} of unclaimed winnings will also be sent to your wallet`}
            </Text>
          </Flex>
        )}
      </ModalBody>
      <Box p={6} bg="ui-01">
        <Row py={2} mb={6}>
          <TextDetail>{`Your ${currencyLabel} Balance`}</TextDetail>
          <CurrencyValueDisplay
            currency={currency}
            total={formatToSignificant(tokenBalance, 6)}
            textProps={{ color: "text-03", textStyle: "detail" }}
            iconProps={{ boxSize: 4 }}
          />
        </Row>
        <LoginStep chainId={chainId} onRetry={onRetryLogin} />
        <ApprovalStep
          chainId={chainId}
          currency={currency}
          onRetryTmStep={onRetryTmStep}
          onRetryErc20Step={onRetryErc20Approval}
        />
        <SendStep chainId={chainId} onRetry={onRetryTransaction} />
      </Box>
    </>
  );
};
