import { lineHeights } from "./typography";

export const global = {
  body: {
    background: "ui-bg",
    color: "text-01",
    fontFamily: "'Space Grotesk', sans-serif",
    fontFeatureSettings: "'calt' 0",
    a: {
      lineHeight: lineHeights.md,
    },
  },
  // with focus-visible package we override chakra's focus effects on clicked elements. focus states should only appear from keyboard navigation
  ".js-focus-visible :focus:not(.focus-visible)": {
    shadow: "none",
  },
};
