import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { StackProps, VStack } from "@chakra-ui/react";
import { Hash } from "viem";
import { useHandleModalStep, useWalletClientIsReady } from "@looksrare/utils";
import { TransactionStatus, TransactionStepRow } from "../TransactionStep";
import { Text } from "../Text";
import { StepErrorCta } from "../BulkTransactionStep";

export type TransactionSetter = (hash?: Hash) => void;

export interface AutoTransactionStepProps extends StackProps {
  isStepActive: boolean;
  /**
   * A hook that returns our useHandleModalStep. Receives the setTransaction function
   * @param setTransaction - A function to be called with the transaction hash so the component can update its state
   */
  useHandleTransaction: (setTransaction: TransactionSetter) => ReturnType<typeof useHandleModalStep>;
  ctaText: string;
  onSkip?: () => void;
}

/**
 * A component that handles triggering a transaction and displaying its status
 */
export const AutoTransactionStepRow = ({
  isStepActive,
  useHandleTransaction,
  ctaText,
  onSkip,
  ...props
}: AutoTransactionStepProps) => {
  const { t } = useTranslation();
  const { isSignerReady } = useWalletClientIsReady();
  const [transaction, setTransaction] = useState<string>();

  const { handleSubmit, isRejected, isIdle, isAccepted } = useHandleTransaction(setTransaction);

  const rowStatus = ((): TransactionStatus => {
    if (isRejected) {
      return "error";
    }
    if (isIdle) {
      return "wait";
    }
    if (isAccepted) {
      return "done";
    }
    return "pending";
  })();

  useEffect(() => {
    if (isStepActive && isSignerReady) {
      handleSubmit({ callOnlyOnce: true });
    }
  }, [isStepActive, handleSubmit, isSignerReady]);

  return (
    <VStack spacing={4} alignItems="flex-start" width="100%" {...props}>
      {isStepActive && (
        <Text textStyle="detail" bold color={isRejected ? "text-error" : "text-01"}>
          {isRejected ? t("You declined the transaction") : t("Waiting for you to confirm in wallet")}
        </Text>
      )}
      <TransactionStepRow transactionHash={transaction} status={rowStatus} text={ctaText} />
      {isRejected && isStepActive && <StepErrorCta onRetry={handleSubmit} onSkip={onSkip} />}
    </VStack>
  );
};
