import { HStack, IconButton } from "@chakra-ui/react";
import { gradients } from "@looksrare/chakra-theme";
import { ArrowLeftIcon, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { EntryPosition } from "../../../../types";

interface RoundEntryEnteringHeaderProps {
  entryPosition: EntryPosition;
  onBack: () => void;
}

export const RoundEntryEnteringHeader = ({ entryPosition, onBack }: RoundEntryEnteringHeaderProps) => {
  const { t } = useTranslation();

  return (
    <HStack spacing={4} p={4} bgGradient={gradients["ui-gradient-v-01"]}>
      <IconButton
        onClick={onBack}
        icon={<ArrowLeftIcon boxSize={5} />}
        aria-label={t("Go Back")}
        variant="ghost"
        colorScheme="secondary"
        size="xs"
      />

      <Text textStyle="heading-04" bold>
        {t("mod::Entering {{position}}", { position: entryPosition.toUpperCase() })}
      </Text>
    </HStack>
  );
};
