import { useMemo } from "react";
import { HStack, ResponsiveObject, StackProps, StyleProps } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ArrowUpRightIcon, ErrorIcon, HamburgerIcon, Text } from "@looksrare/uikit";
import { getPriceChangeColors } from "../../utils/getPriceChangeColor";
import { getFormattedPriceChange } from "../../utils/getFormattedPriceChange";

export type PriceChangeIndicatorSize = "xxs" | "md" | "lg";

const getSizeStyles = (_size: PriceChangeIndicatorSize): StyleProps => {
  switch (_size) {
    case "xxs":
      return {
        px: 1.5,
        py: 0.5,
        boxSize: 3,
        textStyle: "helper",
        borderRadius: "circular",
      };
    case "md":
      return {
        px: 2,
        py: 1,
        boxSize: 6,
        textStyle: "display-body",
        borderRadius: "button",
      };
    case "lg":
      return {
        px: 4,
        py: 2,
        boxSize: 6,
        textStyle: "display-body",
        borderRadius: "button",
      };
  }
};

const getBreakpointStyles = (
  size: PriceChangeIndicatorSize | ResponsiveObject<PriceChangeIndicatorSize>,
  prop: keyof StyleProps
) => {
  if (typeof size === "string") {
    return getSizeStyles(size)[prop];
  }

  return Object.entries(size).reduce((acc, [breakpoint, breakpointSize]) => {
    if (breakpointSize) {
      return { ...acc, [breakpoint]: getSizeStyles(breakpointSize)[prop] };
    }

    return acc;
  }, {});
};

interface PriceChangeIndicatorProps extends StackProps {
  startPrice: number;
  currentPrice: number;
  isCancelled?: boolean;
  showAsUsd?: boolean;
  size?: PriceChangeIndicatorSize | ResponsiveObject<PriceChangeIndicatorSize>;
}

export const PriceChangeIndicator = ({
  startPrice,
  currentPrice,
  isCancelled,
  showAsUsd,
  size = "xxs",
  ...props
}: PriceChangeIndicatorProps) => {
  const { t } = useTranslation();
  const priceChange = (currentPrice ?? 0) - (startPrice ?? 0);

  const { color, backgroundColor } = (() => {
    if (isCancelled) {
      return { color: "text-03", backgroundColor: "ui-02" };
    }

    return getPriceChangeColors(priceChange);
  })();

  const sizes = useMemo(() => {
    return {
      px: getBreakpointStyles(size, "px"),
      py: getBreakpointStyles(size, "py"),
      boxSize: getBreakpointStyles(size, "boxSize"),
      textStyle: getBreakpointStyles(size, "textStyle"),
      borderRadius: getBreakpointStyles(size, "borderRadius"),
    };
  }, [size]);

  return (
    <HStack
      px={sizes.px}
      py={sizes.py}
      justifyContent="space-between"
      minWidth="108px"
      width="fit-content"
      backgroundColor={backgroundColor}
      borderRadius={sizes.borderRadius}
      transition="background-color 200ms"
      overflow="hidden"
      {...props}
    >
      {isCancelled ? (
        <ErrorIcon boxSize={sizes.boxSize} color={color} />
      ) : priceChange === 0 ? (
        <HamburgerIcon boxSize={sizes.boxSize} color={color} />
      ) : (
        <ArrowUpRightIcon
          boxSize={sizes.boxSize}
          color={color}
          transition="transform 200ms"
          transform={priceChange < 0 ? "rotate(90deg)" : "rotate(0deg)"}
        />
      )}

      <Text
        textStyle={sizes.textStyle}
        color={color}
        transition="color 200ms"
        sx={{ fontFeatureSettings: '"tnum" on, "lnum" on' }}
        bold
      >
        {(() => {
          if (isCancelled) {
            return t("Canceled");
          } else {
            return getFormattedPriceChange(priceChange, { showAsUsd });
          }
        })()}
      </Text>
    </HStack>
  );
};
