import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const TokenBoost = (props: IconProps) => {
  const [id] = useState(uniqueId());
  return (
    <Icon viewBox="0 0 96 96" {...props}>
      <g clipPath={`url(#${id})`}>
        <circle cx="48" cy="48" r="48" fill="#33DEBF" />
        <path
          d="M35.6757 73.3889L35.7046 27.9258L61.1362 21.1115C71.4264 18.3542 76.7818 20.7006 77.2813 28.4447C77.6875 36.1349 74.3933 40.0112 60.9425 43.6153L60.9084 43.782C73.5507 40.3945 77.1959 43.1204 77.69 50.5507C78.0857 57.6136 74.9255 62.8719 62.2833 66.2594L35.6757 73.3889ZM58.154 35.8544L51.7593 37.5678L51.8178 45.43L58.0654 43.756C60.491 43.1061 61.9648 41.8446 61.8386 38.7273C61.6729 35.4629 60.359 35.2635 58.154 35.8544ZM57.9923 48.1872L51.8182 49.8415L51.8031 57.7235L57.8302 56.1085C60.4028 55.4192 61.9751 54.5252 61.8239 51.0207C61.6923 47.5897 60.2708 47.5767 57.9923 48.1872Z"
          fill="#0D121C"
        />
        <path d="M33.836 73.8587L33.8658 28.4187L28.8198 29.7708L28.7901 75.2107L33.836 73.8587Z" fill="#0D121C" />
        <path d="M26.9887 75.6933L27.0184 30.2533L23.1676 31.2851L23.1378 76.7251L26.9887 75.6933Z" fill="#0D121C" />
        <path d="M21.3742 77.1978L21.4039 31.7578L19.0297 32.394L19 77.834L21.3742 77.1978Z" fill="#0D121C" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default TokenBoost;
