import { useRouter } from "next/router";
import { getStorageKey, isAuthorized, useLocalStorage, useOnAccountChange } from "@looksrare/utils";
import { useEffect, useMemo } from "react";
import { useAccount } from "wagmi";
import { useAddAffiliateMutation } from "@/queries/referral";

/**
 * If a referral code is present in the URL, sends a mutation to link the user to its referral, otherwise it stores the
 * referral code in local storage and links it to the user's account once they sign in.
 */
export const useHandleReferral = () => {
  const { address } = useAccount();
  const hasValidToken = useMemo(() => isAuthorized(address), [address]);
  const router = useRouter();
  // Whether the connected account was already referred
  const { value: isAlreadyReferred, setValue: setIsAlreadyReferred } = useLocalStorage<boolean | null>(
    getStorageKey("isAlreadyReferred"),
    true
  );

  // Persist the referral from URL, to assign it to the user once he signs in. Not scoped to the account
  const { value: pendingReferral, setValue: setPendingReferral } = useLocalStorage<string | null>(
    getStorageKey("referralCode")
  );

  const urlReferralCode = router.query?.code?.length === 5 ? (router.query?.code as string) : null;
  // Prioritize the url, then the local storage
  const referralCode = urlReferralCode || pendingReferral;

  const {
    mutate: addAffiliate,
    isPending,
    isError,
    reset,
  } = useAddAffiliateMutation({
    onSuccess: () => {
      setPendingReferral(null);
      setIsAlreadyReferred(true);
    },
    onError: () => {
      setPendingReferral(null);
    },
  });

  useOnAccountChange(reset);

  const shouldSkip = isError || isPending || isAlreadyReferred || !referralCode;
  useEffect(() => {
    if (!shouldSkip) {
      if (hasValidToken) {
        addAffiliate(referralCode);
      } else {
        // Cannot be set now, persist it for later.
        if (!!urlReferralCode && urlReferralCode !== pendingReferral) {
          setPendingReferral(urlReferralCode);
        }
      }
    }
  }, [
    hasValidToken,
    referralCode,
    setIsAlreadyReferred,
    setPendingReferral,
    shouldSkip,
    pendingReferral,
    urlReferralCode,
    addAffiliate,
  ]);
};
