import { Icon, IconProps } from "@looksrare/uikit";

const SeatAvailable = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M18.375 14.25C16.098 14.25 14.25 16.098 14.25 18.375C14.25 20.652 16.098 22.5 18.375 22.5C20.652 22.5 22.5 20.652 22.5 18.375C22.5 16.098 20.652 14.25 18.375 14.25ZM20.4375 19.2H19.2V20.4375C19.2 20.8929 18.8312 21.2625 18.375 21.2625C17.9188 21.2625 17.55 20.8929 17.55 20.4375V19.2H16.3125C15.8563 19.2 15.4875 18.8304 15.4875 18.375C15.4875 17.9196 15.8563 17.55 16.3125 17.55H17.55V16.3125C17.55 15.8571 17.9188 15.4875 18.375 15.4875C18.8312 15.4875 19.2 15.8571 19.2 16.3125V17.55H20.4375C20.8937 17.55 21.2625 17.9196 21.2625 18.375C21.2625 18.8304 20.8937 19.2 20.4375 19.2Z"
      fill="#0CE466"
    />
    <path
      d="M10.875 11.25C13.5674 11.25 15.75 9.06739 15.75 6.375C15.75 3.68261 13.5674 1.5 10.875 1.5C8.18261 1.5 6 3.68261 6 6.375C6 9.06739 8.18261 11.25 10.875 11.25Z"
      fill="#697077"
    />
    <path
      d="M18 13.1196C17.2848 12.0894 16.3909 11.2009 15.3657 10.5017C14.2186 11.8327 12.5526 12.6762 10.6937 12.6762C8.83305 12.6762 7.1671 11.8318 6.01914 10.5C3.2988 12.353 1.5 15.5351 1.5 19.1572C1.5 20.5893 2.62206 21.75 4.00646 21.75H14.4818C13.6856 20.8322 13.1968 19.6231 13.1968 18.293C13.1968 15.5023 15.3315 13.2336 18 13.1196Z"
      fill="#697077"
    />
  </Icon>
);

export default SeatAvailable;
