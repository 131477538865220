import { MoDUnclaimedEntries } from "../../../network/graphql/hooks";

/**
 * We calculate the total amount of rounds by adding up
 * the calculated total amount of rounds of each contract.
 */
export const getTotalUnclaimedRounds = (unclaimedEntries: MoDUnclaimedEntries) => {
  return Object.values(unclaimedEntries).reduce((totalSum, contractUnclaimedEntries) => {
    return contractUnclaimedEntries.reduce(
      (contractSum, entry) => contractSum + entry.roundOnChainIds.length,
      totalSum
    );
  }, 0);
};
