import { Icon, IconProps } from "../Icon";

const YoloGamesGrayscale = (props: IconProps) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <circle cx="20" cy="20" r="20" fill="white" fillOpacity="0.08" />
    <path
      d="M23.1343 10.8477H30.1691L23.3992 31.3561H16.3645L18.9252 23.6398H13.8037L9.83008 10.8477H16.7765L19.7199 21.2046L23.1343 10.8477Z"
      fill="#F8FAFC"
    />
  </Icon>
);

export default YoloGamesGrayscale;
