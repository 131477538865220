import { Icon, IconProps } from "../Icon";
export const Join = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 6L13.59 7.41L21.17 15L2 15V17H21.17L13.59 24.59L15 26L25 16L15 6ZM24 6H28V26H24V28H28C29.1046 28 30 27.1046 30 26V6C30 4.89543 29.1046 4 28 4H24V6Z"
      fill="currentColor"
    />
  </Icon>
);

export default Join;
