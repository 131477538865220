import { Icon, IconProps } from "@chakra-ui/react";

export const RankThird = (props: IconProps) => (
  <Icon width="256" height="256" viewBox="0 0 256 256" fill="none" {...props}>
    <path
      d="M112 9.2376C121.901 3.52135 134.099 3.52136 144 9.2376L222.851 54.7624C232.752 60.4786 238.851 71.0427 238.851 82.4752V173.525C238.851 184.957 232.752 195.521 222.851 201.238L144 246.762C134.099 252.479 121.901 252.479 112 246.762L33.1487 201.238C23.2479 195.521 17.1488 184.957 17.1488 173.525V82.4752C17.1488 71.0427 23.2479 60.4786 33.1488 54.7624L112 9.2376Z"
      fill="url(#paint0_linear_2424_83568)"
    />
    <path
      d="M82.0626 48.4356C110.489 32.0235 145.512 32.0235 173.939 48.4356C202.365 64.8477 219.877 95.1785 219.877 128.003C219.877 160.827 202.365 191.158 173.939 207.57C145.512 223.982 110.489 223.982 82.0626 207.57C53.6361 191.158 36.1246 160.827 36.1246 128.003C36.1246 95.1785 53.6361 64.8477 82.0626 48.4356Z"
      fill="url(#paint1_linear_2424_83568)"
      stroke="black"
    />
    <g filter="url(#filter0_diii_2424_83568)">
      <path
        d="M63.8145 107.925V86.9369C70.2825 79.8089 80.5785 77.9609 96.9465 77.9609H163.606C179.974 77.9609 193.174 89.8409 193.174 106.209V108.585C193.174 120.333 185.782 124.821 179.314 125.613C185.782 126.537 193.174 131.685 193.174 142.113V144.225C193.174 160.593 179.974 173.001 163.606 173.001H97.2105C80.0505 173.001 70.2825 168.645 63.8145 162.837V140.793C70.5465 146.205 80.0505 151.221 97.2105 151.221C113.314 151.221 140.638 151.221 160.834 151.221C173.506 151.221 174.958 134.325 160.834 134.325H102.358V116.637H160.966C175.354 116.637 174.034 99.7409 160.834 99.7409H96.9465C80.5785 99.7409 70.2825 101.589 63.8145 107.925Z"
        fill="#D9D9D9"
      />
      <path
        d="M63.8145 107.925V86.9369C70.2825 79.8089 80.5785 77.9609 96.9465 77.9609H163.606C179.974 77.9609 193.174 89.8409 193.174 106.209V108.585C193.174 120.333 185.782 124.821 179.314 125.613C185.782 126.537 193.174 131.685 193.174 142.113V144.225C193.174 160.593 179.974 173.001 163.606 173.001H97.2105C80.0505 173.001 70.2825 168.645 63.8145 162.837V140.793C70.5465 146.205 80.0505 151.221 97.2105 151.221C113.314 151.221 140.638 151.221 160.834 151.221C173.506 151.221 174.958 134.325 160.834 134.325H102.358V116.637H160.966C175.354 116.637 174.034 99.7409 160.834 99.7409H96.9465C80.5785 99.7409 70.2825 101.589 63.8145 107.925Z"
        fill="url(#paint2_linear_2424_83568)"
      />
    </g>
    <defs>
      <filter
        id="filter0_diii_2424_83568"
        x="43.322"
        y="57.4685"
        width="180.59"
        height="146.27"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="5.12311" dy="5.12311" />
        <feGaussianBlur stdDeviation="12.8078" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
        <feBlend mode="overlay" in2="BackgroundImageFix" result="effect1_dropShadow_2424_83568" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2424_83568" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.28078" dy="1.28078" />
        <feGaussianBlur stdDeviation="0.640389" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend mode="soft-light" in2="shape" result="effect2_innerShadow_2424_83568" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1.28078" dy="-1.28078" />
        <feGaussianBlur stdDeviation="0.640389" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.9 0" />
        <feBlend mode="overlay" in2="effect2_innerShadow_2424_83568" result="effect3_innerShadow_2424_83568" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1.28078" dy="-1.28078" />
        <feGaussianBlur stdDeviation="0.640389" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
        <feBlend mode="normal" in2="effect3_innerShadow_2424_83568" result="effect4_innerShadow_2424_83568" />
      </filter>
      <linearGradient
        id="paint0_linear_2424_83568"
        x1="256"
        y1="2.96789e-05"
        x2="-44.6599"
        y2="182.374"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA450" />
        <stop offset="1" stopColor="#EF6B20" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2424_83568"
        x1="128.001"
        y1="21.3359"
        x2="128.001"
        y2="234.669"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#191B20" />
        <stop offset="1" stopColor="#3D4048" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2424_83568"
        x1="153.386"
        y1="89.8016"
        x2="70.5373"
        y2="163.429"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFA450" />
        <stop offset="1" stopColor="#EF6B20" />
      </linearGradient>
    </defs>
  </Icon>
);

export const RankThirdGradient = (props: IconProps) => (
  <Icon width="512" height="512" viewBox="0 0 512 512" fill="none" {...props}>
    <circle opacity="0.2" cx="256" cy="256" r="256" fill="url(#paint0_radial_2424_83562)" />
    <defs>
      <radialGradient
        id="paint0_radial_2424_83562"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(256 256) rotate(90) scale(256)"
      >
        <stop stopColor="#FFA500" />
        <stop offset="1" stopColor="#FFA500" stopOpacity="0" />
      </radialGradient>
    </defs>
  </Icon>
);
