import { HStack, Stack } from "@chakra-ui/react";
import { EthHalfIcon, Text } from "@looksrare/uikit";
import { BigIntish, formatNumberToLocale, formatToSignificant } from "@looksrare/utils";
import { EntryPosition } from "../../../types";

interface MoDHistoryPositionStatsProps {
  multiplier: number;
  positionAmount: BigIntish;
  positionType: EntryPosition;
  isWinningPosition: boolean;
}

export const MoDHistoryPositionStats = ({
  multiplier,
  positionAmount,
  positionType,
  isWinningPosition,
}: MoDHistoryPositionStatsProps) => {
  const multiplierTextColor = (() => {
    if (!isWinningPosition) {
      return "text-03";
    }

    return `moondoom-${positionType}`;
  })();

  return (
    <Stack spacing={0} alignItems="end">
      <Text textStyle="detail" color={multiplierTextColor} bold>
        {formatNumberToLocale(multiplier, 0, 2)}x
      </Text>
      <HStack spacing={1}>
        <Text textStyle="caption" color="text-02">
          {formatToSignificant(positionAmount)}
        </Text>
        <EthHalfIcon height={4} width={2} />{" "}
      </HStack>
    </Stack>
  );
};
