import { VStack } from "@chakra-ui/react";
import { ChainId } from "@looksrare/config";
import { Step, useEnterCaveStore } from "./state";
import { CurrentSubStep, StepDetails, StepRow } from "./shared";

interface SendStepProps {
  chainId: ChainId;
  onRetry: () => void;
}

export const SendStep = ({ chainId, onRetry }: SendStepProps) => {
  const step = useEnterCaveStore((state) => state.steps[Step.SEND]);
  return (
    <VStack spacing={4} alignItems="start">
      <StepRow stepStatus={step.status}>Complete Purchase</StepRow>
      {step.status === "current" && (
        <StepDetails>
          <CurrentSubStep
            chainId={chainId}
            hasError={step.hasError}
            hashLinkText="Transaction"
            totalSteps={1}
            currentStep={1}
            hash={step.hash}
            onRetry={onRetry}
          />
        </StepDetails>
      )}
    </VStack>
  );
};
