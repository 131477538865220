import { useState } from "react";
import { useTranslation } from "next-i18next";
import noop from "lodash/noop";
import {
  fromDecimals,
  toDecimals,
  useBreakpointValueSsr,
  useDepositEth,
  useEthBalance,
  useGetFormattedErrorAndTitle,
  useInvalidateTokenBalance,
  useSubmitTransaction,
  useWethBalance,
  useWithdrawWeth,
} from "@looksrare/utils";
import { Address } from "viem";
import { Divider, Box, IconButton, useBoolean, useDisclosure, ModalBody } from "@chakra-ui/react";
import { ConfirmInWalletModal } from "../ConfirmInWalletModal";
import CurrencyInput from "../../Input/CurrencyInput";
import { Button } from "../../Button";
import { DoubleArrowIcon } from "../../Icons";
import { ModalFooterStack } from "../../Modal";
import { Text } from "../../Text";
import { useToast } from "../../useToast";

interface ConvertEthViewProps {
  isOpen: boolean;
  onClose?: () => void;
  account: Address;
  defaultOutputAmount?: number; // @NOTE uses decimals not WEI
}

// Buffer to keep some eth to pay gas
const MIN_ETH_BALANCE = 10000000000000000n; // 0.01

export const ConvertEthView = ({ account, isOpen, onClose = noop, defaultOutputAmount }: ConvertEthViewProps) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const transactionDisclosure = useDisclosure();
  const [isDeposit, setIsDeposit] = useBoolean(true);
  const [amount, setAmount] = useState(defaultOutputAmount?.toString() || "");
  const isGteSmBreakpoint = useBreakpointValueSsr({ base: false, sm: true });

  const { data: ethBalanceWei } = useEthBalance(account, { enabled: isOpen });
  const { data: wethBalanceWei } = useWethBalance(account, { enabled: isOpen });
  const invalidateTokenBalance = useInvalidateTokenBalance();
  const depositEth = useDepositEth();
  const withdrawEth = useWithdrawWeth();

  const balanceWei = isDeposit ? ethBalanceWei : wethBalanceWei;
  const balance = balanceWei ? fromDecimals(balanceWei) : null;
  const amountInWei = amount !== "" ? toDecimals(amount) : null;

  const canConvert = amountInWei && balanceWei && amountInWei <= balanceWei && amountInWei > 0n;
  const getFormattedErrorAndTitle = useGetFormattedErrorAndTitle();

  const { submitTransaction, isTxConfirmed, isTxError, isTxSending, isTxWaiting, txResponse } = useSubmitTransaction({
    onSend: async () => {
      const transaction = isDeposit ? await depositEth(amountInWei!) : await withdrawEth(amountInWei!);
      return transaction ?? null;
    },
    onSuccess: () => {
      invalidateTokenBalance();
      transactionDisclosure.onClose();
      toast({
        title: t("Converted!"),
        description: t("Your WETH/ETH conversion was successful"),
      });
      onClose();
    },
    onError: (error) => {
      const { title, description } = getFormattedErrorAndTitle(error);
      toast({ status: "error", title, description, dataIdSuffix: "convert-eth" });
    },
  });

  const handleMax = async () => {
    if (balance && balanceWei) {
      if (isDeposit) {
        const estimatedMaxAmount = balanceWei - MIN_ETH_BALANCE;
        setAmount(fromDecimals(estimatedMaxAmount));
      } else {
        setAmount(balance);
      }
    }
  };

  const handleSubmit = () => {
    transactionDisclosure.onOpen();
    submitTransaction();
  };

  return (
    <>
      <ConfirmInWalletModal
        onRetry={submitTransaction}
        isOpen={transactionDisclosure.isOpen}
        onClose={transactionDisclosure.onClose}
        isTxConfirmed={isTxConfirmed}
        isTxSending={isTxSending}
        isTxWaiting={isTxWaiting}
        isTxError={isTxError}
        txResponse={txResponse || undefined}
      />

      <ModalBody borderTopWidth="1px" borderTopColor="border-01" flexDirection="column" py={12}>
        <Text color="text-02" textStyle="detail" mb={4}>
          {t("Convert from")}
        </Text>
        <CurrencyInput
          price={amount}
          setPrice={setAmount}
          onMax={handleMax}
          currency={isDeposit ? "ETH" : "WETH"}
          autoFocus={isGteSmBreakpoint}
          info={t("Balance: {{amount}}", { amount: balance })}
          warning={balanceWei && amountInWei && amountInWei > balanceWei ? t("Insufficient Balance") : undefined}
        />

        <Box position="relative" my={10}>
          <Divider />
          <IconButton
            onClick={setIsDeposit.toggle}
            colorScheme="gray"
            isRound
            aria-label="Switch currency"
            border="solid 1px"
            borderColor="currentcolor"
            position="absolute"
            right={0}
            top={-6}
            mr={4}
          >
            <DoubleArrowIcon />
          </IconButton>
        </Box>

        <Text color="text-02" textStyle="detail" mb={4}>
          {t("Convert to")}
        </Text>
        <CurrencyInput price={amount} setPrice={setAmount} currency={isDeposit ? "WETH" : "ETH"} isReadOnly />

        <Text color="text-03" textStyle="detail" mt={4}>
          {t("Rate: 1 ETH = 1 WETH")}
        </Text>
        <Text color="text-03" textStyle="detail">
          {t("Platform Fees: {{fee}}", { fee: 0 })}
        </Text>
      </ModalBody>

      <ModalFooterStack>
        <Button tabIndex={1} colorScheme="gray" flex={1} onClick={onClose}>
          {t("Close")}
        </Button>
        <Button tabIndex={2} isDisabled={!canConvert} flex={1} onClick={handleSubmit}>
          {t("Convert")}
        </Button>
      </ModalFooterStack>
    </>
  );
};
