import { Button, CheckmarkFilledIcon, ErrorIcon, Text } from "@looksrare/uikit";
import { type BigIntish } from "@looksrare/utils";
import { useTranslation } from "next-i18next";
import { EnterCaveButton } from "../Buttons";
import { usePtbConfig, type PtbPlayer } from "../..";
import { Wrapper, useIsEligibleForRefund } from "./shared";

interface WaitingToStartProps {
  hasOpenSpot: boolean;
  isParticipating: boolean;
  caveOnChainId: BigIntish;
  roundOnChainId: BigIntish;
  players: PtbPlayer[];
  playersPerRound: number;
  openedAt: string | null;
}

export const WaitingToStart = ({
  hasOpenSpot,
  isParticipating,
  caveOnChainId,
  roundOnChainId,
  players,
  playersPerRound,
  openedAt,
}: WaitingToStartProps) => {
  const { t } = useTranslation();

  const { isWalletBlocked } = usePtbConfig();

  const { isPlayerEligibleForRefund, handleRefund, isLoading } = useIsEligibleForRefund({
    caveOnChainId,
    roundOnChainId,
    openedAt,
    players,
    playersPerRound,
  });

  const ctaText = (() => {
    if (!isParticipating && hasOpenSpot) {
      return t("ptb::Waiting for the round to begin. Join Now!");
    }

    return t("ptb::Waiting for the round to begin.");
  })();
  const ctaButtonText = (() => {
    if (isParticipating) {
      return t("ptb::Joined!");
    }

    if (!hasOpenSpot) {
      t("ptb::Join Next Round");
    }

    return t("ptb::Join Now!");
  })();

  return (
    <Wrapper>
      <Text>{ctaText}</Text>
      {(() => {
        if (isWalletBlocked) {
          return (
            <Button rightIcon={<ErrorIcon boxSize={5} />} variant="outline" colorScheme="red" isDisabled>
              {t("Wallet Blocked")}
            </Button>
          );
        }

        if (isPlayerEligibleForRefund) {
          return (
            <Button size="sm" colorScheme="red" onClick={handleRefund} isLoading={isLoading}>
              {t("ptb::Run Away Scared")}
            </Button>
          );
        }

        return (
          <EnterCaveButton
            colorScheme="brand"
            rightIcon={isParticipating ? <CheckmarkFilledIcon /> : undefined}
            isDisabled={isParticipating}
          >
            {ctaButtonText}
          </EnterCaveButton>
        );
      })()}
    </Wrapper>
  );
};
