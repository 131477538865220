import { useTranslation } from "next-i18next";
import { PtbRoundLogType } from "../../../../types";
import { LogText } from "./LogText";
import type { LogTextProps, RoundLogTextGetter } from "./types";

const RoundOpenedText1 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.roundOpened1")}</LogText>;
};

export const roundOpenedLogs: RoundLogTextGetter = () => [
  <RoundOpenedText1 key={`${PtbRoundLogType.ROUND_OPENED}1`} />,
];
