import { MutableRefObject, useCallback, useEffect, useState } from "react";

interface SlidingSelectorResult {
  offset: {
    x: number;
    y: number;
  };
  height: number;
  width: number;
  containerWidth: number;
  containerHeight: number;
}

const defaults: SlidingSelectorResult = {
  offset: { x: 0, y: 0 },
  height: 0,
  width: 0,
  containerWidth: 0,
  containerHeight: 0,
};

export const useSlidingSelector = (
  elements: MutableRefObject<Map<number, HTMLDivElement>>,
  activeIdx: number
): SlidingSelectorResult & { updatePosition: () => void } => {
  const [result, setResult] = useState<SlidingSelectorResult>(defaults);

  const updatePosition = useCallback(() => {
    const activeElement = elements.current.get(activeIdx);

    if (activeElement) {
      setResult({
        offset: { x: activeElement.offsetLeft, y: activeElement.offsetTop },
        height: activeElement.scrollHeight,
        width: activeElement.scrollWidth,
        containerWidth: activeElement.parentElement?.scrollWidth ?? 0,
        containerHeight: activeElement.parentElement?.scrollHeight ?? 0,
      });
    }
  }, [activeIdx, elements]);

  useEffect(() => {
    updatePosition();
  }, [updatePosition]);

  return { ...result, updatePosition };
};
