"use client";

import { forwardRef, useState } from "react";
import type { Address } from "viem";
import Image, { type ImageProps } from "next/image";
import {
  AspectRatio,
  Box,
  useBreakpointValue,
  type AspectRatioProps,
  type BoxProps,
  type ResponsiveObject,
} from "@chakra-ui/react";
import { Blockie } from "../Avatar";

interface ProfileAvatarProps extends AspectRatioProps {
  src?: string;
  address: Address;
  size?: number | ResponsiveObject<number>;
  isPrivate?: boolean;
  nextImageProps?: ImageProps;
  imageWrapperProps?: BoxProps;
}

export const ProfileAvatar = forwardRef<HTMLDivElement, ProfileAvatarProps>(
  (
    {
      src,
      address,
      borderRadius = "circular",
      size = 16,
      isPrivate = false,
      nextImageProps,
      imageWrapperProps,
      ...props
    },
    ref
  ) => {
    const [hasImageErrored, setHasImageErrored] = useState(false);
    const showAvatar = !isPrivate && !!src && !hasImageErrored;
    const sizeAsBreakpoint = typeof size === "number" ? { base: size } : size;
    const breakpointSize = useBreakpointValue(sizeAsBreakpoint);
    return (
      <AspectRatio
        ref={ref}
        borderRadius={borderRadius}
        ratio={1}
        width={`${breakpointSize}px`}
        height={`${breakpointSize}px`}
        {...props}
      >
        {(() => {
          if (showAvatar) {
            return (
              <Box borderRadius={borderRadius} {...imageWrapperProps}>
                <Image
                  src={src}
                  alt={address}
                  width={breakpointSize}
                  height={breakpointSize}
                  objectFit="cover"
                  placeholder="blur"
                  /* 1px of text-03 */
                  blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mOcu2TdfwAGxQLwlV4SuQAAAABJRU5ErkJggg=="
                  onError={() => setHasImageErrored(true)}
                  {...nextImageProps}
                />
              </Box>
            );
          }
          return <Blockie address={address} size={breakpointSize} borderRadius={borderRadius} />;
        })()}
      </AspectRatio>
    );
  }
);
ProfileAvatar.displayName = "ProfileAvatar";
