import type { FC, PropsWithChildren } from "react";
import { Box, type BoxProps, Flex, type FlexProps, Image } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button, HelpFilledIcon, NextLink } from "@looksrare/uikit";

export const Panel: FC<PropsWithChildren<FlexProps>> = (props) => (
  <Flex
    border="1px solid"
    borderColor="border-01"
    borderRadius="dialog"
    flexDirection="column"
    height="100%"
    maxHeight="100%"
    position="relative"
    {...props}
  />
);

export const PanelContent: FC<PropsWithChildren<BoxProps>> = (props) => (
  <Box
    borderBottomLeftRadius="dialog"
    borderBottomRightRadius="dialog"
    p={0}
    flex={1}
    bg="ui-glass"
    position="relative"
    overflow="hidden"
    {...props}
  />
);

export const PanelHeader: FC<PropsWithChildren<FlexProps>> = (props) => (
  <Flex
    alignItems="center"
    p={4}
    justifyContent="space-between"
    bg="ui-bg"
    borderTopLeftRadius="dialog"
    borderTopRightRadius="dialog"
    minHeight={8}
    borderBottom="1px solid"
    borderBottomColor="border-01"
    {...props}
  />
);

type CaveListPanelProps = BoxProps;

export const CaveListPanel: FC<PropsWithChildren<CaveListPanelProps>> = ({ children, ...props }) => {
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection="column"
      bg="ui-glass"
      overflow="hidden"
      border="1px solid"
      borderColor="border-01"
      borderRadius="dialog"
      {...props}
    >
      <Flex
        alignItems="center"
        justifyContent="end"
        p={4}
        borderBottom="1px solid"
        borderBottomColor="border-01"
        bgGradient="linear(to-b, ui-01, ui-bg)"
        position="relative"
        overflow="hidden"
        minHeight={16}
      >
        <Image
          src="/images/poke-the-bear/ptb-panel-bg.svg"
          width="75px"
          alt="ptb-mini-logo"
          position="absolute"
          left={0}
          top={0}
        />
        <Button
          as={NextLink}
          href="/poke-the-bear/faq"
          size="xs"
          colorScheme="secondary"
          leftIcon={<HelpFilledIcon boxSize={5} />}
        >
          {t("How to Play")}
        </Button>
      </Flex>
      {children}
    </Flex>
  );
};
