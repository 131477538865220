import { FC, PropsWithChildren } from "react";
import { Box, BoxProps, Flex, FlexProps } from "@chakra-ui/react";
import { SideNav, TopNav, TopNavMobile } from ".";

export interface YoloGamesPageProps extends FlexProps {
  title?: string;
  titleElement?: React.ReactElement;
  breadcrumbHref?: string;
  contentContainerProps?: BoxProps;
}

export const Page: FC<PropsWithChildren<YoloGamesPageProps>> = ({
  title,
  titleElement,
  breadcrumbHref,
  children,
  contentContainerProps,
}) => {
  return (
    <>
      <SideNav
        height="100dvh"
        position="fixed"
        top={0}
        left={0}
        zIndex="sticky"
        display={{ base: "none", lg: "flex" }}
      />
      <Flex
        position="relative"
        bg="ui-bg"
        flexDirection="column"
        // Unclear where this 4px is coming from but it removes scrollbar when no scrolling is not necessary
        minHeight="calc(100dvh - 4px)"
        pl={{ base: 0, lg: 20 }}
      >
        <TopNavMobile title={title} position="sticky" top={0} zIndex="docked" display={{ base: "flex", lg: "none" }} />
        <TopNav
          title={title}
          titleElement={titleElement}
          breadcrumbHref={breadcrumbHref}
          position="sticky"
          top={0}
          zIndex="docked"
          display={{ base: "none", lg: "flex" }}
        />
        <Box width="100%" flex={1} {...contentContainerProps}>
          {children}
        </Box>
      </Flex>
    </>
  );
};
