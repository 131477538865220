export const looksBridgeVaultAbi = [
  {
    inputs: [
      { internalType: "address", name: "receiver_", type: "address" },
      { internalType: "uint256", name: "amount_", type: "uint256" },
      { internalType: "uint256", name: "msgGasLimit_", type: "uint256" },
      { internalType: "address", name: "connector_", type: "address" },
      { internalType: "bytes", name: "execPayload_", type: "bytes" },
      { internalType: "bytes", name: "options_", type: "bytes" },
    ],
    name: "bridge",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "bridgeType",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  { inputs: [], name: "claimOwner", outputs: [], stateMutability: "nonpayable", type: "function" },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "connectorCache",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "connector_", type: "address" },
      { internalType: "uint256", name: "msgGasLimit_", type: "uint256" },
      { internalType: "uint256", name: "payloadSize_", type: "uint256" },
    ],
    name: "getMinFees",
    outputs: [{ internalType: "uint256", name: "totalFees", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role_", type: "bytes32" },
      { internalType: "address", name: "grantee_", type: "address" },
    ],
    name: "grantRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role_", type: "bytes32" },
      { internalType: "address", name: "address_", type: "address" },
    ],
    name: "hasRole",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "hook__",
    outputs: [{ internalType: "contract IHook", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    name: "identifierCache",
    outputs: [{ internalType: "bytes", name: "", type: "bytes" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "nominee_", type: "address" }],
    name: "nominateOwner",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "nominee",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint32", name: "siblingChainSlug_", type: "uint32" },
      { internalType: "bytes", name: "payload_", type: "bytes" },
    ],
    name: "receiveInbound",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "token_", type: "address" },
      { internalType: "address", name: "rescueTo_", type: "address" },
      { internalType: "uint256", name: "amount_", type: "uint256" },
    ],
    name: "rescueFunds",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "connector_", type: "address" },
      { internalType: "bytes32", name: "messageId_", type: "bytes32" },
    ],
    name: "retry",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role_", type: "bytes32" },
      { internalType: "address", name: "revokee_", type: "address" },
    ],
    name: "revokeRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "token",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address[]", name: "connectors", type: "address[]" },
      { internalType: "bool[]", name: "statuses", type: "bool[]" },
    ],
    name: "updateConnectorStatus",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "hook_", type: "address" },
      { internalType: "bool", name: "approve_", type: "bool" },
    ],
    name: "updateHook",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "validConnectors",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
] as const;
