import Cookies from "js-cookie";
import { Address } from "viem";
import { getStorageKey } from ".";

/**
 * LR Auth
 */
export const getAuthCookie = (address: Address): string | undefined => Cookies.get(getStorageKey("auth", address));

export const setAuthCookie = (address: Address, value: string): void => {
  Cookies.set(getStorageKey("auth", address), value, { expires: 30, sameSite: "strict", secure: true });
};

export const removeAuthCookie = (address: Address) => {
  Cookies.remove(getStorageKey("auth", address));
};
