import { useEffect, useState } from "react";
import { Address } from "viem";
import { useAccount, useWalletClient } from "wagmi";
import { UseQueryOptions } from "@tanstack/react-query";
import { useDisclosure } from "@chakra-ui/react";
import { isAuthorized, useSignInHandler } from "@looksrare/utils";
import uniqueId from "lodash/uniqueId";
import { useTranslation } from "next-i18next";
import { SectionLoader, SectionLoaderProps, SectionPlaceholder } from "@looksrare/uikit";
import { User } from "@/types";
import { useUser } from "@/queries/user";
import { SignInModal } from "./Modals";
import { ConnectWalletPlaceholderPageContent, SignInPlaceholderPageContent } from "./Placeholders";

interface SignedUserGuardProps {
  children: (user: User, account: Address) => JSX.Element;
  userQueryOptions?: UseQueryOptions<User>;
  loaderProps?: SectionLoaderProps;
}

export const SignedUserGuard = ({ children, userQueryOptions, loaderProps }: SignedUserGuardProps) => {
  const { t } = useTranslation();
  const { address, isConnecting, isConnected } = useAccount();
  const { data: signer } = useWalletClient();
  const userQuery = useUser(address!, { enabled: !!address, refetchOnWindowFocus: false, ...userQueryOptions });
  const [hasClickedPlaceholderConnectCta, setHasClickedPlaceholderConnectCta] = useState(false);
  const [signInSuccess, setSignInSuccess] = useState(false);
  const signInDisclosure = useDisclosure();
  const accountIsAuthorized = isAuthorized(address);

  const sectionLoader = <SectionLoader minHeight="400px" {...loaderProps} />;
  const isLoading = isConnecting || userQuery.isLoading;

  const { txStatus, signInHandler } = useSignInHandler({
    onAuthSuccess: () => {
      setSignInSuccess(true);
      setHasClickedPlaceholderConnectCta(false);
      signInDisclosure.onClose();
    },
  });

  useEffect(() => {
    // Automatically open sign in modal after successful wallet connection via the placeholder connect CTA
    if (!signInDisclosure.isOpen && signer && address && hasClickedPlaceholderConnectCta) {
      signInHandler();
      signInDisclosure.onOpen();
    }
  }, [address, signInDisclosure, hasClickedPlaceholderConnectCta, isConnected, signInHandler, signer]);

  const handleOnCloseSignIn = () => {
    setHasClickedPlaceholderConnectCta(false);
    signInDisclosure.onClose();
  };

  return (
    <>
      <SignInModal
        key={uniqueId("signin-modal-")}
        isOpen={signInDisclosure.isOpen}
        onClose={handleOnCloseSignIn}
        txStatus={txStatus}
        signInHandler={signInHandler}
      />
      {(() => {
        // Initialization of a connected wallet, or fetching user
        if (isLoading) {
          return sectionLoader;
        }

        // Not connected
        if (!isConnected || !address) {
          return (
            <>
              <ConnectWalletPlaceholderPageContent
                borderRadius="dialog"
                px={8}
                py={16}
                bg="ui-bg"
                borderColor="border-01"
                borderWidth="1px"
                mb={8}
                minHeight={0}
                connectButtonProps={{ onClick: () => setHasClickedPlaceholderConnectCta(true) }}
              />
            </>
          );
        }

        // Not signed in
        if (!accountIsAuthorized) {
          return (
            <SignInPlaceholderPageContent
              borderRadius="dialog"
              px={8}
              py={16}
              bg="ui-bg"
              borderColor="border-01"
              border="1px "
              mb={8}
              minHeight={0}
              onAuthSuccess={() => setSignInSuccess(true)}
            />
          );
        }

        // User found and sign in succeeded
        if ((accountIsAuthorized || signInSuccess) && userQuery.isSuccess && userQuery.data) {
          return children(userQuery.data, address);
        }

        // Default not found or sign-in unsuccessful
        return <SectionPlaceholder>{t("User Not Found")}</SectionPlaceholder>;
      })()}
    </>
  );
};
