import { useQuery, useQueryClient } from "@tanstack/react-query";
import { type RQueryOptions, type BigIntish } from "@looksrare/utils";
import { getRoundLogs, type RoundLogsReturn } from "../graphql";
import { getPtbContractNameFromNetwork } from "../utils";
import type { PtbContractName } from "../types";
import { useGetCaveQueryParams } from "./useGetCaveQueryParams";
import { getPtbUserQuery } from "./usePtbUser";

type RoundLogsQueryOptions = RQueryOptions<RoundLogsReturn>;

export const getRoundLogsKey = (caveOnChainId: BigIntish, roundOnChainId: BigIntish, contract: PtbContractName) => [
  "ptb",
  "roundLogs",
  caveOnChainId,
  roundOnChainId,
  contract,
];

export const useRoundLogs = (
  caveOnChainId: BigIntish,
  roundOnChainId: BigIntish,
  contract: PtbContractName,
  options?: RoundLogsQueryOptions
) => {
  const queryClient = useQueryClient();
  return useQuery({
    queryKey: getRoundLogsKey(caveOnChainId, roundOnChainId, contract),
    queryFn: async () => {
      const logs = await getRoundLogs(caveOnChainId, roundOnChainId, contract);

      // Update individual user cache
      logs.logs.forEach((ptbLog) => {
        if (ptbLog.user) {
          ptbLog.user.forEach((ptbUser) => {
            queryClient.setQueryData(getPtbUserQuery(ptbUser.address), ptbUser);
          });
        }
      });

      return logs;
    },
    ...options,
  });
};

export const useActiveRoundLogs = (options?: RoundLogsQueryOptions) => {
  const { caveOnChainId, roundOnChainId, network } = useGetCaveQueryParams();
  return useRoundLogs(caveOnChainId, roundOnChainId, getPtbContractNameFromNetwork(network), {
    enabled: !!caveOnChainId && !!roundOnChainId && options?.enabled !== false,
    refetchInterval: 20 * 1_000,
    ...options,
  });
};
