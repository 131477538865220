import { Icon, IconProps } from "../Icon";

const LightningBolt = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.61 29.92C11.189 29.7423 10.942 29.3019 11.01 28.85L12.83 17H7.99999C7.68964 17.0083 7.39302 16.8721 7.19717 16.6312C7.00133 16.3903 6.92847 16.0721 6.99999 15.77L9.99999 2.77C10.1088 2.30883 10.5263 1.98733 11 2H21C21.3026 1.99897 21.5894 2.135 21.78 2.37C21.9734 2.60772 22.0471 2.92098 21.98 3.22L20.25 11H25C25.3824 10.9992 25.7317 11.2166 25.9 11.56C26.0459 11.8888 26.0039 12.2707 25.79 12.56L12.79 29.56C12.6118 29.8241 12.3184 29.9876 12 30C11.8662 29.9975 11.734 29.9704 11.61 29.92ZM17.75 13L19.75 3.99999H11.8L9.25999 15H15.17L13.58 25.28L23 13H17.75Z"
      fill="currentColor"
    />
  </Icon>
);

export default LightningBolt;
