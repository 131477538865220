import { Icon, IconProps } from "../Icon";

const Rakeback = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99998 12H6C4.89543 12 4 12.8954 4 14V28C4 29.1046 4.89543 30 6 30H26C27.1046 30 28 29.1046 28 28V19C28 17.8954 27.1046 17 26 17H6V14H9.99998V12ZM23 22H20V25H23V22Z"
      fill="currentColor"
    />
    <path d="M22 14H26V12H22L22 14Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6 7.6L17 11.2V1H15V11.2L11.4 7.6L10 9L16 15L22 9L20.6 7.6Z"
      fill="currentColor"
    />
  </Icon>
);

export default Rakeback;
