import type { Address } from "viem";
import { useConfig } from "wagmi";
import { Erc20Abi, PokeTheBearAbi, TransferManagerAbi } from "@looksrare/config";
import { ChainId } from "@looksrare/config";
import { useGameAddresses, useGameFallbackChainId } from "@looksrare/uikit";

interface PtbRelevantContractInfo {
  ptb: { address: Address; abi: typeof PokeTheBearAbi };
  looks: { address: Address; abi: typeof Erc20Abi };
  transferManager: { address: Address; abi: typeof TransferManagerAbi };
}

export const usePtbContractInfo = () => {
  const { chains } = useConfig();
  const ptbAddresses = useGameAddresses();
  const fallbackChainId = useGameFallbackChainId();
  const chainsFromNetwork = chains.reduce<Record<number, PtbRelevantContractInfo>>((accum, chain) => {
    const chainId = chain.id as ChainId;
    accum[chainId] = {
      ptb: {
        address: ptbAddresses.PTB,
        abi: PokeTheBearAbi,
      } as const,
      looks: {
        address: ptbAddresses.LOOKS,
        abi: Erc20Abi,
      } as const,
      transferManager: {
        address: ptbAddresses.PTB_TRANSFER_MANAGER,
        abi: TransferManagerAbi,
      } as const,
    };
    return accum;
  }, {});

  // Ensure a fallback for server-side
  return {
    [fallbackChainId]: {
      ptb: {
        address: ptbAddresses.PTB,
        abi: PokeTheBearAbi,
      } as const,
      looks: {
        address: ptbAddresses.LOOKS,
        abi: Erc20Abi,
      } as const,
      transferManager: {
        address: ptbAddresses.PTB_TRANSFER_MANAGER,
        abi: TransferManagerAbi,
      } as const,
    },
    ...chainsFromNetwork,
  };
};
