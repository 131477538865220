import { useTranslation } from "next-i18next";
import { ModalFooter, Stack } from "@chakra-ui/react";
import { useAssertNetwork } from "@looksrare/utils";
import { addTokenToWallet } from "@looksrare/utils/";
import { blastMainnetAddresses } from "@looksrare/config";
import { CheckmarkFilledIcon, LaunchOpenInNewIcon, MetaMaskIcon } from "../../../Icons";
import { ModalHeroHeader } from "../../../Modal/ModalHeaderLegacy";
import { Button } from "../../../Button";
import { Link } from "../../../Link";

interface BridgeLooksSuccessViewProps {
  transactionHash: string;
  onClose: () => void;
}

export const BridgeLooksSuccessView = ({ transactionHash, onClose }: BridgeLooksSuccessViewProps) => {
  const { t } = useTranslation();

  const assertNetwork = useAssertNetwork({
    network: "blast",
  });

  return (
    <>
      <ModalHeroHeader
        icon={CheckmarkFilledIcon}
        title={t("Bridging Successful!")}
        label={t("Your funds are now being bridged.")}
        onClose={onClose}
        showCloseButton
      />
      <ModalFooter>
        <Stack width="100%">
          <Button
            as={Link}
            href={`https://www.socketscan.io/tx/${transactionHash}`}
            leftIcon={<LaunchOpenInNewIcon boxSize={5} />}
            colorScheme="secondary"
            isExternal
          >
            {t("View Bridge Progress")}
          </Button>
          <Button
            leftIcon={<MetaMaskIcon boxSize={5} />}
            onClick={async () => {
              await assertNetwork();

              addTokenToWallet({
                tokenAddress: blastMainnetAddresses.LOOKS,
                tokenDecimals: 18,
                tokenSymbol: "bLOOKS",
                tokenImageSrc: "/images/tokens/BLOOKS.png",
              });
            }}
          >
            {t("Add bLOOKS to MetaMask")}
          </Button>
        </Stack>
      </ModalFooter>
    </>
  );
};
