import { Icon, IconProps } from "../Icon";

const ChartLine = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.8934 4.44934L22.4648 19.2186C22.2175 19.7103 21.6193 19.9097 21.1264 19.6647L10.8748 14.5692L3.89341 28.4493L2.10669 27.5507L9.53526 12.7814C9.78259 12.2897 10.3808 12.0903 10.8737 12.3353L21.1253 17.4308L28.1067 3.55066L29.8934 4.44934Z"
      fill="currentColor"
    />
  </Icon>
);

export default ChartLine;
