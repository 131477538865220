import { useEffect } from "react";
import debounce from "lodash/debounce";

export const useOnVisibilityChange = (callback: (isHidden: boolean) => void) => {
  useEffect(() => {
    const onVisibilityChance = debounce(() => callback(document.hidden), 150);

    document.addEventListener("visibilitychange", onVisibilityChance);

    return () => window.removeEventListener("visibilitychange", onVisibilityChance);
  }, [callback]);
};
