import { FC, PropsWithChildren, forwardRef } from "react";
import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  type AccordionButtonProps,
  type AccordionItemProps,
  type AccordionPanelProps,
} from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";

export const PtbAccordionPanel = forwardRef<HTMLDivElement, AccordionPanelProps>((props, ref) => (
  <AccordionPanel ref={ref} borderRadius="none" bg="transparent" border={0} p={0} pr={2} {...props} />
));
PtbAccordionPanel.displayName = "PtbAccordionPanel";

export const PtbAccordionItem: FC<PropsWithChildren<AccordionItemProps>> = (props) => (
  <AccordionItem
    borderRadius="none"
    bg="transparent"
    border={0}
    borderBottom="1px solid"
    borderBottomColor="border-01"
    p={2}
    sx={{ ":last-of-type": { borderBottom: 0 } }}
    {...props}
  />
);

interface PtbAccordionButtonProps extends AccordionButtonProps {
  icon: JSX.Element;
}

export const PtbAccordionButton: FC<PropsWithChildren<PtbAccordionButtonProps>> = ({ icon, children, ...props }) => (
  <AccordionButton
    borderRadius="button"
    p={0}
    px={2}
    height={12}
    color="text-02"
    bg="transparent"
    border={0}
    sx={{ _hover: { bg: "hover-ui" }, _active: { bg: "onclick-ui" } }}
    zIndex={5} // Account for gradient border position
    {...props}
  >
    {icon}
    <Text as="span" px={2} flex={1} textAlign="left" bold>
      {children}
    </Text>
    <AccordionIcon color="text-03" />
  </AccordionButton>
);
