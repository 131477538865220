import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const TicketRedeemablesFill = (props: IconProps) => {
  const [uid0] = useState(uniqueId());

  return (
    <Icon viewBox="0 0 48 48" {...props}>
      <g id="categories/ticket-redeemables-fill">
        <path
          id="Fill"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44 19.5H43.5C41.0147 19.5 39 21.5147 39 24C39 26.4853 41.0147 28.5 43.5 28.5H44C44.5523 28.5 45 28.9477 45 29.5V36C45 37.6569 43.6569 39 42 39H31.5V33H28.5V39H6C4.34315 39 3 37.6569 3 36V29.5C3 28.9477 3.44772 28.5 4 28.5H4.5C6.98528 28.5 9 26.4853 9 24C9 21.5147 6.98528 19.5 4.5 19.5H4C3.44772 19.5 3 19.0523 3 18.5L3 12C3 10.3431 4.34315 9 6 9L28.5 9V15H31.5V9H42C43.6569 9 45 10.3431 45 12V18.5C45 19.0523 44.5523 19.5 44 19.5ZM31.5 19.5H28.5V28.5H31.5V19.5Z"
          fill={`url(#${uid0})`}
        />
      </g>
      <defs>
        <linearGradient id={uid0} x1="24" y1="9" x2="24" y2="39" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CCFD07" />
          <stop offset="1" stopColor="#00A0E4" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export default TicketRedeemablesFill;
