import { Icon, IconProps } from "../Icon";

const LightningBoltFilled = (props: IconProps) => (
  <Icon viewBox="0 0 29 42" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.41434 41.8805C6.78288 41.614 6.41241 40.9533 6.51434 40.2755L9.24434 22.5005H1.99934C1.53382 22.5131 1.08889 22.3087 0.795118 21.9473C0.501348 21.586 0.392065 21.1087 0.499342 20.6555L4.99934 1.15554C5.16256 0.46379 5.78885 -0.0184528 6.49934 0.000541863H21.4993C21.9532 -0.00100143 22.3834 0.203055 22.6693 0.555542C22.9594 0.912125 23.07 1.38203 22.9693 1.83054L20.3743 13.5005H27.4993C28.0729 13.4994 28.597 13.8255 28.8493 14.3405C29.0682 14.8338 29.0052 15.4066 28.6843 15.8405L9.18434 41.3405C8.91705 41.7368 8.47693 41.9819 7.99934 42.0005C7.79863 41.9968 7.60032 41.9561 7.41434 41.8805Z"
      fill="currentColor"
    />
  </Icon>
);

export default LightningBoltFilled;
