import { Icon, IconProps } from "../Icon";

const YieldToken = (props: IconProps) => (
  <Icon viewBox="0 0 96 96" {...props}>
    <path
      d="M36.7059 0H59.2941V2.82353H64.9412V5.64706H70.5882V8.47059H76.2353V11.2941H79.0588V14.1176H81.8824V16.9412H84.7059V19.7647H87.5294V25.4118H90.3529V31.0588H93.1765V36.7059H96V59.2941H93.1765V64.9412H90.3529V70.5882H87.5294V76.2353H84.7059V79.0588H81.8824V81.8824H79.0588V84.7059H76.2353V87.5294H70.5882V90.3529H64.9412V93.1765H59.2941V96H36.7059V93.1765H31.0588V90.3529H25.4118V87.5294H19.7647V84.7059H16.9412V81.8824H14.1176V79.0588H11.2941V76.2353H8.47059V70.5882H5.64706V64.9412H2.82353V59.2941H0V36.7059H2.82353V31.0588H5.64706V25.4118H8.47059V19.7647H11.2941V16.9412H14.1176V14.1176H16.9412V11.2941H19.7647V8.47059H25.4118V5.64706H31.0588V2.82353H36.7059V0Z"
      fill="#3F2832"
    />
    <path
      d="M22.5882 16.9412H14.1176V19.7647H11.2941V25.4118H8.47055V31.0588H5.64702V36.7059H2.82349V59.2941H5.64702V64.9412H8.47055V70.5882H11.2941V76.2353H14.1176V79.0588H16.9411V81.8823H19.7647V84.7059H25.4117V87.5294H31.0588V90.3529H36.7058V93.1765H59.2941V90.3529H64.9411V87.5294H70.5882V84.7059H76.2352V81.8823H79.0588V76.2353H22.5882V16.9412Z"
      fill="#F77622"
    />
    <path
      d="M73.4118 79.0588H81.8823V76.2352H84.7059V70.5882H87.5294V64.9411H90.3529V59.2941H93.1765V36.7058H90.3529V31.0588H87.5294V25.4117H84.7059V19.7647H81.8823V16.9411H79.0588V14.1176H76.2353V11.2941H70.5882V8.47054H64.9412V5.64701H59.2941V2.82349H36.7059V5.64701H31.0588V8.47054H25.4118V11.2941H19.7647V14.1176H16.9412V19.7647H73.4118V79.0588Z"
      fill="#FEE761"
    />
    <path
      d="M33.8824 5.64709H62.1177V8.47062H67.7647V11.2942H73.4118V14.1177H76.2353V16.9412H79.0589V19.7647H81.8824V22.5883H84.7059V28.2353H87.5294V33.8824H90.353V62.1177H87.5294V67.7647H84.7059V73.4118H81.8824V76.2353H79.0589V79.0589H76.2353V81.8824H73.4118V84.7059H67.7647V87.5294H62.1177V90.353H33.8824V87.5294H28.2353V84.7059H22.5883V81.8824H19.7647V79.0589H16.9412V76.2353H14.1177V73.4118H11.2942V67.7647H8.47062V62.1177H5.64709V33.8824H8.47062V28.2353H11.2942V22.5883H14.1177V19.7647H16.9412V16.9412H19.7647V14.1177H22.5883V11.2942H28.2353V8.47062H33.8824V5.64709Z"
      fill="#FEAE34"
    />
    <path
      d="M45.1765 5.64709H53.6471V8.47062H50.8236V11.2942L48 11.2942V14.1177H45.1765V16.9412H42.353V19.7647H39.5294V22.5883H36.7059V25.4118L33.8824 25.4118V28.2353H31.0589V31.0589L28.2353 31.0589V33.8824H25.4118V36.7059H22.5883V39.5294H19.7647V42.353H16.9412V45.1765L14.1177 45.1765V48H11.2942V50.8236H8.47062V53.6471H5.64709V45.1765L8.47062 45.1765V42.353H11.2942V39.5294H14.1177V36.7059H16.9412V33.8824H19.7647V31.0589L22.5883 31.0589V28.2353H25.4118V25.4118L28.2353 25.4118V22.5883H31.0589V19.7647H33.8824V16.9412H36.7059V14.1177H39.5294V11.2942L42.353 11.2942V8.47062H45.1765V5.64709Z"
      fill="#FEC744"
    />
    <path
      d="M76.2352 14.1177H67.7647V16.9412H64.9411V19.7647H62.1176V22.5883H59.2941V25.4118H56.4705V28.2353H53.647V31.0589H50.8235V33.8824H47.9999V36.7059H45.1764V39.5294H42.3529V42.353H39.5294V45.1765H36.7058V48H33.8823V50.8236H31.0588V53.6471H28.2352V56.4706H25.4117V59.2941H22.5882V62.1177H19.7647V64.9412H16.9411V67.7647H14.1176V70.5883H11.2941V73.4118H14.1176V76.2353H16.9411V79.0589H19.7647V81.8824H22.5882V79.0589H25.4117V76.2353H28.2352V73.4118H31.0588V70.5883H33.8823V67.7647H36.7058V64.9412H39.5294V62.1177H42.3529V59.2941H45.1764V56.4706H47.9999V53.6471H50.8235V50.8236H53.647V48H56.4705V45.1765H59.2941V42.353H62.1176V39.5294H64.9411V36.7059H67.7647V33.8824H70.5882V31.0589H73.4117V28.2353H76.2352V25.4118H79.0588V22.5883H81.8823V19.7647H79.0588V16.9412H76.2352V14.1177Z"
      fill="#FEC744"
    />
    <path
      d="M87.5294 42.3529H90.3529V62.1176H87.5294V64.9411H84.7058V67.7647H81.8823V70.5882H79.0588V73.4117H76.2353V76.2353H73.4117V79.0588H70.5882V81.8823H67.7647V84.7058H64.9411V87.5294H62.1176V90.3529H42.3529V87.5294H45.1764V84.7058H48V81.8823H50.8235V79.0588H53.647V76.2353H56.4706V73.4117H59.2941V70.5882H62.1176V67.7647H64.9411V64.9411H67.7647V62.1176H70.5882V59.2941H73.4117V56.4706H76.2353V53.647H79.0588V50.8235H81.8823V48H84.7058V45.1764H87.5294V42.3529Z"
      fill="#FEC744"
    />
    <path
      d="M25.4118 25.4117H39.5294L39.5294 67.7647H70.5882V70.5882H67.7647V73.4117L62.1176 73.4117V76.2353H56.4706V79.0588H39.5294V76.2353H33.8823V73.4117L28.2353 73.4117V70.5882L25.4118 70.5882V67.7647H22.5882V62.1176H19.7647V56.4706H16.9412V39.5294H19.7647V33.8823H22.5882V28.2353H25.4118V25.4117Z"
      fill="#FEE761"
    />
    <path
      d="M73.4118 62.1176V67.7647H56.4706V28.2353H28.2354V22.5882L33.8824 22.5882V19.7647H39.5295V16.9412H56.4706V19.7647H62.1177V22.5882L67.7648 22.5882V25.4118H70.5883V28.2353H73.4118V33.8823H76.2354V39.5294H79.0589V56.4706H76.2354V62.1176H73.4118Z"
      fill="#A55430"
    />
    <path
      d="M56.4705 19.7646H39.5294V22.5882H33.8823V25.4117H28.2352V28.2352H25.4117V33.8823H22.5882V39.5294H19.7646V56.4705H22.5882V62.1176H25.4117V67.7646H28.2352V70.5882H33.8823V73.4117H39.5294V76.2352H56.4705V73.4117H62.1176V70.5882H67.7646V67.7646H70.5882V62.1176H73.4117V56.4705H76.2352V39.5294H73.4117V33.8823H70.5882V28.2352H67.7646V25.4117H62.1176V22.5882H56.4705V19.7646Z"
      fill="#F77622"
    />
    <path d="M62.1176 22.5883H59.2941V25.4118H62.1176V22.5883Z" fill="#FA932B" />
    <path
      d="M56.4706 25.4118H67.7647V28.2353H70.5883V33.8824H67.7647V36.7059H64.9412V39.5295H62.1177V42.353H59.2941V45.1765L56.4706 45.1765V48H53.6471V50.8236H50.8235V53.6471L48 53.6471V56.4706H45.1765V59.2942H42.353V62.1177H39.5294V64.9412L36.7059 64.9412V67.7647H33.8824V70.5883H28.2353V67.7647H25.4118V62.1177H22.5883V59.2942H25.4118V56.4706H28.2353V53.6471L31.0588 53.6471V50.8236H33.8824V48H36.7059V45.1765L39.5294 45.1765V42.353H42.353V39.5295H45.1765V36.7059H48V33.8824H50.8236V31.0589H53.6471V28.2353H56.4706V25.4118Z"
      fill="#FA932B"
    />
    <path
      d="M36.7059 22.5883H33.8824V25.4118L28.2353 25.4118V28.2353H25.4118V33.8824H22.5883V36.7059H25.4118L25.4118 33.8824H28.2353V31.0588H31.0588V28.2353H33.8824V25.4118H36.7059V22.5883Z"
      fill="#FA932B"
    />
    <path
      d="M59.2941 73.4117H62.1176V70.5882H67.7647V67.7647H70.5882V62.1176H73.4117V59.2941H70.5882V62.1176H67.7647V64.9411H64.9411V67.7647H62.1176V70.5882H59.2941V73.4117Z"
      fill="#FA932B"
    />
    <path
      d="M36.7059 33.8823H33.8824V36.7059H31.0589V39.5294H28.2354V45.1764H31.0589V48H33.8824V50.8235H36.7059V53.647H39.5295V56.4706H42.353V70.5882H53.6471V56.4706H56.4706V53.647H59.2942V50.8235H62.1177V48H64.9412V45.1764H67.7648V39.5294H64.9412V36.7059H62.1177V33.8823H59.2942V36.7059H56.4706V39.5294H50.8236V42.3529H45.1765V39.5294H39.5295V36.7059H36.7059V33.8823Z"
      fill="#A55430"
    />
    <path
      d="M36.7059 31.0588H33.8824V33.8824H31.0589V36.7059H28.2354V42.353H31.0589V45.1765H33.8824V48H36.7059V50.8235H39.5295V53.6471H42.353V67.7647H53.6471V53.6471H56.4706V50.8235H59.2942V48H62.1177V45.1765H64.9412V42.353H67.7648V36.7059H64.9412V33.8824H62.1177V31.0588H59.2942V33.8824H56.4706V36.7059H53.6471V39.5294H42.353V36.7059H39.5295V33.8824H36.7059V31.0588Z"
      fill="#FEAE34"
    />
    <path d="M36.7059 31.0588H39.5295L39.5294 33.8824H36.7059V31.0588Z" fill="#FEE761" />
    <path d="M42.3529 36.7059H39.5294V33.8824L42.3529 33.8824V36.7059Z" fill="#FEE761" />
    <path d="M42.3529 36.7059L45.1765 36.7059V39.5294H42.3529L42.3529 36.7059Z" fill="#FEE761" />
    <path
      d="M62.1176 31.0588H59.2941V33.8824H62.1176L62.1176 36.7059H64.9412V39.5294H67.7647V36.7059L64.9412 36.7059V33.8824L62.1176 33.8824V31.0588Z"
      fill="#FEE761"
    />
    <path
      d="M59.2942 31.0588H56.4706V33.8824L53.6471 33.8824V36.7059L50.8236 36.7059V39.5294L42.353 39.5294V42.353L39.5295 42.353V45.1765H36.7059V50.8235H39.5295V53.6471L42.353 53.6471V59.2941H45.1765V56.4706H48.0001V53.6471L50.8236 53.6471V50.8235H53.6471V48H56.4706V45.1765H59.2942V42.353L62.1177 42.353V39.5294L64.9412 39.5294V36.7059L62.1177 36.7059V33.8824L59.2942 33.8824V31.0588Z"
      fill="#FEC744"
    />
  </Icon>
);

export default YieldToken;
