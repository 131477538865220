import Icon, { IconProps } from "@chakra-ui/icon";

const Favorite = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4504 5.99997C23.9212 5.99873 25.3306 6.58986 26.3604 7.63997C28.546 9.8588 28.546 13.4211 26.3604 15.64L16.0004 26.13L5.64043 15.64C3.45481 13.4211 3.45481 9.8588 5.64043 7.63997C6.67084 6.59066 8.07977 5.99954 9.55043 5.99954C11.0211 5.99954 12.43 6.59066 13.4604 7.63997L16.0004 10.24L18.5304 7.65997C19.5574 6.59713 20.9725 5.99785 22.4504 5.99997ZM22.4508 4C20.442 3.9983 18.5172 4.80571 17.1108 6.24L16.0008 7.36L14.8908 6.24C13.4827 4.80838 11.5588 4.0021 9.55075 4.0021C7.54273 4.0021 5.6188 4.80838 4.21075 6.24C1.26048 9.24334 1.26048 14.0567 4.21075 17.06L16.0008 29L27.7908 17.06C30.741 14.0567 30.741 9.24334 27.7908 6.24C26.383 4.80761 24.4591 4.00055 22.4508 4Z"
      fill="currentColor"
    />
  </Icon>
);

export default Favorite;
