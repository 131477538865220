import { useToken } from "@chakra-ui/react";

export const RoundStatsChartStyles = () => {
  const [moon, doom, ui02] = useToken("colors", ["moondoom-moon", "moondoom-doom", "ui-02"]);

  return (
    <style jsx global>
      {`
        .round-entry-header-chart .highcharts-pie-series .highcharts-point {
          stroke: none;
        }
        .round-entry-header-chart .highcharts-empty-series {
          stroke: none;
          fill: ${ui02};
        }
        .round-entry-header-chart .doomRatio {
          fill: ${doom};
        }
        .round-entry-header-chart .moonRatio {
          fill: ${moon};
        }
      `}
    </style>
  );
};
