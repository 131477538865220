import { type Address } from "viem";
import { useAccount } from "wagmi";
import { Box, Flex, HStack, Skeleton, type StackProps } from "@chakra-ui/react";
import { CheckmarkOutlineIcon, NextLink, Text } from "@looksrare/uikit";
import { formatToSignificant, type BigIntish, isAddressEqual } from "@looksrare/utils";
import type { PtbPlayer, PtbSupportedNetwork } from "..";
import { getRoundUrl } from "../utils";
import { CurrencyTokenIcon } from "./CurrencyTokenIcon";
import { PlayersRemaining } from "./PlayersRemaining";

interface CaveListItemProps {
  isActive: boolean;
  caveOnChainId: BigIntish;
  roundOnChainId?: BigIntish;
  name: string;
  currency: Address;
  network: PtbSupportedNetwork;
  enterAmount: BigIntish;
  players?: PtbPlayer[];
  playersPerRound: number;
}

export const CaveListItem = ({
  isActive,
  caveOnChainId,
  roundOnChainId,
  name,
  currency,
  network,
  enterAmount,
  players = [],
  playersPerRound,
}: CaveListItemProps) => {
  const { address } = useAccount();

  const playerCount = players.length;
  const isParticipating = players.some((player) => isAddressEqual(player.user.address, address));

  return (
    <Flex alignItems="center" sx={{ ":last-of-type .cave-name": { borderBottom: 0 } }}>
      <Flex width={10} alignItems="center" justifyContent="center">
        {isParticipating && <CheckmarkOutlineIcon boxSize={6} color="link-01" />}
      </Flex>
      <Box borderBottom="1px solid" borderBottomColor="border-01" py={0.5} flex={1} className="cave-name">
        <HStack
          as={NextLink}
          href={getRoundUrl({ caveOnChainId, network, roundOnChainId })}
          py={1.5}
          px={2}
          spacing={2}
          borderRadius="button"
          bg={isActive ? "ui-bg" : "transparent"}
          sx={{ _hover: { bg: "ui-bg" }, _active: { bg: "onclick-ui" } }}
          cursor="pointer"
        >
          {(() => {
            if (isActive) {
              return (
                <Text textStyle="detail" color="link-01" noOfLines={1} flex={1}>
                  {name}
                </Text>
              );
            }
            return (
              <>
                <CurrencyTokenIcon network={network} currency={currency} boxSize={5} />
                <Text flex={1} bold>
                  {formatToSignificant(enterAmount, 6)}
                </Text>
              </>
            );
          })()}
          <PlayersRemaining playerCount={playerCount} playersPerRound={playersPerRound} />
        </HStack>
      </Box>
    </Flex>
  );
};

export const CaveListItemSkeleton = (props: StackProps) => (
  <HStack spacing={2} px={2} height={10} {...props}>
    <Skeleton height={5} width={5} borderRadius="circular" speed={0} startColor="text-03" />
    <Skeleton height={4} speed={0} startColor="text-03" flex={1} />
    <Skeleton height={4} width={8} speed={0} startColor="text-03" />
    <Skeleton height={5} width={5} speed={0} startColor="text-03" borderRadius="circular" />
  </HStack>
);
