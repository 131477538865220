import { ModalBody, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button } from "../../Button";
import { LaunchOpenInNewIcon, Link } from "../../..";

export const BlastView = () => {
  const { t } = useTranslation();

  return (
    <ModalBody>
      <Stack spacing={2}>
        <Button
          as={Link}
          href="https://app.thruster.finance/?token1=0x0000000000000000000000000000000000000000&token2=0xf77dd21c5ce38ac08786BE35Ef1d1DeC1a6a15F3"
          rightIcon={<LaunchOpenInNewIcon />}
          width="100%"
          isExternal
          colorScheme="brand"
        >
          {t("Buy {{currency}}", { currency: "YOLO" })}
        </Button>
        <Button as={Link} href="https://blast.io/bridge" rightIcon={<LaunchOpenInNewIcon />} width="100%" isExternal>
          {t("Move Funds to Blast")}
        </Button>
      </Stack>
    </ModalBody>
  );
};
