import { type ImageProps } from "next/image";

/**
 * Check if an src string is a valid url before passing to NextImage
 */
export const isValidImageSrc = (src: ImageProps["src"]) => {
  if (typeof src === "string") {
    if (src.startsWith("/images/")) {
      // Images starting with /images/ are local and are therefore assumed valid
      return true;
    }

    try {
      return !!new URL(src.toString());
    } catch (e) {
      return false;
    }
  }
  return true; // `StaticImport` src type is presumed to be valid
};
