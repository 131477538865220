import { Box, BoxProps } from "@chakra-ui/react";
import { Accordion, AccordionLabel, Link, Text, TextProps } from "@looksrare/uikit";
import { Trans, useTranslation } from "next-i18next";

export const FAQ = (props: BoxProps) => {
  const { t } = useTranslation();
  const accordionProps = {
    buttonProps: {
      borderRadius: "none",
      borderBottomLeftRadius: "none",
      borderBottomRightRadius: "none",
      _hover: {
        borderRadius: "none",
        borderBottomLeftRadius: "none",
        borderBottomRightRadius: "none",
        bg: "hover-interactive-02",
      },
      _active: {
        bg: "onclick-interactive-02",
      },
    },
    panelProps: {
      borderRadius: "none",
    },
  };

  const textProps: TextProps = {
    textStyle: "detail",
    color: "text-03",
    align: "left",
    pb: 2,
  };

  return (
    <Box borderRadius="container" overflow="hidden" maxWidth="768px" width="100%" {...props}>
      <Accordion
        leftButtonElement={<AccordionLabel>{t("ptb::What is Poke the Bear?")}</AccordionLabel>}
        {...accordionProps}
      >
        <Text {...textProps}>{t("ptb::The latest and greatest on-chain game from LooksRare!")}</Text>
        <Text {...textProps}>
          {t(
            "ptb::In Poke the Bear, you'll join other players as you venture into the lair of the titular beast. Take a seat, and wait for your turn to poke him with a stick. It may not bother him — but it may just wake him up, and cause him to maul the person responsible."
          )}
        </Text>
        <Text {...textProps}>
          {t(
            "ptb::If that happens, the player's entry fee is distributed among the survivors (minus LooksRare's 5% fee)."
          )}
        </Text>
      </Accordion>
      <Accordion
        leftButtonElement={<AccordionLabel>{t("ptb::Is it provably fair?")}</AccordionLabel>}
        {...accordionProps}
      >
        <Text {...textProps}>{t("ptb::You know it.")}</Text>
        <Text {...textProps}>
          <Trans i18nKey="ptbFaqProvablyFair" ns="ptb">
            Once again, we&apos;ve built on top of Chainlink&apos;s randomness to bring you a game with cryptographic
            fairness baked in. Check out the contracts{" "}
            <Link href="https://docs.chain.link/vrf/v2/introduction" isExternal>
              here
            </Link>
            . The Poke the Bear contract is an alpha release, and we will audit the contract after the alpha period.
          </Trans>
        </Text>
      </Accordion>

      <Accordion
        leftButtonElement={<AccordionLabel>{t("ptb::How much is the entry fee?")}</AccordionLabel>}
        {...accordionProps}
      >
        <Text {...textProps}>
          {t(
            "ptb::Again, that depends on the cave. Depending on the venue, you'll pay your entry fee either in LOOKS or in ETH."
          )}
        </Text>
      </Accordion>
    </Box>
  );
};
