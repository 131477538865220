import { type ReactNode, createContext, useState, useContext, useEffect } from "react";
import { useAccount } from "wagmi";
import WidgetBotOptions from "@widgetbot/crate/dist/types/options";
import {
  getChainIdFromSupportedNetwork,
  getSupportedNetworkFromChainId,
  type SupportedNetwork,
} from "@looksrare/utils";
import { ChainId, type GameAddresses } from "@looksrare/config";

export interface GameContext<TGameConfig> {
  supportedNetworks: SupportedNetwork[];
  addresses: Record<number, GameAddresses>;
  fallbackChainId: number;
  widgetBotOptions: WidgetBotOptions;
  config?: TGameConfig;
}

export const GameProviderContext = createContext<GameContext<unknown> | undefined>(undefined);

export const GameProvider = <T,>({
  supportedNetworks,
  addresses,
  fallbackChainId,
  widgetBotOptions,
  config,
  children,
}: GameContext<T> & { children: ReactNode }) => {
  const [state, setState] = useState<GameContext<T>>(() => ({
    supportedNetworks,
    addresses,
    fallbackChainId,
    widgetBotOptions,
    config,
  }));

  // Allow game config to be updated during runtime
  useEffect(() => {
    setState((prevState) => ({ ...prevState, config }));
  }, [config, setState]);

  return <GameProviderContext.Provider value={state}>{children}</GameProviderContext.Provider>;
};

export const useGameSupportedNetworks = () => {
  const context = useContext(GameProviderContext);
  if (!context) {
    throw new Error("No supported networks found");
  }
  return context.supportedNetworks;
};

export const useWidgetBotOptions = () => {
  const context = useContext(GameProviderContext);
  if (!context) {
    throw new Error("No supported widgetbot config found");
  }
  return context.widgetBotOptions;
};

export const useGameAddresses = (network?: SupportedNetwork) => {
  const context = useContext(GameProviderContext);
  const { chain } = useAccount();
  if (!context) {
    throw new Error("No addresses found");
  }

  if (!!network) {
    const addresses = context.addresses[getChainIdFromSupportedNetwork(network)];
    // Only return if addresses was found
    if (!!addresses) {
      return addresses;
    }
  }

  if (!!chain) {
    if (context.supportedNetworks.includes(getSupportedNetworkFromChainId(chain.id))) {
      return context.addresses[chain.id as ChainId];
    }
  }

  return context.addresses[context.fallbackChainId];
};

export const useGameFallbackChainId = () => {
  const context = useContext(GameProviderContext);
  if (!context) {
    throw new Error("No fallback chain id found");
  }
  return context.fallbackChainId;
};
