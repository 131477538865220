import { Icon, IconProps } from "../Icon";

const BlurMono = (props?: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 15.1401V3H12.6757L15.1179 5.27686V8.34486L14.4214 9.17129L15.1179 9.92343V12.9301L12.6683 15.2051L3 15.142V15.1401ZM11.6116 12.7129L12.5067 11.8864V10.8836L12.0053 10.4676H5.60929V12.6776L11.6097 12.7147L11.6116 12.7129ZM11.9589 7.98457L12.5067 7.47386V6.32057L11.606 5.483H5.61114V7.98457H11.9589ZM26.5634 16.7986L29 19.0773V22.3124L28.1884 23.1481L29 23.8873V28.8644H26.3889V24.8493L25.8874 24.4314H19.4951V28.8663H16.884V16.7986H26.5634ZM25.49 19.2797H19.4951V21.954H25.8429L26.3889 21.4433V20.1191L25.49 19.2797ZM28.5636 15.2033V12.7203H20.6429L19.4914 11.8214V3.00371H16.8821V12.9951L19.7087 15.2033H28.5636ZM11.2977 26.517L12.4009 25.6311V16.8023H15.012V26.7826L12.2523 29H5.79314L3 26.7863V16.8023H5.61114V25.6237L6.73843 26.517H11.2977Z"
      fill="currentColor"
    />
  </Icon>
);

export default BlurMono;
