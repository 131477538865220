import { Flex } from "@chakra-ui/react";
import { SingleClaimPanel } from "@looksrare/poke-the-bear";

export const BottomClaimPanel = () => (
  <Flex
    justifyContent="center"
    alignItems="center"
    position="fixed"
    bottom={0}
    left={{ base: 0, md: "80px" }}
    width={{ base: "100%", md: "calc(100% - 80px)" }}
    zIndex="sticky"
    borderTop="1px solid"
    borderTopColor="border-01"
    bg="ui-bg-translucent"
    backdropFilter="blur(8px)"
    height={18}
  >
    <SingleClaimPanel network="blast" border={0} mt={0} bg="none" />
  </Flex>
);
