import { useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Address } from "viem";
import { usePublicClient } from "wagmi";
import { ChainId, ETH_AVG_BLOCK_TIME_MS, Erc20Abi } from "@looksrare/config";
import { RQueryOptions } from "../types";
import { NoPublicClientError } from "..";

export type TokenBalanceQueryOptions = RQueryOptions<bigint>;

export const BASE_TOKEN_BALANCE_KEY = ["token-balance"]; // @TODO migration - remove export once all uses are updated

export const useTokenBalance = ({
  contractAddress,
  address,
  chainId,
  queryOptions,
}: {
  contractAddress: Address;
  address: Address;
  chainId?: ChainId;
  queryOptions?: TokenBalanceQueryOptions;
}) => {
  const publicClient = usePublicClient({ chainId });

  const balanceOf = async (): Promise<bigint> => {
    if (!publicClient) {
      throw new NoPublicClientError();
    }
    const balance = await publicClient.readContract({
      address: contractAddress,
      abi: Erc20Abi,
      functionName: "balanceOf",
      args: [address],
    });
    return balance;
  };

  return useQuery({
    queryKey: [BASE_TOKEN_BALANCE_KEY, contractAddress, address],
    queryFn: () => balanceOf(),
    refetchInterval: ETH_AVG_BLOCK_TIME_MS * 3,
    ...queryOptions,
  });
};

export const useInvalidateTokenBalance = () => {
  const queryClient = useQueryClient();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: BASE_TOKEN_BALANCE_KEY });
  }, [queryClient]);
};
