import { Chain } from "viem";
import { useRef } from "react";
import { useAccount, usePublicClient } from "wagmi";
import { QueryOptions, useQuery } from "@tanstack/react-query";

interface Options extends QueryOptions<bigint | undefined> {
  onBlock?: (blockNumber: bigint) => void;
  watch?: boolean;
  chain?: Chain;
}

/**
 * Returns the latest block number for the given chain.
 * Uses the default wagmi transport instead of alchemy to avoid consuming massive API credits
 */
export const useBlockNumber = ({ chain: chainArg, watch, onBlock, ...options }: Options = {}) => {
  const { chain: chainInUse } = useAccount();
  const chain = chainArg ?? chainInUse;
  const client = usePublicClient();

  const lastSeenBlockNumber = useRef<bigint | undefined>();

  const { data: blockNumber } = useQuery({
    queryKey: ["block-number", chain],
    queryFn: async () => {
      const currentBlockNumber = await client?.getBlockNumber();

      if (currentBlockNumber && currentBlockNumber !== lastSeenBlockNumber.current) {
        onBlock?.(currentBlockNumber);
      }
      lastSeenBlockNumber.current = currentBlockNumber;

      return currentBlockNumber;
    },
    ...options,
    refetchInterval: watch === false ? Infinity : 2000,
  });

  return blockNumber;
};
