import { Icon, IconProps } from "../Icon";

const Warning = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.14614 28.5205C2.32806 28.8189 2.65258 29.0007 3.00208 29H29.0021C29.3516 29.0007 29.6761 28.8189 29.858 28.5205C30.0399 28.2221 30.0529 27.8503 29.8921 27.54L16.8921 2.54001C16.721 2.20604 16.3773 1.99597 16.0021 1.99597C15.6268 1.99597 15.2832 2.20604 15.1121 2.54001L2.11208 27.54C1.95131 27.8503 1.96422 28.2221 2.14614 28.5205ZM16.0018 5.16999L27.3518 27H4.65176L16.0018 5.16999ZM16 22C15.1716 22 14.5 22.6716 14.5 23.5C14.5 24.3284 15.1716 25 16 25C16.8284 25 17.5 24.3284 17.5 23.5C17.5 22.6716 16.8284 22 16 22ZM17 11H15V20H17V11Z"
      fill="currentColor"
    />
  </Icon>
);

export default Warning;
