import { useEffect } from "react";
import { Box, HStack, VStack } from "@chakra-ui/react";
import { EthTokenIcon, Text, useThrowConfettiFromCenter } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import { formatToSignificant, useSoundEffect } from "@looksrare/utils";
import { MoDRoundFragment } from "@looksrare/yolo-games-gql-typegen";
import { Ufo } from "../../../components/Ufo/Ufo";
import { getMoDResultColor } from "../../../utils/getMoDResultColor";
import { getUfoStateByResult } from "../utils/getUfoState";
import { useMoDConfig } from "../../../config";

interface MoDRoundEndProps {
  isCurrentRoundClosed: boolean;
  isRoundTransitioning: boolean;
  onClickSnooze: () => void;
  previousRound?: MoDRoundFragment | null;
}

export const MoDRoundEnd = ({
  isCurrentRoundClosed,
  isRoundTransitioning,
  onClickSnooze,
  previousRound,
}: MoDRoundEndProps) => {
  const { t } = useTranslation();

  const { isConnected } = useAccount();
  const { isMuted } = useMoDConfig();
  const throwConfetti = useThrowConfettiFromCenter();

  const previousRoundResult = previousRound?.result?.result;
  const userEntry = previousRound?.entries?.[0];
  const isMoon = previousRoundResult === "MOON";
  const hasUserWon =
    (userEntry?.moonPosition && isMoon) || (userEntry?.moonPosition === false && previousRoundResult === "DOOM");

  const { play: playEndSound, stop: stopEndSound } = useSoundEffect({
    path: `/sounds/moon-or-doom/${isMoon ? "moon" : "doom"}.mp3`,
    volume: 1,
    isMuted,
  });

  useEffect(() => {
    if (!isCurrentRoundClosed && isRoundTransitioning && !!previousRoundResult) {
      playEndSound({ restartIfAlreadyPlaying: false });
    } else {
      stopEndSound();
    }
  }, [isCurrentRoundClosed, isRoundTransitioning, playEndSound, previousRoundResult, stopEndSound]);

  useEffect(() => {
    if (!isCurrentRoundClosed && isRoundTransitioning && hasUserWon) {
      throwConfetti();
    }
  }, [hasUserWon, isCurrentRoundClosed, isRoundTransitioning, throwConfetti]);

  const title = (() => {
    if (isCurrentRoundClosed) {
      return undefined;
    }

    return t("Round ended");
  })();

  const resultText = (() => {
    if (isCurrentRoundClosed) {
      return `${t("mod::Calculating Result...")}`;
    }

    switch (previousRoundResult) {
      case "MOON":
        return t("mod::MOON");
      case "DOOM":
        return t("mod::DOOM");
      case "HOUSE":
        return t("mod::No Change");
    }
  })();

  const userResultText = (() => {
    if (!isConnected || !userEntry) {
      return "";
    }

    if (hasUserWon) {
      return t("mod::You won!");
    }

    return t("mod::Unlucky...");
  })();

  const userResultAmount = (() => {
    if (!isConnected || !userEntry) {
      return "";
    }

    if (hasUserWon) {
      return `+${userEntry.payoutAmount ? formatToSignificant(userEntry.payoutAmount, 3) : "0"}`;
    }

    return `-${formatToSignificant(userEntry.amount, 3)}`;
  })();

  const userResultColor = userEntry?.moonPosition && previousRoundResult === "MOON" ? "link-01" : "text-03";

  const resultColor =
    !isCurrentRoundClosed && previousRoundResult ? getMoDResultColor(previousRoundResult) : "acid.200";
  const resultUfoState =
    !isCurrentRoundClosed && previousRoundResult ? getUfoStateByResult(previousRoundResult) : "loading";

  return (
    <>
      <Box
        onClick={onClickSnooze}
        position="fixed"
        inset={0}
        pointerEvents={isRoundTransitioning ? "all" : "none"}
        zIndex="overlay"
      />
      <VStack
        spacing={8}
        p={8}
        position="fixed"
        top="50%"
        left="50%"
        width="calc(100vw - 32px)"
        maxWidth="512px"
        backgroundColor="ui-glass"
        borderRadius="dialog"
        backdropFilter="blur(10px)"
        opacity={isRoundTransitioning ? 1 : 0}
        transform={isRoundTransitioning ? "translate(-50%, -50%)" : "translate(-50%, calc(-50% - 25px))"}
        transition="opacity 500ms, transform 500ms"
        pointerEvents="none"
        zIndex={1}
      >
        <Ufo state={resultUfoState} height="192px" width="192px" />

        <VStack position="relative" spacing={2}>
          {!!title && (
            <Text textStyle="body" textAlign="center" color="text-01">
              {title}
            </Text>
          )}

          <Text
            textStyle={isCurrentRoundClosed ? "display-03" : "display-01"}
            textAlign="center"
            color={resultColor}
            bold
          >
            {resultText}
          </Text>
        </VStack>

        {isConnected && userEntry && !isCurrentRoundClosed && (
          <VStack spacing={0} p={2} pt={4} borderRadius="container" backgroundColor="ui-01" width="100%">
            <Text textStyle="display-03" textAlign="center" color={userResultColor} bold>
              {userResultText}
            </Text>

            <HStack spacing={2}>
              <Text textStyle="heading-03" textAlign="center" color={userResultColor} bold>
                {userResultAmount}
              </Text>
              <EthTokenIcon boxSize={5} />
            </HStack>
          </VStack>
        )}
      </VStack>
    </>
  );
};
