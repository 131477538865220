import { Trans } from "next-i18next";
import { PtbRoundLogType } from "../../../../types";
import { LogText, LogTextHighlight } from "./LogText";
import type { LogTextProps, RoundLogTextGetter } from "./types";

const RoundRevealedText1 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.roundRevealed1">
      As the bear&apos;s distracted, the remaining players grab the loot{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      dropped, and run like f$%^!
    </Trans>
  </LogText>
);

export const roundRevealedLogs: RoundLogTextGetter = ({ user1 }) => [
  <RoundRevealedText1 key={`${PtbRoundLogType.ROUND_REVEALED}1`} user1={user1} />,
];
