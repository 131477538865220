import { Address } from "viem";
import { STORAGE_NAMESPACE } from "@looksrare/config";

export const getStorageKey = (key: string, address?: Address) => {
  const baseKey = `${STORAGE_NAMESPACE}_${key}`;
  if (!!address) {
    return `${baseKey}_${address}`;
  }
  return baseKey;
};
