import { Icon, IconProps } from "../Icon";

const ThreeDimensional = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00049 9.86663C4.00016 9.88106 4 9.89551 4 9.90997V22.09C4 22.79 4.37 23.45 4.98 23.81L15 29.734L15.0024 29.7328C15.3068 29.9047 15.6487 30 16 30C16.36 30 16.71 29.9 17.02 29.72L27.02 23.81C27.62 23.45 28 22.79 28 22.09V9.90997C28 9.89549 27.9998 9.88103 27.9995 9.86658L28.0199 9.85415L27.9979 9.81787C27.967 9.1525 27.6032 8.53417 27.02 8.19L26.9997 8.17799L26.9801 8.14575L26.9629 8.15622L17 2.26604L16.9976 2.26718C16.6932 2.09528 16.3513 2 16 2C15.64 2 15.29 2.10003 14.98 2.28003L5.03712 8.15625L5.01987 8.14575L5.00021 8.17806L4.98 8.19C4.40646 8.53413 4.03394 9.15236 4.0022 9.81764L3.97998 9.85415L4.00049 9.86663ZM6 22.09L15 27.409V16.562L6 11.0837V22.09ZM6.97327 9.33477L16 4L25.0267 9.33475L16 14.8293L6.97327 9.33477ZM26 22.09L17 27.409V16.5619L26 11.0837V22.09Z"
      fill="currentColor"
    />
  </Icon>
);

export default ThreeDimensional;
