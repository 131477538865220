import { useTranslation } from "next-i18next";
import { PtbRoundLogType } from "../../../types";
import { LogTextHighlight } from "./LogText";
import type { LogTextProps, RoundLogTextGetter } from "./types";

const RoundDrawingText1 = (props: LogTextProps) => {
  const { t } = useTranslation(["ptb"]);
  return <LogTextHighlight {...props}>{t("ptb::commentary.roundDrawing1")}</LogTextHighlight>;
};

export const roundDrawingLogs: RoundLogTextGetter = () => [
  <RoundDrawingText1 key={`${PtbRoundLogType.ROUND_DRAWING}1`} />,
];
