/**
 * List of supported chains
 */
export enum ChainId {
  /**
   * YoloGames
   */
  BLAST_SEPOLIA = 168587773,
  BLAST_MAINNET = 81457,

  /**
   * LooksRare
   */
  MAINNET = 1,
  GOERLI = 5,
  SEPOLIA = 11155111,
  HARDHAT = 31337,
  ARB_MAINNET = 42161,
  ARB_SEPOLIA = 421614,
}

/** ChainInfo data used to interact with LooksRare ecosystem */
export interface ChainInfo {
  label: string;
  appUrl: string;
  rpcUrl: string;
  explorer: string;
  baseApiUrl: string;
  osApiUrl: string;
  cdnUrl: string;
  rewardsSubgraphUrl: string;
  cloudinaryUrl: string;
  wsUrl: string;
}

export const chainInfo: { [chainId in ChainId]: ChainInfo } = {
  /**
   * YoloGames
   */
  [ChainId.BLAST_SEPOLIA]: {
    appUrl: "https://sepolia.yologames.io",
    baseApiUrl: "https://graphql-sepolia.yologames.io",
    // Currently Unknown for Blast YoloGames
    cdnUrl: "https://static-sepolia.looksnice.org",
    cloudinaryUrl: "https://looksrare.mo.cloudinary.net/sepolia",
    explorer: "https://testnet.blastscan.io",
    label: "Blast Sepolia",
    osApiUrl: "", // N/A
    rewardsSubgraphUrl: "", // N/A
    rpcUrl: "https://sepolia.blast.io",
    wsUrl: "wss://ws-sepolia.yologames.io/ws",
  },
  [ChainId.BLAST_MAINNET]: {
    appUrl: "https://yologames.io",
    baseApiUrl: "https://graphql.yologames.io",
    // For potential backwards compatibility of asset urls
    // use looksnice. Important to note this should not actually be called.
    cdnUrl: "https://static.looksnice.org",
    cloudinaryUrl: "https://looksrare.mo.cloudinary.net",
    explorer: "https://blastscan.io",
    label: "Blast",
    osApiUrl: "", // N/A
    rewardsSubgraphUrl: "", // N/A
    rpcUrl: "https://rpc.blast.io",
    wsUrl: "wss://ws.yologames.io/ws",
  },

  /**
   * LooksRare
   */
  [ChainId.MAINNET]: {
    appUrl: "https://looksrare.org",
    baseApiUrl: "https://graphql.looksrare.org",
    cdnUrl: "https://static.looksnice.org",
    cloudinaryUrl: "https://looksrare.mo.cloudinary.net",
    explorer: "https://etherscan.io",
    label: "Ethereum",
    osApiUrl: "https://api.opensea.io",
    rewardsSubgraphUrl: "https://api.thegraph.com/subgraphs/name/looksrare/looks-distribution",
    rpcUrl: "https://eth-mainnet.g.alchemy.com/v2",
    wsUrl: "wss://ws.looksrare.org/ws",
  },
  [ChainId.GOERLI]: {
    appUrl: "https://goerli.looksrare.org",
    baseApiUrl: "https://graphql-goerli.looksrare.org",
    cdnUrl: "https://static-goerli.looksnice.org",
    cloudinaryUrl: "https://looksrare.mo.cloudinary.net/goerli",
    explorer: "https://goerli.etherscan.io",
    label: "Goerli",
    osApiUrl: "https://testnets-api.opensea.io",
    rewardsSubgraphUrl: "https://api.thegraph.com/subgraphs/name/0xjurassicpunk/looks-distribution",
    rpcUrl: "https://eth-goerli.g.alchemy.com/v2",
    wsUrl: "wss://ws-goerli.looksrare.org/ws",
  },
  [ChainId.SEPOLIA]: {
    appUrl: "https://sepolia.looksrare.org",
    baseApiUrl: "https://graphql-sepolia.looksrare.org",
    cdnUrl: "https://static-sepolia.looksnice.org",
    cloudinaryUrl: "https://looksrare.mo.cloudinary.net/sepolia",
    explorer: "https://sepolia.etherscan.io",
    label: "Sepolia",
    osApiUrl: "https://testnets-api.opensea.io",
    rewardsSubgraphUrl: "https://api.thegraph.com/subgraphs/name/0xjurassicpunk/looks-distribution",
    rpcUrl: "https://eth-sepolia.g.alchemy.com/v2",
    wsUrl: "wss://ws-sepolia.looksrare.org/ws",
  },
  [ChainId.ARB_MAINNET]: {
    appUrl: "https://looksrare.org",
    baseApiUrl: "https://graphql.looksrare.org",
    cdnUrl: "https://static.looksnice.org",
    cloudinaryUrl: "https://looksrare.mo.cloudinary.net",
    explorer: "https://arbiscan.io",
    label: "Arbitrum",
    osApiUrl: "https://api.opensea.io",
    rewardsSubgraphUrl: "https://api.thegraph.com/subgraphs/name/looksrare/looks-distribution",
    rpcUrl: "https://arb-mainnet.g.alchemy.com/v2",
    wsUrl: "wss://ws.looksrare.org/ws",
  },
  [ChainId.ARB_SEPOLIA]: {
    appUrl: "https://sepolia.looksrare.org",
    baseApiUrl: "https://graphql-sepolia.looksrare.org",
    cdnUrl: "https://static-sepolia.looksnice.org",
    cloudinaryUrl: "https://looksrare.mo.cloudinary.net/sepolia",
    explorer: "https://sepolia.arbiscan.io",
    label: "Arbitrum Sepolia",
    osApiUrl: "https://testnets-api.opensea.io",
    rewardsSubgraphUrl: "https://api.thegraph.com/subgraphs/name/0xjurassicpunk/looks-distribution",
    rpcUrl: "https://arb-sepolia.g.alchemy.com/v2",
    wsUrl: "wss://ws-sepolia.looksrare.org/ws",
  },
  [ChainId.HARDHAT]: {
    appUrl: "http://localhost:3000",
    baseApiUrl: "http://localhost:4000",
    cdnUrl: "https://via.placeholder.com",
    cloudinaryUrl: "",
    explorer: "https://etherscan.io",
    label: "Hardhat",
    osApiUrl: "https://testnets-api.opensea.io",
    rewardsSubgraphUrl: "https://api.thegraph.com/subgraphs/name/0xjurassicpunk/looks-distribution",
    rpcUrl: "http://127.0.0.1:8545",
    wsUrl: "ws://localhost:5001/ws",
  },
};
