import { FC, PropsWithChildren } from "react";
import { Box } from "@chakra-ui/react";
import { useAccount } from "wagmi";
import { useTranslation } from "next-i18next";
import { ConnectWalletButton } from "../Button";
import { Text } from "../Text";

export const ConnectWalletModalGuard: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { t } = useTranslation();
  const { address } = useAccount();

  if (!address) {
    return (
      <Box px={4} py={8}>
        <Text as="h1" textStyle="heading-03" bold mb={4}>
          {t("Connect a Wallet")}
        </Text>
        <Text textStyle="detail" color="text-02" mb={6}>
          {t("LooksRare works great with Metamask, Coinbase Wallet, and most other Ethereum wallets.")}
        </Text>
        <ConnectWalletButton />
      </Box>
    );
  }

  return <>{children}</>;
};
