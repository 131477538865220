import { type NavButtonType } from "./NavButton";

interface NavItem {
  type: NavButtonType;
  pathnames?: string[];
  pathMatch?: "exact" | "includes";
  hasSubMenu?: boolean;
  isComingSoon?: boolean;
}

export const navItems: NavItem[] = [
  {
    type: "home",
    pathnames: ["/"],
    pathMatch: "exact",
  },
  {
    type: "games",
    pathnames: ["/poke-the-bear", "/moon-or-doom", "/yolo"],
    hasSubMenu: true,
  },
  { type: "rewards", pathnames: ["/rewards", "/points", "/points/leaderboard"] },
  { type: "rakeback", pathnames: ["/rakeback"] },
  { type: "lottery", pathnames: ["/lottery", "/lottery/history"] },
  { type: "gold-rush", pathnames: ["/gold-rush"] },
  { type: "liquidity", pathnames: ["/liquidity"] },
  { type: "settings", pathnames: ["/settings", "/setting/profile", "/settings/notifications"] },
];
