import { type SupportedNetwork, getChainIdFromSupportedNetwork } from "@looksrare/utils";
import { chainIconMap } from "../constants";
import { IconProps } from "./Icons";

interface ChainIconProps extends IconProps {
  network: SupportedNetwork;
}

export const ChainIcon = ({ network, ...props }: ChainIconProps) => {
  const chainIdFromNetwork = getChainIdFromSupportedNetwork(network);
  const Icon = chainIconMap[chainIdFromNetwork];

  return <Icon {...props} />;
};
