import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const WethToken = (props: IconProps) => {
  const id = uniqueId();
  return (
    <Icon viewBox="0 0 96 96" {...props}>
      <g clipPath={`url(#${id})`}>
        <circle cx="48" cy="48" r="48" fill="#EEDADA" />
        <path
          d="M47.9926 13.7997L47.5337 15.3585V60.5875L47.9926 61.0454L68.987 48.6354L47.9926 13.7997Z"
          fill="#DF5960"
        />
        <path d="M47.9949 13.7997L27 48.6354L47.9949 61.0454V39.0925V13.7997Z" fill="#EE9398" />
        <path
          d="M47.9929 65.0208L47.7343 65.3362V81.447L47.9929 82.202L68.9995 52.6177L47.9929 65.0208Z"
          fill="#DF5960"
        />
        <path d="M47.9943 82.2019V65.0207L27 52.6175L47.9943 82.2019Z" fill="#EE9398" />
        <path d="M47.9949 61.043L68.9886 48.6334L47.9949 39.0907V61.043Z" fill="#CF373E" />
        <path d="M27 48.6337L47.9943 61.0433V39.091L27 48.6337Z" fill="#DF5960" />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default WethToken;
