import { Icon, IconProps } from "../Icon";

const RecentlyViewed = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.59 22L15 16.41V7H17V15.58L22 20.59L20.59 22ZM16 2C12.232 2.00053 8.62465 3.52643 6 6.23V2H4V10H12V8H7.08C10.951 3.69843 17.3405 2.77224 22.2736 5.79764C27.2067 8.82304 29.2779 14.9381 27.1987 20.3386C25.1195 25.7391 19.4823 28.8864 13.794 27.8226C8.10572 26.7588 3.98694 21.7869 4 16H2C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2Z"
      fill="currentColor"
    />
  </Icon>
);

export default RecentlyViewed;
