import { Icon, IconProps } from "../Icon";

const CartAddToCart = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 1V10.1715L20.585 6.58655L21.9992 8.00076L16 13.9999V14L16 14L16 14V13.9999L10.0008 8.00076L11.415 6.58655L15 10.1715V1H17ZM28 6.99996H27.3095L26 12.5L25.9745 12.5007L25.1997 16H7.61969L7.01959 12.9995L7 13L5 3C4.90353 2.52688 4.48276 1.99023 4 1.99996H0V3.99996H3.18L7 23.2C7.09647 23.6731 7.51724 24.0097 8 24H26V22H8.82L8 18H26C26.4767 18.0116 26.8952 17.6851 27 17.22L29 8.21996C29.0679 7.9193 28.9934 7.60412 28.798 7.36573C28.6026 7.12734 28.3081 6.99239 28 6.99996ZM12 27.9999C12 29.1045 11.1046 29.9999 10 29.9999C8.89543 29.9999 8 29.1045 8 27.9999C8 26.8954 8.89543 25.9999 10 25.9999C11.1046 25.9999 12 26.8954 12 27.9999ZM26 27.9999C26 29.1045 25.1046 29.9999 24 29.9999C22.8954 29.9999 22 29.1045 22 27.9999C22 26.8954 22.8954 25.9999 24 25.9999C25.1046 25.9999 26 26.8954 26 27.9999Z"
      fill="currentColor"
    />
  </Icon>
);

export default CartAddToCart;
