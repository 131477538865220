import { useColorModeValue } from "@chakra-ui/react";

type BoxShadowPreset = "card" | "card-hover" | "popover";
const presets: Record<BoxShadowPreset, { light: string; dark: string }> = {
  card: { light: "shadow-card-light", dark: "shadow-card-dark" },
  "card-hover": { light: "shadow-card-light-hover", dark: "shadow-card-dark-hover" },
  popover: { light: "shadow-popover-light", dark: "shadow-popover-dark" },
};
export const useBoxShadowPreset = (preset: BoxShadowPreset) => {
  const boxShadow = useColorModeValue(presets[preset].light, presets[preset].dark);
  return boxShadow;
};
