import { useQuery, useQueries, QueriesResults } from "@tanstack/react-query";
import { usePublicClient } from "wagmi";
import { Address, PublicClient } from "viem";
import { PriceOracleAbi, WEI_PER_ETHER } from "@looksrare/config";
import {
  BigIntish,
  RQueryOptions,
  divideWeiByWei,
  multiplyWeiByNumber,
  useAddressesByNetwork,
  useCurrentChainId,
} from "@looksrare/utils";

//@TODO rename file to `useTwap`

export const defaultTwapWindow = 3_600;

const getTokenEthTwap = async (
  publicClient: PublicClient,
  oracleAddress: Address,
  tokenAddress: Address,
  twapWindow = defaultTwapWindow
): Promise<bigint> => {
  const twap = await publicClient.readContract({
    address: oracleAddress,
    abi: PriceOracleAbi,
    functionName: "getTWAP",
    args: [tokenAddress, twapWindow],
  });
  return twap;
};

export const useTokenEthTwap = (
  erc20Address: Address,
  twapWindow = defaultTwapWindow,
  options?: RQueryOptions<BigIntish>
) => {
  const publicClient = usePublicClient();
  const chainId = useCurrentChainId();
  const addressesByNetwork = useAddressesByNetwork();
  return useQuery({
    queryKey: ["twap", chainId, erc20Address],
    queryFn: async () => {
      if (!publicClient) {
        throw new Error("No public client found");
      }
      return getTokenEthTwap(publicClient, addressesByNetwork.ERC_20_PRICE_ORACLE, erc20Address, twapWindow);
    },
    gcTime: 1000 * 60 * 5, // 5 minutes
    ...options,
  });
};

export const useMultipleTokenEthTwap = (
  erc20Addresses: Address[],
  twapWindow = defaultTwapWindow,
  options?: RQueryOptions<bigint[]>
): QueriesResults<bigint[]> => {
  const publicClient = usePublicClient();
  const chainId = useCurrentChainId();
  const addressesByNetwork = useAddressesByNetwork();

  return useQueries({
    queries: erc20Addresses.map((erc20Address) => ({
      queryKey: ["twap", chainId, erc20Address],
      queryFn: async () => {
        if (!publicClient) {
          throw new Error("No public client found");
        }
        return getTokenEthTwap(publicClient, addressesByNetwork.ERC_20_PRICE_ORACLE, erc20Address, twapWindow);
      },
      options,
    })),
  });
};

/**
 * Converts a TWAP to a USD price
 * @param twap
 * @param ethPriceUsd - ether price (not WEI) in USD
 */
export const twapToUsdPrice = (twap: bigint, ethPriceUsd: number) =>
  divideWeiByWei(multiplyWeiByNumber(twap, ethPriceUsd), WEI_PER_ETHER);

/**
 * Retreive LOOKS TWAP against ETH
 */
export const useLooksTwap = (options?: RQueryOptions<BigIntish>) => {
  return useTokenEthTwap(useAddressesByNetwork().LOOKS, defaultTwapWindow, options);
};
