import { Icon, IconProps } from "../Icon";

const TicketRedeemables = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29 14C29.5523 14 30 13.5523 30 13V8C30 6.89543 29.1046 6 28 6H4C2.89543 6 2 6.89543 2 8V13C2 13.5523 2.44772 14 3 14C4.10457 14 5 14.8954 5 16C5 17.1046 4.10457 18 3 18C2.44772 18 2 18.4477 2 19V24C2 25.1046 2.89543 26 4 26H28C29.1046 26 30 25.1046 30 24V19C30 18.4477 29.5523 18 29 18C27.8954 18 27 17.1046 27 16C27 14.8954 27.8954 14 29 14ZM19 13H21V19H19V13ZM28 24V19.87C26.2396 19.4099 25.0115 17.8196 25.0115 16C25.0115 14.1804 26.2396 12.5901 28 12.13V8H21V11H19V8H4V12.13C5.76044 12.5901 6.98852 14.1804 6.98852 16C6.98852 17.8196 5.76044 19.4099 4 19.87V24H19V21H21V24H28Z"
      fill="currentColor"
    />
  </Icon>
);

export default TicketRedeemables;
