import dynamic from "next/dynamic";
import {
  Drawer,
  DrawerOverlay,
  Flex,
  FlexProps,
  IconButton,
  Skeleton,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { usePreviousValue } from "@looksrare/utils";
import { HamburgerIcon, NextLink, YoloGamesGrayscaleIcon } from "@looksrare/uikit";
import { InviteButton, MuteButton } from "@/components";
import { SideNavMobile } from "@/components/Layout";

const WalletBalance = dynamic(() => import("./WalletBalance").then((module) => module.WalletBalance), {
  ssr: false,
  loading: () => <Skeleton height={10} width={10} />,
});
const ProfileNavButton = dynamic(() => import("./ProfileNavButton").then((module) => module.ProfileNavButton), {
  ssr: false,
  loading: () => <Skeleton height={10} width={10} />,
});

export const TopNavMobile = (props: FlexProps) => {
  const sideNavDrawerDisclosure = useDisclosure();

  // Close the drawer when the route changes
  const router = useRouter();
  const previousPathname = usePreviousValue(router.pathname);
  const isMobileWidth = useBreakpointValue({ base: true, lg: false });
  useEffect(() => {
    if (!isMobileWidth || previousPathname !== router.pathname) {
      sideNavDrawerDisclosure.onClose();
    }
  }, [router.pathname, previousPathname, sideNavDrawerDisclosure, isMobileWidth]);

  return (
    <>
      <Flex py={3} px={4} alignItems="center" bg="ui-bg" borderBottom="1px solid" borderColor="border-01" {...props}>
        <Flex width="100%" justifyContent="space-between">
          <Flex flexGrow={1} alignItems="center" gap={3}>
            <IconButton
              variant="outline"
              colorScheme="secondary"
              size="sm"
              aria-label="back"
              onClick={sideNavDrawerDisclosure.onOpen}
            >
              <HamburgerIcon />
            </IconButton>
            <Flex justifyContent="center" alignItems="center" width={10} height={10}>
              <NextLink href="/">
                <YoloGamesGrayscaleIcon boxSize={10} />
              </NextLink>
            </Flex>
          </Flex>

          <Flex gap={2}>
            <InviteButton borderColor="acid.200" />
            <WalletBalance isMobile />
            <ProfileNavButton isMobile />
            <MuteButton aria-label="mute-button" size="sm" variant="outline" colorScheme="secondary" />
          </Flex>
        </Flex>
      </Flex>
      <Drawer blockScrollOnMount closeOnEsc closeOnOverlayClick {...sideNavDrawerDisclosure}>
        <SideNavMobile
          onClose={sideNavDrawerDisclosure.onClose}
          visibility={sideNavDrawerDisclosure.isOpen ? "visible" : "hidden"}
          height="100dvh"
          position="fixed"
          top={0}
          left={0}
          zIndex="popover"
        />
        <DrawerOverlay onClick={sideNavDrawerDisclosure.onClose} zIndex="overlay" pointerEvents="none" />
      </Drawer>
    </>
  );
};
