import { getSupportedNetworkFromChainId } from "@looksrare/utils";
import { ChainId } from "@looksrare/config";
import { AnimatedSwitcher } from "@looksrare/uikit";
import { MoDAssetPair } from "@looksrare/yolo-games-gql-typegen";
import { getMoDContractNameByNetworkPair } from "../../utils/transformContract";
import { AssetPairData } from "./components/AssetPairData";

interface AssetSwitcherProps {
  chainId: ChainId;
  assetPairs: MoDAssetPair[];
  selectedPair: MoDAssetPair;
  onClickAssetPair?: () => void;
}

export const AssetSwitcher = ({ chainId, assetPairs, selectedPair, onClickAssetPair }: AssetSwitcherProps) => {
  const selectedIndex = assetPairs.indexOf(selectedPair);

  return (
    <AnimatedSwitcher
      selectedIndex={selectedIndex}
      spacing={2}
      p={1}
      backgroundColor="ui-bg"
      borderColor="ui-02"
      borderRadius="container"
      selectorProps={{
        borderRadius: "button",
        backgroundColor: "ui-02",
      }}
    >
      {assetPairs.map((assetPair) => (
        <AssetPairData
          key={assetPair}
          onClick={onClickAssetPair}
          chainId={chainId}
          contract={getMoDContractNameByNetworkPair(getSupportedNetworkFromChainId(chainId), assetPair)}
          assetPair={assetPair}
          isSelected={assetPair === selectedPair}
          zIndex={1}
          width="100%"
        />
      ))}
    </AnimatedSwitcher>
  );
};
