import type { LiquidityPoolContract } from "@looksrare/yolo-games-gql-typegen";

interface LPConfig {
  displayDecimals: number;
  textColor: string;
}

export const lpConfig: Record<LiquidityPoolContract, LPConfig> = {
  GAMES_ETH_LP_V1_BLAST: {
    displayDecimals: 4,
    textColor: "purple.500",
  },
  GAMES_YOLO_LP_V1_BLAST: {
    displayDecimals: 2,
    textColor: "acid.400",
  },
};
