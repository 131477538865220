import { useMemo, useState } from "react";
import { useElapsedTime } from "use-elapsed-time";
import { CircularCountdown, Text } from "@looksrare/uikit";
import { Spinner, type FlexProps } from "@chakra-ui/react";
import { pokeDurSec } from "../../../config";

interface PokeCountdownProps extends FlexProps {
  isPokingUntil: string | null;
}

export const PokeCountdown = ({ isPokingUntil, ...props }: PokeCountdownProps) => {
  const [pending, setIsPending] = useState(false);
  const pokingDur = useMemo(() => {
    return isPokingUntil ? Math.floor((new Date(isPokingUntil).getTime() - Date.now()) / 1_000) : pokeDurSec;
  }, [isPokingUntil]);
  const { elapsedTime } = useElapsedTime({
    isPlaying: !!isPokingUntil,
    duration: pokingDur,
    onComplete: () => setIsPending(true),
  });
  const countdown = pokingDur - elapsedTime;

  return (
    <CircularCountdown
      gradientProfile="yellow"
      strokeWidth="2px"
      duration={pokingDur}
      countdown={countdown}
      size={32}
      p={1}
      {...props}
    >
      {/* @todo-ptb figure out what to do if the we don't recent an event after the countdown */}
      {pending ? (
        <Spinner height={4} width={4} color="#F8CC32" speed="0.5s" />
      ) : (
        <Text
          textStyle="detail"
          bold
          background="linear-gradient(85.93deg, #F8CC32 61.04%, #DF6D04 100%)"
          sx={{ "-webkit-text-fill-color": "transparent", "-webkit-background-clip": "text" }}
        >
          {Math.round(countdown)}
        </Text>
      )}
    </CircularCountdown>
  );
};
