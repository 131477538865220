import { Icon, IconProps } from "../Icon";

const PauseFilled = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6H10C8.89543 6 8 6.89543 8 8V24C8 25.1046 8.89543 26 10 26H12C13.1046 26 14 25.1046 14 24V8C14 6.89543 13.1046 6 12 6ZM22 6H20C18.8954 6 18 6.89543 18 8V24C18 25.1046 18.8954 26 20 26H22C23.1046 26 24 25.1046 24 24V8C24 6.89543 23.1046 6 22 6Z"
      fill="currentColor"
    />
  </Icon>
);

export default PauseFilled;
