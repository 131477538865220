import { useAccount } from "wagmi";
import { Modal, type ModalProps } from "@looksrare/uikit";
import { PtbSupportedNetwork } from "../../../types";
import { useInvalidateUnclaimedPtbFunds } from "../../../hooks";
import type { ClaimFundsSuccessHandler } from "../types";
import { ClaimFunds } from "./ClaimFunds";

type ClaimFundsModalProps = Omit<ModalProps, "children"> & {
  network: PtbSupportedNetwork;
  onSuccess?: ClaimFundsSuccessHandler;
};

export const ClaimFundsModal = ({ isOpen, onClose, network, onSuccess }: ClaimFundsModalProps) => {
  const { address } = useAccount();
  const invalidateUnclaimedPtbFunds = useInvalidateUnclaimedPtbFunds();

  const handleCloseModal = () => {
    onClose();
    // invalidate the queries related to claimable funds
    invalidateUnclaimedPtbFunds({ address, network });
  };
  return (
    <Modal isOpen={isOpen} onClose={handleCloseModal}>
      <ClaimFunds network={network} onClose={handleCloseModal} onSuccess={onSuccess} />
    </Modal>
  );
};
