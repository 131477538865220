import { useTranslation } from "next-i18next";
import { PtbRoundLogType } from "../../../../types";
import { LogText } from "./LogText";
import type { LogTextProps, RoundLogTextGetter } from "./types";

const RoundDrawnText1 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.roundDrawn1")}</LogText>;
};

const RoundDrawnText2 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.roundDrawn2")}</LogText>;
};

const RoundDrawnText3 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.roundDrawn3")}</LogText>;
};

const RoundDrawnText4 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.roundDrawn4")}</LogText>;
};

const RoundDrawnText5 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.roundDrawn5")}</LogText>;
};

const RoundDrawnText6 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.roundDrawn6")}</LogText>;
};

const RoundDrawnText7 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.roundDrawn7")}</LogText>;
};

const RoundDrawnText8 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.roundDrawn8")}</LogText>;
};

const RoundDrawnText9 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.roundDrawn9")}</LogText>;
};

export const roundDrawnLogs: RoundLogTextGetter = () => [
  <RoundDrawnText1 key={`${PtbRoundLogType.ROUND_DRAWN}1`} />,
  <RoundDrawnText2 key={`${PtbRoundLogType.ROUND_DRAWN}2`} />,
  <RoundDrawnText3 key={`${PtbRoundLogType.ROUND_DRAWN}3`} />,
  <RoundDrawnText4 key={`${PtbRoundLogType.ROUND_DRAWN}4`} />,
  <RoundDrawnText5 key={`${PtbRoundLogType.ROUND_DRAWN}5`} />,
  <RoundDrawnText6 key={`${PtbRoundLogType.ROUND_DRAWN}6`} />,
  <RoundDrawnText7 key={`${PtbRoundLogType.ROUND_DRAWN}7`} />,
  <RoundDrawnText8 key={`${PtbRoundLogType.ROUND_DRAWN}8`} />,
  <RoundDrawnText9 key={`${PtbRoundLogType.ROUND_DRAWN}9`} />,
];
