import { Box, BoxProps } from "@chakra-ui/react";
import { FC, PropsWithChildren } from "react";

export const PTBPageContent: FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Box py={{ base: 0, md: 4 }} px={{ base: 0, md: 8 }} {...props}>
      {children}
    </Box>
  );
};
