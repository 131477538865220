import { Icon, IconProps } from "../Icon";

const ChevronSort: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 28L9 21L10.41 19.59L16 25.17L21.59 19.59L23 21L16 28ZM16 4L23 11L21.59 12.41L16 6.83L10.41 12.41L9 11L16 4Z"
      fill="currentColor"
    />
  </Icon>
);

export default ChevronSort;
