import { Icon, IconProps } from "../Icon";

const GoldBigPrize = (props: IconProps) => (
  <Icon viewBox="0 0 128 128" {...props}>
    <g clipPath="url(#clip0_9786_146032)">
      <g filter="url(#filter0_d_9786_146032)">
        <path
          d="M109.603 33.4235C106.754 40.3766 101.458 44.7851 95.8301 45.6948L88.9661 46.3895C80.6572 41.3418 75.089 25.9415 79.5137 15.1441C81.3409 10.6855 86.7185 4.81805 90 2.70366L95.8647 2.70366C98.1433 2.77354 100.424 3.4296 102.57 4.73342C110.879 9.78112 114.028 22.6261 109.603 33.4235Z"
          fill="url(#paint0_linear_9786_146032)"
        />
      </g>
      <g clipPath="url(#clip1_9786_146032)">
        <path
          d="M103.634 33.4298C99.1781 44.3042 89.0199 48.943 81.0292 44.0886C73.0384 39.2342 69.974 26.5626 74.4303 15.6882C78.8866 4.81375 89.0448 0.174912 97.0355 5.02931C105.026 9.8837 108.091 22.5553 103.634 33.4298Z"
          fill="url(#paint1_linear_9786_146032)"
          stroke="#AAA758"
          strokeWidth="0.944178"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M94.1895 16.3847L99.5629 19.933L99.2229 28.9275L84.9301 33.4752L77.3402 23.7258L83.5381 16.6566L94.1895 16.3847ZM93.8217 17.9926L85.5583 18.2035L88.6777 21.3009L96.826 19.9765L93.8217 17.9926ZM98.2662 21.3414L88.8893 22.8654L86.1696 31.4595L98.0264 27.6869L98.2662 21.3414ZM84.9524 31.1614L87.7139 22.4351L83.7404 18.4898L79.1571 23.7173L84.9524 31.1614Z"
          fill="#201F19"
        />
      </g>
      <g filter="url(#filter1_d_9786_146032)">
        <path
          d="M116.357 108.052C110.019 118.057 101.439 123.303 93.738 122.959L84.5019 121.889C75.5338 111.362 74.0388 85.465 83.8807 69.9284C87.9449 63.5127 94.9789 55.9624 100.064 53.6796L109.934 55.5672C112.887 56.3935 115.623 58.1395 117.939 60.8588C126.907 71.3864 126.199 92.5156 116.357 108.052Z"
          fill="url(#paint2_linear_9786_146032)"
        />
      </g>
      <g clipPath="url(#clip2_9786_146032)">
        <path
          d="M109.061 106.682C104.131 114.465 97.8436 119.345 91.6783 120.959C85.5134 122.574 79.4953 120.922 75.0448 115.697C70.5942 110.473 68.5091 102.612 68.7758 94.0739C69.0425 85.535 71.6635 76.3518 76.5939 68.5686C81.5244 60.7853 87.8116 55.9059 93.9768 54.2913C100.142 52.6767 106.16 54.3286 110.61 59.5531C115.061 64.7777 117.146 72.6381 116.879 81.1766C116.613 89.7155 113.992 98.8987 109.061 106.682Z"
          fill="url(#paint3_linear_9786_146032)"
          stroke="#AAA758"
          strokeWidth="0.944178"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M102.599 76.5884L108.419 83.9859L104.717 97.9571L84.1795 100.186L77.6714 82.3169L88.4206 73.3669L102.599 76.5884ZM101.531 78.9808L90.5317 76.4816L93.5353 82.401L104.786 83.1169L101.531 78.9808ZM106.196 85.7477L93.2493 84.9239L86.5467 97.4532L103.584 95.6043L106.196 85.7477ZM85.0455 96.5694L91.8512 83.8475L88.0251 76.3074L80.0764 82.9257L85.0455 96.5694Z"
          fill="#201F19"
        />
      </g>
      <g filter="url(#filter2_d_9786_146032)">
        <path
          d="M9.93305 73.1746C3.14551 60.2172 2.7577 47.8143 7.7683 39.8106L14.4748 30.6186C31.0498 27.5686 59.4162 42.0085 69.9564 62.1297C74.3088 70.4386 78.073 82.6188 77.4264 89.4579L69.4703 98.8204C66.8125 101.46 63.3107 103.297 59.0294 104.085C42.4544 107.135 20.4732 93.2959 9.93305 73.1746Z"
          fill="url(#paint4_linear_9786_146032)"
        />
      </g>
      <g clipPath="url(#clip3_9786_146032)">
        <path
          d="M76.0624 55.0442C81.3662 65.1691 82.7402 74.9486 80.6967 82.5714C78.6531 90.1941 73.2034 95.6363 64.8799 97.1679C56.5565 98.6995 46.8646 96.0435 37.8965 90.4471C28.9282 84.8506 20.7158 76.3294 15.412 66.2045C10.1082 56.0795 8.7342 46.3 10.7778 38.6772C12.8213 31.0545 18.271 25.6123 26.5945 24.0807C34.9179 22.5491 44.6098 25.2051 53.5779 30.8015C62.5462 36.3981 70.7586 44.9192 76.0624 55.0442Z"
          fill="url(#paint5_linear_9786_146032)"
          stroke="#AAA758"
          strokeWidth="0.944178"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.9265 43.3197L51.3637 41.6765L64.0045 54.3027L53.8575 77.6705L30.8347 73.5566L27.8458 56.4998L39.9265 43.3197ZM41.8267 45.9461L32.4543 56.1713L40.5979 56.6266L48.2214 45.0273L41.8267 45.9461ZM51.8867 45.1491L43.1137 58.4972L52.387 73.4418L60.8046 54.0567L51.8867 45.1491ZM50.5292 74.5008L41.1133 59.3265L30.7401 58.7465L32.9504 71.3597L50.5292 74.5008Z"
          fill="#201F19"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_9786_146032"
        x="73.6379"
        y="-1.61214"
        width="42.2848"
        height="52.318"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.15812" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9786_146032" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9786_146032" result="shape" />
      </filter>
      <filter
        id="filter1_d_9786_146032"
        x="72.799"
        y="49.3634"
        width="55.7448"
        height="77.9284"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.15812" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9786_146032" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9786_146032" result="shape" />
      </filter>
      <filter
        id="filter2_d_9786_146032"
        x="0.0577798"
        y="25.8957"
        width="81.7575"
        height="82.9264"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.15812" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9786_146032" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9786_146032" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_9786_146032"
        x1="102.57"
        y1="4.73342"
        x2="80.4184"
        y2="41.1967"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="#E6BF33" />
        <stop offset="1" stopColor="#B7B10F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9786_146032"
        x1="74.0268"
        y1="15.4431"
        x2="107.435"
        y2="24.4181"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF61F" />
        <stop offset="0.627629" stopColor="#FCFDC4" />
        <stop offset="1" stopColor="#FDF972" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_9786_146032"
        x1="117.939"
        y1="60.8588"
        x2="68.4356"
        y2="103.029"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="#E6BF33" />
        <stop offset="1" stopColor="#B7B10F" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_9786_146032"
        x1="76.2878"
        y1="68.2092"
        x2="119.409"
        y2="89.1722"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF61F" />
        <stop offset="0.627629" stopColor="#FCFDC4" />
        <stop offset="1" stopColor="#FDF972" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_9786_146032"
        x1="59.0294"
        y1="104.085"
        x2="44.4943"
        y2="25.0947"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0001" stopColor="#E6BF33" />
        <stop offset="1" stopColor="#B7B10F" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_9786_146032"
        x1="14.9477"
        y1="66.2899"
        x2="63.7448"
        y2="33.3501"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF61F" />
        <stop offset="0.627629" stopColor="#FCFDC4" />
        <stop offset="1" stopColor="#FDF972" />
      </linearGradient>
      <clipPath id="clip0_9786_146032">
        <rect width="128" height="128" fill="white" />
      </clipPath>
      <clipPath id="clip1_9786_146032">
        <rect
          width="35.115"
          height="43.156"
          fill="white"
          transform="matrix(0.85465 0.519204 -0.379191 0.925318 82.209 -4.52344)"
        />
      </clipPath>
      <clipPath id="clip2_9786_146032">
        <rect
          width="51.0117"
          height="67.4053"
          fill="white"
          transform="matrix(0.64847 0.76124 -0.535134 0.844767 94.3232 39.7383)"
        />
      </clipPath>
      <clipPath id="clip3_9786_146032">
        <rect
          width="62.6128"
          height="83.4518"
          fill="white"
          transform="matrix(0.983488 -0.180972 0.464023 0.885823 -4.41406 29.3281)"
        />
      </clipPath>
    </defs>
  </Icon>
);

export default GoldBigPrize;
