import { Box, HStack, VStack } from "@chakra-ui/react";
import { tabularNums } from "@looksrare/utils";
import { Text, TextProps } from "../Text";

interface RollingCounterProps {
  formattedValue: string;
  fontSize: TextProps["fontSize"];
  lineHeight: TextProps["lineHeight"];
  textProps?: Omit<TextProps, "lineHeight">;
  customDecimalComponent?: React.FC<React.PropsWithChildren<{ decimal: number | string }>>;
  animationDurationMs?: number;

  /**
   * Some fonts (like used in lottery prize pool) have different widths for
   * some decimals, so we need to allow for custom width calculation.
   */
  getDecimalWidth?: (decimal: string) => string;
}

export const RollingCounter = ({
  formattedValue,
  fontSize,
  lineHeight,
  textProps,
  customDecimalComponent: CustomDecimalComponent,
  animationDurationMs = 1000,
  getDecimalWidth,
}: RollingCounterProps) => {
  return (
    <HStack gap={0} overflow="hidden" margin={-1} padding={1}>
      {[...formattedValue].map((decimal, idx) => {
        if (isNaN(parseInt(decimal, 10))) {
          return (
            <VStack key={idx} height={lineHeight}>
              {(() => {
                if (CustomDecimalComponent) {
                  return <CustomDecimalComponent decimal={decimal} />;
                }

                return (
                  <Text bold {...textProps}>
                    {decimal}
                  </Text>
                );
              })()}
            </VStack>
          );
        }

        return (
          <Box key={idx} height={lineHeight} fontSize={fontSize}>
            <VStack
              /**
               * To animate a stack of 10 decimals, we create a vertical stack and translate it upwards based on the decimal to be displayed.
               * The translation is 10% times the decimal plus an adjustment for the 8px gap between decimals.
               * For example, for “5”, the translation is 50% (10% * 5) plus 4px ((8px / 10) * 5).
               * We negate the calculation for upward transformation.
               */
              transform={`translateY(calc(calc(10% + calc(8px / 10)) * ${decimal} * -1))`}
              transition={`transform ${animationDurationMs}ms ease, width ${animationDurationMs}ms ease`}
              width={getDecimalWidth?.(decimal)}
              sx={tabularNums}
            >
              {[...Array(10)].map((_, _decimal) => {
                if (CustomDecimalComponent) {
                  return <CustomDecimalComponent key={_decimal} decimal={_decimal} />;
                }

                return (
                  <Text key={_decimal} bold {...textProps}>
                    {_decimal}
                  </Text>
                );
              })}
            </VStack>
          </Box>
        );
      })}
    </HStack>
  );
};
