import { useCallback } from "react";
import { usePublicClient, useAccount } from "wagmi";
import { Address } from "viem";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ChainId } from "@looksrare/config";
import { RQueryOptions } from "../types";
import { BASE_TOKEN_BALANCE_KEY } from "./useTokenBalance";
import { useCurrentChainId } from "./useGlobalStore";

export const useEthBalance = (address: Address, queryOptions?: RQueryOptions<bigint>, chainId?: ChainId) => {
  const publicClient = usePublicClient({ chainId });
  const currentChainId = useCurrentChainId();

  return useQuery({
    queryKey: [BASE_TOKEN_BALANCE_KEY, address, chainId ?? currentChainId],
    queryFn: () => {
      if (!publicClient) {
        throw new Error("No public client found");
      }
      return publicClient.getBalance({ address });
    },
    refetchInterval: 60 * 1000,
    refetchOnWindowFocus: true,
    ...queryOptions,
  });
};

/**
 * After transactions that affect the balance, we need to invalidate the balance query to refetch the new balance.
 */
export const useInvalidateEthBalance = () => {
  const { address } = useAccount();
  const queryClient = useQueryClient();
  const currentChainId = useCurrentChainId();
  return useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [BASE_TOKEN_BALANCE_KEY, address, currentChainId] });
  }, [address, currentChainId, queryClient]);
};
