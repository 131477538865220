import { useEffect, useState } from "react";
import { intervalToDuration, Duration } from "date-fns";

export const useCountdown = (timestamp: number, isEnabled = true) => {
  const [duration, setDuration] = useState<Duration>();

  useEffect(() => {
    if (isEnabled) {
      const intervalId = setInterval(() => {
        const d = intervalToDuration({
          start: new Date().getTime(),
          end: timestamp,
        });

        if (!d.years && !d.weeks && !d.days && !d.hours && !d.minutes && !d.seconds) {
          setDuration({ seconds: 0 });
          clearInterval(intervalId);
        } else {
          setDuration(d);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [isEnabled, timestamp]);

  return duration;
};
