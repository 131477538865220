import { Icon, IconProps } from "../Icon";

const Watchlist = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4C5 2.89543 5.89543 2 7 2H25C26.1046 2 27 2.89543 27 4V28C27 29.1046 26.1046 30 25 30H7C5.89543 30 5 29.1046 5 28V4ZM25 4L7 4V28H25V4ZM22 10L10 10V8L22 8V10ZM22 16L10 16V14L22 14V16ZM17 22H10V20H17V22Z"
      fill="currentColor"
    />
  </Icon>
);

export default Watchlist;
