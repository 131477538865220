import { MouseEvent, useCallback, useRef, useState, VideoHTMLAttributes } from "react";
import { Box, BoxProps, IconButton, ResponsiveObject } from "@chakra-ui/react";
import noop from "lodash/noop";
import {
  CloudinaryLoaderProps,
  Format,
  getCloudinaryUrl,
  Quality,
  useBreakpointValueSsr,
  useCurrentChainInfo,
} from "@looksrare/utils";
import { PlayFilledAltIcon } from "../Icons";

/**
 * Return Cloudinary URL for resources delivered by our CDN.
 * For other resources - return the src
 * @returns string
 */
const getMediaSrc = (
  cdnUrl: string,
  cloudinaryUrl: string,
  { src, width, format, quality }: Omit<CloudinaryLoaderProps, "resourceType" | "baseCloudinaryUrl">
) => {
  if (cdnUrl && src.startsWith(cdnUrl)) {
    const relativeSrc = src.replace(cdnUrl, "");
    return getCloudinaryUrl({
      src: relativeSrc,
      baseCloudinaryUrl: cloudinaryUrl,
      width,
      format,
      quality,
      resourceType: "video",
    });
  }

  return src;
};

enum PlayState {
  PLAYING,
  PAUSED,
}

export interface VideoProps extends VideoHTMLAttributes<HTMLVideoElement> {
  src: string;
  sizes?: ResponsiveObject<number>;
  format?: Format;
  quality?: Quality;
  disableContainerOnClick?: boolean;
  containerProps?: BoxProps;
  preventClickEventBubbling?: boolean;
}

export const Video: React.FC<React.PropsWithChildren<VideoProps>> = ({
  src,
  sizes,
  format,
  quality = "auto",
  disableContainerOnClick,
  onError = noop,
  onPlay = noop,
  onPause = noop,
  onLoadedMetadata = noop,
  containerProps,
  preventClickEventBubbling = true,
  ...props
}) => {
  const [playState, setPlayState] = useState(PlayState.PAUSED);
  const videoElement = useRef<HTMLVideoElement>(null);
  const width = useBreakpointValueSsr({ ...sizes });
  const currentChainInfo = useCurrentChainInfo();

  const showPlay = !props.controls && playState !== PlayState.PLAYING;

  const mediaSrc = useCallback(() => {
    return getMediaSrc(currentChainInfo.cdnUrl, currentChainInfo.cloudinaryUrl, { src, width, quality, format });
  }, [src, width, quality, format, currentChainInfo])();

  const posterSrc = useCallback(() => {
    return getMediaSrc(currentChainInfo.cdnUrl, currentChainInfo.cloudinaryUrl, {
      src,
      width,
      quality,
      format: "webp",
    });
  }, [src, width, quality, currentChainInfo])();

  const handlePlayClick = (e: MouseEvent) => {
    if (preventClickEventBubbling) {
      e.stopPropagation(); // prevent triggering card link on click
      e.preventDefault(); // prevent triggering card link on click
    }
    videoElement.current?.play();
  };

  const handleOnPlay = () => {
    onPlay();
    setPlayState(PlayState.PLAYING);
  };

  const handleOnPause = () => {
    onPause();
    setPlayState(PlayState.PAUSED);
  };

  return (
    <>
      <Box
        position="relative"
        height="100%"
        onClick={disableContainerOnClick ? noop : (e) => handlePlayClick(e)}
        {...containerProps}
      >
        <video
          ref={videoElement}
          onPlay={handleOnPlay}
          onPause={handleOnPause}
          onLoadedMetadata={onLoadedMetadata}
          onError={onError}
          controls={props.controls || playState === PlayState.PLAYING}
          preload="metadata"
          style={{ borderRadius: "mini", height: "100%", width: "100%" }}
          poster={posterSrc}
          {...props}
        >
          <source src={mediaSrc} type="video/mp4" />
        </video>
        {showPlay && (
          <Box opacity={0.8} position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" zIndex="docked">
            <IconButton
              isRound
              isLoading={!videoElement.current}
              aria-label="play"
              colorScheme="gray"
              onClick={(e) => handlePlayClick(e)}
            >
              <PlayFilledAltIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </>
  );
};
