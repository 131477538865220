import { Accordion, type BoxProps, type AccordionProps } from "@chakra-ui/react";
import { useGameSupportedNetworks } from "@looksrare/uikit";
import { usePtbConfig } from "..";
import { CaveListAccordion } from "./CaveListAccordion";
import { HotCavesAccordion } from "./HotCavesAccordion";
import { CaveListPanel } from "./layout";

interface CavesAccordionProps extends BoxProps {
  accordionProps?: AccordionProps;
}

export const CavesAccordion = ({ accordionProps, ...props }: CavesAccordionProps) => {
  const supportedNetworks = useGameSupportedNetworks();
  const ptbConfig = usePtbConfig();

  return (
    <CaveListPanel {...props}>
      <Accordion allowToggle allowMultiple={false} {...accordionProps}>
        {!!ptbConfig.hotNetwork && <HotCavesAccordion network={ptbConfig.hotNetwork} />}
        {supportedNetworks.map((network) => (
          <CaveListAccordion key={network} network={network} />
        ))}
      </Accordion>
    </CaveListPanel>
  );
};
