import { Icon, IconProps } from "../Icon";

const PriceRange = (props: IconProps) => (
  <Icon viewBox="0 0 64 64" {...props}>
    <path
      d="M28 18C25.7909 18 24 19.7909 24 22V30C24 32.2091 25.7909 34 28 34H36V42H24V46H30V52H34V46H36C38.2091 46 40 44.2091 40 42V34C40 31.7909 38.2091 30 36 30H28V22H40V18H34V12H30V18H28Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 4C16.536 4 4 16.536 4 32C4 47.464 16.536 60 32 60C47.464 60 60 47.464 60 32C60 16.536 47.464 4 32 4ZM32 8C45.2548 8 56 18.7452 56 32C56 45.2548 45.2548 56 32 56C18.7452 56 8 45.2548 8 32C8 18.7452 18.7452 8 32 8Z"
      fill="currentColor"
    />
  </Icon>
);

export default PriceRange;
