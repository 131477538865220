import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { type BigIntish } from "@looksrare/utils";
import { useAccount } from "wagmi";
import { PtbSupportedNetwork } from "../types";
import { pokeTheBear } from "../graphql";

interface GameContext {
  caveOnChainId: BigIntish;
  roundOnChainId: BigIntish;
  network: PtbSupportedNetwork;
}

export const usePokeTheBear = (options?: UseMutationOptions<boolean, any, GameContext>) => {
  const { address } = useAccount();
  return useMutation<boolean, any, GameContext>({
    mutationFn: ({ caveOnChainId, roundOnChainId, network }) => {
      if (!address) {
        throw new Error("No wallet connected");
      }
      return pokeTheBear(address, caveOnChainId, roundOnChainId, network);
    },

    ...options,
  });
};
