import { useCallback } from "react";
import { type Address } from "viem";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { isAddressEqual, type BigIntish, divideWeiByNumber, type RQueryOptions } from "@looksrare/utils";
import { add } from "date-fns";
import { pokeDurSec } from "../config";
import {
  type PtbCave,
  type PtbPlayer,
  type RoundLogsReturn,
  type CaveLog,
  type CaveInfoReturn,
  PtbGraphQlLog,
  getRoundInfo,
} from "../graphql";
import { type PtbContractName, PtbRoundStatus } from "../types";
import { getRoundMeta, getPtbContractNameFromNetwork } from "../utils";
import { useGetCaveQueryParams } from "./useGetCaveQueryParams";
import { getRoundLogsKey } from "./useRoundLogs";
import { getPtbUserQuery } from "./usePtbUser";

type CaveInfoQueryOptions = RQueryOptions<CaveInfoReturn>;

const getActiveCaveRoundInfoKey = (
  caveOnChainId: BigIntish,
  contractName: PtbContractName,
  roundOnChainId?: BigIntish
) => ["ptb", "caveInfo", contractName, caveOnChainId, roundOnChainId];

/**
 * Returns the prize amount before fees and excluding the principal
 */
export const getPrizePerPlayer = ({ enterAmount, playersPerRound }: Partial<PtbCave>) => {
  if (!enterAmount || !playersPerRound) {
    return 0n;
  }
  return divideWeiByNumber(enterAmount, playersPerRound - 1);
};

export const useCaveRoundInfo = (
  {
    caveOnChainId,
    roundOnChainId,
    contract,
  }: { caveOnChainId: BigIntish; contract: PtbContractName; roundOnChainId?: BigIntish },
  options?: CaveInfoQueryOptions
) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: getActiveCaveRoundInfoKey(caveOnChainId, contract, roundOnChainId),
    queryFn: async () => {
      const response = await getRoundInfo(caveOnChainId, contract, roundOnChainId);

      // Update individual user cache
      response.players.forEach((ptbPlayer) => {
        queryClient.setQueryData(getPtbUserQuery(ptbPlayer.user.address), ptbPlayer.user);
      });

      return response;
    },
    ...options,
  });
};

/**
 * Current = the current open cave
 */
export const useCurrentCaveRoundInfo = (options?: CaveInfoQueryOptions) => {
  const { caveOnChainId, network } = useGetCaveQueryParams();
  return useCaveRoundInfo(
    { caveOnChainId, contract: getPtbContractNameFromNetwork(network) },
    {
      gcTime: 5 * 1_000,
      refetchInterval: 20 * 1_000,
      enabled: !!caveOnChainId && options?.enabled !== false,
      ...options,
    }
  );
};

/**
 * Active = the current cave and round being viewed
 */
export const useActiveCaveRoundInfo = (options?: CaveInfoQueryOptions) => {
  const { caveOnChainId, roundOnChainId, network } = useGetCaveQueryParams();
  return useCaveRoundInfo(
    { caveOnChainId, roundOnChainId, contract: getPtbContractNameFromNetwork(network) },
    {
      ...options,
      staleTime: 20 * 1_000,
      enabled: !!caveOnChainId && !!roundOnChainId && options?.enabled !== false,
    }
  );
};

/**
 * Cache setters for optimistic updates
 */
export const useUpdateCache = () => {
  const { caveOnChainId, roundOnChainId, network } = useGetCaveQueryParams();
  const caveQueryKey = getActiveCaveRoundInfoKey(caveOnChainId, getPtbContractNameFromNetwork(network), roundOnChainId);
  const roundLogQueryKey = getRoundLogsKey(caveOnChainId, roundOnChainId, getPtbContractNameFromNetwork(network));
  const queryClient = useQueryClient();
  return {
    playerJoined: useCallback(
      (playerAddress: Address) => {
        queryClient.setQueryData<CaveInfoReturn | undefined>(caveQueryKey, (currentData) => {
          if (currentData) {
            const newData = {
              ...currentData,
            };

            // Only add player if they are not already in the list (race condition) and the round hasn't filled up
            const hasJoined = newData.players.some((player) => isAddressEqual(player.user.address, playerAddress));
            if (!hasJoined && newData.players.length < newData.cave.playersPerRound) {
              newData.players = [
                ...newData.players,
                {
                  entryIndex: currentData.players.length + 1,
                  poke: null,
                  user: { address: playerAddress },
                  futureCommittedRoundsCount: 0,
                  claimed: null,
                  refunded: null,
                  lost: null,
                  // @todo-cloud see if we can find this data
                  gemsEarned: null,
                },
              ];
            }
            // Optimistically update the cutoff time if we are adding the first player
            if (newData.round && newData.players.length === 1) {
              newData.round.status = PtbRoundStatus.OPEN;
              newData.meta = { ...newData.meta, ...getRoundMeta(PtbRoundStatus.OPEN) };
            }

            return newData;
          }
          return currentData;
        });
      },
      [queryClient, caveQueryKey]
    ),
    playerRemoved: useCallback(
      (playerAddress: Address) => {
        queryClient.setQueryData<CaveInfoReturn | undefined>(caveQueryKey, (currentData) => {
          if (currentData) {
            const newData = {
              ...currentData,
            };

            newData.players = newData.players.filter((player) => !isAddressEqual(player.user.address, playerAddress));

            return newData;
          }
          return currentData;
        });
      },
      [queryClient, caveQueryKey]
    ),
    playerDied: useCallback(
      (playerAddress: Address, timestamp: string) => {
        queryClient.setQueryData<CaveInfoReturn | undefined>(caveQueryKey, (currentData) => {
          return currentData
            ? {
                ...currentData,
                players: currentData.players.map((player) => {
                  if (isAddressEqual(playerAddress, player.user.address)) {
                    return {
                      ...player,
                      lost: true,
                      poke: {
                        ...player.poke,
                        pokedAt: timestamp,
                        isPokingUntil: null,
                      },
                    } as PtbPlayer;
                  }
                  return player;
                }),
              }
            : currentData;
        });
      },
      [queryClient, caveQueryKey]
    ),
    playerSurvived: useCallback(
      (playerAddress: Address) => {
        queryClient.setQueryData<CaveInfoReturn | undefined>(caveQueryKey, (currentData) => {
          return currentData
            ? {
                ...currentData,
                players: currentData.players.map((player) => {
                  if (isAddressEqual(playerAddress, player.user.address)) {
                    return {
                      ...player,
                      lost: false,
                      poke: {
                        ...player.poke,
                        pokedAt: new Date().toISOString(),
                        isPokingUntil: null,
                      },
                    } as PtbPlayer;
                  }
                  return player;
                }),
              }
            : currentData;
        });
      },
      [queryClient, caveQueryKey]
    ),
    playerIsPoking: useCallback(
      (playerAddress: Address, pokeTimerStartedAt: Date) => {
        queryClient.setQueryData<CaveInfoReturn | undefined>(caveQueryKey, (currentData) => {
          return currentData
            ? {
                ...currentData,
                players: currentData.players.map((player) => {
                  const pokeData = isAddressEqual(playerAddress, player.user.address)
                    ? {
                        isPokingUntil: add(pokeTimerStartedAt, { seconds: pokeDurSec }).toISOString(),
                        pokedAt: null,
                      }
                    : { isPokingUntil: null };
                  return {
                    ...player,
                    lost: false,
                    poke: {
                      ...player.poke,
                      ...pokeData,
                    },
                  } as PtbPlayer;
                }),
              }
            : currentData;
        });
      },

      [queryClient, caveQueryKey]
    ),
    updateRoundLog: useCallback(
      (caveLog: CaveLog) => {
        queryClient.setQueryData<RoundLogsReturn | undefined>(roundLogQueryKey, (currentData) => {
          if (currentData) {
            // We want the log hydration and update process to be idempotent since
            // we are using a realtime subscription to update the cache. The realtime
            // data will provide the latest log on connection, which we may already have.
            // We can use a keyed map to display deduplicated logs.
            const logs = new Map<string, PtbGraphQlLog>();

            const keyFn = (type: string, address: string[]) => `${type}-${address.join(",") ?? "None"}`;

            // Backfill the existing graphql as baseline log data
            currentData.logs.forEach((log) => {
              const addresses = log.user?.map((x) => x.address) ?? [];
              logs.set(keyFn(log.type, addresses), log);
            });

            // Add latest realtime log to the map to hydrate
            logs.set(keyFn(caveLog.type, caveLog.user), {
              type: caveLog.type,
              timestamp: caveLog.timestamp,
              user: caveLog.user.map((userAddress) => ({
                address: userAddress,
              })),
            });

            return {
              ...currentData,
              logs: [...logs.values()],
            };
          }
        });
      },

      [queryClient, roundLogQueryKey]
    ),
    roundRevealed: useCallback(() => {
      queryClient.setQueryData<CaveInfoReturn | undefined>(caveQueryKey, (currentData) => {
        if (currentData && currentData.round) {
          return {
            ...currentData,
            round: {
              ...currentData.round,
              status: PtbRoundStatus.REVEALED,
            },
            meta: {
              ...currentData.meta,
              ...getRoundMeta(PtbRoundStatus.REVEALED),
            },
          };
        }
        return currentData;
      });
    }, [queryClient, caveQueryKey]),
    roundCanceled: useCallback(() => {
      queryClient.setQueryData<CaveInfoReturn | undefined>(caveQueryKey, (currentData) => {
        if (currentData && currentData.round) {
          return {
            ...currentData,
            round: {
              ...currentData.round,
              status: PtbRoundStatus.CANCELLED,
            },
            meta: {
              ...currentData.meta,
              ...getRoundMeta(PtbRoundStatus.CANCELLED),
            },
          };
        }
        return currentData;
      });
    }, [queryClient, caveQueryKey]),
    roundDrawn: useCallback(() => {
      queryClient.setQueryData<CaveInfoReturn | undefined>(caveQueryKey, (currentData) => {
        if (currentData && currentData.round) {
          return {
            ...currentData,
            round: {
              ...currentData.round,
              status: PtbRoundStatus.DRAWN,
            },
            meta: {
              ...currentData.meta,
              ...getRoundMeta(PtbRoundStatus.DRAWN),
            },
          };
        }
        return currentData;
      });
    }, [queryClient, caveQueryKey]),
  };
};

/**
 * Given a cave query return, determine if any player is poking
 */
export const usePokingPlayer = () => {
  const caveInfoQuery = useActiveCaveRoundInfo();
  return caveInfoQuery.data?.players.find((player) => !!player.poke?.isPokingUntil);
};

/**
 * Return the player who lost
 */
export const usePtbLoser = () => {
  const caveInfoQuery = useActiveCaveRoundInfo();
  return caveInfoQuery.data?.players.find((player) => !!player.lost);
};

/**
 * Get a players position from an address
 * NOTE: 0 means we did not find it in the cache
 */
export const useGetEntryIndexFromAddress = (playerAddress?: Address | null) => {
  const queryClient = useQueryClient();
  const { caveOnChainId, roundOnChainId, network } = useGetCaveQueryParams();
  const data = queryClient.getQueryData<CaveInfoReturn | undefined>(
    getActiveCaveRoundInfoKey(caveOnChainId, getPtbContractNameFromNetwork(network), roundOnChainId)
  );

  if (!playerAddress || !data) {
    return 0;
  }

  const foundPtbPlayer = data.players.find((ptbPlayer) => isAddressEqual(playerAddress, ptbPlayer.user.address));
  return foundPtbPlayer ? foundPtbPlayer.entryIndex : 0;
};

export const useGetRoundLoser = () => {
  const queryClient = useQueryClient();
  const { caveOnChainId, roundOnChainId, network } = useGetCaveQueryParams();
  const data = queryClient.getQueryData<CaveInfoReturn | undefined>(
    getActiveCaveRoundInfoKey(caveOnChainId, getPtbContractNameFromNetwork(network), roundOnChainId)
  );
  const ptbLoser = data && data.players.find((ptbPlayer) => ptbPlayer.lost);
  return ptbLoser || null;
};
