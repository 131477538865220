import { forwardRef } from "react";
import { Stack, type StackProps, HStack, Skeleton } from "@chakra-ui/react";
import times from "lodash/times";
import { useActiveRoundLogs, useScrollToBottom } from "../../../hooks";
import { RoundLog } from "./RoundLog";

const ContentBox = forwardRef<HTMLDivElement, StackProps>((props, ref) => (
  <Stack
    ref={ref}
    position="absolute"
    spacing={0}
    left={0}
    bottom={0}
    zIndex={3}
    height="136px"
    overflowY="auto"
    flex={1}
    p={4}
    sx={{
      scrollbarWidth: "none",
      "::-webkit-scrollbar": {
        display: "none",
      },
    }}
    width="100%"
    maxWidth="100%"
    {...props}
  />
));
ContentBox.displayName = "ContentBox";

export const RoundLogs = () => {
  const roundLogQuery = useActiveRoundLogs();
  const logsLength = !!roundLogQuery.data?.logs ? roundLogQuery.data.logs.length : 0;
  const nodeRef = useScrollToBottom(logsLength);

  if (roundLogQuery.isLoading) {
    return (
      <ContentBox>
        {times(5).map((n) => (
          <HStack key={n} mb={1}>
            <Skeleton height={3} width="10%" animation={0} />
            <Skeleton height={3} width="20%" animation={0} />
          </HStack>
        ))}
      </ContentBox>
    );
  }

  if (roundLogQuery.isSuccess) {
    const { caveOnChainId, roundOnChainId, logs } = roundLogQuery.data;
    return (
      <ContentBox ref={nodeRef}>
        {/* @todo-ptb older logs have the exact same timestamp so we need to get creative with the keys */}
        {logs.map((log, index) => {
          return (
            <RoundLog
              key={[caveOnChainId, roundOnChainId, log.type, log.timestamp, index].join("")}
              ptbGraphQlLog={log}
            />
          );
        })}
      </ContentBox>
    );
  }
  return null;
};
