import { Icon, IconProps } from "../Icon";

const DarkMode = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9999 3H14.7999C8.1389 4.10374 3.41023 10.0927 3.8817 16.828C4.35317 23.5633 9.87009 28.835 16.6199 29H17.1099C21.3726 29.0026 25.3691 26.9278 27.8199 23.44C28.0262 23.1348 28.048 22.7408 27.8766 22.4147C27.7051 22.0886 27.3683 21.8831 26.9999 21.88C22.9555 21.5163 19.3095 19.2948 17.1317 15.8676C14.9539 12.4403 14.4914 8.1959 15.8799 4.38C16.004 4.0788 15.9736 3.73602 15.7985 3.46134C15.6233 3.18666 15.3253 3.01452 14.9999 3Z"
      fill="currentColor"
    />
  </Icon>
);

export default DarkMode;
