import { gql } from "graphql-request";
import { graphql } from "@looksrare/utils";

export const getLoginMessage = async (): Promise<string> => {
  const timestamp = Date.now();
  const query = gql`
    query GetLoginMessage($timestamp: Timestamp) {
      message(timestamp: $timestamp)
    }
  `;

  const res: {
    message: string;
  } = await graphql({ query, params: { timestamp } });
  return res.message;
};
