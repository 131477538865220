import { Icon, IconProps } from "../Icon";

interface ShoppingBagAndBadgeProps extends IconProps {
  badgeColor?: string;
}

const ShoppingBagAndBadge = ({ badgeColor = "#FA4D56", ...props }: ShoppingBagAndBadgeProps) => (
  <Icon viewBox="0 0 22 22" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9752 9.09375C17.8565 8.95491 17.6829 8.87499 17.5002 8.875H13.7502V6.375C13.7502 5.33947 12.9108 4.5 11.8752 4.5H8.12525C7.08971 4.5 6.25025 5.33947 6.25025 6.375V8.875H2.50025C2.31689 8.87292 2.14186 8.95147 2.02154 9.08985C1.90122 9.22822 1.84773 9.41245 1.87525 9.59375L3.05025 17.1875C3.14371 17.8038 3.67694 18.257 4.30025 18.25H15.7127C16.336 18.257 16.8693 17.8038 16.9627 17.1875L18.1252 9.59375C18.1506 9.41318 18.0958 9.23056 17.9752 9.09375ZM7.50004 6.375C7.50004 6.02982 7.77986 5.75 8.12504 5.75H11.875C12.2202 5.75 12.5 6.02982 12.5 6.375V8.875H7.50004V6.375ZM15.7128 17H4.28785L3.2316 10.125H16.7691L15.7128 17Z"
      fill="currentColor"
    />
    <circle cx="20" cy="2" r="2" fill={badgeColor} />
  </Icon>
);

export default ShoppingBagAndBadge;
