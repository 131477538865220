import { useTranslation } from "next-i18next";
import { ModalLegacy, ModalLegacyProps, WalletIcon } from "@looksrare/uikit";
import { SignInStatus } from "@looksrare/utils";
import { SignInModalBody } from "./SignInModalBody";

export interface SignInModalProps {
  isOpen: ModalLegacyProps["isOpen"];
  onClose: ModalLegacyProps["onClose"];
  txStatus: SignInStatus;
  signInHandler: () => void;
}

export const SignInModal = ({ isOpen, onClose, txStatus, signInHandler }: SignInModalProps) => {
  const { t } = useTranslation();

  const heroIcon = <WalletIcon boxSize={14} mb={4} color="interactive-03" />;
  const heroTitle = t("Sign in Wallet");
  const heroLabel = t(
    "Open your wallet app and sign the message. This lets us verify that you’re the owner of the connected wallet."
  );
  return (
    <ModalLegacy
      label={heroLabel}
      heroElement={heroIcon}
      title={heroTitle}
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="none"
    >
      <SignInModalBody txStatus={txStatus} signInHandler={signInHandler} />
    </ModalLegacy>
  );
};
