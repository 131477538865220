import { Icon, IconProps } from "../Icon";

const MetaMask = (props: IconProps) => (
  <Icon fill="none" {...props}>
    <path d="M27.5308 3.5656L17.5371 10.9881L19.3852 6.60891L27.5308 3.5656Z" fill="#E2761B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5744 3.54161C27.5864 3.56359 27.5803 3.59101 27.5602 3.60592L17.5665 11.0284C17.5483 11.0419 17.5233 11.0416 17.5055 11.0276C17.4877 11.0136 17.4815 10.9894 17.4903 10.9685L19.3384 6.58939C19.3437 6.57667 19.3541 6.56671 19.3671 6.56188L27.5127 3.51856C27.5361 3.50981 27.5625 3.51964 27.5744 3.54161ZM19.4225 6.64839L17.6544 10.838L27.2156 3.7368L19.4225 6.64839Z"
      fill="#E2761B"
    />
    <path d="M4.45898 3.5656L14.3723 11.0584L12.6147 6.60891L4.45898 3.5656Z" fill="#E4761B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.41441 3.54144C4.42641 3.51958 4.45263 3.50984 4.47598 3.51856L12.6317 6.56187C12.645 6.56685 12.6556 6.57724 12.6608 6.59047L14.4185 11.0399C14.4268 11.0609 14.4201 11.0848 14.4022 11.0984C14.3843 11.112 14.3595 11.112 14.3415 11.0985L4.42815 3.60567C4.40826 3.59064 4.40241 3.56329 4.41441 3.54144ZM4.763 3.73286L14.2594 10.9105L12.5756 6.64816L4.763 3.73286Z"
      fill="#E4761B"
    />
    <path d="M23.9331 20.7709L21.2715 24.8487L26.9664 26.4156L28.6035 20.8613L23.9331 20.7709Z" fill="#E4761B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8905 20.7434C23.9 20.7289 23.9162 20.7203 23.9335 20.7207L28.604 20.8111C28.6196 20.8114 28.6343 20.8189 28.6435 20.8316C28.6528 20.8442 28.6556 20.8604 28.6512 20.8755L27.014 26.4298C27.0063 26.456 26.9789 26.4713 26.9525 26.464L21.2576 24.8971C21.2421 24.8929 21.2296 24.8815 21.224 24.8665C21.2183 24.8515 21.2201 24.8347 21.2289 24.8213L23.8905 20.7434ZM23.9594 20.8216L21.3506 24.8185L26.9316 26.3541L28.5362 20.9102L23.9594 20.8216Z"
      fill="#E4761B"
    />
    <path d="M3.4043 20.8613L5.03142 26.4156L10.7263 24.8487L8.06469 20.7709L3.4043 20.8613Z" fill="#E4761B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.06315 20.7207C8.08046 20.7203 8.09672 20.7289 8.10618 20.7434L10.7678 24.8213C10.7766 24.8347 10.7784 24.8515 10.7727 24.8665C10.7671 24.8815 10.7546 24.8929 10.7391 24.8971L5.04418 26.464C5.01775 26.4713 4.99037 26.456 4.98266 26.4297L3.35554 20.8754C3.35114 20.8604 3.35399 20.8442 3.36325 20.8315C3.37251 20.8189 3.38712 20.8114 3.40276 20.8111L8.06315 20.7207ZM3.4704 20.9102L5.06516 26.3541L10.6461 24.8185L8.03728 20.8216L3.4704 20.9102Z"
      fill="#E4761B"
    />
    <path d="M10.4033 13.8807L8.81641 16.2812L14.4711 16.5323L14.2703 10.4557L10.4033 13.8807Z" fill="#E4761B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2915 10.4096C14.3094 10.4173 14.3212 10.4346 14.3218 10.4541L14.5227 16.5307C14.5232 16.5447 14.5178 16.5582 14.5078 16.5681C14.4979 16.5779 14.4843 16.5831 14.4703 16.5825L8.81557 16.3314C8.7975 16.3306 8.78126 16.3202 8.77305 16.304C8.76484 16.2879 8.76593 16.2686 8.77591 16.2536L10.3628 13.8531C10.3653 13.8494 10.3682 13.8461 10.3714 13.8432L14.2384 10.4182C14.2529 10.4053 14.2736 10.4019 14.2915 10.4096ZM10.443 13.914L8.90857 16.235L14.4206 16.4798L14.225 10.5642L10.443 13.914Z"
      fill="#E4761B"
    />
    <path d="M21.5848 13.8807L17.6677 10.3854L17.5371 16.5323L23.1818 16.2812L21.5848 13.8807Z" fill="#E4761B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6471 10.3394C17.665 10.3316 17.6859 10.3349 17.7006 10.348L21.6177 13.8433C21.6209 13.8461 21.6237 13.8493 21.6261 13.8529L23.2231 16.2534C23.2331 16.2685 23.2342 16.2878 23.226 16.304C23.2178 16.3201 23.2016 16.3306 23.1835 16.3314L17.5388 16.5825C17.5249 16.5831 17.5114 16.578 17.5015 16.5682C17.4915 16.5585 17.486 16.5451 17.4863 16.5313L17.6169 10.3844C17.6173 10.3648 17.6291 10.3472 17.6471 10.3394ZM17.715 10.4955L17.5879 16.4798L23.0902 16.235L21.546 13.9139L17.715 10.4955Z"
      fill="#E4761B"
    />
    <path d="M10.7246 24.8487L14.1195 23.1915L11.1866 20.9015L10.7246 24.8487Z" fill="#E4761B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1687 20.8551C11.1854 20.8481 11.2046 20.8507 11.2189 20.8619L14.1518 23.1519C14.1654 23.1625 14.1726 23.1795 14.1708 23.1967C14.169 23.2139 14.1584 23.229 14.1429 23.2366L10.748 24.8938C10.7315 24.9019 10.7119 24.9003 10.697 24.8897C10.682 24.8791 10.674 24.8611 10.6761 24.8429L11.1381 20.8956C11.1403 20.8776 11.1519 20.8621 11.1687 20.8551ZM11.2275 20.996L10.7866 24.7633L14.0266 23.1816L11.2275 20.996Z"
      fill="#E4761B"
    />
    <path d="M17.8672 23.1915L21.2721 24.8487L20.8 20.9015L17.8672 23.1915Z" fill="#E4761B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8188 20.8551C20.8355 20.8621 20.8472 20.8775 20.8493 20.8955L21.3214 24.8428C21.3236 24.861 21.3156 24.879 21.3006 24.8896C21.2857 24.9003 21.2661 24.9019 21.2495 24.8939L17.8446 23.2366C17.8291 23.229 17.8185 23.214 17.8167 23.1967C17.8149 23.1795 17.8221 23.1625 17.8357 23.1519L20.7686 20.8619C20.7828 20.8507 20.802 20.8481 20.8188 20.8551ZM17.961 23.1815L21.2107 24.7633L20.7602 20.9958L17.961 23.1815Z"
      fill="#E4761B"
    />
    <path d="M21.2721 24.8487L17.8672 23.1915L18.1384 25.4112L18.1082 26.3453L21.2721 24.8487Z" fill="#D7C1B3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8375 23.1506C17.8524 23.1399 17.8721 23.1383 17.8886 23.1463L21.2935 24.8036C21.3109 24.812 21.3218 24.8297 21.3217 24.849C21.3216 24.8683 21.3105 24.8859 21.293 24.8941L18.1292 26.3907C18.1133 26.3981 18.0948 26.3969 18.0801 26.3873C18.0655 26.3777 18.0569 26.3611 18.0575 26.3436L18.0875 25.4134L17.8168 23.1976C17.8145 23.1793 17.8225 23.1613 17.8375 23.1506ZM17.9277 23.277L18.1877 25.4051C18.188 25.4076 18.1881 25.4102 18.188 25.4128L18.1605 26.2647L21.1554 24.8481L17.9277 23.277Z"
      fill="#D7C1B3"
    />
    <path d="M10.7266 24.8487L13.8904 26.3453L13.8703 25.4112L14.1214 23.1915L10.7266 24.8487Z" fill="#D7C1B3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1498 23.1504C14.1648 23.161 14.1728 23.1789 14.1708 23.1971L13.92 25.4135L13.9401 26.3442C13.9404 26.3616 13.9318 26.3779 13.9172 26.3874C13.9026 26.3969 13.8841 26.3981 13.8684 26.3907L10.7045 24.8941C10.6871 24.8859 10.6759 24.8683 10.6758 24.849C10.6757 24.8297 10.6866 24.8121 10.704 24.8036L14.0988 23.1463C14.1153 23.1383 14.1348 23.1399 14.1498 23.1504ZM10.8419 24.848L13.8379 26.2651L13.8195 25.4123C13.8195 25.41 13.8196 25.4078 13.8199 25.4055L14.0607 23.2767L10.8419 24.848Z"
      fill="#D7C1B3"
    />
    <path d="M13.9418 19.435L11.1094 18.6014L13.1081 17.6874L13.9418 19.435Z" fill="#233447" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0867 17.6417C13.1116 17.6303 13.1411 17.641 13.1529 17.6658L13.9865 19.4134C13.9951 19.4313 13.9923 19.4525 13.9794 19.4676C13.9666 19.4827 13.946 19.4888 13.927 19.4832L11.0946 18.6495C11.0745 18.6436 11.0602 18.6259 11.0587 18.605C11.0572 18.584 11.0689 18.5644 11.0879 18.5557L13.0867 17.6417ZM11.2519 18.5911L13.8474 19.3551L13.0835 17.7536L11.2519 18.5911Z"
      fill="#233447"
    />
    <path d="M18.0488 19.435L18.8825 17.6874L20.8913 18.6014L18.0488 19.435Z" fill="#233447" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8385 17.6658C18.8503 17.641 18.8797 17.6303 18.9047 17.6417L20.9135 18.5557C20.9326 18.5644 20.9442 18.584 20.9427 18.6049C20.9413 18.6259 20.9269 18.6437 20.9068 18.6496L18.0644 19.4832C18.0453 19.4888 18.0248 19.4827 18.012 19.4676C17.9991 19.4525 17.9964 19.4313 18.0049 19.4134L18.8385 17.6658ZM18.908 17.7535L18.1439 19.3552L20.749 18.5912L18.908 17.7535Z"
      fill="#233447"
    />
    <path d="M10.7261 24.8487L11.2082 20.7709L8.06445 20.8613L10.7261 24.8487Z" fill="#CD6116" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2447 20.737C11.2546 20.7477 11.2592 20.7623 11.2575 20.7768L10.7754 24.8546C10.7729 24.8755 10.7577 24.8927 10.7373 24.8976C10.7168 24.9025 10.6955 24.8941 10.6838 24.8766L8.02212 20.8891C8.01198 20.874 8.01089 20.8545 8.01927 20.8382C8.02765 20.822 8.04419 20.8116 8.06245 20.8111L11.2062 20.7207C11.2208 20.7203 11.2348 20.7262 11.2447 20.737ZM8.15604 20.9089L10.6917 24.7075L11.1509 20.8227L8.15604 20.9089Z"
      fill="#CD6116"
    />
    <path d="M20.791 20.7709L21.2731 24.8487L23.9348 20.8613L20.791 20.7709Z" fill="#CD6116" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7534 20.737C20.7633 20.7262 20.7773 20.7203 20.7919 20.7207L23.9357 20.8111C23.9539 20.8116 23.9705 20.822 23.9788 20.8382C23.9872 20.8545 23.9861 20.874 23.976 20.8891L21.3143 24.8766C21.3027 24.8941 21.2813 24.9025 21.2608 24.8976C21.2404 24.8927 21.2252 24.8755 21.2227 24.8546L20.7406 20.7768C20.7389 20.7623 20.7435 20.7477 20.7534 20.737ZM20.8472 20.8227L21.3064 24.7075L23.8421 20.9089L20.8472 20.8227Z"
      fill="#CD6116"
    />
    <path
      d="M23.1798 16.2812L17.5352 16.5323L18.0574 19.435L18.8911 17.6874L20.8999 18.6014L23.1798 16.2812Z"
      fill="#CD6116"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.2252 16.2608C23.2336 16.2796 23.2296 16.3017 23.2151 16.3164L20.9351 18.6366C20.9203 18.6516 20.8977 18.6558 20.8785 18.6471L18.9146 17.7535L18.1022 19.4567C18.0928 19.4763 18.0718 19.4877 18.0502 19.4848C18.0286 19.4819 18.0113 19.4654 18.0075 19.4439L17.4852 16.5412C17.4826 16.527 17.4863 16.5123 17.4954 16.501C17.5044 16.4897 17.5179 16.4828 17.5324 16.4822L23.1771 16.2311C23.1977 16.2302 23.2168 16.2419 23.2252 16.2608ZM17.5942 16.58L18.0787 19.2727L18.8452 17.6658C18.857 17.6411 18.8864 17.6303 18.9113 17.6417L20.8881 18.5411L23.054 16.3371L17.5942 16.58Z"
      fill="#CD6116"
    />
    <path
      d="M11.1064 18.6014L13.1152 17.6874L13.9388 19.435L14.4711 16.5323L8.81641 16.2812L11.1064 18.6014Z"
      fill="#CD6116"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.77191 16.2608C8.7803 16.242 8.79939 16.2302 8.82003 16.2311L14.4748 16.4822C14.4893 16.4828 14.5028 16.4897 14.5118 16.5011C14.5209 16.5124 14.5246 16.5271 14.5219 16.5414L13.9896 19.4441C13.9857 19.4655 13.9683 19.482 13.9467 19.4848C13.9251 19.4876 13.9041 19.4762 13.8948 19.4564L13.0923 17.7536L11.1286 18.6471C11.1094 18.6558 11.0869 18.6517 11.0721 18.6367L8.78206 16.3165C8.76755 16.3018 8.76352 16.2797 8.77191 16.2608ZM8.94349 16.3371L11.1189 18.5412L13.0958 17.6417C13.1208 17.6303 13.1503 17.6411 13.162 17.666L13.919 19.2722L14.4128 16.58L8.94349 16.3371Z"
      fill="#CD6116"
    />
    <path d="M8.81836 16.2812L11.1887 20.9014L11.1084 18.6014L8.81836 16.2812Z" fill="#E4751F" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.78836 16.2406C8.80844 16.226 8.83613 16.2283 8.85354 16.246L11.1436 18.5661C11.1524 18.5751 11.1576 18.587 11.158 18.5996L11.2384 20.8997C11.2392 20.9232 11.2236 20.9441 11.2008 20.9501C11.1781 20.956 11.1542 20.9453 11.1435 20.9244L8.77312 16.3042C8.7618 16.2821 8.76828 16.2551 8.78836 16.2406ZM9.01172 16.5492L11.1301 20.6783L11.0583 18.6227L9.01172 16.5492Z"
      fill="#E4751F"
    />
    <path d="M20.8993 18.6014L20.7988 20.9014L23.1792 16.2812L20.8993 18.6014Z" fill="#E4751F" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.21 16.2405C23.2302 16.2551 23.2366 16.2822 23.2253 16.3042L20.8449 20.9245C20.8341 20.9454 20.8101 20.956 20.7873 20.95C20.7645 20.9439 20.749 20.9228 20.75 20.8993L20.8505 18.5992C20.851 18.5868 20.8561 18.575 20.8648 18.5662L23.1448 16.246C23.1622 16.2283 23.1899 16.226 23.21 16.2405ZM20.95 18.6228L20.8604 20.675L22.9843 16.5527L20.95 18.6228Z"
      fill="#E4751F"
    />
    <path d="M14.4718 16.5323L13.9395 19.435L14.6024 22.86L14.753 18.3503L14.4718 16.5323Z" fill="#E4751F" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4739 16.4821C14.4984 16.4825 14.5191 16.5004 14.5228 16.5247L14.804 18.3426C14.8045 18.3457 14.8047 18.3488 14.8046 18.352L14.6539 22.8617C14.6531 22.8873 14.6332 22.9081 14.6077 22.9101C14.5822 22.9121 14.5593 22.8947 14.5544 22.8696L13.8915 19.4446C13.8904 19.4384 13.8903 19.4321 13.8914 19.426L14.4238 16.5233C14.4282 16.4992 14.4494 16.4818 14.4739 16.4821ZM13.9919 19.4348L14.5684 22.4133L14.7041 18.3533L14.469 16.8337L13.9919 19.4348Z"
      fill="#E4751F"
    />
    <path d="M17.5368 16.5323L17.2656 18.3403L17.3862 22.86L18.0591 19.435L17.5368 16.5323Z" fill="#E4751F" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5355 16.4821C17.5601 16.4818 17.5813 16.4993 17.5857 16.5234L18.108 19.4262C18.1091 19.4323 18.109 19.4386 18.1078 19.4447L17.4349 22.8697C17.4299 22.8948 17.4069 22.9122 17.3814 22.9101C17.3559 22.9079 17.3361 22.887 17.3354 22.8614L17.2149 18.3416C17.2148 18.3387 17.215 18.3357 17.2154 18.3328L17.4866 16.5249C17.4902 16.5006 17.5109 16.4825 17.5355 16.4821ZM17.5408 16.8409L17.3154 18.3433L17.4237 22.4056L18.0074 19.4346L17.5408 16.8409Z"
      fill="#E4751F"
    />
    <path
      d="M18.0597 19.435L17.3867 22.86L17.8688 23.1915L20.8017 20.9014L20.9021 18.6014L18.0597 19.435Z"
      fill="#F6851B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9325 18.5619C20.9453 18.5718 20.9524 18.5874 20.9517 18.6036L20.8513 20.9036C20.8506 20.9183 20.8436 20.932 20.832 20.941L17.8992 23.2311C17.8819 23.2445 17.8579 23.2453 17.8398 23.2329L17.3577 22.9014C17.3412 22.8901 17.333 22.87 17.3369 22.8503L18.0098 19.4253C18.0134 19.407 18.027 19.3921 18.045 19.3868L20.8874 18.5532C20.9029 18.5486 20.9197 18.5519 20.9325 18.5619ZM18.1025 19.4746L17.4418 22.8373L17.8664 23.1292L20.7519 20.8761L20.8483 18.6693L18.1025 19.4746Z"
      fill="#F6851B"
    />
    <path
      d="M11.1094 18.6014L11.1897 20.9014L14.1226 23.1915L14.6047 22.86L13.9418 19.435L11.1094 18.6014Z"
      fill="#F6851B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.078 18.5617C11.0908 18.5518 11.1075 18.5487 11.123 18.5532L13.9554 19.3869C13.9734 19.3922 13.9869 19.4071 13.9905 19.4255L14.6534 22.8505C14.6572 22.8701 14.649 22.8901 14.6326 22.9014L14.1504 23.2329C14.1324 23.2453 14.1084 23.2445 14.0911 23.2311L11.1583 20.941C11.1466 20.9319 11.1395 20.918 11.139 20.9032L11.0586 18.6031C11.0581 18.587 11.0653 18.5716 11.078 18.5617ZM11.1614 18.6692L11.2385 20.8763L14.1239 23.1292L14.5485 22.8373L13.8977 19.4746L11.1614 18.6692Z"
      fill="#F6851B"
    />
    <path
      d="M18.1089 26.3452L18.139 25.4112L17.8879 25.1902H14.1013L13.8703 25.4112L13.8904 26.3452L10.7266 24.8487L11.8314 25.7527L14.0712 27.3095H17.918L20.1679 25.7527L21.2727 24.8487L18.1089 26.3452Z"
      fill="#C0AD9E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6836 24.8219C10.6971 24.8004 10.7245 24.7925 10.7475 24.8033L13.8379 26.2651L13.8196 25.4123C13.8193 25.3982 13.8249 25.3846 13.835 25.3749L14.0661 25.1539C14.0754 25.145 14.0878 25.14 14.1008 25.14H17.8873C17.8996 25.14 17.9114 25.1444 17.9205 25.1525L18.1716 25.3735C18.1829 25.3834 18.1891 25.3978 18.1886 25.4128L18.1612 26.2647L21.2507 24.8033C21.2736 24.7925 21.301 24.8004 21.3146 24.8219C21.3282 24.8433 21.3236 24.8715 21.304 24.8876L20.1991 25.7915C20.1981 25.7924 20.197 25.7932 20.1959 25.794L17.946 27.3508C17.9377 27.3566 17.9277 27.3597 17.9175 27.3597H14.0706C14.0604 27.3597 14.0504 27.3566 14.042 27.3507L11.8022 25.7939C11.8011 25.7932 11.8001 25.7924 11.799 25.7915L10.6942 24.8876C10.6746 24.8715 10.67 24.8433 10.6836 24.8219ZM11.0749 25.0693L11.8611 25.7126L14.0864 27.2593H17.9018L20.1371 25.7125L20.9232 25.0693L18.1298 26.3907C18.114 26.3981 18.0954 26.3968 18.0808 26.3872C18.0661 26.3777 18.0576 26.3611 18.0581 26.3436L18.0875 25.4332L17.8684 25.2404H14.1209L13.9204 25.4322L13.9401 26.3442C13.9404 26.3616 13.9318 26.3779 13.9172 26.3874C13.9026 26.3969 13.8841 26.3981 13.8684 26.3907L11.0749 25.0693Z"
      fill="#C0AD9E"
    />
    <path
      d="M17.8686 23.1915L17.3865 22.86H14.6043L14.1222 23.1915L13.8711 25.4112L14.1021 25.1902H17.8887L18.1398 25.4112L17.8686 23.1915Z"
      fill="#161616"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5753 22.8186C14.5837 22.8129 14.5936 22.8098 14.6037 22.8098H17.3859C17.3961 22.8098 17.406 22.8129 17.4144 22.8186L17.8965 23.1501C17.9083 23.1582 17.9161 23.1711 17.9179 23.1854L18.1891 25.4051C18.1916 25.4258 18.1811 25.4459 18.1626 25.4556C18.1442 25.4653 18.1217 25.4627 18.106 25.4489L17.8692 25.2404H14.1217L13.9052 25.4475C13.89 25.4621 13.8672 25.4656 13.8482 25.4562C13.8293 25.4468 13.8183 25.4265 13.8206 25.4055L14.0717 23.1858C14.0734 23.1714 14.0812 23.1583 14.0932 23.1501L14.5753 22.8186ZM14.6193 22.9102L14.169 23.2199L13.936 25.279L14.0668 25.1539C14.0762 25.145 14.0886 25.14 14.1015 25.14H17.8881C17.9003 25.14 17.9121 25.1444 17.9213 25.1525L18.0734 25.2863L17.8209 23.22L17.3703 22.9102H14.6193Z"
      fill="#161616"
    />
    <path
      d="M27.9513 11.4702L28.805 7.37225L27.5295 3.5656L17.8672 10.737L21.5834 13.8807L26.8364 15.4174L28.0015 14.0615L27.4993 13.6999L28.3028 12.9667L27.6801 12.4846L28.4836 11.8719L27.9513 11.4702Z"
      fill="#763D16"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.544 3.51769C27.5593 3.52251 27.5714 3.53439 27.5765 3.54964L28.8521 7.3563C28.8549 7.36474 28.8555 7.37378 28.8536 7.3825L28.0064 11.4493L28.5133 11.8319C28.5259 11.8413 28.5333 11.8561 28.5333 11.8718C28.5333 11.8875 28.526 11.9023 28.5135 11.9119L27.762 12.4849L28.333 12.927C28.3447 12.9361 28.3519 12.9499 28.3525 12.9647C28.3531 12.9795 28.3471 12.9938 28.3361 13.0038L27.5783 13.6953L28.0303 14.0208C28.0417 14.029 28.0492 14.0416 28.0508 14.0556C28.0525 14.0695 28.0482 14.0836 28.0391 14.0942L26.874 15.4502C26.8611 15.4651 26.8407 15.4712 26.8218 15.4656L21.5688 13.9289C21.5621 13.927 21.5558 13.9236 21.5505 13.9191L17.8342 10.7753C17.8225 10.7654 17.8159 10.7507 17.8164 10.7354C17.8169 10.72 17.8244 10.7058 17.8367 10.6966L27.499 3.52527C27.5119 3.51569 27.5286 3.51287 27.544 3.51769ZM17.9474 10.7395L21.6072 13.8355L26.8189 15.3602L27.9272 14.0703L27.4694 13.7407C27.4571 13.7318 27.4495 13.7179 27.4486 13.7028C27.4478 13.6877 27.4537 13.673 27.4649 13.6628L28.2243 12.9699L27.6488 12.5243C27.6365 12.5148 27.6293 12.5 27.6293 12.4844C27.6294 12.4688 27.6367 12.4541 27.6491 12.4447L28.4 11.8721L27.9205 11.5103C27.905 11.4986 27.8976 11.479 27.9016 11.4599L28.7525 7.37532L27.5033 3.64717L17.9474 10.7395Z"
      fill="#763D16"
    />
    <path
      d="M3.19336 7.37225L4.04709 11.4702L3.50472 11.8719L4.30824 12.4846L3.69556 12.9667L4.49907 13.6999L3.99687 14.0615L5.15193 15.4174L10.4049 13.8807L14.1212 10.737L4.4589 3.5656L3.19336 7.37225Z"
      fill="#763D16"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.44322 3.51771C4.45858 3.51286 4.47533 3.51568 4.48827 3.52527L14.1505 10.6966C14.1629 10.7058 14.1703 10.72 14.1708 10.7354C14.1713 10.7507 14.1648 10.7654 14.153 10.7753L10.4368 13.9191C10.4314 13.9236 10.4252 13.927 10.4185 13.9289L5.16547 15.4656C5.14647 15.4712 5.12597 15.4651 5.11314 15.45L3.95808 14.0941C3.94899 14.0834 3.94477 14.0694 3.94646 14.0555C3.94815 14.0415 3.95558 14.029 3.96697 14.0208L4.41896 13.6953L3.66114 13.0038C3.65024 12.9939 3.64427 12.9796 3.64481 12.9649C3.64535 12.9501 3.65234 12.9364 3.66394 12.9273L4.22567 12.4852L3.47371 11.9119C3.46115 11.9023 3.45383 11.8874 3.45394 11.8716C3.45405 11.8558 3.46158 11.841 3.47427 11.8316L3.99081 11.449L3.14363 7.3825C3.14183 7.37382 3.14235 7.36482 3.14514 7.35641L4.41068 3.54976C4.41576 3.53447 4.42786 3.52256 4.44322 3.51771ZM4.48411 3.64727L3.24472 7.37526L4.0957 11.4599C4.09969 11.4791 4.09217 11.4989 4.07643 11.5105L3.58775 11.8725L4.33813 12.4447C4.35048 12.4541 4.35778 12.4687 4.35789 12.4842C4.35801 12.4998 4.35094 12.5145 4.33873 12.5241L3.7726 12.9696L4.53236 13.6628C4.54354 13.673 4.54952 13.6877 4.54865 13.7028C4.54777 13.7179 4.54013 13.7318 4.52785 13.7407L4.06988 14.0704L5.1685 15.3601L10.38 13.8355L14.0398 10.7395L4.48411 3.64727Z"
      fill="#763D16"
    />
    <path
      d="M26.8372 15.4174L21.5842 13.8807L23.1812 16.2812L20.8008 20.9014L23.9345 20.8613H28.6049L26.8372 15.4174Z"
      fill="#F6851B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5451 13.851C21.5576 13.834 21.5794 13.8266 21.5997 13.8325L26.8527 15.3693C26.8686 15.3739 26.8812 15.3862 26.8864 15.4019L28.6541 20.8458C28.6591 20.861 28.6564 20.8778 28.6469 20.8908C28.6375 20.9038 28.6224 20.9115 28.6063 20.9115H23.9359L20.8028 20.9517C20.7852 20.9519 20.7687 20.9429 20.7595 20.9279C20.7502 20.9129 20.7495 20.8941 20.7575 20.8784L23.1244 16.2845L21.5438 13.9085C21.5321 13.891 21.5326 13.868 21.5451 13.851ZM21.7037 13.9676L23.2244 16.2534C23.2345 16.2686 23.2356 16.288 23.2272 16.3042L20.8851 20.8502L23.9359 20.811L28.5372 20.811L26.799 15.4582L21.7037 13.9676Z"
      fill="#F6851B"
    />
    <path
      d="M10.4049 13.8807L5.15194 15.4174L3.4043 20.8613H8.06469L11.1884 20.9014L8.81798 16.2812L10.4049 13.8807Z"
      fill="#F6851B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4448 13.8509C10.4573 13.8679 10.4579 13.8908 10.4463 13.9084L8.87551 16.2844L11.2325 20.8785C11.2405 20.8942 11.2398 20.9129 11.2305 20.9279C11.2212 20.9429 11.2048 20.9519 11.1871 20.9517L8.06413 20.9115H3.40374C3.38769 20.9115 3.37261 20.9038 3.36316 20.8909C3.35371 20.8779 3.35102 20.8612 3.35592 20.8459L5.10357 15.4021C5.10866 15.3862 5.12129 15.3739 5.13728 15.3693L10.3903 13.8325C10.4105 13.8266 10.4323 13.834 10.4448 13.8509ZM5.19105 15.4582L3.4726 20.811H8.06477L11.105 20.8502L8.77274 16.3042C8.76446 16.288 8.76552 16.2687 8.77553 16.2535L10.2868 13.9674L5.19105 15.4582Z"
      fill="#F6851B"
    />
    <path
      d="M17.5368 16.5323L17.8682 10.737L19.3949 6.60892H12.6152L14.1218 10.737L14.4734 16.5323L14.5939 18.3603L14.6039 22.86H17.3861L17.4062 18.3603L17.5368 16.5323Z"
      fill="#F6851B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5735 6.58013C12.5829 6.5667 12.5983 6.5587 12.6147 6.5587H19.3943C19.4108 6.5587 19.4261 6.56674 19.4355 6.58022C19.4449 6.59371 19.4471 6.61093 19.4414 6.62634L17.9174 10.7474L17.5863 16.5352L17.5863 16.5359L17.4558 18.3622L17.4358 22.8602C17.4356 22.8879 17.4132 22.9102 17.3855 22.9102H14.6034C14.5757 22.9102 14.5532 22.8878 14.5532 22.8601L14.5431 18.362L14.4227 16.5356L14.4227 16.5354L14.0716 10.7473L12.5675 6.62614C12.5619 6.61074 12.5641 6.59357 12.5735 6.58013ZM12.6865 6.65914L14.1684 10.7198C14.1701 10.7243 14.1711 10.7291 14.1714 10.7339L14.5229 16.529L14.5229 16.5293L14.6434 18.357C14.6435 18.3581 14.6435 18.3592 14.6435 18.3602L14.6535 22.8098H17.3355L17.3554 18.3601C17.3554 18.359 17.3555 18.3579 17.3555 18.3568L17.4861 16.5295L17.4861 16.5288L17.8175 10.7341C17.8178 10.7291 17.8188 10.7242 17.8205 10.7196L19.3222 6.65914H12.6865Z"
      fill="#F6851B"
    />
  </Icon>
);

export default MetaMask;
