import { IconButton } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { NextLink, type ButtonProps, Chevron } from "@looksrare/uikit";
import { getRoundUrl } from "../../utils";
import { useActiveRoundOnChainId, useGetCaveQueryParams } from "../../hooks";

export const NextRoundButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const { caveOnChainId, roundOnChainId, network } = useGetCaveQueryParams();
  const paramRoundOnChainId = Number(roundOnChainId);
  const activeRoundQuery = useActiveRoundOnChainId();
  const nextJoinableRoundId = activeRoundQuery.data ? Number(activeRoundQuery.data) : null;
  const baseCaveUrl = getRoundUrl({ caveOnChainId, network });

  return (
    <IconButton
      aria-label={t("Next Round")}
      title={t("Next Round")}
      isDisabled={paramRoundOnChainId === nextJoinableRoundId}
      colorScheme="secondary"
      size="xs"
      {...(paramRoundOnChainId !== nextJoinableRoundId && {
        as: NextLink,
        href: `${baseCaveUrl}/${paramRoundOnChainId + 1}`,
      })}
      {...props}
    >
      <Chevron direction="right" />
    </IconButton>
  );
};
