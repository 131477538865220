import { Box, Flex, FlexProps, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import {
  Chevron,
  GamesFillIcon,
  HelpFilledIcon,
  HomeFilledIcon,
  IngotIcon,
  LiquidityIcon,
  NextLink,
  Popover,
  RakebackIcon,
  SettingsFillIcon,
  Text,
  TicketRedeemablesFillIcon,
  TooltipText,
  TrophyFillIcon,
} from "@looksrare/uikit";
import { getMoDUrl } from "@looksrare/moon-or-doom";
import { ChainId } from "@looksrare/config";
import { useLocalStorageSnooze } from "@looksrare/utils";
import { NavSubMenuItem, NavSubMenuPopover } from "@/components/Layout/SideNav/NavSubMenu";
import { SubMenuState } from "@/components/Layout/SideNav/SideNavMobile";
import { IngotIconWithSpinsCounter } from "@/views/LuckySpin/components/IngotIconWithSpinsCounter";
import { RakebackIconWithCounter } from "@/views/Rakeback/components/RakebackIconWithCounter";
import { NavSubMenuItemSub } from "./NavSubMenu/NavSubMenuItemSub";

export type NavButtonType =
  | "games"
  | "rewards"
  | "settings"
  | "help"
  | "home"
  | "lottery"
  | "gold-rush"
  | "liquidity"
  | "rakeback";

interface NavButtonProps extends FlexProps {
  type: NavButtonType;
  isActive?: boolean;
  onClose?: () => void;
  isMobile?: boolean;
  isComingSoon?: boolean;
  onOpenSubMenu?: (data: SubMenuState | null) => void;
}

export const NavButton = ({
  type,
  onClose,
  isActive = false,
  isMobile = false,
  isComingSoon = false,
  onOpenSubMenu,
  ...props
}: NavButtonProps) => {
  const { t } = useTranslation();
  const { isSnoozed: isNewSnoozed, handleSnoozeIndefinitely: snoozeIsNew } = useLocalStorageSnooze({
    baseKey: `new-nav-item-${type}`,
  });

  const {
    href,
    icon: Icon,
    title,
    drawerContent,
    isNew = false,
  } = {
    games: {
      drawerContent: (
        <Stack p={4} spacing={6} width="100%">
          <Stack spacing={1}>
            <Text textStyle="detail" bold color="text-02">
              {t("Multiplayer")}
            </Text>
            <NavSubMenuItem
              title={t("YOLO")}
              description={t("Spin to win!")}
              as={NextLink}
              href="/yolo/blast"
              image="/images/yolo/yolo-thumbnail.png"
              data-id="sidenav-yolo"
            />
            <NavSubMenuItemSub as={NextLink} href="/yolo-limited/blast" data-id="sidenav-yolo-ltd" isNew>
              <Text bold>{t("YOLO Limited")}</Text>
            </NavSubMenuItemSub>
            <NavSubMenuItem
              title={t("Poke the Bear")}
              description={t("If you dare!")}
              as={NextLink}
              href="/poke-the-bear"
              image="/images/poke-the-bear/ptb-thumbnail.png"
              data-id="sidenav-ptb"
            />
            <NavSubMenuItem
              title={t("MOON or DOOM")}
              description={t("Predict the future!")}
              as={NextLink}
              href={getMoDUrl(ChainId.BLAST_MAINNET, "ETHUSD")}
              image="/images/moon-or-doom/moon-or-doom-thumbnail.png"
              data-id="sidenav-mod"
            />
          </Stack>
          <Stack spacing={1}>
            <Text textStyle="detail" bold color="text-02">
              {t("Single Player")}
            </Text>
            <NavSubMenuItem
              title={t("Flipper")}
              description={t("Pick your side...")}
              as={NextLink}
              href="/flipper"
              image="/images/flipper/thumbnail.png"
              data-id="sidenav-flipper"
            />
            <NavSubMenuItem
              title={t("Quantum")}
              description={t("Over or under?")}
              as={NextLink}
              href="/quantum"
              image="/images/quantum/thumbnail.png"
              data-id="sidenav-quantum"
            />
            <NavSubMenuItem
              title={t("LaserBlast")}
              description={t("Invaders must die.")}
              as={NextLink}
              href="/laser-blast"
              image="/images/laser-blast/thumbnail.png"
              data-id="sidenav-laserblast"
            />
            <NavSubMenuItem
              title={t("Don't Fall In")}
              description={t("Yeah, what he said.")}
              as={NextLink}
              href="/dont-fall-in"
              image="/images/dont-fall-in/thumbnail.png"
              data-id="sidenav-dfi"
            />
          </Stack>
        </Stack>
      ),
      icon: GamesFillIcon,
      title: t("Games"),
    },
    rewards: {
      icon: TrophyFillIcon,
      title: t("Rewards"),
      href: "/rewards",
    },
    settings: {
      href: "/settings",
      icon: SettingsFillIcon,
      title: t("Settings"),
    },
    help: {
      href: "https://docs.yologames.io",
      icon: HelpFilledIcon,
      title: t("Docs"),
    },
    home: {
      href: "/",
      icon: HomeFilledIcon,
      title: t("Home"),
    },
    rakeback: {
      href: "/rakeback",
      icon: isMobile ? RakebackIcon : RakebackIconWithCounter,
      title: t("Rakeback"),
    },
    lottery: {
      href: "/lottery",
      icon: TicketRedeemablesFillIcon,
      title: t("Lottery"),
    },
    "gold-rush": {
      href: "/gold-rush",
      icon: isMobile ? IngotIcon : IngotIconWithSpinsCounter,
      title: t("Gold Rush"),
    },
    liquidity: {
      href: "/liquidity",
      icon: LiquidityIcon,
      title: t("Liquidity"),
      isNew: true,
    },
  }[type];

  const iconColor = (() => {
    if (isComingSoon) {
      return "text-disabled";
    }
    return isActive ? "link-02" : "text-03";
  })();
  const textColor = (() => {
    if (isComingSoon) {
      return "text-disabled";
    }
    return isActive ? "link-01" : { base: "text-01", lg: "text-03" };
  })();

  const asTooltip = !!isComingSoon;
  const hasSubMenu = !!drawerContent;
  const mobileOnClick = () => {
    if (isNew) {
      snoozeIsNew();
    }
    if (hasSubMenu && !!onOpenSubMenu) {
      onOpenSubMenu({ type, title, drawerContent });
    }
  };
  const desktopOnClick = () => {
    if (isNew) {
      snoozeIsNew();
    }

    if (!isComingSoon && !hasSubMenu) {
      onClose?.();
    }
  };

  const buttonContent = (
    <Flex
      width="100%"
      borderRadius="button"
      flexDirection={{ base: "row", lg: "column" }}
      gap={{ base: 3, lg: 2 }}
      px={{ base: 4, lg: "6px" }}
      py={{ base: 3, lg: 2 }}
      bgColor={{ base: "ui-02", lg: "transparent" }}
      cursor="pointer"
      justifyContent="space-between"
      onClick={isMobile ? mobileOnClick : desktopOnClick}
      sx={{
        _hover: {
          bg: "hover-ui",
        },
        _active: !isComingSoon
          ? {
              bg: "onclick-ui",
            }
          : {},
      }}
      {...(href ? { as: NextLink, href } : {})}
      {...props}
    >
      <Flex
        width="100%"
        alignItems="center"
        justifyContent={isMobile ? "initial" : "center"}
        flexDirection={isMobile ? "row" : "column"}
        gap={isMobile ? 3 : 1}
        position="relative"
      >
        <Box
          position="relative"
          _after={
            isNew && !isNewSnoozed
              ? {
                  content: `""`,
                  position: "absolute",
                  top: 0,
                  right: "-8px",
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  bg: "support-error-inverse",
                }
              : undefined
          }
        >
          <Icon boxSize={5} color={iconColor} />
        </Box>
        <Text
          flexGrow={1}
          fontWeight={{ base: "bold", lg: "normal" }}
          textStyle={{ base: "body", lg: "caption" }}
          color={textColor}
          whiteSpace="nowrap"
        >
          {title}
        </Text>
        {isMobile && isComingSoon && (
          <Text textStyle="helper" bold color="text-03">
            {t("Coming Soon")}
          </Text>
        )}
        {!isMobile && hasSubMenu && (
          <Chevron direction="right" boxSize={3} color="text-03" position="absolute" right={-2} />
        )}
      </Flex>
      {isMobile && hasSubMenu && (
        <Chevron
          direction="right"
          boxSize={5}
          color={isComingSoon ? "text-disabled" : { base: "text-01", lg: "text-03" }}
          alignSelf="flex-end"
        />
      )}
    </Flex>
  );

  if (asTooltip) {
    return (
      <Popover variant="tooltip" label={<TooltipText textStyle="detail">{t("Coming Soon")}</TooltipText>}>
        <Box width="100%">{buttonContent}</Box>
      </Popover>
    );
  }

  if (hasSubMenu && !isMobile) {
    return (
      <>
        <NavSubMenuPopover
          onClose={onClose}
          title={title}
          labelContent={drawerContent}
          popoverProps={{
            trigger: "hover",
          }}
        >
          <span>{buttonContent}</span>
        </NavSubMenuPopover>
      </>
    );
  }
  return buttonContent;
};
