import { HStack, Skeleton } from "@chakra-ui/react";
import { Text, ViewFilledIcon } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { PanelHeader } from "../layout";
import { useActiveCaveRoundInfo, useActiveCaveViews, useGetCaveQueryParams } from "../../hooks";
import { getPtbContractNameFromNetwork } from "../../utils";

export const PlayerListPanelHeader = () => {
  const { t } = useTranslation();
  const { caveOnChainId, network } = useGetCaveQueryParams();
  const { data, isLoading } = useActiveCaveRoundInfo();
  const response = useActiveCaveViews(caveOnChainId, getPtbContractNameFromNetwork(network));
  const watchCount = response?.count || 1;
  const playerCount = !!data ? data.cave.playersPerRound : "-";

  return (
    <PanelHeader>
      {isLoading ? (
        <Skeleton width="80px" height="24px" />
      ) : (
        <Text bold>{t("ptb::{{playerCount}} Players", { playerCount })}</Text>
      )}
      <HStack spacing={0.5}>
        <Text color="link-01" mr={2}>
          {t("{{watchCount}} Watching", { watchCount })}
        </Text>
        <ViewFilledIcon boxSize={5} color="link-01" />
      </HStack>
    </PanelHeader>
  );
};
