import { Box, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { AutoTransactionStepProps, AutoTransactionStepRow, Text } from "@looksrare/uikit";
import { YourEntry } from "../../../YourEntry/YourEntry";
import { EntryPosition } from "../../../../types";

interface RoundEntryEnteringBodyProps {
  entryAmountWei: bigint;
  entryPosition: EntryPosition;
  useHandleTransaction: AutoTransactionStepProps["useHandleTransaction"];
}

export const RoundEntryEnteringBody = ({
  entryPosition,
  entryAmountWei,
  useHandleTransaction,
}: RoundEntryEnteringBodyProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack pt={6} px={4} pb={8}>
        <YourEntry entryAmount={entryAmountWei} entryType={entryPosition} size="md" width="100%" />

        <Text textStyle="body" color="text-02">
          {t("mod::Confirm your entry in your wallet app before the timer runs out.")}
        </Text>
      </Stack>

      <Box px={4} pb={8}>
        <Box px={4} py={6} backgroundColor="ui-bg" borderRadius="container">
          <AutoTransactionStepRow
            ctaText={t("mod::Entering round")}
            useHandleTransaction={useHandleTransaction}
            isStepActive
          />
        </Box>
      </Box>
    </>
  );
};
