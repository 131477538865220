import { Icon, IconProps } from "../Icon";

const Auction = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2708 7.47121C9.93345 6.88642 9.90866 6.14255 10.2704 5.51582L11.7355 2.97737C12.275 2.04274 13.4697 1.72252 14.404 2.26213L21.1711 6.17032C22.1054 6.70992 22.4256 7.90503 21.8861 8.83966L20.421 11.3781C20.0597 12.0041 19.4043 12.3545 18.7302 12.3555L17.1346 15.12L28.9775 21.9597L28.0007 23.652L16.1578 16.8123L14.5613 19.5784C14.8976 20.1629 14.9219 20.9058 14.5606 21.5319L13.0954 24.0703C12.556 25.0049 11.3613 25.3252 10.4269 24.7856L3.65984 20.8774C2.7255 20.3378 2.40537 19.1426 2.94481 18.208L4.40993 15.6696C4.77167 15.0428 5.42807 14.6924 6.10302 14.6922L10.2708 7.47121ZM18.7295 10.4006L11.9633 6.49285L11.9625 6.49236L13.4273 3.95442L20.1944 7.86261L18.7295 10.4006ZM11.8321 8.67417L7.92546 15.4429L13.0008 18.374L16.9075 11.6053L11.8321 8.67417ZM4.63658 19.1851L6.1017 16.6466L12.8688 20.5548L11.4037 23.0933L4.63658 19.1851ZM29.0233 29H30V27.0459H29.0233H2.97674H2V29H2.97674H29.0233Z"
      fill="currentColor"
    />
  </Icon>
);

export default Auction;
