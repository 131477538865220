import { useState } from "react";
import { TimeAgoOptions, timeAgo } from "@looksrare/utils";
import { useInterval } from "@chakra-ui/react";
import { Text, TextProps } from "../Text";

interface TimeAgoProps extends TextProps {
  moment: number | Date;
  updateIntervalInMs?: number;
  options?: TimeAgoOptions;
}

export const TimeAgo = ({ moment, updateIntervalInMs = 1000, options, ...props }: TimeAgoProps) => {
  const [, setCurrentTime] = useState(Date.now());

  useInterval(() => {
    setCurrentTime(Date.now());
  }, updateIntervalInMs);

  return <Text {...props}>{timeAgo(moment, options)}</Text>;
};
