import { IconProps, IngotIcon } from "@looksrare/uikit";
import { useAccount } from "wagmi";
import { Badge, Box } from "@chakra-ui/react";
import { useUser } from "@/queries/user";

export const IngotIconWithSpinsCounter = (props: IconProps) => {
  const { address } = useAccount();
  const { data: user } = useUser(address!, {
    enabled: !!address,
  });

  const availableSpinsNumber = Number(user?.luckySpinTickets || 0);
  const availableSpinsDisplay = availableSpinsNumber > 99 ? "99+" : availableSpinsNumber;

  return (
    <Box>
      {availableSpinsNumber > 0 && (
        <Badge
          position="absolute"
          top="-25%"
          px={1}
          right="calc(-100% - 4px)" // to the right border but respect padding
          bg="support-error-inverse"
          color="white"
          textAlign="center"
        >
          {availableSpinsDisplay}
        </Badge>
      )}
      <IngotIcon {...props} />
    </Box>
  );
};
