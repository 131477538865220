import { FC, PropsWithChildren } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useAccount } from "wagmi";
import { Button } from "@looksrare/uikit";
import uniqueId from "lodash/uniqueId";
import noop from "lodash/noop";
import { isAuthorized, useSignInHandler } from "@looksrare/utils";
import { AuthorizedActionButtonProps, SignInModal } from "../Modals";

export const AuthorizedActionButton: FC<PropsWithChildren<AuthorizedActionButtonProps>> = ({
  children,
  onAuthSuccess,
  onAuthFailure = noop,
  authScope,
  as,
  ...props
}) => {
  const { address } = useAccount();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleAuthSuccess = (jwt?: string) => {
    onClose();
    onAuthSuccess(jwt);
  };

  const { txStatus, signInHandler } = useSignInHandler({ onAuthSuccess: handleAuthSuccess, onAuthFailure });

  const CTA = as ?? Button;

  const handleClick = () => {
    const accountIsAuthorized = isAuthorized(address, authScope);

    if (!accountIsAuthorized) {
      onOpen();
      signInHandler();
    } else {
      // Proceed directly to the original requested action
      onAuthSuccess();
    }
  };

  return (
    <>
      <CTA onClick={handleClick} isDisabled={isOpen} {...props}>
        {children}
      </CTA>
      <SignInModal
        key={uniqueId("signin-modal-")}
        isOpen={isOpen}
        onClose={onClose}
        txStatus={txStatus}
        signInHandler={signInHandler}
      />
    </>
  );
};
