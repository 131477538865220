import { IS_TESTNET, addressesByNetwork, addressesByNetworkInvasion } from "@looksrare/config";
import { ChainId } from "@looksrare/config";
import { useRouter } from "next/router";
import { blastSepolia, blast } from "viem/chains";
import { useCurrentChainId } from "./useGlobalStore";

export const useAddressesByNetwork = (chainId?: ChainId) => {
  const currentChainId = useCurrentChainId();
  const { pathname } = useRouter();

  // This allows us to support blast chain exclusively for the
  // invasion game without having a wallet connected.
  if (pathname.includes("/invasion")) {
    return IS_TESTNET ? addressesByNetworkInvasion[blastSepolia.id] : addressesByNetworkInvasion[blast.id];
  }

  return addressesByNetwork[chainId || currentChainId];
};
