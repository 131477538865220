import { Box, BoxProps, Flex, FlexProps, forwardRef } from "@chakra-ui/react";
import { Children, ReactNode } from "react";

export interface CarouselProps extends FlexProps {
  children: ReactNode;
  contentContainerProps?: BoxProps;
  hideScrollbar?: boolean;
}

export const Carousel = forwardRef<CarouselProps, "div">(
  ({ children, contentContainerProps, hideScrollbar = false, gap = 2, ...props }, ref) => {
    return (
      <Flex
        ref={ref}
        position="relative"
        {...props}
        gap={gap}
        overflowX="auto"
        scrollSnapType="x mandatory"
        sx={
          hideScrollbar
            ? {
                scrollbarWidth: "none",
                "::-webkit-scrollbar": {
                  display: "none",
                },
              }
            : undefined
        }
      >
        {Children.map(children, (child) => {
          if (child) {
            return (
              <Box flexShrink={0} scrollSnapAlign="start" {...contentContainerProps}>
                {child}
              </Box>
            );
          }
        })}
      </Flex>
    );
  }
);
