import { useEffect, useMemo } from "react";
import { useBreakpointValue } from "@chakra-ui/react";
import { type Address } from "viem";
import { useElapsedTime } from "use-elapsed-time";
import { CircularCountdown, Text } from "@looksrare/uikit";
import { pokeDurSec } from "../../config";
import { CountdownWrapper, getStrokeColor } from "./shared";

interface PokeDurationTimerProps {
  playerAddress: Address;
  isPokingUntil?: string | null;
}

export const PokeDurationTimer = ({ playerAddress, isPokingUntil }: PokeDurationTimerProps) => {
  const pokingDur = useMemo(() => {
    return isPokingUntil ? Math.floor((new Date(isPokingUntil).getTime() - Date.now()) / 1_000) : pokeDurSec;
  }, [isPokingUntil]);
  const { elapsedTime, reset } = useElapsedTime({
    isPlaying: !!isPokingUntil,
    duration: pokingDur,
  });
  const countdown = pokingDur - elapsedTime;
  const color = getStrokeColor(countdown, false);

  useEffect(() => {
    reset();
  }, [playerAddress, reset]);

  const countdownSize = useBreakpointValue({ base: 56, sm: 100 }) || 100;
  return (
    <CountdownWrapper>
      <CircularCountdown
        size={countdownSize}
        duration={pokingDur}
        countdown={countdown}
        strokeWidth="6px"
        strokeColor={color.stroke}
        trackColor="border-01"
      >
        <Text
          textStyle="display-02"
          bold
          lineHeight={14}
          color={color.text}
          sx={{ fontVariantNumeric: "tabular-nums" }}
        >
          {Math.floor(countdown)}
        </Text>
      </CircularCountdown>
    </CountdownWrapper>
  );
};
