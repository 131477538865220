import { Icon, IconProps } from "../Icon";

const Image = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 4H6C4.89543 4 4 4.89543 4 6V26C4 27.1046 4.89543 28 6 28H26C27.1046 28 28 27.1046 28 26V6C28 4.89543 27.1046 4 26 4ZM6 26V20L11 15L16.59 20.59C17.3701 21.3654 18.6299 21.3654 19.41 20.59L21 19L26 24V26H6ZM22.41 17.58L26 21.17V6H6V17.17L9.59 13.58C10.3701 12.8046 11.6299 12.8046 12.41 13.58L18 19.17L19.59 17.58C20.3701 16.8046 21.6299 16.8046 22.41 17.58ZM19 14C20.6569 14 22 12.6569 22 11C22 9.34315 20.6569 8 19 8C17.3431 8 16 9.34315 16 11C16 12.6569 17.3431 14 19 14ZM20 11C20 10.4477 19.5523 10 19 10C18.4477 10 18 10.4477 18 11C18 11.5523 18.4477 12 19 12C19.5523 12 20 11.5523 20 11Z"
      fill="currentColor"
    />
  </Icon>
);

export default Image;
