import { useTranslation } from "next-i18next";
import { BoxProps } from "@chakra-ui/react";
import { pageHeightRemDesktop, pageHeightRemMobile } from "@looksrare/chakra-theme";
import { Container, EditAltIcon, Text } from "@looksrare/uikit";
import { AuthorizedActionButton } from "../Buttons";

interface Props extends BoxProps {
  onAuthSuccess: (jwt?: string) => void;
  onAuthFailure?: () => void;
}

export const SignInPlaceholderPageContent: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onAuthSuccess,
  onAuthFailure,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Container
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight={{ base: pageHeightRemMobile, lg: pageHeightRemDesktop }}
      maxW="608px"
      px={2}
      {...props}
    >
      <EditAltIcon boxSize={16} mb={8} color="interactive-03" />
      <Text as="h1" textStyle="heading-03" textAlign="center" mb={6} bold>
        {t("Signature Required")}
      </Text>
      {children || (
        <Text color="text-02" textAlign="center">
          {t("This lets us verify that you're the owner of the connected wallet.")}
        </Text>
      )}
      <AuthorizedActionButton onAuthSuccess={onAuthSuccess} onAuthFailure={onAuthFailure} mt={6}>
        {t("Sign Message in Wallet")}
      </AuthorizedActionButton>
    </Container>
  );
};
