import { AspectRatio, chakra, HStack, ModalBody, ModalFooter, Stack } from "@chakra-ui/react";
import { Button, CloudinaryImage, Link, Modal, Text } from "@looksrare/uikit";
import { Trans, useTranslation } from "next-i18next";
import { useCountdown } from "@looksrare/utils";
import { addSeconds } from "date-fns/addSeconds";
import noop from "lodash/noop";
import { useEffect, useState } from "react";
import { MOON_OR_DOOM_DOCS_PAGE } from "../../../config/constants";

interface RoundEntryExplanationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const RoundEntryExplanationModal = ({ isOpen, onClose }: RoundEntryExplanationModalProps) => {
  const { t } = useTranslation();
  const [endTimestamp, setEndTimestamp] = useState<number>(Date.now());

  useEffect(() => {
    if (isOpen) {
      setEndTimestamp(addSeconds(new Date(), 7).getTime());
    }
  }, [isOpen]);

  const duration = useCountdown(endTimestamp, true);
  const canClose = duration?.seconds !== undefined && duration.seconds === 0;

  return (
    <Modal isOpen={isOpen} onClose={noop} modalContentProps={{ overflow: "hidden", maxWidth: "768px" }}>
      <AspectRatio ratio={3 / 2}>
        <CloudinaryImage
          src="/images/moon-or-doom/round-entry-explainer.png"
          alt="Round Entry Explanation"
          width={768}
          height={512}
          layout="fill"
        />
      </AspectRatio>

      <ModalBody pt={8} pb={6}>
        <Stack spacing={6}>
          <Trans i18nKey="mod::roundExplainerModalTitle">
            <Text textStyle="display-03" bold>
              YOU ENTERED THE <chakra.span color="link-01">NEXT ROUND.</chakra.span>
            </Text>
          </Trans>
          <Trans i18nKey="mod::roundExplainerModalDescription">
            <Text textStyle="body" whiteSpace="pre-wrap">
              In MOON or DOOM, you always enter the NEXT round, not the current round.
              <chakra.span color="link-01" fontWeight="bold">
                The round you entered will start when the current round ends.
              </chakra.span>
            </Text>
          </Trans>
        </Stack>
      </ModalBody>

      <ModalFooter>
        <HStack justifyContent="flex-end" width="100%">
          <Button
            as={Link}
            href={MOON_OR_DOOM_DOCS_PAGE}
            width="50%"
            maxWidth="188px"
            colorScheme="secondary"
            isExternal
          >
            {t("Review the Rules")}
          </Button>

          <Button onClick={onClose} width="50%" maxWidth="188px" isDisabled={!canClose}>
            {canClose ? t("Close") : t("Close in: {{seconds}}s", { seconds: duration?.seconds ?? 0 })}
          </Button>
        </HStack>
      </ModalFooter>
    </Modal>
  );
};
