import { Icon, IconProps } from "../Icon";

const Sweep = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6 15.83C16.05 16.72 17 18.25 17 20V20.01C17 20.06 17.06 25.55 20.6 28.21C20.95 28.47 21.09 28.93 20.95 29.33C20.81 29.73 20.43 30.01 20 30.01H5.01C4.47 30.01 4.03 29.59 4.01 29.05C4.00989 29.0459 4.00974 29.0409 4.00956 29.0348C3.99398 28.5004 3.75216 20.2093 7.45 16.64C8.71 15.42 10.26 14.89 12.07 15.03C12.2333 15.0378 12.3905 15.0697 12.551 15.1024C12.597 15.1117 12.6433 15.1211 12.69 15.13L20.27 2L22 3L14.6 15.83ZM15 20C15 18.4 13.71 17.14 11.93 17C10.83 16.92 9.91 17.21 9.12 17.85L15.02 20.47C15 20.21 15 20.04 15 20ZM14 25C14 25.06 14.07 26.46 15.45 28H17.59C16.3 26.32 15.66 24.36 15.33 22.8L7.80001 19.45C6.29001 22.11 6.03001 26.16 6.00001 28H8.67C8.02 25.62 8.00001 24.08 8.00001 24H10C10 24.02 10.04 25.6 10.75 28H12.97C12.02 26.39 12 25.07 12 25H14ZM26 20H20V18H26V20ZM30 28H24V26H30V28ZM22 24H28V22H22V24Z"
      fill="currentColor"
    />
  </Icon>
);

export default Sweep;
