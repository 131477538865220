import type { AppProps } from "next/app";
import { appWithTranslation, useTranslation } from "next-i18next";
import { DefaultSeo } from "next-seo";
import { Fonts, GlobalAppWrapper, GoogleTagManager, Providers } from "@/components";
import { getChainInfo } from "@/utils";

// BigInt patch
import "@looksrare/utils/bigint-patch";
import "@rainbow-me/rainbowkit/styles.css";
import "@/components/Datepicker/theme.css";

import nextI18nextConfig from "../../next-i18next.config";
import getDefaultSeo from "../utils/seo";

const App = ({ Component, pageProps }: AppProps) => {
  const { t } = useTranslation();
  const { appUrl } = getChainInfo();
  const defaultSeoProps = getDefaultSeo(t, appUrl);

  return (
    <Providers>
      <GoogleTagManager />
      <GlobalAppWrapper>
        <DefaultSeo {...defaultSeoProps} />
        <Fonts />
        <Component {...pageProps} />
      </GlobalAppWrapper>
    </Providers>
  );
};

export default appWithTranslation(App, nextI18nextConfig);
