export const miniRadius = 8;
export const buttonRadius = 12;
export const containerRadius = 16;
export const dialogRadius = 24;
export const squareRadius = 0;
export const circularRadius = 9999;

export const radii = {
  mini: `${miniRadius}px`,
  button: `${buttonRadius}px`,
  container: `${containerRadius}px`,
  dialog: `${dialogRadius}px`,
  square: `${squareRadius}px`,
  circular: `${circularRadius}px`,
};
