import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { ArrowLeftIcon, CloseIcon, IconProps } from "../Icons";

interface ModalButtonProps extends Omit<IconButtonProps, "aria-label"> {
  dataId?: string;
  "aria-label"?: string;
  onClick?: () => void;
  iconProps?: IconProps;
}

export const ModalBackButton = ({ iconProps, dataId, ...props }: ModalButtonProps) => (
  <IconButton
    {...(dataId && { "data-id": `${dataId}-header-back-button` })}
    size="xs"
    variant="ghost"
    colorScheme="secondary"
    aria-label="Go back"
    mr={4}
    icon={<ArrowLeftIcon fill="text-01" {...iconProps} />}
    {...props}
  />
);

export const ModalCloseButton = ({ iconProps, dataId, ...props }: ModalButtonProps) => (
  <IconButton
    {...(dataId && { "data-id": `${dataId}-header-close-button` })}
    size="xs"
    variant="ghost"
    colorScheme="secondary"
    aria-label="Close modal"
    icon={<CloseIcon fill="text-01" {...iconProps} />}
    {...props}
  />
);
