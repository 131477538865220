import { Icon, IconProps } from "../Icon";

const NotificationAdded = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 -8.57621e-06L22 4.99999L20 4.99999L20 -8.44867e-06L22 -8.57621e-06ZM25.8506 14.6382L22.1624 13.65L19.8624 9.66625C17.2854 5.21615 11.7695 3.42257 7.06813 5.50599L6.06813 3.77394L4.33608 4.77394L5.33608 6.50599C1.21364 9.55684 0.0147104 15.2035 2.54185 19.6662L4.84185 23.65L3.85358 27.3382C3.77164 27.5914 3.8046 27.8677 3.94377 28.0945L5.44377 30.6925C5.56344 30.9356 5.78213 31.1151 6.04379 31.1853C6.30546 31.2554 6.58465 31.2092 6.8098 31.0586L14.604 26.5586C15.9847 28.95 19.0427 29.7694 21.4342 28.3887C23.8256 27.008 24.645 23.95 23.2643 21.5586L27.5944 19.0586C27.8374 18.9389 28.017 18.7202 28.0871 18.4585C28.1573 18.1969 28.1111 17.9177 27.9604 17.6925L26.4604 15.0945C26.3336 14.8605 26.1108 14.6938 25.8506 14.6382ZM20.4343 26.6565C18.9994 27.485 17.1646 26.9933 16.3362 25.5584L21.5324 22.5585C22.3608 23.9933 21.8692 25.8281 20.4343 26.6565ZM32 9.99999L27 9.99999L27 12L32 12L32 9.99999ZM26.4142 6.94972L29.9497 3.41418L28.5355 1.99997L25 5.53551L26.4142 6.94972Z"
      fill="currentColor"
    />
  </Icon>
);

export default NotificationAdded;
