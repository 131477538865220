import { Icon, IconProps } from "../Icon";

const CartRemoveFromCart = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 2L28.5 0.5L1.5 27.5L2.99999 29L8 24H26V22H10L14 18H26C26.4767 18.0116 26.8952 17.6851 27 17.22L29 8.21996C29.0679 7.9193 28.9934 7.60412 28.798 7.36573C28.6026 7.12734 28.3081 6.99239 28 6.99996H25L30 2ZM19 6.99996L8.00004 18H8L8.00001 18L6.30305 19.697L3.18 3.99996H0V1.99996H4C4.48276 1.99023 4.90353 2.32684 5 2.79996L5.82 6.99996H19ZM12 28C12 29.1045 11.1046 30 10 30C8.89543 30 8 29.1045 8 28C8 26.8954 8.89543 26 10 26C11.1046 26 12 26.8954 12 28ZM26 28C26 29.1045 25.1046 30 24 30C22.8954 30 22 29.1045 22 28C22 26.8954 22.8954 26 24 26C25.1046 26 26 26.8954 26 28Z"
      fill="currentColor"
    />
  </Icon>
);

export default CartRemoveFromCart;
