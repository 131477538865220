import { Icon, IconProps } from "@chakra-ui/react";

export const YoloLogoHorizontalColorOnDark = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 364 42" width="364" height="42" {...props}>
      <path
        d="M125.471 0.955078C136.543 0.955078 145.509 9.93643 145.509 21.0279C145.509 32.0652 136.543 41.0466 125.471 41.0466C114.453 41.0466 105.487 32.0652 105.487 21.0279C105.487 9.93643 114.453 0.955078 125.471 0.955078ZM125.471 29.7928C130.332 29.7928 133.951 25.8432 133.951 21.0279C133.951 16.1585 130.332 12.2088 125.471 12.2088C120.61 12.2088 116.991 16.1585 116.991 21.0279C116.991 25.8432 120.61 29.7928 125.471 29.7928Z"
        fill="#CCFD07"
      />
      <path d="M90.9309 28.4401H104.974V40.0184H78.8325V2.14525H90.9309V28.4401Z" fill="#CCFD07" />
      <path
        d="M55.8998 0.955078C66.972 0.955078 75.9377 9.93643 75.9377 21.0279C75.9377 32.0652 66.972 41.0466 55.8998 41.0466C44.8817 41.0466 35.916 32.0652 35.916 21.0279C35.916 9.93643 44.8817 0.955078 55.8998 0.955078ZM55.8998 29.7928C60.7608 29.7928 64.3795 25.8432 64.3795 21.0279C64.3795 16.1585 60.7608 12.2088 55.8998 12.2088C51.0389 12.2088 47.4202 16.1585 47.4202 21.0279C47.4202 25.8432 51.0389 29.7928 55.8998 29.7928Z"
        fill="#CCFD07"
      />
      <path
        d="M25.1295 2.14525H38.0381L25.6156 39.9643H12.7071L17.406 25.7348H8.00821L0.716797 2.14525H14.1114L19.1884 20.1621L25.1295 2.14525Z"
        fill="#CCFD07"
      />
      <path
        d="M363.284 27.808C363.284 34.5079 357.621 39.9111 350.717 39.9111H331.73V28.7266H350.717C351.526 28.7266 352.065 28.2403 352.065 27.808C352.065 27.2137 351.849 26.5653 350.717 26.5653H343.165C336.099 26.5653 330.598 21.2162 330.598 14.3541C330.598 7.49213 336.099 2.14299 343.165 2.14299H362.151V13.3275H343.165C342.68 13.3275 341.763 13.5437 341.763 14.3541C341.763 15.0025 342.518 15.3807 343.165 15.3807H350.717C357.728 15.3807 363.284 20.8379 363.284 27.754V27.808Z"
        fill="#F8FAFC"
      />
      <path
        d="M327.167 13.3275H306.455V15.4348H327.167V26.6193H306.455V28.7266H327.167V39.9111H294.696V2.14299H327.167V13.3275Z"
        fill="#F8FAFC"
      />
      <path
        d="M250.676 2.14299H262.111L264.592 39.9111H253.75L250.676 2.14299ZM267.019 2.14299H278.508L275.433 39.9111H264.592L267.019 2.14299ZM290.266 39.9111H278.508V2.14299H290.266V39.9111ZM250.676 39.9111H238.918V2.14299H250.676V39.9111Z"
        fill="#F8FAFC"
      />
      <path
        d="M235.906 39.9111H222.853L216.273 2.14299L209.693 39.9111H196.64L203.166 2.14299H229.326L235.906 39.9111Z"
        fill="#F8FAFC"
      />
      <path
        d="M196.259 18.0831V25.8096C196.259 33.8063 188.384 41.0466 178.405 41.0466C167.402 41.0466 158.395 32.0233 158.395 21.0008C158.395 9.92434 167.402 0.955078 178.405 0.955078C185.795 0.955078 192.213 4.95342 195.665 10.9509L185.795 16.6783C184.393 14.0307 181.696 12.1937 178.405 12.1937C173.551 12.1937 169.937 16.138 169.937 21.0008C169.937 25.8637 173.551 29.808 178.405 29.808C180.833 29.808 183.044 28.6733 184.608 27.0524L178.837 26.9983V18.0831H196.259Z"
        fill="#F8FAFC"
      />
    </Icon>
  );
};
