import { Icon, IconProps } from "../Icon";

const Website = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 4H4C2.89543 4 2 4.89543 2 6V26C2 27.1046 2.89543 28 4 28H28C29.1046 28 30 27.1046 30 26V6C30 4.89543 29.1046 4 28 4ZM28 6V8H4V6H28ZM4 10V26H28V10H4ZM8 14H19V16H8V14ZM21 19H8V21H21V19Z"
      fill="currentColor"
    />
  </Icon>
);

export default Website;
