import { forwardRef } from "react";
import { HStack, type StackProps, Skeleton, Stack } from "@chakra-ui/react";
import times from "lodash/times";
import { useActiveRoundLogs, useScrollToBottom } from "../../hooks";
import { CommentaryLine } from "../CommentaryLine";

const ContentBox = forwardRef<HTMLDivElement, StackProps>((props, ref) => (
  <Stack ref={ref} bg="ui-bg" spacing={0.5} p={4} overflowY="auto" height="100%" flex={1} {...props} />
));
ContentBox.displayName = "ContentBox";

export const Commentary = () => {
  const roundLogQuery = useActiveRoundLogs();
  const logsLength = !!roundLogQuery.data?.logs ? roundLogQuery.data.logs.length : 0;
  const nodeRef = useScrollToBottom(logsLength);

  if (roundLogQuery.isLoading) {
    return (
      <ContentBox>
        {times(5).map((n) => (
          <HStack key={n} mb={1}>
            <Skeleton height={3} width="10%" animation={0} />
            <Skeleton height={3} width="20%" animation={0} />
          </HStack>
        ))}
      </ContentBox>
    );
  }

  if (roundLogQuery.isSuccess) {
    const { caveOnChainId, roundOnChainId, logs } = roundLogQuery.data;
    return (
      <ContentBox ref={nodeRef}>
        {/* @todo-ptb older logs have the exact same timestamp so we need to get creative with the keys */}
        {logs.map((log, index) => {
          return (
            <CommentaryLine
              key={[caveOnChainId, roundOnChainId, log.type, log.timestamp, index].join("")}
              color="text-03"
              ptbGraphQlLog={log}
            />
          );
        })}
      </ContentBox>
    );
  }
  return null;
};
