import { Icon, IconProps } from "../Icon";

const ViewOff = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.23989 22.51L6.6699 21.09C5.10411 19.6841 3.87387 17.9447 3.0699 16C5.0999 10.93 10.6999 7 15.9999 7C17.3638 7.018 18.7153 7.26128 19.9999 7.72L21.5499 6.16C19.7925 5.41732 17.9076 5.02335 15.9999 5C9.3378 5.25053 3.46386 9.4417 1.0599 15.66C0.980462 15.8797 0.980462 16.1203 1.0599 16.34C1.94814 18.697 3.38032 20.811 5.23989 22.51ZM25.2 8.22C27.7981 10.087 29.7933 12.6732 30.94 15.66C31.0194 15.8797 31.0194 16.1203 30.94 16.34C28.536 22.5583 22.6621 26.7495 16 27C13.364 26.9574 10.7839 26.234 8.51 24.9L3.41 30L2 28.59L28.59 2L30 3.41L25.2 8.22ZM15.6999 12.03C13.7191 12.1737 12.1437 13.7491 11.9999 15.73L10.1799 17.54C9.63647 15.476 10.2303 13.2788 11.7395 11.7696C13.2487 10.2604 15.446 9.66653 17.5099 10.21L15.6999 12.03ZM20 16C19.9914 17.4248 19.2257 18.7374 17.9896 19.4461C16.7536 20.1547 15.2339 20.1524 14 19.44L19.44 14C19.7995 14.6057 19.9927 15.2957 20 16ZM10 23.42C11.8439 24.4214 13.9021 24.9633 16 25C21.3 25 26.9 21.07 28.93 16C27.849 13.4354 26.0546 11.235 23.76 9.66L20.89 12.53C22.5444 14.9143 22.2552 18.141 20.2031 20.1931C18.151 22.2452 14.9243 22.5344 12.54 20.88L10 23.42Z"
      fill="currentColor"
    />
  </Icon>
);

export default ViewOff;
