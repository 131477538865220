import { useEffect, useState } from "react";
import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import { useWidgetBotCrate } from "@looksrare/utils";
import { Flex, Stack, TagCloseButton, TagLabel } from "@chakra-ui/react";
import { Button, Container, Tag, useWidgetBotOptions } from "@looksrare/uikit";
import { useRouter } from "next/router";
import { Header } from "../components/History/Header";
import { HistoryTable } from "../components/History/HistoryTable";
import { HistoryTabs } from "../components/HistoryTabs";
import { usePtbHistory } from "../hooks/usePtbHistory";
import { type PtbRoundsFilter, PtbRoundsSort } from "../graphql";
import { PtbRoundStatus } from "../types";
import { useGetHistoryQueryParams } from "../hooks/useGetHistoryQueryParams";
import { getPtbContractNameFromNetwork } from "../utils";
import { useCaveIds } from "../hooks";

export type CaveOption = {
  id: string;
  name: string;
};

export const PTB_HISTORY_PER_PAGE = 10;
export type StatusOption = "all" | "completed" | "canceled";
export const statusFilters = {
  all: [PtbRoundStatus.OPEN, PtbRoundStatus.DRAWING, PtbRoundStatus.REVEALED, PtbRoundStatus.CANCELLED],
  completed: [PtbRoundStatus.REVEALED],
  canceled: [PtbRoundStatus.CANCELLED],
};

interface HistoryViewProps {
  serverSideCaveOptions?: CaveOption[];
}

export const HistoryView = ({ serverSideCaveOptions }: HistoryViewProps) => {
  useWidgetBotCrate(useWidgetBotOptions());

  const { address } = useAccount();
  const { t } = useTranslation();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const offset = (currentPageNumber - 1) * PTB_HISTORY_PER_PAGE;
  const { network } = useGetHistoryQueryParams();
  const { query } = useRouter();
  const { fromCave } = query;

  // Temporary workaround while debugging aws cutover.
  // serverSideCaveOptions is provided via getServerSideProps, but it's not available on the client sometimes
  const caveOptionsQuery = useCaveIds(network, { enabled: !serverSideCaveOptions });
  const caveOptions = serverSideCaveOptions || caveOptionsQuery.data || [];

  // construct the query filters
  const [selectedStatusOption, setSelectedStatusOption] = useState<StatusOption>("completed");
  const [isShowMyEntries, setIsShowMyEntries] = useState<boolean>(false);
  const [selectedCaves, setSelectedCaves] = useState<CaveOption[]>(
    caveOptions.filter(({ id }) => id === fromCave) || []
  );

  const toggleCaveOption = (option: CaveOption) => {
    setSelectedCaves((prev) => {
      if (prev.some((caveOption) => caveOption.id === option.id)) {
        return prev.filter((caveOption) => caveOption.id !== option.id);
      }
      return [...prev, option];
    });
  };

  // Note: The designs only clear the selected caves not all filters
  const handleClearSelectedCaves = () => {
    setSelectedCaves([]);
  };

  const filterIds = selectedCaves.map(({ id }) => Number(id));
  const filter: PtbRoundsFilter = {
    caveOnChainIds: filterIds.length ? filterIds : undefined,
    player: isShowMyEntries ? address : undefined,
    status: statusFilters[selectedStatusOption],
  };

  const { data: history, isLoading } = usePtbHistory({
    filter,
    sort: PtbRoundsSort.CREATED_AT_DESC,
    pagination: { offset, first: PTB_HISTORY_PER_PAGE },
    contract: getPtbContractNameFromNetwork(network) ?? "PTB_V1",
  });
  const { rounds, total } = history || {};

  const [safeTotal, setSafeTotal] = useState(total || 1);
  useEffect(() => {
    if (typeof total === "undefined" || total === safeTotal) {
      return;
    }
    setSafeTotal(total);
  }, [safeTotal, total]);

  return (
    <Container position="relative" pt={4} pb={8} maxWidth="1440px">
      <HistoryTabs />
      <Stack spacing={4}>
        {/* Header */}
        <Header
          caveOptions={caveOptions}
          selectedOptions={selectedCaves}
          selectedStatus={selectedStatusOption}
          setSelectedStatus={setSelectedStatusOption}
          toggleCaveOption={toggleCaveOption}
          toggleShowMyEntries={() => {
            setIsShowMyEntries(!isShowMyEntries);
          }}
          isShowMyEntries={isShowMyEntries}
        />

        {/* Cave Filters */}
        {filter.caveOnChainIds?.length && (
          <Flex gap={2} flexWrap="wrap" rowGap={2}>
            <Button variant="outline" colorScheme="secondary" size="xs" round onClick={handleClearSelectedCaves}>
              {t("Clear Filters")}
            </Button>
            {selectedCaves.map((option) => {
              const { id, name } = option;
              return (
                <Tag
                  key={id}
                  variant="solid"
                  colorScheme="secondary"
                  isClickable
                  onClick={() => toggleCaveOption(option)}
                >
                  <TagLabel>{name || id}</TagLabel>
                  <TagCloseButton />
                </Tag>
              );
            })}
          </Flex>
        )}

        {/* Table */}
        <HistoryTable
          page={currentPageNumber}
          setPage={setCurrentPageNumber}
          totalRows={safeTotal}
          rounds={rounds}
          isLoading={isLoading}
        />
      </Stack>
    </Container>
  );
};
