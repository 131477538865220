import { Icon, IconProps } from "../Icon";

const ArrowDownLeft = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 26V24H9.41L26 7.41L24.59 6L8 22.59V10H6V26H22Z"
      fill="currentColor"
    />
  </Icon>
);

export default ArrowDownLeft;
