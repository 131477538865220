import { useCallback } from "react";
import { usePublicClient, useWalletClient } from "wagmi";
import { MoonOrDoomNativeTokenAbi } from "@looksrare/config";
import { NoPublicClientError } from "@looksrare/utils";
import { MoDContract } from "@looksrare/yolo-games-gql-typegen";
import { useMoDContractAddress } from "../../../hooks/useMoDContractAddress";
import { EntryPosition } from "../../../types";

export const useEnterRound = (contract: MoDContract) => {
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const contractAddress = useMoDContractAddress(contract);

  return useCallback(
    async (currentRoundId: bigint, entryPosition: EntryPosition, entryAmount: bigint) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new NoPublicClientError();
      }

      const [account] = await walletClient.getAddresses();
      const { request } = await publicClient.simulateContract({
        address: contractAddress,
        abi: MoonOrDoomNativeTokenAbi,
        functionName: entryPosition === "moon" ? "enterMoon" : "enterDoom",
        args: [currentRoundId],
        value: entryAmount,
        account,
      });

      return walletClient.writeContract(request);
    },
    [publicClient, walletClient, contractAddress]
  );
};

export const useClaimEntries = (contract: MoDContract) => {
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const contractAddress = useMoDContractAddress(contract);

  return useCallback(
    async (epochIds: bigint[]) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new NoPublicClientError();
      }

      const [account] = await walletClient.getAddresses();

      const { request } = await publicClient.simulateContract({
        address: contractAddress,
        abi: MoonOrDoomNativeTokenAbi,
        functionName: "claim",
        args: [epochIds],
        account,
      });

      return walletClient.writeContract(request);
    },
    [contractAddress, publicClient, walletClient]
  );
};
