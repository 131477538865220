import { Icon, IconProps } from "../Icon";

const UsdcIcon = (props: IconProps) => (
  <Icon viewBox="0 0 96 96" {...props}>
    <rect width="96" height="96" rx="48" fill="white" />
    <path
      d="M48 96C74.6002 96 96 74.6002 96 48C96 21.3998 74.6002 0 48 0C21.3998 0 0 21.3998 0 48C0 74.6002 21.3998 96 48 96Z"
      fill="#2775CA"
    />
    <path
      d="M61.2 55.5998C61.2 48.6 57 46.2 48.6 45.2002C42.6 44.4 41.4 42.8002 41.4 39.9998C41.4 37.1995 43.4001 35.4 47.4 35.4C51 35.4 53.0001 36.6 54 39.6C54.2001 40.2 54.8001 40.5998 55.4001 40.5998H58.5998C59.4 40.5998 60 39.9998 60 39.2002V39C59.1998 34.5998 55.5998 31.2 51 30.8002V26.0002C51 25.2 50.4 24.6 49.4001 24.3998H46.4001C45.6 24.3998 45 24.9998 44.7998 26.0002V30.6C38.7998 31.4002 35.0001 35.4 35.0001 40.4002C35.0001 47.0002 39 49.5998 47.4 50.6002C53.0001 51.6 54.8001 52.8 54.8001 56.0002C54.8001 59.2003 51.9998 61.4002 48.2001 61.4002C42.9998 61.4002 41.1998 59.1998 40.5998 56.1998C40.4001 55.4002 39.8001 54.9998 39.2001 54.9998H35.7998C35.0001 54.9998 34.4001 55.5998 34.4001 56.4V56.6002C35.1998 61.5998 38.4 65.1998 45 66.2002V71.0002C45 71.7998 45.6 72.3998 46.5998 72.6H49.5998C50.4 72.6 51 72 51.2001 71.0002V66.2002C57.2001 65.1998 61.2 60.9998 61.2 55.5998Z"
      fill="white"
    />
    <path
      d="M37.8 76.5998C22.2 71.0001 14.1998 53.6002 20.0002 38.1998C23.0002 29.7998 29.6002 23.4 37.8 20.4C38.6002 20.0002 39 19.4002 39 18.3998V15.6C39 14.7998 38.6002 14.1998 37.8 14.0002C37.5998 14.0002 37.2 14.0002 36.9998 14.1998C18 20.1998 7.59983 40.4002 13.5998 59.4C17.1998 70.5998 25.8 79.2 36.9998 82.8C37.8 83.1998 38.6002 82.8 38.7998 81.9998C39 81.8001 39 81.6 39 81.2001V78.3998C39 77.7998 38.4 77.0001 37.8 76.5998ZM59.0002 14.1998C58.2 13.8 57.3998 14.1998 57.2002 15C57 15.2002 57 15.3998 57 15.8002V18.6C57 19.4002 57.6 20.1998 58.2 20.6002C73.8 26.1998 81.8002 43.5998 75.9998 59.0002C72.9998 67.4002 66.3998 73.8 58.2 76.8C57.3998 77.1998 57 77.7998 57 78.8002V81.6C57 82.4002 57.3998 83.0002 58.2 83.1998C58.4002 83.1998 58.8 83.1998 59.0002 83.0002C78 77.0002 88.4002 56.7998 82.4002 37.8C78.8002 26.4 69.9998 17.7998 59.0002 14.1998Z"
      fill="white"
    />
  </Icon>
);

export default UsdcIcon;
