import { PtbRoundLogType } from "../../types";
import {
  type RoundLogArgs,
  type RoundLogTextGetter,
  caveResetLogs,
  playerDiedLogs,
  playerJoinedLogs,
  playerPassLogs,
  playerPokeLogs,
  playerReadyLogs,
  playerSurvivedLogs,
  roundDrawingLogs,
  roundDrawnLogs,
  roundOpenedLogs,
  roundRevealedLogs,
  potSplitLogs,
} from "./CommentaryText";

export const eventText = new Map<PtbRoundLogType, RoundLogTextGetter>();

// Round Opened
eventText.set(PtbRoundLogType.ROUND_OPENED, roundOpenedLogs);

// Player Joined
eventText.set(PtbRoundLogType.PLAYER_JOINED, playerJoinedLogs);

// Round Drawing
eventText.set(PtbRoundLogType.ROUND_DRAWING, roundDrawingLogs);

// Round Drawn
eventText.set(PtbRoundLogType.ROUND_DRAWN, roundDrawnLogs);

// Player Ready
eventText.set(PtbRoundLogType.PLAYER_READY, playerReadyLogs);

// Player Poke
eventText.set(PtbRoundLogType.PLAYER_POKE, playerPokeLogs);

// Player Pass
eventText.set(PtbRoundLogType.PLAYER_PASS, playerPassLogs);

// Player Survived
eventText.set(PtbRoundLogType.PLAYER_SURVIVED, playerSurvivedLogs);

// Player Died
eventText.set(PtbRoundLogType.PLAYER_DIED, playerDiedLogs);

// Round Revealed
eventText.set(PtbRoundLogType.ROUND_REVEALED, roundRevealedLogs);

// Cave Reset
eventText.set(PtbRoundLogType.CAVE_RESET, caveResetLogs);

// Pot Split
eventText.set(PtbRoundLogType.POT_SPLIT, potSplitLogs);

/**
 * Returns log text based on deterministic random number
 */
export const getLogComponent = (type: PtbRoundLogType, timestamp: string, eventLogArgs: RoundLogArgs) => {
  if (eventText.has(type)) {
    const logListGetter = eventText.get(type)!;
    const logList = logListGetter(eventLogArgs);
    const min = 0;
    const max = logList.length;

    // Round to the nearest second because the GraphQL and Websocket events could be off by milliseconds
    const roundedTimestamp = Math.round(new Date(timestamp).getTime() / 1_000);
    // ((x - min) % d + d) % d
    const index = (((roundedTimestamp - min) % max) + max) % max;
    return logList[index];
  }
  return type;
};
