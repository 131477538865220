import { useEffect, useRef, useState } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import Highcharts, { Chart as HighchartsChart, TooltipFormatterContextObject } from "highcharts";
import HighchartsStock from "highcharts/highstock";
import Boost from "highcharts/modules/boost";
import { Container } from "../Container";
import { defaultHighchartsOptions } from "./defaultOptions";
import { HighchartsTooltip } from "./HighchartsTooltip";

export type HighchartsTooltipFormatterFunction = (
  formatterContext: TooltipFormatterContextObject
) => JSX.Element | null;

interface HighChartsProps extends Omit<BoxProps, "ref"> {
  tooltipFormatter?: HighchartsTooltipFormatterFunction;
  boost?: boolean;
  stockChart?: boolean;
  options?: Highcharts.Options;
}

export const HighCharts = ({
  tooltipFormatter,
  boost = false,
  stockChart = false,
  options = {},
  ...props
}: HighChartsProps) => {
  const ref = useRef(null);
  const [highchartsChart, setHighchartsChart] = useState<HighchartsChart | null>(null);

  const highchartsCallback = (chart: HighchartsChart) => {
    setHighchartsChart(chart);
  };

  useEffect(() => {
    if (ref.current) {
      Highcharts.setOptions({
        lang: {
          thousandsSep: ",",
        },
      });

      if (stockChart) {
        HighchartsStock.stockChart(
          ref.current,
          Highcharts.merge(defaultHighchartsOptions, options),
          highchartsCallback
        );

        if (boost) {
          Boost(HighchartsStock);
        }
      } else {
        Highcharts.chart(ref.current, Highcharts.merge(defaultHighchartsOptions, options), highchartsCallback);

        if (boost) {
          Boost(Highcharts);
        }
      }
    }
  }, [ref, options, stockChart, boost]);

  return (
    <Container>
      <Box {...props} ref={ref} />
      {tooltipFormatter && (
        <HighchartsTooltip chart={highchartsChart} tooltipFormatter={tooltipFormatter} withContainer />
      )}
    </Container>
  );
};
