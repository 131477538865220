import React, { ReactNode } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { useInView, IntersectionOptions } from "react-intersection-observer";

interface Props extends Omit<BoxProps, "placeholder"> {
  placeholder?: ReactNode;
  bottomOffset?: number;
  topOffset?: number;
  options?: IntersectionOptions;
}

export const LazyLoad = ({ children, placeholder, topOffset = 0, bottomOffset = 0, options, ...props }: Props) => {
  const { ref: observedRef, inView: isVisible } = useInView({
    rootMargin: `${topOffset}px 0px ${bottomOffset}px 0px`,
    triggerOnce: true,
    ...options,
  });

  return (
    <Box ref={observedRef} {...props}>
      {isVisible ? children : placeholder}
    </Box>
  );
};
