import { ModalBody, ModalFooter, Stack } from "@chakra-ui/react";
import { Button, Link, Modal, ModalHeaderLegacy, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { useMoDStore } from "../../stores/modStore";
import { MOON_OR_DOOM_DOCS_PAGE } from "../../config/constants";

export const TradingViewModal = () => {
  const { t } = useTranslation();

  const { isTradingViewModalOpen, setIsTradingViewModalOpen } = useMoDStore();

  const onClose = () => setIsTradingViewModalOpen(false);

  return (
    <Modal isOpen={isTradingViewModalOpen} onClose={onClose}>
      <ModalHeaderLegacy title="TradingView Charts" onClose={onClose} showCloseButton />

      <ModalBody>
        <Text textStyle="body" color="text-02" whiteSpace="pre-wrap">
          {t(
            "mod::TradingView charts are provided for your reference only, and the price shown may differ from the price used to determine the open and close price of a round.\n\nThis is because round open and close prices are determined by a third-party price oracle. The Default chart shows the accurate price provided by this price oracle.\n\nIn case of differences between TradingView and the price oracle, you can verify the fairness of a round after it closes via the History page."
          )}
        </Text>
      </ModalBody>

      <ModalFooter dir="column">
        <Stack width="100%">
          <Button onClick={onClose}>{t("Gotcha")}</Button>
          <Button as={Link} href={MOON_OR_DOOM_DOCS_PAGE} onClick={onClose} colorScheme="secondary" isExternal>
            {t("Review the Rules")}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};
