import { useTranslation } from "next-i18next";
import { usePublicClient, useWalletClient } from "wagmi";
import { NoPublicClientError, toDecimals, useAssertNetwork, useHandleModalStep } from "@looksrare/utils";
import { ChainId, blastMainnetAddresses, looksBridgeVaultAbi, mainnetAddresses } from "@looksrare/config";
import { AutoTransactionStepRow, TransactionSetter } from "../../../AutoTransactionStepRow";
import { LooksBridgeDirection } from "../BridgeLooksModal";

const SOCKET_PUBLIC_API_KEY = "72a5b4b0-e727-48be-8aa1-5da9d62fe635";
const MSG_GAS_LIMIT = 120_000n;

interface BridgeLooksBridgeTransactionStepProps {
  looksToBridge: string;
  direction: LooksBridgeDirection;
  isStepActive: boolean;
  onSuccess?: (hash: string) => void;
}

export const BridgeLooksBridgeTransactionStep = ({
  looksToBridge,
  direction,
  isStepActive,
  onSuccess,
}: BridgeLooksBridgeTransactionStepProps) => {
  const { t } = useTranslation();

  const { BRIDGE_VAULT_CONTRACT, BRIDGE_CONNECTOR } =
    direction === LooksBridgeDirection.ETH_TO_BLAST ? mainnetAddresses : blastMainnetAddresses;

  const assertNetwork = useAssertNetwork({
    network: direction === LooksBridgeDirection.ETH_TO_BLAST ? "ethereum" : "blast",
  });
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();

  const amountInWei = toDecimals(looksToBridge);

  const useHandleTransaction = (setTransaction: TransactionSetter) => {
    return useHandleModalStep({
      onSubmit: async () => {
        await assertNetwork();

        if (!publicClient) {
          throw new NoPublicClientError();
        }
        if (!walletClient) {
          throw Error("No wallet client found");
        }

        const [account] = await walletClient.getAddresses();

        // Step 2. Bridge
        const srcChainId = direction === LooksBridgeDirection.ETH_TO_BLAST ? ChainId.MAINNET : ChainId.BLAST_MAINNET;
        const dstChainId = direction === LooksBridgeDirection.ETH_TO_BLAST ? ChainId.BLAST_MAINNET : ChainId.MAINNET;

        const response = await fetch(
          `https://prod.dlapi.socket.tech/v1/estimate-min-fees?srcPlug=${BRIDGE_CONNECTOR}&srcChainSlug=${srcChainId}&dstChainSlug=${dstChainId}&msgGasLimit=${MSG_GAS_LIMIT}`,
          {
            headers: {
              "x-api-key": process.env.SOCKET_V2_API_KEY ?? SOCKET_PUBLIC_API_KEY,
            },
          }
        );

        const fees: { status: string; result: string } = await response.json();

        const { request: bridgeRequest } = await publicClient.simulateContract({
          abi: looksBridgeVaultAbi,
          address: BRIDGE_VAULT_CONTRACT,
          functionName: "bridge",
          args: [account, amountInWei, MSG_GAS_LIMIT, BRIDGE_CONNECTOR, "0x", "0x"],
          account,
          value: BigInt(fees.result),
        });

        const hash = await walletClient.writeContract(bridgeRequest);
        setTransaction(hash);

        const receipt = await publicClient.waitForTransactionReceipt({ hash });

        if (receipt.status === "success") {
          setTransaction(undefined);

          onSuccess?.(hash);
        } else {
          throw new Error(`Bridging failed. Transaction hash ${receipt.transactionHash}`);
        }
      },
    });
  };

  return (
    <AutoTransactionStepRow
      ctaText={t("Bridge LOOKS")}
      useHandleTransaction={useHandleTransaction}
      isStepActive={isStepActive}
    />
  );
};
