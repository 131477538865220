import { zeroAddress } from "viem";
import { useTranslation } from "next-i18next";
import { type FlexProps, useDisclosure } from "@chakra-ui/react";
import { formatToSignificant, useCoinPrices } from "@looksrare/utils";
import { Button, Text } from "@looksrare/uikit";
import { useActiveCaveRoundInfo, useCurrencyLabel, useUnclaimedPtbFunds } from "../../hooks";
import { Panel } from "../layout";
import { PtbSupportedNetwork } from "../../types";
import { ClaimFundsModal } from "../Modals/ClaimFunds";
import { getTotalEth } from "./utils";

interface SingleClaimPanelProps extends FlexProps {
  network: PtbSupportedNetwork;
}

/**
 * This component's name is misleading. It is written for Blast
 * @todo-yg Rename this component and move it to the config
 */
export const SingleClaimPanel = ({ network, ...props }: SingleClaimPanelProps) => {
  const { t } = useTranslation();
  const activeCaveRoundQuery = useActiveCaveRoundInfo();
  const currencyLabel = useCurrencyLabel(activeCaveRoundQuery.data?.cave.currency || zeroAddress);
  const unclaimedFundsQuery = useUnclaimedPtbFunds(network);
  const disclosure = useDisclosure();
  const coinPriceQuery = useCoinPrices();

  const ethPriceUsd = coinPriceQuery.data ? coinPriceQuery.data.eth.price : 0;
  const looksPriceUsd = coinPriceQuery.data ? coinPriceQuery.data.looks.price : 0;
  const isLoading = activeCaveRoundQuery.isLoading || unclaimedFundsQuery.isLoading;

  const totalEthereumValueInEth = getTotalEth(
    unclaimedFundsQuery.ethAmount,
    unclaimedFundsQuery.looksAmount,
    ethPriceUsd,
    looksPriceUsd
  );
  const noFundsToClaim = totalEthereumValueInEth === 0n;

  return (
    <Panel p={4} bg="ui-bg" height="auto" mt={4} {...props}>
      <Button width="100%" onClick={disclosure.onOpen} size="sm" isDisabled={noFundsToClaim} isLoading={isLoading}>
        {noFundsToClaim ? (
          t("ptb::Nothing to Claim")
        ) : (
          <>
            {t("Claim Funds")}
            <Text
              as="span"
              color="text-03"
              textStyle="detail"
              ml={1}
              sx={{
                ":before": {
                  content: `" \u2022 "`,
                  fontWeight: "bold",
                  color: "text-03",
                },
              }}
            >
              {`${formatToSignificant(totalEthereumValueInEth, 6)} ${currencyLabel}`}
            </Text>
          </>
        )}
      </Button>
      <ClaimFundsModal network={network} isOpen={disclosure.isOpen} onClose={disclosure.onClose} />
    </Panel>
  );
};
