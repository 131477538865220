import { Icon, IconProps } from "../Icon";

const Weth = (props: IconProps) => (
  <Icon viewBox="0 0 96 96" {...props}>
    <g>
      <path d="M47.9898 0L47.3457 2.18771V65.6644L47.9898 66.307L77.4543 48.8902L47.9898 0Z" fill="#DF5960" />
      <path d="M47.9907 0L18.5254 48.8902L47.9907 66.307V35.4971V0Z" fill="#EE9398" />
      <path
        d="M47.9899 71.8882L47.627 72.3309V94.9422L47.9899 96.0019L77.4726 54.4805L47.9899 71.8882Z"
        fill="#DF5960"
      />
      <path d="M47.9907 96.0019V71.8882L18.5254 54.4805L47.9907 96.0019Z" fill="#EE9398" />
      <path d="M47.9902 66.3059L77.4548 48.8892L47.9902 35.4961V66.3059Z" fill="#CF373E" />
      <path d="M18.5254 48.8892L47.9907 66.3059V35.4961L18.5254 48.8892Z" fill="#DF5960" />
    </g>
  </Icon>
);

export default Weth;
