import {
  Box,
  HStack,
  Stack,
  Switch,
  Table,
  TableContainer,
  TableContainerProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { format } from "date-fns/format";
import { useAccount } from "wagmi";
import { useTranslation } from "next-i18next";
import { Button, EthHalfIcon, Text } from "@looksrare/uikit";
import { formatToSignificant, formatUsd } from "@looksrare/utils";
import { useState } from "react";
import { MoDContract } from "@looksrare/yolo-games-gql-typegen";
import { useMoDRounds, useMoDStats } from "../../network/graphql/hooks";
import { getMoDResultColor } from "../../utils/getMoDResultColor";
import { PriceChangeIndicator } from "../../components/PriceChangeIndicator/PriceChangeIndicator";
import { RecentRoundsFeed } from "../../components/RecentRoundsFeed/RecentRoundsFeed";
import { MoDHistoryYourEntry } from "./components/MoDHistoryYourEntry";
import { MoDHistoryYourResult } from "./components/MoDHistoryYourResult";
import { MoDHistoryPositionStats } from "./components/MoDHistoryPositionStats";

interface MoDHistoryViewProps extends TableContainerProps {
  contract: MoDContract;
}

export const MoDHistoryView = ({ contract, ...props }: MoDHistoryViewProps) => {
  const { t } = useTranslation();
  const { address, isConnected } = useAccount();

  const [showOnlyMine, setShowOnlyMine] = useState(false);

  const {
    data: pastRounds,
    isRefetching,
    hasNextPage,
    fetchNextPage,
  } = useMoDRounds(
    {
      filter: {
        contract,
        status: ["CLOSED", "NONE", "LOCKED"],
        player: showOnlyMine ? address : undefined,
      },
      player: address,
    },
    {
      refetchInterval: 10_000,
    }
  );

  const { data: stats } = useMoDStats(contract, { refetchInterval: 10_000 });

  const now = new Date();

  return (
    <Stack spacing={2}>
      <Stack direction={{ base: "column", md: "row" }} justifyContent="space-between">
        {isConnected ? (
          <HStack spacing={3}>
            <Switch size="sm" isChecked={showOnlyMine} onChange={() => setShowOnlyMine((current) => !current)} />

            <Text textStyle="detail" color="text-03">
              {t("mod::Only My Rounds")}
            </Text>
          </HStack>
        ) : (
          <Box />
        )}

        {stats && <RecentRoundsFeed stats={stats} />}
      </Stack>
      <TableContainer overflowY="auto" backgroundColor="ui-glass" borderRadius="dialog" {...props}>
        <Table size="lg" opacity={isRefetching ? 0.5 : 1} transition="opacity 0.1s ease-in">
          <Thead position="sticky" top={0} zIndex={1}>
            <Tr>
              <Th>{t("Status")}</Th>
              <Th textAlign="right">{t("Your Entry")}</Th>
              <Th textAlign="right">{t("Your Result")}</Th>
              <Th textAlign="right">{t("mod::MOON")}</Th>
              <Th textAlign="right">{t("mod::DOOM")}</Th>
              <Th textAlign="right">{t("Prize Pool")}</Th>
              <Th textAlign="right">{t("Open Price")}</Th>
              <Th textAlign="right">{t("Close Price")}</Th>
              <Th textAlign="right">{t("End Time")}</Th>
            </Tr>
          </Thead>

          <Tbody>
            {pastRounds &&
              pastRounds.pages.flatMap((rounds) => {
                return rounds.map(
                  ({
                    onChainId,
                    closedAt,
                    lockPrice,
                    closePrice,
                    oracleCalled,
                    entries,
                    moonPayoutRatio,
                    moonAmount,
                    doomPayoutRatio,
                    doomAmount,
                    totalAmount,
                    result,
                  }) => {
                    // We don't want to show rounds that are still open
                    if (!closedAt || new Date(closedAt) > now) {
                      return null;
                    }

                    const resultColor = result?.result ? getMoDResultColor(result.result) : "text-02";

                    const userEntry = address ? entries?.[0] : undefined;
                    const hasClosed = !!closePrice;
                    const lockPriceFloat = lockPrice ? Number(lockPrice) : 0;
                    const closePriceFloat = closePrice ? Number(closePrice) : 0;

                    return (
                      <Tr key={onChainId}>
                        <Td>
                          <PriceChangeIndicator
                            startPrice={lockPriceFloat}
                            currentPrice={closePriceFloat}
                            isCancelled={!oracleCalled}
                            size="xxs"
                            minWidth="88px"
                            showAsUsd
                          />
                        </Td>
                        <Td>
                          <MoDHistoryYourEntry userEntry={userEntry} />
                        </Td>
                        <Td>
                          <MoDHistoryYourResult userEntry={userEntry} />
                        </Td>
                        <Td>
                          <MoDHistoryPositionStats
                            multiplier={moonPayoutRatio ?? 0}
                            positionAmount={moonAmount ?? 0n}
                            positionType="moon"
                            isWinningPosition={result?.result === "MOON"}
                          />
                        </Td>
                        <Td>
                          <MoDHistoryPositionStats
                            multiplier={doomPayoutRatio ?? 0}
                            positionAmount={doomAmount ?? 0n}
                            positionType="doom"
                            isWinningPosition={result?.result === "DOOM"}
                          />
                        </Td>
                        <Td>
                          <HStack justifyContent="end">
                            <Text textStyle="detail" color="text-02">
                              {formatToSignificant(totalAmount)}
                            </Text>
                            <EthHalfIcon height={4} width={2} />
                          </HStack>
                        </Td>
                        <Td>
                          <Text textStyle="detail" textAlign="end" color="text-02">
                            {lockPriceFloat ? formatUsd(lockPriceFloat) : "-"}
                          </Text>
                        </Td>
                        <Td>
                          <Text textStyle="detail" textAlign="end" color={resultColor}>
                            {closePriceFloat ? formatUsd(closePriceFloat) : "-"}
                          </Text>
                        </Td>
                        <Td>
                          <Stack spacing={0} alignItems="end">
                            {hasClosed ? (
                              <>
                                <Text textStyle="helper" color="text-03">
                                  {format(new Date(closedAt), "dd MMM")}
                                </Text>
                                <Text textStyle="helper" color="text-03">
                                  {format(new Date(closedAt), "HH:mm:ss")}
                                </Text>
                              </>
                            ) : (
                              <Text textStyle="helper" color="text-03">
                                -
                              </Text>
                            )}
                          </Stack>
                        </Td>
                      </Tr>
                    );
                  }
                );
              })}
          </Tbody>
        </Table>
        {hasNextPage && (
          <Button variant="ghost" colorScheme="link" width="100%" onClick={() => fetchNextPage()}>
            {t("Load More")}
          </Button>
        )}
      </TableContainer>
    </Stack>
  );
};
