import { useState } from "react";
import { ModalBody, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import noop from "lodash/noop";
import { toDecimals } from "@looksrare/utils";
import { blastMainnetAddresses, mainnetAddresses } from "@looksrare/config";
import { BulkTransactionStep } from "../../../BulkTransactionStep";
import { ApproveErc20SpenderStep } from "../../../TransactionStep";
import { LooksBridgeDirection } from "../BridgeLooksModal";
import { ModalHeaderLegacy } from "../../../Modal/ModalHeaderLegacy";
import { BridgeLooksBridgeTransactionStep } from "./BridgeLooksBridgeTransactionStep";

enum TransactionSteps {
  APPROVAL = "APPROVAL",
  BRIDGE = "BRIDGE",
}

interface BridgeLooksTransactionViewProps {
  direction: LooksBridgeDirection;
  looksToBridge: string;
  onSuccess: (hash: string) => void;
  onBack: () => void;
}

export const BridgeLooksTransactionView = ({
  direction,
  looksToBridge,
  onSuccess,
  onBack,
}: BridgeLooksTransactionViewProps) => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<TransactionSteps>(TransactionSteps.APPROVAL);

  const { LOOKS, BRIDGE_VAULT_CONTRACT } =
    direction === LooksBridgeDirection.ETH_TO_BLAST ? mainnetAddresses : blastMainnetAddresses;

  const getStepStatus = (step: TransactionSteps) => {
    if (currentStep === step) {
      return "current";
    }

    if (currentStep > step) {
      return "past";
    }

    return "future";
  };

  return (
    <>
      <ModalHeaderLegacy title={t("Bridge LOOKS")} onClose={noop} onBack={onBack} showBackButton />

      <ModalBody>
        <Stack spacing={4}>
          <BulkTransactionStep
            status={getStepStatus(TransactionSteps.APPROVAL)}
            title={t("LOOKS Approval")}
            collapse={currentStep !== TransactionSteps.APPROVAL}
          >
            <ApproveErc20SpenderStep
              ctaText={t("LOOKS Approval")}
              currency={LOOKS}
              spender={BRIDGE_VAULT_CONTRACT}
              amount={toDecimals(looksToBridge)}
              isStepActive={currentStep === TransactionSteps.APPROVAL}
              onComplete={() => setCurrentStep(TransactionSteps.BRIDGE)}
            />
          </BulkTransactionStep>

          <BulkTransactionStep
            status={getStepStatus(TransactionSteps.BRIDGE)}
            title={t("Bridge LOOKS")}
            collapse={currentStep !== TransactionSteps.BRIDGE}
          >
            <BridgeLooksBridgeTransactionStep
              looksToBridge={looksToBridge}
              direction={direction}
              isStepActive={currentStep === TransactionSteps.BRIDGE}
              onSuccess={onSuccess}
            />
          </BulkTransactionStep>
        </Stack>
      </ModalBody>
    </>
  );
};
