export const layerStyles = {
  clickable: {
    cursor: "pointer",
    _hover: {
      bg: "hover-ui",
    },
    _active: {
      bg: "onclick-ui",
    },
  },
  animatedHighlight: {
    animation: "highlight 2s ease-out",
    "@keyframes highlight": {
      "0%": { filter: "brightness(130%)" },
      "100%": {
        filter: "brightness(100%)",
      },
    },
  },
  animatedPriceIncrease: {
    animation: "increase 2s ease-in",
    "@keyframes increase": {
      "0%": {
        backgroundColor: "support-success-alpha",
      },
      "100%": {
        backgroundColor: undefined,
      },
    },
  },
  animatedPriceDecrease: {
    animation: "decrease 2s ease-in",
    "@keyframes decrease": {
      "0%": {
        backgroundColor: "support-error-alpha",
      },
      "100%": {
        backgroundColor: undefined,
      },
    },
  },
  animatedPulse: {
    animation: "pulse 1.5s ease-in-out infinite",
    "@keyframes pulse": {
      "0%": {
        opacity: "1",
      },
      "50%": {
        opacity: ".5",
      },
      "100%": {
        opacity: "1",
      },
    },
  },
  animatedShine: {
    overflow: "hidden",
    position: "relative",
    _before: {
      height: "30px",
      animation: `1s shine ease-in-out`,
      animationDelay: "1s",
    },
    _after: {
      height: "8px",
      animation: `1s shine ease-in-out`,
      animationDelay: "1.05s",
    },
    "&::after, &::before": {
      content: "''",
      position: "absolute",
      pointerEvents: "none",
      left: "80%",
      top: "35%",
      width: "260%",
      zIndex: 2,
      background: "text-01",
      opacity: 0.2,
      transform: "rotate(-45deg)",
      "@keyframes shine": {
        "0%": {
          left: "-220%",
          top: "50%",
        },
        "100%": {
          left: "80%",
          top: "50%",
        },
      },
    },
  },
  animatedShineInfinite: {
    overflow: "hidden",
    position: "relative",
    _before: {
      height: "30px",
      animation: `30s shine ease-in-out infinite`,
      animationDelay: "1s",
    },
    _after: {
      height: "8px",
      animation: `30s shine ease-in-out infinite`,
      animationDelay: "1.05s",
    },
    "&::after, &::before": {
      content: "''",
      position: "absolute",
      pointerEvents: "none",
      left: "80%",
      top: "35%",
      width: "260%",
      zIndex: 2,
      background: "text-01",
      opacity: 0.2,
      transform: "rotate(-45deg)",
      "@keyframes shine": {
        "0%": {
          left: "-220%",
          top: "50%",
        },
        "5%": {
          left: "80%",
          top: "50%",
        },
      },
    },
  },
  animatedFocusLoss: {
    animation: "refocus 1s infinite alternate",
    "@keyframes refocus": {
      "0%": {
        filter: "blur(3px)",
      },
      "100%": {
        filter: "blur(8px)",
      },
    },
  },
};
