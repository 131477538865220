import { useEffect } from "react";
import { useTranslation } from "next-i18next";
import { Flex, ModalBody, Stack } from "@chakra-ui/react";
import {
  ModalHeroHeader,
  ModalLegacy,
  ModalProps,
  Text,
  Button,
  CheckmarkFilledIcon,
  TwitterIcon,
  Link,
  useThrowConfettiFromCenter,
} from "@looksrare/uikit";
import { formatToSignificant } from "@looksrare/utils";
import { getChainInfo } from "@/utils";

interface ClaimCompleteModalProps extends Omit<ModalProps, "children"> {
  amountClaimed: bigint;
  referralCode?: string;
}
export const ClaimCompleteModal = ({ amountClaimed, referralCode, ...props }: ClaimCompleteModalProps) => {
  const { t } = useTranslation();
  const { appUrl } = getChainInfo();

  const throwConfetti = useThrowConfettiFromCenter({
    shapes: ["square"],
    particleCount: 5,
    colors: ["#CCFD07", "#b2e400", "#FFFFFF"],
    scalar: 2,
    startVelocity: 25,
    gravity: 3,
  });

  const { isOpen } = props;
  useEffect(() => {
    if (isOpen) {
      throwConfetti();
    }
  }, [isOpen, throwConfetti]);

  const referralUrl = `${appUrl}/?code=${referralCode}`;
  const tweetText = t("I just claimed my BOOST airdrop on @YOLO_Blast! \n\nGrab your early access airdrop here:", {
    referralUrl,
  });

  return (
    <ModalLegacy hideHeader variant="rainbow" {...props}>
      <ModalHeroHeader
        showCloseButton
        icon={CheckmarkFilledIcon}
        title={t("Claim Complete")}
        label={t("Share your claim on X to get a head-start on the Season 1 Leaderboard!")}
        onClose={props.onClose}
      />
      <ModalBody>
        <Stack spacing={6}>
          <Flex alignItems="center" justifyContent="space-between">
            <Text bold color="text-03">
              {t("BOOST Claimed")}
            </Text>
            <Text bold>{formatToSignificant(amountClaimed.toString())}</Text>
          </Flex>
          <Button
            isDisabled={!referralCode}
            rightIcon={<TwitterIcon />}
            width="100%"
            as={Link}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(referralUrl)}&text=${encodeURIComponent(
              tweetText
            )}`}
          >
            {t("Share on")}
          </Button>
        </Stack>
      </ModalBody>
    </ModalLegacy>
  );
};
