import { usePublicClient } from "wagmi";
import { useQuery } from "@tanstack/react-query";
import { NoPublicClientError, type BigIntish } from "@looksrare/utils";
import { LiquidityPoolAbi } from "@looksrare/config";
import type { LiquidityPoolContract } from "@looksrare/yolo-games-gql-typegen";
import { getLiquidityPoolAddressFromContract } from "@/utils";

export const useConvertSharesToAssets = (contract: LiquidityPoolContract, shares?: BigIntish) => {
  const publicClient = usePublicClient();
  return useQuery({
    queryKey: ["liquidity-pools-convert-to-assets", shares],
    queryFn: async () => {
      if (!publicClient) {
        throw new NoPublicClientError();
      }

      if (!shares) {
        return 0n;
      }

      const totalAssets = await publicClient.readContract({
        address: getLiquidityPoolAddressFromContract(contract),
        abi: LiquidityPoolAbi,
        functionName: "convertToAssets",
        args: [BigInt(shares)],
      });

      return totalAssets;
    },
  });
};
