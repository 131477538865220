import { useCallback } from "react";
import { type Hash } from "viem";
import { usePublicClient, useWalletClient, useAccount } from "wagmi";
import {
  BigIntish,
  JwtScope,
  NoPublicClientError,
  signAndLoginIfJwtIsInvalid,
  useGetFormattedErrorAndTitle,
} from "@looksrare/utils";
import { SAFE_MAX_UINT_256 } from "@looksrare/config";
import { ChainId } from "@looksrare/config";
import { useToast } from "@looksrare/uikit";
import { PlayerWithdrawalCallData, PtbContractName } from "../../../types";
import { getActiveRoundOnChainId } from "../../../graphql";
import { usePtbContractInfo } from "../../../hooks";
import { Step, useEnterCaveStore } from "./state";

type Callback<TArgs> = (args?: TArgs) => void;
type HandlerArgs<TSuccess = never> = {
  next: Callback<TSuccess>;
  chainId: ChainId;
};

// 1: Login
export const useHandleLogin = (): ((args: HandlerArgs<string>) => () => void) => {
  const { chain } = useAccount();
  const { data: walletClient } = useWalletClient();
  const [setStepError, setStep, hasValidJwt] = useEnterCaveStore((state) => [
    state.setStepError,
    state.setStep,
    state.hasValidJwt,
  ]);
  const getErrorMessage = useGetFormattedErrorAndTitle();
  const { toast } = useToast();

  return useCallback(
    ({ next }) =>
      async () => {
        if (!walletClient) {
          throw Error("No wallet client found");
        }

        if (!chain) {
          throw Error("No Chain found");
        }

        setStep(Step.LOGIN);

        if (hasValidJwt) {
          next();
          return;
        }

        // Sign
        try {
          const [account] = await walletClient.getAddresses();
          await signAndLoginIfJwtIsInvalid(walletClient, account, JwtScope.LongLived);
          next();
        } catch (err) {
          const { title, description } = getErrorMessage(err);
          toast({ title, description, status: "error", dataIdSuffix: "ptb-enter-login-fail" });
          setStepError(Step.SEND);
        }
      },
    [walletClient, setStepError, getErrorMessage, toast, setStep, hasValidJwt, chain]
  );
};

// 2: TransferManager Approval
export const useTransferManagerApproval = (): ((args: HandlerArgs) => () => void) => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const [setStepError, setStepHash, setStep, hasTransferManagerApproved] = useEnterCaveStore((state) => [
    state.setStepError,
    state.setStepHash,
    state.setStep,
    state.hasTransferManagerApproved,
  ]);
  const getErrorMessage = useGetFormattedErrorAndTitle();
  const contractInfo = usePtbContractInfo();
  const { toast } = useToast();

  return useCallback(
    ({ next, chainId }) =>
      async () => {
        if (!walletClient) {
          throw Error("No wallet client found");
        }
        if (!publicClient) {
          throw new NoPublicClientError();
        }
        setStep(Step.APPROVE_TRANSFER_MANAGER);

        if (hasTransferManagerApproved) {
          next();
          return;
        }

        try {
          const [account] = await walletClient.getAddresses();
          const { request } = await publicClient.simulateContract({
            ...contractInfo[chainId].transferManager,
            functionName: "grantApprovals",
            args: [[contractInfo[chainId].ptb.address]],
            account,
          });
          const hash = await walletClient.writeContract(request);
          setStepHash({ step: Step.APPROVE_TRANSFER_MANAGER, newHash: hash });

          await publicClient.waitForTransactionReceipt({ hash });
          next();
        } catch (err) {
          const { title, description } = getErrorMessage(err);
          toast({ title, description, status: "error", dataIdSuffix: "ptb-enter-tmapproval-fail" });
          setStepError(Step.APPROVE_TRANSFER_MANAGER);
        }
      },
    [
      walletClient,
      publicClient,
      setStepError,
      setStepHash,
      getErrorMessage,
      toast,
      setStep,
      hasTransferManagerApproved,
      contractInfo,
    ]
  );
};

// 3: ERC20 Approval
interface Erc20ApprovalArgs extends HandlerArgs {
  totalValue: bigint;
}
export const useErc20Approval = (): ((args: Erc20ApprovalArgs) => () => void) => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const [setStepError, setStepHash, setStep, erc20Allowance] = useEnterCaveStore((state) => [
    state.setStepError,
    state.setStepHash,
    state.setStep,
    state.erc20Allowance,
  ]);
  const getErrorMessage = useGetFormattedErrorAndTitle();
  const contractInfo = usePtbContractInfo();
  const { toast } = useToast();

  return useCallback(
    ({ totalValue, next, chainId }) =>
      async () => {
        if (!walletClient) {
          throw Error("No wallet client found");
        }
        if (!publicClient) {
          throw new NoPublicClientError();
        }
        setStep(Step.APPROVE_ERC20);

        if (erc20Allowance >= totalValue) {
          next();
          return;
        }

        try {
          const [account] = await walletClient.getAddresses();
          const { request } = await publicClient.simulateContract({
            ...contractInfo[chainId].looks,
            functionName: "approve",
            args: [contractInfo[chainId].transferManager.address, SAFE_MAX_UINT_256],
            account,
          });
          const hash = await walletClient.writeContract(request);
          setStepHash({ step: Step.APPROVE_ERC20, newHash: hash });

          await publicClient.waitForTransactionReceipt({ hash });
          next();
        } catch (err) {
          const { title, description } = getErrorMessage(err);
          toast({ title, description, status: "error", dataIdSuffix: "ptb-enter-erc20approval-fail" });
          setStepError(Step.APPROVE_ERC20);
        }
      },
    [
      walletClient,
      publicClient,
      setStepError,
      setStepHash,
      getErrorMessage,
      toast,
      setStep,
      erc20Allowance,
      contractInfo,
    ]
  );
};

// 4: Enter Cave
interface EnterCaveArgs extends HandlerArgs<{ caveOnChainId: BigIntish; roundOnChainId: BigIntish }> {
  caveOnChainId: BigIntish;
  isUsingEth: boolean;
  numberOfRounds: number;
  totalValue: bigint;
  rolloverBalance: bigint;
  // @todo-wagmiv2 "& readonly never[]" HAS to be a bug
  rolloverPlayerDetails: PlayerWithdrawalCallData[] & never[];
  contractName: PtbContractName;
}

export const useEnterCave = (): ((args: EnterCaveArgs) => () => void) => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const [setStepError, setStepHash, setStep] = useEnterCaveStore((state) => [
    state.setStepError,
    state.setStepHash,
    state.setStep,
  ]);
  const getErrorMessage = useGetFormattedErrorAndTitle();
  const contractInfo = usePtbContractInfo();
  const { toast } = useToast();
  return useCallback(
    ({
        next,
        caveOnChainId,
        isUsingEth,
        totalValue,
        rolloverBalance,
        rolloverPlayerDetails,
        numberOfRounds,
        contractName,
        chainId,
      }) =>
      async () => {
        if (!walletClient) {
          throw Error("No wallet client found");
        }
        if (!publicClient) {
          throw new NoPublicClientError();
        }
        setStep(Step.SEND);
        try {
          const [[account], roundOnChainId] = await Promise.all([
            walletClient.getAddresses(),
            getActiveRoundOnChainId({ caveOnChainId, contract: contractName }),
          ]);

          if (!roundOnChainId) {
            throw new Error("Cannot find new round data");
          }

          const payableValue = totalValue - rolloverBalance < 0n ? 0n : totalValue - rolloverBalance;
          const numberOfExtraRoundsToEnter = numberOfRounds - rolloverPlayerDetails.length;

          let hash: Hash;
          // Rollover
          if (!!rolloverBalance) {
            const { request } = await publicClient.simulateContract({
              ...contractInfo[chainId].ptb,
              functionName: "rollover",
              account,
              args: [
                [
                  {
                    caveId: BigInt(caveOnChainId),
                    startingRoundId: BigInt(roundOnChainId),
                    numberOfExtraRoundsToEnter: BigInt(numberOfExtraRoundsToEnter),
                    playerDetails: rolloverPlayerDetails,
                  },
                ],
              ],
              value: isUsingEth ? payableValue : undefined,
            });
            hash = await walletClient.writeContract(request);
          } else {
            // Errors are being reported when simulating this transaction so we submit it directly
            hash = await walletClient.writeContract({
              ...contractInfo[chainId].ptb,
              functionName: "enter",
              account,
              args: [BigInt(caveOnChainId), BigInt(roundOnChainId), BigInt(numberOfRounds)],
              value: isUsingEth ? payableValue : undefined,
            });
          }

          setStepHash({ step: Step.SEND, newHash: hash });
          const transaction = await publicClient.waitForTransactionReceipt({ hash });

          if (transaction.status === "reverted") {
            throw new Error("Transaction reverted, please try again.");
          }

          next({ caveOnChainId, roundOnChainId });
        } catch (err) {
          const { title, description } = getErrorMessage(err);
          toast({ title, description, status: "error", dataIdSuffix: "ptb-enter-fail" });
          setStepError(Step.SEND);
        }
      },
    [walletClient, publicClient, setStepError, setStepHash, getErrorMessage, toast, setStep, contractInfo]
  );
};
