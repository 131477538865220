import { FC, PropsWithChildren } from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import { CloseIcon, Text } from "@looksrare/uikit";

export interface NavSubMenuProps extends FlexProps {
  title: string;
  onClose?: () => void;
  isMobile?: boolean;
}
export const NavSubMenu: FC<PropsWithChildren<NavSubMenuProps>> = ({
  onClose,
  isMobile = false,
  title,
  children,
  ...props
}) => {
  return (
    <Flex
      flexDirection="column"
      width="360px"
      height="100dvh"
      borderRightRadius="dialog"
      border="1px solid"
      borderColor="border-01"
      boxShadow="0px 16px 24px 0px #0000003D"
      {...props}
    >
      <Flex width="100%" p={4} gap={4} height={{ base: 18, md: "auto" }} justifyContent="space-between">
        <Text textStyle="heading-04" bold>
          {title}
        </Text>
        {isMobile && onClose && <CloseIcon color="white" cursor="pointer" onClick={onClose} />}
      </Flex>
      {children}
    </Flex>
  );
};
