import { Icon, IconProps } from "../Icon";

const Asleep = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1171 4.0575C9.42237 7.02421 9.95403 10.1457 11.5917 12.7152C13.2294 15.2846 15.8345 17.0846 18.8171 17.7075C17.2462 19.3358 15.0797 20.2539 12.8171 20.25H12.5021C8.49402 20.1028 5.16065 17.1186 4.5726 13.1512C3.98456 9.18371 6.30903 5.36096 10.1021 4.0575H10.1171ZM11.2496 2.25H11.0996C6.1039 3.07781 2.55739 7.56949 2.911 12.621C3.2646 17.6725 7.40229 21.6263 12.4646 21.75H12.8321C16.0292 21.7519 19.0266 20.1958 20.8646 17.58C21.0194 17.3511 21.0357 17.0556 20.9072 16.811C20.7786 16.5664 20.5259 16.4123 20.2496 16.41C17.2164 16.1372 14.4818 14.4711 12.8485 11.9007C11.2151 9.3302 10.8682 6.14693 11.9096 3.285C12.0027 3.0591 11.9799 2.80201 11.8486 2.596C11.7172 2.39 11.4937 2.26089 11.2496 2.25Z"
      fill="currentColor"
    />
  </Icon>
);

export default Asleep;
