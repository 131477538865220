import type { FC, PropsWithChildren } from "react";
import { Text } from "@looksrare/uikit";
import type { LogTextFCProps, LogTextProps } from "./types";

export const LogText: FC<PropsWithChildren<LogTextProps>> = (props) => (
  <Text
    fontWeight={400}
    color="currentcolor"
    textStyle="detail"
    sx={{ ":before": { content: `" \u2022 "`, fontWeight: "bold", marginRight: 0.5, color: "text-03" } }}
    {...props}
  />
);

export const LogTextHighlight: LogTextFCProps = (props) => (
  <Text as="span" textStyle="detail" bold color="link-01" {...props} />
);
