import { Box, BoxProps, forwardRef } from "@chakra-ui/react";

export const containerPadding = {
  base: 4,
  lg: 8,
  "3xl": 12,
};

export const Container = forwardRef<BoxProps, "div">((props, ref) => (
  <Box ref={ref} px={containerPadding} mx="auto" {...props} />
));
