import { type ModalProps } from "@chakra-ui/react";
import { ChainId } from "@looksrare/config";
import { type Address, zeroAddress } from "viem";
import { useAccount } from "wagmi";
import { useRouter } from "next/router";
import {
  type BigIntish,
  DataLayerEventNames,
  fromDecimals,
  isAddressEqual,
  multiplyWeiByNumber,
  setLocalStorageItem,
  useCoinPrices,
  useSendAnalyticsEvent,
  useAssertNetwork,
} from "@looksrare/utils";
import { useToast } from "@looksrare/uikit";
import { LS_DISCLAIMER_KEY } from "../../../config";
import { useUpdateCache, useValidatedRolloverAndClaimData } from "../../../hooks";
import { getNetWinnings, getNetworkFromPtbContractName, getRoundUrl } from "../../../utils";
import { PlayerWithdrawalCallData, PtbContractName } from "../../../types";
import { OverView } from "./OverView";
import { TransactionView } from "./TransactionView";
import { View, useEnterCaveStore } from "./state";
import { useEnterCave, useErc20Approval, useHandleLogin, useTransferManagerApproval } from "./handlers";
import { Disclaimer } from "./Disclaimer";

interface EnterCaveProps {
  chainId: ChainId;
  caveOnChainId: BigIntish;
  enterAmount: BigIntish;
  currency: Address;
  protocolFeeBp: BigIntish;
  playersPerRound: number;
  contractName: PtbContractName;
  onClose: ModalProps["onClose"];
  onEnter?: () => void;
}

export const EnterCave = ({
  chainId,
  caveOnChainId,
  enterAmount,
  currency,
  protocolFeeBp,
  playersPerRound,
  contractName,
  onClose,
  onEnter,
}: EnterCaveProps) => {
  const [view, numberOfRounds, setView] = useEnterCaveStore((state) => [
    state.view,
    state.numberOfRounds,
    state.setView,
  ]);
  const { address } = useAccount();
  const { toast } = useToast();
  const updateCache = useUpdateCache();
  const router = useRouter();
  const sendAnalyticsEvent = useSendAnalyticsEvent();
  const { data: prices } = useCoinPrices();

  const validatedClaimData = useValidatedRolloverAndClaimData({
    caveOnChainId,
    chainId,
    contractName,
    currency,
    enterAmount,
    numberOfRounds,
    protocolFeeBp,
    playersPerRound,
  });
  const { totalRolloverAvailable, playerDetails, maxWinningsToClaim } = validatedClaimData;

  const isUsingEth = isAddressEqual(currency, zeroAddress);
  const totalValue = multiplyWeiByNumber(enterAmount, Number.isNaN(numberOfRounds) ? 1 : numberOfRounds);

  const winningsToClaim = (() => {
    const netWinnings = getNetWinnings(enterAmount, playersPerRound, protocolFeeBp);
    const potentialWinnings = multiplyWeiByNumber(netWinnings, numberOfRounds);
    return potentialWinnings > maxWinningsToClaim ? maxWinningsToClaim : potentialWinnings;
  })();

  const network = getNetworkFromPtbContractName(contractName);
  const assertNetwork = useAssertNetwork({ network });

  // Step handlers
  const handleEnterCave = useEnterCave()({
    caveOnChainId,
    isUsingEth,
    totalValue,
    numberOfRounds,
    rolloverBalance: totalRolloverAvailable,
    rolloverPlayerDetails: playerDetails.slice(0, numberOfRounds) as PlayerWithdrawalCallData[] & never[],
    contractName,
    chainId,
    next: async (enteredCave) => {
      await assertNetwork();

      if (isUsingEth) {
        const totalPriceEth = fromDecimals(totalValue);
        const totalPriceUsd = prices?.eth ? parseFloat(totalPriceEth) * prices?.eth.price : 0;

        sendAnalyticsEvent({
          event: DataLayerEventNames.PTB_DEPOSIT,
          connectedWalletAddress: address,
          totalPriceEth,
          totalPriceUsd: totalPriceUsd.toString(),
        });
      }

      toast({
        title: "Entry Successful",
        description: `You're ready to poke! Good luck. 🤞👀,`,
        status: "success",
        dataIdSuffix: "ptb-enter-success",
      });

      if (!!address) {
        // Add the player immediately to the list
        updateCache.playerJoined(address);
      }

      // Callback
      onEnter?.();
      onClose();

      const href = getRoundUrl({ caveOnChainId, network, roundOnChainId: enteredCave?.roundOnChainId });
      router.push(href);
    },
  });

  const handleErc20Approval = useErc20Approval()({
    totalValue,
    chainId,
    next: () => {
      handleEnterCave();
    },
  });
  const handleTransferManagerApproval = useTransferManagerApproval()({
    chainId,
    next: () => {
      handleErc20Approval();
    },
  });
  const handleLogin = useHandleLogin()({
    chainId,
    next: () => {
      // Jump straight to submit if we are using ETH
      if (isUsingEth) {
        handleEnterCave();
        return;
      }
      handleTransferManagerApproval();
    },
  });

  // Start the process
  const handleConfirm = async () => {
    setView(View.TRANSACTION);
    handleLogin();
  };

  const handleAcknowledge = (dontShow: boolean) => {
    setLocalStorageItem(LS_DISCLAIMER_KEY, Number(dontShow));
    setView(View.OVERVIEW);
  };

  if (view === View.DISCLAIMER) {
    return <Disclaimer onClose={onClose} onAcknowledge={handleAcknowledge} />;
  }

  if (view === View.TRANSACTION) {
    return (
      <TransactionView
        chainId={chainId}
        currency={currency}
        enterAmount={enterAmount}
        winningsToClaim={winningsToClaim}
        onRetryLogin={handleLogin}
        onRetryTransaction={handleEnterCave}
        onRetryTmStep={handleTransferManagerApproval}
        onRetryErc20Approval={handleErc20Approval}
        onClose={onClose}
      />
    );
  }

  return (
    <OverView
      contract={contractName}
      currency={currency}
      enterAmount={enterAmount}
      onConfirm={handleConfirm}
      totalRolloverBalance={totalRolloverAvailable}
      winningsToClaim={winningsToClaim}
      isUsingEth={isUsingEth}
      onClose={onClose}
    />
  );
};
