import { useEnsName } from "wagmi";
import { type Address } from "viem";
import { useGetEntryIndexFromAddress } from "./useActiveCaveRoundInfo";
import { usePtbUser } from "./usePtbUser";

/**
 * 1. Show user name from LR
 * 2. ENS name
 * 3. Formatted address
 * 4. "Player"
 */
export const useRoundLogUserDisplay = (playerAddress?: Address) => {
  const ptbUserQuery = usePtbUser(playerAddress!, { enabled: !!playerAddress });
  const ensNameQuery = useEnsName({
    address: playerAddress,
    query: { enabled: ptbUserQuery.isSuccess && !ptbUserQuery.data },
  });
  const playerOrder = useGetEntryIndexFromAddress(playerAddress);
  const genericPlayer = `Player #${playerOrder}`;

  if (!!ptbUserQuery.data?.name) {
    return ptbUserQuery.data.name;
  }

  if (ensNameQuery.data) {
    return ensNameQuery.data;
  }

  return genericPlayer;
};
