export const YoloLimitedAbi = [
  {
    type: "constructor",
    inputs: [
      {
        name: "params",
        type: "tuple",
        internalType: "struct IYoloV2.ConstructorCalldata",
        components: [
          {
            name: "owner",
            type: "address",
            internalType: "address",
          },
          {
            name: "operator",
            type: "address",
            internalType: "address",
          },
          {
            name: "maximumNumberOfParticipantsPerRound",
            type: "uint40",
            internalType: "uint40",
          },
          {
            name: "roundDuration",
            type: "uint40",
            internalType: "uint40",
          },
          {
            name: "valuePerEntry",
            type: "uint96",
            internalType: "uint96",
          },
          {
            name: "protocolFeeRecipient",
            type: "address",
            internalType: "address",
          },
          {
            name: "protocolFeeBp",
            type: "uint16",
            internalType: "uint16",
          },
          {
            name: "discountedProtocolFeeBp",
            type: "uint16",
            internalType: "uint16",
          },
          {
            name: "keyHash",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "subscriptionId",
            type: "uint64",
            internalType: "uint64",
          },
          {
            name: "vrfCoordinator",
            type: "address",
            internalType: "address",
          },
          {
            name: "reservoirOracle",
            type: "address",
            internalType: "address",
          },
          {
            name: "transferManager",
            type: "address",
            internalType: "address",
          },
          {
            name: "erc20Oracle",
            type: "address",
            internalType: "address",
          },
          {
            name: "weth",
            type: "address",
            internalType: "address",
          },
          {
            name: "signatureValidityPeriod",
            type: "uint40",
            internalType: "uint40",
          },
          {
            name: "minimumRequestConfirmations",
            type: "uint16",
            internalType: "uint16",
          },
        ],
      },
      {
        name: "_ethYieldConfiguration",
        type: "address",
        internalType: "address",
      },
      {
        name: "_usdbYieldConfiguration",
        type: "address",
        internalType: "address",
      },
      {
        name: "_blastPoints",
        type: "address",
        internalType: "address",
      },
      {
        name: "_blastPointsOperator",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "DEFAULT_ADMIN_ROLE",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "bytes32",
        internalType: "bytes32",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "cancel",
    inputs: [
      {
        name: "numberOfRounds",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "cancel",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "cancelAfterRandomnessRequest",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "claim",
    inputs: [
      {
        name: "wethReceiver",
        type: "address",
        internalType: "address",
      },
      {
        name: "usdbReceiver",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "claimPrizes",
    inputs: [
      {
        name: "withdrawalCalldata",
        type: "tuple[]",
        internalType: "struct IYoloV2.WithdrawalCalldata[]",
        components: [
          {
            name: "roundId",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "depositIndices",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
      },
      {
        name: "payWithLOOKS",
        type: "bool",
        internalType: "bool",
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "deposit",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "deposits",
        type: "tuple[]",
        internalType: "struct IYoloV2.DepositCalldata[]",
        components: [
          {
            name: "tokenType",
            type: "uint8",
            internalType: "enum IYoloV2.YoloV2__TokenType",
          },
          {
            name: "tokenAddress",
            type: "address",
            internalType: "address",
          },
          {
            name: "tokenIdsOrAmounts",
            type: "uint256[]",
            internalType: "uint256[]",
          },
          {
            name: "minimumEntries",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "reservoirOracleFloorPrice",
            type: "tuple",
            internalType: "struct IYoloV2.ReservoirOracleFloorPrice",
            components: [
              {
                name: "id",
                type: "bytes32",
                internalType: "bytes32",
              },
              {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
              },
              {
                name: "timestamp",
                type: "uint256",
                internalType: "uint256",
              },
              {
                name: "signature",
                type: "bytes",
                internalType: "bytes",
              },
            ],
          },
        ],
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "depositCount",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "depositor",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "depositCount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "depositETHIntoMultipleRounds",
    inputs: [
      {
        name: "startingRoundId",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "amounts",
        type: "uint256[]",
        internalType: "uint256[]",
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "depositedAmount",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "depositor",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "discountedProtocolFeeBp",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint16",
        internalType: "uint16",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "drawWinner",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "erc20Oracle",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "contract IPriceOracle",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "estimatedERC20DepositEntriesCount",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "singleDeposit",
        type: "tuple",
        internalType: "struct IYoloV2.DepositCalldata",
        components: [
          {
            name: "tokenType",
            type: "uint8",
            internalType: "enum IYoloV2.YoloV2__TokenType",
          },
          {
            name: "tokenAddress",
            type: "address",
            internalType: "address",
          },
          {
            name: "tokenIdsOrAmounts",
            type: "uint256[]",
            internalType: "uint256[]",
          },
          {
            name: "minimumEntries",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "reservoirOracleFloorPrice",
            type: "tuple",
            internalType: "struct IYoloV2.ReservoirOracleFloorPrice",
            components: [
              {
                name: "id",
                type: "bytes32",
                internalType: "bytes32",
              },
              {
                name: "payload",
                type: "bytes",
                internalType: "bytes",
              },
              {
                name: "timestamp",
                type: "uint256",
                internalType: "uint256",
              },
              {
                name: "signature",
                type: "bytes",
                internalType: "bytes",
              },
            ],
          },
        ],
      },
    ],
    outputs: [
      {
        name: "entriesCount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getClaimPrizesPaymentRequired",
    inputs: [
      {
        name: "withdrawalCalldata",
        type: "tuple[]",
        internalType: "struct IYoloV2.WithdrawalCalldata[]",
        components: [
          {
            name: "roundId",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "depositIndices",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
      },
      {
        name: "payWithLOOKS",
        type: "bool",
        internalType: "bool",
      },
    ],
    outputs: [
      {
        name: "protocolFeeOwed",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getRoleAdmin",
    inputs: [
      {
        name: "role",
        type: "bytes32",
        internalType: "bytes32",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bytes32",
        internalType: "bytes32",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getRound",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "status",
        type: "uint8",
        internalType: "enum IYoloV2.RoundStatus",
      },
      {
        name: "maximumNumberOfParticipants",
        type: "uint40",
        internalType: "uint40",
      },
      {
        name: "roundProtocolFeeBp",
        type: "uint16",
        internalType: "uint16",
      },
      {
        name: "cutoffTime",
        type: "uint40",
        internalType: "uint40",
      },
      {
        name: "drawnAt",
        type: "uint40",
        internalType: "uint40",
      },
      {
        name: "numberOfParticipants",
        type: "uint40",
        internalType: "uint40",
      },
      {
        name: "winner",
        type: "address",
        internalType: "address",
      },
      {
        name: "roundValuePerEntry",
        type: "uint96",
        internalType: "uint96",
      },
      {
        name: "protocolFeeOwed",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "deposits",
        type: "tuple[]",
        internalType: "struct IYoloV2.Deposit[]",
        components: [
          {
            name: "tokenType",
            type: "uint8",
            internalType: "enum IYoloV2.YoloV2__TokenType",
          },
          {
            name: "tokenAddress",
            type: "address",
            internalType: "address",
          },
          {
            name: "tokenId",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "tokenAmount",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "depositor",
            type: "address",
            internalType: "address",
          },
          {
            name: "withdrawn",
            type: "bool",
            internalType: "bool",
          },
          {
            name: "currentEntryIndex",
            type: "uint40",
            internalType: "uint40",
          },
        ],
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "grantRole",
    inputs: [
      {
        name: "role",
        type: "bytes32",
        internalType: "bytes32",
      },
      {
        name: "account",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "hasRole",
    inputs: [
      {
        name: "role",
        type: "bytes32",
        internalType: "bytes32",
      },
      {
        name: "account",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "maximumDepositAmountPerRound",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint96",
        internalType: "uint96",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "maximumNumberOfParticipantsPerRound",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint40",
        internalType: "uint40",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "outflowAllowed",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "paused",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "prices",
    inputs: [
      {
        name: "tokenOrCollection",
        type: "address",
        internalType: "address",
      },
      {
        name: "roundId",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "price",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "protocolFeeBp",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint16",
        internalType: "uint16",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "randomnessRequests",
    inputs: [
      {
        name: "requestId",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "exists",
        type: "bool",
        internalType: "bool",
      },
      {
        name: "roundId",
        type: "uint40",
        internalType: "uint40",
      },
      {
        name: "randomWord",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "rawFulfillRandomWords",
    inputs: [
      {
        name: "requestId",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "randomWords",
        type: "uint256[]",
        internalType: "uint256[]",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "renounceRole",
    inputs: [
      {
        name: "role",
        type: "bytes32",
        internalType: "bytes32",
      },
      {
        name: "account",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "reservoirOracle",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "revokeRole",
    inputs: [
      {
        name: "role",
        type: "bytes32",
        internalType: "bytes32",
      },
      {
        name: "account",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "rolloverETH",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "withdrawalCalldata",
        type: "tuple[]",
        internalType: "struct IYoloV2.WithdrawalCalldata[]",
        components: [
          {
            name: "roundId",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "depositIndices",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
      },
      {
        name: "payWithLOOKS",
        type: "bool",
        internalType: "bool",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "roundDuration",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint40",
        internalType: "uint40",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "roundsCount",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint40",
        internalType: "uint40",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "setLOOKS",
    inputs: [
      {
        name: "looks",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "signatureValidityPeriod",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint40",
        internalType: "uint40",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "supportsInterface",
    inputs: [
      {
        name: "interfaceId",
        type: "bytes4",
        internalType: "bytes4",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "toggleOutflowAllowed",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "togglePaused",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "updateDiscountedProtocolFeeBp",
    inputs: [
      {
        name: "_discountedProtocolFeeBp",
        type: "uint16",
        internalType: "uint16",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "updateERC20Oracle",
    inputs: [
      {
        name: "_erc20Oracle",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "updateMaximumDepositAmountPerRound",
    inputs: [
      {
        name: "_maximumDepositAmountPerRound",
        type: "uint96",
        internalType: "uint96",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "updateMaximumNumberOfParticipantsPerRound",
    inputs: [
      {
        name: "_maximumNumberOfParticipantsPerRound",
        type: "uint40",
        internalType: "uint40",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "updateProtocolFeeBp",
    inputs: [
      {
        name: "_protocolFeeBp",
        type: "uint16",
        internalType: "uint16",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "updateProtocolFeeRecipient",
    inputs: [
      {
        name: "_protocolFeeRecipient",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "updateReservoirOracle",
    inputs: [
      {
        name: "_reservoirOracle",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "updateRoundDuration",
    inputs: [
      {
        name: "_roundDuration",
        type: "uint40",
        internalType: "uint40",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "updateSignatureValidityPeriod",
    inputs: [
      {
        name: "_signatureValidityPeriod",
        type: "uint40",
        internalType: "uint40",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "updateTokensStatus",
    inputs: [
      {
        name: "tokens",
        type: "address[]",
        internalType: "address[]",
      },
      {
        name: "tokenType",
        type: "uint8",
        internalType: "enum IYoloV2.YoloV2__TokenType",
      },
      {
        name: "isAllowed",
        type: "bool",
        internalType: "bool",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "updateValuePerEntry",
    inputs: [
      {
        name: "_valuePerEntry",
        type: "uint96",
        internalType: "uint96",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "valuePerEntry",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint96",
        internalType: "uint96",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "withdrawDeposits",
    inputs: [
      {
        name: "withdrawalCalldata",
        type: "tuple[]",
        internalType: "struct IYoloV2.WithdrawalCalldata[]",
        components: [
          {
            name: "roundId",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "depositIndices",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "event",
    name: "Deposited",
    inputs: [
      {
        name: "depositor",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "roundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "entriesCount",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "DepositsWithdrawn",
    inputs: [
      {
        name: "depositor",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "withdrawalCalldata",
        type: "tuple[]",
        indexed: false,
        internalType: "struct IYoloV2.WithdrawalCalldata[]",
        components: [
          {
            name: "roundId",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "depositIndices",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "DiscountedProtocolFeeBpUpdated",
    inputs: [
      {
        name: "discountedProtocolFeeBp",
        type: "uint16",
        indexed: false,
        internalType: "uint16",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ERC20OracleUpdated",
    inputs: [
      {
        name: "erc20Oracle",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "MaximumDepositAmountPerRoundUpdated",
    inputs: [
      {
        name: "maximumDepositAmountPerRound",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "MaximumNumberOfParticipantsPerRoundUpdated",
    inputs: [
      {
        name: "maximumNumberOfParticipantsPerRound",
        type: "uint40",
        indexed: false,
        internalType: "uint40",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "MultipleRoundsDeposited",
    inputs: [
      {
        name: "depositor",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "startingRoundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "amounts",
        type: "uint256[]",
        indexed: false,
        internalType: "uint256[]",
      },
      {
        name: "entriesCounts",
        type: "uint256[]",
        indexed: false,
        internalType: "uint256[]",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OutflowAllowedUpdated",
    inputs: [
      {
        name: "isAllowed",
        type: "bool",
        indexed: false,
        internalType: "bool",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Paused",
    inputs: [
      {
        name: "account",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "PrizesClaimed",
    inputs: [
      {
        name: "winner",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "withdrawalCalldata",
        type: "tuple[]",
        indexed: false,
        internalType: "struct IYoloV2.WithdrawalCalldata[]",
        components: [
          {
            name: "roundId",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "depositIndices",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ProtocolFeeBpUpdated",
    inputs: [
      {
        name: "protocolFeeBp",
        type: "uint16",
        indexed: false,
        internalType: "uint16",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ProtocolFeePayment",
    inputs: [
      {
        name: "amount",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "token",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ProtocolFeeRecipientUpdated",
    inputs: [
      {
        name: "protocolFeeRecipient",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RandomnessRequested",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "requestId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ReservoirOracleUpdated",
    inputs: [
      {
        name: "reservoirOracle",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RoleAdminChanged",
    inputs: [
      {
        name: "role",
        type: "bytes32",
        indexed: true,
        internalType: "bytes32",
      },
      {
        name: "previousAdminRole",
        type: "bytes32",
        indexed: true,
        internalType: "bytes32",
      },
      {
        name: "newAdminRole",
        type: "bytes32",
        indexed: true,
        internalType: "bytes32",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RoleGranted",
    inputs: [
      {
        name: "role",
        type: "bytes32",
        indexed: true,
        internalType: "bytes32",
      },
      {
        name: "account",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "sender",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RoleRevoked",
    inputs: [
      {
        name: "role",
        type: "bytes32",
        indexed: true,
        internalType: "bytes32",
      },
      {
        name: "account",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "sender",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Rollover",
    inputs: [
      {
        name: "depositor",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "withdrawalCalldata",
        type: "tuple[]",
        indexed: false,
        internalType: "struct IYoloV2.WithdrawalCalldata[]",
        components: [
          {
            name: "roundId",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "depositIndices",
            type: "uint256[]",
            internalType: "uint256[]",
          },
        ],
      },
      {
        name: "enteredRoundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "entriesCount",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RoundDurationUpdated",
    inputs: [
      {
        name: "roundDuration",
        type: "uint40",
        indexed: false,
        internalType: "uint40",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RoundStatusUpdated",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "status",
        type: "uint8",
        indexed: false,
        internalType: "enum IYoloV2.RoundStatus",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RoundsCancelled",
    inputs: [
      {
        name: "startingRoundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "numberOfRounds",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "SignatureValidityPeriodUpdated",
    inputs: [
      {
        name: "signatureValidityPeriod",
        type: "uint40",
        indexed: false,
        internalType: "uint40",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "TokensStatusUpdated",
    inputs: [
      {
        name: "tokens",
        type: "address[]",
        indexed: false,
        internalType: "address[]",
      },
      {
        name: "tokenType",
        type: "uint8",
        indexed: false,
        internalType: "enum IYoloV2.YoloV2__TokenType",
      },
      {
        name: "isAllowed",
        type: "bool",
        indexed: false,
        internalType: "bool",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Unpaused",
    inputs: [
      {
        name: "account",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ValuePerEntryUpdated",
    inputs: [
      {
        name: "valuePerEntry",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "error",
    name: "AlreadyWithdrawn",
    inputs: [],
  },
  {
    type: "error",
    name: "CutoffTimeNotReached",
    inputs: [],
  },
  {
    type: "error",
    name: "DrawExpirationTimeNotReached",
    inputs: [],
  },
  {
    type: "error",
    name: "ERC20TransferFail",
    inputs: [],
  },
  {
    type: "error",
    name: "ERC721TransferFromFail",
    inputs: [],
  },
  {
    type: "error",
    name: "ExceededMaximumDepositAmountPerRound",
    inputs: [],
  },
  {
    type: "error",
    name: "InsufficientParticipants",
    inputs: [],
  },
  {
    type: "error",
    name: "InvalidIndex",
    inputs: [],
  },
  {
    type: "error",
    name: "InvalidLength",
    inputs: [],
  },
  {
    type: "error",
    name: "InvalidSignatureTimestamp",
    inputs: [],
  },
  {
    type: "error",
    name: "InvalidStatus",
    inputs: [],
  },
  {
    type: "error",
    name: "InvalidToken",
    inputs: [],
  },
  {
    type: "error",
    name: "InvalidTokenType",
    inputs: [],
  },
  {
    type: "error",
    name: "InvalidValue",
    inputs: [],
  },
  {
    type: "error",
    name: "IsPaused",
    inputs: [],
  },
  {
    type: "error",
    name: "LooksAlreadySet",
    inputs: [],
  },
  {
    type: "error",
    name: "MaximumNumberOfDepositsReached",
    inputs: [],
  },
  {
    type: "error",
    name: "MaximumNumberOfParticipantsReached",
    inputs: [],
  },
  {
    type: "error",
    name: "MessageIdInvalid",
    inputs: [],
  },
  {
    type: "error",
    name: "NotAContract",
    inputs: [],
  },
  {
    type: "error",
    name: "NotDepositor",
    inputs: [],
  },
  {
    type: "error",
    name: "NotOperator",
    inputs: [],
  },
  {
    type: "error",
    name: "NotOwner",
    inputs: [],
  },
  {
    type: "error",
    name: "NotPaused",
    inputs: [],
  },
  {
    type: "error",
    name: "NotWinner",
    inputs: [],
  },
  {
    type: "error",
    name: "NullSignerAddress",
    inputs: [],
  },
  {
    type: "error",
    name: "OnePlayerCannotFillUpTheWholeRound",
    inputs: [],
  },
  {
    type: "error",
    name: "OnlyCoordinatorCanFulfill",
    inputs: [
      {
        name: "have",
        type: "address",
        internalType: "address",
      },
      {
        name: "want",
        type: "address",
        internalType: "address",
      },
    ],
  },
  {
    type: "error",
    name: "OutflowNotAllowed",
    inputs: [],
  },
  {
    type: "error",
    name: "ProtocolFeeNotPaid",
    inputs: [],
  },
  {
    type: "error",
    name: "RandomnessRequestAlreadyExists",
    inputs: [],
  },
  {
    type: "error",
    name: "ReentrancyFail",
    inputs: [],
  },
  {
    type: "error",
    name: "RoundCannotBeClosed",
    inputs: [],
  },
  {
    type: "error",
    name: "SignatureEOAInvalid",
    inputs: [],
  },
  {
    type: "error",
    name: "SignatureERC1271Invalid",
    inputs: [],
  },
  {
    type: "error",
    name: "SignatureLengthInvalid",
    inputs: [
      {
        name: "length",
        type: "uint256",
        internalType: "uint256",
      },
    ],
  },
  {
    type: "error",
    name: "SignatureParameterSInvalid",
    inputs: [],
  },
  {
    type: "error",
    name: "SignatureParameterVInvalid",
    inputs: [
      {
        name: "v",
        type: "uint8",
        internalType: "uint8",
      },
    ],
  },
  {
    type: "error",
    name: "TooFewEntries",
    inputs: [],
  },
  {
    type: "error",
    name: "ZeroDeposits",
    inputs: [],
  },
  {
    type: "error",
    name: "ZeroEntries",
    inputs: [],
  },
  {
    type: "error",
    name: "ZeroRounds",
    inputs: [],
  },
] as const;
