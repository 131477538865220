import { formatNumberToLocale, formatUsd } from "@looksrare/utils";

/**
 * If price change is either smaller than `range` or -`range`
 * then we consider it a tiny change and show an alternative display value.
 *
 * By deafult we consider the tiny "range" to be `0.01`.
 */
export const getFormattedPriceChange = (
  priceChange: number,
  opts?: { showAsUsd?: boolean; range?: number }
): string => {
  const { showAsUsd = false, range = 0.01 } = opts || {};
  const isTinyChange = (priceChange > 0 && priceChange < range) || (priceChange < 0 && priceChange > -range);

  if (isTinyChange) {
    const tinyChange = priceChange > 0 ? range : -range;
    const tinyChangeFormatted = showAsUsd ? formatUsd(tinyChange) : formatNumberToLocale(tinyChange);

    return `< ${tinyChangeFormatted}`;
  }

  return showAsUsd ? formatUsd(priceChange) : formatNumberToLocale(priceChange);
};
