import { useTranslation } from "next-i18next";
import { Button, type ButtonProps, Chevron } from "@looksrare/uikit";

interface TriggerButtonProps extends Omit<ButtonProps, "children"> {
  isOpen: boolean;
}

export const TriggerButton = ({ isOpen, ...props }: TriggerButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      borderRadius={0}
      borderTopLeftRadius="dialog"
      borderTopRightRadius="dialog"
      width="100%"
      variant="ghost"
      background="transparent"
      color="text-01"
      justifyContent="start"
      size="lg"
      px={4}
      leftIcon={<Chevron direction={isOpen ? "up" : "down"} />}
      {...props}
    >
      {t("ptb::You've Got Funds to Claim!")}
    </Button>
  );
};
