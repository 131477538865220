import { useTranslation } from "next-i18next";
import { IconButton } from "@chakra-ui/react";
import { Button, type ButtonProps, NextLink, Popover, TooltipText, JoinIcon } from "@looksrare/uikit";
import { useActiveRoundOnChainId, useGetCaveQueryParams } from "../../hooks";
import { getRoundUrl } from "../../utils";

export const NextJoinableRoundButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const { caveOnChainId, network, roundOnChainId } = useGetCaveQueryParams();
  const paramRoundOnChainId = Number(roundOnChainId);
  const baseCaveUrl = getRoundUrl({ caveOnChainId, network });

  const activeRoundQuery = useActiveRoundOnChainId();
  const currentRoundOnChainId = activeRoundQuery.data ? Number(activeRoundQuery.data) : null;
  const currentRoundUrl = currentRoundOnChainId ? `${baseCaveUrl}/${currentRoundOnChainId}` : baseCaveUrl;

  const sharedButtonProps: Partial<ButtonProps> = {
    size: "xs",
    isDisabled: activeRoundQuery.isLoading || currentRoundOnChainId === paramRoundOnChainId,
    ...(currentRoundOnChainId !== paramRoundOnChainId && {
      as: NextLink,
      href: currentRoundUrl,
    }),
  };

  return (
    <>
      {/* Hide on xl+ */}
      <Popover label={<TooltipText>{t("ptb::Next Joinable")}</TooltipText>}>
        <IconButton
          aria-label="poke-the-bear-next-joinable-round"
          display={{ base: "flex", xl: "none" }}
          {...sharedButtonProps}
          {...props}
          {...(currentRoundOnChainId !== paramRoundOnChainId && {
            variant: "solid",
            colorScheme: "primary",
          })}
        >
          <JoinIcon />
        </IconButton>
      </Popover>

      {/* Hide on mobile */}
      <Button
        display={{ base: "none", xl: "flex" }}
        rightIcon={<JoinIcon />}
        {...sharedButtonProps}
        {...props}
        {...(currentRoundOnChainId !== paramRoundOnChainId && {
          variant: "solid",
          colorScheme: "primary",
        })}
      >
        {t("ptb::Joinable")}
      </Button>
    </>
  );
};
