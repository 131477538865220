import { useAccount, useSwitchChain, useWalletClient } from "wagmi";
import { ChainId } from "@looksrare/config";
import { getChainIdFromSupportedNetwork } from "@looksrare/utils";
import { PtbSupportedNetwork } from "../types";

type SuccessHandler = (data: ChainId) => Promise<void> | void;

export const useAssertPtbNetwork = (onSuccess: SuccessHandler) => {
  const { refetch } = useWalletClient();
  const { switchChainAsync } = useSwitchChain();
  const { chain } = useAccount();

  return async (desiredNetwork: PtbSupportedNetwork): Promise<void> => {
    const chainIdFromNetwork = getChainIdFromSupportedNetwork(desiredNetwork);

    // Call the function if we are already on the desired network
    if (chain?.id === chainIdFromNetwork) {
      return onSuccess(chain?.id);
    }
    try {
      const newChain = await switchChainAsync?.({ chainId: chainIdFromNetwork });
      await refetch();
      return onSuccess(newChain?.id as ChainId);
    } catch (err) {
      // @todo-ptbl2 find out what to do here?
      console.error(err);
    }
  };
};
