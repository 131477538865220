import { Icon, IconProps } from "../Icon";

const Uniswap = (props: IconProps) => (
  <Icon {...props}>
    <path
      d="M20 15C20.5523 15 21 14.5523 21 14C21 13.4477 20.5523 13 20 13C19.4477 13 19 13.4477 19 14C19 14.5523 19.4477 15 20 15Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 4C8.92487 4 4 8.92487 4 15V29H6V15C6 10.0294 10.0294 6 15 6H17L15.4965 8.00463C11.3322 8.1472 8 11.568 8 15.7671V29H23.9458V25.8493C23.9458 24.0346 22.69 22.5131 21 22.1066V20H22.5V21.5582H27.421L30.9274 17.9702L23.3973 10.8831L28.9736 7.88045L28.0254 6.11951L22 9.36395V4H15ZM24.5 19.5582H26.579L28.0726 18.0298L20 10.4321V6H19.5L16.5 10H15.7671C12.582 10 10 12.582 10 15.7671V27H21.9458V25.8493C21.9458 24.828 21.1178 24 20.0965 24H19V20C16.2386 20 14 17.7614 14 15H16C16 16.6569 17.3431 18 19 18H24.5V19.5582Z"
      fill="currentColor"
    />
  </Icon>
);

export default Uniswap;
