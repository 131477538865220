import { Icon, IconProps } from "../Icon";

const TokenBlast = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 96 96" {...props}>
      <rect width="96" height="96" rx="48" fill="#FCFC03" />
      <path
        d="M27.1552 27.9434L17 36.0421L69.2674 36.3679L66.7442 45.0065L45.4767 44.6807L43.7241 51.6996H64.5814L61.6977 62.0696H32.1395L37.907 43.1913L31.431 37.6618L20.7414 70.0566H59.2241L69.6279 65.337L72.8721 53.7196L66.7442 48.4601L76.3276 43.6009L79 32.8026L73.1207 27.9434H27.1552Z"
        fill="black"
      />
    </Icon>
  );
};

export default TokenBlast;
