import { useGameAddresses } from "@looksrare/uikit";
import { MoDContract } from "@looksrare/yolo-games-gql-typegen";

export const useMoDContractAddress = (contract: MoDContract) => {
  const addresses = useGameAddresses();

  switch (contract) {
    case "MOON_OR_DOOM_ETHUSD_V1_BLAST":
      return addresses.MOON_OR_DOOM_NATIVE_TOKEN_ETH;
    case "MOON_OR_DOOM_BTCUSD_V1_BLAST":
      return addresses.MOON_OR_DOOM_NATIVE_TOKEN_BTC;
  }
};
