import { SupportedNetwork } from "@looksrare/utils";
import { MoDAssetPair, MoDContract } from "@looksrare/yolo-games-gql-typegen";

export const getMoDContractNameByNetworkPair = (network: SupportedNetwork, pair: MoDAssetPair): MoDContract => {
  switch (network) {
    case "arbitrum":
      return `MOON_OR_DOOM_${pair}_V1_ARBITRUM` as MoDContract;
    case "ethereum":
      return `MOON_OR_DOOM_${pair}_V1_ETH` as MoDContract;
    case "blast":
      return `MOON_OR_DOOM_${pair}_V1_BLAST` as MoDContract;
  }
};

export const getAssetPairByContractName = (contractName: MoDContract): MoDAssetPair => {
  const pair = contractName.split("_")[3];
  return pair as MoDAssetPair;
};
