import { gql } from "graphql-request";
import { Address } from "viem";
import { getAuthCookie, graphql, isAuthorized, removeAuthCookie } from "@looksrare/utils";

export const logout = async (address: Address): Promise<void> => {
  // It's possible for a user to be connected with a wallet and not have a JWT ("signed in")
  if (!isAuthorized(address)) {
    return;
  }

  const requestHeaders = {
    Authorization: `Bearer ${getAuthCookie(address)}`,
  };

  const query = gql`
    mutation LogoutMutation {
      logout {
        success
      }
    }
  `;

  await graphql({ query, requestHeaders });
  removeAuthCookie(address);
};
