import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const RankTwo = (props: IconProps) => {
  const [uid0] = useState(uniqueId());
  const [uid1] = useState(uniqueId());
  const [uid2] = useState(uniqueId());
  const [uid3] = useState(uniqueId());
  const [uid4] = useState(uniqueId());

  return (
    <Icon viewBox="0 0 256 256" {...props}>
      <path
        d="M113 8.65986C122.282 3.30088 133.718 3.30088 143 8.65986L223.851 55.3394C233.133 60.6983 238.851 70.6021 238.851 81.3201V174.679C238.851 185.397 233.133 195.301 223.851 200.66L143 247.339C133.718 252.698 122.282 252.698 113 247.339L32.1484 200.66C22.8664 195.301 17.1484 185.397 17.1484 174.679V81.3201C17.1484 70.6022 22.8664 60.6983 32.1484 55.3394L113 8.65986Z"
        fill={`url(#${uid0})`}
      />
      <path
        d="M113 8.65986C122.282 3.30088 133.718 3.30088 143 8.65986L223.851 55.3394C233.133 60.6983 238.851 70.6021 238.851 81.3201V174.679C238.851 185.397 233.133 195.301 223.851 200.66L143 247.339C133.718 252.698 122.282 252.698 113 247.339L32.1484 200.66C22.8664 195.301 17.1484 185.397 17.1484 174.679V81.3201C17.1484 70.6022 22.8664 60.6983 32.1484 55.3394L113 8.65986Z"
        fill={`url(#${uid1})`}
      />
      <path
        d="M81.813 48.001C110.394 31.4997 145.608 31.4997 174.189 48.001C202.77 64.5024 220.377 94.9983 220.377 128.001C220.377 161.004 202.77 191.5 174.189 208.001C145.608 224.502 110.394 224.502 81.813 208.001C53.2318 191.5 35.625 161.004 35.625 128.001C35.625 94.9983 53.2318 64.5024 81.813 48.001Z"
        fill={`url(#${uid2})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.313 48.8671C54.0412 65.1898 36.625 95.3555 36.625 128.001C36.625 160.647 54.0412 190.812 82.313 207.135C110.585 223.458 145.417 223.458 173.689 207.135C201.961 190.812 219.377 160.647 219.377 128.001C219.377 95.3555 201.961 65.1898 173.689 48.8671C145.417 32.5443 110.585 32.5443 82.313 48.8671ZM174.189 48.001C145.608 31.4997 110.394 31.4997 81.813 48.001C53.2318 64.5024 35.625 94.9983 35.625 128.001C35.625 161.004 53.2318 191.5 81.813 208.001C110.394 224.502 145.608 224.502 174.189 208.001C202.77 191.5 220.377 161.004 220.377 128.001C220.377 94.9983 202.77 64.5024 174.189 48.001Z"
        fill="black"
      />
      <g filter={`url(#${uid3})`}>
        <path
          d="M80.9832 134.721L148.699 107.001C157.279 103.437 155.827 99.4769 145.399 99.4769C128.635 99.4769 108.703 99.6089 99.5952 99.7409C86.9232 99.7409 75.0432 100.929 67.7832 107.265V85.2209C74.2512 80.2049 83.8872 77.9609 93.3912 77.9609C109.363 77.9609 142.231 77.9609 164.671 77.9609C179.851 77.9609 188.167 85.8809 188.167 96.8369V98.9489C188.167 114.789 140.647 137.097 108.967 148.845V150.957H189.355V173.001H87.3192C81.5112 173.001 68.5752 168.117 68.3112 157.161L68.1792 150.033C68.1792 144.093 75.3072 136.965 80.9832 134.721Z"
          fill="#D9D9D9"
        />
        <path
          d="M80.9832 134.721L148.699 107.001C157.279 103.437 155.827 99.4769 145.399 99.4769C128.635 99.4769 108.703 99.6089 99.5952 99.7409C86.9232 99.7409 75.0432 100.929 67.7832 107.265V85.2209C74.2512 80.2049 83.8872 77.9609 93.3912 77.9609C109.363 77.9609 142.231 77.9609 164.671 77.9609C179.851 77.9609 188.167 85.8809 188.167 96.8369V98.9489C188.167 114.789 140.647 137.097 108.967 148.845V150.957H189.355V173.001H87.3192C81.5112 173.001 68.5752 168.117 68.3112 157.161L68.1792 150.033C68.1792 144.093 75.3072 136.965 80.9832 134.721Z"
          fill={`url(#${uid4})`}
        />
      </g>
      <defs>
        <filter
          id={uid3}
          x="47.2908"
          y="57.4685"
          width="172.803"
          height="146.27"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="5.12311" dy="5.12311" />
          <feGaussianBlur stdDeviation="12.8078" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
          <feBlend mode="overlay" in2="BackgroundImageFix" result="effect1_dropShadow_2357_95347" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2357_95347" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1.28078" dy="1.28078" />
          <feGaussianBlur stdDeviation="0.640389" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="soft-light" in2="shape" result="effect2_innerShadow_2357_95347" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1.28078" dy="-1.28078" />
          <feGaussianBlur stdDeviation="0.640389" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.9 0" />
          <feBlend mode="overlay" in2="effect2_innerShadow_2357_95347" result="effect3_innerShadow_2357_95347" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1.28078" dy="-1.28078" />
          <feGaussianBlur stdDeviation="0.640389" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="effect3_innerShadow_2357_95347" result="effect4_innerShadow_2357_95347" />
        </filter>
        <linearGradient id={uid0} x1="172.552" y1="30.0179" x2="82.7689" y2="62.8072" gradientUnits="userSpaceOnUse">
          <stop stopColor="#33DEBF" />
          <stop offset="1" stopColor="#27796A" />
        </linearGradient>
        <linearGradient id={uid1} x1="172.552" y1="30.6433" x2="13.8771" y2="160.81" gradientUnits="userSpaceOnUse">
          <stop stopColor="#33DEBF" />
          <stop offset="1" stopColor="#27796A" />
        </linearGradient>
        <linearGradient id={uid2} x1="128.001" y1="21.3344" x2="128.001" y2="234.668" gradientUnits="userSpaceOnUse">
          <stop stopColor="#191B20" />
          <stop offset="1" stopColor="#3D4048" />
        </linearGradient>
        <linearGradient id={uid4} x1="151.646" y1="89.8016" x2="69.7111" y2="157.525" gradientUnits="userSpaceOnUse">
          <stop stopColor="#33DEBF" />
          <stop offset="1" stopColor="#27796A" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export default RankTwo;
