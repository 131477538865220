import type { SupportedNetwork } from "@looksrare/utils";

export enum PtbRoundStatus {
  NONE = "NONE", // Future rounds (likely with commitments)
  OPEN = "OPEN", // One or more players have entered but not full
  DRAWING = "DRAWING", // All players have joined and a randomness request has been sent
  DRAWN = "DRAWN", // Have received a response from the oracle
  REVEALED = "REVEALED", // Game over
  CANCELLED = "CANCELLED",
}

export enum PtbRoundLogType {
  // Rounds Pre-Poke flow
  ROUND_OPENED = "ROUND_OPENED",
  PLAYER_JOINED = "PLAYER_JOINED",
  ROUND_DRAWING = "ROUND_DRAWING",
  ROUND_DRAWN = "ROUND_DRAWN",

  // Poking flow
  PLAYER_READY = "PLAYER_READY",
  PLAYER_COUNTDOWN = "PLAYER_COUNTDOWN",
  PLAYER_POKE = "PLAYER_POKE",
  PLAYER_PASS = "PLAYER_PASS",
  PLAYER_SURVIVED = "PLAYER_SURVIVED",
  PLAYER_DIED = "PLAYER_DIED",

  // Rounds Post-Poke flow
  ROUND_REVEALED = "ROUND_REVEALED",
  POT_SPLIT = "POT_SPLIT",
  CAVE_RESET = "CAVE_RESET",

  // Error Paths
  ROUND_CANCELLED = "ROUND_CANCELLED",
}

export enum PtbPokeOutcome {
  SURVIVED = "SURVIVED",
  DIED = "DIED",
}

export interface PlayerWithdrawalCallData {
  roundId: bigint;
  playerIndex: bigint;
}

export interface WithdrawalCallData {
  caveId: bigint;
  playerDetails: PlayerWithdrawalCallData[];
}

export interface CaveRolloverCallData {
  caveId: bigint;
  startingRoundId: bigint;
  numberOfExtraRoundsToEnter: bigint;
  playerDetails: PlayerWithdrawalCallData[];
}

export type PtbSupportedNetwork = SupportedNetwork;

export type PtbContractName = "PTB_V1" | "PTB_V1_ARBITRUM" | "PTB_V1_BLAST";
