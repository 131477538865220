import { Trans, useTranslation } from "next-i18next";
import { type FlexProps, HStack, type AccordionProps, Accordion } from "@chakra-ui/react";
import { HelpIcon, Popover, Text, TooltipText } from "@looksrare/uikit";
import { useCoinPrices } from "@looksrare/utils";
import { Panel } from "../layout";
import { getGradientBorder } from "../../utils";
import { useClaimFundsDisclosure, useUnclaimedPtbFunds } from "../../hooks";
import { ClaimFundsModal } from "../Modals/ClaimFunds";
import type { ClaimFundsSuccessHandler } from "../Modals/types";
import { NetworkClaimAccordionItem } from "./NetworkClaimAccordionItem";
import { getTotalEth } from "./utils";

interface ClaimPanelProps extends FlexProps {
  accordionProps?: AccordionProps;
  onSuccess?: ClaimFundsSuccessHandler;
}

export const ClaimPanel = ({ accordionProps, onSuccess, ...props }: ClaimPanelProps) => {
  const { t } = useTranslation();
  const { state, onOpen, onClose } = useClaimFundsDisclosure();

  const coinPriceQuery = useCoinPrices();
  const unclaimedEthFundsQuery = useUnclaimedPtbFunds("ethereum");
  const unclaimedArbFundsQuery = useUnclaimedPtbFunds("arbitrum");

  const ethPriceUsd = coinPriceQuery.data ? coinPriceQuery.data.eth.price : 0;
  const looksPriceUsd = coinPriceQuery.data ? coinPriceQuery.data.looks.price : 0;

  const totalEthereumValueInEth = getTotalEth(
    unclaimedEthFundsQuery.ethAmount,
    unclaimedEthFundsQuery.looksAmount,
    ethPriceUsd,
    looksPriceUsd
  );
  const totalArbitrumValueInEth = getTotalEth(
    unclaimedArbFundsQuery.ethAmount,
    unclaimedArbFundsQuery.looksAmount,
    ethPriceUsd,
    looksPriceUsd
  );

  const isLoading = unclaimedEthFundsQuery.isLoading || unclaimedArbFundsQuery.isLoading;

  const handleSuccess: ClaimFundsSuccessHandler = (networkClaimed) => {
    onClose();
    if (onSuccess) {
      onSuccess(networkClaimed);
    }
  };

  if (totalArbitrumValueInEth === 0n && totalEthereumValueInEth === 0n) {
    return null;
  }

  return (
    <Panel p={4} bg="ui-bg" height="auto" _before={getGradientBorder(1, "dialog")} border={0} mt={4} {...props}>
      <Popover
        variant="tooltip"
        label={
          <TooltipText>
            <Trans i18nKey="ptbClaimBalanceText">
              A combination of your <strong>Unclaimed Winnings</strong>, <strong>Refunds</strong> from canceled rounds,
              and <strong>Rollover Balances</strong>.
            </Trans>
          </TooltipText>
        }
      >
        <HStack mb={2}>
          <Text color="text-03" bold textStyle="detail">
            {t("ptb::Your Claimable Funds")}
          </Text>
          <HelpIcon boxSize={5} color="text-03" />
        </HStack>
      </Popover>
      <Accordion allowToggle zIndex={1} {...accordionProps}>
        <NetworkClaimAccordionItem
          onOpen={() => onOpen("arbitrum")}
          network="arbitrum"
          totalAmountInEth={totalArbitrumValueInEth}
          ethAmount={unclaimedArbFundsQuery.ethAmount}
          ethPriceUsd={ethPriceUsd}
          looksAmount={unclaimedArbFundsQuery.looksAmount}
          looksPriceUsd={looksPriceUsd}
          isLoading={isLoading}
        />
        <NetworkClaimAccordionItem
          onOpen={() => onOpen("ethereum")}
          network="ethereum"
          totalAmountInEth={totalEthereumValueInEth}
          ethAmount={unclaimedEthFundsQuery.ethAmount}
          ethPriceUsd={ethPriceUsd}
          looksAmount={unclaimedEthFundsQuery.looksAmount}
          looksPriceUsd={looksPriceUsd}
          isLoading={isLoading}
        />
      </Accordion>
      <ClaimFundsModal network={state.network} isOpen={state.isOpen} onClose={onClose} onSuccess={handleSuccess} />
    </Panel>
  );
};
