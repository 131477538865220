import { Trans } from "next-i18next";
import { PtbRoundLogType } from "../../../types";
import { LogText, LogTextHighlight } from "./LogText";
import type { LogTextProps, RoundLogTextGetter } from "./types";

const PlayerJoinedText1 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerJoined1">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      takes a seat.
    </Trans>
  </LogText>
);

const PlayerJoinedText2 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerJoined2">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      saunters into the cave.
    </Trans>
  </LogText>
);

const PlayerJoinedText3 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerJoined3">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      tiptoes in.
    </Trans>
  </LogText>
);

const PlayerJoinedText4 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerJoined4">
      Welcome to the cave,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      .
    </Trans>
  </LogText>
);

const PlayerJoinedText5 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerJoined5">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      sits down.
    </Trans>
  </LogText>
);

const PlayerJoinedText6 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerJoined6">
      <LogTextHighlight>
        <>{{ user1 }}</>&apos;s
      </LogTextHighlight>{" "}
      mother drops them off.
    </Trans>
  </LogText>
);

const PlayerJoinedText7 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerJoined7">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      , stop crying and sit down.
    </Trans>
  </LogText>
);

const PlayerJoinedText8 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerJoined8">
      idk why you&apos;re smiling{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      . This won&apos;t end well.
    </Trans>
  </LogText>
);

export const playerJoinedLogs: RoundLogTextGetter = ({ user1 }) => [
  <PlayerJoinedText1 key={`${PtbRoundLogType.PLAYER_JOINED}1`} user1={user1} />,
  <PlayerJoinedText2 key={`${PtbRoundLogType.PLAYER_JOINED}2`} user1={user1} />,
  <PlayerJoinedText3 key={`${PtbRoundLogType.PLAYER_JOINED}3`} user1={user1} />,
  <PlayerJoinedText4 key={`${PtbRoundLogType.PLAYER_JOINED}4`} user1={user1} />,
  <PlayerJoinedText5 key={`${PtbRoundLogType.PLAYER_JOINED}5`} user1={user1} />,
  <PlayerJoinedText6 key={`${PtbRoundLogType.PLAYER_JOINED}6`} user1={user1} />,
  <PlayerJoinedText7 key={`${PtbRoundLogType.PLAYER_JOINED}7`} user1={user1} />,
  <PlayerJoinedText8 key={`${PtbRoundLogType.PLAYER_JOINED}8`} user1={user1} />,
];
