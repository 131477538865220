import { useQuery } from "@tanstack/react-query";
import { RQueryOptions } from "../../types";

interface CoinGeckoToken {
  price: number;
  change24h: number;
}

type CoinPriceResponse = Record<"eth" | "looks" | "arb" | "yolo" | string, CoinGeckoToken>;

/**
 * @see https://www.coingecko.com/en/api/documentation
 *
 * Supported Coins
 * @see https://api.coingecko.com/api/v3/coins/list
 *
 * Supported currencies
 * @see https://api.coingecko.com/api/v3/simple/supported_vs_currencies
 *
 * Price of ETH in USD
 * @see https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd
 *
 */
export const useCoinPrices = (options?: RQueryOptions<CoinPriceResponse>) => {
  return useQuery({
    queryKey: ["coin-gecko-prices"],
    queryFn: async () => {
      const response = await fetch("/api/coingecko");
      const data: CoinPriceResponse = await response.json();
      return data;
    },
    gcTime: 300_000,
    staleTime: 300_000,
    retry: false,
    ...options,
  });
};
