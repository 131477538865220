import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const YoloLooks = (props: IconProps) => {
  const [id] = useState(uniqueId());
  return (
    <Icon width="64px" height="48px" viewBox="0 0 64 48" {...props}>
      <g clipPath={`url(#${id})`}>
        <circle cx="40" cy="24" r="24" fill="#CCFD07" />
        <path
          d="M43.7541 13.0137H52.1937L44.0719 37.6178H35.6323L38.7045 28.3604H32.5601L27.793 13.0137H36.1267L39.6579 25.4389L43.7541 13.0137Z"
          fill="#1F2A37"
        />
      </g>
      <rect y="18" width="30" height="30" rx="15" fill="#04CD58" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 34.7234C13.1769 34.7234 11.6976 33.2457 11.6976 31.4211C11.6976 29.5965 13.1769 28.1187 15 28.1187C16.8231 28.1187 18.3024 29.5965 18.3024 31.4211C18.3024 33.2457 16.8231 34.7234 15 34.7234ZM13.5642 31.4211C13.5642 32.2144 14.2073 32.8569 15 32.8569C15.7927 32.8569 16.4358 32.2144 16.4358 31.4211C16.4358 30.6278 15.7927 29.9853 15 29.9853C14.2073 29.9853 13.5642 30.6278 13.5642 31.4211Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.375 31.4255L10.6926 25.1035H19.3074L25.625 31.4255L15 42.0461L4.375 31.4255ZM19.8818 29.1238C17.1975 26.4277 12.8025 26.4277 10.1182 29.1238L7.82095 31.4211L10.1182 33.7184C12.8025 36.4145 17.1975 36.4145 19.8818 33.7184L22.1791 31.4211L19.8818 29.1238Z"
        fill="black"
      />
      <defs>
        <clipPath id={id}>
          <rect width="48" height="48" fill="white" transform="translate(16)" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default YoloLooks;
