import { useEffect, useMemo, useRef } from "react";
import { useAccount } from "wagmi";
import { Box, type BoxProps } from "@chakra-ui/react";
import random from "lodash/random";
import { isAddressEqual } from "@looksrare/utils";
import { usePtbLoser, type GameStatus, useCurrentCaveRoundInfo } from "../../hooks";

interface BearVideoProps extends BoxProps {
  isActive: boolean;
  fileName: string;
}

const BearVideo = ({ isActive, fileName, ...props }: BearVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const { current: video } = videoRef;

    // Ensure the video starts at 0 when active
    if (!!video && isActive) {
      video.currentTime = 0;
    }
  }, [videoRef, isActive]);

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      height="100%"
      width="100%"
      opacity={isActive ? 1 : 0}
      transition="opacity 500ms ease-in"
      pointerEvents="none"
      zIndex={isActive ? 1 : 0}
      {...props}
    >
      <Box
        as="video"
        ref={videoRef}
        height="100%"
        width="100%"
        autoPlay
        loop
        muted
        controls={false}
        playsInline
        disablePictureInPicture
        poster="/images/poke-the-bear/pixel-cave-small.png"
      >
        <Box as="source" src={`/videos/poke-the-bear/${fileName}.mp4`} type="video/mp4" />
      </Box>
    </Box>
  );
};

interface GameVideoProps {
  gameStatus: GameStatus;
}

export const SleepingVideo = ({ gameStatus }: GameVideoProps) => (
  <BearVideo isActive={gameStatus === "idle"} fileName="sleeping" />
);

export const PokingVideo = ({ gameStatus }: GameVideoProps) => (
  <BearVideo isActive={gameStatus === "poking"} fileName="poking" />
);

export const ShufflingVideo = ({ gameStatus }: GameVideoProps) => {
  const fileName = useMemo(() => {
    return `shuffling-${random(1, 3)}`;
  }, []);
  return <BearVideo key={fileName} isActive={gameStatus === "shuffling"} fileName={fileName} />;
};

export const MaulVideo = ({ gameStatus }: GameVideoProps) => {
  const { address } = useAccount();
  const ptbLoser = usePtbLoser();
  const isConnectedPlayer = isAddressEqual(address, ptbLoser?.user.address);
  const caveInfoQuery = useCurrentCaveRoundInfo();

  // Determine if the last player is the one to be mauled
  const playersThatHavePoked =
    caveInfoQuery.data?.players.filter((player) => !!player.poke?.pokedAt && player.lost !== true).length || 0;
  const playersPerRound = caveInfoQuery.data?.cave.playersPerRound || 0;
  const lastPlayerIsMauled = playersThatHavePoked === playersPerRound - 1;

  const fileName = useMemo(() => {
    const suffix = lastPlayerIsMauled ? "-alt" : "";
    if (isConnectedPlayer) {
      return `maul-user${suffix}`;
    }
    return `maul-spectator-${random(1, 2)}${suffix}`;
  }, [isConnectedPlayer, lastPlayerIsMauled]);
  return <BearVideo key={fileName} isActive={gameStatus === "mauled"} fileName={fileName} />;
};

export const CountdowngVideo = ({ gameStatus }: GameVideoProps) => (
  <BearVideo isActive={gameStatus === "countdown"} fileName="countdown" />
);
