import { Icon, IconProps } from "../Icon";

const Help = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 16C2 8.26801 8.26801 2 16 2C19.713 2 23.274 3.475 25.8995 6.1005C28.525 8.72601 30 12.287 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16ZM17.5 23.5C17.5 24.3284 16.8284 25 16 25C15.1716 25 14.5 24.3284 14.5 23.5C14.5 22.6716 15.1716 22 16 22C16.8284 22 17.5 22.6716 17.5 23.5ZM17 8.00001H15.5C14.3057 7.99735 13.1596 8.4706 12.3151 9.31509C11.4706 10.1596 10.9973 11.3057 11 12.5V13H13V12.5C13 11.1193 14.1193 10 15.5 10H17C18.3807 10 19.5 11.1193 19.5 12.5C19.5 13.8807 18.3807 15 17 15H15V19.5H17V17C19.4853 17 21.5 14.9853 21.5 12.5C21.5 10.0147 19.4853 8.00001 17 8.00001ZM4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16Z"
      fill="currentColor"
    />
  </Icon>
);

export default Help;
