import { useEffect } from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { useCountdown, useSoundEffect } from "@looksrare/utils";
import { useMoDConfig } from "../../../config";

interface RoundEntryLoaderProps extends BoxProps {
  roundLockedAt: number;
  roundDurationInSecs: number;
}

export const RoundEntryLoader = ({ roundLockedAt, roundDurationInSecs, ...props }: RoundEntryLoaderProps) => {
  const duration = useCountdown(roundLockedAt);
  const { isMuted } = useMoDConfig();

  const durationInSeconds = (duration?.minutes ?? 0) * 60 + (duration?.seconds ?? 0);
  const durationPercent = (durationInSeconds / roundDurationInSecs) * 100;

  const { play: playCountdown, stop: stopCountdown } = useSoundEffect({
    path: "/sounds/moon-or-doom/countdown.mp3",
    volume: 1,
    isMuted,
  });

  useEffect(() => {
    if (durationInSeconds < 8) {
      playCountdown({ restartIfAlreadyPlaying: false });
    } else {
      stopCountdown();
    }
  }, [durationInSeconds, playCountdown, stopCountdown]);

  if (!duration) {
    return <Box position="relative" width="100%" height="4px" backgroundColor="ui-02" />;
  }

  return (
    <Box
      position="relative"
      width="100%"
      height="4px"
      backgroundColor="ui-02"
      _after={{
        content: '""',
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        width: `${durationPercent}%`,
        backgroundColor: "acid.200",
        transition: "width 1000ms linear",
      }}
      {...props}
    />
  );
};
