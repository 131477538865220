import { AspectRatio, Box, Flex, FlexProps } from "@chakra-ui/react";
import { Text, CloudinaryImage } from "@looksrare/uikit";

interface NavSubMenuItemProps extends FlexProps {
  title: string;
  description?: string;
  image?: string;
  href?: string;
}

export const NavSubMenuItem = ({ title, description, image, ...props }: NavSubMenuItemProps) => {
  const containerProps = !!image
    ? {}
    : {
        height: "48px",
        py: 2,
      };
  return (
    <Flex
      width="100%"
      borderRadius="button"
      pl={4}
      gap="18px"
      bg="ui-02"
      alignItems="center"
      overflow="hidden"
      {...containerProps}
      {...props}
    >
      <Box flexGrow={1}>
        <Text bold>{title}</Text>
        {!!description && <Text textStyle="helper">{description}</Text>}
      </Box>
      {!!image && (
        <AspectRatio width="96px" ratio={3 / 2}>
          <CloudinaryImage src={image} alt="" layout="fill" sizes="192px" objectFit="cover" />
        </AspectRatio>
      )}
    </Flex>
  );
};
