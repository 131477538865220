interface Args {
  currentSeconds: number;
  roundCutoffTime: number;
  roundDuration: number;
}

/**
 * Return seconds elapsed as percentage of total round duration
 */
export const getRoundPercentElapsed = ({ currentSeconds, roundCutoffTime, roundDuration }: Args): number => {
  const secondsElapsed = roundDuration - (roundCutoffTime - currentSeconds);
  return (secondsElapsed / roundDuration) * 100;
};
