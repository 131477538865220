import { useAccount } from "wagmi";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import capitalize from "lodash/capitalize";
import { Flex, Grid, Switch, useDisclosure } from "@chakra-ui/react";
import { ArrowLeftIcon, Button, ButtonToggle, NextLink, Text } from "@looksrare/uikit";
import { useUnclaimedPtbFunds } from "../../hooks";
import { BASE_URL, usePtbConfig } from "../../config";
import { type CaveOption, type StatusOption } from "../../views/HistoryView";
import { ClaimBox } from "../ClaimBox/ClaimBox";
import { ClaimFundsModal } from "../Modals/ClaimFunds";
import { useGetHistoryQueryParams } from "../../hooks/useGetHistoryQueryParams";
import { FilterButton } from "./FilterButton";

interface HeaderProps {
  caveOptions: CaveOption[];
  isShowMyEntries: boolean;
  selectedOptions: CaveOption[];
  selectedStatus: StatusOption;
  setSelectedStatus: (status: StatusOption) => void;
  toggleCaveOption: (option: CaveOption) => void;
  toggleShowMyEntries: () => void;
}

export const Header = ({
  caveOptions,
  isShowMyEntries,
  selectedOptions,
  selectedStatus,
  setSelectedStatus,
  toggleCaveOption,
  toggleShowMyEntries,
}: HeaderProps) => {
  const { t } = useTranslation();
  const { isConnected, address } = useAccount();
  const { query } = useRouter();
  const { network } = useGetHistoryQueryParams();
  const ptbConfig = usePtbConfig();
  const { fromCave } = query;
  const backButtonHref = fromCave ? `${BASE_URL}/cave/${network}/${fromCave}` : "";

  const statusOptions = ["all", "completed", "canceled"] as StatusOption[];
  const getVariant = (isActive: boolean) => (isActive ? "solid" : "outline");
  const getColor = (isActive: boolean) => (isActive ? "link-01" : "link-02");

  const { isLoading, ethAmount, looksAmount } = useUnclaimedPtbFunds(network);
  const claimFundsModalDisclosure = useDisclosure();

  return (
    <Grid
      gap={4}
      gridTemplateAreas={{ base: "'backButton' 'claim' 'filters'", lg: "'backButton claim' 'filters claim'" }}
      gridTemplateColumns={{ base: "1fr", lg: "1fr minmax(512px, 1fr)" }}
    >
      <Flex gridArea="backButton">
        {backButtonHref ? (
          <Button leftIcon={<ArrowLeftIcon />} size="xs" as={NextLink} href={backButtonHref} variant="ghost">
            {t("Current Round")}
          </Button>
        ) : (
          <Button leftIcon={<ArrowLeftIcon />} size="xs" as={NextLink} href="/poke-the-bear" variant="ghost">
            {t("Game Lobby")}
          </Button>
        )}
      </Flex>
      <Flex gridArea="filters">
        <Flex
          flexDirection={{ base: "column", xl: "row" }}
          gap={{ base: 2, xl: 3 }}
          alignItems={{ base: "flex-start", xl: "center" }}
          width="100%"
        >
          <Flex gap={{ base: 2, xl: 3 }}>
            <ButtonToggle>
              {statusOptions.map((status) => {
                return (
                  <Button
                    key={status}
                    onClick={() => setSelectedStatus(status)}
                    size="sm"
                    variant={getVariant(selectedStatus === status)}
                    color={getColor(selectedStatus === status)}
                  >
                    {capitalize(status)}
                  </Button>
                );
              })}
            </ButtonToggle>
            <FilterButton onClickOption={toggleCaveOption} options={caveOptions} selectedOptions={selectedOptions} />
          </Flex>
          <Flex alignItems="center" gap={3}>
            <Switch isChecked={isShowMyEntries} onChange={toggleShowMyEntries} isDisabled={!isConnected} />
            <Text textStyle="detail" bold color={!isConnected ? "text-disabled" : "text-02"} whiteSpace="nowrap">
              Show My Entries
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {ptbConfig.renderHistoryPageTopClaimBox && (
        <Flex gridArea="claim" alignItems="center">
          <ClaimBox
            network={network}
            width="100%"
            onClickClaim={claimFundsModalDisclosure.onOpen}
            ethAmount={ethAmount}
            looksAmount={looksAmount}
            address={address}
            isLoading={isLoading}
          />
          <ClaimFundsModal
            network={network}
            isOpen={claimFundsModalDisclosure.isOpen}
            onClose={claimFundsModalDisclosure.onClose}
          />
        </Flex>
      )}
    </Grid>
  );
};
