import { Icon, IconProps } from "../Icon";

const WinnerCup = (props: IconProps) => (
  <Icon viewBox="0 0 36 32" fill="none" {...props}>
    <path
      d="M21.9384 14.2932C21.7563 14.4619 21.4633 14.4092 21.3514 14.1876L16.3339 4.24951C16.1959 3.97606 15.8054 3.97606 15.6673 4.24951L10.6498 14.1876C10.5379 14.4092 10.2449 14.4619 10.0628 14.2932L2.80258 7.56823C2.54132 7.32624 2.12183 7.55257 2.18063 7.90379L4.28132 20.4523C4.31144 20.6322 4.46715 20.764 4.64956 20.764H27.3525C27.5349 20.764 27.6906 20.6322 27.7208 20.4523L29.8215 7.9037C29.8803 7.55249 29.4608 7.32616 29.1995 7.56813L21.9384 14.2932Z"
      fill="url(#paint0_linear_672_12006)"
    />
    <path
      d="M27.2976 26.3644H4.7093C4.5031 26.3644 4.33594 26.1973 4.33594 25.9911V23.4709C4.33594 23.2647 4.5031 23.0975 4.7093 23.0975H27.2976C27.5038 23.0975 27.671 23.2647 27.671 23.4709V25.9911C27.671 26.1973 27.5038 26.3644 27.2976 26.3644Z"
      fill="url(#paint1_linear_672_12006)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_672_12006"
        x1="15.9926"
        y1="-0.880247"
        x2="15.0044"
        y2="14.3102"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDF307" />
        <stop offset="1" stopColor="#D5CD0B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_672_12006"
        x1="15.9943"
        y1="-5.14876"
        x2="14.6513"
        y2="12.5464"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDF307" />
        <stop offset="1" stopColor="#D5CD0B" />
      </linearGradient>
    </defs>
  </Icon>
);

export default WinnerCup;
