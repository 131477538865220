import { useMemo } from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import { Duration } from "date-fns";
import { Text } from "@looksrare/uikit";

type CountdownStatus = "counting" | "inProgress" | "ended" | "canceled" | "readyToJoin";

interface LobbyCaveCardCountdownProps extends FlexProps {
  duration?: Duration;
  status: CountdownStatus;
}

export const LobbyCaveCardCountdown = ({ duration, status, ...props }: LobbyCaveCardCountdownProps) => {
  const colors = useMemo((): { highlight: string; background: string } => {
    // Green
    if (status === "inProgress") {
      return {
        highlight: "support-success",
        background: "support-success-bg",
      };
    }

    // Red
    if (status === "counting" && duration && duration.minutes === 0 && duration.seconds && duration.seconds < 60) {
      return {
        highlight: "support-error",
        background: "support-error-bg",
      };
    }

    // Gray
    return {
      highlight: "text-02",
      background: "ui-01",
    };
  }, [duration, status]);

  const node = (() => {
    if (status === "inProgress") {
      return (
        <Text textStyle="heading-03" color="inherit" bold style={{ fontFeatureSettings: '"tnum" on, "lnum" on' }}>
          In Progress
        </Text>
      );
    }
    if (status === "ended") {
      return (
        <Text textStyle="heading-03" color="inherit" bold style={{ fontFeatureSettings: '"tnum" on, "lnum" on' }}>
          Ended
        </Text>
      );
    }
    if (status === "canceled") {
      return (
        <Text textStyle="heading-03" color="inherit" bold style={{ fontFeatureSettings: '"tnum" on, "lnum" on' }}>
          Canceled
        </Text>
      );
    }
    if (status === "readyToJoin") {
      return (
        <Text textStyle="heading-03" color="inherit" bold style={{ fontFeatureSettings: '"tnum" on, "lnum" on' }}>
          Enter
        </Text>
      );
    }
  })();

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      borderColor={colors.highlight}
      border="solid 1px"
      borderRadius="button"
      color={colors.highlight}
      bgColor={colors.background}
      minWidth="66px"
      minHeight="32px"
      width="fit-content"
      px={2}
      {...props}
    >
      {node}
    </Flex>
  );
};
