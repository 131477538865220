import { Icon, IconProps } from "../Icon";

const TrophyFill = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.7866 4C8.79989 4 8 4.79989 8 5.7866V6.5H4.05505C3.47236 6.5 3 6.97236 3 7.55505C3 10.3462 4.10877 13.023 6.08239 14.9966L9.04925 17.9635C10.2704 20.1004 12.4487 21.62 15 21.9381V26H11V28H15H17H21V26H17V21.9381C19.5513 21.62 21.7296 20.1004 22.9507 17.9635L25.9176 14.9966C27.8912 13.023 29 10.3462 29 7.55505C29 6.97236 28.5276 6.5 27.9449 6.5H24V5.7866C24 4.79989 23.2001 4 22.2134 4H9.7866ZM24 8.5V14C24 14.0288 23.9998 14.0575 23.9995 14.0862L24.5034 13.5824C25.8776 12.2082 26.7342 10.413 26.9475 8.5H24ZM5.05251 8.5H8V14C8 14.0288 8.00015 14.0575 8.00046 14.0862L7.49661 13.5824C6.12238 12.2082 5.26585 10.413 5.05251 8.5Z"
      fill="currentColor"
    />
  </Icon>
);

export default TrophyFill;
