import { StrategyType } from "@looksrare/sdk-v2";
import { Address } from "viem";
import { NotificationType, ValidNotificationSetting } from "@looksrare/nfts";
import {
  BrowserNotificationEnabledDataLayerType,
  NotificationItemClickDataLayerType,
  NotificationSettingsSwitchDataLayerType,
} from "./notifications";
import {
  AskCreatedDataLayerType,
  BidCreatedDataLayerType,
  AskExecutedDataLayerType,
  BidExecutedDataLayerType,
} from "./orders";
import { WalletConnectionDataLayerType } from "./wallet";
import {
  DontFallInDepositDataLayerType,
  FlipperDepositDataLayerType,
  LaserBlastDepositDataLayerType,
  MordDepositDataLayerType,
  PtbDepositDataLayerType,
  QuantumDepositDataLayerType,
  YoloDepositDataLayerType,
} from "./win";

export enum DataLayerEventNames {
  EXECUTED_BIDS = "executed_bids",
  EXECUTED_ASKS = "executed_asks",
  CONNECTED_WALLET = "connected_wallet",
  CREATED_ASKS = "created_asks",
  CREATED_BIDS = "created_bids",
  BROWSER_NOTIFICATIONS_ENABLED = "browser_notifications_enabled",
  NOTIFICATION_SETTINGS_SWITCH = "notification_settings_switch",
  NOTIFICATION_ITEM_CLICK = "notification_item_click",
  YOLO_DEPOSIT = "yolo_deposit",
  MORD_DEPOSIT = "mord_deposit",
  PTB_DEPOSIT = "ptb_deposit",
  FLIPPER_DEPOSIT = "flipper_deposit",
  QUANTUM_DEPOSIT = "quantum_deposit",
  LASER_BLAST_DEPOSIT = "laser_blast_deposit",
  DONT_FALL_IN_DEPOSIT = "dont_fall_in_deposit",
}

export type DataLayerEvent = {
  event: DataLayerEventNames;
};

export interface BaseMarketplaceTradeData {
  totalPriceEth: number;
  countTokens?: number; // undefined for collection offer creation
  avgPctToFloor: number; // Average % of price above or below floor
}

export interface CompetitorMarketplaceTradeData extends BaseMarketplaceTradeData {
  countImported?: number; // undefined if not creating listings via import
}

/** Alphabetized list of supported User-Defined Data Layer Variables in TagManager */
export type SupportedDataLayerVariables = {
  collectionAddress: Address;
  tokensTradeDataLr: BaseMarketplaceTradeData;
  tokensTradeDataLrSeaport: BaseMarketplaceTradeData;
  tokensTradeDataOs: CompetitorMarketplaceTradeData;
  tokensTradeDataBlur: CompetitorMarketplaceTradeData;
  tokenId?: string;
  collectionIsVerified: boolean;
  collectionRoyaltiesPercentage: number;
  protocolFeePercentage: number;
  connectedWalletAddress?: string;
  connectedWalletConnector?: string;
  ethUsdPrice: number;
  orderStrategy: StrategyType;
  transactionId: string;
  profitUsd: number;
  profitWei: string;
  totalPriceEth: string;
  totalPriceUsd: string;
  notificationEnabledStatus: "success" | "permission-denied" | "dismissed";
  notificationSetting: ValidNotificationSetting;
  notificationSettingValue: boolean | string;
  notificationType: NotificationType;
};

export type DataLayerAllowedValues =
  | WalletConnectionDataLayerType
  | AskExecutedDataLayerType
  | BidExecutedDataLayerType
  | AskCreatedDataLayerType
  | BidCreatedDataLayerType
  | BrowserNotificationEnabledDataLayerType
  | NotificationItemClickDataLayerType
  | NotificationSettingsSwitchDataLayerType
  | YoloDepositDataLayerType
  | MordDepositDataLayerType
  | PtbDepositDataLayerType
  | FlipperDepositDataLayerType
  | QuantumDepositDataLayerType
  | LaserBlastDepositDataLayerType
  | DontFallInDepositDataLayerType;
