import { Icon, IconProps } from "../Icon";

const RainbowGift = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M19.5 7.49999H17.4287C17.9169 6.77848 18.1062 5.89577 17.9569 5.03751C17.8076 4.17926 17.3313 3.41236 16.6281 2.89812C15.9249 2.38388 15.0497 2.16238 14.1866 2.2802C13.3234 2.39802 12.5396 2.84598 12 3.52987C11.4592 2.84937 10.6761 2.4045 9.8146 2.28844C8.95315 2.17238 8.08021 2.39413 7.37858 2.90724C6.67696 3.42035 6.20107 4.18503 6.05059 5.04114C5.9001 5.89724 6.08669 6.77837 6.57127 7.49999H4.5C4.10233 7.50049 3.72109 7.65868 3.43989 7.93988C3.15869 8.22108 3.0005 8.60232 3 8.99999V12C3.0005 12.3977 3.15869 12.7789 3.43989 13.0601C3.72109 13.3413 4.10233 13.4995 4.5 13.5V21C4.5005 21.3977 4.65869 21.7789 4.93989 22.0601C5.22109 22.3413 5.60233 22.4995 6 22.5H18C18.3977 22.4995 18.7789 22.3413 19.0601 22.0601C19.3413 21.7789 19.4995 21.3977 19.5 21V13.5C19.8977 13.4995 20.2789 13.3413 20.5601 13.0601C20.8413 12.7789 20.9995 12.3977 21 12V8.99999C20.9995 8.60232 20.8413 8.22108 20.5601 7.93988C20.2789 7.65868 19.8977 7.50049 19.5 7.49999V7.49999ZM12.75 5.62499C12.75 5.25415 12.86 4.89164 13.066 4.5833C13.272 4.27496 13.5649 4.03463 13.9075 3.89272C14.2501 3.75081 14.6271 3.71367 14.9908 3.78602C15.3545 3.85837 15.6886 4.03695 15.9508 4.29917C16.213 4.56139 16.3916 4.89549 16.464 5.2592C16.5363 5.62291 16.4992 5.99991 16.3573 6.34253C16.2154 6.68514 15.975 6.97797 15.6667 7.184C15.3584 7.39003 14.9958 7.49999 14.625 7.49999H12.75V5.62499ZM9.375 3.74999C9.8721 3.75059 10.3487 3.94832 10.7002 4.29983C11.0517 4.65133 11.2494 5.1279 11.25 5.62499V7.49999H9.375C8.87772 7.49999 8.40081 7.30245 8.04917 6.95082C7.69754 6.59919 7.5 6.12227 7.5 5.62499C7.5 5.12771 7.69754 4.6508 8.04917 4.29917C8.40081 3.94754 8.87772 3.74999 9.375 3.74999V3.74999ZM4.5 8.99999H11.25V12H4.5V8.99999ZM6 13.5H11.25V21H6V13.5ZM18.0009 21H12.75V13.5H18L18.0009 21ZM12.75 12V8.99999H19.5L19.5009 12H12.75Z"
      fill="url(#paint0_angular_541_160)"
    />
    <defs>
      <radialGradient
        id="paint0_angular_541_160"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12 12.6815) scale(18.375 27.4497)"
      >
        <stop stopColor="#4589FF" />
        <stop offset="0.392305" stopColor="#FF7EB6" />
        <stop offset="0.642309" stopColor="#F1C21B" />
        <stop offset="0.798562" stopColor="#49CD7A" />
        <stop offset="1" stopColor="#4589FF" />
      </radialGradient>
    </defs>
  </Icon>
);

export default RainbowGift;
