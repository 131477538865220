import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const TokenEthArbitrum = (props: IconProps) => {
  const id = uniqueId();
  return (
    <Icon viewBox="0 0 96 96" fill="none" {...props}>
      <g clipPath={`url(#${id})`}>
        <circle cx="40" cy="40" r="40" fill="#F3F3F8" />
        <g clipPath="url(#clip1_2593_491)">
          <path
            d="M39.9951 11.4997L39.6127 12.7987V50.4885L39.9951 50.87L57.4899 40.5287L39.9951 11.4997Z"
            fill="#767676"
          />
          <path d="M39.9953 11.4997L22.5 40.5287L39.9953 50.87V32.5764V11.4997Z" fill="#8E8E8E" />
          <path
            d="M39.9937 54.184L39.7782 54.4468V67.8725L39.9937 68.5017L57.4993 43.848L39.9937 54.184Z"
            fill="#5F5F5F"
          />
          <path d="M39.9953 68.5017V54.184L22.5 43.848L39.9953 68.5017Z" fill="#8E8E8E" />
          <path d="M39.9955 50.8694L57.4903 40.5281L39.9955 32.5758V50.8694Z" fill="#5F5F5F" />
          <path d="M22.5 40.5281L39.9953 50.8694V32.5758L22.5 40.5281Z" fill="#767676" />
        </g>
        <g clipPath="url(#clip2_2593_491)">
          <path
            d="M72 96C85.2548 96 96 85.2548 96 72C96 58.7452 85.2548 48 72 48C58.7452 48 48 58.7452 48 72C48 85.2548 58.7452 96 72 96Z"
            fill="#213147"
          />
          <path
            d="M56.4363 64.5502V79.4466C56.4363 80.3976 56.9444 81.2771 57.7677 81.7511L70.6683 89.2008C71.4915 89.6748 72.5051 89.6748 73.3283 89.2008L86.2289 81.7511C87.0521 81.2771 87.5603 80.3976 87.5603 79.4466V64.5502C87.5603 63.5992 87.0521 62.7197 86.2289 62.2457L73.3283 54.796C72.5051 54.3221 71.4915 54.3221 70.6683 54.796L57.7645 62.2457C56.9413 62.7197 56.4363 63.5992 56.4363 64.5502Z"
            fill="#213147"
          />
          <path
            d="M74.8127 74.8876L72.9729 79.933C72.9231 80.0732 72.9231 80.2262 72.9729 80.3665L76.1379 89.0478L79.7987 86.9336L75.4049 74.8876C75.305 74.6102 74.9122 74.6102 74.8124 74.8876H74.8127Z"
            fill="#12AAFF"
          />
          <path
            d="M78.5019 66.4026C78.402 66.1252 78.0093 66.1252 77.9094 66.4026L76.0695 71.4479C76.0198 71.5882 76.0198 71.7412 76.0695 71.8815L81.2551 86.0948L84.9159 83.9806L78.5015 66.4026H78.5019Z"
            fill="#12AAFF"
          />
          <path
            d="M71.997 55.3543C72.0875 55.3543 72.178 55.3791 72.2589 55.423L86.2197 63.4838C86.3817 63.5774 86.4815 63.7519 86.4815 63.936V80.0546C86.4815 80.2418 86.3817 80.4131 86.2197 80.5068L72.2589 88.5676C72.1808 88.6142 72.0875 88.6363 71.997 88.6363C71.9065 88.6363 71.816 88.6114 71.7351 88.5676L57.7743 80.513C57.6123 80.4194 57.5125 80.2449 57.5125 80.0608V63.9391C57.5125 63.7519 57.6123 63.5806 57.7743 63.4869L71.7351 55.4261C71.8163 55.3795 71.9065 55.3543 71.997 55.3543ZM71.997 53C71.5013 53 71.0021 53.1278 70.5565 53.3866L56.5987 61.4443C55.7068 61.9587 55.1582 62.91 55.1582 63.9388V80.0573C55.1582 81.0864 55.7071 82.0375 56.5987 82.5518L70.5596 90.6127C71.0055 90.8683 71.5013 90.9992 72.0001 90.9992C72.4989 90.9992 72.995 90.8714 73.4407 90.6127L87.4015 82.5518C88.2934 82.0375 88.842 81.0861 88.842 80.0573V63.9388C88.842 62.9097 88.2931 61.9587 87.4015 61.4443L73.4375 53.3866C72.9916 53.1278 72.4927 53 71.997 53Z"
            fill="#9DCCED"
          />
          <path d="M62.7637 86.1135L64.0484 82.5961L66.6334 84.7444L64.2167 86.9522L62.7637 86.1135Z" fill="#213147" />
          <path
            d="M70.8213 62.7853H67.2821C67.0171 62.7853 66.7801 62.9505 66.6896 63.2002L59.1028 83.9993L62.7636 86.1135L71.1177 63.2095C71.1957 63.0037 71.0427 62.7853 70.8213 62.7853Z"
            fill="white"
          />
          <path
            d="M77.0143 62.7853H73.4751C73.2102 62.7853 72.9732 62.9505 72.8827 63.2002L64.2201 86.9495L67.8809 89.0636L77.3107 63.2095C77.3857 63.0037 77.2326 62.7853 77.0143 62.7853Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="96" height="96" fill="white" />
        </clipPath>
        <clipPath id="clip1_2593_491">
          <rect width="35" height="70" fill="white" transform="translate(22.5 5)" />
        </clipPath>
        <clipPath id="clip2_2593_491">
          <rect width="48" height="48" fill="white" transform="translate(48 48)" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default TokenEthArbitrum;
