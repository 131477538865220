import { Flex } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";
import { BigIntish, formatCompactNumber } from "@looksrare/utils";
import { useTranslation } from "next-i18next";

interface PlayerListMetaProps {
  gemsEarned?: BigIntish | null;
}

export const PlayerListMeta = ({ gemsEarned }: PlayerListMetaProps) => {
  const { t } = useTranslation();
  if (!gemsEarned) {
    return null;
  }

  const gemsEarnedDisplay = formatCompactNumber(Number(gemsEarned));
  return (
    <Flex alignItems="center" gap={1}>
      <Text color="text-03" textStyle="detail">
        {t("{{points}} Pts", { points: gemsEarnedDisplay })}
      </Text>
    </Flex>
  );
};
