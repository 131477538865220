import { IconProps, RakebackIcon } from "@looksrare/uikit";
import { useAccount } from "wagmi";
import { Badge, Box } from "@chakra-ui/react";
import { useUserRakebackData } from "@/hooks/rakeback";

export const RakebackIconWithCounter = (props: IconProps) => {
  const { address } = useAccount();
  const { data: rakebackData } = useUserRakebackData(address!, {
    enabled: !!address,
  });

  const availableRakebacksNumber = Number(rakebackData?.rakebackClaimDetails.length ?? 0);
  const availableRakebacksDisplay = availableRakebacksNumber > 99 ? "99+" : availableRakebacksNumber;

  return (
    <Box>
      {availableRakebacksNumber > 0 && (
        <Badge
          position="absolute"
          top="-25%"
          px={1}
          right="calc(-100% - 4px)" // to the right border but respect padding
          bg="support-error-inverse"
          color="white"
          textAlign="center"
        >
          {availableRakebacksDisplay}
        </Badge>
      )}
      <RakebackIcon {...props} />
    </Box>
  );
};
