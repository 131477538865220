import Image from "next/image";
import { useTranslation } from "next-i18next";
import { Box, Flex, List, ListItem, Stack, useBreakpointValue } from "@chakra-ui/react";
import { ArrowLeftIcon, Button, Container, ExternalLink, NextLink, Text, useWidgetBotOptions } from "@looksrare/uikit";
import { navHeightResponsive } from "@looksrare/chakra-theme";
import { useWidgetBotCrate } from "@looksrare/utils";
import { FAQ } from "../components/FAQ";

export const FAQView = () => {
  useWidgetBotCrate(useWidgetBotOptions());
  const { t } = useTranslation();
  // at 2xl, a second column is added with the sticky logo asset
  const is2xl = useBreakpointValue({ base: false, "2xl": true });

  return (
    <Container position="relative" my={16} maxWidth="1440px">
      <Flex flexDirection="row" justifyContent="space-between" alignItems="flex-start">
        <Stack spacing={16} maxWidth={{ base: "auto", "2xl": "768px" }} flex={1}>
          {/* Header */}
          <Stack spacing={4}>
            <Box>
              <Button
                as={NextLink}
                href="/poke-the-bear"
                variant="ghost"
                size="xs"
                textStyle="detail"
                color="link-01"
                textAlign="center"
                leftIcon={<ArrowLeftIcon />}
              >
                {t("Lobby")}
              </Button>
            </Box>
            <Text textStyle="display-01" bold>
              Poke the Bear
            </Text>
          </Stack>
          {/* Ready to Poke the Bear? */}
          <Stack spacing={8}>
            {/* Ready1 */}
            <Stack spacing={4}>
              <Text textStyle="heading-03" bold>
                {t("ptb::Ready to Poke the Bear?")}
              </Text>
              <Stack spacing={4}>
                <Text color="text-02">{t("ptb::Fortune favors the brave.")}</Text>
                <Text color="text-02">
                  {t(
                    "ptb::In our latest game, you'll venture into a cave with a handful of other players — and take turns prodding a very hungry bear."
                  )}
                </Text>
                <Text color="text-02">-</Text>
                <Text color="text-02">
                  {t(
                    "ptb::Sooner or later, a poke is going to wake him up. And he's going to maul the bastard responsible. Meanwhile, the rest of the players will run off with whatever ETH/LOOKS the unlucky degen brought in with them."
                  )}
                </Text>
              </Stack>
            </Stack>
            {/* Ready2 */}
            <Stack spacing={4}>
              <Text textStyle="heading-03" bold>
                🤔 {t("ptb::Ready to Poke the Bear?")}
              </Text>
              <Stack spacing={4}>
                <Text color="text-02">
                  {t("ptb::Poke the Bear — like Raffles or YOLO — is Chainlink-powered to ensure provable fairness.")}
                </Text>
                <Text color="text-02">
                  {t(
                    "ptb::Each round takes place in a cave with a fixed amount of seats (one per player). Before heading in, you'll pay an entry fee:"
                  )}
                </Text>
                <List>
                  <ListItem>
                    <Text color="text-02">
                      {t("ptb::Different caves have different fees, so there's something for everyone")}
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text color="text-02">
                      {t("ptb::The cost is denominated either in ETH or LOOKS, depending on the cave")}
                    </Text>
                  </ListItem>
                </List>
                <Text color="text-02">
                  {t(
                    "ptb::Once everyone's comfortable, the contract hits up Chainlink for the magic number: the index of the player destined to be devoured by the bear. Of course, there'll be some preprocessing so players can't figure it out before the round."
                  )}
                </Text>
                <Text color="text-02">
                  {t(
                    "ptb::From here, you'll each take a turn to poke the bear. You'll get fifteen seconds to do so, after which we'll laugh at you for your cowardice and poke him on your behalf anyway. One of two things will happen:"
                  )}
                </Text>
                <List>
                  <ListItem>
                    <Text color="text-02">{t("ptb::He'll keep sleeping, and you can breathe a sigh of relief")}</Text>
                  </ListItem>
                  <ListItem>
                    <Text color="text-02">{t("ptb::He'll wake up and devour you. GG.")}</Text>
                  </ListItem>
                </List>
                <Text color="text-02">
                  {t(
                    "ptb::When the loser gets mauled, the round ends. Their entry fee — minus our 5% fee — is distributed among the survivors."
                  )}
                </Text>
              </Stack>
            </Stack>
            {/* Brave Enough */}
            <Stack spacing={4}>
              <Text textStyle="heading-03" bold>
                🐻 {t("ptb::Are You Brave Enough?")}
              </Text>
              <Text color="text-02">{t("ptb::Pick your cave, pay the fee, and take a seat.")}</Text>
              <Box>
                <Button as={NextLink} href="/poke-the-bear">
                  {t("Play Now")}
                </Button>
              </Box>
            </Stack>
          </Stack>
          {/* How does it work? */}
          <Stack spacing={4}>
            <Text textStyle="heading-03" bold>
              {t("How Does It Work?")}
            </Text>
            <FAQ />
          </Stack>
          <Box>
            <Button
              as={ExternalLink}
              href="https://docs.looksrare.org/blog/ready-to-poke-the-bear"
              isExternal
              colorScheme="secondary"
            >
              {t("Learn More")}
            </Button>
          </Box>
          {!is2xl && (
            <Flex width="100%" justifyContent="center">
              <Image src="/images/poke-the-bear/logo.png" alt="Poke The Bear" sizes="375px" width="375" height="422" />
            </Flex>
          )}
        </Stack>

        {is2xl && (
          <Box position="sticky" top={navHeightResponsive}>
            <Image src="/images/poke-the-bear/logo.png" alt="Poke The Bear" sizes="375px" width="375" height="422" />
          </Box>
        )}
      </Flex>
    </Container>
  );
};
