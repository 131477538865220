import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

export type MoDChart = "oracle" | "tradingview";

interface MoDState {
  selectedChart: MoDChart;
  isTradingViewModalOpen: boolean;
  hasSeenTradingViewModalOnce: boolean;
  shouldSmoothData: boolean;
  setSelectedChart: (chart: MoDChart) => void;
  setIsTradingViewModalOpen: (isOpen: boolean) => void;
  setShouldSmoothData: (shouldSmooth: boolean) => void;
}

export const useMoDStore = create<MoDState>()(
  persist(
    (set) => ({
      selectedChart: "oracle",
      isTradingViewModalOpen: false,
      hasSeenTradingViewModalOnce: false,
      shouldSmoothData: true,
      setSelectedChart: (chart: MoDChart) => {
        set(({ hasSeenTradingViewModalOnce, ...state }) => {
          if (chart === "tradingview" && !hasSeenTradingViewModalOnce) {
            return {
              ...state,
              isTradingViewModalOpen: true,
              hasSeenTradingViewModalOnce: true,
              selectedChart: chart,
            };
          }

          return {
            ...state,
            hasSeenTradingViewModalOnce,
            selectedChart: chart,
          };
        });
      },
      setIsTradingViewModalOpen: (isOpen: boolean) => {
        set((state) => {
          return {
            ...state,
            isTradingViewModalOpen: isOpen,
          };
        });
      },
      setShouldSmoothData: (shouldSmooth: boolean) => {
        set((state) => {
          return {
            ...state,
            shouldSmoothData: shouldSmooth,
          };
        });
      },
    }),
    { name: "MoD-state", storage: createJSONStorage(() => localStorage) }
  )
);
