import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const TokenArbitrum = (props: IconProps) => {
  const id = uniqueId();
  return (
    <Icon viewBox="0 0 96 96" fill="none" {...props}>
      <g id="token-arbitrum-arb" clipPath={`url(#${id})`}>
        <path
          id="Vector"
          d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z"
          fill="#213147"
        />
        <path
          id="Vector_2"
          d="M16.8726 33.1005V62.8931C16.8726 64.7952 17.8889 66.5542 19.5353 67.5021L45.3366 82.4016C46.983 83.3495 49.0101 83.3495 50.6565 82.4016L76.4578 67.5021C78.1042 66.5542 79.1205 64.7952 79.1205 62.8931V33.1005C79.1205 31.1984 78.1042 29.4394 76.4578 28.4914L50.6565 13.592C49.0101 12.6441 46.983 12.6441 45.3366 13.592L19.5291 28.4914C17.8827 29.4394 16.8726 31.1984 16.8726 33.1005Z"
          fill="#213147"
        />
        <path
          id="Vector_3"
          d="M53.6254 53.7752L49.9456 63.8659C49.8461 64.1464 49.8461 64.4525 49.9456 64.733L56.2757 82.0955L63.5972 77.8671L54.8096 53.7752C54.6099 53.2204 53.8243 53.2204 53.6247 53.7752H53.6254Z"
          fill="#12AAFF"
        />
        <path
          id="Vector_4"
          d="M61.0036 36.8051C60.804 36.2503 60.0184 36.2503 59.8187 36.8051L56.1389 46.8958C56.0395 47.1764 56.0395 47.4824 56.1389 47.7629L66.5102 76.1896L73.8317 71.9613L61.0029 36.8051H61.0036Z"
          fill="#12AAFF"
        />
        <path
          id="Vector_5"
          d="M47.9939 14.7085C48.1749 14.7085 48.3559 14.7583 48.5176 14.846L76.4392 30.9677C76.7633 31.1549 76.9629 31.5038 76.9629 31.8721V64.1091C76.9629 64.4836 76.7633 64.8263 76.4392 65.0135L48.5176 81.1351C48.3614 81.2284 48.1749 81.2726 47.9939 81.2726C47.8129 81.2726 47.6318 81.2229 47.4702 81.1351L19.5485 65.0259C19.2245 64.8387 19.0248 64.4898 19.0248 64.1215V31.8783C19.0248 31.5038 19.2245 31.1611 19.5485 30.9739L47.4702 14.8522C47.6325 14.759 47.8129 14.7085 47.9939 14.7085ZM47.9939 10C47.0024 10 46.0041 10.2556 45.1128 10.7731L17.1974 26.8885C15.4134 27.9173 14.3163 29.8201 14.3163 31.8776V64.1146C14.3163 66.1728 15.4141 68.0749 17.1974 69.1037L45.119 85.2253C46.011 85.7366 47.0024 85.9984 48.0001 85.9984C48.9978 85.9984 49.9899 85.7428 50.8812 85.2253L78.8028 69.1037C80.5867 68.0749 81.6839 66.1722 81.6839 64.1146V31.8776C81.6839 29.8194 80.586 27.9173 78.8028 26.8885L50.875 10.7731C49.983 10.2556 48.9853 10 47.9939 10Z"
          fill="#9DCCED"
        />
        <path
          id="Vector_6"
          d="M29.5272 76.2269L32.0967 69.1921L37.2668 73.4889L32.4332 77.9045L29.5272 76.2269Z"
          fill="#213147"
        />
        <path
          id="Vector_7"
          d="M45.6425 29.5706H38.5642C38.0343 29.5706 37.5603 29.9009 37.3793 30.4004L22.2056 71.9986L29.5271 76.2269L46.2353 30.4191C46.3915 30.0073 46.0854 29.5706 45.6425 29.5706Z"
          fill="white"
        />
        <path
          id="Vector_8"
          d="M58.0285 29.5706H50.9502C50.4202 29.5706 49.9463 29.9009 49.7653 30.4004L32.4401 77.8989L39.7616 82.1273L58.6213 30.4191C58.7712 30.0073 58.4652 29.5706 58.0285 29.5706Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default TokenArbitrum;
