import { Flex } from "@chakra-ui/react";
import { usePokingPlayer } from "../../hooks";
import { PokeDurationTimer2 } from "../PokeDurationTimer";

export const PokerTimer = () => {
  const pokingPtbPlayer = usePokingPlayer();

  if (!pokingPtbPlayer) {
    return null;
  }

  return (
    <Flex
      bg="ui-bg"
      borderRadius="circular"
      alignItems="center"
      justifyContent="center"
      position="absolute"
      top={2}
      right={2}
      zIndex="tooltip"
      height={24}
      width={24}
    >
      <PokeDurationTimer2
        size={80}
        playerAddress={pokingPtbPlayer.user.address}
        isPokingUntil={pokingPtbPlayer.poke?.isPokingUntil}
      />
    </Flex>
  );
};
