import { useState, type FC, type PropsWithChildren } from "react";
import { useAccount, usePublicClient, useReadContract, useWalletClient } from "wagmi";
import { useTranslation } from "next-i18next";
import { Grid, type GridProps } from "@chakra-ui/react";
import { BigIntish, NoPublicClientError, isAddressEqual } from "@looksrare/utils";
import { useGameFallbackChainId, useToast } from "@looksrare/uikit";
import { add, isAfter, parseISO } from "date-fns";
import { PtbPlayer, usePtbConfig, usePtbContractInfo, useUpdateCache } from "../..";

export const Wrapper: FC<PropsWithChildren<GridProps>> = (props) => (
  <Grid
    gridGap={4}
    py={{ base: 4, lg: 0 }}
    px={4}
    gridTemplateColumns={{ base: "1fr", lg: "minmax(0, 1fr) auto" }}
    width="100%"
    bg={{ base: "ui-01", lg: "ui-bg" }}
    borderTopLeftRadius={{ base: "dialog", lg: 0 }}
    borderTopRightRadius={{ base: "dialog", lg: 0 }}
    borderBottomLeftRadius={{ base: 0, lg: "dialog" }}
    borderBottomRightRadius={{ base: 0, lg: "dialog" }}
    borderTop="1px solid"
    borderColor="border-01"
    height={{ base: "auto", lg: 20 }}
    alignItems="center"
    {...props}
  />
);

interface RefundActionArgs {
  caveOnChainId: BigIntish;
  roundOnChainId: BigIntish;
  openedAt: string | null;
  players: PtbPlayer[];
  playersPerRound: number;
}

/**
 * Eligible when:
 * 1. The connected wallet has entered the cave
 * 2. There are open spots
 * 3. The round is cancellable
 * 4. An arbitrary time has passed e.g. "refundCutoffSec"
 */
export const useIsEligibleForRefund = ({
  caveOnChainId,
  roundOnChainId,
  openedAt,
  players,
  playersPerRound,
}: RefundActionArgs) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const { address, chain } = useAccount();
  const { refundCutoffSec } = usePtbConfig();
  const ptbAddresses = usePtbContractInfo();
  const fallbackChainId = useGameFallbackChainId();
  const publicClient = usePublicClient();
  const updateCache = useUpdateCache();
  const { data: walletClient } = useWalletClient();
  const { toast } = useToast();

  const ptbAddressInfo = ptbAddresses[chain?.id || fallbackChainId].ptb;

  const caveId = BigInt(caveOnChainId);
  const roundId = BigInt(roundOnChainId);
  const openedAtDate = !!openedAt ? parseISO(openedAt) : new Date();
  const refundCutoff = add(openedAtDate, { seconds: refundCutoffSec });
  const connectedPlayer = players.find((player) => isAddressEqual(player.user.address, address));

  const cancellableResult = useReadContract({
    address: ptbAddressInfo.address,
    abi: ptbAddressInfo.abi,
    functionName: "cancellable",
    args: [caveId, roundId],
  });

  const handleRefund = async () => {
    setIsLoading(true);

    if (!walletClient) {
      throw Error("No wallet client found");
    }
    if (!publicClient) {
      throw new NoPublicClientError();
    }
    if (!connectedPlayer) {
      throw Error("You are not participating in this cave");
    }

    try {
      const [account] = await walletClient.getAddresses();
      const { request } = await publicClient.simulateContract({
        ...ptbAddressInfo,
        functionName: "refund",
        args: [
          [
            {
              caveId,
              playerDetails: [{ roundId, playerIndex: BigInt(connectedPlayer.entryIndex) - 1n }],
            },
          ],
        ],
        account,
      });
      const hash = await walletClient.writeContract(request);
      const receipt = await publicClient.waitForTransactionReceipt({ hash });

      if (receipt.status === "success") {
        updateCache.playerRemoved(account);
        toast({
          status: "success",
          title: t("ptb::Refund Successful"),
          description: t("Your funds are now in your wallet"),
        });
      }
    } catch (err) {
      toast({
        status: "error",
        title: t("Error"),
        description: t("ptb::Unable to process refund, please try again"),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isPlayerEligibleForRefund:
      !!connectedPlayer &&
      players.length < playersPerRound &&
      !!cancellableResult.data &&
      isAfter(new Date(), refundCutoff),
    isLoading,
    handleRefund,
  };
};
