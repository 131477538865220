import type { UseQueryOptions } from "@tanstack/react-query";

export type StateSetter<T> = (newValue: T) => void;
export type SupportedNetwork = "ethereum" | "arbitrum" | "blast";

/**
 * @see https://github.com/TanStack/query/discussions/6407
 */
export type RQueryOptions<TReturn, TError = any> = Omit<
  UseQueryOptions<TReturn, TError, TReturn>,
  "queryKey" | "queryFn"
>;

/** Extracts react-query options from a fetcher function */
export type RQueryOptionsForFetcher<Typeof extends (...args: any) => Promise<any>> = RQueryOptions<
  Awaited<ReturnType<Typeof>>
>;

export * from "./auth";
export * from "./bigint";
export * from "./currency";
export * from "./graphql";
