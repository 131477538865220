import { Hash, zeroAddress } from "viem";
import { Flex, ModalBody, ModalFooter, ModalHeader, Stack } from "@chakra-ui/react";
import {
  ModalCloseButton,
  Text,
  TransactionStatus,
  TransactionStep,
  TransactionStepRow,
  useGameAddresses,
} from "@looksrare/uikit";
import { useUnclaimedPtbFunds } from "../../../hooks";
import { PtbSupportedNetwork } from "../../../types";
import { TokenRow } from "./TokenRow";

interface TransactionViewProps {
  onClose: () => void;
  claimWinningsStatus: TransactionStatus;
  claimRefundStatus: TransactionStatus;
  claimWinningsHash?: Hash;
  claimRefundHash?: Hash;
  hasWinnings: boolean;
  hasRefund: boolean;
  network: PtbSupportedNetwork;
}

/**
 * If there are winnings we claim those first, then we claim refunds, then `onComplete` moves to the next view in the Modal
 */
export const TransactionView = ({
  onClose,
  claimWinningsStatus,
  claimRefundStatus,
  claimWinningsHash,
  claimRefundHash,
  hasWinnings,
  hasRefund,
  network,
}: TransactionViewProps) => {
  const { looksAmount, ethAmount } = useUnclaimedPtbFunds(network);
  const ptbAddresses = useGameAddresses();

  const totalTransactionsCount = hasWinnings && hasRefund ? 2 : 1;
  const currentTransactionNumber = (() => {
    let count = 1;
    if (hasWinnings && claimWinningsStatus === "done") {
      count++;
    }
    if (hasRefund && claimRefundStatus === "done") {
      count++;
    }
    return Math.min(count, totalTransactionsCount);
  })();

  return (
    <>
      <ModalHeader bg="ui-bg">
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <Text textStyle="heading-04" bold>
            Claim Funds
          </Text>
          <ModalCloseButton onClick={onClose} />
        </Flex>
      </ModalHeader>
      <ModalBody bg="ui-bg">
        <Stack spacing={4}>
          {!!looksAmount && <TokenRow currency={ptbAddresses.LOOKS} amountWei={looksAmount} network={network} />}
          {!!ethAmount && <TokenRow currency={zeroAddress} amountWei={ethAmount} network={network} />}
        </Stack>
      </ModalBody>
      <ModalFooter>
        <TransactionStep status="current" title="Claim Funds" width="100%">
          <Stack spacing={4}>
            <Text textStyle="detail" bold>
              {`Waiting for you to confirm in wallet (${currentTransactionNumber}/${totalTransactionsCount})`}
            </Text>
            {hasWinnings && (
              <TransactionStepRow
                text="Claim Winnings"
                status={claimWinningsStatus}
                transactionHash={claimWinningsHash}
              />
            )}
            {hasRefund && (
              <TransactionStepRow text="Claim Refund" status={claimRefundStatus} transactionHash={claimRefundHash} />
            )}
          </Stack>
        </TransactionStep>
      </ModalFooter>
    </>
  );
};
