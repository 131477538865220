import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const BLooksToken = (props: IconProps) => {
  const [id] = useState(uniqueId());
  return (
    <Icon viewBox="0 0 96 96" {...props}>
      <g clipPath={`url(#clip0_${id})`}>
        <mask
          id={`mask0_${id}`}
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="96"
          height="96"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M95.541 54.665C95.8436 52.4867 96 50.2616 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96C50.2616 96 52.4867 95.8436 54.665 95.541C52.9983 93.4804 52 90.8568 52 88V64C52 57.3726 57.3726 52 64 52H88C90.8568 52 93.4804 52.9983 95.541 54.665Z"
            fill="#D9D9D9"
          />
        </mask>
        <g mask={`url(#mask0_${id})`}>
          <rect width="96" height="96" rx="48" fill="#04CD58" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48 53.5135C42.1661 53.5135 37.4324 48.7846 37.4324 42.9459C37.4324 37.1073 42.1661 32.3784 48 32.3784C53.8339 32.3784 58.5676 37.1073 58.5676 42.946C58.5676 48.7846 53.8339 53.5135 48 53.5135ZM43.4054 42.946C43.4054 45.4845 45.4635 47.5405 48 47.5405C50.5365 47.5405 52.5946 45.4845 52.5946 42.946C52.5946 40.4074 50.5365 38.3514 48 38.3514C45.4635 38.3514 43.4054 40.4074 43.4054 42.946Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 42.9599L34.2162 22.7297H61.7838L82 42.9599L48 76.946L14 42.9599ZM63.6216 35.5945C55.0319 26.9671 40.9681 26.9671 32.3784 35.5945L25.027 42.946L32.3784 50.2974C40.9681 58.9248 55.0319 58.9248 63.6216 50.2973L70.973 42.946L63.6216 35.5945Z"
            fill="black"
          />
        </g>
        <g clipPath={`url(#clip1_${id})`}>
          <path
            opacity="0.3"
            d="M56 66C56 60.4772 60.4772 56 66 56H86C91.5228 56 96 60.4772 96 66V86C96 91.5228 91.5228 96 86 96H66C60.4772 96 56 91.5228 56 86V66Z"
            fill="#D3CA00"
          />
          <path
            d="M65.1989 65.5833L59.9368 69.75L87.0201 69.9176L85.7126 74.3621L74.6925 74.1945L73.7844 77.8056H84.5919L83.0977 83.1408H67.7816L70.7701 73.4282L67.4145 70.5833L61.8754 87.25H81.816L87.2069 84.8218L88.8879 78.8448L85.7126 76.1389L90.6784 73.6389L92.0632 68.0833L89.0167 65.5833H65.1989Z"
            fill="#D3CA00"
          />
        </g>
      </g>
      <defs>
        <clipPath id={`clip0_${id}`}>
          <rect width="96" height="96" fill="white" />
        </clipPath>
        <clipPath id={`clip1_${id}`}>
          <rect width="40" height="40" fill="white" transform="translate(56 56)" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default BLooksToken;
