import { maxUint96, maxUint256 } from "viem";

export const BLAST_AVG_BLOCK_TIME_MS = 2_000; // 2 seconds
export const ETH_AVG_BLOCK_TIME_MS = 12_150; // 12.15 seconds
export const WEI_PER_ETHER = BigInt("1000000000000000000");
export const SAFE_MAX_UINT_256 = maxUint256 - BigInt("1");
export const SOCKET_V2_NATIVE_TOKEN_ADDRESS = "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
// ERC20 Voting modules often require the max amount to be within 96 bits.
export const DEFAULT_ERC20_APPROVAL_AMOUNT = maxUint96;
/**
 * Env variables shared by LR and YG
 */
export const IS_TESTNET = Number(process.env.NEXT_PUBLIC_IS_TESTNET) === 1;
export const STORAGE_NAMESPACE = process.env.NEXT_PUBLIC_STORAGE_NAMESPACE;
export const WALLET_CONNECT_PROJECT_ID = process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID;
export const ALCHEMY_KEY = process.env.NEXT_PUBLIC_ALCHEMY_KEY;
export const ALCHEMY_KEY_TESTNET = process.env.NEXT_PUBLIC_ALCHEMY_KEY_TESTNET;
export const QUICKNODE_KEY = process.env.NEXT_PUBLIC_QUICKNODE_KEY;

const GRAPHQL_ENDPOINT = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT;
const GRAPHQL_ENDPOINT_TESTNET = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT_TESTNET;
export const GRAPHQL_API_ENDPOINT = IS_TESTNET ? GRAPHQL_ENDPOINT_TESTNET : GRAPHQL_ENDPOINT;

const WEBSOCKET_ENDPOINT = process.env.NEXT_PUBLIC_WEBSOCKET_ENDPOINT;
const WEBSOCKET_ENDPOINT_TESTNET = process.env.NEXT_PUBLIC_WEBSOCKET_ENDPOINT_TESTNET;
export const WS_ENDPOINT = IS_TESTNET ? WEBSOCKET_ENDPOINT_TESTNET : WEBSOCKET_ENDPOINT;

// Local storage key for PTB on the FE
export const LS_PTB_MUTED_KEY = `${STORAGE_NAMESPACE}_ptb_muted`;

export const INDEXER_REFETCH_DELAY_MS = 4_000;
export const CLASSIC_GAMES_GAS_BUFFER = 1.25;
