import { PositionObject, Tooltip, TooltipPositionerPointObject } from "highcharts";

const MIN_LABEL_WIDTH = 139;

export function tooltipPositioner(
  this: Tooltip,
  labelWidth: number,
  labelHeight: number,
  point: TooltipPositionerPointObject,
  minTooltipHeight = 0
): PositionObject {
  // @NOTE have not figured out why labelWidth is 0 the first time it's called
  const labelWidthWithMin = labelWidth || MIN_LABEL_WIDTH;
  const containerRect = this.chart.container.getBoundingClientRect();
  // The horizontal point at which the tooltip will be out of bounds
  const containerXMax = containerRect.left + containerRect.width;
  const potentialXPosition = this.chart.pointer.getChartPosition().left + this.chart.plotLeft + point.plotX;
  const cursorArea = 30;
  // Determine if the potential x-position + label width + cursor area is out of bounds
  const isTooltipXClipped = potentialXPosition + labelWidthWithMin + cursorArea > containerXMax;
  // Tooltip is aligned to left side if it's clipped
  const x = isTooltipXClipped ? potentialXPosition - labelWidthWithMin - cursorArea : potentialXPosition;
  const y = point.plotY - this.chart.plotTop + this.chart.pointer.getChartPosition().top - 30;

  // Prevent window overflow
  const maxX = window.innerWidth;
  const tooltipRectOffset = Math.max(this.container?.offsetHeight || 0, minTooltipHeight);
  const maxY = containerRect.y + containerRect.height + window.scrollY - tooltipRectOffset;

  const clampedX = Math.max(0, Math.min(x, maxX));
  const clampedY = Math.min(maxY, y);
  return { x: clampedX, y: clampedY };
}
