export interface CloudinaryLoaderProps {
  src: string;
  baseCloudinaryUrl: string;
  quality?: Quality;
  format?: Format;
  resourceType?: ResourceType;
  contentType?: string | null;
  width?: number | "iw";
}

export type Quality = number | "auto" | "auto:best" | "auto:good" | "auto:eco" | "auto:low";
export type Crop = "limit" | "fit";
export type ResourceType = "image" | "video";
export type Format =
  | "auto"
  | "avif" // image
  | "webp" // image
  | "svg" // image
  | "png" // image
  | "webm"; // video

export interface CloudinaryLoaderProps {
  src: string;
  baseCloudinaryUrl: string;
  quality?: Quality;
  format?: Format;
  resourceType?: ResourceType;
  contentType?: string | null;
  width?: number | "iw";
}

const normalizeSrc = (src: string): string => {
  return src[0] === "/" ? src.slice(1) : src;
};

/**
 * Get f= (format) transformation based on format & isSvg
 *
 * @param format
 * @param contentType mime type
 * @returns Format
 */
const getFormatTransformation = (format?: Format, isSvg?: boolean): Format => {
  if (format) {
    return format;
  }

  if (isSvg) {
    return "svg";
  }

  return "auto";
};

/**
 * Get q= (quality) transformation based on quality & isSvg
 *
 * @param quality
 * @param contentType mime type
 * @returns Quality
 */
const getQualityTransformation = (quality?: Quality, isSvg?: boolean): Quality => {
  // if asset isSvg, use 'auto:best' regardless of quality passed
  if (isSvg) {
    return "auto:best";
  }

  return quality || "auto";
};

/**
 *
 * Function to construct Cloudinary Media Optimizer mapping function url.
 *
 * This should only be used for image and video assets hosted on our CDN `static.looksnice.org` / `static-sepolia.looksnice.org`.
 *
 * The constructed url can be passed directly as an image/video src value.
 * Note that this string is parsed by the Cloudinary mapping function before the request is sent to the Cloudinary server.
 *
 * @param src asset uid / path, without cdn domain
 * @param baseCloudinaryUrl base Cloudinary delivery domain
 * @param width Width
 * @param quality Quality
 * @param format Format
 * @param contentType string
 * @param resourceType ResourceType
 * @returns cloudinary asset url
 */
export const getCloudinaryUrl = ({
  src,
  baseCloudinaryUrl,
  width,
  quality,
  format,
  contentType,
  resourceType,
}: CloudinaryLoaderProps): string => {
  const isSvg = !!(contentType && contentType.includes("svg"));
  const crop = isSvg ? "fit" : "limit";
  const formatTransformation = getFormatTransformation(format, isSvg);
  const qualityTransformation = getQualityTransformation(quality, isSvg);

  const transformations = [
    `f=${formatTransformation}`,
    `c=${crop}`,
    `w=${width || "iw"}`,
    `q=${qualityTransformation}`,
  ];
  const transformationsString = transformations.join("&");

  const cloudinaryUrl = `${baseCloudinaryUrl}/${normalizeSrc(src)}?resource_type=${
    resourceType || "image"
  }&${transformationsString}`;

  return cloudinaryUrl;
};
