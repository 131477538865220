import { Children, ReactElement, cloneElement, useRef } from "react";
import { BoxProps, HStack, StackProps } from "@chakra-ui/react";
import { useResizeObserver, useSlidingSelector } from "@looksrare/utils";

interface AnimatedSwitcherProps extends Omit<StackProps, "children" | "onChange"> {
  children: ReactElement[];
  selectedIndex: number;
  selectorProps?: BoxProps;
}

/**
 * This component allows a selector element to slide-animate between children elements to indicate the current selection.
 * Both horizontal and vertical sliding is supported.
 * @param children Requires an array of ReactElements, ensure these elements accept a ref using forwardRef
 * when using custom components.
 */
export const AnimatedSwitcher = ({ children, selectedIndex, selectorProps, ...props }: AnimatedSwitcherProps) => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const childRefs = useRef(new Map<number, HTMLDivElement>());

  const {
    offset: optionOffset,
    width: optionWidth,
    height: optionHeight,
    updatePosition,
  } = useSlidingSelector(childRefs, selectedIndex);
  useResizeObserver(updatePosition, parentRef.current);

  return (
    <HStack
      ref={parentRef}
      spacing={0}
      position="relative"
      border="1px solid"
      borderColor="border-01"
      borderRadius="mini"
      overflow="hidden"
      _before={{
        content: '""',
        position: "absolute",
        left: `${optionOffset.x}px`,
        width: `${optionWidth}px`,
        height: `${optionHeight}px`,
        backgroundColor: "interactive-02",
        zIndex: 0,
        transition: "left 300ms, width 300ms, height 300ms",
        ...selectorProps,
      }}
      {...props}
    >
      {Children.map(children, (child, index) => {
        return cloneElement(child as ReactElement, {
          ref: (node: HTMLDivElement | null): void => {
            if (node) {
              childRefs.current.set(index, node);
            } else {
              childRefs.current.delete(index);
            }
          },
        });
      })}
    </HStack>
  );
};
