import { useCallback, useEffect, useRef, useState } from "react";
import { useAccount, usePublicClient } from "wagmi";
import { type Hash } from "viem";
import { useTranslation } from "next-i18next";
import { ModalProps, TransactionStatus, useToast } from "@looksrare/uikit";
import { useGetFormattedErrorAndTitle, useSubmitTransaction } from "@looksrare/utils";
import { useInvalidateUnclaimedPtbFunds, useUnclaimedPtbFunds } from "../../../hooks";
import { PtbSupportedNetwork } from "../../../types";
import { ClaimFundsSuccessHandler } from "../types";
import { TransactionView } from "./TransactionView";

interface ClaimFundsProps {
  network: PtbSupportedNetwork;
  onClose: ModalProps["onClose"];
  onSuccess?: ClaimFundsSuccessHandler;
}

export const ClaimFunds = ({ network, onClose, onSuccess }: ClaimFundsProps) => {
  const { address } = useAccount();
  const { t } = useTranslation();
  const { unclaimedRefunds, unclaimedWinnings, claimRefund, claimWinnings } = useUnclaimedPtbFunds(network);
  const invalidateUnclaimedFunds = useInvalidateUnclaimedPtbFunds();

  const { toast } = useToast();
  const publicClient = usePublicClient();

  const getFormattedErrorAndTitle = useGetFormattedErrorAndTitle();
  const onError = (error: any) => {
    const { title, description } = getFormattedErrorAndTitle(error);
    toast({ title, description, status: "error", dataIdSuffix: "cancel-v2-orders" });
  };
  const handleSuccess = () => {
    if (onSuccess) {
      onSuccess(network);
    }
    toast({ title: t("Claim Successful"), description: t("Your funds are now in your wallet"), status: "success" });
  };

  const [claimWinningsHash, setClaimWinningsHash] = useState<Hash>();
  const [claimRefundHash, setClaimRefundHash] = useState<Hash>();

  const [claimWinningsStatus, setClaimWinningsStatus] = useState<TransactionStatus>("wait");
  const [claimRefundStatus, setClaimRefundStatus] = useState<TransactionStatus>("wait");

  const claimRefundHandler = useSubmitTransaction({
    onSend: async () => {
      if (!publicClient) {
        throw new Error("No public client found");
      }
      setClaimRefundStatus("pending");
      const hash = await claimRefund();
      setClaimRefundHash(hash);
      await publicClient.waitForTransactionReceipt({ hash });
      return hash;
    },
    onSuccess: () => {
      setClaimRefundStatus("done");
      handleSuccess();
      invalidateUnclaimedFunds({ address, network });
      onClose();
    },
    onError: (error) => {
      onError(error);
      setClaimRefundStatus("error");
    },
  });

  const claimWinningsHandler = useSubmitTransaction({
    onSend: async () => {
      if (!publicClient) {
        throw new Error("No public client found");
      }
      setClaimWinningsStatus("pending");
      const hash = await claimWinnings();
      setClaimWinningsHash(hash);
      await publicClient.waitForTransactionReceipt({ hash });
      return hash;
    },
    onSuccess: () => {
      setClaimWinningsStatus("done");

      if (unclaimedRefunds?.length) {
        return claimRefundHandler.submitTransaction();
      } else {
        handleSuccess();
        onClose();
        invalidateUnclaimedFunds({ address, network });
      }
    },
    onError: (error) => {
      onError(error);
      setClaimWinningsStatus("error");
    },
  });

  /**
   * Call init just when the view updates to TransactionView
   */
  const hasInitialized = useRef<boolean>(false);

  const init = useCallback(() => {
    if (unclaimedWinnings?.length) {
      claimWinningsHandler.submitTransaction();
    } else if (unclaimedRefunds?.length) {
      claimRefundHandler.submitTransaction();
    }
  }, [claimRefundHandler, claimWinningsHandler, unclaimedRefunds, unclaimedWinnings]);

  useEffect(() => {
    if (!hasInitialized.current) {
      hasInitialized.current = true;
      init();
    }
  }, [init, hasInitialized]);

  return (
    <TransactionView
      network={network}
      claimWinningsStatus={claimWinningsStatus}
      claimRefundStatus={claimRefundStatus}
      claimWinningsHash={claimWinningsHash}
      claimRefundHash={claimRefundHash}
      onClose={onClose}
      hasRefund={!!unclaimedRefunds?.length}
      hasWinnings={!!unclaimedWinnings?.length}
    />
  );
};
