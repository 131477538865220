import { Box, StackProps, HStack, VStack, BoxProps } from "@chakra-ui/react";
import { Text } from "../Text";
import { StepTimingStatusIcon } from "./StatusIcons";
import { type StepTimingStatus } from "./types";

export interface TransactionStepProps extends StackProps {
  title: string;
  status: StepTimingStatus;
  collapse?: boolean;
  description?: string;
  bodyWrapperProps?: BoxProps;
}

export const TransactionStep: React.FC<React.PropsWithChildren<TransactionStepProps>> = ({
  title,
  description,
  status,
  collapse = false,
  bodyWrapperProps,
  children,
  ...props
}) => {
  return (
    <VStack spacing={4} alignItems="stretch" {...props}>
      <HStack spacing={4}>
        <StepTimingStatusIcon status={status} />
        <Text bold color={status === "current" ? "text-02" : "text-disabled"}>
          {title}
        </Text>
      </HStack>
      {status === "current" && !!description && (
        <Text textStyle="helper" bold color="text-03">
          {description}
        </Text>
      )}
      {!collapse && (
        <Box p={4} bg="ui-bg" borderRadius="container" {...bodyWrapperProps}>
          {children}
        </Box>
      )}
    </VStack>
  );
};
