import { useCallback } from "react";
import { usePublicClient, useWalletClient } from "wagmi";
import { Address } from "viem";
import { Erc20Abi, SAFE_MAX_UINT_256 } from "@looksrare/config";
import { NoPublicClientError } from "../../..";

/**
 * Request the ERC20 allowance a spender can use on behalf of the owner
 */
export const useGetAllowance = (tokenAddress: Address) => {
  const publicClient = usePublicClient();
  return useCallback(
    async (owner: Address, spender: Address): Promise<bigint> => {
      if (!publicClient) {
        throw new NoPublicClientError();
      }
      const allowance = await publicClient.readContract({
        address: tokenAddress,
        abi: Erc20Abi,
        functionName: "allowance",
        args: [owner, spender],
      });
      return allowance;
    },
    [tokenAddress, publicClient]
  );
};

/**
 * Approve an amount of ECR20 to be spent by a spender on behalf of a user
 */
export const useApprove = (tokenAddress: Address) => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  return useCallback(
    async (spender: Address, amount: bigint = SAFE_MAX_UINT_256) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new NoPublicClientError();
      }
      const [account] = await walletClient.getAddresses();
      const { request } = await publicClient.simulateContract({
        address: tokenAddress,
        abi: Erc20Abi,
        functionName: "approve",
        args: [spender, amount],
        account,
      });
      return walletClient.writeContract(request);
    },
    [tokenAddress, publicClient, walletClient]
  );
};
