"use client";
import Script from "next/script";

const gaId: `G-${string}` = "G-YFXDDWLVHE";

/**
 * Injects Google Analytics script
 * @note consider using https://nextjs.org/docs/app/building-your-application/optimizing/third-party-libraries#google-analytics
 */
export const GoogleTagManager = () => {
  // if (IS_TESTNET) { //@TODO reinstate after testing
  //   return null;
  // }
  return (
    <>
      <Script async id="_next-gtm" src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`} />
      <Script
        id="_next-gtm-init"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gaId}');
        `,
        }}
      />
    </>
  );
};
