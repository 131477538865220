import { Icon, IconProps } from "../Icon";

const ArrowUpRightFilled = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.002 28H5.998C4.89454 28 4 27.1055 4 26.002V5.998C4 4.89454 4.89454 4 5.998 4H26.002C26.5319 4 27.0401 4.2105 27.4148 4.5852C27.7895 4.9599 28 5.4681 28 5.998V26.002C28 26.5319 27.7895 27.0401 27.4148 27.4148C27.0401 27.7895 26.5319 28 26.002 28ZM10 8V10H20.59L8 22.59L9.41 24L22 11.41V22H24V8H10Z"
      fill="currentColor"
    />
  </Icon>
);

export default ArrowUpRightFilled;
