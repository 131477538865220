import { Box, Divider, HStack, Stack } from "@chakra-ui/react";
import { ArrowDownRightIcon, ArrowUpRightIcon, EthHalfIcon, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { formatNumberToLocale, formatToSignificant } from "@looksrare/utils";
import { MoDRoundFragment } from "@looksrare/yolo-games-gql-typegen";
import { RoundStatsChart } from "./components/RoundStatsChart";

interface RoundStatsProps {
  moonPayoutRatio?: MoDRoundFragment["moonPayoutRatio"];
  doomPayoutRatio?: MoDRoundFragment["doomPayoutRatio"];
  moonAmount?: MoDRoundFragment["moonAmount"];
  doomAmount?: MoDRoundFragment["doomAmount"];
  hideTotalAmount?: boolean;
}

export const RoundStats = ({ moonPayoutRatio, doomPayoutRatio, moonAmount, doomAmount }: RoundStatsProps) => {
  const { t } = useTranslation();

  const moonPayoutRatioDisplay =
    moonPayoutRatio !== undefined && moonPayoutRatio !== null ? `${formatNumberToLocale(moonPayoutRatio, 0)}x` : "-x";
  const doomPayoutRatioDisplay =
    doomPayoutRatio !== undefined && doomPayoutRatio !== null ? `${formatNumberToLocale(doomPayoutRatio, 0)}x` : "-x";

  const moonAmountDisplay = moonAmount !== undefined && moonAmount !== null ? formatToSignificant(moonAmount, 2) : "-";
  const doomAmountDisplay = doomAmount !== undefined && doomAmount !== null ? formatToSignificant(doomAmount, 2) : "-";

  return (
    <Stack spacing={2}>
      <HStack spacing={2}>
        <Box width="64px" height="64px">
          <RoundStatsChart moonPayoutRatio={moonPayoutRatio} doomPayoutRatio={doomPayoutRatio} />
        </Box>

        <Stack spacing={0} flex={1}>
          <HStack spacing={2} justifyContent="space-between">
            <HStack spacing={2}>
              <ArrowUpRightIcon boxSize={5} color="moondoom-moon" />

              <Text textStyle="display-body" color="moondoom-moon" bold>
                {moonPayoutRatioDisplay}
              </Text>

              <Text textStyle="caption" color="text-03">
                {t("Payout")}
              </Text>
            </HStack>

            <HStack spacing={2}>
              <Text textStyle="detail" color="text-02" bold>
                {moonAmountDisplay}
              </Text>

              <EthHalfIcon height={4} width={2} />
            </HStack>
          </HStack>

          <Divider />

          <HStack spacing={2} justifyContent="space-between">
            <HStack spacing={2}>
              <ArrowDownRightIcon boxSize={5} color="moondoom-doom" />

              <Text textStyle="display-body" color="moondoom-doom" bold>
                {doomPayoutRatioDisplay}
              </Text>

              <Text textStyle="caption" color="text-03">
                {t("Payout")}
              </Text>
            </HStack>

            <HStack spacing={2}>
              <Text textStyle="detail" color="text-02" bold>
                {doomAmountDisplay}
              </Text>

              <EthHalfIcon height={4} width={2} />
            </HStack>
          </HStack>
        </Stack>
      </HStack>
    </Stack>
  );
};
