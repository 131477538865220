import { Icon, IconProps } from "../Icon";

const DiamondData = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9999 25.473H15.0009L13.4879 26.782L0.715942 12.045L7.49994 3H22.4999L29.2839 12.045L24.9889 17.002L23.4759 15.692L26.7159 11.955L21.4999 5H8.49994L3.28394 11.955L14.9999 25.473ZM26 28H18V30H26V28ZM18 24H30V26H18V24ZM30 20H18V22H30V20Z"
      fill="currentColor"
    />
  </Icon>
);

export default DiamondData;
