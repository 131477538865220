import { IconButton } from "@chakra-ui/react";
import { Button, type ButtonProps, NextLink, RecentlyViewedIcon } from "@looksrare/uikit";
import type { BigIntish, SupportedNetwork } from "@looksrare/utils";
import { BASE_URL } from "../../config";

interface HistoryButtonProps extends ButtonProps {
  caveOnChainId?: BigIntish;
  network: SupportedNetwork;
}

export const HistoryButton = ({ caveOnChainId, network, ...props }: HistoryButtonProps) => {
  const href = `${BASE_URL}/history/${network}/${caveOnChainId ? `?fromCave=${caveOnChainId}` : ""}`;
  const sharedProps = {
    size: "xs",
    colorScheme: "secondary",
    href,
    as: NextLink,
  };
  return (
    <>
      {/* Hide on xl+ */}
      <IconButton display={{ base: "flex", xl: "none" }} aria-label="poke-the-bear-history" {...sharedProps} {...props}>
        <RecentlyViewedIcon />
      </IconButton>
      {/* Hide on mobile */}
      <Button display={{ base: "none", xl: "flex" }} leftIcon={<RecentlyViewedIcon />} {...sharedProps} {...props}>
        History
      </Button>
    </>
  );
};
