import { forwardRef, Tag as ChakraTag, TagProps as ChakraTagProps } from "@chakra-ui/react";
import memoize from "lodash/memoize";

export interface TagProps extends ChakraTagProps {
  isClickable?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

// handle this here instead of on the Theme config to avoid passing `isClickable` to the DOM element
const getIsClickableProps = memoize((isClickable, variant = "solid") => {
  if (!isClickable) {
    return {
      cursor: "default",
    };
  }

  switch (variant) {
    // clickable tags behave like secondary button
    case "solid":
      return {
        cursor: "pointer",
        sx: {
          _hover: {
            bg: "hover-interactive-02",
          },
          _active: {
            bg: "onclick-interactive-02",
          },
        },
      };
    case "outline":
      return {
        cursor: "pointer",
        sx: {
          _hover: {
            bg: "hover-ui",
          },
          _active: {
            bg: "onclick-ui",
          },
        },
      };
    default:
      return {
        cursor: "pointer",
      };
  }
});

export const Tag = forwardRef<TagProps, "span">(({ children, isClickable, ...props }, ref) => {
  const isClickableProps = getIsClickableProps(isClickable, props.variant);
  return (
    <ChakraTag ref={ref} {...isClickableProps} {...props}>
      {children}
    </ChakraTag>
  );
});
