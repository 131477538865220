import { useCallback } from "react";
import { usePublicClient, useWalletClient } from "wagmi";
import { WethAbi } from "@looksrare/config";
import { NoPublicClientError, useAddressesByNetwork } from "@looksrare/utils";

/**
 * Deposit ETH to wrap to WETH
 */
export const useDepositEth = () => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const addressesByNetwork = useAddressesByNetwork();

  return useCallback(
    async (amount: bigint) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new NoPublicClientError();
      }
      const [account] = await walletClient.getAddresses();
      const { request } = await publicClient.simulateContract({
        address: addressesByNetwork.WETH,
        abi: WethAbi,
        functionName: "deposit",
        value: amount,
        account,
      });
      return walletClient.writeContract(request);
    },
    [walletClient, publicClient, addressesByNetwork]
  );
};

/**
 * Withdraw WETH to unwrap to ETH
 */
export const useWithdrawWeth = () => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const addressesByNetwork = useAddressesByNetwork();

  return useCallback(
    async (amount: bigint) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new NoPublicClientError();
      }
      const [account] = await walletClient.getAddresses();
      const { request } = await publicClient.simulateContract({
        address: addressesByNetwork.WETH,
        abi: WethAbi,
        functionName: "withdraw",
        args: [amount],
        account,
      });
      return walletClient.writeContract(request);
    },
    [walletClient, publicClient, addressesByNetwork]
  );
};
