import capitalize from "lodash/capitalize";
import { useTranslation } from "next-i18next";
import times from "lodash/times";
import { ChainIcon, ListIcon, useGameSupportedNetworks } from "@looksrare/uikit";
import { getNetworkFromPtbContractName, getPtbContractNameFromNetwork, type PtbSupportedNetwork } from "..";
import { useCaves, useGetCaveQueryParams } from "../hooks";
import { PtbAccordionButton, PtbAccordionItem, PtbAccordionPanel } from "./ClaimPanel/shared";
import { CaveListItem, CaveListItemSkeleton } from "./CaveListItem";

interface CaveListAccordionProps {
  network: PtbSupportedNetwork;
}

export const CaveListAccordion = ({ network }: CaveListAccordionProps) => {
  const { t } = useTranslation();
  const cavesQuery = useCaves(getPtbContractNameFromNetwork(network));
  const { caveOnChainId, network: urlNetwork } = useGetCaveQueryParams();
  const supportedNetworks = useGameSupportedNetworks();

  const caveListIcon =
    supportedNetworks.length === 1 ? (
      <ListIcon boxSize={6} color="text-03" />
    ) : (
      <ChainIcon network={network} boxSize={6} />
    );

  return (
    <PtbAccordionItem>
      <PtbAccordionButton icon={caveListIcon}>
        {supportedNetworks.length === 1 ? t("All Caves") : capitalize(network)}
      </PtbAccordionButton>
      <PtbAccordionPanel>
        {(() => {
          if (cavesQuery.isLoading) {
            return times(5).map((n) => <CaveListItemSkeleton key={n} />);
          }
          if (cavesQuery.isSuccess) {
            return cavesQuery.data.map((cave) => (
              <CaveListItem
                key={cave.id}
                isActive={
                  caveOnChainId === cave.onChainId && urlNetwork === getNetworkFromPtbContractName(cave.contract)
                }
                caveOnChainId={cave.onChainId}
                roundOnChainId={cave.round?.onChainId}
                name={cave.name}
                currency={cave.currency}
                network={network}
                enterAmount={cave.enterAmount}
                players={cave.round?.players}
                playersPerRound={cave.playersPerRound}
              />
            ));
          }
          return null;
        })()}
      </PtbAccordionPanel>
    </PtbAccordionItem>
  );
};
