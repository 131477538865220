import { useTranslation } from "next-i18next";
import { SupportedCurrency } from "../AddFundsModal";
import { ModalLegacy, ModalLegacyProps } from "../../Modal/ModalLegacy";
import { UniswapWidgetView } from "./UniswapWidgetView";

interface UniswapWidgetModalProps {
  isOpen: ModalLegacyProps["isOpen"];
  onClose: ModalLegacyProps["onClose"];
  defaultBuyCurrency?: SupportedCurrency;
  defaultBuyAmount?: number; // @NOTE uses decimals not WEI
}

export const UniswapWidgetModal = ({
  isOpen,
  onClose,
  defaultBuyCurrency,
  defaultBuyAmount,
}: UniswapWidgetModalProps) => {
  const { t } = useTranslation();
  return (
    <ModalLegacy
      title={t("Buy {{token}}", { token: defaultBuyCurrency || "LOOKS" })}
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="none"
      closeOnOverlayClick={false}
    >
      <UniswapWidgetView defaultBuyCurrency={defaultBuyCurrency} defaultBuyAmount={defaultBuyAmount} />
    </ModalLegacy>
  );
};
