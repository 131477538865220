import { HStack, VStack } from "@chakra-ui/react";
import { ArrowDownRightIcon, ArrowUpRightIcon, ClockIcon, EthHalfIcon, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { BigIntish, formatToSignificant } from "@looksrare/utils";
import { EntryPosition } from "../../../../types";

interface RoundEntryEnteredBodyProps {
  entryPosition: EntryPosition;
  entryAmount: BigIntish;
}

export const RoundEntryEnteredBody = ({ entryPosition, entryAmount }: RoundEntryEnteredBodyProps) => {
  const { t } = useTranslation();

  return (
    <VStack spacing={4} pt={12} px={4} pb={16}>
      <ClockIcon boxSize={16} color={`moondoom-${entryPosition}`} />

      <VStack>
        <Text textStyle="heading-03" textAlign="center" color={`moondoom-${entryPosition}`} bold>
          {t("Entered Next Round")}
        </Text>

        <HStack spacing={1} justifyContent="center">
          {entryPosition === "moon" ? (
            <ArrowUpRightIcon boxSize={5} color="moondoom-moon" />
          ) : (
            <ArrowDownRightIcon boxSize={5} color="moondoom-doom" />
          )}

          <Text textStyle="display-body" color="text-01" bold>
            {formatToSignificant(entryAmount)}
          </Text>

          <EthHalfIcon height={4} width={2} />
        </HStack>
      </VStack>

      <Text textStyle="body" textAlign="center" color="text-02">
        {t("mod::Your entry isn’t live yet! The next round starts once current round ends.")}
      </Text>
    </VStack>
  );
};
