import { useTranslation } from "next-i18next";
import { useDisclosure } from "@chakra-ui/react";
import { Button, ButtonProps } from "@looksrare/uikit";
import { ReferralModal } from "@/components/Modals";

export const InviteButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const referralModalDisclosure = useDisclosure();

  return (
    <>
      <ReferralModal isOpen={referralModalDisclosure.isOpen} onClose={referralModalDisclosure.onClose} />
      <Button size="sm" variant="outline" colorScheme="secondary" onClick={referralModalDisclosure.onOpen} {...props}>
        {t("Invite")}
      </Button>
    </>
  );
};
