import { ChangeEvent, ReactNode } from "react";
import {
  InputGroup,
  InputGroupProps,
  InputRightElement as ChakraInputRightElement,
  InputLeftElement as ChakraInputLeftElement,
} from "@chakra-ui/input";
import { IconButton } from "@chakra-ui/button";
import { InputTheme } from "@looksrare/chakra-theme";
import { SearchIcon, CloseIcon } from "../Icons";
import { Input, InputProps } from "./Input";

export interface TextInputProps extends Omit<InputProps, "size"> {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  placeholder?: string;
  size?: "xs" | "sm" | "md";
  wrapperProps?: InputGroupProps;
  InputLeftElement?: ReactNode;
  InputRightElement?: ReactNode;
}

export const TextInput: React.FC<React.PropsWithChildren<TextInputProps>> = ({
  placeholder = "",
  value,
  onChange,
  onClear,
  isActive,
  size = "sm",
  wrapperProps,
  InputLeftElement = (
    <ChakraInputLeftElement pointerEvents="none">
      <SearchIcon color={isActive ? "text-01" : "text-03"} transition="color 0.2s ease-out" />
    </ChakraInputLeftElement>
  ),
  InputRightElement = (
    <>
      {onClear && value && (
        <ChakraInputRightElement>
          <IconButton size="xs" onClick={onClear} variant="ghost" colorScheme="gray" aria-label="clear search">
            <CloseIcon color="text-01" />
          </IconButton>
        </ChakraInputRightElement>
      )}
    </>
  ),
  ...props
}) => {
  const fieldHeight = InputTheme.sizes[size].field.height;

  return (
    <InputGroup size={size} width={{ base: "100%", md: "280px" }} {...wrapperProps}>
      {InputLeftElement}
      <Input
        pl={InputLeftElement ? fieldHeight : 4} // Use padding equal to fieldHeight to create square space for Input left/right elements
        pr={InputRightElement ? fieldHeight : 4}
        placeholder={placeholder}
        value={value}
        isActive={isActive}
        onChange={onChange}
        size={size}
        sx={{
          "::-webkit-search-cancel-button": {
            display: "none",
          },
        }}
        {...props}
      />
      {InputRightElement}
    </InputGroup>
  );
};
