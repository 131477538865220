import { type FC, type PropsWithChildren } from "react";
import { type Address } from "viem";
import { HStack, type StackProps } from "@chakra-ui/react";
import { IconProps, Text, useGameFallbackChainId, type TextProps } from "@looksrare/uikit";
import { getSupportedNetworkFromChainId } from "@looksrare/utils";
import type { PtbSupportedNetwork } from "../types";
import { CurrencyTokenIcon } from "./CurrencyTokenIcon";

interface CurrencyValueDisplayProps extends StackProps {
  total: string;
  currency: Address;
  network?: PtbSupportedNetwork;
  prefix?: string;
  textProps?: TextProps;
  iconProps?: IconProps;
}

// @todo-ptb this component should REALLY go into a shared space
export const CurrencyValueDisplay: FC<PropsWithChildren<CurrencyValueDisplayProps>> = ({
  total,
  currency,
  network,
  prefix,
  textProps,
  iconProps,
  ...props
}) => {
  const fallbackChainId = useGameFallbackChainId();
  return (
    <HStack spacing={1} {...props}>
      {prefix && (
        <Text as="span" bold color="currentcolor">
          {prefix}
        </Text>
      )}
      <CurrencyTokenIcon
        network={network || getSupportedNetworkFromChainId(fallbackChainId)}
        currency={currency}
        color="currentcolor"
        boxSize={4}
        {...iconProps}
      />
      <Text as="span" bold color="currentcolor" {...textProps}>
        {total}
      </Text>
    </HStack>
  );
};
