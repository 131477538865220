import { Icon, IconProps } from "../Icon";

const Loader = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.60284 20.064C1.75369 17.2647 1.8027 14.2698 2.74298 11.4998C3.68325 8.72988 5.46757 6.32401 7.84531 4.62014C10.2231 2.91627 13.0748 2 16 2L16 4C13.4927 4 11.0483 4.78538 9.01027 6.24584C6.9722 7.70629 5.44279 9.76846 4.63684 12.1427C3.83089 14.517 3.78888 17.0841 4.51672 19.4834C5.24455 21.8828 6.70567 23.9939 8.69486 25.5202C10.6841 27.0466 13.1014 27.9115 15.6074 27.9936C18.1133 28.0756 20.5821 27.3706 22.6668 25.9776C24.7516 24.5846 26.3477 22.5736 27.2309 20.227C28.1141 17.8804 28.2401 15.3161 27.5911 12.8942L29.523 12.3765C30.2801 15.2021 30.1331 18.1938 29.1027 20.9315C28.0723 23.6692 26.2102 26.0154 23.778 27.6406C21.3458 29.2657 18.4656 30.0882 15.5419 29.9925C12.6183 29.8968 9.79806 28.8877 7.47734 27.1069C5.15662 25.3262 3.45198 22.8632 2.60284 20.064Z"
      fill="currentColor"
    />
  </Icon>
);

export default Loader;
