import { defineStyleConfig } from "@chakra-ui/react";

export const AccordionTheme = defineStyleConfig({
  baseStyle: {
    root: {
      borderRadius: 0,
      bg: "ui-01",
    },
    container: {
      border: 0,
      borderTop: "1px solid",
      borderTopColor: "border-01",
    },
    button: {
      fontWeight: 700,
      p: 4,
    },
    panel: {},
  },
  variants: {
    default: {
      container: {},
      panel: {},
      button: {},
    },
  },
  defaultProps: {
    variant: "default",
  },
});
