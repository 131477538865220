interface CommifyOptions {
  locale?: string;
  maximumSignificantDigits?: number;
  minimumFractionDigits?: number;
}

/**
 * Replacement for ethers.utils.commify
 */
export const commify = (value: string | number, options?: CommifyOptions) => {
  const { locale, maximumSignificantDigits = 18, minimumFractionDigits } = options || {};
  const numberToFormat = typeof value === "string" ? parseFloat(value) : value;
  return new Intl.NumberFormat(locale, { maximumSignificantDigits, minimumFractionDigits }).format(numberToFormat);
};
