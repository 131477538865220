import { getFormattedTimer, useCountdown } from "@looksrare/utils";
import { Text } from "@looksrare/uikit";

interface RoundEntryTimerProps {
  roundLockedAt: number;
}

export const RoundEntryTimer = ({ roundLockedAt }: RoundEntryTimerProps) => {
  const duration = useCountdown(roundLockedAt);
  const formattedDuration = getFormattedTimer(duration);

  return (
    <Text textStyle="detail" color="inherit" bold>
      {formattedDuration}
    </Text>
  );
};
