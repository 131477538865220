import { Icon, IconProps } from "../Icon";

const Share = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 23V27H6V23H4V27C4 28.1046 4.89543 29 6 29H26C27.1046 29 28 28.1046 28 27V23H26ZM8 10L9.41 11.4L15 5.83V24H17V5.83L22.59 11.4L24 10L16 2L8 10ZM4 17C4 15.8954 4.89543 15 6 15H11V17H6V23H4V17ZM28 17C28 15.8954 27.1046 15 26 15H21V17H26V23H28V17Z"
      fill="currentColor"
    />
  </Icon>
);

export default Share;
