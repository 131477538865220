import { Icon, IconProps } from "@chakra-ui/react";

export const RankFirst = (props: IconProps) => (
  <Icon width="256" height="256" viewBox="0 0 256 256" fill="none" {...props}>
    <path
      d="M112 9.2376C121.901 3.52135 134.099 3.52136 144 9.2376L222.851 54.7624C232.752 60.4786 238.851 71.0427 238.851 82.4752V173.525C238.851 184.957 232.752 195.521 222.851 201.238L144 246.762C134.099 252.479 121.901 252.479 112 246.762L33.1487 201.238C23.2479 195.521 17.1488 184.957 17.1488 173.525V82.4752C17.1488 71.0427 23.2479 60.4786 33.1488 54.7624L112 9.2376Z"
      fill="url(#paint0_linear_2424_83566)"
    />
    <path
      d="M82.0626 48.4356C110.489 32.0235 145.512 32.0235 173.939 48.4356C202.365 64.8477 219.877 95.1785 219.877 128.003C219.877 160.827 202.365 191.158 173.939 207.57C145.512 223.982 110.489 223.982 82.0626 207.57C53.6361 191.158 36.1246 160.827 36.1246 128.003C36.1246 95.1785 53.6361 64.8477 82.0626 48.4356Z"
      fill="url(#paint1_linear_2424_83566)"
      stroke="black"
    />
    <g filter="url(#filter0_diii_2424_83566)">
      <path
        d="M118.106 77.9609H139.886V151.221H177.242V173.001H80.75V151.221H118.106V100.797L80.75 111.885V89.0489L118.106 77.9609Z"
        fill="#D9D9D9"
      />
      <path
        d="M118.106 77.9609H139.886V151.221H177.242V173.001H80.75V151.221H118.106V100.797L80.75 111.885V89.0489L118.106 77.9609Z"
        fill="url(#paint2_linear_2424_83566)"
      />
    </g>
    <defs>
      <filter
        id="filter0_diii_2424_83566"
        x="60.2576"
        y="57.4685"
        width="147.723"
        height="146.27"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="5.12311" dy="5.12311" />
        <feGaussianBlur stdDeviation="12.8078" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
        <feBlend mode="overlay" in2="BackgroundImageFix" result="effect1_dropShadow_2424_83566" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2424_83566" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.28078" dy="1.28078" />
        <feGaussianBlur stdDeviation="0.640389" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend mode="soft-light" in2="shape" result="effect2_innerShadow_2424_83566" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1.28078" dy="-1.28078" />
        <feGaussianBlur stdDeviation="0.640389" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.9 0" />
        <feBlend mode="overlay" in2="effect2_innerShadow_2424_83566" result="effect3_innerShadow_2424_83566" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1.28078" dy="-1.28078" />
        <feGaussianBlur stdDeviation="0.640389" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
        <feBlend mode="normal" in2="effect3_innerShadow_2424_83566" result="effect4_innerShadow_2424_83566" />
      </filter>
      <linearGradient
        id="paint0_linear_2424_83566"
        x1="256"
        y1="8.35077e-06"
        x2="-21.5035"
        y2="230.062"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDF307" />
        <stop offset="1" stopColor="#D5CD0B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2424_83566"
        x1="128.001"
        y1="21.3359"
        x2="128.001"
        y2="234.669"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#191B20" />
        <stop offset="1" stopColor="#3D4048" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2424_83566"
        x1="148.143"
        y1="89.8016"
        x2="70.4081"
        y2="142.942"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDF307" />
        <stop offset="1" stopColor="#D5CD0B" />
      </linearGradient>
    </defs>
  </Icon>
);

export const RankFirstGradient = (props: IconProps) => (
  <Icon width="512" height="512" viewBox="0 0 512 512" fill="none" {...props}>
    <circle opacity="0.2" cx="256" cy="256" r="256" fill="url(#paint0_radial_2424_83560)" />
    <defs>
      <radialGradient
        id="paint0_radial_2424_83560"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(256 256) rotate(90) scale(256)"
      >
        <stop stopColor="#CCFD07" />
        <stop offset="1" stopColor="#CCFD07" stopOpacity="0" />
      </radialGradient>
    </defs>
  </Icon>
);
