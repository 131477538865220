import { Box } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { SupportedCurrency } from "../AddFundsModal";
import { SectionLoader } from "../../SectionLoader";

const UniswapWidget = dynamic<any>(() => import("./UniswapWidget"), {
  loading: () => <SectionLoader minHeight="351px" spinnerProps={{ size: "lg" }} />,
});

interface UniswapWidgetViewProps {
  defaultBuyCurrency?: SupportedCurrency;
  defaultBuyAmount?: number; // @NOTE uses decimals not wei
}

export const UniswapWidgetView = ({ defaultBuyCurrency, defaultBuyAmount }: UniswapWidgetViewProps) => {
  return (
    <Box mx="auto" minHeight="351px">
      <UniswapWidget defaultBuyCurrency={defaultBuyCurrency} defaultOutputAmount={defaultBuyAmount} />
    </Box>
  );
};
