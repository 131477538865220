import { ChainId, chainInfo } from "./chains";

export const USER_ACCOUNT_URI = "me";
export const COIN_GECKO_API_URL = "https://api.coingecko.com/api/v3";
export const COIN_GECKO_PRO_API_URL = "https://pro-api.coingecko.com/api/v3";
export const MOONPAY_WIDGET_BASE_URL = "https://buy.moonpay.com";
export const NFTGO_API_URL = "https://data-api.nftgo.io";
export const DOC_URL = "https://docs.looksrare.org";
export const SWAP_URL = "https://app.uniswap.org/#/swap";
export const INFO_URL = "https://info.uniswap.org/#/tokens";
export const CHAT_URL = "https://chat.blockscan.com";
export const IPFS_GATEWAY = "https://looksrare.mypinata.cloud/ipfs";
export const SENDY_API_URL = "https://gm.looksrare.org";
export const TOKEN_IMAGE_PLACEHOLDER_URI = "/images/token-image-placeholder.svg";
export const CONTENTFUL_URL = "https://graphql.contentful.com";
export const PYTH_URL = "https://benchmarks.pyth.network";

export const getGraphQLApiUrl = (chainId: ChainId) => `${chainInfo[chainId].baseApiUrl}/graphql`;
export const getExplorerUrl = (chainId: ChainId) => chainInfo[chainId].explorer;

const reservoirApiUrl: { [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: "https://api.reservoir.tools",
  [ChainId.GOERLI]: "https://api-goerli.reservoir.tools",
  [ChainId.HARDHAT]: "https://api.reservoir.tools",
  [ChainId.SEPOLIA]: "https://api-sepolia.reservoir.tools",
  [ChainId.ARB_MAINNET]: "https://api-arbitrum.reservoir.tools",

  // @todo-arb-base Confirm this URL
  [ChainId.ARB_SEPOLIA]: "https://api-arbitrum-sepolia.reservoir.tools",

  // Not relevant to this project
  [ChainId.BLAST_SEPOLIA]: "",
  [ChainId.BLAST_MAINNET]: "",
};
export const getReservoirApiUrl = (chainId: ChainId) => reservoirApiUrl[chainId];
