export const InvasionAbi = [
  {
    type: "constructor",
    inputs: [
      {
        name: "constructorCalldata",
        type: "tuple",
        internalType: "struct IInfiltration.ConstructorCalldata",
        components: [
          {
            name: "owner",
            type: "address",
            internalType: "address",
          },
          {
            name: "name",
            type: "string",
            internalType: "string",
          },
          {
            name: "symbol",
            type: "string",
            internalType: "string",
          },
          {
            name: "price",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "maxSupply",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "maxMintPerAddress",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "secondsPerRound",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "agentsToWoundPerRoundInBasisPoints",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "roundsToBeWoundedBeforeDead",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "looks",
            type: "address",
            internalType: "address",
          },
          {
            name: "vrfCoordinator",
            type: "address",
            internalType: "address",
          },
          {
            name: "keyHash",
            type: "bytes32",
            internalType: "bytes32",
          },
          {
            name: "subscriptionId",
            type: "uint64",
            internalType: "uint64",
          },
          {
            name: "transferManager",
            type: "address",
            internalType: "address",
          },
          {
            name: "healBaseCost",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "protocolFeeRecipient",
            type: "address",
            internalType: "address",
          },
          {
            name: "protocolFeeBp",
            type: "uint16",
            internalType: "uint16",
          },
          {
            name: "weth",
            type: "address",
            internalType: "address",
          },
          {
            name: "baseURI",
            type: "string",
            internalType: "string",
          },
        ],
      },
      {
        name: "blast",
        type: "address",
        internalType: "address",
      },
      {
        name: "blastPoints",
        type: "address",
        internalType: "address",
      },
      {
        name: "blastPointsOperator",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "AGENTS_TO_WOUND_PER_ROUND_IN_BASIS_POINTS",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "HEAL_BASE_COST",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "MAXIMUM_PROTOCOL_FEE_BP",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint16",
        internalType: "uint16",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "MAX_MINT_PER_ADDRESS",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "MAX_SUPPLY",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "PRICE",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "ROUNDS_TO_BE_WOUNDED_BEFORE_DEAD",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "SECONDS_PER_ROUND",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "agentIndex",
    inputs: [
      {
        name: "agentId",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "index",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "agentsAlive",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "amountMintedPerAddress",
    inputs: [
      {
        name: "minter",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "approve",
    inputs: [
      {
        name: "to",
        type: "address",
        internalType: "address",
      },
      {
        name: "tokenId",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "balanceOf",
    inputs: [
      {
        name: "owner",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "cancelOwnershipTransfer",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "claimGrandPrize",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "claimSecondaryPrizes",
    inputs: [
      {
        name: "agentId",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "closeRound",
    inputs: [
      {
        name: "requestId",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "confirmOwnershipRenouncement",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "confirmOwnershipTransfer",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "costToHeal",
    inputs: [
      {
        name: "agentIds",
        type: "uint256[]",
        internalType: "uint256[]",
      },
    ],
    outputs: [
      {
        name: "cost",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "emergencyWithdraw",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "escape",
    inputs: [
      {
        name: "agentIds",
        type: "uint256[]",
        internalType: "uint256[]",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "escapeMultiplier",
    inputs: [],
    outputs: [
      {
        name: "multiplier",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "escapeReward",
    inputs: [
      {
        name: "agentIds",
        type: "uint256[]",
        internalType: "uint256[]",
      },
    ],
    outputs: [
      {
        name: "reward",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "escapeRewardSplitForSecondaryPrizePool",
    inputs: [],
    outputs: [
      {
        name: "split",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "gameInfo",
    inputs: [],
    outputs: [
      {
        name: "activeAgents",
        type: "uint16",
        internalType: "uint16",
      },
      {
        name: "woundedAgents",
        type: "uint16",
        internalType: "uint16",
      },
      {
        name: "healingAgents",
        type: "uint16",
        internalType: "uint16",
      },
      {
        name: "deadAgents",
        type: "uint16",
        internalType: "uint16",
      },
      {
        name: "escapedAgents",
        type: "uint16",
        internalType: "uint16",
      },
      {
        name: "currentRoundId",
        type: "uint40",
        internalType: "uint40",
      },
      {
        name: "currentRoundBlockTimestamp",
        type: "uint40",
        internalType: "uint40",
      },
      {
        name: "randomnessLastRequestedAt",
        type: "uint40",
        internalType: "uint40",
      },
      {
        name: "prizePool",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "secondaryPrizePool",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "secondaryLooksPrizePool",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getAgent",
    inputs: [
      {
        name: "index",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "agent",
        type: "tuple",
        internalType: "struct IInfiltration.Agent",
        components: [
          {
            name: "agentId",
            type: "uint16",
            internalType: "uint16",
          },
          {
            name: "status",
            type: "uint8",
            internalType: "enum IInfiltration.AgentStatus",
          },
          {
            name: "woundedAt",
            type: "uint40",
            internalType: "uint40",
          },
          {
            name: "healCount",
            type: "uint16",
            internalType: "uint16",
          },
        ],
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getApproved",
    inputs: [
      {
        name: "tokenId",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getRoundInfo",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "woundedAgentIds",
        type: "uint256[]",
        internalType: "uint256[]",
      },
      {
        name: "healingAgentIds",
        type: "uint256[]",
        internalType: "uint256[]",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "heal",
    inputs: [
      {
        name: "agentIds",
        type: "uint256[]",
        internalType: "uint256[]",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "healProbability",
    inputs: [
      {
        name: "healingRoundsDelay",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "y",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "initiateOwnershipRenouncement",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "initiateOwnershipTransfer",
    inputs: [
      {
        name: "newPotentialOwner",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "isApprovedForAll",
    inputs: [
      {
        name: "owner",
        type: "address",
        internalType: "address",
      },
      {
        name: "operator",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "mint",
    inputs: [
      {
        name: "quantity",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "mintEnd",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint40",
        internalType: "uint40",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "mintStart",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint40",
        internalType: "uint40",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "name",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "string",
        internalType: "string",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "owner",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "ownerOf",
    inputs: [
      {
        name: "tokenId",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "ownershipStatus",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint8",
        internalType: "enum IOwnableTwoSteps.Status",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "potentialOwner",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "premint",
    inputs: [
      {
        name: "to",
        type: "address",
        internalType: "address",
      },
      {
        name: "quantity",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "protocolFeeBp",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint16",
        internalType: "uint16",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "protocolFeeRecipient",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "randomnessRequests",
    inputs: [
      {
        name: "requestId",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "status",
        type: "uint8",
        internalType: "enum IInfiltration.RandomnessRequestStatus",
      },
      {
        name: "roundId",
        type: "uint40",
        internalType: "uint40",
      },
      {
        name: "randomWord",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "rawFulfillRandomWords",
    inputs: [
      {
        name: "requestId",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "randomWords",
        type: "uint256[]",
        internalType: "uint256[]",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "safeTransferFrom",
    inputs: [
      {
        name: "from",
        type: "address",
        internalType: "address",
      },
      {
        name: "to",
        type: "address",
        internalType: "address",
      },
      {
        name: "tokenId",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "safeTransferFrom",
    inputs: [
      {
        name: "from",
        type: "address",
        internalType: "address",
      },
      {
        name: "to",
        type: "address",
        internalType: "address",
      },
      {
        name: "tokenId",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "_data",
        type: "bytes",
        internalType: "bytes",
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "secondaryPrizePoolShareAmount",
    inputs: [
      {
        name: "secondaryPrizePoolAmount",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "placement",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "shareAmount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "pure",
  },
  {
    type: "function",
    name: "secondaryPrizePoolShareBp",
    inputs: [
      {
        name: "placement",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "share",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "pure",
  },
  {
    type: "function",
    name: "setApprovalForAll",
    inputs: [
      {
        name: "operator",
        type: "address",
        internalType: "address",
      },
      {
        name: "approved",
        type: "bool",
        internalType: "bool",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setMintPeriod",
    inputs: [
      {
        name: "newMintStart",
        type: "uint40",
        internalType: "uint40",
      },
      {
        name: "newMintEnd",
        type: "uint40",
        internalType: "uint40",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "startGame",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "startNewRound",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "supportsInterface",
    inputs: [
      {
        name: "interfaceId",
        type: "bytes4",
        internalType: "bytes4",
      },
    ],
    outputs: [
      {
        name: "",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "symbol",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "string",
        internalType: "string",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "tokenURI",
    inputs: [
      {
        name: "tokenId",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "",
        type: "string",
        internalType: "string",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "totalSupply",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "transferFrom",
    inputs: [
      {
        name: "from",
        type: "address",
        internalType: "address",
      },
      {
        name: "to",
        type: "address",
        internalType: "address",
      },
      {
        name: "tokenId",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "updateProtocolFeeBp",
    inputs: [
      {
        name: "",
        type: "uint16",
        internalType: "uint16",
      },
    ],
    outputs: [],
    stateMutability: "pure",
  },
  {
    type: "function",
    name: "updateProtocolFeeRecipient",
    inputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "pure",
  },
  {
    type: "event",
    name: "Approval",
    inputs: [
      {
        name: "owner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "approved",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "tokenId",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ApprovalForAll",
    inputs: [
      {
        name: "owner",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "operator",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "approved",
        type: "bool",
        indexed: false,
        internalType: "bool",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "CancelOwnershipTransfer",
    inputs: [],
    anonymous: false,
  },
  {
    type: "event",
    name: "ConsecutiveTransfer",
    inputs: [
      {
        name: "fromTokenId",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
      {
        name: "toTokenId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "from",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "to",
        type: "address",
        indexed: true,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "EmergencyWithdrawal",
    inputs: [
      {
        name: "ethAmount",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "looksAmount",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Escaped",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "agentIds",
        type: "uint256[]",
        indexed: false,
        internalType: "uint256[]",
      },
      {
        name: "rewards",
        type: "uint256[]",
        indexed: false,
        internalType: "uint256[]",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "HealRequestFulfilled",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "healResults",
        type: "tuple[]",
        indexed: false,
        internalType: "struct IInfiltration.HealResult[]",
        components: [
          {
            name: "agentId",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "outcome",
            type: "uint8",
            internalType: "enum IInfiltration.HealOutcome",
          },
        ],
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "HealRequestSubmitted",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "agentIds",
        type: "uint256[]",
        indexed: false,
        internalType: "uint256[]",
      },
      {
        name: "costs",
        type: "uint256[]",
        indexed: false,
        internalType: "uint256[]",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "InitiateOwnershipRenouncement",
    inputs: [],
    anonymous: false,
  },
  {
    type: "event",
    name: "InitiateOwnershipTransfer",
    inputs: [
      {
        name: "previousOwner",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "potentialOwner",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "InvalidRandomnessFulfillment",
    inputs: [
      {
        name: "requestId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "randomnessRequestRoundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "currentRoundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Killed",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "agentIds",
        type: "uint256[]",
        indexed: false,
        internalType: "uint256[]",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "MintPeriodUpdated",
    inputs: [
      {
        name: "mintStart",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "mintEnd",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "NewOwner",
    inputs: [
      {
        name: "newOwner",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "PrizeClaimed",
    inputs: [
      {
        name: "agentId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "currency",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ProtocolFeeBpUpdated",
    inputs: [
      {
        name: "protocolFeeBp",
        type: "uint16",
        indexed: false,
        internalType: "uint16",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ProtocolFeeRecipientUpdated",
    inputs: [
      {
        name: "protocolFeeRecipient",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RandomnessFulfilled",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "requestId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RandomnessRequested",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "requestId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RoundStarted",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Transfer",
    inputs: [
      {
        name: "from",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "to",
        type: "address",
        indexed: true,
        internalType: "address",
      },
      {
        name: "tokenId",
        type: "uint256",
        indexed: true,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Won",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "agentId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Wounded",
    inputs: [
      {
        name: "roundId",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "agentIds",
        type: "uint256[]",
        indexed: false,
        internalType: "uint256[]",
      },
    ],
    anonymous: false,
  },
  {
    type: "error",
    name: "ApprovalCallerNotOwnerNorApproved",
    inputs: [],
  },
  {
    type: "error",
    name: "ApprovalQueryForNonexistentToken",
    inputs: [],
  },
  {
    type: "error",
    name: "BalanceQueryForZeroAddress",
    inputs: [],
  },
  {
    type: "error",
    name: "ERC20TransferFail",
    inputs: [],
  },
  {
    type: "error",
    name: "ExceededTotalSupply",
    inputs: [],
  },
  {
    type: "error",
    name: "FrontrunLockIsOn",
    inputs: [],
  },
  {
    type: "error",
    name: "GameAlreadyBegun",
    inputs: [],
  },
  {
    type: "error",
    name: "GameIsStillRunning",
    inputs: [],
  },
  {
    type: "error",
    name: "GameNotYetBegun",
    inputs: [],
  },
  {
    type: "error",
    name: "GameOver",
    inputs: [],
  },
  {
    type: "error",
    name: "HealingDisabled",
    inputs: [],
  },
  {
    type: "error",
    name: "Immutable",
    inputs: [],
  },
  {
    type: "error",
    name: "InexactNativeTokensSupplied",
    inputs: [],
  },
  {
    type: "error",
    name: "InvalidAgentStatus",
    inputs: [
      {
        name: "agentId",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "expectedStatus",
        type: "uint8",
        internalType: "enum IInfiltration.AgentStatus",
      },
    ],
  },
  {
    type: "error",
    name: "InvalidHealingRoundsDelay",
    inputs: [],
  },
  {
    type: "error",
    name: "InvalidMaxSupply",
    inputs: [],
  },
  {
    type: "error",
    name: "InvalidMintPeriod",
    inputs: [],
  },
  {
    type: "error",
    name: "InvalidPlacement",
    inputs: [],
  },
  {
    type: "error",
    name: "InvalidRandomnessRequestId",
    inputs: [],
  },
  {
    type: "error",
    name: "MaximumHealingRequestPerRoundExceeded",
    inputs: [],
  },
  {
    type: "error",
    name: "MintAlreadyStarted",
    inputs: [],
  },
  {
    type: "error",
    name: "MintCanOnlyBeExtended",
    inputs: [],
  },
  {
    type: "error",
    name: "MintERC2309QuantityExceedsLimit",
    inputs: [],
  },
  {
    type: "error",
    name: "MintStartIsInThePast",
    inputs: [],
  },
  {
    type: "error",
    name: "MintToZeroAddress",
    inputs: [],
  },
  {
    type: "error",
    name: "MintZeroQuantity",
    inputs: [],
  },
  {
    type: "error",
    name: "NoAgentsLeft",
    inputs: [],
  },
  {
    type: "error",
    name: "NoAgentsProvided",
    inputs: [],
  },
  {
    type: "error",
    name: "NoOngoingTransferInProgress",
    inputs: [],
  },
  {
    type: "error",
    name: "NotAContract",
    inputs: [],
  },
  {
    type: "error",
    name: "NotAgentOwner",
    inputs: [],
  },
  {
    type: "error",
    name: "NotEnoughMinted",
    inputs: [],
  },
  {
    type: "error",
    name: "NotInMintPeriod",
    inputs: [],
  },
  {
    type: "error",
    name: "NotOwner",
    inputs: [],
  },
  {
    type: "error",
    name: "NothingToClaim",
    inputs: [],
  },
  {
    type: "error",
    name: "OnlyCoordinatorCanFulfill",
    inputs: [
      {
        name: "have",
        type: "address",
        internalType: "address",
      },
      {
        name: "want",
        type: "address",
        internalType: "address",
      },
    ],
  },
  {
    type: "error",
    name: "OwnerQueryForNonexistentToken",
    inputs: [],
  },
  {
    type: "error",
    name: "OwnershipNotInitializedForExtraData",
    inputs: [],
  },
  {
    type: "error",
    name: "ProtocolFee__InvalidValue",
    inputs: [],
  },
  {
    type: "error",
    name: "RandomnessRequestAlreadyExists",
    inputs: [],
  },
  {
    type: "error",
    name: "ReentrancyFail",
    inputs: [],
  },
  {
    type: "error",
    name: "RenouncementNotInProgress",
    inputs: [],
  },
  {
    type: "error",
    name: "RoundsToBeWoundedBeforeDeadTooLow",
    inputs: [],
  },
  {
    type: "error",
    name: "StillMinting",
    inputs: [],
  },
  {
    type: "error",
    name: "TooEarlyToRetryRandomnessRequest",
    inputs: [],
  },
  {
    type: "error",
    name: "TooEarlyToStartNewRound",
    inputs: [],
  },
  {
    type: "error",
    name: "TooManyMinted",
    inputs: [],
  },
  {
    type: "error",
    name: "TransferAlreadyInProgress",
    inputs: [],
  },
  {
    type: "error",
    name: "TransferCallerNotOwnerNorApproved",
    inputs: [],
  },
  {
    type: "error",
    name: "TransferFromIncorrectOwner",
    inputs: [],
  },
  {
    type: "error",
    name: "TransferNotInProgress",
    inputs: [],
  },
  {
    type: "error",
    name: "TransferToNonERC721ReceiverImplementer",
    inputs: [],
  },
  {
    type: "error",
    name: "TransferToZeroAddress",
    inputs: [],
  },
  {
    type: "error",
    name: "URIQueryForNonexistentToken",
    inputs: [],
  },
  {
    type: "error",
    name: "WoundedAgentIdsPerRoundExceeded",
    inputs: [],
  },
  {
    type: "error",
    name: "WrongPotentialOwner",
    inputs: [],
  },
] as const;
