import { ButtonProps } from "@chakra-ui/react";

// @TODO add black colorScheme
function getOutlineBorder(colorScheme?: string) {
  switch (colorScheme) {
    case "danger":
    case "red":
      return "support-error";
    case "secondary":
    case "gray":
      return "border-02";
    case "white":
      return "white";
    case "primary":
    case "brand":
    default:
      return "interactive-03";
  }
}

function getPrimaryTextColor(variant?: string) {
  switch (variant) {
    case "outline":
    case "ghost":
      return "link-01";
    case "solid":
    default:
      return "text-primarybutton";
  }
}

function getBrandTextColor(variant?: string) {
  switch (variant) {
    case "outline":
    case "ghost":
      return "link-01";
    case "solid":
    default:
      return "text-primarybutton";
  }
}

function getPrimaryBgColors(variant?: string) {
  switch (variant) {
    case "outline":
    case "ghost":
      return {
        default: "transparent",
        hover: "hover-ui",
        active: "onclick-ui",
        focus: "transparent",
      };
    case "solid":
    default:
      return {
        default: "interactive-01",
        hover: "hover-interactive-01",
        active: "onclick-interactive-01",
        focus: "interactive-01",
      };
  }
}

function getBrandBgColors(variant?: string) {
  switch (variant) {
    case "outline":
    case "ghost":
      return {
        default: "transparent",
        hover: "hover-ui",
        active: "onclick-ui",
        focus: "transparent",
      };
    case "solid":
    default:
      return {
        default: "interactive-03",
        hover: "hover-interactive-03",
        active: "onclick-interactive-03",
        focus: "interactive-03",
      };
  }
}

function getSecondaryBgColors(variant?: string) {
  switch (variant) {
    case "outline":
    case "ghost":
      return {
        default: "transparent",
        hover: "hover-interactive-02",
        active: "onclick-interactive-02",
        focus: "transparent",
      };
    case "solid":
    default:
      return {
        default: "interactive-02",
        hover: "hover-interactive-02",
        active: "onclick-interactive-02",
        focus: "interactive-02",
      };
  }
}

function getDangerTextColor(variant?: string) {
  switch (variant) {
    case "outline":
    case "ghost":
      return "text-error";
    case "solid":
    default:
      return "link-02";
  }
}

function getDangerBgColors(variant?: string) {
  switch (variant) {
    case "outline":
    case "ghost":
      return {
        default: "transparent",
        hover: "hover-ui",
        active: "onclick-ui",
        focus: "transparent",
      };
    case "solid":
    default:
      return {
        default: "support-error-inverse",
        hover: "hover-error",
        active: "onclick-error",
        focus: "support-error-inverse",
      };
  }
}

/**
 * Special case for the Switcher component
 */
const switcherColorScheme = {
  background: "ui-02",
  bg: "ui-02",
  bgColor: "ui-02",
  color: "text-02",
  _hover: {
    bg: "hover-interactive-02",
    bgColor: "hover-interactive-02",
    textDecoration: "none",
  },
  _active: {
    bg: "onclick-interactive-02",
    bgColor: "onclick-interactive-02",
    border: "1px solid",
    borderColor: "ui-02",
    color: "link-02",
  },
};

function applyColorScheme(colorScheme?: string, variant?: string) {
  const { default: primaryDefaultBg, hover: primaryHoverBg, active: primaryActiveBg } = getPrimaryBgColors(variant);
  const { default: brandDefaultBg, hover: brandHoverBg, active: brandActiveBg } = getBrandBgColors(variant);
  const {
    default: secondaryDefaultBg,
    hover: secondaryHoverBg,
    active: secondaryActiveBg,
  } = getSecondaryBgColors(variant);
  const { default: dangerDefaultBg, hover: dangerHoverBg, active: dangerActiveBg } = getDangerBgColors(variant);

  switch (colorScheme) {
    case "primary":
      return {
        bg: primaryDefaultBg,
        bgColor: primaryDefaultBg,
        color: getPrimaryTextColor(variant),
        _hover: {
          bg: primaryHoverBg,
          bgColor: primaryHoverBg,
          textDecoration: "none",
        },
        _active: {
          bg: primaryActiveBg,
          bgColor: primaryActiveBg,
          border: "1px solid",
          borderColor: "focus",
        },
      };
    case "brand":
      return {
        bg: brandDefaultBg,
        bgColor: brandDefaultBg,
        color: getBrandTextColor(variant),
        _hover: {
          bg: brandHoverBg,
          bgColor: brandHoverBg,
          textDecoration: "none",
        },
        _active: {
          bg: brandActiveBg,
          bgColor: brandActiveBg,
          border: "1px solid",
          borderColor: "focus",
        },
      };
    case "gray":
    case "secondary":
      return {
        background: secondaryDefaultBg,
        bg: secondaryDefaultBg,
        bgColor: secondaryDefaultBg,
        color: "link-02",
        _hover: {
          bg: secondaryHoverBg,
          bgColor: secondaryHoverBg,
          textDecoration: "none",
        },
        _active: {
          bg: secondaryActiveBg,
          bgColor: secondaryActiveBg,
          border: "1px solid",
          borderColor: "focus",
        },
      };
    case "red":
    case "danger":
      return {
        background: dangerDefaultBg,
        bg: dangerDefaultBg,
        bgColor: dangerDefaultBg,
        color: getDangerTextColor(variant),
        _hover: {
          bg: dangerHoverBg,
          bgColor: dangerHoverBg,
          textDecoration: "none",
        },
        _active: {
          bg: dangerActiveBg,
          bgColor: dangerActiveBg,
          border: "1px solid",
          borderColor: "focus",
        },
      };
    // @todo - flesh this out with proper styles
    case "yellow":
    case "warning":
      return {
        background: "support-warning",
        bg: "support-warning",
        bgColor: "support-warning",
        color: "black",
        _hover: {
          bg: "support-warning-inverse",
          bgColor: "support-warning-inverse",
          textDecoration: "none",
        },
        _active: {
          bg: "support-warning",
          bgColor: "support-warning",
        },
      };
    case "white":
      return {
        background: "ui-inverse",
        bg: "ui-inverse",
        bgColor: "ui-inverse",
        color: "text-inverse",
        _hover: {
          bg: "ui-inverse",
          bgColor: "ui-inverse",
          textDecoration: "none",
        },
        _active: {
          bg: "ui-inverse",
          bgColor: "ui-inverse",
          border: "1px solid",
          borderColor: "focus",
        },
      };
    case "black":
      // note black is only used on banners (forced-dark-mode scheme)
      return {
        _hover: {
          bg: "rgba(0, 0, 0, 0.12)",
          textDecoration: "none",
        },
        _active: {
          bg: "rgba(0, 0, 0, 0.18)",
          textDecoration: "none",
        },
      };
    case "blackAlpha": // does not change with color mode
      return {
        background: "blackAlpha.400",
        bg: "blackAlpha.400",
        bgColor: "blackAlpha.400",
        color: "white",
        _hover: {
          bg: "blackAlpha.500",
          bgColor: "blackAlpha.500",
          textDecoration: "none",
        },
        _active: {
          bg: "blackAlpha.500",
          bgColor: "blackAlpha.500",
          border: "1px solid",
          borderColor: "focus",
        },
      };
    case "switcher":
      return switcherColorScheme;
    case "switcher-active":
      return { ...switcherColorScheme, background: "ui-bg", bg: "ui-bg", bgColor: "ui-bg", color: "link-02" };
    default:
      // this applies chakra's default colorScheme
      return {};
  }
}

//at this point variant is already resolved to a string
type ButtonConfigProps = Omit<ButtonProps, "variant"> & { variant: string };
/**
 * Applied to every Button
 * These properties get overridden by supplying a `variant`
 */
const baseStyle = (props: ButtonConfigProps) => {
  const { colorScheme, variant } = props;

  return {
    borderRadius: "button",
    fontWeight: "600",
    borderWidth: "1px",
    borderColor: "transparent",
    _disabled: {
      opacity: 1,
    },
    ...applyColorScheme(colorScheme, variant),
  };
};

const solidDisabled = {
  bg: "interactive-02",
  bgColor: "interactive-02",
  color: "text-disabled",
  _hover: {
    bg: "interactive-02",
    bgColor: "interactive-02",
  },
  _active: {
    borderColor: "transparent",
  },
};

const ghostDisabled = {
  color: "text-disabled",
  _hover: {
    bg: "transparent",
    bgColor: "transparent",
  },
  _active: {
    borderColor: "transparent",
  },
};

const outlineDisabled = {
  borderColor: "border-02",
  borderTopColor: "border-02",
  borderLeftColor: "border-02",
  borderRightColor: "border-02",
  borderBottomColor: "border-02",
  color: "text-disabled",
  _hover: {
    bg: "transparent",
    bgColor: "transparent",
  },
  _active: {
    borderColor: "text-disabled",
  },
};

/**
 * Base button changes
 */
const solidVariant = (props: ButtonConfigProps) => ({
  ...applyColorScheme(props.colorScheme, props.variant),
  _disabled: solidDisabled,
});

const ghostVariant = (props: ButtonConfigProps) => ({
  ...applyColorScheme(props.colorScheme, props.variant),
  _disabled: ghostDisabled,
});

const outlineVariant = ({ colorScheme, variant }: ButtonConfigProps) => ({
  ...applyColorScheme(colorScheme, variant),
  borderColor: getOutlineBorder(colorScheme),
  borderTopColor: getOutlineBorder(colorScheme),
  borderLeftColor: getOutlineBorder(colorScheme),
  borderRightColor: getOutlineBorder(colorScheme),
  borderBottomColor: getOutlineBorder(colorScheme),
  _disabled: outlineDisabled,
});

// Legacy button colors are "gray" | "red"
// Semantic button colors = "primary" | "secondary" | "danger" | "brand"
// svg styles added for IconButton
export const ButtonTheme = {
  baseStyle,
  sizes: {
    lg: {
      height: "3.5rem",
      minWidth: "3.5rem",
      fontSize: "md",
      svg: {
        boxSize: 6,
      },
    },
    md: {
      height: "3rem",
      minWidth: "3rem",
      fontSize: "sm",
      svg: {
        boxSize: 5,
      },
    },
    sm: {
      height: "2.5rem",
      minWidth: "2.5rem",
      fontSize: "sm",
      svg: {
        boxSize: 5,
      },
    },
    xs: {
      height: "2rem",
      minWidth: "2rem",
      fontSize: "sm",
      svg: {
        boxSize: 5,
      },
    },
    xxs: {
      height: "1.25rem",
      minWidth: "1.25rem",
      fontSize: "xs",
      px: 2,
      py: "2px",
    },
  },
  variants: {
    solid: solidVariant,
    ghost: ghostVariant,
    outline: outlineVariant,
  },
  defaultProps: {
    variant: "solid",
    colorScheme: "primary",
  },
};
