import { useState } from "react";
import { useAssertNetwork } from "@looksrare/utils";
import { Modal, ModalProps } from "../../Modal";
import { BridgeLooksInputView } from "./components/BridgeLooksInputView";
import { BridgeLooksTransactionView } from "./components/BridgeLooksTransactionView";
import { BridgeLooksSuccessView } from "./components/BridgeLooksSuccessView";

enum BridgeLooksModalView {
  INPUT = "INPUT",
  TRANSACTION = "TRANSACTION",
  SUCCESS = "SUCCESS",
}

export enum LooksBridgeDirection {
  ETH_TO_BLAST = "ETH_TO_BLAST",
  BLAST_TO_ETH = "BLAST_TO_ETH",
}

export interface BridgeLooksModalProps {
  isOpen: ModalProps["isOpen"];
  onClose: ModalProps["onClose"];
}

export const BridgeLooksModal = ({ isOpen, onClose }: BridgeLooksModalProps) => {
  const [modalView, setModalView] = useState<BridgeLooksModalView>(BridgeLooksModalView.INPUT);

  const [looksToBridge, setLooksToBridge] = useState<string>("1");
  const [direction, setBridgeDirection] = useState<LooksBridgeDirection>(LooksBridgeDirection.ETH_TO_BLAST);
  const [txHash, setTxHash] = useState("");

  const assertNetwork = useAssertNetwork({
    network: direction === LooksBridgeDirection.ETH_TO_BLAST ? "ethereum" : "blast",
  });

  const switchDirection = () => {
    if (direction === LooksBridgeDirection.ETH_TO_BLAST) {
      setBridgeDirection(LooksBridgeDirection.BLAST_TO_ETH);
      return;
    }

    setBridgeDirection(LooksBridgeDirection.ETH_TO_BLAST);
  };

  const onStartBridge = async () => {
    await assertNetwork();
    setModalView(BridgeLooksModalView.TRANSACTION);
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} closeOnOverlayClick={false} modalContentProps={{ border: "none" }}>
      {(() => {
        switch (modalView) {
          case BridgeLooksModalView.INPUT:
            return (
              <BridgeLooksInputView
                looksToBridge={looksToBridge}
                direction={direction}
                setLooksToBridge={setLooksToBridge}
                onSwitchDirection={switchDirection}
                onStartBridge={onStartBridge}
                onClose={onClose}
              />
            );
          case BridgeLooksModalView.TRANSACTION:
            return (
              <BridgeLooksTransactionView
                looksToBridge={looksToBridge}
                direction={direction}
                onSuccess={(hash) => {
                  setTxHash(hash);
                  setModalView(BridgeLooksModalView.SUCCESS);
                }}
                onBack={() => setModalView(BridgeLooksModalView.INPUT)}
              />
            );
          case BridgeLooksModalView.SUCCESS:
            return <BridgeLooksSuccessView transactionHash={txHash} onClose={onClose} />;
        }
      })()}
    </Modal>
  );
};
