import { ModalFooter, ModalHeader, VStack } from "@chakra-ui/react";
import { Button, CheckmarkFilledIcon, Modal, ModalCloseButton, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";

interface ClaimSuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ClaimSuccessModal = ({ isOpen, onClose }: ClaimSuccessModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader position="relative">
        <VStack spacing={4} py={6} width="100%">
          <CheckmarkFilledIcon color="interactive-03" boxSize={16} />

          <VStack spacing={2}>
            <Text textStyle="heading-03" textAlign="center" bold>
              {t("Claimed!")}
            </Text>
            <Text textStyle="body" textAlign="center" color="text-02" fontWeight="400">
              {`${t("Your winnings are now in your wallet")}.`}
            </Text>
          </VStack>
        </VStack>

        <ModalCloseButton position="absolute" top={4} right={4} onClick={onClose} />
      </ModalHeader>
      <ModalFooter>
        <Button onClick={onClose} colorScheme="secondary" width="100%">
          {t("Nice")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
