import { MutableRefObject, useCallback, useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce";

const END_ERROR_MARGIN_PX = 5;

export interface useCarouselControlOptions {
  isAutomatic: boolean;
  isPaused: boolean;
  interval: number;
}

const defaultOptions: useCarouselControlOptions = {
  isAutomatic: false,
  isPaused: false,
  interval: 5000,
};

export const useCarouselControl = (
  parentRef: MutableRefObject<HTMLDivElement | null>,
  options?: Partial<useCarouselControlOptions>
) => {
  const timeout = useRef<NodeJS.Timeout>();
  const [prevAutoSlideTime, setPrevAutoSlideTime] = useState(Date.now());

  const [slideIndex, setSlideIndex] = useState(0);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const mergedOptions = {
    ...defaultOptions,
    ...(options ?? {}),
  };

  // If the right side of the last element in the carousel is the same as the right side of the carousel itself,
  // then we are at the end of the carousel.
  const checkIsAtEnd = useCallback((carousel: HTMLDivElement) => {
    const paddingRightValue = parseFloat(window.getComputedStyle(carousel).paddingRight);
    const paddingRight = isNaN(paddingRightValue) ? 0 : paddingRightValue;

    const carouselEnd = carousel.getBoundingClientRect().right - paddingRight;

    // @NOTE indexed access to children is not recognized as possibly undefined
    const lastElement: Element | undefined = carousel.children[carousel.children.length - 1];

    const lastElementPosition = !!lastElement ? lastElement.getBoundingClientRect().right : 0;

    setIsAtEnd(lastElementPosition <= carouselEnd + END_ERROR_MARGIN_PX);
  }, []);

  // When the carousel is scrolled, we need to determine which slide is currently selected.
  // We do this by checking the offsetLeft of each slide. If the offsetLeft of a slide is the same as the scrollLeft
  // of the carousel, then that slide is currently selected.
  // Otherwise, if the right side of the slide is greater than the left side of the carousel, then that slide is partially in view,
  // meaning that it's actually the slide after it that is currently selected.
  useEffect(() => {
    const carousel = parentRef.current;

    if (carousel) {
      checkIsAtEnd(carousel);

      const scrollListener = () => {
        checkIsAtEnd(carousel);

        const paddingLeftValue = parseFloat(window.getComputedStyle(carousel).scrollPaddingLeft);
        const paddingLeft = isNaN(paddingLeftValue) ? 0 : paddingLeftValue;

        let newSlideIndex = 0;

        [...carousel.children].every((child, index) => {
          const childElement = child as HTMLElement;

          if (childElement.offsetLeft === carousel.scrollLeft + paddingLeft) {
            newSlideIndex = index;
            return false;
          }

          if (childElement.getBoundingClientRect().right > carousel.getBoundingClientRect().left) {
            newSlideIndex = index + 1;
            return false;
          }

          return true;
        });

        if (newSlideIndex >= carousel.children.length) {
          newSlideIndex = carousel.children.length - 1;
        }

        setSlideIndex(newSlideIndex);
      };

      carousel.addEventListener("scroll", debounce(scrollListener, 150));

      return () => carousel.removeEventListener("scroll", scrollListener);
    }
  }, [checkIsAtEnd, parentRef]);

  const scrollTo = useCallback(
    (newOffset: number) => {
      parentRef.current?.scrollTo({ left: newOffset, behavior: "smooth" });

      setPrevAutoSlideTime(Date.now());
    },
    [parentRef]
  );

  const next = useCallback(() => {
    const amountOfSlides = parentRef.current?.children.length ?? 0;

    if (slideIndex < amountOfSlides - 1 && !isAtEnd && parentRef.current) {
      const nextSlideIndex = slideIndex + 1;
      const newOffset = (parentRef.current.children[nextSlideIndex] as HTMLElement)?.offsetLeft ?? 0;

      setSlideIndex(nextSlideIndex);
      scrollTo(newOffset);
    }
  }, [isAtEnd, parentRef, slideIndex, scrollTo]);

  const previous = useCallback(() => {
    if (slideIndex > 0 && parentRef.current) {
      const prevSlideIndex = slideIndex - 1;
      const newOffset = (parentRef.current.children[prevSlideIndex] as HTMLElement)?.offsetLeft ?? 0;

      setSlideIndex(prevSlideIndex);
      scrollTo(newOffset);
    }
  }, [parentRef, slideIndex, scrollTo]);

  const scrollToSlide = useCallback(
    (index: number) => {
      const amountOfSlides = parentRef.current?.children.length ?? 0;

      if (index < amountOfSlides && index >= 0 && parentRef.current) {
        const newOffset = (parentRef.current.children[index] as HTMLElement)?.offsetLeft ?? 0;

        setSlideIndex(index);
        scrollTo(newOffset);
      }
    },
    [parentRef, scrollTo]
  );

  // Automatic scrolling
  useEffect(() => {
    /**
     * Because this hook will be called every time the slide changes (or another value), we can't reliably
     * use setInterval, as it will be restarted every time anyway. Instead, we use setTimeout, and calculate
     * the time that has passed since the last iteration, and subtract that from the interval.
     */
    clearTimeout(timeout.current);

    if (mergedOptions.isAutomatic && !mergedOptions.isPaused) {
      let timeoutLength = mergedOptions.interval - (Date.now() - prevAutoSlideTime);

      if (timeoutLength < 0) {
        timeoutLength = 0;
      }

      timeout.current = setTimeout(() => {
        if (!isAtEnd) {
          next();
        } else {
          setSlideIndex(0);
          scrollTo(0);
        }

        setPrevAutoSlideTime(Date.now());
      }, timeoutLength);
    }

    () => clearTimeout(timeout.current);
  }, [
    isAtEnd,
    mergedOptions.interval,
    mergedOptions.isAutomatic,
    mergedOptions.isPaused,
    next,
    prevAutoSlideTime,
    scrollTo,
  ]);

  // We want to reset the automatic timer after pausing
  useEffect(() => {
    if (!mergedOptions.isPaused) {
      setPrevAutoSlideTime(Date.now());
    }
    clearTimeout(timeout.current);
  }, [mergedOptions.isPaused]);

  return {
    next,
    previous,
    scrollToSlide,
    isPreviousDisabled: slideIndex === 0,
    isNextDisabled: isAtEnd,
    slideIndex,
    prevAutoSlideTime,
  };
};
