import { Icon, IconProps } from "../Icon";

const PersonDissatisfied = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 12.287 28.525 8.72601 25.8995 6.1005C23.274 3.475 19.713 2 16 2ZM11.5 11C10.1193 11 9 12.1193 9 13.5C9 14.8807 10.1193 16 11.5 16C12.8807 16 14 14.8807 14 13.5C14 12.837 13.7366 12.2011 13.2678 11.7322C12.7989 11.2634 12.163 11 11.5 11ZM20.5 11C19.1193 11 18 12.1193 18 13.5C18 14.8807 19.1193 16 20.5 16C21.8807 16 23 14.8807 23 13.5C23 12.1193 21.8807 11 20.5 11ZM9.15 22.89C10.5925 20.4811 13.1922 19.0047 16 19C18.8078 19.0047 21.4075 20.4811 22.85 22.89L21.14 23.89C20.0545 22.0874 18.1041 20.9852 16 20.9852C13.8959 20.9852 11.9455 22.0874 10.86 23.89L9.15 22.89ZM4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16Z"
      fill="currentColor"
    />
  </Icon>
);

export default PersonDissatisfied;
