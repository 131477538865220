import { Icon, IconProps } from "../Icon";

const LooksAggregator = (props: IconProps) => (
  <Icon width="40px" height="40px" viewBox="0 0 40 40" fill="none" {...props}>
    <g clipPath="url(#clip0_598_106403)">
      <circle cx="16.6667" cy="16.6669" r="16.6667" fill="#878D96" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8889 12.5003H10.2639C12.1084 9.66549 15.5974 8.38106 18.8397 9.34317C22.082 10.3053 24.3057 13.2849 24.3055 16.6669H25.6944C25.6983 12.8617 23.3158 9.46263 19.7376 8.16823C16.1593 6.87383 12.1536 7.96208 9.7222 10.8891V8.3336H8.33331V13.8891H13.8889V12.5003ZM19.4444 20.8336H23.0694C21.2248 23.6683 17.7358 24.9528 14.4935 23.9907C11.2512 23.0285 9.02753 20.049 9.02775 16.6669H7.63886C7.63494 20.4721 10.0174 23.8712 13.5957 25.1656C17.1739 26.46 21.1796 25.3717 23.611 22.4447V25.0002H24.9999V19.4447H19.4444V20.8336Z"
        fill="black"
      />
    </g>
    <g clipPath="url(#clip1_598_106403)">
      <circle cx="31.6667" cy="31.6666" r="8.33333" fill="#0CE466" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.6667 32.5676C30.7134 32.5676 29.94 31.7949 29.94 30.8409C29.94 29.8869 30.7134 29.1142 31.6667 29.1142C32.6199 29.1142 33.3934 29.8869 33.3934 30.8409C33.3934 31.7949 32.6199 32.5676 31.6667 32.5676ZM30.9159 30.8409C30.9159 31.2557 31.2522 31.5916 31.6667 31.5916C32.0811 31.5916 32.4174 31.2557 32.4174 30.8409C32.4174 30.4261 32.0811 30.0901 31.6667 30.0901C31.2522 30.0901 30.9159 30.4261 30.9159 30.8409Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.1111 30.8432L29.4144 27.5376H33.9189L37.2222 30.8432L31.6667 36.3964L26.1111 30.8432ZM34.2192 29.6397C32.8157 28.23 30.5177 28.23 29.1141 29.6397L27.9129 30.8409L29.1141 32.0421C30.5177 33.4518 32.8157 33.4518 34.2192 32.0421L35.4204 30.8409L34.2192 29.6397Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_598_106403">
        <rect width="33.3333" height="33.3333" fill="white" transform="translate(0 0.000244141)" />
      </clipPath>
      <clipPath id="clip1_598_106403">
        <rect width="16.6667" height="16.6667" fill="white" transform="translate(23.3334 23.3333)" />
      </clipPath>
    </defs>
  </Icon>
);

export default LooksAggregator;
