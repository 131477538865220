import { Box, BoxProps, useColorModeValue } from "@chakra-ui/react";

interface ScrollableRowProps extends BoxProps {
  maskWidth?: BoxProps["width"];
}

/**
 * Full width scrolling row with a transparent-to-uibg gradient mask on each side
 */
const ScrollableRow: React.FC<React.PropsWithChildren<ScrollableRowProps>> = ({ children, maskWidth, ...boxProps }) => {
  const outerColor = useColorModeValue("rgba(255,255,255,1) 100%", "#121619 100%");
  const innerColor = useColorModeValue("rgba(255,255,255,0) 0%", "rgba(18, 22, 25, 0) 0%");
  return (
    <Box width="100vw" position="relative" {...boxProps}>
      <Box
        zIndex={1}
        left={0}
        top={0}
        bottom={0}
        position="absolute"
        width={maskWidth}
        bg={`linear-gradient(to left, ${innerColor}, ${outerColor})`}
      />
      <Box
        zIndex={1}
        right={0}
        top={0}
        bottom={0}
        position="absolute"
        width={maskWidth}
        bg={`linear-gradient(to right, ${innerColor}, ${outerColor})`}
      />
      {children}
    </Box>
  );
};

export default ScrollableRow;

ScrollableRow.defaultProps = {
  maskWidth: "16px",
};
