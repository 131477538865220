import { Icon, IconProps } from "../Icon";

const HelpFilled = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 12.287 28.525 8.72601 25.8995 6.1005C23.274 3.475 19.713 2 16 2ZM16 25C15.3933 25 14.8464 24.6345 14.6142 24.074C14.382 23.5135 14.5103 22.8683 14.9393 22.4393C15.3683 22.0103 16.0135 21.882 16.574 22.1142C17.1345 22.3464 17.5 22.8933 17.5 23.5C17.5 24.3284 16.8284 25 16 25ZM17.14 17.25V19.75H14.89V15H17C18.2519 14.9187 19.2258 13.8796 19.2258 12.625C19.2258 11.3704 18.2519 10.3313 17 10.25H15.5C14.1895 10.25 13.1255 11.3095 13.12 12.62V13.26H10.89V12.62C10.8955 10.0668 12.9668 7.99999 15.52 8H17C19.4755 8.0784 21.4509 10.0911 21.483 12.5676C21.5152 15.0442 19.5926 17.1074 17.12 17.25H17.14Z"
      fill="currentColor"
    />
  </Icon>
);

export default HelpFilled;
