/**
 * Return points multiplier based on seconds elapsed as percent of total round time
 */
export const getPointsMultiplier = (
  secondsElapsedAsPercent: number,
  multiplierThresholds: Record<number, number>,
  defaultMultiplier = 1
): number => {
  let thresholdMultiplier = defaultMultiplier;

  for (const [multiplier, threshold] of Object.entries(multiplierThresholds)) {
    const numberMultiplier = Number(multiplier);

    if (secondsElapsedAsPercent <= threshold && numberMultiplier > thresholdMultiplier) {
      thresholdMultiplier = numberMultiplier;
    }
  }

  return thresholdMultiplier;
};
