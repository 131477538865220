import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { ChainId } from "@looksrare/sdk-v2";

interface AccordionIndexArgs {
  initialIndex?: number | number[];
  switchOnNetworkChange?: boolean;
}

const getIndex = (chainId?: ChainId) => {
  switch (chainId) {
    case ChainId.MAINNET:
    case ChainId.SEPOLIA:
      return [1];
    case ChainId.ARB_MAINNET:
    case ChainId.ARB_SEPOLIA:
    default:
      return [0];
  }
};

export const useAccordionIndex = (args?: AccordionIndexArgs) => {
  const { initialIndex = [0], switchOnNetworkChange = false } = args || {};
  const { isConnected, chain } = useAccount();
  const [accordionIndex, setAccordionIndex] = useState<number | number[]>(initialIndex);

  const handleChange = (expandedIndex: number | number[]) => setAccordionIndex(expandedIndex);

  useEffect(() => {
    if (isConnected && switchOnNetworkChange) {
      setAccordionIndex(getIndex(chain?.id));
    }
  }, [switchOnNetworkChange, chain, setAccordionIndex, isConnected]);

  return { accordionIndex, handleChange };
};
