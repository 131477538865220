import { HStack } from "@chakra-ui/react";
import { ArrowDownRightIcon, ArrowUpRightIcon, EthHalfIcon, Text } from "@looksrare/uikit";
import { formatToSignificant } from "@looksrare/utils";
import { MoDEntryFragment } from "@looksrare/yolo-games-gql-typegen";

interface MoDHistoryYourEntryProps {
  userEntry?: MoDEntryFragment;
}

export const MoDHistoryYourEntry = ({ userEntry }: MoDHistoryYourEntryProps) => {
  if (!userEntry) {
    return (
      <Text textStyle="detail" textAlign="end" color="text-02">
        -
      </Text>
    );
  }

  return (
    <HStack spacing={1} justifyContent="end">
      <Text textStyle="detail" color="text-02">
        {formatToSignificant(userEntry.amount)}
      </Text>

      <EthHalfIcon height={4} width={2} />

      {userEntry.moonPosition ? (
        <ArrowUpRightIcon boxSize={4} color="moondoom-moon" />
      ) : (
        <ArrowDownRightIcon boxSize={4} color="moondoom-doom" />
      )}
    </HStack>
  );
};
