import "viem/window";

export interface AddTokenToWalletParams {
  tokenAddress: string;
  tokenSymbol?: string;
  tokenDecimals?: number;
  tokenImageSrc?: string;
}
/**
 * Prompt the user to add a custom token to metamask
 * The wallet checks on the contract address the symbol and decimals: providing them is optional
 */
export const addTokenToWallet = async ({
  tokenAddress,
  tokenSymbol,
  tokenDecimals,
  tokenImageSrc,
}: AddTokenToWalletParams) => {
  await window.ethereum?.request({
    method: "wallet_watchAsset",
    params: {
      type: "ERC20",
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: tokenImageSrc,
      },
    },
  });
};
