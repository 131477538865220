import { Icon, IconProps } from "../Icon";

const Group = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 14H24V16H26C27.6562 16.0017 28.9983 17.3438 29 19V23H31V19C30.9967 16.2399 28.7601 14.0033 26 14ZM24 4C25.6569 4 27 5.34315 27 7C27 8.65685 25.6569 10 24 10C22.3431 10 21 8.65685 21 7C21 5.34315 22.3431 4 24 4ZM24 2C21.2386 2 19 4.23858 19 7C19 9.76142 21.2386 12 24 12C26.7614 12 29 9.76142 29 7C29 5.67392 28.4732 4.40215 27.5355 3.46447C26.5979 2.52678 25.3261 2 24 2ZM23 30H21V28C20.9983 26.3438 19.6562 25.0017 18 25H14C12.3438 25.0017 11.0017 26.3438 11 28V30H9V28C9.00331 25.2399 11.2399 23.0033 14 23H18C20.7601 23.0033 22.9967 25.2399 23 28V30ZM16 13C17.6569 13 19 14.3431 19 16C19 17.6569 17.6569 19 16 19C14.3431 19 13 17.6569 13 16C13 14.3431 14.3431 13 16 13ZM16 11C13.2386 11 11 13.2386 11 16C11 18.7614 13.2386 21 16 21C18.7614 21 21 18.7614 21 16C21 13.2386 18.7614 11 16 11ZM8 14H6C3.23995 14.0033 1.00331 16.2399 1 19V23H3V19C3.00165 17.3438 4.34383 16.0017 6 16H8V14ZM8 4C9.65685 4 11 5.34315 11 7C11 8.65685 9.65685 10 8 10C6.34315 10 5 8.65685 5 7C5 5.34315 6.34315 4 8 4ZM8 2C5.23858 2 3 4.23858 3 7C3 9.76142 5.23858 12 8 12C10.7614 12 13 9.76142 13 7C13 5.67392 12.4732 4.40215 11.5355 3.46447C10.5979 2.52678 9.32608 2 8 2Z"
      fill="currentColor"
    />
  </Icon>
);

export default Group;
