import { Icon, IconProps } from "../Icon";

const VolumeUpFilled = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.16 8.07995L25.63 9.36995C28.8823 13.2225 28.758 18.8936 25.34 22.5999L26.81 23.9999C30.9145 19.5523 31.0646 12.7442 27.16 8.11995V8.07995ZM17.29 29.6999C17.4771 29.8908 17.7328 29.9988 18 29.9999C18.5523 29.9999 19 29.5522 19 28.9999V2.99995C18.9962 2.73618 18.8884 2.48458 18.7 2.29995C18.31 1.91222 17.68 1.91222 17.29 2.29995L9.67 9.99995H3C2.44772 9.99995 2 10.4477 2 10.9999V20.9999C2 21.5522 2.44772 21.9999 3 21.9999H9.67L17.29 29.6999ZM21.4 19.9399C23.4537 17.7174 23.5309 14.3133 21.58 11.9999L23.1 10.7099C25.7059 13.7925 25.6073 18.3335 22.87 21.2999L21.4 19.9399Z"
      fill="currentColor"
    />
  </Icon>
);

export default VolumeUpFilled;
