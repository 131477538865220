import { Box, FlexProps } from "@chakra-ui/react";
import { useCurrentGameStatus } from "../../hooks";
import { BearAnimation } from "../BearAnimation";
import { GameActions } from "../GameActions";
import { NextRoundCountdown } from "../NextRoundCountdown";
import { Panel, PanelContent } from "../layout";
import { MuteButton } from "../MuteButton";
import { usePtbConfig } from "../..";
import { RoundLogs } from "./RoundLogs";
import { GamePlayHeader } from "./GamePlayHeader";

export const GamePlay = (props: FlexProps) => {
  const gameStatus = useCurrentGameStatus();
  const { showLocalMuteButton } = usePtbConfig();

  return (
    <Panel height="auto" {...props}>
      <GamePlayHeader />
      <PanelContent>
        <BearAnimation />
        <RoundLogs />
        {gameStatus === "finished" && <NextRoundCountdown />}
        {showLocalMuteButton && (
          <Box position="absolute" top={4} right={4} zIndex={2}>
            <MuteButton size="xs" colorScheme="secondary" />
          </Box>
        )}
      </PanelContent>
      <GameActions />
    </Panel>
  );
};
