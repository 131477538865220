import { Icon, IconProps } from "../Icon";

const Handshake = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.5001 15.8679L31.3416 13.4472L26.4471 3.65833L19.7638 6.99997L14.4999 6.99997C12.2321 6.99997 10.3561 8.67753 10.0452 10.8594L6.10566 12.8292C5.42809 13.1679 5.00009 13.8605 5.00009 14.618V17.3819L0.658447 19.5528L5.55287 29.3416L12.2362 26H18.564C19.1202 26 19.6512 25.7684 20.0296 25.3609L25.9657 18.9682C26.3092 18.5983 26.5001 18.1121 26.5001 17.6073V15.8679ZM26.5001 13.6318L28.6583 12.5528L25.5527 6.34161L20.6583 8.78882C20.3806 8.92768 20.0743 8.99997 19.7638 8.99997H14.4999C13.1292 8.99997 12.0161 10.1031 12.0001 11.47V17H14.0001C14.5524 17 15.0001 16.5523 15.0001 16V11.5H24.5001C25.6047 11.5 26.5001 12.3954 26.5001 13.5V13.6318ZM7.00009 14.618L10.0001 13.118V19H14.0001C15.6569 19 17.0001 17.6568 17.0001 16V13.5H24.5001V17.6073L18.564 24H11.764L6.4473 26.6583L3.34173 20.4472L7.00009 18.618V14.618Z"
      fill="currentColor"
    />
  </Icon>
);

export default Handshake;
