import { fontSizes } from "@looksrare/chakra-theme";

const fieldProps = {
  color: "text-01",
  border: "none",
  borderBottom: "none",
  borderRadius: "button",
  transition: "all 200ms ease-out",
  _placeholder: { color: "text-placeholder" },
  _disabled: { color: "text-disabled", bg: "none", shadow: "border-01", opacity: 1 },
  px: 4,
  _readOnly: {
    // Removing !important on the Chakra version so we can override it in the date picker
    boxShadow: "none",
  },
  _focus: {
    transition: "none",
    boxShadow: "none", // unsetting first, before assigning shadow: focus
    shadow: "focus",
  },
  _focusVisible: {
    transition: "none",
    boxShadow: "none",
    shadow: "focus",
  },
  _invalid: {
    boxShadow: "none",
    shadow: "error",
  },
};

export const InputTheme = {
  baseStyle: {
    field: fieldProps,
  },
  sizes: {
    md: {
      field: {
        height: "3rem",
        borderRadius: "button",
        fontSize: fontSizes["md"],
      },
      addon: {
        height: "3rem",
      },
    },
    sm: {
      field: {
        height: "2.5rem",
        borderRadius: "button",
        fontSize: fontSizes["md"],
      },
      addon: {
        height: "2.5rem",
      },
    },
    xs: {
      field: {
        height: "2rem",
        borderRadius: "button",
        fontSize: fontSizes["md"],
      },
      addon: {
        height: "2rem",
      },
    },
  },
  variants: {
    flushed: {
      field: {
        bg: "field-02",
        ...fieldProps,
      },
    },
    lowcontrast: {
      field: {
        bg: "field-01",
        ...fieldProps,
      },
    },
  },
  defaultProps: {
    variant: "flushed",
    size: "sm",
  },
};
