import { Trans } from "next-i18next";
import { PtbRoundLogType } from "../../../../types";
import { LogText, LogTextHighlight } from "./LogText";
import type { LogTextProps, RoundLogTextGetter } from "./types";

const PotSplitText1 = ({ user1, proceeds, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.potSplit1">
      The survivors take an equal share of{" "}
      <LogTextHighlight>
        <>{{ user1 }}&apos;s</>
      </LogTextHighlight>{" "}
      entry fee — a cut of{" "}
      <LogTextHighlight>
        <>{{ proceeds }}</>
      </LogTextHighlight>{" "}
      each.
    </Trans>
  </LogText>
);

export const potSplitLogs: RoundLogTextGetter = ({ user1, proceeds }) => [
  <PotSplitText1 key={`${PtbRoundLogType.POT_SPLIT}1`} user1={user1} proceeds={proceeds} />,
];
