import { ModalLegacy, ModalLegacyProps } from "@looksrare/uikit";
import { TermsOfService } from "./TermsOfService";

export const TermsOfServiceModal = ({ onClose, ...props }: Omit<ModalLegacyProps, "children">) => {
  return (
    <ModalLegacy
      closeOnEsc={false}
      variant="rainbow"
      closeOnOverlayClick={false}
      onClose={onClose}
      hideHeader
      {...props}
    >
      <TermsOfService handleNext={onClose} />
    </ModalLegacy>
  );
};
