import { keyframes } from "@chakra-ui/system";
import { getColor, SystemStyleFunction } from "@chakra-ui/theme-tools";

const fade = (startColor: string, endColor: string) =>
  keyframes({
    from: { borderColor: startColor, background: startColor },
    to: { borderColor: endColor, background: endColor },
  });

const baseStyles: SystemStyleFunction = (props) => {
  const { startColor, endColor, speed, theme } = props;

  const defaultStartColor = "gray.400";
  const defaultEndColor = "gray.600";

  const start = getColor(theme, startColor || defaultStartColor);
  const end = getColor(theme, endColor || defaultEndColor);

  return {
    opacity: 0.2,
    borderColor: start,
    background: end,
    animation: `${speed}s linear infinite alternate ${fade(start, end)}`,
  };
};

export const SkeletonTheme = { baseStyles };
