import { Icon, IconProps } from "../Icon";

const Send = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.71 4.28998C27.4345 4.01573 27.025 3.92603 26.66 4.05998L4.66 12.06C4.27154 12.2073 4.01465 12.5795 4.01465 12.995C4.01465 13.4104 4.27154 13.7826 4.66 13.93L14.26 17.77L18.1 27.37C18.2475 27.7407 18.6012 27.9883 19 28C19.4137 27.9915 19.7794 27.7291 19.92 27.34L27.92 5.33998C28.0591 4.97883 27.9773 4.56986 27.71 4.28998ZM19 24.2L16.21 17.2L21 12.41L19.59 11L14.76 15.83L7.8 13L25.33 6.66998L19 24.2Z"
      fill="currentColor"
    />
  </Icon>
);

export default Send;
