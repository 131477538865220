import { Icon, IconProps } from "@looksrare/uikit";

const Mauled = (props: IconProps) => (
  <Icon width="32px" height="32px" viewBox="0 0 32px 32px" fill="none" {...props}>
    <g clipPath="url(#clip0_340_69173)">
      <path
        d="M16 2C13.2311 2 10.5243 2.82109 8.22202 4.35943C5.91973 5.89777 4.12532 8.08427 3.06569 10.6424C2.00607 13.2006 1.72882 16.0155 2.26901 18.7313C2.80921 21.447 4.14258 23.9416 6.10051 25.8995C8.05845 27.8574 10.553 29.1908 13.2687 29.731C15.9845 30.2712 18.7994 29.9939 21.3576 28.9343C23.9157 27.8747 26.1022 26.0803 27.6406 23.778C29.1789 21.4757 30 18.7689 30 16C30 12.287 28.525 8.72601 25.8995 6.1005C23.274 3.475 19.713 2 16 2ZM16 28C13.6266 28 11.3066 27.2962 9.33316 25.9776C7.35977 24.6591 5.8217 22.7849 4.91345 20.5922C4.0052 18.3995 3.76756 15.9867 4.23058 13.6589C4.69361 11.3311 5.83649 9.19295 7.51472 7.51472C9.19296 5.83649 11.3312 4.6936 13.6589 4.23058C15.9867 3.76755 18.3995 4.00519 20.5922 4.91345C22.7849 5.8217 24.6591 7.35977 25.9776 9.33316C27.2962 11.3065 28 13.6266 28 16C28 19.1826 26.7357 22.2348 24.4853 24.4853C22.2348 26.7357 19.1826 28 16 28Z"
        fill="url(#paint0_linear_340_69173)"
      />
      <path
        d="M24.4098 11L22.9998 9.59L20.9998 11.59L18.9998 9.59L17.5898 11L19.5898 13L17.5898 15L18.9998 16.41L20.9998 14.41L22.9998 16.41L24.4098 15L22.4098 13L24.4098 11Z"
        fill="url(#paint1_linear_340_69173)"
      />
      <path
        d="M14.4098 11L12.9998 9.59L10.9998 11.59L8.99984 9.59L7.58984 11L9.58984 13L7.58984 15L8.99984 16.41L10.9998 14.41L12.9998 16.41L14.4098 15L12.4098 13L14.4098 11Z"
        fill="url(#paint2_linear_340_69173)"
      />
      <path
        d="M16 19C15.4067 19 14.8266 19.1759 14.3333 19.5056C13.8399 19.8352 13.4554 20.3038 13.2284 20.8519C13.0013 21.4001 12.9419 22.0033 13.0576 22.5853C13.1734 23.1672 13.4591 23.7018 13.8787 24.1213C14.2982 24.5409 14.8328 24.8266 15.4147 24.9424C15.9967 25.0581 16.5999 24.9987 17.1481 24.7716C17.6962 24.5446 18.1648 24.1601 18.4944 23.6667C18.8241 23.1734 19 22.5933 19 22C19 21.2044 18.6839 20.4413 18.1213 19.8787C17.5587 19.3161 16.7957 19 16 19Z"
        fill="url(#paint3_linear_340_69173)"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_340_69173" x1="16" y1="2" x2="16" y2="30" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FA4D56" />
        <stop offset="1" stopColor="#7B00A6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_340_69173"
        x1="20.9998"
        y1="9.59"
        x2="20.9998"
        y2="16.41"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FA4D56" />
        <stop offset="1" stopColor="#7B00A6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_340_69173"
        x1="10.9998"
        y1="9.59"
        x2="10.9998"
        y2="16.41"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FA4D56" />
        <stop offset="1" stopColor="#7B00A6" />
      </linearGradient>
      <linearGradient id="paint3_linear_340_69173" x1="16" y1="19" x2="16" y2="25" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FA4D56" />
        <stop offset="1" stopColor="#7B00A6" />
      </linearGradient>
      <clipPath id="clip0_340_69173">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

export default Mauled;
