export const GameConfigManagerAbi = [
  {
    type: "constructor",
    inputs: [
      {
        name: "_owner",
        type: "address",
        internalType: "address",
      },
      {
        name: "_weth",
        type: "address",
        internalType: "address",
      },
      {
        name: "_blast",
        type: "address",
        internalType: "address",
      },
      {
        name: "_vrfFeeRecipient",
        type: "address",
        internalType: "address",
      },
      {
        name: "_protocolFeeRecipient",
        type: "address",
        internalType: "address",
      },
      {
        name: "_vrfCoordinator",
        type: "address",
        internalType: "address",
      },
      {
        name: "_keyHash",
        type: "bytes32",
        internalType: "bytes32",
      },
      {
        name: "_subscriptionId",
        type: "uint64",
        internalType: "uint64",
      },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "GAME_LIQUIDITY_POOL_CONNECTION_TIMELOCK",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "cancelOwnershipTransfer",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "confirmGameLiquidityPoolConnectionRequest",
    inputs: [
      {
        name: "game",
        type: "address",
        internalType: "address",
      },
      {
        name: "currency",
        type: "address",
        internalType: "address",
      },
      {
        name: "liquidityPool",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "confirmOwnershipRenouncement",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "confirmOwnershipTransfer",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "disconnectGameFromLiquidityPool",
    inputs: [
      {
        name: "game",
        type: "address",
        internalType: "address",
      },
      {
        name: "currency",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "elapsedTimeRequiredForRefund",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint40",
        internalType: "uint40",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "gameLiquidityPoolConnectionRequests",
    inputs: [
      {
        name: "requestId",
        type: "bytes32",
        internalType: "bytes32",
      },
    ],
    outputs: [
      {
        name: "requestedAt",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getFeeSplit",
    inputs: [
      {
        name: "game",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "",
        type: "tuple",
        internalType: "struct IGameConfigurationManager.FeeSplit",
        components: [
          {
            name: "protocolFeeBasisPoints",
            type: "uint16",
            internalType: "uint16",
          },
          {
            name: "liquidityPoolFeeBasisPoints",
            type: "uint16",
            internalType: "uint16",
          },
        ],
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getGameLiquidityPool",
    inputs: [
      {
        name: "game",
        type: "address",
        internalType: "address",
      },
      {
        name: "currency",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "liquidityPool",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "initiateGameLiquidityPoolConnectionRequest",
    inputs: [
      {
        name: "game",
        type: "address",
        internalType: "address",
      },
      {
        name: "currency",
        type: "address",
        internalType: "address",
      },
      {
        name: "liquidityPool",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "initiateOwnershipRenouncement",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "initiateOwnershipTransfer",
    inputs: [
      {
        name: "newPotentialOwner",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "kellyFractionBasisPoints",
    inputs: [
      {
        name: "game",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "basisPoints",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "maximumNumberOfRounds",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint16",
        internalType: "uint16",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "owner",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "ownershipStatus",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint8",
        internalType: "enum IOwnableTwoSteps.Status",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "potentialOwner",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "protocolFeeRecipient",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "setElapsedTimeRequiredForRefund",
    inputs: [
      {
        name: "_elapsedTimeRequiredForRefund",
        type: "uint40",
        internalType: "uint40",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setFeeSplit",
    inputs: [
      {
        name: "_game",
        type: "address",
        internalType: "address",
      },
      {
        name: "_protocolFeeBasisPoints",
        type: "uint16",
        internalType: "uint16",
      },
      {
        name: "_liquidityPoolFeeBasisPoints",
        type: "uint16",
        internalType: "uint16",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setGameKellyFractionBasisPoints",
    inputs: [
      {
        name: "game",
        type: "address",
        internalType: "address",
      },
      {
        name: "basisPoints",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setMaximumNumberOfRounds",
    inputs: [
      {
        name: "_maximumNumberOfRounds",
        type: "uint16",
        internalType: "uint16",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setProtocolFeeRecipient",
    inputs: [
      {
        name: "_protocolFeeRecipient",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setVrfFeeRecipient",
    inputs: [
      {
        name: "_vrfFeeRecipient",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "setVrfParameters",
    inputs: [
      {
        name: "_vrfParameters",
        type: "tuple",
        internalType: "struct GameConfigurationManager.VrfParameters",
        components: [
          {
            name: "coordinator",
            type: "address",
            internalType: "address",
          },
          {
            name: "subscriptionId",
            type: "uint64",
            internalType: "uint64",
          },
          {
            name: "callbackGasLimit",
            type: "uint32",
            internalType: "uint32",
          },
          {
            name: "minimumRequestConfirmations",
            type: "uint16",
            internalType: "uint16",
          },
          {
            name: "vrfFee",
            type: "uint240",
            internalType: "uint240",
          },
          {
            name: "keyHash",
            type: "bytes32",
            internalType: "bytes32",
          },
        ],
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "transferPayoutToPlayer",
    inputs: [
      {
        name: "currency",
        type: "address",
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "receiver",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "transferProtocolFee",
    inputs: [
      {
        name: "currency",
        type: "address",
        internalType: "address",
      },
      {
        name: "amount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "vrfFeeRecipient",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "vrfParameters",
    inputs: [],
    outputs: [
      {
        name: "coordinator",
        type: "address",
        internalType: "address",
      },
      {
        name: "subscriptionId",
        type: "uint64",
        internalType: "uint64",
      },
      {
        name: "callbackGasLimit",
        type: "uint32",
        internalType: "uint32",
      },
      {
        name: "minimumRequestConfirmations",
        type: "uint16",
        internalType: "uint16",
      },
      {
        name: "vrfFee",
        type: "uint240",
        internalType: "uint240",
      },
      {
        name: "keyHash",
        type: "bytes32",
        internalType: "bytes32",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "event",
    name: "CancelOwnershipTransfer",
    inputs: [],
    anonymous: false,
  },
  {
    type: "event",
    name: "ElapsedTimeRequiredForRefundUpdated",
    inputs: [
      {
        name: "_elapsedTimeRequiredForRefund",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "FeeSplitUpdated",
    inputs: [
      {
        name: "game",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "protocolFeeBasisPoints",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "liquidityPoolFeeBasisPoints",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "GameAndLiquidityPoolConnected",
    inputs: [
      {
        name: "game",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "currency",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "liquidityPool",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "GameAndLiquidityPoolConnectionRequestInitiated",
    inputs: [
      {
        name: "game",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "currency",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "liquidityPool",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "GameAndLiquidityPoolDisconnected",
    inputs: [
      {
        name: "game",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "currency",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "liquidityPool",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "GameKellyFractionBasisPointsUpdated",
    inputs: [
      {
        name: "game",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "basisPoints",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "InitiateOwnershipRenouncement",
    inputs: [],
    anonymous: false,
  },
  {
    type: "event",
    name: "InitiateOwnershipTransfer",
    inputs: [
      {
        name: "previousOwner",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "potentialOwner",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "MaximumNumberOfRoundsUpdated",
    inputs: [
      {
        name: "_maximumNumberOfRounds",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "NewOwner",
    inputs: [
      {
        name: "newOwner",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "ProtocolFeeRecipientUpdated",
    inputs: [
      {
        name: "_protocolFeeRecipient",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "VrfFeeRecipientUpdated",
    inputs: [
      {
        name: "_vrfFeeRecipient",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "VrfParametersUpdated",
    inputs: [
      {
        name: "coordinator",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "subscriptionId",
        type: "uint64",
        indexed: false,
        internalType: "uint64",
      },
      {
        name: "callbackGasLimit",
        type: "uint32",
        indexed: false,
        internalType: "uint32",
      },
      {
        name: "minimumRequestConfirmations",
        type: "uint16",
        indexed: false,
        internalType: "uint16",
      },
      {
        name: "vrfFee",
        type: "uint240",
        indexed: false,
        internalType: "uint240",
      },
      {
        name: "keyHash",
        type: "bytes32",
        indexed: false,
        internalType: "bytes32",
      },
    ],
    anonymous: false,
  },
  {
    type: "error",
    name: "GameConfigurationManager__BasisPointsTooHigh",
    inputs: [],
  },
  {
    type: "error",
    name: "GameConfigurationManager__ElapsedTimeRequiredForRefundIsTooLong",
    inputs: [],
  },
  {
    type: "error",
    name: "GameConfigurationManager__ElapsedTimeRequiredForRefundIsTooShort",
    inputs: [],
  },
  {
    type: "error",
    name: "GameConfigurationManager__GameIsNotAllowed",
    inputs: [
      {
        name: "game",
        type: "address",
        internalType: "address",
      },
      {
        name: "currency",
        type: "address",
        internalType: "address",
      },
    ],
  },
  {
    type: "error",
    name: "GameConfigurationManager__GameLiquidityPoolConnectionRequestConfirmationIsTooEarly",
    inputs: [],
  },
  {
    type: "error",
    name: "GameConfigurationManager__GameLiquidityPoolCurrencyMismatch",
    inputs: [],
  },
  {
    type: "error",
    name: "GameConfigurationManager__NoGameLiquidityPoolConnectionRequest",
    inputs: [],
  },
  {
    type: "error",
    name: "GameConfigurationManager__VrfCallbackGasLimitTooLow",
    inputs: [],
  },
  {
    type: "error",
    name: "GameConfigurationManager__VrfMinimumRequestConfirmationsTooLow",
    inputs: [],
  },
  {
    type: "error",
    name: "NoOngoingTransferInProgress",
    inputs: [],
  },
  {
    type: "error",
    name: "NotOwner",
    inputs: [],
  },
  {
    type: "error",
    name: "RenouncementNotInProgress",
    inputs: [],
  },
  {
    type: "error",
    name: "TransferAlreadyInProgress",
    inputs: [],
  },
  {
    type: "error",
    name: "TransferNotInProgress",
    inputs: [],
  },
  {
    type: "error",
    name: "WrongPotentialOwner",
    inputs: [],
  },
] as const;
