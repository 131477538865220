import { Icon, IconProps } from "../Icon";

const BackToStart = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 16L23 26L24.41 24.59L15.83 16L24.41 7.41L23 6L13 16ZM7 28L7 4L9 4L9 28L7 28Z"
      fill="currentColor"
    />
  </Icon>
);

export default BackToStart;
