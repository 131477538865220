import { useQuery } from "@tanstack/react-query";
import { usePublicClient } from "wagmi";
import { MoonOrDoomNativeTokenAbi } from "@looksrare/config";
import { NoPublicClientError, type RQueryOptions } from "@looksrare/utils";
import { MoDContract } from "@looksrare/yolo-games-gql-typegen";
import { useMoDContractAddress } from "../../../hooks/useMoDContractAddress";

export const MOONDOOM_IS_PAUSED_QUERY_KEY = ["moondoom-is-paused"];

export const useIsPaused = (contract: MoDContract, queryOptions?: RQueryOptions<boolean>) => {
  const publicClient = usePublicClient();
  const contractAddress = useMoDContractAddress(contract);

  return useQuery({
    queryKey: MOONDOOM_IS_PAUSED_QUERY_KEY,
    queryFn: async () => {
      if (!publicClient) {
        throw new NoPublicClientError();
      }
      const userMintedCount = await publicClient.readContract({
        address: contractAddress,
        abi: MoonOrDoomNativeTokenAbi,
        functionName: "paused",
      });

      return userMintedCount;
    },
    ...queryOptions,
  });
};
