import { Container, useGameSupportedNetworks } from "@looksrare/uikit";
import { BoxProps, GridItem } from "@chakra-ui/react";
import { PlayerList } from "../PlayerList";
import { ClaimPanel, SingleClaimPanel } from "../ClaimPanel";
import { Panel, PanelContent } from "../layout";
import { useAccordionIndex } from "../../hooks";
import { CavesAccordion } from "../CavesAccordion";
import { GamePlay } from "./GamePlay";
import { PlayerListPanelHeader } from "./PlayerListPanelHeader";
import { SingleCaveList } from "./SingleCaveList";

type CaveDesktopProps = BoxProps;

export const CaveDesktop = (props: CaveDesktopProps) => {
  const { accordionIndex, handleChange } = useAccordionIndex({ initialIndex: [0, 1] });
  const supportedNetworks = useGameSupportedNetworks();
  return (
    <Container
      display="grid"
      maxWidth="1600px"
      gridColumnGap={8}
      gridRowGap={4}
      gridTemplateColumns={{ lg: "288px minmax(0, 1fr)", "2xl": "288px minmax(0, 1fr) 288px" }}
      gridTemplateAreas={{
        lg: `'sidebar gameplay' 'sidebar players' 'sidebar claim'`,
        "2xl": "'sidebar gameplay players'",
      }}
      {...props}
    >
      {supportedNetworks.length === 1 ? (
        <SingleCaveList gridArea="sidebar" network={supportedNetworks[0]} />
      ) : (
        <CavesAccordion
          gridArea="sidebar"
          accordionProps={{ index: accordionIndex, onChange: handleChange, allowMultiple: true }}
          height="auto"
        />
      )}
      <GridItem gridArea="gameplay">
        <GamePlay />
      </GridItem>
      <GridItem gridArea="players">
        <Panel height="auto">
          <PlayerListPanelHeader />
          <PanelContent p={2}>
            <PlayerList />
          </PanelContent>
        </Panel>
        {supportedNetworks.length === 1 ? <SingleClaimPanel network={supportedNetworks[0]} /> : <ClaimPanel />}
      </GridItem>
    </Container>
  );
};
