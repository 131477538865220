import { zeroAddress } from "viem";
import { useTranslation } from "next-i18next";
import { Box, Grid, HStack, Skeleton, UseDisclosureReturn } from "@chakra-ui/react";
import { InformationCircleIcon, Popover, Text, TooltipText } from "@looksrare/uikit";
import { formatToSignificant } from "@looksrare/utils";
import { useActiveCaveRoundInfo, useGetCaveQueryParams } from "../../hooks";
import { CurrencyTokenIcon } from "../CurrencyTokenIcon";
import { PlayerListButton } from "./PlayerListButton";

interface RoundInfoProps {
  onOpenPlayerList: UseDisclosureReturn["onOpen"];
}

export const RoundInfo = ({ onOpenPlayerList }: RoundInfoProps) => {
  const { t } = useTranslation();
  const { network } = useGetCaveQueryParams();
  const activeCaveRoundQuery = useActiveCaveRoundInfo();
  const totalPrizePool = activeCaveRoundQuery.data?.meta.totalPrizePool || 0n;
  const prizerPerPlayer = activeCaveRoundQuery.data?.meta.prizePerPlayerNet || 0n;
  const currency = activeCaveRoundQuery.data?.cave.currency || zeroAddress;

  return (
    <Grid alignItems="center" gridGap={2} gridTemplateColumns="40px minmax(0, 1fr) auto" p={4} bg="ui-bg" flex="none">
      {activeCaveRoundQuery.isLoading ? (
        <Skeleton height={10} width={10} borderRadius="circular" />
      ) : (
        <CurrencyTokenIcon network={network} currency={currency} boxSize={10} />
      )}
      <Box>
        <HStack spacing={2}>
          <Text bold>{formatToSignificant(totalPrizePool, 6)}</Text>
          <Popover
            variant="tooltip"
            label={
              <TooltipText>
                {t(
                  `ptb::When the round ends, this amount will be split equally among all the survivors (once the fee is subtracted).`
                )}
              </TooltipText>
            }
          >
            <HStack spacing={0.5}>
              <Text color="text-03" textStyle="detail">
                {t("ptb::Prize Pool")}
              </Text>
              <InformationCircleIcon color="text-03" boxSize={4} />
            </HStack>
          </Popover>
        </HStack>
        <Text color="text-03" textStyle="detail">
          {t("ptb::{{amount}} Per Winner", { amount: formatToSignificant(prizerPerPlayer, 6) })}
        </Text>
      </Box>
      <PlayerListButton onOpenPlayerList={onOpenPlayerList} />
    </Grid>
  );
};
