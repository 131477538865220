import { Icon, IconProps } from "../Icon";

const LevelsFilled = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 5.375C7.5 4.82272 7.94772 4.375 8.5 4.375H11.5C12.0523 4.375 12.5 4.82272 12.5 5.375V14.625C12.5 15.1773 12.0523 15.625 11.5 15.625H8.5C7.94772 15.625 7.5 15.1773 7.5 14.625V5.375ZM1.25 10.375C1.25 9.82272 1.69772 9.375 2.25 9.375H5.25C5.80228 9.375 6.25 9.82272 6.25 10.375V14.625C6.25 15.1773 5.80228 15.625 5.25 15.625H2.25C1.69772 15.625 1.25 15.1773 1.25 14.625V10.375ZM14.75 11.25C14.1977 11.25 13.75 11.6977 13.75 12.25V14.625C13.75 15.1773 14.1977 15.625 14.75 15.625H17.75C18.3023 15.625 18.75 15.1773 18.75 14.625V12.25C18.75 11.6977 18.3023 11.25 17.75 11.25H14.75Z"
      fill="currentColor"
    />
  </Icon>
);

export default LevelsFilled;
