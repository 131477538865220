import { Icon, IconProps } from "../Icon";

const LogoMono = (props?: IconProps) => (
  <Icon {...props}>
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 18.5946C13.2546 18.5946 11.027 16.3692 11.027 13.6216C11.027 10.874 13.2546 8.64863 16 8.64863C18.7454 8.64863 20.973 10.874 20.973 13.6216C20.973 16.3692 18.7454 18.5946 16 18.5946ZM13.8378 13.6216C13.8378 14.8162 14.8064 15.7838 16 15.7838C17.1936 15.7838 18.1622 14.8162 18.1622 13.6216C18.1622 12.427 17.1936 11.4594 16 11.4594C14.8064 11.4594 13.8378 12.427 13.8378 13.6216Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13.6282L9.51351 4.10809H22.4865L32 13.6282L16 29.6216L0 13.6282ZM23.3514 10.1621C19.3091 6.10213 12.6909 6.10215 8.64865 10.1621L5.18919 13.6216L8.64865 17.0811C12.6909 21.1411 19.3091 21.1411 23.3514 17.0811L26.8108 13.6216L23.3514 10.1621Z"
        fill="currentColor"
      />
    </g>
  </Icon>
);

export default LogoMono;
