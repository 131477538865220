import { Icon, IconProps } from "../Icon";

const ArrowUpRight = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 6V8H22.59L6 24.59L7.41 26L24 9.41V22H26V6H10Z"
      fill="currentColor"
    />
  </Icon>
);

export default ArrowUpRight;
