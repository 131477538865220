import { Stack, forwardRef } from "@chakra-ui/react";
import { ChartCandlestickIcon, ChartLineIcon } from "@looksrare/uikit";
import { MoDChart } from "../../../stores/modStore";

interface ChartSwitcherButtonProps {
  chartType: MoDChart;
  isSelected: boolean;
  onClick?: () => void;
}

export const ChartSwitcherButton = forwardRef<ChartSwitcherButtonProps, "div">(
  ({ chartType, isSelected, onClick }, ref) => {
    return (
      <Stack
        ref={ref}
        position="relative"
        p={1.5}
        onClick={onClick}
        color={isSelected ? "link-01" : "text-02"}
        cursor="pointer"
      >
        {chartType === "oracle" && <ChartLineIcon boxSize={5} transition="color 150ms" />}
        {chartType === "tradingview" && <ChartCandlestickIcon boxSize={5} transition="color 150ms" />}
      </Stack>
    );
  }
);
