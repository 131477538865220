import { Icon, IconProps } from "../Icon";

const EthHigh = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9954 16.2409L10.9979 2.9707L3 16.2414L10.998 20.9689L18.9954 16.2409ZM10.9977 22.4837L3 17.7588L10.998 29.0283L19 17.7588L10.9977 22.4837ZM26 6.8L29.6 10.4L31 9L25 3L19 9L20.4 10.4L24 6.8V17H26V6.8Z"
      fill="currentColor"
    />
  </Icon>
);

export default EthHigh;
