import { Icon, IconProps } from "../Icon";

const AsleepFilled = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2496 2.25H11.0996C6.1039 3.07781 2.55739 7.56949 2.911 12.621C3.2646 17.6725 7.40229 21.6263 12.4646 21.75H12.8321C16.0292 21.7519 19.0266 20.1958 20.8646 17.58C21.0194 17.3511 21.0357 17.0556 20.9072 16.811C20.7786 16.5664 20.5259 16.4123 20.2496 16.41C17.2164 16.1372 14.4818 14.4711 12.8485 11.9007C11.2151 9.3302 10.8682 6.14693 11.9096 3.285C12.0027 3.0591 11.9799 2.80201 11.8486 2.596C11.7172 2.39 11.4937 2.26089 11.2496 2.25Z"
      fill="currentColor"
    />
  </Icon>
);

export default AsleepFilled;
