import { Icon, IconProps } from "../Icon";

const Document = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <g clipPath="url(#clip0_766_19605)">
      <path
        d="M16.0625 5.8125L11.6875 1.4375C11.5625 1.3125 11.4375 1.25 11.25 1.25H5C4.3125 1.25 3.75 1.8125 3.75 2.5V17.5C3.75 18.1875 4.3125 18.75 5 18.75H15C15.6875 18.75 16.25 18.1875 16.25 17.5V6.25C16.25 6.0625 16.1875 5.9375 16.0625 5.8125ZM11.25 2.75L14.75 6.25H11.25V2.75ZM15 17.5H5V2.5H10V6.25C10 6.9375 10.5625 7.5 11.25 7.5H15V17.5Z"
        fill="currentColor"
      />
      <path d="M13.75 13.75H6.25V15H13.75V13.75Z" fill="currentColor" />
      <path d="M13.75 10H6.25V11.25H13.75V10Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_766_19605">
        <rect width="20" height="20" fill="currentColor" />
      </clipPath>
    </defs>
  </Icon>
);

export default Document;
