import { Icon, IconProps } from "../Icon";

const Gas = (props: IconProps) => (
  <Icon {...props}>
    <path d="M15 10H8V12H15V10Z" fill="currentColor" />
    <path
      d="M28.4142 8L23.4142 3L22 4.4141L25 7.4141V12C25.0006 12.5302 25.2115 13.0386 25.5865 13.4135C25.9614 13.7885 26.4698 13.9994 27 14V24.5C27 24.8978 26.842 25.2794 26.5607 25.5607C26.2794 25.842 25.8978 26 25.5 26C25.1022 26 24.7206 25.842 24.4393 25.5607C24.158 25.2794 24 24.8978 24 24.5V16C24 15.7348 23.8946 15.4804 23.7071 15.2929C23.5196 15.1054 23.2652 15 23 15H19V5C19 4.73478 18.8946 4.48043 18.7071 4.29289C18.5196 4.10536 18.2652 4 18 4H5C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V26H2V28H21V26H19V17H22V24.4C21.9883 25.2152 22.2564 26.0099 22.7596 26.6514C23.2628 27.2928 23.9708 27.7424 24.7654 27.9251C25.2759 28.0335 25.8041 28.0266 26.3116 27.9049C26.8191 27.7832 27.293 27.5497 27.6988 27.2215C28.1045 26.8933 28.4319 26.4787 28.657 26.0078C28.8821 25.537 28.9993 25.0219 29 24.5V9.4144C29 9.15172 28.9483 8.89162 28.8478 8.64893C28.7473 8.40625 28.5999 8.18574 28.4142 8ZM17 26H6V6H17V26Z"
      fill="currentColor"
    />
  </Icon>
);

export default Gas;
