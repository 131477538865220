import { Icon, IconProps } from "../Icon";

const ViewFilled = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 20C18.2091 20 20 18.2091 20 16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20ZM15.9999 5C22.662 5.25053 28.5359 9.4417 30.9399 15.66C31.0193 15.8797 31.0193 16.1203 30.9399 16.34C28.5359 22.5583 22.662 26.7495 15.9999 27C9.3378 26.7495 3.46386 22.5583 1.0599 16.34C0.980462 16.1203 0.980462 15.8797 1.0599 15.66C3.46386 9.4417 9.3378 5.25053 15.9999 5ZM9.5 16C9.5 19.5899 12.4102 22.5 16 22.5C19.5876 22.4945 22.4945 19.5876 22.5 16C22.5 12.4101 19.5899 9.5 16 9.5C12.4102 9.5 9.5 12.4101 9.5 16Z"
      fill="currentColor"
    />
  </Icon>
);

export default ViewFilled;
