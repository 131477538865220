import { Stack, StackProps } from "@chakra-ui/react";
import { MoDStatsFragment } from "@looksrare/yolo-games-gql-typegen";
import { RecentRoundsStats } from "./components/RecentRoundsStats";

interface RecentRoundsFeedProps extends StackProps {
  stats: MoDStatsFragment;
}

export const RecentRoundsFeed = ({ stats, ...props }: RecentRoundsFeedProps) => {
  return (
    <Stack direction={{ base: "column", md: "row" }} spacing={3} {...props}>
      <RecentRoundsStats period="Hour" periodResult={stats.oneHourResult} />
      <RecentRoundsStats period="Day" periodResult={stats.oneDayResult} />
    </Stack>
  );
};
