import { Icon, IconProps } from "../Icon";

const LockedFill = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 14H22V8C22 4.68629 19.3137 2 16 2C12.6863 2 10 4.68629 10 8V14H8C6.89543 14 6 14.8954 6 16V28C6 29.1046 6.89543 30 8 30H24C25.1046 30 26 29.1046 26 28V16C26 14.8954 25.1046 14 24 14ZM12 8C12 5.79086 13.7909 4 16 4C18.2091 4 20 5.79086 20 8V14H12V8ZM17 22.8293C18.1652 22.4175 19 21.3062 19 20C19 18.3431 17.6569 17 16 17C14.3431 17 13 18.3431 13 20C13 21.3062 13.8348 22.4175 15 22.8293V27H17V22.8293Z"
      fill="currentColor"
    />
  </Icon>
);

export default LockedFill;
