import { Box, ModalProps } from "@chakra-ui/react";
import { gradients } from "@looksrare/chakra-theme";
import { Modal } from "@looksrare/uikit";
import { ClaimPanel } from "../../ClaimPanel";
import type { ClaimFundsSuccessHandler } from "../../Modals/types";
import { TriggerButton } from "./TriggerButton";

interface ClaimFundsDisplayModalProps {
  isOpen: ModalProps["isOpen"];
  onClose: ModalProps["onClose"];
  onSuccess?: ClaimFundsSuccessHandler;
}

export const ClaimFundsDisplayModal = ({ isOpen, onClose, onSuccess }: ClaimFundsDisplayModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose} modalContentProps={{ overflow: "hidden" }}>
    <Box bgGradient={gradients["ui-gradient-v-01"]}>
      <TriggerButton isOpen onClick={onClose} />
      <Box p={4}>
        <ClaimPanel
          _before={undefined}
          border="1px solid"
          borderColor="border-01"
          accordionProps={{ defaultIndex: [0, 1], allowMultiple: true, allowToggle: false }}
          onSuccess={onSuccess}
        />
      </Box>
    </Box>
  </Modal>
);
