import { useBreakpointValue } from "@chakra-ui/react";
import { useWidgetBotOptions } from "@looksrare/uikit";
import { useWidgetBotCrate } from "@looksrare/utils";
import { MobileLobby } from "../components/CaveMobile/MobileLobby";
import { DesktopLobby } from "../components/CaveDesktop/DesktopLobby";

export const GameLobby = () => {
  useWidgetBotCrate(useWidgetBotOptions());

  const isMobile = useBreakpointValue({ base: true, lg: false });
  return isMobile ? <MobileLobby /> : <DesktopLobby />;
};
