import { Flex, Skeleton, Td, Tr } from "@chakra-ui/react";

export const RowSkeleton = () => {
  return (
    <Tr borderBottom="1px solid" borderBottomColor="border-01" borderLeft="transparent 4px" borderLeftColor="ui-01">
      <Td>
        <Flex justifyContent="center">
          <Skeleton height="18px" width="30%" />
        </Flex>
      </Td>
      <Td>
        <Skeleton width="50%" height="18px" />
      </Td>
      <Td>
        <Skeleton width="50%" height="18px" />
      </Td>
      <Td>
        <Flex justifyContent="flex-end" width="100%">
          <Skeleton width="50%" height="18px" />
        </Flex>
      </Td>
      <Td>
        <Flex justifyContent="flex-end" width="100%">
          <Skeleton width="50%" height="18px" />
        </Flex>
      </Td>
      <Td>
        <Flex justifyContent="flex-end" width="100%">
          <Skeleton width="50%" height="18px" />
        </Flex>
      </Td>
      <Td>
        <Flex flexDirection="column" gap={1} alignItems="flex-end">
          <Skeleton width="20%" height="18px" />
          <Skeleton width="40%" height="18px" />
        </Flex>
      </Td>
      <Td>
        <Skeleton width="50%" height="18px" />
      </Td>
    </Tr>
  );
};
