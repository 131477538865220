import React from "react";
import { useTranslation } from "next-i18next";
import { Grid, GridProps, Flex, InputProps, InputRightElement, InputGroup, Box } from "@chakra-ui/react";
import { type Currency } from "@looksrare/config";
import { parseInputToSafeDecimals, useGetCurrencyConfigBySymbol } from "@looksrare/utils";
import { Text } from "../Text";
import { Button } from "../Button";
import { NumberInput } from "./NumberInput";

interface CurrencyInputProps extends InputProps {
  price: string;
  setPrice: (inputValue: string) => void;
  onMax?: () => void;
  currency: Currency;
  info?: string;
  warning?: string;
  wrapperProps?: GridProps;
  label?: string;
}

export const CurrencyInput = ({
  price,
  setPrice,
  onMax,
  currency,
  warning,
  info,
  label,
  wrapperProps,
  ...props
}: CurrencyInputProps) => {
  const { t } = useTranslation();
  const getCurrencyConfigBySymbol = useGetCurrencyConfigBySymbol();
  const { icon: Icon, symbol } = getCurrencyConfigBySymbol(currency);

  return (
    <Box>
      {label && (
        <Text textStyle="detail" bold color="text-03" mb={1}>
          {label}
        </Text>
      )}
      <Grid templateColumns="1fr auto" templateRows={`1fr ${warning ? "auto" : "16px"}`} {...wrapperProps}>
        <InputGroup>
          <NumberInput
            data-test-id="currency-input-price-field"
            placeholder={t("Input price")}
            value={price}
            onTextChange={(newValue) => setPrice(parseInputToSafeDecimals(newValue))}
            isInvalid={!!warning}
            paddingRight={onMax ? "60px" : 4}
            borderRightRadius="0px"
            shadow="border-01"
            {...props}
          />
          {onMax && (
            <InputRightElement mr={2}>
              <Button size="xs" colorScheme="gray" variant="ghost" onClick={onMax}>
                {t("Max")}
              </Button>
            </InputRightElement>
          )}
        </InputGroup>
        <Flex
          alignItems="center"
          border="solid 1px"
          borderColor="border-01"
          borderLeft={0}
          px={4}
          borderRightRadius="button"
        >
          <Icon />
          <Text color="text-02">{symbol}</Text>
        </Flex>
        {warning && (
          <Text textStyle="helper" color="text-error" gridColumn="1" mt={2}>
            {warning}
          </Text>
        )}
        {info && (
          <Text textStyle="helper" color="text-02" gridColumn="1" mt={2}>
            {info}
          </Text>
        )}
      </Grid>
    </Box>
  );
};

export default CurrencyInput;
