import { Icon, IconProps } from "../Icon";

const ArrowDownRight = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 26V24H22.59L6 7.41L7.41 6L24 22.59V10H26V26H10Z"
      fill="currentColor"
    />
  </Icon>
);

export default ArrowDownRight;
