import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const TokenDroplets = (props: IconProps) => {
  const id = uniqueId();
  return (
    <Icon viewBox="0 0 96 96" {...props}>
      <g clipPath={`url(#${id})`}>
        <circle cx="48" cy="48" r="48" fill="#33DEBF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.5042 69.3658C57.6985 69.3658 65.9626 61.1017 65.9626 50.9075C65.9626 42.8723 60.7793 35.2173 55.8638 27.9579C52.1166 22.4239 48.5251 17.1197 47.5042 12.0523C46.4772 17.2236 42.8485 22.5707 39.077 28.1282C34.1817 35.3414 29.0459 42.9092 29.0459 50.9075C29.0459 61.1017 37.31 69.3658 47.5042 69.3658ZM71.8622 50.3172C71.8622 64.375 60.9566 75.7711 47.5039 75.7711C34.0512 75.7711 23.1456 64.375 23.1456 50.3172C23.1456 45.5079 24.422 41.0101 26.6401 37.1735C22.0975 42.176 19.3292 48.8189 19.3292 56.1083C19.3292 71.6688 31.9435 84.2831 47.504 84.2831C63.0645 84.2831 75.6788 71.6688 75.6788 56.1083C75.6788 48.8185 72.9103 42.1753 68.3673 37.1728C70.5856 41.0095 71.8622 45.5076 71.8622 50.3172Z"
          fill="#1F2A37"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default TokenDroplets;
