import { zeroAddress } from "viem";
import { useTranslation } from "next-i18next";
import { Box, Flex, HStack, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { CircularCountdown, ClockIcon, InformationCircleIcon, Popover, Text, TooltipText } from "@looksrare/uikit";
import { formatToSignificant } from "@looksrare/utils";
import { CurrencyValueDisplay } from "../CurrencyValueDisplay";
import { caveDurationSec } from "../../config";
import { useActiveCaveRoundInfo, usePokingPlayer } from "../../hooks";
import { getNetworkFromPtbContractName } from "../../utils";
import { PokeDurationTimer } from "./PokeDurationTimer";
import { CountdownWrapper } from "./shared";

export const CaveCountdown = () => {
  const { data } = useActiveCaveRoundInfo();
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  const bgGradient = useColorModeValue(
    "linear-gradient(180deg, #E5E5EA 0%, #FFF 100%)",
    "linear-gradient(180deg, #2A3136 0%, #121619 100%)"
  );
  const pokingPtbPlayer = usePokingPlayer();
  const prizePool = !!data ? data.meta.totalPrizePool : 0n;
  const prizerPerPlayer = !!data ? data.meta.prizePerPlayerNet : 0n;
  const currency = data?.cave.currency || zeroAddress;
  const caveDuration = Number(data?.cave.roundDurationSecs || caveDurationSec);

  const timerComponent = (() => {
    if (!!pokingPtbPlayer) {
      return (
        <PokeDurationTimer
          playerAddress={pokingPtbPlayer.user.address}
          isPokingUntil={pokingPtbPlayer.poke?.isPokingUntil}
        />
      );
    }

    return (
      <CountdownWrapper>
        <CircularCountdown
          size={100}
          duration={caveDuration}
          countdown={caveDuration}
          strokeWidth="6px"
          strokeColor="text-03"
          trackColor="border-01"
        >
          <ClockIcon boxSize={10} color="text-03" />
        </CircularCountdown>
      </CountdownWrapper>
    );
  })();

  return (
    <Flex display="inline-flex" position="relative" height="114px" alignItems="center">
      {timerComponent}
      <Flex
        justifyContent="center"
        ml="100px"
        pl={8}
        pr={2}
        bgGradient={bgGradient}
        height="79px"
        flexDirection="column"
      >
        <Popover
          variant="tooltip"
          label={
            <TooltipText>
              {t(
                `ptb::When the round ends, this amount will be split equally among all the survivors (once the fee is subtracted).`
              )}
            </TooltipText>
          }
        >
          <HStack spacing={0.5}>
            <Text color="text-03" textStyle="helper">
              {t("ptb::Prize Pool")}
            </Text>
            <InformationCircleIcon color="text-03" boxSize={4} />
          </HStack>
        </Popover>
        <CurrencyValueDisplay
          currency={currency}
          network={data && getNetworkFromPtbContractName(data?.cave.contract)}
          iconProps={{ boxSize: 8 }}
          textProps={{
            color: "text-01",
            textStyle: "display-03",
            lineHeight: 1,
          }}
          total={formatToSignificant(prizePool, 6)}
        />
        <HStack>
          <Text textStyle="caption" color="text-03">
            {t("ptb::Prize Per Winner")}
          </Text>
          <CurrencyValueDisplay
            currency={currency}
            network={data && getNetworkFromPtbContractName(data?.cave.contract)}
            iconProps={{ boxSize: 3 }}
            textProps={{ textStyle: "caption", color: "text-02", bold: true, lineHeight: 1 }}
            total={formatToSignificant(prizerPerPlayer, 6)}
          />
        </HStack>
      </Flex>
      <Box
        backgroundImage={`url('/images/poke-the-bear/countdown-rectangle-${colorMode}.svg')`}
        backgroundRepeat="no-repeat"
        backgroundPosition="right center"
        width="40px"
        height="79px"
      />
    </Flex>
  );
};
