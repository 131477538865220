import { chainInfo } from "@looksrare/config";
import { SupportedNetwork } from "../types";
import { getChainIdFromSupportedNetwork } from "../chain";
import { useCurrentChainId } from "./useGlobalStore";

export const useCurrentChainInfo = (network?: SupportedNetwork) => {
  const currentChainId = useCurrentChainId();

  if (!!network) {
    return chainInfo[getChainIdFromSupportedNetwork(network)];
  }

  return chainInfo[currentChainId];
};
