import { IconProps } from "@chakra-ui/react";
import { type Currency, ChainId } from "@looksrare/config";
import {
  ArbitrumChainLogoIcon,
  BlastChainLogoIcon,
  EthArbTokenIcon,
  EthChainLogoIcon,
  EthTokenIcon,
  JuiceTokenIcon,
  LooksTokenIcon,
  OrbitTokenIcon,
  PacTokenIcon,
  TokenBoostIcon,
  TokenYoloIcon,
  UsdbIcon,
  UsdcIcon,
  UsdtIcon,
  WLooksTokenIcon,
  WethTokenIcon,
  YesTokenIcon,
  YieldTokenIcon,
} from "./components/Icons";

export const chainIconMap: Record<ChainId, (props: IconProps) => JSX.Element> = {
  [ChainId.BLAST_SEPOLIA]: BlastChainLogoIcon,
  [ChainId.BLAST_MAINNET]: BlastChainLogoIcon,
  [ChainId.ARB_MAINNET]: ArbitrumChainLogoIcon,
  [ChainId.ARB_SEPOLIA]: ArbitrumChainLogoIcon,
  [ChainId.GOERLI]: EthChainLogoIcon,
  [ChainId.SEPOLIA]: EthChainLogoIcon,
  [ChainId.MAINNET]: EthChainLogoIcon,
  [ChainId.HARDHAT]: EthChainLogoIcon,
};

export const chainTokenIconMap: Record<ChainId, (props: IconProps) => JSX.Element> = {
  [ChainId.ARB_MAINNET]: EthArbTokenIcon,
  [ChainId.ARB_SEPOLIA]: EthArbTokenIcon,
  [ChainId.BLAST_SEPOLIA]: EthTokenIcon,
  [ChainId.BLAST_MAINNET]: EthTokenIcon,
  [ChainId.GOERLI]: EthTokenIcon,
  [ChainId.SEPOLIA]: EthTokenIcon,
  [ChainId.MAINNET]: EthTokenIcon,
  [ChainId.HARDHAT]: EthTokenIcon,
};

export const chainNameMap: Record<ChainId, string> = {
  [ChainId.BLAST_SEPOLIA]: "Sepolia (Blast)",
  [ChainId.BLAST_MAINNET]: "Blast",
  [ChainId.ARB_MAINNET]: "Arbitrum",
  [ChainId.ARB_SEPOLIA]: "Sepolia (Arb)",
  [ChainId.GOERLI]: "Goerli",
  [ChainId.SEPOLIA]: "Sepolia",
  [ChainId.MAINNET]: "Ethereum",
  [ChainId.HARDHAT]: "Hardhat",
};

export const chainGradientMap: Record<ChainId, string> = {
  [ChainId.BLAST_SEPOLIA]: "linear-gradient(180deg, rgba(23, 170, 253, 0.20) 0%, rgba(23, 170, 253, 0.05) 100%)",
  [ChainId.BLAST_MAINNET]: "linear-gradient(180deg, rgba(23, 170, 253, 0.20) 0%, rgba(23, 170, 253, 0.05) 100%)",
  [ChainId.ARB_MAINNET]: "linear-gradient(180deg, rgba(23, 170, 253, 0.20) 0%, rgba(23, 170, 253, 0.05) 100%)",
  [ChainId.ARB_SEPOLIA]: "linear-gradient(180deg, rgba(23, 170, 253, 0.20) 0%, rgba(23, 170, 253, 0.05) 100%)",
  [ChainId.GOERLI]: "linear-gradient(180deg, rgba(141, 139, 252, 0.20) 0%, rgba(141, 139, 252, 0.05) 100%)",
  [ChainId.SEPOLIA]: "linear-gradient(180deg, rgba(141, 139, 252, 0.20) 0%, rgba(141, 139, 252, 0.05) 100%)",
  [ChainId.MAINNET]: "linear-gradient(180deg, rgba(141, 139, 252, 0.20) 0%, rgba(141, 139, 252, 0.05) 100%)",
  [ChainId.HARDHAT]: "linear-gradient(180deg, rgba(141, 139, 252, 0.20) 0%, rgba(141, 139, 252, 0.05) 100%)",
};

export const getNetworkConfig = (chainId: ChainId) => {
  return {
    icon: chainIconMap[chainId],
    tokenIcon: chainTokenIconMap[chainId],
    name: chainNameMap[chainId],
    gradient: chainGradientMap[chainId],
  };
};

export const currencyTokenIconMap: Record<Currency | string, (props: IconProps) => JSX.Element> = {
  ETH: EthTokenIcon,
  BOOST: TokenBoostIcon,
  JUICE: JuiceTokenIcon,
  LOOKS: LooksTokenIcon,
  ORBIT: OrbitTokenIcon,
  PAC: PacTokenIcon,
  USDB: UsdbIcon,
  USDC: UsdcIcon,
  USDT: UsdtIcon,
  WETH: WethTokenIcon,
  WLOOKS: WLooksTokenIcon,
  YES: YesTokenIcon,
  YIELD: YieldTokenIcon,
  YOLO: TokenYoloIcon,
};
