import { Children, FC, PropsWithChildren, ReactNode } from "react";
import { Box, Flex, FlexProps } from "@chakra-ui/react";

interface AvatarGroupProps extends FlexProps {
  children: ReactNode;
  max?: number;
}

/**
 * This is modeled after chakra's AvatarGroup. Not using chakra's because we do not use chakra's Avatar.
 */
export const AvatarGroup: FC<PropsWithChildren<AvatarGroupProps>> = ({ max = 3, children, ...props }) => {
  const childrenArray = Children.toArray(children);
  return (
    <Flex alignItems="center" {...props}>
      {Children.map(childrenArray, (child, index) => {
        if (index >= max) {
          return null;
        }
        return <Box marginEnd="-8px">{child}</Box>;
      })}
      {childrenArray.length <= max ? null : (
        <Flex
          borderRadius="50%"
          width={8}
          height={8}
          bg="ui-02"
          border="1px solid"
          borderColor="ui-bg"
          justifyContent="center"
          alignItems="center"
          position="relative"
        >
          +{childrenArray.length - max}
        </Flex>
      )}
    </Flex>
  );
};
