/**
 * @see https://github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/foundations/shadows.ts
 */
export const shadows = {
  // For some reason, chara-ui doesn't support theme colors in this syntax
  focus: "0 0 0 1px var(--lr-colors-focus) inset",
  "border-01": "0 0 0 1px var(--lr-colors-border-01) inset",
  error: "0 0 0 1px var(--lr-colors-support-error) inset",
  warning: "0 0 0 1px var(--lr-colors-text-warning) inset",
  "shadow-card-dark": "0px 8px 16px var(--lr-colors-blackAlpha-400)",
  "shadow-card-light": "0px 8px 16px var(--lr-colors-blackAlpha-50)",
  "shadow-card-dark-hover": "0px 16px 24px var(--lr-colors-blackAlpha-500)",
  "shadow-card-light-hover": "0px 16px 24px var(--lr-colors-blackAlpha-200)",
  "shadow-popover-dark": "0px 2px 8px var(--lr-colors-blackAlpha-500), 0px 16px 24px var(--lr-colors-blackAlpha-500)",
  "shadow-popover-light": "0px 2px 8px var(--lr-colors-blackAlpha-200), 0px 16px 24px var(--lr-colors-blackAlpha-200)",
  "shadow-text-on-image": "1px 2px 4px rgba(0, 0, 0, 0.30)",
};
