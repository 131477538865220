import { useTranslation } from "next-i18next";
import { BoxProps } from "@chakra-ui/react";
import { pageHeightRemDesktop, pageHeightRemMobile } from "@looksrare/chakra-theme";
import { ConnectWalletButton, ConnectWalletButtonProps, Container, Text, WalletIcon } from "@looksrare/uikit";

interface Props extends BoxProps {
  connectButtonProps?: ConnectWalletButtonProps;
}

export const ConnectWalletPlaceholderPageContent: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  connectButtonProps,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Container
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight={{ base: pageHeightRemMobile, lg: pageHeightRemDesktop }}
      maxW="608px"
      px={2}
      {...props}
    >
      <WalletIcon boxSize={16} mb={8} color="interactive-03" />
      <Text as="h1" textStyle="heading-03" textAlign="center" mb={6} bold>
        {t("Connect a Wallet")}
      </Text>
      {children || (
        <Text color="text-02" textAlign="center">
          {t("YOLO Games works great with Metamask, Coinbase Wallet, and most other Ethereum wallets.")}
        </Text>
      )}
      <ConnectWalletButton mt={6} {...connectButtonProps} />
    </Container>
  );
};
