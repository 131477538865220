import type { Address } from "viem";
import { isAddressEqual } from "@looksrare/utils";
import { useGameAddresses } from "@looksrare/uikit";

export const useCurrencyLabel = (currency?: Address) => {
  const ptbAddresses = useGameAddresses();

  if (isAddressEqual(currency, ptbAddresses.LOOKS)) {
    return "LOOKS";
  }

  return "ETH";
};
