import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { colors } from "@looksrare/chakra-theme";

export const ModalTheme = {
  baseStyle: (props: StyleFunctionProps) => {
    const { scrollBehavior } = props;
    return {
      dialog: {
        borderRadius: "dialog",
        borderColor: "border-01",
        borderWidth: "1px",
        bg: "ui-01",
        shadow: "none",
        maxH: scrollBehavior === "inside" ? "calc(100% - var(--lr-space-20))" : undefined,
      },
      header: {
        color: "text-01",
        minHeight: "64px",
        px: 4,
        overflow: "hidden",
        display: "flex",
        justifyContent: "space-between",
        borderTopRadius: "dialog",
      },
      body: {
        color: "text-02",
        py: 6,
      },
      footer: {
        pt: 6,
      },
      overlay: {
        backdropFilter: "blur(8px)",
        zIndex: "overlay",
      },
    };
  },
  variants: {
    standard: {
      overlay: {
        bg: `${colors.purple[900]}D9`,
      },
    },
    rainbow: {
      overlay: {
        filter: "blur(8px)",
        bg: "conic-gradient(from 90deg at 50% 51.52%, #4589FFE6 0deg, #FF7EB6E6 141.23deg, #F1C21BE6 231.23deg, #49CD7AE6 287.48deg, #4589FFE6 360deg);",
        transform: "matrix(1, 0, 0, -1, 0, 0)",
        width: "150%",
        height: "150%",
        left: "-50%",
        top: "-25%",
      },
    },
  },
  sizes: {
    sm: {
      dialogContainer: {
        m: 0,
        bottom: "0px",
        top: "auto",
        overflow: "hidden",
        alignItems: "flex-end",
      },
      dialog: {
        maxWidth: "auto",
        width: "100%",
        mt: 0,
        mb: 0,
        borderBottomRadius: "0",
      },
      body: {
        px: 4,
      },
      footer: {
        px: 4,
        pb: 6,
      },
    },
    md: {
      dialog: {
        mx: 4,
        maxWidth: "512px",
        width: "100%",
      },
      body: {
        px: 8,
      },
      footer: {
        px: 8,
        pb: 8,
      },
    },
    lg: {
      dialog: {
        mx: 4,
        maxWidth: "1200px",
        width: "90%",
      },
      body: {
        px: 8,
      },
      footer: {
        px: 8,
        pb: 8,
      },
    },
    xl: {
      dialogContainer: {
        display: "flex",
        alignItems: "stretch",
        justifyContent: "stretch",
      },
      dialog: {
        maxW: "100%",
        mt: 8,
        mb: 0,
      },
    },
  },
  defaultProps: {
    variant: "standard",
  },
};
