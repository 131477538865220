import { Icon, IconProps } from "../Icon";

const Ingot = (props?: IconProps) => {
  return (
    <Icon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.19034 14.3456L1 22.2151L14.1907 26.612L12.6131 16.7521L4.19034 14.3456ZM16.1568 26.241L30.5404 14.7341L27.54 8.53317L14.5931 16.4683L16.1568 26.241ZM26.0876 7.07759L13.3521 14.8832L5.65345 12.6836L18.1852 6L26.0876 7.07759Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default Ingot;
