import { Flex, FlexProps } from "@chakra-ui/react";
import { gradients } from "@looksrare/chakra-theme/colors";
import { Button, ButtonProps } from "./Button";

interface RainbowBorderButtonProps extends ButtonProps {
  boxProps?: FlexProps;
}

export const RainbowBorderButton: React.FC<React.PropsWithChildren<RainbowBorderButtonProps>> = ({
  children,
  boxProps,
  ...props
}) => {
  const rainbowButtonProps = {
    border: "none",
    backgroundImage: gradients.conicGradient,
    p: "1px",
  };
  return (
    <Button
      borderRadius={props.round ? "circular" : "button"}
      height="fit-content"
      sx={{
        bosSizing: "border-box",
        _hover: {
          textDecoration: "none",
        },
        _active: {
          border: "none",
        },
      }}
      {...rainbowButtonProps}
      {...props}
    >
      <Flex
        px={3}
        py={2}
        borderRadius={props.round ? "circular" : "button"}
        background="ui-01"
        justifyContent="center"
        alignItems="center"
        whiteSpace="normal"
        sx={{
          _hover: { background: "ui-bg" },
          transitionProperty: "var(--lr-transition-property-common)",
          transitionDuration: "var(--lr-transition-duration-normal)",
        }}
        {...boxProps}
      >
        {children}
      </Flex>
    </Button>
  );
};

RainbowBorderButton.defaultProps = {
  colorScheme: "gray",
};
