import { StateSetter, getLocalStorageItem } from "@looksrare/utils";
import { type Hash } from "viem";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { LS_DISCLAIMER_KEY } from "../../../config";

export enum View {
  DISCLAIMER = "DISCLAIMER",
  OVERVIEW = "OVERVIEW",
  TRANSACTION = "TRANSACTION",
}

export enum Step {
  LOGIN = "LOGIN",
  APPROVE_TRANSFER_MANAGER = "APPROVE_TRANSFER_MANAGER",
  APPROVE_ERC20 = "APPROVE_ERC20",
  SEND = "SEND",
}

export type StepStatus = "past" | "current" | "future";

export enum TransactionStatus {
  IDLE = "IDLE",
  INITIALIZED = "INITIALIZED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

export interface StepData {
  status: StepStatus;
  hasError: boolean;
  hash?: Hash;
}

interface Steps {
  [Step.LOGIN]: StepData;
  [Step.APPROVE_TRANSFER_MANAGER]: StepData;
  [Step.APPROVE_ERC20]: StepData;
  [Step.SEND]: StepData;
}

interface State {
  view: View;
  steps: Steps;
  numberOfRounds: number;
  hasValidJwt: boolean;
  hasTransferManagerApproved: boolean;
  erc20Allowance: bigint;
}

interface EnterCaveStoreState extends State {
  resetModal: () => void;
  setNumberOfRounds: StateSetter<number>;
  setView: StateSetter<View>;
  setStepHash: StateSetter<{ step: Step; newHash: Hash }>;
  setStepError: StateSetter<Step>;
  setStepStatus: StateSetter<{ step: Step; status: StepStatus }>;
  updatePreflightCheck: StateSetter<{
    hasValidJwt: boolean;
    hasTransferManagerApproved: boolean;
    erc20Allowance: bigint;
  }>;
  setStep: StateSetter<Step>;
}

const getInitialState = (): State => {
  const hasAcknowledgedValue = getLocalStorageItem(LS_DISCLAIMER_KEY);

  return {
    numberOfRounds: 1,
    hasValidJwt: false,
    hasTransferManagerApproved: false,
    erc20Allowance: 0n,
    steps: {
      [Step.LOGIN]: { status: "current", hasError: false },
      [Step.APPROVE_TRANSFER_MANAGER]: { status: "future", hasError: false },
      [Step.APPROVE_ERC20]: { status: "future", hasError: false },
      [Step.SEND]: { status: "future", hasError: false },
    },
    view: Boolean(hasAcknowledgedValue) ? View.OVERVIEW : View.DISCLAIMER,
  };
};

export const useEnterCaveStore = create(
  immer<EnterCaveStoreState>((set) => ({
    ...getInitialState(),
    resetModal: () => {
      set(getInitialState());
    },
    setNumberOfRounds: (newValue) => {
      set((state) => {
        state.numberOfRounds = newValue;
      });
    },
    setView: (newValue) => {
      set((state) => {
        state.view = newValue;
      });
    },
    setStepHash: ({ step, newHash }) => {
      set((state) => {
        state.steps[step].hash = newHash;
      });
    },
    setStepError: (step) => {
      set((state) => {
        state.steps[step].hasError = true;
      });
    },
    setStepStatus: ({ step, status }) => {
      set((state) => {
        state.steps[step].status = status;
      });
    },
    setStep: (step) => {
      set((state) => {
        switch (step) {
          case Step.LOGIN:
            state.steps = {
              [Step.LOGIN]: { status: "current", hasError: false },
              [Step.APPROVE_TRANSFER_MANAGER]: { status: "future", hasError: false },
              [Step.APPROVE_ERC20]: { status: "future", hasError: false },
              [Step.SEND]: { status: "future", hasError: false },
            };
            break;
          case Step.APPROVE_TRANSFER_MANAGER:
            state.steps = {
              [Step.LOGIN]: { status: "past", hasError: false },
              [Step.APPROVE_TRANSFER_MANAGER]: { status: "current", hasError: false },
              [Step.APPROVE_ERC20]: { status: "future", hasError: false },
              [Step.SEND]: { status: "future", hasError: false },
            };
            break;
          case Step.APPROVE_ERC20:
            state.steps = {
              [Step.LOGIN]: { status: "past", hasError: false },
              [Step.APPROVE_TRANSFER_MANAGER]: { status: "past", hasError: false },
              [Step.APPROVE_ERC20]: { status: "current", hasError: false },
              [Step.SEND]: { status: "future", hasError: false },
            };
            break;
          case Step.SEND:
            state.steps = {
              [Step.LOGIN]: { status: "past", hasError: false },
              [Step.APPROVE_TRANSFER_MANAGER]: { status: "past", hasError: false },
              [Step.APPROVE_ERC20]: { status: "past", hasError: false },
              [Step.SEND]: { status: "current", hasError: false },
            };
            break;
        }
      });
    },
    updatePreflightCheck: ({ hasValidJwt, hasTransferManagerApproved, erc20Allowance }) => {
      set((state) => {
        state.hasValidJwt = hasValidJwt;
        state.hasTransferManagerApproved = hasTransferManagerApproved;
        state.erc20Allowance = erc20Allowance;
      });
    },
  }))
);
