/**
 * Ensure a numeric input string doesn't exceed safeDecimals on either side of the decimal pt
 * @param value
 * @params safeIntegers max permissable integers
 * @param decimals max permissable decimals
 * @returns string
 */
export const parseInputToSafeDecimals = (value: string, safeIntegers = 9, safeDecimals = 18) => {
  const [beforeDecimal, afterDecimal] = value.split(".");
  if (value === ".") {
    return "0.";
  }

  const countAfterDecimal = afterDecimal ? afterDecimal.length : 0;
  const countBeforeDecimal = beforeDecimal ? beforeDecimal.length : 0;
  const integerDigits = beforeDecimal && beforeDecimal.substring(0, safeIntegers);
  const decimalDigits = afterDecimal && afterDecimal.substring(0, safeDecimals);

  // Integer digit length is greater than safeDecimals
  if (countBeforeDecimal > safeIntegers) {
    if (countAfterDecimal > safeDecimals) {
      return `${integerDigits}.${decimalDigits}`;
    }
    return `${integerDigits}${afterDecimal ? `.${afterDecimal}` : ""}`;
  }

  // Decimal digit length is greater than safeDecimals
  if (countAfterDecimal > safeDecimals) {
    if (countBeforeDecimal > safeIntegers) {
      return `${integerDigits}.${decimalDigits}`;
    }
    return `${beforeDecimal}.${decimalDigits}`;
  }

  return value;
};
