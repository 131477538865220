import { Icon, IconProps } from "../Icon";

const CopyLink = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.2505 6.76003C28.1247 5.6303 26.5954 4.9953 25.0005 4.9953C23.4056 4.9953 21.8763 5.6303 20.7505 6.76003L22.1705 8.18003C23.1833 7.16718 24.6596 6.77162 26.0432 7.14235C27.4267 7.51308 28.5074 8.59377 28.8782 9.97735C29.2489 11.3609 28.8533 12.8372 27.8405 13.85L19.8405 21.85C18.8294 22.8629 17.3548 23.2594 15.9721 22.8902C14.5895 22.521 13.5088 21.4422 13.1371 20.0602C12.7655 18.6782 13.1594 17.2029 14.1705 16.19L15.5805 14.77L14.1705 13.35L12.7505 14.77C11.6208 15.8958 10.9858 17.4251 10.9858 19.02C10.9858 20.6149 11.6208 22.1442 12.7505 23.27C13.882 24.387 15.4106 25.0093 17.0005 25C18.6016 25.0066 20.139 24.3729 21.2705 23.24L29.2705 15.24C31.6046 12.892 31.5956 9.09705 29.2505 6.76003V6.76003ZM4.19034 24.82C3.43616 24.0693 3.01217 23.0491 3.01217 21.985C3.01217 20.9209 3.43616 19.9007 4.19034 19.15L12.1903 11.15C12.941 10.3958 13.9612 9.97186 15.0253 9.97186C16.0894 9.97186 17.1097 10.3958 17.8603 11.15C18.61 11.9069 19.0212 12.935 19.0003 14C19.0065 15.0681 18.5852 16.0943 17.8303 16.85L15.7103 19L17.1303 20.42L19.2503 18.3C21.6003 15.9501 21.6003 12.14 19.2503 9.79002C16.9004 7.44005 13.0903 7.44005 10.7403 9.79002L2.74034 17.79C1.60754 18.9162 0.970581 20.4477 0.970581 22.045C0.970581 23.6424 1.60754 25.1738 2.74034 26.3C3.87916 27.4085 5.41123 28.0199 7.00034 28C8.60344 28.0016 10.1421 27.3689 11.2803 26.24L9.86034 24.82C9.10966 25.5742 8.08943 25.9982 7.02534 25.9982C5.96124 25.9982 4.94101 25.5742 4.19034 24.82V24.82Z"
      fill="currentColor"
    />
  </Icon>
);

export default CopyLink;
