import { Icon, IconProps } from "../Icon";

const EditAlt = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.9966 7.58157C29.9966 8.11239 29.7856 8.62144 29.41 8.99657L14.41 23.9966H8V17.5866L23 2.58657C23.3751 2.21102 23.8842 2 24.415 2C24.9458 2 25.4549 2.21102 25.83 2.58657L29.41 6.16657C29.7856 6.54171 29.9966 7.05076 29.9966 7.58157ZM27.9997 7.58658L24.4097 3.99658L21.4097 6.99658L24.9997 10.5866L27.9997 7.58658ZM10 21.9966V18.4066L20 8.40657L23.59 11.9966L13.59 21.9966H10ZM4 29.9966H24C25.1046 29.9966 26 29.1011 26 27.9966V18.9966H24V27.9966H4V7.99658H13V5.99658H4C2.89543 5.99658 2 6.89201 2 7.99658V27.9966C2 29.1011 2.89543 29.9966 4 29.9966Z"
      fill="currentColor"
    />
  </Icon>
);

export default EditAlt;
