import { useTranslation } from "next-i18next";
import { Badge, Flex } from "@chakra-ui/react";
import { IconProps, UserFilledIcon } from "@looksrare/uikit";
import times from "lodash/times";

interface PlayersRemainingProps {
  playerCount: number;
  playersPerRound: number;
}

export const PlayersRemaining = ({ playerCount, playersPerRound }: PlayersRemainingProps) => {
  const { t } = useTranslation();
  const emptySpots = playersPerRound - playerCount;

  const sharedProps: Partial<IconProps> = {
    ml: -2,
    position: "relative",
    boxSize: 5,
    filter: "drop-shadow(2px 0px 0px #121619)",
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="end"
      layerStyle={playerCount === playersPerRound ? "animatedPulse" : undefined}
    >
      {playerCount === playersPerRound - 1 && (
        <Badge bg="support-error-inverse" color="white" mr={2}>
          {t("ptb::{{total}} Left!", { total: 1 })}
        </Badge>
      )}
      {times(playerCount).map((n) => (
        <UserFilledIcon key={n} zIndex={playersPerRound - n} color="text-01" {...sharedProps} />
      ))}
      {times(emptySpots).map((n) => (
        <UserFilledIcon key={n} zIndex={emptySpots - n} color="text-disabled" {...sharedProps} />
      ))}
    </Flex>
  );
};
