import { Box, Flex, HStack, IconButton, Slide, UseDisclosureReturn } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { CloseIcon, Text, ViewFilledIcon } from "@looksrare/uikit";
import { useActiveCaveRoundInfo, useActiveCaveViews, useGetCaveQueryParams } from "../../hooks";
import { getPtbContractNameFromNetwork } from "../../utils";
import { PlayerList } from "../PlayerList";

interface PlayerListProps {
  isOpen: boolean;
  onClosePlayerList: UseDisclosureReturn["onClose"];
}

export const PlayerListPanel = ({ isOpen, onClosePlayerList }: PlayerListProps) => {
  const { t } = useTranslation();
  const { caveOnChainId, network } = useGetCaveQueryParams();
  const activeCaveRoundQuery = useActiveCaveRoundInfo();
  const response = useActiveCaveViews(caveOnChainId, getPtbContractNameFromNetwork(network));

  const watchCount = response?.count || 1;
  const totalPlayers = activeCaveRoundQuery.data?.cave.playersPerRound || 0;

  return (
    <Slide direction="bottom" in={isOpen} style={{ height: "100%", position: "absolute" }} unmountOnExit>
      <Flex
        flexDirection="column"
        borderTopLeftRadius="dialog"
        borderTopRightRadius="dialog"
        height="100%"
        border="1px solid"
        borderColor="border-01"
        overflow="hidden"
      >
        <Flex alignItems="center" p={4} borderBottom="1px solid" borderBottomColor="border-01" bg="ui-bg" flex="none">
          <Text bold>{t("{{total}} Players", { total: totalPlayers })}</Text>
          <Flex alignItems="center" flex={1} justifyContent="flex-end" px={3}>
            <HStack spacing={2}>
              <Text color="link-01">{t("{{watchCount}} Watching", { watchCount })}</Text>
              <ViewFilledIcon boxSize={5} color="link-01" />
            </HStack>
          </Flex>
          <IconButton
            size="xs"
            onClick={onClosePlayerList}
            aria-label="hide player list"
            colorScheme="secondary"
            variant="outline"
          >
            <CloseIcon boxSize={5} />
          </IconButton>
        </Flex>
        <Box p={2} bg="ui-01" height="100%" flex={1} overflowY="auto">
          <PlayerList />
        </Box>
      </Flex>
    </Slide>
  );
};
