import { HStack, Stack } from "@chakra-ui/react";
import { Accordion, AccordionIcon, EthHalfIcon, Text } from "@looksrare/uikit";
import { formatToSignificant } from "@looksrare/utils";
import { useTranslation } from "next-i18next";
import { MoDContract } from "@looksrare/yolo-games-gql-typegen";
import { MoDUnclaimedEntries } from "../../../network/graphql/hooks";
import { getAssetPairByContractName } from "../../../utils/transformContract";

interface ClaimModalValueBreakdownProps {
  totalUnclaimedAmount: bigint;
  unclaimedEntries: MoDUnclaimedEntries;
}

export const ClaimModalValueBreakdown = ({ totalUnclaimedAmount, unclaimedEntries }: ClaimModalValueBreakdownProps) => {
  const { t } = useTranslation();

  return (
    <Accordion
      buttonProps={{
        px: 0,
        py: 2,
        borderWidth: 0,
        borderRadius: "square",
        backgroundColor: "ui-bg",
        color: "text-02",
        _hover: {
          backgroundColor: "ui-bg",
          color: "text-01",
        },
      }}
      panelProps={{
        borderWidth: 0,
        p: 0,
      }}
      leftButtonElement={
        <HStack spacing={1}>
          <Text textStyle="detail" color="inherit">
            {t("Total Value")}
          </Text>
          <AccordionIcon boxSize={5} />
        </HStack>
      }
      rightButtonElement={
        <HStack spacing={1}>
          <Text textStyle="detail" color="text-01" bold>
            {formatToSignificant(totalUnclaimedAmount)}
          </Text>
          <EthHalfIcon height={4} width={2} />
        </HStack>
      }
      showAccordionIcon={false}
    >
      <Stack spacing={0}>
        {Object.entries(unclaimedEntries).map(([contract, _unclaimedEntries]) => {
          const modContract = contract as MoDContract;
          const assetPair = getAssetPairByContractName(modContract);

          return _unclaimedEntries.map(({ isRefund, amount }, entryIdx) => {
            const label = isRefund ? t("mod::Canceled {{assetPair}}", { assetPair }) : assetPair;

            return (
              <HStack key={contract + amount.toString() + entryIdx} py={2} pl={8} justifyContent="space-between">
                <Text textStyle="detail" color="text-03">
                  {label}
                </Text>

                <HStack spacing={1}>
                  <Text textStyle="detail" color="text-03" bold>
                    {formatToSignificant(amount)}
                  </Text>

                  <EthHalfIcon height={4} width={2} />
                </HStack>
              </HStack>
            );
          });
        })}
      </Stack>
    </Accordion>
  );
};
