import { Icon, IconProps } from "../Icon";

const Model = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5008 4H8.5008L1.7168 13.045L16.0008 29.527L30.2848 13.045L23.5008 4ZM27.0009 12H21.5549L17.8049 6H22.5009L27.0009 12ZM10.3028 14L14.0568 24.23L5.19079 14H10.3028ZM12.4328 14H19.5688L15.9998 23.721L12.4328 14ZM12.8057 12L16.0007 6.88699L19.1967 12H12.8057ZM21.6987 14H26.8107L17.9437 24.231L21.6987 14ZM9.50076 6H14.1968L10.4468 12H5.00076L9.50076 6Z"
      fill="currentColor"
    />
  </Icon>
);

export default Model;
