import { Icon, IconProps } from "../Icon";

const Sparkle = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.69706 8.69706L7 13L5.30294 8.69706L1 7L5.30294 5.30294L7 1L8.69706 5.30294L13 7L8.69706 8.69706ZM12.2627 25.2627L10 31L7.73726 25.2627L2 23L7.73726 20.7373L10 15L12.2627 20.7373L18 23L12.2627 25.2627ZM21.5 24L24.4698 16.4698L32 13.5L24.4698 10.5302L21.5 3L18.5302 10.5302L11 13.5L18.5302 16.4698L21.5 24Z"
      fill="currentColor"
    />
  </Icon>
);

export default Sparkle;
