export const FlipperAbi = [
  {
    type: "constructor",
    inputs: [
      {
        name: "_gameConfigurationManager",
        type: "address",
        internalType: "address",
      },
      {
        name: "_transferManager",
        type: "address",
        internalType: "address",
      },
      {
        name: "_weth",
        type: "address",
        internalType: "address",
      },
      {
        name: "_vrfCoordinator",
        type: "address",
        internalType: "address",
      },
      {
        name: "_blast",
        type: "address",
        internalType: "address",
      },
      {
        name: "_usdb",
        type: "address",
        internalType: "address",
      },
      {
        name: "_owner",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "GAME_CONFIGURATION_MANAGER",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "contract IGameConfigurationManager",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "TRANSFER_MANAGER",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "contract ITransferManager",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "cancelOwnershipTransfer",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "claimYield",
    inputs: [
      {
        name: "receiver",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "confirmOwnershipRenouncement",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "confirmOwnershipTransfer",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "games",
    inputs: [
      {
        name: "player",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "params",
        type: "tuple",
        internalType: "struct Game.Game__GameParams",
        components: [
          {
            name: "blockNumber",
            type: "uint40",
            internalType: "uint40",
          },
          {
            name: "randomnessRequestedAt",
            type: "uint40",
            internalType: "uint40",
          },
          {
            name: "numberOfRounds",
            type: "uint16",
            internalType: "uint16",
          },
          {
            name: "currency",
            type: "address",
            internalType: "address",
          },
          {
            name: "playAmountPerRound",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "stopGain",
            type: "int256",
            internalType: "int256",
          },
          {
            name: "stopLoss",
            type: "int256",
            internalType: "int256",
          },
          {
            name: "vrfFee",
            type: "uint256",
            internalType: "uint256",
          },
        ],
      },
      {
        name: "isGold",
        type: "bool",
        internalType: "bool",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "initiateOwnershipRenouncement",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "initiateOwnershipTransfer",
    inputs: [
      {
        name: "newPotentialOwner",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "kellyFraction",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "maxPlayAmountPerGame",
    inputs: [
      {
        name: "currency",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "maxPlayAmount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "minPlayAmountPerGame",
    inputs: [
      {
        name: "currency",
        type: "address",
        internalType: "address",
      },
    ],
    outputs: [
      {
        name: "minPlayAmount",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "owner",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "ownershipStatus",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "uint8",
        internalType: "enum IOwnableTwoSteps.Status",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "play",
    inputs: [
      {
        name: "numberOfRounds",
        type: "uint16",
        internalType: "uint16",
      },
      {
        name: "playAmountPerRound",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "currency",
        type: "address",
        internalType: "address",
      },
      {
        name: "stopGain",
        type: "int256",
        internalType: "int256",
      },
      {
        name: "stopLoss",
        type: "int256",
        internalType: "int256",
      },
      {
        name: "isGold",
        type: "bool",
        internalType: "bool",
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "potentialOwner",
    inputs: [],
    outputs: [
      {
        name: "",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "randomnessRequests",
    inputs: [
      {
        name: "requestId",
        type: "uint256",
        internalType: "uint256",
      },
    ],
    outputs: [
      {
        name: "requester",
        type: "address",
        internalType: "address",
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "rawFulfillRandomWords",
    inputs: [
      {
        name: "requestId",
        type: "uint256",
        internalType: "uint256",
      },
      {
        name: "randomWords",
        type: "uint256[]",
        internalType: "uint256[]",
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "refund",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "event",
    name: "CancelOwnershipTransfer",
    inputs: [],
    anonymous: false,
  },
  {
    type: "event",
    name: "Flipper__GameCompleted",
    inputs: [
      {
        name: "blockNumber",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "player",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "results",
        type: "bool[]",
        indexed: false,
        internalType: "bool[]",
      },
      {
        name: "payouts",
        type: "uint256[]",
        indexed: false,
        internalType: "uint256[]",
      },
      {
        name: "numberOfRoundsPlayed",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "protocolFee",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "liquidityPoolFee",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Flipper__GameCreated",
    inputs: [
      {
        name: "blockNumber",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "player",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "numberOfRounds",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "playAmountPerRound",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "currency",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "stopGain",
        type: "int256",
        indexed: false,
        internalType: "int256",
      },
      {
        name: "stopLoss",
        type: "int256",
        indexed: false,
        internalType: "int256",
      },
      {
        name: "isGold",
        type: "bool",
        indexed: false,
        internalType: "bool",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Game__Refunded",
    inputs: [
      {
        name: "blockNumber",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
      {
        name: "player",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "totalPlayAmount",
        type: "uint256",
        indexed: false,
        internalType: "uint256",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "InitiateOwnershipRenouncement",
    inputs: [],
    anonymous: false,
  },
  {
    type: "event",
    name: "InitiateOwnershipTransfer",
    inputs: [
      {
        name: "previousOwner",
        type: "address",
        indexed: false,
        internalType: "address",
      },
      {
        name: "potentialOwner",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "NewOwner",
    inputs: [
      {
        name: "newOwner",
        type: "address",
        indexed: false,
        internalType: "address",
      },
    ],
    anonymous: false,
  },
  {
    type: "error",
    name: "ERC20TransferFail",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__InexactNativeTokensSupplied",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__InvalidMultiplier",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__InvalidStops",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__InvalidValue",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__LiquidityPoolConnected",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__NoLiquidityPool",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__NoOngoingRound",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__OngoingRound",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__PlayAmountPerRoundTooHigh",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__PlayAmountPerRoundTooLow",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__TooEarlyForARefund",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__TooManyRounds",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__WrongVrfCoordinator",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__ZeroMultiplier",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__ZeroNumberOfRounds",
    inputs: [],
  },
  {
    type: "error",
    name: "Game__ZeroPlayAmountPerRound",
    inputs: [],
  },
  {
    type: "error",
    name: "NoOngoingTransferInProgress",
    inputs: [],
  },
  {
    type: "error",
    name: "NotAContract",
    inputs: [],
  },
  {
    type: "error",
    name: "NotOwner",
    inputs: [],
  },
  {
    type: "error",
    name: "OnlyCoordinatorCanFulfill",
    inputs: [
      {
        name: "have",
        type: "address",
        internalType: "address",
      },
      {
        name: "want",
        type: "address",
        internalType: "address",
      },
    ],
  },
  {
    type: "error",
    name: "ReentrancyFail",
    inputs: [],
  },
  {
    type: "error",
    name: "RenouncementNotInProgress",
    inputs: [],
  },
  {
    type: "error",
    name: "TransferAlreadyInProgress",
    inputs: [],
  },
  {
    type: "error",
    name: "TransferNotInProgress",
    inputs: [],
  },
  {
    type: "error",
    name: "WrongPotentialOwner",
    inputs: [],
  },
] as const;
