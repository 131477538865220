import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { Box, Flex, FlexProps, IconButton, Stack } from "@chakra-ui/react";
import {
  DiscordIcon,
  Link,
  NextLink,
  Popover,
  TooltipText,
  TwitterIcon,
  YoloGamesGrayscaleIcon,
} from "@looksrare/uikit";
import { navItems } from "@/components/Layout/SideNav/config";
import { NavButton } from "./NavButton";

// @note SideNavMobile handles all mobile concerns

export const SideNav = (props: FlexProps) => {
  const { pathname } = useRouter();
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="column"
      width="80px"
      alignItems="center"
      borderTopRightRadius="0"
      py={3}
      px={0}
      gap={8}
      borderRight="1px solid"
      borderColor="border-01"
      bg="ui-bg"
      {...props}
    >
      <Flex justifyContent="center" alignItems="center" width="100%" height={10}>
        <NextLink href="/">
          <YoloGamesGrayscaleIcon boxSize={10} />
        </NextLink>
      </Flex>
      <Stack px={{ base: 0, md: 1 }} width="100%" flexGrow={1}>
        {navItems.map(({ isComingSoon, type, pathnames, pathMatch }) => {
          const buttonContent = (
            <NavButton
              key={type}
              type={type}
              isActive={
                pathnames &&
                pathnames.filter((p) => (pathMatch === "exact" ? pathname === p : pathname.includes(p))).length > 0
              }
              isComingSoon={isComingSoon}
            />
          );

          // wrap in tooltip if coming soon
          if (isComingSoon) {
            return (
              <Popover
                key={type}
                variant="tooltip"
                label={<TooltipText textStyle="detail">{t("Coming Soon")}</TooltipText>}
              >
                <span>{buttonContent}</span>
              </Popover>
            );
          }

          return buttonContent;
        })}
      </Stack>

      {/* Social Buttons */}
      <Stack spacing={3} justifyContent="center">
        <IconButton
          size="sm"
          colorScheme="secondary"
          variant="outline"
          aria-label="x-link"
          as={Link}
          href="https://twitter.com/YOLO_Blast"
          isExternal
        >
          <TwitterIcon />
        </IconButton>
        <IconButton
          size="sm"
          colorScheme="secondary"
          variant="outline"
          aria-label="discord-link"
          as={Link}
          href="https://discord.gg/7SHJdtZz37"
          isExternal
        >
          <DiscordIcon />
        </IconButton>
      </Stack>

      <Box px={1} width="100%">
        <NavButton type="help" isActive={pathname === "/faq"} />
      </Box>
    </Flex>
  );
};
