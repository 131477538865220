import { IconProps } from "@chakra-ui/icon";
import { Icon } from "../Icon";

const FaceWink = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 12.287 28.525 8.72601 25.8995 6.1005C23.274 3.475 19.713 2 16 2ZM20.5 11C19.1193 11 18 12.1193 18 13.5C18 14.8807 19.1193 16 20.5 16C21.8807 16 23 14.8807 23 13.5C23 12.1193 21.8807 11 20.5 11ZM8 13H14V15H8V13ZM22.8503 20.11C21.4078 22.5189 18.808 23.9953 16.0003 24C13.1925 23.9953 10.5927 22.5189 9.15026 20.11L10.8603 19.11C11.9457 20.9126 13.8961 22.0148 16.0003 22.0148C18.1044 22.0148 20.0548 20.9126 21.1403 19.11L22.8503 20.11ZM4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16Z"
      fill="currentColor"
    />
  </Icon>
);

export default FaceWink;
