import { DefaultSeoProps, NextSeoProps } from "next-seo";
import { TFunction } from "next-i18next";
import { NextRouter } from "next/router";

// @todo-yg
const brand = "YOLO Games";
const rawDescription = "Blast Off!";
export const getSeoBaseImage = (appUrl: string) => ({
  url: `${appUrl}/images/meta.png`,
  width: 1200,
  height: 675,
  alt: brand,
});

interface CanonicalAndLanguageAlternates {
  canonical: string;
  languageAlternates: NextSeoProps["languageAlternates"];
}

/***
 *
 * @param canonicalPathname - the pathname of the page, e.g. /collections/0x...14d3/142. Include the leading slash,
 * trailing ones will be removed.
 * @param router - the router object from Next.js
 */
export const getCanonicalAndLanguageAlternates = (
  canonicalPathname: string,
  router: NextRouter,
  appUrl: string
): CanonicalAndLanguageAlternates => {
  const { locale: currentLocale, locales = [], defaultLocale } = router;
  // Remove any trailing slash to avoid 308 redirects. Also applies to homepage "/".
  const safePathName = canonicalPathname.endsWith("/")
    ? canonicalPathname.substring(0, canonicalPathname.length - 1)
    : canonicalPathname;

  const defaultCanonicalUrl = `${appUrl}${safePathName}`; // "/en" is omitted because it's the default
  const defaultTag = { hrefLang: "x-default", href: defaultCanonicalUrl };
  const localeTagsConfig = locales.map((locale) => ({
    hrefLang: locale,
    href: locale === defaultLocale ? defaultCanonicalUrl : `${appUrl}/${locale}${safePathName}`,
  }));

  const languageAlternates = [defaultTag, ...localeTagsConfig];
  const canonical = currentLocale === defaultLocale ? defaultCanonicalUrl : `${appUrl}/${currentLocale}${safePathName}`;

  return { canonical, languageAlternates };
};

const getDefaultSeo = (t: TFunction, appUrl: string): DefaultSeoProps => ({
  title: brand,
  titleTemplate: `%s | ${brand}`,
  description: t(rawDescription),
  twitter: {
    handle: "@YOLO_Blast",
    site: "https://twitter.com/YOLO_Blast",
    cardType: "summary_large_image",
  },
  openGraph: {
    type: "website",
    url: appUrl,
    title: brand,
    description: t(rawDescription),
    images: [getSeoBaseImage(appUrl)],
    site_name: brand,
  },
  additionalLinkTags: [
    { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-icon.png" },
    { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
    { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" },
    { rel: "manifest", href: "/manifest.json" },
  ],
});

export default getDefaultSeo;
