import { getTopicName, TopicName, useRealtimeSubscription } from "@looksrare/utils";
import { MoDContract } from "@looksrare/yolo-games-gql-typegen";

export const useModPageViews = () => {
  const topicName = getTopicName({ name: TopicName.modActiveViews });
  return useRealtimeSubscription<{ count: number }>(topicName);
};

export const useModContractViews = (contract: MoDContract) => {
  const topicName = getTopicName({ name: TopicName.modContractActiveViews, contract });
  return useRealtimeSubscription<{ count: number }>(topicName);
};

export const useModActivePlayers = () => {
  const topicName = getTopicName({ name: TopicName.modActivePlayers });

  return useRealtimeSubscription<number>(topicName);
};
