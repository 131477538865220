import { Icon, IconProps } from "../Icon";

const WinnerCup = (props: IconProps) => (
  <Icon viewBox="0 0 36 32" fill="none" {...props}>
    <path
      d="M14.572 2.77294C15.4629 2.2907 16.5371 2.2907 17.428 2.77294L28.2844 8.64915C29.253 9.17338 29.8564 10.1862 29.8564 11.2875V22.7125C29.8564 23.8138 29.253 24.8266 28.2844 25.3509L17.428 31.2271C16.5371 31.7093 15.4629 31.7093 14.572 31.2271L3.71556 25.3509C2.74703 24.8266 2.14359 23.8138 2.14359 22.7125V11.2875C2.14359 10.1862 2.74703 9.17338 3.71556 8.64915L14.572 2.77294Z"
      fill="url(#paint0_radial_2451_179098)"
    />
    <g style={{ mixBlendMode: "overlay" }}>
      <path
        d="M14.572 2.77294C15.4629 2.2907 16.5371 2.2907 17.428 2.77294L28.2844 8.64915C29.253 9.17338 29.8564 10.1862 29.8564 11.2875V22.7125C29.8564 23.8138 29.253 24.8266 28.2844 25.3509L17.428 31.2271C16.5371 31.7093 15.4629 31.7093 14.572 31.2271L3.71556 25.3509C2.74703 24.8266 2.14359 23.8138 2.14359 22.7125V11.2875C2.14359 10.1862 2.74703 9.17338 3.71556 8.64915L14.572 2.77294Z"
        fill="#D6A800"
      />
    </g>
    <path
      d="M14.572 0.772945C15.4629 0.290703 16.5371 0.290703 17.428 0.772945L28.2844 6.64915C29.253 7.17338 29.8564 8.18616 29.8564 9.28747V20.7125C29.8564 21.8138 29.253 22.8266 28.2844 23.3509L17.428 29.2271C16.5371 29.7093 15.4629 29.7093 14.572 29.2271L3.71556 23.3509C2.74703 22.8266 2.14359 21.8138 2.14359 20.7125V9.28747C2.14359 8.18616 2.74703 7.17338 3.71556 6.64915L14.572 0.772945Z"
      fill="url(#paint1_radial_2451_179098)"
    />
    <g style={{ mixBlendMode: "overlay" }}>
      <path
        d="M14.572 0.772945C15.4629 0.290703 16.5371 0.290703 17.428 0.772945L28.2844 6.64915C29.253 7.17338 29.8564 8.18616 29.8564 9.28747V20.7125C29.8564 21.8138 29.253 22.8266 28.2844 23.3509L17.428 29.2271C16.5371 29.7093 15.4629 29.7093 14.572 29.2271L3.71556 23.3509C2.74703 22.8266 2.14359 21.8138 2.14359 20.7125V9.28747C2.14359 8.18616 2.74703 7.17338 3.71556 6.64915L14.572 0.772945Z"
        fill="#F8CC32"
      />
    </g>
    <g style={{ mixBlendMode: "soft-light" }} opacity="0.7">
      <path d="M6 24L18 2" stroke="url(#paint2_linear_2451_179098)" strokeWidth="4" />
    </g>
    <g style={{ mixBlendMode: "soft-light" }} opacity="0.5">
      <path d="M13 28L25 6" stroke="url(#paint3_linear_2451_179098)" strokeWidth="6" />
    </g>
    <path
      d="M14.81 1.21266C15.5524 0.810796 16.4476 0.810796 17.19 1.21266L28.0464 7.08887C28.8535 7.52573 29.3564 8.36971 29.3564 9.28747V20.7125C29.3564 21.6303 28.8535 22.4743 28.0464 22.9111L17.19 28.7873C16.4476 29.1892 15.5524 29.1892 14.81 28.7873L3.95357 22.9111C3.14645 22.4743 2.64359 21.6303 2.64359 20.7125V9.28747C2.64359 8.36971 3.14645 7.52573 3.95357 7.08887L14.81 1.21266Z"
      stroke="url(#paint4_linear_2451_179098)"
    />
    <g style={{ mixBlendMode: "overlay" }}>
      <path
        d="M14.81 1.21266C15.5524 0.810796 16.4476 0.810796 17.19 1.21266L28.0464 7.08887C28.8535 7.52573 29.3564 8.36971 29.3564 9.28747V20.7125C29.3564 21.6303 28.8535 22.4743 28.0464 22.9111L17.19 28.7873C16.4476 29.1892 15.5524 29.1892 14.81 28.7873L3.95357 22.9111C3.14645 22.4743 2.64359 21.6303 2.64359 20.7125V9.28747C2.64359 8.36971 3.14645 7.52573 3.95357 7.08887L14.81 1.21266Z"
        stroke="#F8CC32"
      />
    </g>
    <path
      d="M3.81243 26.5069C3.87686 26.3328 4.12314 26.3328 4.18757 26.5069L4.77836 28.1035C4.79861 28.1582 4.84178 28.2014 4.89652 28.2216L6.4931 28.8124C6.66722 28.8769 6.66722 29.1231 6.4931 29.1876L4.89652 29.7784C4.84178 29.7986 4.79861 29.8418 4.77836 29.8965L4.18757 31.4931C4.12314 31.6672 3.87686 31.6672 3.81243 31.4931L3.22164 29.8965C3.20139 29.8418 3.15822 29.7986 3.10348 29.7784L1.5069 29.1876C1.33278 29.1231 1.33278 28.8769 1.5069 28.8124L3.10348 28.2216C3.15822 28.2014 3.20139 28.1582 3.22164 28.1035L3.81243 26.5069Z"
      fill="#FCF2BE"
    />
    <g style={{ mixBlendMode: "overlay" }} filter="url(#filter0_i_2451_179098)">
      <path
        d="M3.81243 26.5069C3.87686 26.3328 4.12314 26.3328 4.18757 26.5069L4.77836 28.1035C4.79861 28.1582 4.84178 28.2014 4.89652 28.2216L6.4931 28.8124C6.66722 28.8769 6.66722 29.1231 6.4931 29.1876L4.89652 29.7784C4.84178 29.7986 4.79861 29.8418 4.77836 29.8965L4.18757 31.4931C4.12314 31.6672 3.87686 31.6672 3.81243 31.4931L3.22164 29.8965C3.20139 29.8418 3.15822 29.7986 3.10348 29.7784L1.5069 29.1876C1.33278 29.1231 1.33278 28.8769 1.5069 28.8124L3.10348 28.2216C3.15822 28.2014 3.20139 28.1582 3.22164 28.1035L3.81243 26.5069Z"
        fill="url(#paint5_linear_2451_179098)"
      />
    </g>
    <g style={{ mixBlendMode: "overlay" }} filter="url(#filter1_di_2451_179098)">
      <path
        d="M25.6249 1.0138C25.7537 0.665556 26.2463 0.665555 26.3751 1.0138L27.5567 4.20696C27.5972 4.31645 27.6836 4.40277 27.793 4.44328L30.9862 5.62486C31.3344 5.75372 31.3344 6.24628 30.9862 6.37514L27.793 7.55672C27.6836 7.59723 27.5972 7.68355 27.5567 7.79304L26.3751 10.9862C26.2463 11.3344 25.7537 11.3344 25.6249 10.9862L24.4433 7.79304C24.4028 7.68355 24.3164 7.59723 24.207 7.55672L21.0138 6.37514C20.6656 6.24628 20.6656 5.75372 21.0138 5.62486L24.207 4.44328C24.3164 4.40277 24.4028 4.31645 24.4433 4.20696L25.6249 1.0138Z"
        fill="url(#paint6_linear_2451_179098)"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      d="M25.6249 1.0138C25.7537 0.665556 26.2463 0.665555 26.3751 1.0138L27.5567 4.20696C27.5972 4.31645 27.6836 4.40277 27.793 4.44328L30.9862 5.62486C31.3344 5.75372 31.3344 6.24628 30.9862 6.37514L27.793 7.55672C27.6836 7.59723 27.5972 7.68355 27.5567 7.79304L26.3751 10.9862C26.2463 11.3344 25.7537 11.3344 25.6249 10.9862L24.4433 7.79304C24.4028 7.68355 24.3164 7.59723 24.207 7.55672L21.0138 6.37514C20.6656 6.24628 20.6656 5.75372 21.0138 5.62486L24.207 4.44328C24.3164 4.40277 24.4028 4.31645 24.4433 4.20696L25.6249 1.0138Z"
      fill="#FCF2BE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7283 7.75C11.0499 7.75 10.5 8.29992 10.5 8.97829V9.46875H7.78785C7.38725 9.46875 7.0625 9.7935 7.0625 10.1941C7.0625 12.113 7.82478 13.9533 9.18164 15.3102L11.2214 17.3499C12.0609 18.819 13.5585 19.8637 15.3125 20.0824V22.875H12.5625V24.25H15.3125H16.6875H19.4375V22.875H16.6875V20.0824C18.4415 19.8637 19.9391 18.819 20.7786 17.3499L22.8184 15.3102C24.1752 13.9533 24.9375 12.113 24.9375 10.1941C24.9375 9.7935 24.6127 9.46875 24.2122 9.46875H21.5V8.97829C21.5 8.29992 20.9501 7.75 20.2717 7.75H11.7283ZM21.5 10.8438V14.625C21.5 14.6448 21.4999 14.6646 21.4997 14.6843L21.8461 14.3379C22.7909 13.3931 23.3797 12.159 23.5264 10.8438H21.5ZM8.4736 10.8438H10.5V14.625C10.5 14.6448 10.5001 14.6646 10.5003 14.6843L10.1539 14.3379C9.20914 13.3931 8.62027 12.159 8.4736 10.8438Z"
      fill="#93670F"
    />
    <defs>
      <filter
        id="filter0_i_2451_179098"
        x="-0.623535"
        y="26.3763"
        width="7.24707"
        height="7.24738"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2451_179098" />
      </filter>
      <filter
        id="filter1_di_2451_179098"
        x="16.7524"
        y="0.752625"
        width="18.4951"
        height="18.4948"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2451_179098" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2451_179098" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2451_179098" />
      </filter>
      <radialGradient
        id="paint0_radial_2451_179098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 2) rotate(43.1524) scale(43.8634 43.7722)"
      >
        <stop offset="0.34375" stopColor="#827F7F" />
        <stop offset="1" stopColor="#5F5F5F" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_2451_179098"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(43.1524) scale(43.8634 43.7722)"
      >
        <stop offset="0.34375" stopColor="#827F7F" />
        <stop offset="1" stopColor="#5F5F5F" />
      </radialGradient>
      <linearGradient id="paint2_linear_2451_179098" x1="12" y1="24" x2="12" y2="2" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint3_linear_2451_179098" x1="19" y1="28" x2="19" y2="6" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2451_179098"
        x1="32"
        y1="0"
        x2="4.76837e-07"
        y2="31.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#697077" />
        <stop offset="1" stopColor="#C7C7CC" />
      </linearGradient>
      <linearGradient id="paint5_linear_2451_179098" x1="4" y1="26" x2="4" y2="32" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint6_linear_2451_179098" x1="26" y1="0" x2="26" y2="12" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </Icon>
);

export default WinnerCup;
