import { useState } from "react";
import { useAccount } from "wagmi";
import { useTranslation } from "next-i18next";
import { Box } from "@chakra-ui/react";
import { useCurrentChainId } from "@looksrare/utils";
import { ChainId } from "@looksrare/config";
import { UniswapWidgetView } from "../UniswapWidgetModal/UniswapWidgetView";
import { ModalLegacy, ModalLegacyProps } from "../../Modal";
import { Text } from "../../Text";
import { WalletIcon } from "../../Icons";
import { BridgeFundsView } from "../BridgeFundsModal/BridgeFundsView";
import { ConvertEthView } from "../ConvertEthModal/ConvertEthView";
import { SelectWidgetView } from "./SelectWidgetView";
import { MoonpayWidgetView } from "./Moonpay";
import { BlastView } from "./BlastView";

export type SupportedCurrency = "ETH" | "WETH" | "LOOKS" | "DUST";

interface AddFundsModalProps {
  isOpen: ModalLegacyProps["isOpen"];
  onClose: ModalLegacyProps["onClose"];
  defaultBuyCurrency?: SupportedCurrency;
  defaultBuyAmount?: number;
}

export enum View {
  SELECT_WIDGET,
  MOONPAY_WIDGET,
  UNISWAP_WIDGET,
  CONVERT_ETH_WETH,
  BRIDGE_FUNDS,
}

export const AddFundsModal = ({ isOpen, onClose, defaultBuyCurrency, defaultBuyAmount }: AddFundsModalProps) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const [view, setView] = useState<View>(View.SELECT_WIDGET);
  const chainId = useCurrentChainId();

  // reset view when the modal closes
  const handleClose = () => {
    onClose();
    setView(View.SELECT_WIDGET);
  };

  if (!address) {
    return null;
  }

  const title = (() => {
    switch (view) {
      case View.SELECT_WIDGET:
        // we use a heroElement for this case, no title
        return undefined;
      case View.MOONPAY_WIDGET:
        return t("Buy with Card");
      case View.UNISWAP_WIDGET:
        return t("Buy with Crypto");
      case View.CONVERT_ETH_WETH:
        return t("Swap ETH/WETH");
      case View.BRIDGE_FUNDS:
        return t("Move Across Networks");
    }
  })();

  const isBuyingEthOrWeth = !!defaultBuyCurrency && ["ETH", "WETH"].includes(defaultBuyCurrency);
  const isOnBlast = [ChainId.BLAST_MAINNET, ChainId.BLAST_SEPOLIA].includes(chainId);

  return (
    <ModalLegacy
      title={title}
      onClose={handleClose}
      isOpen={isOpen}
      closeOnOverlayClick={false}
      showBackButton={view !== View.SELECT_WIDGET}
      onBack={() => setView(View.SELECT_WIDGET)}
      modalContentProps={{ border: "none" }}
      modalHeaderProps={{
        backgroundColor: view === View.BRIDGE_FUNDS ? "ui-bg" : undefined,
      }}
      heroElement={
        view === View.SELECT_WIDGET && (
          <Box textAlign="center">
            <WalletIcon boxSize={16} color="interactive-03" mb={6} />
            <Text textStyle="heading-03" bold mb={2}>
              {t("Add Funds")}
            </Text>
            <Text color="text-02">
              {isOnBlast ? t("This will open the Blast bridge in a new tab.") : t("How do you want to add funds?")}
            </Text>
          </Box>
        )
      }
    >
      {(() => {
        if (isOnBlast) {
          return <BlastView />;
        }

        switch (view) {
          case View.SELECT_WIDGET:
            return <SelectWidgetView setView={setView} />;
          case View.MOONPAY_WIDGET:
            return <MoonpayWidgetView defaultBuyCurrency={defaultBuyCurrency} defaultBuyAmount={defaultBuyAmount} />;
          case View.UNISWAP_WIDGET:
            return <UniswapWidgetView defaultBuyCurrency={defaultBuyCurrency} defaultBuyAmount={defaultBuyAmount} />;
          case View.CONVERT_ETH_WETH:
            return (
              <ConvertEthView
                isOpen
                account={address}
                defaultOutputAmount={isBuyingEthOrWeth ? defaultBuyAmount : undefined}
              />
            );
          case View.BRIDGE_FUNDS:
            return <BridgeFundsView />;
        }
      })()}
    </ModalLegacy>
  );
};
