import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const Percent = (props: IconProps) => {
  const [uid] = useState(uniqueId());

  return (
    <Icon {...props}>
      <g clipPath={`url(#${uid})`}>
        <path
          d="M9 14C8.0111 14 7.0444 13.7068 6.22215 13.1573C5.39991 12.6079 4.75904 11.827 4.3806 10.9134C4.00217 9.99979 3.90315 8.99446 4.09608 8.02455C4.289 7.05465 4.76521 6.16373 5.46447 5.46447C6.16373 4.76521 7.05465 4.289 8.02455 4.09608C8.99446 3.90315 9.99979 4.00217 10.9134 4.3806C11.827 4.75904 12.6079 5.39991 13.1573 6.22215C13.7068 7.0444 14 8.0111 14 9C13.9985 10.3256 13.4713 11.5966 12.5339 12.5339C11.5966 13.4713 10.3256 13.9985 9 14ZM9 6C8.40666 6 7.82664 6.17595 7.33329 6.50559C6.83994 6.83524 6.45543 7.30377 6.22836 7.85195C6.0013 8.40013 5.94189 9.00333 6.05765 9.58527C6.1734 10.1672 6.45912 10.7018 6.87868 11.1213C7.29824 11.5409 7.83279 11.8266 8.41473 11.9424C8.99667 12.0581 9.59987 11.9987 10.1481 11.7716C10.6962 11.5446 11.1648 11.1601 11.4944 10.6667C11.8241 10.1734 12 9.59335 12 9C11.9991 8.20462 11.6828 7.44207 11.1204 6.87965C10.5579 6.31723 9.79538 6.00088 9 6Z"
          fill="currentColor"
        />
        <path d="M26.5859 4.00003L4 26.5859L5.41407 28L28 5.4141L26.5859 4.00003Z" fill="currentColor" />
        <path
          d="M23 28C22.0111 28 21.0444 27.7068 20.2222 27.1574C19.3999 26.6079 18.759 25.8271 18.3806 24.9134C18.0022 23.9998 17.9031 22.9945 18.0961 22.0246C18.289 21.0546 18.7652 20.1637 19.4645 19.4645C20.1637 18.7652 21.0546 18.289 22.0246 18.0961C22.9945 17.9031 23.9998 18.0022 24.9134 18.3806C25.8271 18.759 26.6079 19.3999 27.1574 20.2222C27.7068 21.0444 28 22.0111 28 23C27.9985 24.3256 27.4713 25.5966 26.5339 26.5339C25.5966 27.4713 24.3256 27.9985 23 28ZM23 20C22.4067 20 21.8266 20.1759 21.3333 20.5056C20.8399 20.8352 20.4554 21.3038 20.2284 21.852C20.0013 22.4001 19.9419 23.0033 20.0576 23.5853C20.1734 24.1672 20.4591 24.7018 20.8787 25.1213C21.2982 25.5409 21.8328 25.8266 22.4147 25.9424C22.9967 26.0581 23.5999 25.9987 24.1481 25.7716C24.6962 25.5446 25.1648 25.1601 25.4944 24.6667C25.8241 24.1734 26 23.5933 26 23C25.9991 22.2046 25.6828 21.4421 25.1204 20.8796C24.5579 20.3172 23.7954 20.0009 23 20Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id={uid}>
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default Percent;
