import { Hash, isHash as isHex } from "viem";

/**
 * Checks if the input is a valid (32 byte) Hash
 * @param hash
 */
export const isHash = (hash?: string | null): hash is Hash => {
  if (!hash) {
    return false;
  }
  return isHex(hash); // viem checks the byte size to be correct but then types it as just a Hex
};
