import Highcharts from "highcharts";
import { tooltipPositioner } from "./tooltipPositioner";

export const defaultHighchartsOptions: Highcharts.Options = {
  time: {
    timezoneOffset: new Date().getTimezoneOffset(),
  },
  chart: {
    styledMode: true,
    spacingTop: 12,
    spacingLeft: 20,
    spacingRight: 20,
    spacingBottom: 0,
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    series: {
      animation: false,
    },
  },
  tooltip: {
    shared: true,
    // Below settings are important for custom tooltips
    backgroundColor: "transparent",
    borderRadius: 0,
    borderWidth: 0,
    outside: true,
    hideDelay: 0,
    padding: 0,
    shadow: false,
    useHTML: true,
    positioner: tooltipPositioner,
    // Default is true with Highstock, but needs to be false for followPointer to be active
    split: false,
  },
  title: {
    text: "",
  },
  subtitle: {
    text: "",
  },
  legend: {
    enabled: false,
  },
  yAxis: [
    {
      title: {
        text: "",
      },
      labels: {
        enabled: false,
      },
    },
  ],
};
