import { Stack, StackProps, useDisclosure } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import {
  NoPublicClientError,
  fromDecimals,
  sleep,
  useEthBalance,
  useLocalStorageSnooze,
  useSendAnalyticsEvent,
  DataLayerEventNames,
  useCoinPrices,
  useAssertNetwork,
  getSupportedNetworkFromChainId,
  toDecimals,
} from "@looksrare/utils";
import { useAccount, usePublicClient } from "wagmi";
import { ChainId } from "@looksrare/sdk-v2";
import { INDEXER_REFETCH_DELAY_MS } from "@looksrare/config";
import { TransactionSetter, useHandleModalStep } from "@looksrare/uikit";
import { MoDContract, MoDRoundFragment } from "@looksrare/yolo-games-gql-typegen";
import { EntryPosition } from "../../types";
import { useIsPaused } from "../../network/contract/read/hooks";
import { useEnterRound } from "../../network/contract/write/hooks";
import { RoundEntryHeader } from "./components/RoundEntryHeader";
import { RoundEntryEnteringHeader } from "./components/entering/RoundEntryEnteringHeader";
import { RoundEntryFooter } from "./components/RoundEntryFooter";
import { RoundEntryInput } from "./components/RoundEntryInput";
import { RoundEntryEnteredBody } from "./components/entered/RoundEntryEnteredBody";
import { RoundEntryEnteringBody } from "./components/entering/RoundEntryEnteringBody";
import { RoundEntryPausedBody } from "./components/paused/RoundEntryPausedBody";
import { RoundEntryLoader } from "./components/RoundEntryLoader";
import { RoundEntryExplanationModal } from "./components/RoundEntryExplanationModal";

interface RoundEntryProps extends StackProps {
  round: MoDRoundFragment;
  chainId: ChainId;
  contract: MoDContract;
  isBlocked?: boolean;
  onEntry?: () => unknown | Promise<unknown>;
}

export const RoundEntry = ({ round, chainId, contract, isBlocked, onEntry, ...props }: RoundEntryProps) => {
  // States
  const [entryPosition, setEntryPosition] = useState<EntryPosition>("moon");
  const [entryAmount, setEntryAmount] = useState("0.01");
  const [isEntering, setIsEntering] = useState(false);
  const { isSnoozed: isExplainerModalSnoozed, handleSnoozeIndefinitely: handleSnoozeExplainerModal } =
    useLocalStorageSnooze({
      baseKey: "mod-enter-round-explainer-modal",
    });

  // Modals
  const explainerModalDisclosure = useDisclosure();

  // Helpers
  const { address } = useAccount();
  const enterRound = useEnterRound(contract);
  const publicClient = usePublicClient();
  const sendAnalyticsEvent = useSendAnalyticsEvent();
  const { data: prices } = useCoinPrices();

  // Data
  const { data: ethBalanceWei } = useEthBalance(address!, { enabled: !!address });
  const { data: isPaused = false } = useIsPaused(contract, {
    refetchInterval: 1000,
  });

  // Interpreted values
  const gasBuffer = [ChainId.MAINNET, ChainId.SEPOLIA].includes(chainId) ? 0.01 : 0.001; // Buffer for paying fees
  const roundLockedAt = new Date(round.lockedAt).getTime();
  const roundDuration = round.setting.roundIntervalSecs;
  const userEntry = address ? round.entries?.[0] : undefined;
  const ethBalance = fromDecimals(ethBalanceWei || 0);
  const minEntryAmountWei = BigInt(round.setting.minimumEnterAmount);
  const entryAmountFloat = parseFloat(entryAmount || "0");
  const entryAmountWei = toDecimals(entryAmount);

  // Ui states
  const hasEntered = !!userEntry;
  const isBalanceSufficient = entryAmountFloat <= parseFloat(ethBalance) - gasBuffer;
  const isAboveMinEntry = entryAmountWei >= minEntryAmountWei;
  const isEntryClosed = roundLockedAt <= Date.now();

  const network = getSupportedNetworkFromChainId(chainId);
  const assertNetwork = useAssertNetwork({ network });

  const useHandleTransaction = (setTransaction: TransactionSetter) => {
    return useHandleModalStep({
      onSubmit: async () => {
        await assertNetwork();

        if (!publicClient) {
          throw new NoPublicClientError();
        }

        const hash = await enterRound(BigInt(round.onChainId), entryPosition, entryAmountWei);
        setTransaction(hash);
        const receipt = await publicClient.waitForTransactionReceipt({ hash });

        if (receipt.status === "success") {
          const totalPriceUsd = prices?.eth ? entryAmountFloat * prices?.eth.price : 0;
          sendAnalyticsEvent({
            event: DataLayerEventNames.MORD_DEPOSIT,
            connectedWalletAddress: address,
            totalPriceEth: entryAmount,
            totalPriceUsd: totalPriceUsd.toString(),
          });
          // Wait for the transaction to be indexed
          await sleep(INDEXER_REFETCH_DELAY_MS);

          setTransaction(undefined);
        } else {
          throw new Error(`Entering round failed. Transaction hash ${receipt.transactionHash}`);
        }
      },
      onSuccess: async () => {
        await onEntry?.();
        setIsEntering(false);

        if (!isExplainerModalSnoozed) {
          explainerModalDisclosure.onOpen();
        }
      },
    });
  };

  const handleCloseExplainerModal = useCallback(() => {
    explainerModalDisclosure.onClose();
    handleSnoozeExplainerModal();
  }, [explainerModalDisclosure, handleSnoozeExplainerModal]);

  return (
    <>
      <Stack position="relative" spacing={0} borderRadius="dialog" backgroundColor="ui-01" overflow="hidden" {...props}>
        {(() => {
          if (isPaused) {
            return (
              <>
                <RoundEntryHeader round={round} hideStats />
                <RoundEntryPausedBody />
              </>
            );
          }

          if (isEntering) {
            return (
              <>
                <RoundEntryEnteringHeader entryPosition={entryPosition} onBack={() => setIsEntering(false)} />
                <RoundEntryEnteringBody
                  entryPosition={entryPosition}
                  entryAmountWei={entryAmountWei}
                  useHandleTransaction={useHandleTransaction}
                />
              </>
            );
          }

          if (hasEntered) {
            return (
              <>
                <RoundEntryHeader round={round} />
                <RoundEntryEnteredBody
                  entryPosition={userEntry.moonPosition ? "moon" : "doom"}
                  entryAmount={userEntry.amount}
                />
              </>
            );
          }

          return (
            <>
              <RoundEntryHeader round={round} />
              <RoundEntryInput
                entryPosition={entryPosition}
                entryAmount={entryAmount}
                onChangeEntryPosition={setEntryPosition}
                onChangeEntryAmount={setEntryAmount}
                isBalanceSufficient={isBalanceSufficient}
                minEntryAmountWei={minEntryAmountWei}
                isAboveMinEntry={isAboveMinEntry}
                ethBalanceWei={ethBalanceWei}
              />
              <RoundEntryFooter
                chainId={chainId}
                roundLockedAt={roundLockedAt}
                entryAmountFloat={entryAmountFloat}
                entryPosition={entryPosition}
                roundDuration={roundDuration}
                isBalanceSufficient={isBalanceSufficient}
                isAboveMinEntry={isAboveMinEntry}
                isEntryClosed={isEntryClosed}
                isBlocked={isBlocked}
                onClickEnter={() => setIsEntering(true)}
              />
            </>
          );
        })()}

        {!isPaused && (
          <RoundEntryLoader
            roundLockedAt={roundLockedAt}
            roundDurationInSecs={roundDuration}
            position="absolute"
            bottom={0}
            left={0}
            right={0}
          />
        )}
      </Stack>

      <RoundEntryExplanationModal isOpen={explainerModalDisclosure.isOpen} onClose={handleCloseExplainerModal} />
    </>
  );
};
