import { Icon, IconProps } from "../Icon";

const Upload = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 17L7.41 18.41L15 10.83V30H17V10.83L24.59 18.41L26 17L16 7L6 17ZM6 8V4H26V8H28V4C28 2.89543 27.1046 2 26 2H6C4.89543 2 4 2.89543 4 4V8H6Z"
      fill="currentColor"
    />
  </Icon>
);

export default Upload;
