import { useAccount } from "wagmi";
import { BoxProps, useBreakpointValue } from "@chakra-ui/react";
import { SelfExclusionModal, useWidgetBotOptions } from "@looksrare/uikit";
import { useIsChainSupported, useWidgetBotCrate } from "@looksrare/utils";
import { getPtbContractNameFromNetwork } from "../utils";
import { useGameManager, useGameState, useGetCaveQueryParams } from "../hooks";
import { EnterCaveModal } from "../components/Modals/EnterCave";
import { CaveMobile } from "../components/CaveMobile";
import { CaveDesktop } from "../components/CaveDesktop/CaveDesktop";
import { PtbBackground } from "../components/PtbBackground";
import { SingleClaimPanel, usePtbConfig } from "..";

interface CaveProps extends BoxProps {
  caveDesktopProps?: BoxProps;
  caveMobileProps?: BoxProps;
}

export const Cave = ({ caveDesktopProps, caveMobileProps, ...props }: CaveProps) => {
  useWidgetBotCrate(useWidgetBotOptions());

  const { caveOnChainId, network } = useGetCaveQueryParams();
  const { chain } = useAccount();
  const isChainSupported = useIsChainSupported();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { isMuted, selfTimeoutUntil, isWalletBlocked } = usePtbConfig();
  const [isCaveModalOpen, setIsEnterCaveModalOpen] = useGameState((state) => [
    state.isEnterCaveModalOpen,
    state.setIsEnterCaveModalOpen,
  ]);

  useGameManager();

  const handleClose = () => {
    setIsEnterCaveModalOpen(false);
  };

  return (
    <>
      <PtbBackground {...props}>
        {isMobile ? <CaveMobile {...caveMobileProps} /> : <CaveDesktop {...caveDesktopProps} />}
        {!!caveOnChainId && !!chain && isChainSupported && !isWalletBlocked && (
          <EnterCaveModal
            chainId={chain.id}
            contractName={getPtbContractNameFromNetwork(network)}
            caveOnChainId={caveOnChainId}
            isOpen={isCaveModalOpen}
            onClose={handleClose}
          />
        )}
        <audio src="/sounds/cave.mp3" autoPlay loop muted={isMuted} />
      </PtbBackground>
      {!!selfTimeoutUntil && (
        <SelfExclusionModal endDate={selfTimeoutUntil}>
          <SingleClaimPanel network={network} mt={0} p={0} bg="transparent" border="none" />
        </SelfExclusionModal>
      )}
    </>
  );
};
