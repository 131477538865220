import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const DailyDraw = (props: IconProps) => {
  const [uid1] = useState(uniqueId());
  const [uid2] = useState(uniqueId());
  const [uid3] = useState(uniqueId());

  return (
    <Icon viewBox="0 0 64 64" {...props}>
      <g opacity="0.5" clipPath={`url(#${uid1})`}>
        <ellipse
          cx="16.0543"
          cy="22.437"
          rx="16.0543"
          ry="22.437"
          transform="matrix(0.880919 -0.473268 0.45846 0.888715 5.16602 20.5547)"
          fill="#CCFD07"
        />
      </g>
      <g clipPath={`url(#${uid2})`}>
        <ellipse
          cx="16.0543"
          cy="22.437"
          rx="16.0543"
          ry="22.437"
          transform="matrix(0.880919 -0.473268 0.45846 0.888715 10.541 18.5312)"
          fill="#CCFD07"
        />
        <g clipPath={`url(#${uid3})`}>
          <path
            d="M27.6391 16.6695L27.838 17.3897L37.5303 36.1782L37.7637 36.2957L41.2898 27.8174L27.6391 16.6695Z"
            fill="#121A28"
          />
          <path d="M27.6388 16.669L28.9183 34.4632L37.7634 36.2952L33.0589 27.1758L27.6388 16.669Z" fill="#121A28" />
          <path
            d="M38.6151 37.9475L38.6065 38.1195L42.059 44.8122L42.2971 45.0849L42.1464 29.4698L38.6151 37.9475Z"
            fill="#121A28"
          />
          <path d="M42.2972 45.0839L38.6152 37.9465L29.7715 36.1172L42.2972 45.0839Z" fill="#121A28" />
          <path d="M37.763 36.2913L41.2891 27.8129L33.0586 27.1719L37.763 36.2913Z" fill="#121A28" />
          <path d="M28.9181 34.4642L37.7632 36.2962L33.0587 27.1768L28.9181 34.4642Z" fill="#121A28" />
        </g>
      </g>
      <defs>
        <clipPath id={uid1}>
          <rect
            width="32.1086"
            height="44.874"
            fill="white"
            transform="matrix(0.880919 -0.473268 0.45846 0.888715 5.16602 20.5547)"
          />
        </clipPath>
        <clipPath id={uid2}>
          <rect
            width="32.1086"
            height="44.874"
            fill="white"
            transform="matrix(0.880919 -0.473268 0.45846 0.888715 10.541 18.5312)"
          />
        </clipPath>
        <clipPath id={uid3}>
          <rect
            width="14.0475"
            height="39.2647"
            fill="white"
            transform="matrix(0.880919 -0.473268 0.45846 0.888715 19.7812 16.75)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default DailyDraw;
