import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const TokenYoloShard = (props: IconProps) => {
  const [id] = useState(uniqueId());

  return (
    <Icon viewBox="0 0 96 96" {...props}>
      <g clipPath={`url(#${id})`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.3475 12.2643L58.6609 73.7421C55.5308 75.103 52.1657 76.0659 48.6198 76.5593C45.9917 76.925 43.3887 77.0155 40.84 76.8528L10.1875 19.1237C15.9259 9.33715 25.9257 2.17734 38.0345 0.492301C50.8697 -1.29381 63.1046 3.4846 71.3475 12.2643Z"
          fill="#CCFD07"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.2718 26.0869L64.6406 82.9319C81.1887 79.998 94.4406 66.3559 96.1364 48.8097C96.93 40.5988 95.076 32.7424 91.2718 26.0869Z"
          fill="#CCFD07"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.5155 95.6297C22.6627 96.0595 6.36786 83.9227 2.20846 65.8406C0.194864 57.0869 1.34827 48.3439 4.91747 40.8115L40.5155 95.6297Z"
          fill="#CCFD07"
        />
        <path
          d="M46.8488 20.2877L60.2233 18.4266L52.7783 59.2085L39.4038 61.0697L42.2308 45.7218L32.4937 47.0768L21.5547 23.8076L34.7613 21.9698L43.0975 40.8817L46.8488 20.2877Z"
          fill="#1F2A37"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default TokenYoloShard;
