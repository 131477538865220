import { Icon, IconProps } from "../Icon";

const BtcToken = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 96 96" {...props}>
      <rect width="96" height="96" rx="48" fill="#F09242" />
      <path
        d="M69.1925 41.8028C70.2518 35.49 65.4958 32.0028 58.9493 29.5742L61.2202 21.0992L56.029 19.7083L53.8003 28.0259C52.4337 27.6598 51.0376 27.2857 49.6513 26.9142L51.88 18.5965L46.7282 17.2161L44.4415 25.7501L33.9117 22.9286L32.4232 28.4836C32.4232 28.4836 36.2761 29.4422 36.1986 29.4952C36.9104 29.6044 37.552 29.9856 37.9882 30.5585C38.4244 31.1315 38.6211 31.8513 38.537 32.5665L32.2776 55.9268C32.1978 56.1655 32.0709 56.3859 31.9045 56.5748C31.738 56.7637 31.5354 56.9172 31.3086 57.0265C31.0844 57.1396 30.8396 57.2063 30.589 57.2224C30.3384 57.2386 30.0871 57.2039 29.8502 57.1204C29.9033 57.1979 26.0748 56.1088 26.0748 56.1088L23.4294 62.0492L33.8609 64.8443L31.5373 73.516L36.7285 74.9069L39.0178 66.3631L43.1668 67.4748L40.888 75.9793L46.089 77.3729L48.3862 68.7996C57.2984 70.6186 63.9934 70.0942 66.96 62.0902C69.3497 55.6492 67.0332 51.8672 62.3869 49.3472C65.8031 48.6713 68.4087 46.419 69.1925 41.8028ZM56.9696 58.3174C55.2414 64.767 44.3881 61.0685 40.8683 60.1254L43.9348 48.6812C47.4519 49.6342 58.7742 51.5826 56.9696 58.3174ZM58.8813 41.5478C57.3006 47.4469 48.2707 44.2581 45.3408 43.473L48.1281 33.071C51.0579 33.8561 60.5278 35.403 58.8813 41.5478Z"
        fill="white"
      />
    </Icon>
  );
};

export default BtcToken;
