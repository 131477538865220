import { Icon, IconProps } from "../Icon";

const RenewRefresh = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10H6.78001C9.43614 5.91795 14.4603 4.06836 19.1292 5.4538C23.7982 6.83924 27.0003 11.1299 27 16H29C29.0056 10.5205 25.5749 5.62583 20.4222 3.76189C15.2695 1.89795 9.50123 3.46503 6.00001 7.68002V4.00002H4.00001V12H12V10ZM20 22H25.22C22.5639 26.0821 17.5397 27.9317 12.8708 26.5462C8.20184 25.1608 4.9997 20.8702 5.00001 16H3.00001C2.99437 21.4795 6.42512 26.3742 11.5778 28.2381C16.7305 30.1021 22.4988 28.535 26 24.32V28H28V20H20V22Z"
      fill="currentColor"
    />
  </Icon>
);

export default RenewRefresh;
