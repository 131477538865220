import { useToken } from "@chakra-ui/react";
import { XYDataType } from "@looksrare/uikit";

interface OracleChartStylesProps {
  data: XYDataType;
  currentRoundLockPrice?: number;
}

export const OracleChartStyles = ({ data, currentRoundLockPrice }: OracleChartStylesProps) => {
  const [acid, orange, ui01Inverse, gray300, border01, text03, textInverse, moon, doom, textOnMoon, textOnDoom] =
    useToken("colors", [
      "acid.200",
      "orange.400",
      "ui-01-inverse",
      "gray.300",
      "border-01",
      "text-03",
      "text-inverse",
      "moondoom-moon",
      "moondoom-doom",
      "moondoom-text-on-moon",
      "moondoom-text-on-doom",
    ]);

  const currentPriceColors = (() => {
    if (!currentRoundLockPrice || data.length === 0) {
      return {
        backgroundColor: ui01Inverse,
        color: textInverse,
      };
    }
    const [, currentPrice] = data[data.length - 1];

    if (currentPrice > currentRoundLockPrice) {
      return {
        backgroundColor: moon,
        color: textOnMoon,
      };
    }
    if (currentPrice < currentRoundLockPrice) {
      return {
        backgroundColor: doom,
        color: textOnDoom,
      };
    }

    return {
      backgroundColor: ui01Inverse,
      color: textInverse,
    };
  })();

  return (
    <style jsx global>
      {`
        /**
         * @NOTE
         * This file is only for backwards compatibility on Moon or Doom
         */
        .highcharts-container {
          position: relative;
          overflow: hidden;
          width: 100%;
          height: 100%;
          text-align: left;
          line-height: normal;
          z-index: 0;
          /* #1072 */
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          font-family: var(--lr-fonts-body);
          font-size: 12px;
          user-select: none;
          touch-action: manipulation;
          outline: none;
        }

        .highcharts-root {
          display: block;
        }

        .highcharts-root text {
          stroke-width: 0;
        }

        .highcharts-strong {
          font-weight: bold;
        }

        .highcharts-emphasized {
          font-style: italic;
        }

        .highcharts-anchor {
          cursor: pointer;
        }

        .highcharts-background {
          fill: transparent;
        }

        .highcharts-plot-border,
        .highcharts-plot-background {
          fill: none;
        }

        .highcharts-label-box {
          fill: none;
        }

        .highcharts-button-box {
          fill: inherit;
        }

        .highcharts-tracker-line {
          stroke-linejoin: round;
          stroke: rgba(192, 192, 192, 0.0001);
          stroke-width: 22;
          fill: none;
        }

        .highcharts-tracker-area {
          fill: rgba(192, 192, 192, 0.0001);
          stroke-width: 0;
        }

        /* Titles */
        .highcharts-title {
          fill: #333333;
          font-size: 1.5em;
        }

        .highcharts-subtitle {
          fill: #666666;
          font-size: 1em;
        }

        /* Axes */
        .highcharts-axis-line {
          fill: none;
          stroke: var(--lr-colors-border-01);
        }

        .highcharts-yaxis .highcharts-axis-line {
          stroke-width: 0;
        }

        .highcharts-axis-title {
          fill: #666666;
        }

        .highcharts-axis-labels {
          fill: #666666;
          cursor: default;
          font-size: 0.9em;
        }

        .highcharts-grid-line {
          fill: none;
          stroke: var(--lr-colors-border-01);
        }

        .highcharts-xaxis-grid .highcharts-grid-line {
          stroke-width: 0px;
        }

        .highcharts-tick {
          stroke: var(--lr-colors-border-01);
        }

        .highcharts-yaxis .highcharts-tick {
          stroke-width: 0;
        }

        .highcharts-minor-grid-line {
          stroke: #f2f2f2;
        }

        .highcharts-crosshair-thin {
          stroke-width: 1px;
          stroke: var(--lr-colors-border-01);
        }

        .highcharts-crosshair-category {
          stroke: var(--lr-colors-border-01);
          stroke-opacity: 0.25;
        }

        /* Credits */
        .highcharts-credits {
          cursor: pointer;
          fill: var(--lr-colors-text-03);
          font-size: 0.7em;
          transition: fill 250ms, font-size 250ms;
        }

        .highcharts-credits:hover {
          fill: black;
          font-size: 1em;
        }

        /* Tooltip */
        .highcharts-tooltip {
          cursor: default;
          pointer-events: none;
          white-space: nowrap;
          transition: stroke 150ms;
          z-index: var(--lr-zIndices-tooltip);
        }

        .highcharts-tooltip text {
          fill: var(--lr-colors-text-03);
        }

        .highcharts-tooltip .highcharts-header {
          font-size: var(--lr-fontSizes-xs);
          font-weight: 700;
        }

        .highcharts-tooltip-box {
          stroke-width: 0;
        }

        div.highcharts-tooltip {
          filter: none;
        }

        .highcharts-selection-marker {
          fill: #335cad;
          fill-opacity: 0.25;
        }

        .highcharts-graph {
          fill: none;
          stroke-width: 2px;
          stroke-linecap: round;
          stroke-linejoin: round;
        }

        .highcharts-empty-series {
          stroke-width: 1px;
          fill: none;
          stroke: #cccccc;
        }

        .highcharts-state-hover .highcharts-graph {
          stroke-width: 3;
        }

        .highcharts-point-inactive {
          opacity: 0.2;
          transition: opacity 50ms;
          /* quick in */
        }

        .highcharts-series-inactive {
          opacity: 1;
        }

        .highcharts-state-hover path {
          transition: stroke-width 50ms;
          /* quick in */
        }

        .highcharts-state-normal path {
          transition: stroke-width 250ms;
          /* slow out */
        }

        /* Legend hover affects points and series */
        g.highcharts-series,
        .highcharts-point,
        .highcharts-markers,
        .highcharts-data-labels {
          transition: opacity 250ms;
        }

        .highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
        .highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
        .highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
        .highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
          opacity: 0.2;
        }

        /* Series options */
        /* Default colors */
        .highcharts-color-0 {
          fill: var(--lr-colors-interactive-01);
          stroke: var(--lr-colors-interactive-01);
        }

        .highcharts-color-1 {
          fill: var(--lr-colors-ui-02);
          stroke: var(--lr-colors-ui-02);
        }

        .highcharts-color-2 {
          fill: #90ed7d;
          stroke: #90ed7d;
        }

        .highcharts-color-3 {
          fill: #f7a35c;
          stroke: #f7a35c;
        }

        .highcharts-color-4 {
          fill: #8085e9;
          stroke: #8085e9;
        }

        .highcharts-color-5 {
          fill: #f15c80;
          stroke: #f15c80;
        }

        .highcharts-color-6 {
          fill: #e4d354;
          stroke: #e4d354;
        }

        .highcharts-color-7 {
          fill: #2b908f;
          stroke: #2b908f;
        }

        .highcharts-color-8 {
          fill: #f45b5b;
          stroke: #f45b5b;
        }

        .highcharts-color-9 {
          fill: #91e8e1;
          stroke: #91e8e1;
        }

        .highcharts-line-series {
          fill: var(--lr-colors-interactive-01);
          stroke: var(--lr-colors-interactive-01);
        }

        .highcharts-scatter-series .highcharts-color-0 {
          fill: var(--lr-colors-interactive-03);
          stroke: var(--lr-colors-interactive-03);
        }

        .highcharts-area {
          fill-opacity: 0.75;
          stroke-width: 0;
        }

        .highcharts-markers {
          stroke-width: 1px;
          stroke: #ffffff;
        }

        .highcharts-a11y-markers-hidden
          .highcharts-point:not(.highcharts-point-hover):not(.highcharts-a11y-marker-visible),
        .highcharts-a11y-marker-hidden {
          opacity: 0;
        }

        .highcharts-point {
          stroke-width: 1px;
        }

        .highcharts-dense-data .highcharts-point {
          stroke-width: 0;
        }

        .highcharts-data-label {
          font-size: 0.9em;
          font-weight: bold;
        }

        .highcharts-data-label-box {
          fill: none;
          stroke-width: 0;
        }

        .highcharts-data-label text,
        text.highcharts-data-label {
          fill: #333333;
        }

        .highcharts-data-label-connector {
          fill: none;
        }

        .highcharts-data-label-hidden {
          pointer-events: none;
        }

        .highcharts-halo {
          fill-opacity: 0.25;
          stroke-width: 0;
        }

        .highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
        .highcharts-markers .highcharts-point-select {
          fill: #cccccc;
          stroke: #000000;
        }

        .highcharts-column-series rect.highcharts-point {
          stroke: var(--lr-colors-ui-02);
        }

        .highcharts-column-series .highcharts-point {
          transition: fill-opacity 250ms;
        }

        .highcharts-column-series .highcharts-point-hover {
          fill-opacity: 0.75;
          transition: fill-opacity 50ms;
        }

        .highcharts-pie-series .highcharts-point {
          stroke-linejoin: round;
          stroke: #ffffff;
        }

        .highcharts-pie-series .highcharts-point-hover {
          fill-opacity: 0.75;
          transition: fill-opacity 50ms;
        }

        .highcharts-funnel-series .highcharts-point {
          stroke-linejoin: round;
          stroke: #ffffff;
        }

        .highcharts-funnel-series .highcharts-point-hover {
          fill-opacity: 0.75;
          transition: fill-opacity 50ms;
        }

        .highcharts-funnel-series .highcharts-point-select {
          fill: inherit;
          stroke: inherit;
        }

        .highcharts-pyramid-series .highcharts-point {
          stroke-linejoin: round;
          stroke: #ffffff;
        }

        .highcharts-pyramid-series .highcharts-point-hover {
          fill-opacity: 0.75;
          transition: fill-opacity 50ms;
        }

        .highcharts-pyramid-series .highcharts-point-select {
          fill: inherit;
          stroke: inherit;
        }

        .highcharts-solidgauge-series .highcharts-point {
          stroke-width: 0;
        }

        .highcharts-treemap-series .highcharts-point {
          stroke-width: 1px;
          stroke: #e6e6e6;
          transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
        }

        .highcharts-treemap-series .highcharts-point-hover {
          stroke: var(--lr-colors-text-03);
          transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
        }

        .highcharts-treemap-series .highcharts-above-level {
          display: none;
        }

        .highcharts-treemap-series .highcharts-internal-node {
          fill: none;
        }

        .highcharts-treemap-series .highcharts-internal-node-interactive {
          fill-opacity: 0.15;
          cursor: pointer;
        }

        .highcharts-treemap-series .highcharts-internal-node-interactive:hover {
          fill-opacity: 0.75;
        }

        .highcharts-vector-series .highcharts-point {
          fill: none;
          stroke-width: 2px;
        }

        .highcharts-windbarb-series .highcharts-point {
          fill: none;
          stroke-width: 2px;
        }

        .highcharts-lollipop-stem {
          stroke: #000000;
        }

        .highcharts-focus-border {
          fill: none;
          stroke-width: 2px;
        }

        .highcharts-legend-item-hidden .highcharts-focus-border {
          fill: none !important;
        }

        /* Legend */
        .highcharts-legend-box {
          fill: none;
          stroke-width: 0;
        }

        .highcharts-legend-item > text {
          fill: #333333;
          font-weight: bold;
          font-size: 1em;
          cursor: pointer;
          stroke-width: 0;
        }

        .highcharts-legend-item:hover text {
          fill: #000000;
        }

        .highcharts-legend-item-hidden * {
          fill: #cccccc !important;
          stroke: #cccccc !important;
          transition: fill 250ms;
        }

        .highcharts-legend-nav-active {
          fill: #003399;
          cursor: pointer;
        }

        .highcharts-legend-nav-inactive {
          fill: #cccccc;
        }

        circle.highcharts-legend-nav-active,
        circle.highcharts-legend-nav-inactive {
          /* tracker */
          fill: rgba(192, 192, 192, 0.0001);
        }

        .highcharts-legend-title-box {
          fill: none;
          stroke-width: 0;
        }

        /* Bubble legend */
        .highcharts-bubble-legend-symbol {
          stroke-width: 2;
          fill-opacity: 0.5;
        }

        .highcharts-bubble-legend-connectors {
          stroke-width: 1;
        }

        .highcharts-bubble-legend-labels {
          fill: #333333;
        }

        /* Loading */
        .highcharts-loading {
          position: absolute;
          background-color: #ffffff;
          opacity: 0.5;
          text-align: center;
          z-index: 10;
          transition: opacity 250ms;
        }

        .highcharts-loading-hidden {
          height: 0 !important;
          opacity: 0;
          overflow: hidden;
          transition: opacity 250ms, height 250ms step-end;
        }

        .highcharts-loading-inner {
          font-weight: bold;
          position: relative;
          top: 45%;
        }

        /* Plot bands and polar pane backgrounds */
        .highcharts-plot-band,
        .highcharts-pane {
          fill: #000000;
          fill-opacity: 0.05;
        }

        .highcharts-plot-line {
          fill: none;
          stroke: var(--lr-colors-text-03);
          stroke-width: 1px;
        }

        /* Highcharts More and modules */
        .highcharts-boxplot-box {
          fill: #ffffff;
        }

        .highcharts-boxplot-median {
          stroke-width: 2px;
        }

        .highcharts-bubble-series .highcharts-point {
          fill-opacity: 0.5;
        }

        .highcharts-errorbar-series .highcharts-point {
          stroke: #000000;
        }

        .highcharts-gauge-series .highcharts-data-label-box {
          stroke: #cccccc;
          stroke-width: 1px;
        }

        .highcharts-gauge-series .highcharts-dial {
          fill: #000000;
          stroke-width: 0;
        }

        .highcharts-polygon-series .highcharts-graph {
          fill: inherit;
          stroke-width: 0;
        }

        .highcharts-waterfall-series .highcharts-graph {
          stroke: #333333;
          stroke-dasharray: 1, 3;
        }

        .highcharts-sankey-series .highcharts-point {
          stroke-width: 0;
        }

        .highcharts-sankey-series .highcharts-link {
          transition: fill 250ms, fill-opacity 250ms;
          fill-opacity: 0.5;
        }

        .highcharts-sankey-series .highcharts-point-hover.highcharts-link {
          transition: fill 50ms, fill-opacity 50ms;
          fill-opacity: 1;
        }

        .highcharts-venn-series .highcharts-point {
          fill-opacity: 0.75;
          stroke: #cccccc;
          transition: stroke 250ms, fill-opacity 250ms;
        }

        .highcharts-venn-series .highcharts-point-hover {
          fill-opacity: 1;
          stroke: #cccccc;
        }

        /* Highstock */
        .highcharts-navigator-mask-outside {
          fill-opacity: 0;
        }

        .highcharts-navigator-mask-inside {
          fill: #6685c2;
          /* navigator.maskFill option */
          fill-opacity: 0.25;
          cursor: ew-resize;
        }

        .highcharts-navigator-outline {
          stroke: #cccccc;
          fill: none;
        }

        .highcharts-navigator-handle {
          stroke: #cccccc;
          fill: #f2f2f2;
          cursor: ew-resize;
        }

        .highcharts-navigator-series {
          fill: #335cad;
          stroke: #335cad;
        }

        .highcharts-navigator-series .highcharts-graph {
          stroke-width: 1px;
        }

        .highcharts-navigator-series .highcharts-area {
          fill-opacity: 0.05;
        }

        .highcharts-navigator-xaxis .highcharts-axis-line {
          stroke-width: 0;
        }

        .highcharts-navigator-xaxis .highcharts-grid-line {
          stroke-width: 1px;
          stroke: #e6e6e6;
        }

        .highcharts-navigator-xaxis.highcharts-axis-labels {
          fill: var(--lr-colors-text-03);
        }

        .highcharts-navigator-yaxis .highcharts-grid-line {
          stroke-width: 0;
        }

        .highcharts-scrollbar-thumb {
          fill: #cccccc;
          stroke: #cccccc;
          stroke-width: 1px;
        }

        .highcharts-scrollbar-button {
          fill: #e6e6e6;
          stroke: #cccccc;
          stroke-width: 1px;
        }

        .highcharts-scrollbar-arrow {
          fill: #666666;
        }

        .highcharts-scrollbar-rifles {
          stroke: #1b0a0a;
          stroke-width: 1px;
        }

        .highcharts-scrollbar-track {
          fill: #f2f2f2;
          stroke: #f2f2f2;
          stroke-width: 1px;
        }

        .highcharts-button {
          fill: #f7f7f7;
          stroke: #cccccc;
          cursor: default;
          stroke-width: 1px;
          transition: fill 250ms;
        }

        .highcharts-button text {
          fill: #333333;
        }

        .highcharts-button-hover {
          transition: fill 0ms;
          fill: #e6e6e6;
          stroke: #cccccc;
        }

        .highcharts-button-hover text {
          fill: #333333;
        }

        .highcharts-button-pressed {
          font-weight: bold;
          fill: #e6ebf5;
          stroke: #cccccc;
        }

        .highcharts-button-pressed text {
          fill: #333333;
          font-weight: bold;
        }

        .highcharts-button-disabled text {
          fill: #333333;
        }

        .highcharts-range-selector-buttons .highcharts-button {
          stroke-width: 0px;
        }

        .highcharts-range-label rect {
          fill: none;
        }

        .highcharts-range-label text {
          fill: #666666;
        }

        .highcharts-range-input rect {
          fill: none;
        }

        .highcharts-range-input text {
          fill: #333333;
        }

        .highcharts-range-input {
          stroke-width: 1px;
          stroke: #cccccc;
        }

        input.highcharts-range-selector {
          position: absolute;
          border: 0;
          width: 1px;
          /* Chrome needs a pixel to see it */
          height: 1px;
          padding: 0;
          text-align: center;
          left: -9em;
          /* #4798 */
        }

        .highcharts-crosshair-label text {
          fill: #ffffff;
          font-size: 1.1em;
        }

        .highcharts-crosshair-label .highcharts-label-box {
          fill: inherit;
        }

        .highcharts-candlestick-series .highcharts-point {
          stroke: #000000;
          stroke-width: 1px;
        }

        .highcharts-candlestick-series .highcharts-point-up {
          fill: var(--lr-colors-support-success);
          stroke: var(--lr-colors-support-success);
        }

        .highcharts-candlestick-series .highcharts-point-down {
          fill: var(--lr-colors-support-error);
          stroke: var(--lr-colors-support-error);
        }

        .highcharts-hollowcandlestick-series .highcharts-point-down {
          fill: #f21313;
          stroke: #f21313;
        }

        .highcharts-hollowcandlestick-series .highcharts-point-down-bearish-up {
          fill: #06b535;
          stroke: #06b535;
        }

        .highcharts-hollowcandlestick-series .highcharts-point-up {
          fill: transparent;
          stroke: #06b535;
        }

        .highcharts-ohlc-series .highcharts-point-hover {
          stroke-width: 3px;
        }

        .highcharts-flags-series .highcharts-point .highcharts-label-box {
          stroke: var(--lr-colors-text-03);
          fill: #ffffff;
          transition: fill 250ms;
        }

        .highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
          stroke: #000000;
          fill: var(--lr-colors-border-01);
        }

        .highcharts-flags-series .highcharts-point text {
          fill: #000000;
          font-size: 0.9em;
          font-weight: bold;
        }

        /* Highcharts Maps */
        .highcharts-map-series .highcharts-point {
          transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
          stroke: #cccccc;
          stroke-width: inherit;
        }

        .highcharts-map-series .highcharts-point-hover {
          transition: fill 0ms, fill-opacity 0ms;
          fill-opacity: 0.5;
        }

        .highcharts-mapline-series .highcharts-point {
          fill: none;
        }

        .highcharts-heatmap-series .highcharts-point {
          stroke-width: 0;
        }

        .highcharts-map-navigation {
          font-size: 1.3em;
          font-weight: bold;
          text-align: center;
        }

        .highcharts-mapview-inset-border {
          stroke: #cccccc;
          stroke-width: 1px;
          fill: none;
        }

        .highcharts-coloraxis {
          stroke-width: 0;
        }

        .highcharts-coloraxis-marker {
          fill: var(--lr-colors-text-03);
        }

        .highcharts-null-point {
          fill: #f7f7f7;
        }

        /* 3d charts */
        .highcharts-3d-frame {
          fill: transparent;
        }

        /* Exporting module */
        .highcharts-contextbutton {
          fill: #ffffff;
          /* needed to capture hover */
          stroke: none;
          stroke-linecap: round;
        }

        .highcharts-contextbutton:hover {
          fill: #e6e6e6;
          stroke: #e6e6e6;
        }

        .highcharts-button-symbol {
          stroke: #666666;
          stroke-width: 3px;
        }

        .highcharts-menu {
          border: 1px solid var(--lr-colors-text-03);
          background: #ffffff;
          padding: 5px 0;
          box-shadow: 3px 3px 10px #888;
        }

        .highcharts-menu-item {
          padding: 0.5em 1em;
          background: none;
          color: #333333;
          cursor: pointer;
          transition: background 250ms, color 250ms;
        }

        .highcharts-menu-item:hover {
          background: #335cad;
          color: #ffffff;
        }

        /* Breadcrumbs */
        .highcharts-breadcrumbs-button {
          fill: none;
          stroke-width: 0;
          cursor: pointer;
        }

        .highcharts-breadcrumbs-separator {
          fill: #666666;
        }

        /* Drilldown module */
        .highcharts-drilldown-point {
          cursor: pointer;
        }

        .highcharts-drilldown-data-label text,
        text.highcharts-drilldown-data-label,
        .highcharts-drilldown-axis-label {
          cursor: pointer;
          fill: #003399;
          font-weight: bold;
          text-decoration: underline;
        }

        /* No-data module */
        .highcharts-no-data text {
          font-weight: bold;
          font-size: 12px;
          fill: #666666;
        }

        /* Drag-panes module */
        .highcharts-axis-resizer {
          cursor: ns-resize;
          stroke: black;
          stroke-width: 2px;
        }

        /* Bullet type series */
        .highcharts-bullet-target {
          stroke-width: 0;
        }

        /* Lineargauge type series */
        .highcharts-lineargauge-target {
          stroke-width: 1px;
          stroke: #333333;
        }

        .highcharts-lineargauge-target-line {
          stroke-width: 1px;
          stroke: #333333;
        }

        /* Annotations module */
        .highcharts-annotation-label-box {
          stroke-width: 1px;
          stroke: #000000;
          fill: #000000;
          fill-opacity: 0.75;
        }

        .highcharts-annotation-label text {
          fill: #e6e6e6;
        }

        /* A11y module */
        .highcharts-a11y-proxy-button {
          border-width: 0;
          background-color: transparent;
          cursor: pointer;
          outline: none;
          opacity: 0.001;
          z-index: 999;
          overflow: hidden;
          padding: 0;
          margin: 0;
          display: block;
          position: absolute;
        }

        .highcharts-a11y-proxy-group li {
          list-style: none;
        }

        .highcharts-visually-hidden {
          position: absolute;
          width: 1px;
          height: 1px;
          overflow: hidden;
          white-space: nowrap;
          clip: rect(1px, 1px, 1px, 1px);
          margin-top: -3px;
          opacity: 0.01;
        }

        .highcharts-a11y-invisible {
          visibility: hidden;
        }

        .highcharts-a11y-proxy-container,
        .highcharts-a11y-proxy-container-before,
        .highcharts-a11y-proxy-container-after {
          position: absolute;
          white-space: nowrap;
        }

        g.highcharts-series,
        .highcharts-markers,
        .highcharts-point {
          outline: none;
        }

        // Vertical grid lines
        .oracle-chart .highcharts-xaxis-grid .highcharts-grid-line,
        .oracle-chart .highcharts-yaxis-grid .highcharts-grid-line {
          stroke-width: 1px;
          stroke: ${border01};
        }

        // Labels styling
        .oracle-chart .highcharts-axis-labels {
          font-size: 12px;
          fill: ${text03};
        }

        // Current round lock price line
        .oracle-chart .highcharts-plot-line {
          stroke: ${orange};
          stroke-dasharray: 3px;
        }
        .oracle-chart .highcharts-plot-line-label {
          color: ${textInverse};
          padding-right: 4px;
          padding-left: 8px;
          background-color: ${gray300};
          line-height: 16px;
          transform: translate(100%, 50%);
          clip-path: polygon(0% 50%, 6px 0%, 100% 0%, 100% 100%, 6px 100%);
        }

        // Historic price line
        .oracle-chart .highcharts-series-0 {
          stroke: ${acid};
          filter: drop-shadow(0px 0px 4px ${acid});
        }
        .oracle-chart .highcharts-series.highcharts-series-1 {
          opacity: 0;
        }

        // Previous round markers
        .oracle-chart .highcharts-markers.highcharts-series-1 .highcharts-point.MOON {
          stroke: ${moon};
        }
        .oracle-chart .highcharts-markers.highcharts-series-1 .highcharts-point.DOOM {
          stroke: ${doom};
        }
        .oracle-chart .highcharts-markers.highcharts-series-1 .highcharts-point.HOUSE {
          stroke: ${text03};
        }

        // Current price marker
        .oracle-chart .highcharts-markers.highcharts-series-2 .highcharts-point {
          stroke: ${currentPriceColors.backgroundColor};
          fill: ${currentPriceColors.backgroundColor};
          filter: drop-shadow(0px 0px 4px ${currentPriceColors.backgroundColor});
        }
        .oracle-chart .highcharts-data-label span {
          color: ${currentPriceColors.color};
          padding-right: 4px;
          padding-left: 8px;
          line-height: 16px;
          font-weight: 400;
          font-size: 12px;
          background-color: ${currentPriceColors.backgroundColor};
          transform: translate(calc(100% + 12px), 50%);
          clip-path: polygon(0% 50%, 6px 0%, 100% 0%, 100% 100%, 6px 100%);
        }
      `}
    </style>
  );
};
