import React, { FC, PropsWithChildren } from "react";
import { forwardRef, Text as ChakraText, TextProps as ChakraTextProps } from "@chakra-ui/react";
import { base, fontWeights } from "@looksrare/chakra-theme";

export interface TextProps extends ChakraTextProps {
  bold?: boolean;
}

export const Text = forwardRef<TextProps, "div">(
  ({ bold, fontWeight, textStyle = "body", color = "text-01", noOfLines, wordBreak, ...props }, ref) => (
    <ChakraText
      color={color}
      textStyle={textStyle}
      fontWeight={bold ? fontWeights.bold : fontWeight}
      ref={ref}
      noOfLines={noOfLines}
      wordBreak={wordBreak || (noOfLines === 1 ? "break-all" : undefined)}
      {...props}
    />
  )
);

export const TooltipText: FC<PropsWithChildren<TextProps>> = ({ children, ...props }) => (
  <Text variant="tooltip" textStyle="detail" color="text-inverse" {...props}>
    {children}
  </Text>
);

export interface RainbowTextProps extends Omit<TextProps, "children"> {
  animate?: boolean;
  bg?: string;
  /*
  Using "any" as there seems to be no better workaround:
  this seems to be a mistake in react-i18next types, see
  https://github.com/i18next/react-i18next/issues/1504
  @TODO React 18 refine type
   */
  children?: TextProps["children"] | any;
}

export const RainbowText = ({ children, animate, bg = base.conicGradient, ...props }: RainbowTextProps) => {
  const baseSx = { WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" };

  const sx = animate
    ? {
        backgroundSize: "200% 100%",
        animation: "hue 6s infinite alternate",
        "@keyframes hue": {
          "0%": {
            WebkitFilter: "hue-rotate(0deg)",
          },
          "100%": {
            WebkitFilter: "hue-rotate(-1turn)",
          },
        },
        ...baseSx,
      }
    : baseSx;

  return (
    <Text bg={bg} sx={sx} {...props}>
      {children}
    </Text>
  );
};
