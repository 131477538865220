import { Icon, IconProps } from "../Icon";

const Tag = (props: IconProps) => (
  <Icon {...props}>
    <svg>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.52 30C17.7246 29.9993 16.9621 29.6828 16.4 29.12L2.88 15.61C2.31723 15.0479 2.0007 14.2854 2 13.49V5C2 3.34315 3.34315 2 5 2H13.49C14.2854 2.0007 15.0479 2.31723 15.61 2.88L29.12 16.39C29.6849 16.9529 30.0024 17.7175 30.0024 18.515C30.0024 19.3125 29.6849 20.0771 29.12 20.64L20.64 29.12C20.0779 29.6828 19.3154 29.9993 18.52 30ZM8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM4 5C4 4.44772 4.44772 4 5 4H13.49C13.7572 4.00111 14.0129 4.10914 14.2 4.3L27.71 17.81C28.0977 18.2 28.0977 18.83 27.71 19.22L19.22 27.71C18.83 28.0977 18.2 28.0977 17.81 27.71L4.3 14.2C4.10914 14.0129 4.00111 13.7572 4 13.49V5Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
);

export default Tag;
