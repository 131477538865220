import { ChainIcon, NextLink, useGameSupportedNetworks } from "@looksrare/uikit";
import capitalize from "lodash/capitalize";
import { Tabs, TabList, Tab, Box } from "@chakra-ui/react";
import { useGetHistoryQueryParams } from "../hooks/useGetHistoryQueryParams";

export const HistoryTabs = () => {
  const supportedNetworks = useGameSupportedNetworks();
  const { network } = useGetHistoryQueryParams();

  if (supportedNetworks.length === 1) {
    return null;
  }

  const tabIndex = supportedNetworks.findIndex((supportedNetwork) => supportedNetwork === network);

  return (
    <Tabs
      position="relative"
      variant="blank"
      defaultIndex={tabIndex}
      zIndex={1}
      mb={8}
      borderBottom="1px solid #2A3136"
    >
      <TabList justifyContent="flex-start">
        {supportedNetworks.map((supportedNetwork) => (
          <NextLink key={supportedNetwork} href={`/poke-the-bear/history/${supportedNetwork}`}>
            <Tab>
              <Box display="flex" alignItems="center" gap={1}>
                {capitalize(supportedNetwork)} <ChainIcon network={supportedNetwork} boxSize={6} />
              </Box>
            </Tab>
          </NextLink>
        ))}
      </TabList>
    </Tabs>
  );
};
