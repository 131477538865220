import { Icon, IconProps } from "../Icon";

const GridRelaxed = (props: IconProps) => (
  <Icon width="32" height="32" viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 5C3 3.89543 3.89543 3 5 3H11H21H27C28.1046 3 29 3.89543 29 5V11V21V27C29 28.1046 28.1046 29 27 29H21H11H5C3.89543 29 3 28.1046 3 27V21V11V5ZM5 13L5 19H11V13H5ZM11 11L5 11V5L11 5V11ZM13 13V19H19V13L13 13ZM19 11H13V5H19V11ZM21 13V19H27V13H21ZM27 11L21 11V5L27 5V11ZM27 21H21V27H27V21ZM19 21H13V27H19V21ZM11 21H5V27H11V21Z"
    />
  </Icon>
);

export default GridRelaxed;
