import { VStack } from "@chakra-ui/react";
import { ClockIcon, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";

export const RoundEntryPausedBody = () => {
  const { t } = useTranslation();

  return (
    <VStack spacing={4} px={4} py={16}>
      <ClockIcon boxSize={16} color="acid.200" />

      <VStack spacing={2}>
        <Text textStyle="heading-03" textAlign="center" color="text-01" bold>
          {t("Entry Paused")}
        </Text>

        <Text textStyle="body" textAlign="center" color="text-02" whiteSpace="pre-wrap">
          {t(
            "mod::Moon or Doom is currently paused.\nIf you entered a canceled round, you can withdraw your entry from the “Claim Winnings” view."
          )}
        </Text>
      </VStack>
    </VStack>
  );
};
