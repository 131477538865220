import { cssVar } from "@chakra-ui/react";
import { StyleFunctionProps, mode } from "@chakra-ui/theme-tools";

export const PopoverTheme = {
  baseStyle: (props: StyleFunctionProps) => {
    const shadowColor = mode("blackAlpha.50", "blackAlpha.400")(props);
    // Targetting arrow shadow color variable as in the popover theme https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/popover.ts#L25
    const $arrowShadowColor = cssVar("popper-arrow-shadow-color");

    return {
      content: {
        color: "text-inverse",
        width: "auto",
        maxWidth: "20rem",
        bg: "ui-inverse",
        zIndex: "tooltip",
        border: "none",
        [$arrowShadowColor.variable]: shadowColor,
      },
      header: {
        color: "text-inverse",
      },
      body: {
        color: "text-inverse",
        p: 0,
      },
      footer: {
        color: "text-inverse",
      },
      popper: {
        zIndex: "tooltip",
      },
    };
  },
  variants: {
    popover: {
      content: {
        borderRadius: "container",
        p: 4,
      },
    },
    tooltip: {
      content: {
        borderRadius: "mini",
        px: 4,
        py: 2,
      },
    },
  },
  defaultProps: {
    variant: "popover",
  },
};
