import { usePublicClient } from "wagmi";
import { Address, getAddress } from "viem";
import { NoPublicClientError, getIsValidAddress, toDecimals } from "@looksrare/utils";
import { useQuery } from "@tanstack/react-query";
import { IUniswapV3PoolAbi, QuoterV2Abi, addressesByNetwork } from "@looksrare/config";
import { ChainId } from "@looksrare/config";

/**
 * Interfaces & functions for useGetWethLooksPrice taken from uniswap docs.
 * Note: The docs are not great
 * https://docs.uniswap.org/sdk/guides/creating-a-pool
 * https://docs.uniswap.org/protocol/reference/periphery/lens/Quoter#quoteexactinputsingle
 */

interface LpPrices {
  lpTotalSupply?: bigint;
  lpPriceWeth?: bigint;
  lpPriceLooks?: bigint;
  wethPriceInLooks?: bigint;
  looksPriceInWeth?: bigint;
  isSuccess: boolean;
  isLoading: boolean;
}

interface QueryProps {
  wethPriceInLooks: bigint;
  looksPriceInWeth: bigint;
}

interface Immutables {
  token0: string;
  token1: string;
  fee: number;
}

// @see https://docs.uniswap.org/contracts/v3/reference/deployments
const UNISWAP_V3_QUOTER_V2_ADDRESS: Address = "0x61fFE014bA17989E743c5F6cB21bF9697530B21e";

export const useGetWethLooksPrice = (chainId: ChainId) => {
  const publicClient = usePublicClient();

  async function getPoolImmutables(): Promise<Immutables> {
    if (!publicClient) {
      throw new NoPublicClientError();
    }
    const contractInfo = {
      address: addressesByNetwork[chainId].LOOKS_LP_V3,
      abi: IUniswapV3PoolAbi,
    } as const;
    const [token0, token1, fee] = await publicClient.multicall({
      allowFailure: false,
      contracts: [
        {
          ...contractInfo,
          functionName: "token0",
        },
        {
          ...contractInfo,
          functionName: "token1",
        },
        {
          ...contractInfo,
          functionName: "fee",
        },
      ],
    });

    return {
      token0,
      token1,
      fee,
    };
  }

  type GetWethLooksPriceParams = { amountInWei: bigint; currencyIn: "WETH" | "LOOKS" };
  /**
   * Input the amount and input currency, output the quoted price for the pair.
   * eg getWethLooksPrice({ amount: parseUnits("1", 18), currencyIn: WETH }) returns a bigint representing the quoted LOOKS equivalent to 1 WETH
   */
  const getWethLooksPrice = async ({ amountInWei, currencyIn }: GetWethLooksPriceParams) => {
    if (!publicClient) {
      throw new NoPublicClientError();
    }
    const immutables = await getPoolImmutables();
    const { token0: wethErc20Address, token1: looksErc20Address, fee } = immutables;

    const [currencyInAddress, currencyOutAddress] =
      currencyIn === "WETH" ? [wethErc20Address, looksErc20Address] : [looksErc20Address, wethErc20Address];
    const { result } = await publicClient.simulateContract({
      address: UNISWAP_V3_QUOTER_V2_ADDRESS,
      abi: QuoterV2Abi,
      functionName: "quoteExactInputSingle",
      args: [
        {
          tokenIn: getAddress(currencyInAddress),
          tokenOut: getAddress(currencyOutAddress),
          fee,
          amountIn: amountInWei,
          sqrtPriceLimitX96: 0n,
        },
      ],
    });

    return result[0];
  };

  return getWethLooksPrice;
};

export const useLooksLpPriceData = (chainId: ChainId) => {
  const getWethLooksPrice = useGetWethLooksPrice(chainId);

  return useQuery({
    queryKey: ["lp-price"],
    queryFn: async (): Promise<QueryProps> => {
      const [wethPriceInLooks, looksPriceInWeth] = await Promise.all([
        getWethLooksPrice({ amountInWei: toDecimals("1"), currencyIn: "WETH" }),
        getWethLooksPrice({ amountInWei: toDecimals("1"), currencyIn: "LOOKS" }),
      ]);
      return { wethPriceInLooks, looksPriceInWeth };
    },
    enabled: chainId !== ChainId.HARDHAT && getIsValidAddress(addressesByNetwork[chainId].LOOKS_LP_V3),
  });
};

export const useLooksLpPrice = (chainId: ChainId): LpPrices => {
  const { data, isSuccess, isLoading } = useLooksLpPriceData(chainId);
  const { wethPriceInLooks, looksPriceInWeth } = data || {};

  return {
    wethPriceInLooks,
    looksPriceInWeth,
    isSuccess,
    isLoading,
  };
};
