import { useEffect, useState } from "react";
import { Box, Flex, FlexProps, Spinner, useClipboard, useDisclosure } from "@chakra-ui/react";
import { Address } from "viem";
import { useTranslation } from "next-i18next";
import { formatAddress, getExplorerLink, useUserEns } from "@looksrare/utils";
import {
  Button,
  ButtonProps,
  CopyFileIcon,
  IconLinkButton,
  LaunchOpenInNewIcon,
  Popover,
  Text,
  TooltipText,
} from "@looksrare/uikit";
import { getDefaultChain } from "@/utils";

export interface CopyAddressProps extends FlexProps {
  address: Address;
  showEnsDomain?: boolean;
  showCopyIcon?: boolean;
  truncateAddress?: boolean;
  startLength?: number;
  endLength?: number;
  iconButtonProps?: ButtonProps;
  copyButtonProps?: ButtonProps;
  size?: "xs" | "sm";
}

export const CopyAddress = ({
  address,
  showEnsDomain = true,
  showCopyIcon = true,
  truncateAddress = true,
  startLength = 4,
  endLength = 4,
  iconButtonProps = {},
  copyButtonProps = {},
  size = "xs",
  ...props
}: CopyAddressProps) => {
  const { t } = useTranslation();
  const { onCopy, hasCopied } = useClipboard(address);
  const {
    isOpen: showAddress,
    onToggle: onToggleEnsDisclosure,
    onClose: onCloseEnsDisclosure,
  } = useDisclosure({ defaultIsOpen: true });
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const userEnsQuery = useUserEns(address, { enabled: showEnsDomain });
  const ensDomain = userEnsQuery.data ?? "";
  const chain = getDefaultChain();

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (hasCopied) {
      setTooltipIsOpen(true);
      timer = setTimeout(() => {
        setTooltipIsOpen(false);
      }, 2000);
    }

    return () => {
      if (timer) {
        setTooltipIsOpen(false);
        clearTimeout(timer);
      }
    };
  }, [hasCopied, setTooltipIsOpen]);

  useEffect(() => {
    if (showEnsDomain && ensDomain) {
      onCloseEnsDisclosure();
    }
  }, [ensDomain, showEnsDomain, onCloseEnsDisclosure]);

  const handleMouse = () => {
    if (showEnsDomain && ensDomain) {
      onToggleEnsDisclosure();
    }
  };

  const rightIcon = (() => {
    if (showCopyIcon) {
      return userEnsQuery.isFetching ? <Spinner boxSize={5} /> : <CopyFileIcon boxSize={5} />;
    }
    return undefined;
  })();

  return (
    <Flex
      onMouseOver={handleMouse}
      onMouseOut={handleMouse}
      title={ensDomain || address}
      height={size === "xs" ? 8 : 10}
      {...props}
    >
      <Popover isOpen={tooltipIsOpen} placement="top" label={<TooltipText>{t("Copied!")}</TooltipText>}>
        <Button
          onClick={onCopy}
          size={size}
          colorScheme="gray"
          rightIcon={rightIcon}
          mr={0.5}
          square
          {...copyButtonProps}
        >
          <Box as="span" position="relative">
            <Text
              as="span"
              textStyle={size === "sm" ? "detail" : "helper"}
              noOfLines={1}
              bold
              visibility={showAddress ? "visible" : "hidden"}
            >
              {truncateAddress ? formatAddress(address, startLength, endLength) : address}
            </Text>
            {showEnsDomain && ensDomain && (
              <Text
                as="span"
                textStyle={size === "sm" ? "detail" : "helper"}
                noOfLines={1}
                bold
                visibility={showAddress ? "hidden" : "visible"}
                position="absolute"
                left={0}
                maxWidth="100%"
              >
                {ensDomain}
              </Text>
            )}
          </Box>
        </Button>
      </Popover>
      <IconLinkButton
        size={size}
        variant="ghost"
        colorScheme="gray"
        isExternal
        href={getExplorerLink(address, "address", chain)}
        aria-label="blastscan"
        borderRadius="square"
        {...iconButtonProps}
      >
        <LaunchOpenInNewIcon boxSize={5} />
      </IconLinkButton>
    </Flex>
  );
};
