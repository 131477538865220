import { Trans } from "next-i18next";
import { PtbRoundLogType } from "../../../../types";
import { LogText, LogTextHighlight } from "./LogText";
import type { LogTextProps, RoundLogTextGetter } from "./types";

const PlayerReadyText1 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerReady1">
      You&apos;re up,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      . Poke him good.
    </Trans>
  </LogText>
);

const PlayerReadyText2 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerReady2">
      Godspeed,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      .
    </Trans>
  </LogText>
);

const PlayerReadyText3 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerReady3">
      Go for it,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      .
    </Trans>
  </LogText>
);

const PlayerReadyText4 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerReady4">
      Careful,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      . He&apos;s bound to wake soon.
    </Trans>
  </LogText>
);

const PlayerReadyText5 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerReady5">
      GLHF,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      .
    </Trans>
  </LogText>
);

const PlayerReadyText6 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerReady6">
      Stop shaking,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      .
    </Trans>
  </LogText>
);

const PlayerReadyText7 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerReady7">
      What&apos;s the worst that could happen,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      ?
    </Trans>
  </LogText>
);

const PlayerReadyText8 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerReady8">
      Tick, tock,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      .
    </Trans>
  </LogText>
);

const PlayerReadyText9 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerReady9">
      Do it or we&apos;ll do it for you,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      .
    </Trans>
  </LogText>
);
const PlayerReadyText10 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerReady10">
      Have at it,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      .
    </Trans>
  </LogText>
);
const PlayerReadyText11 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerReady11">
      Can{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      do something????
    </Trans>
  </LogText>
);
const PlayerReadyText12 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerReady12">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      , it&apos;s showtime.
    </Trans>
  </LogText>
);
const PlayerReadyText13 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerReady13">
      Go big or go home,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      .
    </Trans>
  </LogText>
);
const PlayerReadyText14 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerReady14">
      Poke that bear,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      !
    </Trans>
  </LogText>
);

export const playerReadyLogs: RoundLogTextGetter = ({ user1 }) => [
  <PlayerReadyText1 key={`${PtbRoundLogType.PLAYER_READY}1`} user1={user1} />,
  <PlayerReadyText2 key={`${PtbRoundLogType.PLAYER_READY}2`} user1={user1} />,
  <PlayerReadyText3 key={`${PtbRoundLogType.PLAYER_READY}3`} user1={user1} />,
  <PlayerReadyText4 key={`${PtbRoundLogType.PLAYER_READY}4`} user1={user1} />,
  <PlayerReadyText5 key={`${PtbRoundLogType.PLAYER_READY}5`} user1={user1} />,
  <PlayerReadyText6 key={`${PtbRoundLogType.PLAYER_READY}6`} user1={user1} />,
  <PlayerReadyText7 key={`${PtbRoundLogType.PLAYER_READY}7`} user1={user1} />,
  <PlayerReadyText8 key={`${PtbRoundLogType.PLAYER_READY}8`} user1={user1} />,
  <PlayerReadyText9 key={`${PtbRoundLogType.PLAYER_READY}9`} user1={user1} />,
  <PlayerReadyText10 key={`${PtbRoundLogType.PLAYER_READY}10`} user1={user1} />,
  <PlayerReadyText11 key={`${PtbRoundLogType.PLAYER_READY}11`} user1={user1} />,
  <PlayerReadyText12 key={`${PtbRoundLogType.PLAYER_READY}12`} user1={user1} />,
  <PlayerReadyText13 key={`${PtbRoundLogType.PLAYER_READY}13`} user1={user1} />,
  <PlayerReadyText14 key={`${PtbRoundLogType.PLAYER_READY}14`} user1={user1} />,
];
