import { useTranslation } from "next-i18next";
import { ModalBody } from "@chakra-ui/react";
import { useAccount } from "wagmi";
import { ChainId } from "@looksrare/config";
import { ModalHeroHeader, ModalLegacy, ModalProps, BlastChainLogoIcon, chainNameMap } from "@looksrare/uikit";
import { DisconnectWalletButton } from "@/components/Buttons/DisconnectWalletButton";

export const NetworkSwitcherModal = (props: Omit<ModalProps, "children">) => {
  const { t } = useTranslation();
  const { isConnected, chainId } = useAccount();
  const networkName = chainId ? chainNameMap[chainId as ChainId] : "";

  return (
    <ModalLegacy hideHeader {...props}>
      <ModalHeroHeader
        showCloseButton
        icon={BlastChainLogoIcon}
        title={t("Switch Network")}
        label={t(
          "Your wallet’s currently connected to {{networkName}}. Switch to Blast in your wallet app or disconnect now.",
          { networkName }
        )}
        onClose={props.onClose}
      />
      {isConnected && (
        <ModalBody pt={0}>
          <DisconnectWalletButton width="100%" variant="ghost" colorScheme="danger" />
        </ModalBody>
      )}
    </ModalLegacy>
  );
};
