import { Icon, IconProps } from "../Icon";

const CartEmpty = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 30C11.1046 30 12 29.1045 12 28C12 26.8954 11.1046 26 10 26C8.89543 26 8 26.8954 8 28C8 29.1045 8.89543 30 10 30ZM24 30C25.1046 30 26 29.1045 26 28C26 26.8954 25.1046 26 24 26C22.8954 26 22 26.8954 22 28C22 29.1045 22.8954 30 24 30ZM5.82 6.99996H28C28.3081 6.99239 28.6026 7.12734 28.798 7.36573C28.9934 7.60412 29.0679 7.9193 29 8.21996L27 17.22C26.8952 17.6851 26.4767 18.0116 26 18H8L8.82 22H26V24H8C7.51724 24.0097 7.09647 23.6731 7 23.2L3.18 3.99996H0V1.99996H4C4.48276 1.99023 4.90353 2.32684 5 2.79996L5.82 6.99996ZM7.61969 16H25.1997L26.7497 8.99997H6.21969L7.61969 16Z"
      fill="currentColor"
    />
  </Icon>
);

export default CartEmpty;
