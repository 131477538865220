import {
  forwardRef,
  Alert as ChakraAlert,
  AlertProps as ChakraAlertProps,
  AlertStatus,
  AlertIcon,
  Box,
  Flex,
  AlertIconProps,
} from "@chakra-ui/react";
import { InformationFilledIcon, WarningFilledIcon, ErrorFilledIcon, CheckmarkFilledIcon, IconProps } from "../Icons";
import { Container } from "../Container";

interface Props extends ChakraAlertProps {
  constrain?: boolean;
  alertIconProps?: AlertIconProps;
  icon?: React.FC<React.PropsWithChildren<IconProps>>;
  centerIcon?: boolean;
}

const icons: Record<AlertStatus, React.FC<React.PropsWithChildren<IconProps>>> = {
  loading: InformationFilledIcon, //@TODO inform design
  info: InformationFilledIcon,
  warning: WarningFilledIcon,
  success: CheckmarkFilledIcon,
  error: ErrorFilledIcon,
};

export const Alert = forwardRef<Props, "div">(
  ({ children, constrain = false, icon, status = "info", alertIconProps, centerIcon = false, ...props }, ref) => {
    const Icon = icon || icons[status];
    const iconAlignment = centerIcon ? { alignSelf: "center" } : {};

    return (
      <ChakraAlert ref={ref} status={status} {...props}>
        {constrain ? (
          <Box width="100%">
            <Flex as={Container}>
              <AlertIcon as={Icon} {...iconAlignment} {...alertIconProps} />
              {children}
            </Flex>
          </Box>
        ) : (
          <>
            <AlertIcon as={Icon} {...iconAlignment} {...alertIconProps} />
            {children}
          </>
        )}
      </ChakraAlert>
    );
  }
);
