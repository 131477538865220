import { Icon, IconProps } from "../Icon";

const ExpandRight = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 4H4V28H6V4ZM24.17 17L20.59 20.59L22 22L28 16L22 10L20.59 11.41L24.17 15H10V17H24.17Z"
      fill="currentColor"
    />
  </Icon>
);

export default ExpandRight;
