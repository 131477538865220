import { useTranslation } from "next-i18next";
import { ModalLegacy, ModalProps } from "../../Modal";
import { BridgeFundsView } from "./BridgeFundsView";

export interface BridgeFundsModalProps {
  isOpen: ModalProps["isOpen"];
  onClose: ModalProps["onClose"];
}

export const BridgeFundsModal = ({ isOpen, onClose }: BridgeFundsModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalLegacy
      title={t("Move Across Networks")}
      onClose={onClose}
      isOpen={isOpen}
      closeOnOverlayClick={false}
      modalContentProps={{ border: "none" }}
      modalHeaderProps={{
        backgroundColor: "ui-bg",
      }}
    >
      <BridgeFundsView />
    </ModalLegacy>
  );
};
