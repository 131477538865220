import { Icon, IconProps } from "../Icon";

const SortDescending = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.414 20.586L18 22L24 28L30 22L28.586 20.586L25 24.172V4H23V24.172L19.414 20.586ZM2 6H16V8H2V6ZM16 12H6V14H16V12ZM10 18H16V20H10V18Z"
      fill="currentColor"
    />
  </Icon>
);

export default SortDescending;
