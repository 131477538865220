import { STORAGE_NAMESPACE } from "@looksrare/config";
import { type StateSetter } from "@looksrare/utils";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

interface GlobalState {
  isSoundEnabled: boolean;
}

interface GlobalStore extends GlobalState {
  setIsSoundEnabled: StateSetter<boolean>;
  toggleSound: () => void;
}

export const useGlobalSettings = create(
  persist(
    immer<GlobalStore>((set) => ({
      isSoundEnabled: true,
      setIsSoundEnabled: (newIsSoundEnabled) => {
        set((state) => {
          state.isSoundEnabled = newIsSoundEnabled;
        });
      },
      toggleSound: () => {
        set((state) => {
          state.isSoundEnabled = !state.isSoundEnabled;
        });
      },
    })),
    {
      name: `${STORAGE_NAMESPACE}_yg_settings`,
      storage: createJSONStorage(() => localStorage),
    }
  )
);
