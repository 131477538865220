import { TopicName } from "./helpers";

export type NonEmptyArray<T> = [T, ...T[]];

export type WSTopicName = `${TopicName}${string}`;

/**
 * Requests
 */
type Params = [WSTopicName, ...string[]];
type SubscribeRequest = { requestId: number; method: "subscribe"; params: Params };
type UnsubscribeRequest = { requestId: number; method: "unsubscribe"; params: Params };
type HeartbeatRequest = { method: "heartbeat"; data: unknown };

export type WebsocketRequest = HeartbeatRequest | SubscribeRequest | UnsubscribeRequest;

/**
 * Error Types
 */
type InvalidJson = { code: "invalid_json"; message?: string };
type InvalidTopic = { code: "invalid_topic"; message?: string };

// Errors thrown when the collection or token address is invalid
type InvalidCollection = { code: "invalid_collection"; message?: string };
type InvalidToken = { code: "invalid_token"; message?: string };

// Errors thrown when a given collection or token is unsupported
// This can happen when we deliberately want to ignore certain collections
// or collections / tokens that are not present in our DB.
type UnsupportedCollection = { code: "unsupported_collection"; message?: string };
type UnsupportedToken = { code: "unsupported_token"; message?: string };

type WSResponseError =
  | InvalidJson
  | InvalidTopic
  | InvalidCollection
  | InvalidToken
  | UnsupportedCollection
  | UnsupportedToken;

/**
 * Responses
 */

export enum WSMessageType {
  Request = "R",
  Message = "M",
}

type WSStatusResponse = {
  type: WSMessageType.Request;
  requestId: number;
} & ({ success: true } | { success: false; error: WSResponseError });

type WSMessageResponse<Topic extends WSTopicName | "heartbeat", Data> = {
  type: WSMessageType.Message;
  topic: Topic;
  data: Data;
};

export type WSResponse<T> =
  | WSMessageResponse<"heartbeat", unknown>
  | WSMessageResponse<WSTopicName, T>
  | WSStatusResponse;

/**
 * Internal Types
 */
type WSError =
  | WSResponseError
  | { code: "ws_disconnected"; message?: string }
  | { code: "ws_error_terminated"; message?: string };

export type WSEventHandler<T> = (arg: { err?: null; data: T } | { err: WSError; data?: null }) => void;
