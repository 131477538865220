import { Button, ButtonProps, VolumeMuteFilledIcon, VolumeUpFilledIcon } from "@looksrare/uikit";
import { usePtbConfig } from "..";

export const MuteButton = (props: ButtonProps) => {
  const { isMuted, actions } = usePtbConfig();

  return (
    <Button onClick={actions.toggleMute} color={isMuted ? "red.400" : "link-01"} {...props}>
      {isMuted ? <VolumeMuteFilledIcon /> : <VolumeUpFilledIcon />}
    </Button>
  );
};
