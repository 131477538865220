import { useAccount, useSwitchChain } from "wagmi";
import { HStack, Skeleton } from "@chakra-ui/react";
import { Button, ConnectWalletButton } from "@looksrare/uikit/components/Button";
import { getChainIdFromSupportedNetwork, getSupportedNetworkFromChainId, isAddressEqual } from "@looksrare/utils";
import { useTranslation } from "next-i18next";
import { ChainIcon, Text } from "@looksrare/uikit";
import capitalize from "lodash/capitalize";
import { useActiveCaveRoundInfo, useActiveRoundOnChainId, useGetCaveQueryParams } from "../../hooks";
import { getNetworkFromPtbContractName } from "../../utils";
import { Wrapper } from "./shared";
import { WaitingToStart } from "./WaitingToStart";
import { RoundEnded } from "./RoundEnded";
import { InProgress } from "./InProgress";

export const GameActions = () => {
  const { t } = useTranslation();
  const { isConnected, address, chainId } = useAccount();
  const { network } = useGetCaveQueryParams();
  const activeCaveRoundInfoQuery = useActiveCaveRoundInfo();
  const activeRoundOnChainQuery = useActiveRoundOnChainId();
  const { switchChain } = useSwitchChain();
  const isLoading = activeCaveRoundInfoQuery.isLoading || activeRoundOnChainQuery.isLoading;
  const isOnCorrectNetwork = !!chainId && getSupportedNetworkFromChainId(chainId) === network;

  if (!isConnected) {
    return (
      <Wrapper>
        <Text>{t("Connect your wallet to begin.")}</Text>
        <ConnectWalletButton />
      </Wrapper>
    );
  }

  if (isLoading) {
    return (
      <Wrapper>
        <Skeleton width="25%" height={6} />
        <Skeleton height={10} width={20} borderRadius="button" />
      </Wrapper>
    );
  }

  if (!isOnCorrectNetwork && activeCaveRoundInfoQuery.isSuccess) {
    const caveNetwork = getNetworkFromPtbContractName(activeCaveRoundInfoQuery.data.cave.contract);
    const caveChainId = getChainIdFromSupportedNetwork(caveNetwork);

    return (
      <Wrapper>
        <HStack>
          <ChainIcon network={caveNetwork} boxSize={8} />
          <Text>{t("ptb::Switch network to {{network}} to play.", { network: capitalize(caveNetwork) })}</Text>
        </HStack>
        <Button colorScheme="brand" onClick={() => switchChain?.({ chainId: caveChainId })}>
          {t("Switch Network")}
        </Button>
      </Wrapper>
    );
  }

  if (activeCaveRoundInfoQuery.isSuccess && !!activeCaveRoundInfoQuery.data.round) {
    const { cave, round, players, meta } = activeCaveRoundInfoQuery.data;
    const hasOpenSpot = players.length < cave.playersPerRound;
    const isParticipating = players.some((player) => isAddressEqual(player.user.address, address));

    // Round Cancelled / Round
    if (meta.isRoundRevealed || meta.isRoundCancelled) {
      return (
        <RoundEnded
          contract={cave.contract}
          caveOnChainId={cave.onChainId}
          isRoundCancelled={meta.isRoundCancelled}
          isParticipating={isParticipating}
        />
      );
    }

    // Round in Progress
    if (meta.isRoundDrawn || meta.isRoundDrawing) {
      return (
        <InProgress
          network={getNetworkFromPtbContractName(activeCaveRoundInfoQuery.data?.cave.contract)}
          caveOnChainId={cave.onChainId}
          roundOnChainId={round.onChainId}
          players={players}
        />
      );
    }

    // Waiting to Start
    if (meta.isRoundOpen || meta.isRoundNone) {
      return (
        <WaitingToStart
          hasOpenSpot={hasOpenSpot}
          isParticipating={isParticipating}
          caveOnChainId={activeCaveRoundInfoQuery.data.cave.onChainId}
          roundOnChainId={activeCaveRoundInfoQuery.data.round.onChainId}
          players={activeCaveRoundInfoQuery.data.players}
          playersPerRound={activeCaveRoundInfoQuery.data.cave.playersPerRound}
          openedAt={activeCaveRoundInfoQuery.data.round.openedAt}
        />
      );
    }
  }

  return null;
};
