import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const WeeklyDraw = (props: IconProps) => {
  const [uid0] = useState(uniqueId());
  const [uid1] = useState(uniqueId());
  const [uid2] = useState(uniqueId());
  const [uid3] = useState(uniqueId());
  const [uid4] = useState(uniqueId());
  const [uid5] = useState(uniqueId());
  const [uid6] = useState(uniqueId());
  const [uid7] = useState(uniqueId());
  const [uid8] = useState(uniqueId());
  const [uid9] = useState(uniqueId());

  return (
    <Icon viewBox="0 0 64 64" {...props}>
      <g clipPath={`url(#${uid0})`}>
        <g opacity="0.5" clipPath={`url(#${uid1})`}>
          <ellipse
            cx="16.5057"
            cy="38.1154"
            rx="11.7031"
            ry="20.3333"
            transform="rotate(-27.8192 16.5057 38.1154)"
            fill="#33DEBF"
          />
        </g>
        <g opacity="0.5" clipPath={`url(#${uid2})`}>
          <ellipse
            cx="9.94654"
            cy="11.9383"
            rx="9.94654"
            ry="11.9383"
            transform="matrix(0.868731 0.495283 -0.353363 0.935486 44.8906 31.3359)"
            fill="#33DEBF"
          />
        </g>
        <g clipPath={`url(#${uid3})`}>
          <ellipse
            cx="9.94654"
            cy="11.9383"
            rx="9.94654"
            ry="11.9383"
            transform="matrix(0.868731 0.495283 -0.353363 0.935486 41.7695 31.5781)"
            fill="#33DEBF"
          />
          <g clipPath={`url(#${uid4})`}>
            <path
              d="M49.1978 39.7112L48.9782 40.0267L45.0033 50.5499L45.0457 50.7035L49.9156 49.9708L49.1978 39.7112Z"
              fill="#121A28"
            />
            <path
              d="M49.1973 39.7094L42.3565 45.6597L45.0452 50.7017L46.9745 45.5941L49.1973 39.7094Z"
              fill="#121A28"
            />
            <path
              d="M44.6948 51.6233L44.6205 51.6702L43.2046 55.4187L43.1848 55.6209L49.5664 50.8934L44.6948 51.6233Z"
              fill="#121A28"
            />
            <path d="M43.1851 55.624L44.6951 51.6265L42.0059 46.5859L43.1851 55.624Z" fill="#121A28" />
            <path d="M45.0453 50.7014L49.9152 49.9687L46.9746 45.5938L45.0453 50.7014Z" fill="#121A28" />
            <path d="M42.3566 45.6578L45.0454 50.6998L46.9747 45.5922L42.3566 45.6578Z" fill="#121A28" />
          </g>
        </g>
        <g opacity="0.5" clipPath={`url(#${uid5})`}>
          <ellipse
            cx="35.8758"
            cy="18.3454"
            rx="9.1514"
            ry="15.8999"
            transform="rotate(30 35.8758 18.3454)"
            fill="#33DEBF"
          />
        </g>
        <g clipPath={`url(#${uid6})`}>
          <ellipse
            cx="38.4832"
            cy="19.9079"
            rx="9.1514"
            ry="15.8999"
            transform="rotate(30 38.4832 19.9079)"
            fill="#33DEBF"
          />
          <g clipPath={`url(#${uid7})`}>
            <path
              d="M44.148 10.0984L43.8141 10.5019L36.3233 23.4763L36.3232 23.6514L41.8449 22.0928L44.148 10.0984Z"
              fill="#121A28"
            />
            <path d="M44.148 10.0951L34.9122 18.0868L36.3232 23.6481L39.9591 17.3506L44.148 10.0951Z" fill="#121A28" />
            <path
              d="M35.6642 24.7859L35.5693 24.8517L32.9009 29.4734L32.8186 29.7147L41.1869 23.2303L35.6642 24.7859Z"
              fill="#121A28"
            />
            <path d="M32.8185 29.7148L35.6641 24.786L34.252 19.2266L32.8185 29.7148Z" fill="#121A28" />
            <path d="M36.3231 23.649L41.8448 22.0904L39.959 17.3516L36.3231 23.649Z" fill="#121A28" />
            <path d="M34.9117 18.0891L36.3228 23.6503L39.9586 17.3529L34.9117 18.0891Z" fill="#121A28" />
          </g>
        </g>
        <g clipPath={`url(#${uid8})`}>
          <ellipse
            cx="20.5057"
            cy="36.7795"
            rx="11.7031"
            ry="20.3333"
            transform="rotate(-27.8192 20.5057 36.7795)"
            fill="#33DEBF"
          />
          <g clipPath={`url(#${uid9})`}>
            <path
              d="M13.7415 23.9634L13.9507 24.5996L22.8919 41.5443L23.0814 41.6636L25.1551 34.6256L13.7415 23.9634Z"
              fill="#121A28"
            />
            <path d="M13.742 23.9627L16.1014 39.4025L23.0818 41.663L18.742 33.4385L13.742 23.9627Z" fill="#121A28" />
            <path
              d="M23.8672 43.1565L23.8737 43.3041L27.0587 49.3401L27.2638 49.5935L25.9449 36.1194L23.8672 43.1565Z"
              fill="#121A28"
            />
            <path d="M27.2644 49.5935L23.8678 43.1565L16.8887 40.8984L27.2644 49.5935Z" fill="#121A28" />
            <path d="M23.082 41.662L25.1557 34.6239L18.7422 33.4375L23.082 41.662Z" fill="#121A28" />
            <path d="M16.1014 39.4033L23.0818 41.6638L18.742 33.4393L16.1014 39.4033Z" fill="#121A28" />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id={uid0}>
          <rect width="64" height="64" fill="white" />
        </clipPath>
        <clipPath id={uid1}>
          <rect
            width="23.4062"
            height="40.6667"
            fill="white"
            transform="translate(-3.33398 25.5938) rotate(-27.8192)"
          />
        </clipPath>
        <clipPath id={uid2}>
          <rect
            width="19.8931"
            height="23.8766"
            fill="white"
            transform="matrix(0.868731 0.495283 -0.353363 0.935486 44.8906 31.3359)"
          />
        </clipPath>
        <clipPath id={uid3}>
          <rect
            width="19.8931"
            height="23.8766"
            fill="white"
            transform="matrix(0.868731 0.495283 -0.353363 0.935486 41.7695 31.5781)"
          />
        </clipPath>
        <clipPath id={uid4}>
          <rect
            width="8.70322"
            height="20.8921"
            fill="white"
            transform="matrix(0.868731 0.495283 -0.353363 0.935486 46.1035 35.7422)"
          />
        </clipPath>
        <clipPath id={uid5}>
          <rect width="18.3028" height="31.7998" fill="white" transform="translate(35.9004) rotate(30)" />
        </clipPath>
        <clipPath id={uid6}>
          <rect width="18.3028" height="31.7998" fill="white" transform="translate(38.5078 1.5625) rotate(30)" />
        </clipPath>
        <clipPath id={uid7}>
          <rect width="8.00747" height="27.8249" fill="white" transform="translate(41.9727 5.85938) rotate(30)" />
        </clipPath>
        <clipPath id={uid8}>
          <rect
            width="23.4062"
            height="40.6667"
            fill="white"
            transform="translate(0.666016 24.2578) rotate(-27.8192)"
          />
        </clipPath>
        <clipPath id={uid9}>
          <rect width="10.2402" height="35.5833" fill="white" transform="translate(7.67383 23.4297) rotate(-27.8192)" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default WeeklyDraw;
