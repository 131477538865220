import { useState } from "react";
import {
  Box,
  AspectRatioProps,
  BoxProps,
  AspectRatio,
  Skeleton,
  ResponsiveObject,
  useBreakpointValue,
  forwardRef,
} from "@chakra-ui/react";
import { type Address } from "viem";
import { CloudinaryImage, CloudinaryImageProps } from "../Image";
import { Blockie } from "./Blockie";

export interface AvatarProps extends AspectRatioProps {
  address: Address;
  src?: string;
  size?: number | ResponsiveObject<number>;
  boxProps?: BoxProps;
  priority?: boolean;
  contentType?: CloudinaryImageProps["contentType"];
  isLoading?: boolean;
  isPrivate?: boolean;
}

export const Avatar = forwardRef<AvatarProps, "div">(
  (
    {
      src,
      address,
      size = 16,
      boxProps,
      priority = false,
      borderRadius = "circular",
      contentType,
      isLoading,
      isPrivate = false,
      ...props
    },
    ref
  ) => {
    const { boxSize } = props;
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const sizeAsBreakpoint = typeof size === "number" ? { base: size } : size;
    const breakpointSize = useBreakpointValue(sizeAsBreakpoint);
    const showAvatar = !!src && !isPrivate;

    return (
      <AspectRatio ref={ref} width={boxSize || `${breakpointSize}px`} ratio={1} {...props}>
        {/* Firefox workaround to make the alt text that is rendered while an image is loading, transparent */}
        <Box borderRadius={borderRadius} sx={{ color: "transparent" }} {...boxProps}>
          {showAvatar ? (
            <>
              <Box width="100%" height="100%" position="absolute" top={0} left={0}>
                <Skeleton isLoaded={!isLoading && isImageLoaded} width="100%" height="100%" />
              </Box>
              {src && (
                <CloudinaryImage
                  src={src}
                  alt={address}
                  objectFit="cover"
                  width={breakpointSize}
                  height={breakpointSize}
                  onLoadingComplete={() => setIsImageLoaded(true)}
                  priority={priority}
                  contentType={contentType}
                />
              )}
            </>
          ) : (
            <Blockie address={address} size={breakpointSize} borderRadius={borderRadius} />
          )}
        </Box>
      </AspectRatio>
    );
  }
);
Avatar.displayName = "Avatar";
