import { fontSizes, fontWeights, fonts, lineHeights } from "./typography";

/***
 * Note the [FONT_NAME]-emphasis variants in designs are currently just bolded version of their respective font.
 */
export const textStyles = {
  // Text
  caption: {
    fontFamily: fonts.body,
    fontSize: fontSizes["1xs"],
    lineHeight: lineHeights.xs,
    letterSpacing: "0.02em",
  },
  helper: {
    fontFamily: fonts.body,
    fontSize: fontSizes.xs,
    lineHeight: lineHeights.xs,
  },
  detail: {
    fontFamily: fonts.body,
    fontSize: fontSizes.sm,
    lineHeight: lineHeights.sm,
  },
  body: {
    fontFamily: fonts.body,
    fontSize: fontSizes.md,
    lineHeight: lineHeights.md,
  },
  // Heading
  "display-body": {
    fontFamily: fonts.body,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
  },
  "heading-05": {
    fontFamily: fonts.body,
    fontSize: fontSizes.md,
    lineHeight: lineHeights.md,
  },
  "heading-04": {
    fontFamily: fonts.body,
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.lg,
  },
  "heading-03": {
    fontFamily: fonts.body,
    fontSize: fontSizes.xl,
    lineHeight: lineHeights.lg,
  },
  "display-03": {
    fontFamily: fonts.heading,
    fontSize: { base: fontSizes["1xl"], md: fontSizes["3xl"] },
    lineHeight: { base: lineHeights.lg, md: lineHeights.lg },
    letterSpacing: "-0.02em",
    textTransform: "uppercase",
    fontWeight: fontWeights["bold"],
    transform: "translateY(4px)",
  },
  "display-02": {
    fontFamily: fonts.heading,
    fontSize: { base: fontSizes["3xl"], md: fontSizes["6xl"] },
    lineHeight: { base: lineHeights.lg, md: lineHeights["2xl"] },
    letterSpacing: "-0.02em",
    textTransform: "uppercase",
    fontWeight: fontWeights["bold"],
    transform: "translateY(4px)",
  },
  "display-01": {
    fontFamily: fonts.heading,
    fontSize: { base: fontSizes["4xl"], md: fontSizes["7xl"] },
    lineHeight: { base: lineHeights.xl, md: lineHeights["4xl"] },
    letterSpacing: "-0.02em",
    textTransform: "uppercase",
    fontWeight: fontWeights["bold"],
    transform: "translateY(5px)",
  },
} as const;
