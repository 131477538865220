import { ModalBody, useColorModeValue } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { IS_TESTNET, SOCKET_V2_NATIVE_TOKEN_ADDRESS } from "@looksrare/config";
import { ChainId } from "@looksrare/config";
import { useCurrentChainId, useWalletProvider } from "@looksrare/utils";

const DynamicBridge = dynamic(() => import("@socket.tech/plugin").then((mod) => mod.Bridge), {
  ssr: false,
});

export const BridgeFundsView = () => {
  const chainId = useCurrentChainId();
  const provider = useWalletProvider({ chainId });
  const { uiBg, ui01, text01, text03, linkInverse } = useColorModeValue(
    {
      uiBg: "rgb(255, 255, 255)",
      ui01: "rgb(243, 243, 248)",
      text01: "rgb(33, 37, 43)",
      text03: "rgb(105, 112, 119)",
      linkInverse: "rgb(12, 228, 102)",
    },
    {
      uiBg: "rgb(18, 22, 25)",
      ui01: "rgb(33, 37, 43)",
      text01: "rgb(243, 243, 248)",
      text03: "rgb(138, 140, 149)",
      linkInverse: "rgb(17, 162, 77)",
    }
  );

  return (
    <ModalBody backgroundColor="ui-bg" borderBottomRadius={{ sm: "dialog" }}>
      <DynamicBridge
        provider={provider}
        API_KEY={process.env.SOCKET_V2_API_KEY}
        sourceNetworks={[ChainId.MAINNET, ChainId.ARB_MAINNET]}
        destNetworks={[ChainId.MAINNET, ChainId.ARB_MAINNET]}
        defaultDestNetwork={chainId}
        singleTxOnly
        tokenList={[
          {
            chainId: IS_TESTNET ? ChainId.SEPOLIA : ChainId.MAINNET,
            address: SOCKET_V2_NATIVE_TOKEN_ADDRESS,
            decimals: 18,
            name: "Ethereum",
            symbol: "ETH",
            logoURI: "/images/token-eth.svg",
          },
          {
            chainId: IS_TESTNET ? ChainId.ARB_SEPOLIA : ChainId.ARB_MAINNET,
            address: SOCKET_V2_NATIVE_TOKEN_ADDRESS,
            decimals: 18,
            name: "Ethereum",
            symbol: "ETH",
            logoURI: "/images/token-eth-arbitrum.svg",
          },
        ]}
        customize={{
          responsiveWidth: true,
          primary: uiBg,
          secondary: ui01,
          accent: linkInverse,
          onAccent: text01,
          interactive: ui01,
          onInteractive: text01,
          text: text01,
          secondaryText: text03,
        }}
      />
    </ModalBody>
  );
};
