import { zeroAddress } from "viem";
import times from "lodash/times";
import { useAccount } from "wagmi";
import { format } from "date-fns";
import {
  Table,
  Tr,
  Td,
  Tbody,
  Thead,
  Th,
  TableContainer,
  Flex,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { gradients } from "@looksrare/chakra-theme";
import {
  ProfileAvatar,
  AvatarGroup,
  EthHalfIcon,
  LaunchOpenInNewIcon,
  Link,
  LogoRoundIcon,
  NextLink,
  OverflowMenuHorizontalIcon,
  TableFooter,
  Text,
  UsernameDisplay,
} from "@looksrare/uikit";
import {
  formatNumberToLocale,
  formatTimestampAsDateString,
  formatToSignificant,
  getChainIdFromSupportedNetwork,
  getExplorerLink,
  isAddressEqual,
  multiplyWeiByNumber,
} from "@looksrare/utils";
import { ExtendedPtbRound, PtbUser } from "../../graphql";
import { PTB_HISTORY_PER_PAGE } from "../../views/HistoryView";
import { getNetworkFromPtbContractName } from "../../utils";
import { useGetHistoryQueryParams } from "../../hooks/useGetHistoryQueryParams";
import { CurrencyTokenIcon } from "../CurrencyTokenIcon";
import { usePtbConfig } from "../..";
import { RowSkeleton } from "./RowSkeleton";

interface HistoryTableProps {
  page: number;
  setPage: (page: number) => void;
  totalRows: number;
  rounds?: ExtendedPtbRound[];
  isLoading?: boolean;
}

export const HistoryTable = ({ page, setPage, totalRows, rounds = [], isLoading = false }: HistoryTableProps) => {
  const { address } = useAccount();
  const { t } = useTranslation();
  const { network } = useGetHistoryQueryParams();
  const chainId = getChainIdFromSupportedNetwork(network);
  const ptbConfig = usePtbConfig();

  return (
    <Box>
      <TableContainer
        border="solid 1px"
        borderColor="border-01"
        bgGradient={gradients["ui-gradient-v-01"]}
        borderRadius="dialog"
      >
        <Table size="md" minWidth="960px">
          <Thead>
            <Tr>
              <Th textAlign="center">{t("Round")}</Th>
              <Th>{t("ptb::Cave Name")}</Th>
              <Th>{t("ptb::Loser")}</Th>
              <Th>{t("ptb::Winners")}</Th>
              <Th textAlign="right">{t("ptb::Prize Pool")}</Th>
              <Th textAlign="right">{t("ptb::Your Entry")}</Th>
              <Th textAlign="right">{t("ptb::Players")}</Th>
              <Th textAlign="right">{t("ptb::Date")}</Th>
              <Th textAlign="center">{t("ptb::Verify")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading && times(10, (i) => <RowSkeleton key={i} />)}

            {rounds.map((round) => {
              const { cave, players, revealedAt, drawnTransactionHash, revealTransactionHash } = round;
              const { currency, enterAmount, playersPerRound } = cave;

              // Loser column
              const loserElement = (() => {
                const loserPlayer = round.players.find((player) => !!player.lost);
                const loserUser = loserPlayer?.user;
                return loserUser?.address ? (
                  <Flex gap={4}>
                    <ProfileAvatar size={40} address={loserUser.address} src={loserUser.avatar?.image.src} />
                    <UsernameDisplay
                      address={loserUser.address}
                      name={loserUser.name}
                      textProps={{ textStyle: "detail", bold: true }}
                    />
                  </Flex>
                ) : (
                  <Text color="text-disabled">—</Text>
                );
              })();

              // Winners column
              const winnersElement = (() => {
                const winnerUsers = round.players.reduce<PtbUser[]>((accum, player) => {
                  if (!player.lost) {
                    accum.push(player.user);
                  }
                  return accum;
                }, []);
                if (winnerUsers.length === 0) {
                  return <Text color="text-disabled">—</Text>;
                }

                return (
                  <Flex>
                    <Menu>
                      <MenuButton bgColor="transparent">
                        <AvatarGroup>
                          {winnerUsers.map((user) => (
                            <ProfileAvatar
                              key={user.address}
                              size={32}
                              address={user.address}
                              src={user.avatar?.image.src}
                              imageWrapperProps={{ border: "1px solid", borderColor: "ui-bg" }}
                            />
                          ))}
                        </AvatarGroup>
                      </MenuButton>
                      <MenuList>
                        {winnerUsers.map((user) => {
                          const clickableProps = ptbConfig.historyPageWinnerProfileClickable
                            ? {
                                as: NextLink,
                                href: `/accounts/${user.address}`,
                              }
                            : { cursor: "auto" };
                          return (
                            <MenuItem key={user.address} {...clickableProps}>
                              <Flex gap={4}>
                                <ProfileAvatar
                                  key={user.address}
                                  size={20}
                                  address={user.address}
                                  src={user.avatar?.image.src}
                                  imageWrapperProps={{ border: "1px solid", borderColor: "ui-bg" }}
                                />
                                <UsernameDisplay
                                  address={user.address}
                                  name={user.name}
                                  textProps={{ textStyle: "detail" }}
                                />
                              </Flex>
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    </Menu>
                  </Flex>
                );
              })();

              // Prize Pool column
              const isCurrencyEth = isAddressEqual(currency, zeroAddress);
              const prizeAmount = multiplyWeiByNumber(enterAmount, playersPerRound);
              const ptbNetwork = getNetworkFromPtbContractName(cave.contract);

              // Date column
              const revealedAtDate = revealedAt ? new Date(revealedAt) : undefined;
              const revealedAtTimeFormatted = !!revealedAtDate && format(revealedAtDate, "HH:mm");
              const revealedAtDateFormatted = revealedAtDate
                ? formatTimestampAsDateString(revealedAtDate.getTime(), "M/d/yyyy")
                : "-";

              const isUserParticipant = round.players.some((player) => isAddressEqual(address, player.user.address));

              const hasTransactionToVerify = !!drawnTransactionHash || !!revealTransactionHash;

              return (
                <Tr
                  key={`${round.cave.onChainId}}-${round.onChainId}}`}
                  fontSize="14px"
                  borderBottom="1px solid"
                  borderBottomColor="border-01"
                  borderLeft={isUserParticipant ? "solid 4px" : "transparent 4px"}
                  borderLeftColor={isUserParticipant ? "interactive-03" : "ui-01"}
                >
                  {/* Round Number */}
                  <Td>
                    <Text textStyle="detail" color="text-03" noOfLines={1} textAlign="center">
                      {round.onChainId}
                    </Text>
                  </Td>

                  {/* Cave Name */}
                  <Td>
                    <Text textStyle="detail" color="text-03" noOfLines={1}>
                      {round.cave.name}
                    </Text>
                  </Td>

                  {/* Loser */}
                  <Td>{loserElement}</Td>

                  {/* Winners */}
                  <Td>{winnersElement}</Td>

                  {/* Prize Pool */}
                  <Td>
                    <Flex alignItems="center" gap={1} justifyContent="flex-end">
                      <CurrencyTokenIcon network={ptbNetwork} currency={currency} />
                      <Text bold color="text-01">
                        {formatToSignificant(prizeAmount, 4)}
                      </Text>
                    </Flex>
                  </Td>

                  {/* Your Entry */}
                  <Td>
                    <Flex alignItems="center" gap={1} justifyContent="flex-end">
                      {isUserParticipant ? (
                        <>
                          {isCurrencyEth ? <EthHalfIcon boxSize={4} /> : <LogoRoundIcon boxSize={4} />}
                          <Text bold color="text-01">
                            {formatToSignificant(enterAmount, 4)}
                          </Text>
                        </>
                      ) : (
                        <Text color="text-disabled">—</Text>
                      )}
                    </Flex>
                  </Td>

                  {/* Players count */}
                  <Td textAlign="right">
                    <Text textStyle="detail" bold>
                      {formatNumberToLocale(players.length, 0)}
                    </Text>
                  </Td>

                  {/* Date */}
                  <Td textAlign="right" color="text-03">
                    <Flex justifyContent="flex-end" flexDirection="column">
                      <Text textStyle="detail" color="text-03">
                        {revealedAtTimeFormatted}
                      </Text>
                      <Text textStyle="detail" color="text-03">
                        {revealedAtDateFormatted}
                      </Text>
                    </Flex>
                  </Td>

                  {/* Verify */}
                  <Td textAlign="center">
                    {hasTransactionToVerify ? (
                      <Menu>
                        <MenuButton as={IconButton} size="sm" variant="ghost" colorScheme="primary">
                          <OverflowMenuHorizontalIcon />
                        </MenuButton>
                        <MenuList zIndex="dropdown">
                          {drawnTransactionHash && (
                            <MenuItem
                              as={Link}
                              isExternal
                              href={getExplorerLink(drawnTransactionHash, "transaction", chainId)}
                            >
                              <Flex justifyContent="space-between" width="100%">
                                <Text textStyle="detail">{t("ptb::Verify Randomness")}</Text>
                                <LaunchOpenInNewIcon color="text-03" />
                              </Flex>
                            </MenuItem>
                          )}

                          {revealTransactionHash && (
                            <MenuItem
                              as={Link}
                              isExternal
                              href={getExplorerLink(revealTransactionHash, "transaction", chainId)}
                            >
                              <Flex justifyContent="space-between" width="100%">
                                <Text textStyle="detail">Verify Reveal</Text>
                                <LaunchOpenInNewIcon color="text-03" />
                              </Flex>
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    ) : (
                      <Text color="text-disabled">-</Text>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        <TableFooter
          currentPageNumber={page}
          goToPage={setPage}
          totalPagesNumber={totalRows ? Math.ceil(totalRows / PTB_HISTORY_PER_PAGE) : 1}
          labelPrefix="raffle-history"
        />
      </TableContainer>
      {ptbConfig.historyPageBottomComponent?.()}
    </Box>
  );
};
