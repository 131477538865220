import { Icon, IconProps } from "../Icon";

const GasFilled = (props: IconProps) => (
  <Icon {...props}>
    <path
      d="M28.4143 8L23.4143 3L22 4.4141L25 7.4141V12C25.0006 12.5302 25.2115 13.0386 25.5865 13.4135C25.9614 13.7885 26.4698 13.9994 27 14V24.5C27 24.8978 26.842 25.2794 26.5607 25.5607C26.2794 25.842 25.8978 26 25.5 26C25.1022 26 24.7206 25.842 24.4393 25.5607C24.158 25.2794 24 24.8978 24 24.5V16C24 15.7348 23.8946 15.4804 23.7071 15.2929C23.5196 15.1054 23.2652 15 23 15H19V5C19 4.73478 18.8946 4.48043 18.7071 4.29289C18.5196 4.10536 18.2652 4 18 4H5C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V26H2V28H21V26H19V17H22V24.4C21.9883 25.2152 22.2565 26.0098 22.7597 26.6512C23.2629 27.2927 23.9708 27.7422 24.7654 27.9249C25.2759 28.0334 25.8041 28.0265 26.3116 27.9048C26.8191 27.7831 27.293 27.5496 27.6988 27.2214C28.1045 26.8933 28.4319 26.4786 28.657 26.0078C28.8822 25.537 28.9993 25.0219 29 24.5V9.4146C29.0001 9.1519 28.9484 8.89176 28.8479 8.64904C28.7474 8.40631 28.6 8.18577 28.4143 8ZM15 12H8V10H15V12Z"
      fill="currentColor"
    />
  </Icon>
);

export default GasFilled;
