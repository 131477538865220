import { Icon, IconProps } from "../Icon";

const ContractLeft = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 4H6V28H4V4ZM13.83 17L17.41 20.59L16 22L10 16L16 10L17.41 11.41L13.83 15H28V17H13.83Z"
      fill="currentColor"
    />
  </Icon>
);

export default ContractLeft;
