import { useState, ReactEventHandler, useEffect } from "react";
import NextImage, { ImageLoaderProps, ImageProps as NextImageProps } from "next/legacy/image";
import { TOKEN_IMAGE_PLACEHOLDER_URI } from "@looksrare/config";
import { getCloudinaryUrl, Format, Quality, isValidImageSrc, useCurrentChainInfo } from "@looksrare/utils";

/**
 * Construct Cloudinary URL for resources delivered by our CDN.
 * For other resources - Use Next Image default CDN.
 * @returns string
 */
const cloudinaryLoader = (
  imageLoaderProps: ImageLoaderProps,
  cdnUrl: string,
  cloudinaryUrl: string,
  quality?: Quality,
  format?: Format,
  contentType?: string,
  width?: number
) => {
  const { src } = imageLoaderProps;
  const relativeSrc = src.replace(cdnUrl, "");
  // @note in some cases like activity rows where the content type is a video we want to
  // explicitly render as an image
  const isVideo = contentType?.includes("video") ? true : false;
  return getCloudinaryUrl({
    src: relativeSrc,
    baseCloudinaryUrl: cloudinaryUrl,
    quality,
    width,
    format: isVideo ? "webp" : format,
    contentType,
    resourceType: isVideo ? "video" : "image",
  });
};

const sizeCategoryMapping = {
  thumbnail: "32px, 40px, 48px",
  card: "96px, 128px, 262px",
  detail: "360px, 480px, 768px",
  featured: "960px, 1200px, 1440px, 1600px",
};

const wAllowList = [32, 40, 48, 96, 128, 262, 360, 480, 768, 960, 1200, 1440, 1600];
const isDevelopment = process.env.NODE_ENV === "development";
const maybeUseCloudinaryLoader = process.env.USE_CLOUDINARY_LOADER === "true" || !isDevelopment;

export interface CloudinaryImageProps extends Omit<NextImageProps, "quality"> {
  format?: Format;
  contentType?: string;
  placeholderSrc?: string;
  quality?: Quality;
  sizeCategory?: keyof typeof sizeCategoryMapping;
}

export const CloudinaryImage = ({
  src,
  contentType,
  onError,
  format,
  placeholderSrc = TOKEN_IMAGE_PLACEHOLDER_URI,
  quality = "auto",
  sizeCategory,
  ...props
}: CloudinaryImageProps) => {
  const isVideoOrGif = contentType && (contentType.includes("video") || contentType.includes("gif"));
  const mustUseCloudinaryLoader = isVideoOrGif && maybeUseCloudinaryLoader;

  const chain = useCurrentChainInfo();
  const isValidSrc = isValidImageSrc(src);
  const [isImageError, setIsImageError] = useState(isValidSrc ? false : true);

  useEffect(() => {
    setIsImageError(false);
  }, [src]);

  const handleError: ReactEventHandler<HTMLImageElement> = (error) => {
    setIsImageError(true);

    if (onError) {
      onError(error);
    }
  };

  const nearestWidth = (requestedWidth: number) => {
    return wAllowList.reduce((prev, curr) =>
      Math.abs(curr - requestedWidth) < Math.abs(prev - requestedWidth) ? curr : prev
    );
  };

  const isCloudinary = chain.cdnUrl && src.toString().startsWith(chain.cdnUrl);
  const imageSizes = sizeCategory ? sizeCategoryMapping[sizeCategory] : undefined;
  const loader =
    !isImageError && isCloudinary && mustUseCloudinaryLoader
      ? (resolverProps: ImageLoaderProps) => {
          const width = nearestWidth(resolverProps.width);
          return cloudinaryLoader(
            resolverProps,
            chain.cdnUrl,
            chain.cloudinaryUrl,
            quality,
            format,
            contentType,
            width
          );
        }
      : undefined;

  return (
    <NextImage
      src={isImageError ? placeholderSrc : src}
      onError={handleError}
      loader={loader}
      sizes={imageSizes}
      {...props}
    />
  );
};
