import { Flex, FlexProps } from "@chakra-ui/react";
import { NoDataIcon, IconProps } from "../Icons";

interface Props extends FlexProps {
  logoProps?: IconProps;
  hideLogo?: boolean;
}

export const SectionPlaceholder = ({ children, logoProps, hideLogo, ...props }: Props) => (
  <Flex flexDirection="column" justifyContent="center" alignItems="center" {...props}>
    {!hideLogo && <NoDataIcon color="ui-02" mb={6} boxSize={137} {...logoProps} />}
    {children}
  </Flex>
);
