import { Icon, IconProps } from "../Icon";

const Twitter = (props: IconProps) => (
  <Icon viewBox="0 0 20 20" {...props}>
    <path
      d="M14.3134 3.125H16.6135L11.5884 8.94936L17.5 16.875H12.8713L9.24593 12.0681L5.09769 16.875H2.7962L8.17098 10.6452L2.5 3.125H7.24621L10.5232 7.51865L14.3134 3.125ZM13.5061 15.4788H14.7806L6.55368 4.44782H5.186L13.5061 15.4788Z"
      fill="currentColor"
    />
  </Icon>
);

export default Twitter;
