import { Icon, IconProps } from "../Icon";

const Star = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0002 6.52L18.7602 12.1L19.2202 13.1L20.2202 13.25L26.3802 14.14L22.0002 18.44L21.2502 19.17L21.4302 20.17L22.4802 26.3L16.9702 23.41L16.0002 23L15.0702 23.49L9.56017 26.34L10.5602 20.21L10.7402 19.21L10.0002 18.44L5.58017 14.09L11.7402 13.2L12.7402 13.05L13.2002 12.05L16.0002 6.52ZM16.0003 2L11.4503 11.22L1.2803 12.69L8.64031 19.87L6.9003 30L16.0003 25.22L25.1003 30L23.3603 19.87L30.7203 12.7L20.5503 11.22L16.0003 2Z"
      fill="currentColor"
    />
  </Icon>
);

export default Star;
