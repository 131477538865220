import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const BlastChainLogo = (props?: IconProps) => {
  const [uid1] = useState(uniqueId());
  return (
    <Icon viewBox="0 0 96 96" color="#0CE466" {...props}>
      <g clipPath={`url(#${uid1})`}>
        <path
          opacity="0.3"
          d="M0 24C0 10.7452 10.7452 0 24 0H72C85.2548 0 96 10.7452 96 24V72C96 85.2548 85.2548 96 72 96H24C10.7452 96 0 85.2548 0 72V24Z"
          fill="#D3CA00"
        />
        <path
          d="M22.0773 23L9.44824 33L74.4482 33.4023L71.3103 44.069L44.862 43.6667L42.6825 52.3333H68.6207L65.0345 65.1379H28.2758L35.4482 41.8276L27.3947 35L14.101 75H61.9584L74.8965 69.1724L78.931 54.8276L71.3103 48.3333L83.2283 42.3333L86.5517 29L79.2402 23H22.0773Z"
          fill="#D3CA00"
        />
      </g>
      <defs>
        <clipPath id={uid1}>
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default BlastChainLogo;
