import { Icon, IconProps } from "../Icon";

const VolumeMute = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31 12.4101L29.59 11.0001L26 14.5901L22.41 11.0001L21 12.4101L24.59 16.0001L21 19.5901L22.41 21.0001L26 17.4101L29.59 21.0001L31 19.5901L27.41 16.0001L31 12.4101ZM18 30.0001C17.7328 29.999 17.4771 29.8909 17.29 29.7001L9.67 22.0001H3C2.44772 22.0001 2 21.5524 2 21.0001V11.0001C2 10.4478 2.44772 10.0001 3 10.0001H9.67L17.29 2.30007C17.68 1.91235 18.31 1.91235 18.7 2.30007C18.8884 2.4847 18.9962 2.7363 19 3.00007V29.0001C19 29.5524 18.5523 30.0001 18 30.0001ZM4 20.0001H10C10.2915 19.9982 10.5732 20.1051 10.79 20.3001L17 26.5701V5.43007L10.79 11.7001C10.5732 11.895 10.2915 12.002 10 12.0001H4V20.0001Z"
      fill="currentColor"
    />
  </Icon>
);

export default VolumeMute;
