import { Icon, IconProps } from "../Icon";

const Levels = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 7C11.8954 7 11 7.89543 11 9V14H5C3.89543 14 3 14.8954 3 16V23C3 24.1046 3.89543 25 5 25H7H11H13H19H21H25H27C28.1046 25 29 24.1046 29 23V19C29 17.8954 28.1046 17 27 17H21V9C21 7.89543 20.1046 7 19 7H13ZM25 23H27V19H21V23H25ZM19 23V19V9H13V16V23H19ZM11 23V16H5V23H7H11Z"
      fill="currentColor"
    />
  </Icon>
);

export default Levels;
