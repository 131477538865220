import { Icon, IconProps } from "../Icon";

const NotificationBell = (props: IconProps) => (
  <Icon viewBox="0 0 96 96" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.765 62.8966L79.3857 54.5172V40.2414C79.3651 24.2823 67.3233 10.9026 51.4546 9.2069V3H45.2477V9.2069C29.4339 11.0097 17.4495 24.3257 17.3167 40.2414V54.5172L8.9374 62.8966C8.3244 63.4498 7.98421 64.2435 8.00637 65.069V74.3793C7.95087 75.2182 8.25998 76.0402 8.85445 76.6347C9.44892 77.2291 10.2709 77.5383 11.1098 77.4828H32.834C32.834 86.0527 39.7813 93 48.3512 93C56.9211 93 63.8684 86.0527 63.8684 77.4828H85.5926C86.4314 77.5383 87.2535 77.2291 87.8479 76.6347C88.4424 76.0402 88.7515 75.2182 88.696 74.3793V65.069C88.7182 64.2435 88.378 63.4498 87.765 62.8966ZM48.3515 86.7932C43.2095 86.7932 39.0411 82.6248 39.0411 77.4828H57.6618C57.6618 82.6248 53.4934 86.7932 48.3515 86.7932Z"
      fill="#04CD58"
    />
    <path
      d="M31.0335 30.4938C40.6116 20.8737 56.2936 20.8736 65.8716 30.4937L74.0689 38.6911L65.8716 46.8883C56.2936 56.5084 40.6116 56.5085 31.0335 46.8884L22.8363 38.6911L31.0335 30.4938Z"
      fill="black"
    />
    <path
      d="M48.4534 45.337C44.7851 45.337 41.8087 42.3635 41.8087 38.6923C41.8087 35.021 44.7851 32.0476 48.4534 32.0476C52.1216 32.0476 55.098 35.021 55.098 38.6923C55.098 42.3635 52.1216 45.337 48.4534 45.337Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.5394 38.6907C37.5394 44.722 42.4292 49.6069 48.4557 49.6069C54.4821 49.6069 59.3719 44.722 59.3719 38.6907C59.3719 32.6593 54.4821 27.7744 48.4557 27.7744C42.4292 27.7744 37.5394 32.6593 37.5394 38.6907ZM43.7092 38.6907C43.7092 41.313 45.8352 43.4368 48.4554 43.4368C51.0756 43.4368 53.2016 41.313 53.2016 38.6907C53.2016 36.0683 51.0756 33.9445 48.4554 33.9445C45.8352 33.9445 43.7092 36.0683 43.7092 38.6907Z"
      fill="white"
    />
  </Icon>
);

export default NotificationBell;
