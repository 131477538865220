import { Icon, IconProps } from "../Icon";

const UsdbIcon = (props: IconProps) => (
  <Icon viewBox="0 0 96 96" {...props}>
    <rect width="96" height="96" rx="48" fill="#FCFC03" />
    <path
      d="M35.5475 42.6872L36.8587 38.6438L30.1786 33.7587L26.3593 45.4803L33.0036 50.3615L32.992 50.3978H62.8925L60.013 59.2757H21.9867L19.4316 67.1544H57.4579L67.4069 62.2046L72.2969 47.1273L66.1436 42.6073L35.5475 42.6872Z"
      fill="black"
    />
    <path d="M40.5035 26.0109L30.1786 33.7587L75.7365 33.7733L78.2531 26.0109H40.5035Z" fill="black" />
    <path d="M41.34 80.5714L62.9264 13.4806H55.2735L33.9144 80.5714H41.34Z" fill="black" />
  </Icon>
);

export default UsdbIcon;
