import { Trans } from "next-i18next";
import { PtbRoundLogType } from "../../../../types";
import { LogText, LogTextHighlight } from "./LogText";
import type { LogTextProps, RoundLogTextGetter } from "./types";

const PlayerPokeText1 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerPoke1">
      Damn,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      , you didn&apos;t have to hit him that hard.
    </Trans>
  </LogText>
);

const PlayerPokeText2 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerPoke2">
      You call that a poke,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      ?
    </Trans>
  </LogText>
);

const PlayerPokeText3 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerPoke3">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      launches the stick like a javelin.
    </Trans>
  </LogText>
);

const PlayerPokeText4 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerPoke4">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      massages the bear with the stick.
    </Trans>
  </LogText>
);

const PlayerPokeText5 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerPoke5">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      pokes the bear.
    </Trans>
  </LogText>
);

const PlayerPokeText6 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerPoke6">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      gently prods the bear.
    </Trans>
  </LogText>
);

const PlayerPokeText7 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerPoke7">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      shakes as they lightly tap the bear.
    </Trans>
  </LogText>
);

const PlayerPokeText8 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerPoke8">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      prods the bear.
    </Trans>
  </LogText>
);

const PlayerPokeText9 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerPoke9">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      wipes away a tear before poking the bear.
    </Trans>
  </LogText>
);
const PlayerPokeText10 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerPoke10">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      covers their eyes and prods the bear.
    </Trans>
  </LogText>
);
const PlayerPokeText11 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerPoke11">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      cautiously pokes the sleeping beauty.
    </Trans>
  </LogText>
);
const PlayerPokeText12 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerPoke12">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      gives the bear a good, firm poke.
    </Trans>
  </LogText>
);
const PlayerPokeText13 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerPoke13">
      What a thrust,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      !
    </Trans>
  </LogText>
);
const PlayerPokeText14 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerPoke14">
      Buy a bear dinner first,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      .
    </Trans>
  </LogText>
);

export const playerPokeLogs: RoundLogTextGetter = ({ user1 }) => [
  <PlayerPokeText1 key={`${PtbRoundLogType.PLAYER_POKE}1`} user1={user1} />,
  <PlayerPokeText2 key={`${PtbRoundLogType.PLAYER_POKE}2`} user1={user1} />,
  <PlayerPokeText3 key={`${PtbRoundLogType.PLAYER_POKE}3`} user1={user1} />,
  <PlayerPokeText4 key={`${PtbRoundLogType.PLAYER_POKE}4`} user1={user1} />,
  <PlayerPokeText5 key={`${PtbRoundLogType.PLAYER_POKE}5`} user1={user1} />,
  <PlayerPokeText6 key={`${PtbRoundLogType.PLAYER_POKE}6`} user1={user1} />,
  <PlayerPokeText7 key={`${PtbRoundLogType.PLAYER_POKE}7`} user1={user1} />,
  <PlayerPokeText8 key={`${PtbRoundLogType.PLAYER_POKE}8`} user1={user1} />,
  <PlayerPokeText9 key={`${PtbRoundLogType.PLAYER_POKE}9`} user1={user1} />,
  <PlayerPokeText10 key={`${PtbRoundLogType.PLAYER_POKE}10`} user1={user1} />,
  <PlayerPokeText11 key={`${PtbRoundLogType.PLAYER_POKE}11`} user1={user1} />,
  <PlayerPokeText12 key={`${PtbRoundLogType.PLAYER_POKE}12`} user1={user1} />,
  <PlayerPokeText13 key={`${PtbRoundLogType.PLAYER_POKE}13`} user1={user1} />,
  <PlayerPokeText14 key={`${PtbRoundLogType.PLAYER_POKE}14`} user1={user1} />,
];
