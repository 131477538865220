import { HStack, forwardRef } from "@chakra-ui/react";
import { ArrowUpRightIcon, Text } from "@looksrare/uikit";
import { formatNumberToLocale } from "@looksrare/utils";

interface AssetPairTopPayoutProps {
  moonPayoutRatio: number;
  doomPayoutRatio: number;
}

export const AssetPairTopPayout = forwardRef<AssetPairTopPayoutProps, "div">(
  ({ moonPayoutRatio, doomPayoutRatio }, ref) => {
    const isMoon = moonPayoutRatio >= doomPayoutRatio;

    const topPayoutDisplay = (() => {
      if (moonPayoutRatio >= doomPayoutRatio) {
        return `${formatNumberToLocale(moonPayoutRatio, 1)}x`;
      }

      return `${formatNumberToLocale(doomPayoutRatio, 1)}x`;
    })();

    return (
      <HStack ref={ref} spacing={1} display={{ base: "none", md: "flex" }}>
        <ArrowUpRightIcon
          boxSize={4}
          transform={isMoon ? "rotate(0)" : "rotate(90deg)"}
          color={isMoon ? "moondoom-moon" : "moondoom-doom"}
          transition="transform 200ms, color 200ms"
        />
        <Text
          textStyle="detail"
          textAlign="end"
          color={isMoon ? "moondoom-moon" : "moondoom-doom"}
          minWidth="40px"
          bold
        >
          {topPayoutDisplay}
        </Text>
      </HStack>
    );
  }
);
