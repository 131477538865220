import { useCallback } from "react";
import { useAccount, useSwitchChain, useWalletClient } from "wagmi";
import { ChainId } from "@looksrare/config";
import { SupportedNetwork, getChainIdFromSupportedNetwork } from "@looksrare/utils";
import { useDisclosure } from "@chakra-ui/react";

interface AssertNetworkOptions {
  network: SupportedNetwork;
  onSuccess?: (chainId: ChainId) => Promise<void> | void;
  onError?: (err: unknown) => void;
}

export const useAssertNetwork = ({ network, onSuccess, onError }: AssertNetworkOptions) => {
  const { refetch } = useWalletClient();
  const { switchChainAsync } = useSwitchChain();
  const { chain } = useAccount();

  return useCallback(async (): Promise<void> => {
    const chainId = getChainIdFromSupportedNetwork(network);
    const isNetworkSupported = chain?.id === chainId;

    // Call the function if we are already on the desired network
    if (isNetworkSupported) {
      onSuccess?.(chain?.id);
      return;
    }

    try {
      const newChain = await switchChainAsync?.({ chainId: chainId });
      await refetch();
      onSuccess?.(newChain?.id as ChainId);
    } catch (err) {
      onError?.(err);
    }
  }, [network, onSuccess, onError, switchChainAsync, refetch, chain]);
};

export const useAssertNetworkDisclosure = ({ network, onError }: Omit<AssertNetworkOptions, "onSuccess">) => {
  const disclosure = useDisclosure();
  const onOpen = useAssertNetwork({ network, onError, onSuccess: disclosure.onOpen });
  return { ...disclosure, onOpen };
};
