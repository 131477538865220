import { useCallback, useEffect, useRef, useState } from "react";

type Callback = () => void;

type IntervalOptions = {
  enableOnMount?: boolean;
};

/**
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export const useInterval = (callback: Callback, delay: number, options: IntervalOptions = { enableOnMount: true }) => {
  const [isPaused, setIsPaused] = useState(true);
  const callbackRef = useRef(callback);
  const timer = useRef<NodeJS.Timer>();
  const { enableOnMount } = options;

  const startTimer = useCallback(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    timer.current = setInterval(() => callbackRef.current(), delay);
    setIsPaused(false);
  }, [timer, callbackRef, delay, setIsPaused]);

  const stopTimer = useCallback(() => {
    if (timer.current) {
      clearInterval(timer.current);
      setIsPaused(true);
    }
  }, [timer, setIsPaused]);

  // Update callback if it changes
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback, callbackRef]);

  useEffect(() => {
    if (enableOnMount) {
      startTimer();
    }
    return () => stopTimer();
  }, [enableOnMount, startTimer, stopTimer]);

  return { isPaused, startTimer, stopTimer };
};
