import { forwardRef, Button as ChakraButton, ButtonProps as ChakraButtonProps } from "@chakra-ui/react";

export interface ButtonProps extends Omit<ChakraButtonProps, "disabled"> {
  round?: boolean;
  square?: boolean;
  "data-id"?: string;
}

export const getBorderRadius = (round: boolean, square: boolean, borderRadius: ChakraButtonProps["borderRadius"]) => {
  if (!round && !square) {
    return borderRadius;
  }

  if (square) {
    return "square";
  }

  return "circular";
};

export const Button = forwardRef<ButtonProps, "div">(
  ({ round = false, square = false, borderRadius, ...props }, ref) => (
    <ChakraButton textStyle="body" ref={ref} borderRadius={getBorderRadius(round, square, borderRadius)} {...props} />
  )
);
