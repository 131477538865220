import { type FC, type PropsWithChildren } from "react";
import { zeroAddress } from "viem";
import { useTranslation } from "next-i18next";
import { Box, Grid, GridItem, type StackProps, type GridItemProps, type GridProps, HStack } from "@chakra-ui/react";
import { formatToSignificant } from "@looksrare/utils";
import { ErrorIcon, Text, UserAvatarFilledIcon } from "@looksrare/uikit";
import { PtbRoundStatus } from "../../../types";
import { useActiveCaveRoundInfo } from "../../../hooks";
import { getGradientBorder, getNetworkFromPtbContractName } from "../../../utils";
import { CurrencyValueDisplay } from "../../CurrencyValueDisplay";

interface PlayerCardGridProps extends GridProps {
  isActive?: boolean;
}

export const PlayerCardGrid: FC<PropsWithChildren<PlayerCardGridProps>> = ({ isActive = false, ...props }) => (
  <Grid
    gridTemplateColumns="24px minmax(0, 1fr)"
    position="relative"
    _before={isActive ? getGradientBorder() : undefined}
    {...props}
  />
);

export const PlayerCardRank: FC<PropsWithChildren<GridItemProps>> = ({ children, ...props }) => (
  <GridItem
    alignItems="center"
    display="flex"
    justifyContent="center"
    height={15}
    backgroundColor="ui-01"
    backgroundImage="url('/images/poke-the-bear/player-card-bg.svg')"
    backgroundRepeat="no-repeat"
    borderTopLeftRadius="button"
    borderBottomLeftRadius="button"
    {...props}
  >
    <Text bold>{children}</Text>
  </GridItem>
);

export const PlayerCardContent: FC<PropsWithChildren<StackProps>> = (props) => (
  <HStack bg="ui-bg" spacing={3} px={3} borderTopRightRadius="button" borderBottomRightRadius="button" {...props} />
);

interface EmptyPlayerCardProps extends PlayerCardGridProps {
  num: number;
  isConnectedInGame: boolean;
  onOpenEnterCaveModal: () => void;
}

export const EmptyPlayerCard = ({ num, isConnectedInGame, onOpenEnterCaveModal, ...props }: EmptyPlayerCardProps) => {
  const { t } = useTranslation();
  const { data } = useActiveCaveRoundInfo();

  const renderIcon = () => {
    if (!data?.round) {
      return <ErrorIcon color="text-03" boxSize={8} />;
    }
    return <UserAvatarFilledIcon boxSize={8} color="text-03" />;
  };

  const renderContent = () => {
    const isRoundAvailable = !!data?.round && [PtbRoundStatus.NONE, PtbRoundStatus.OPEN].includes(data.round.status);
    const currency = data?.cave.currency || zeroAddress;
    const enterAmount = data?.cave.enterAmount || "0";

    if (isRoundAvailable) {
      return (
        <>
          <Text bold>{t("Available")}</Text>
          <CurrencyValueDisplay
            justifyContent="start"
            currency={currency}
            network={getNetworkFromPtbContractName(data.cave.contract)}
            total={formatToSignificant(enterAmount, 6)}
            iconProps={{ boxSize: 3 }}
            textProps={{ textStyle: "helper", color: "text-03" }}
          />
        </>
      );
    }

    return <Text bold>{t("ptb::Empty")}</Text>;
  };
  const inGameProps: Partial<GridProps> = isConnectedInGame ? {} : { cursor: "pointer", onClick: onOpenEnterCaveModal };
  return (
    <PlayerCardGrid {...inGameProps} {...props}>
      <PlayerCardRank>{num}</PlayerCardRank>
      <PlayerCardContent>
        {renderIcon()}
        <Box>{renderContent()}</Box>
      </PlayerCardContent>
    </PlayerCardGrid>
  );
};
