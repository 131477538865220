import { gql } from "graphql-request";

export const ptbCaveFragment = gql`
  fragment PtbCaveFragment on PtbCave {
    id
    name
    currency
    enterAmount
    protocolFeeBp
    onChainId
    contract
    playersPerRound
    roundDurationSecs
    isActive
  }
`;

export const ptbCaveRoundFragment = gql`
  fragment PtbCaveRoundFragment on PtbCaveRound {
    id
    onChainId
    status
    drawnTransactionHash
    openedAt
    revealTransactionHash
    revealedAt
  }
`;

export const ptbCaveRoundPlayerFragment = gql`
  fragment PtbCaveRoundPlayerFragment on PtbCaveRoundPlayer {
    entryIndex
    futureCommittedRoundsCount
    claimed
    refunded
    lost
    gemsEarned
  }
`;

export const ptbUserFragment = gql`
  fragment PtbUserFragment on User {
    address
    name
    isVerified
    isProfileImageVisible
    avatar {
      image {
        src
      }
    }
  }
`;

export const ptbPokeFragment = gql`
  fragment PtbPokeFragment on PtbPoke {
    automated
    pokedAt
    index
    isPokingUntil
  }
`;
