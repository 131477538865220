import { StyleFunctionProps, mode } from "@chakra-ui/theme-tools";

export const MenuTheme = {
  baseStyle: (props: StyleFunctionProps) => {
    const boxShadow = mode("shadow-popover-light", "shadow-popover-dark")(props);

    return {
      gutter: 1,
      button: {
        textStyle: "detail",
        bg: "field-02",
        color: "text-01",
        width: "14rem",
        px: 4,
        py: 3,
        span: {
          textAlign: "left",
        },
        borderRadius: "button",
        boxShadow: "none",
        _hover: {
          bg: "hover-ui",
        },
      },
      list: {
        zIndex: 5,
        p: 2,
        borderRadius: "button",
        border: "1px solid",
        borderColor: "border-01",
        bg: "ui-02",
        boxShadow,
        // Forgive me father
        ":focus:not([data-focus-visible-added]):not([data-focus-visible-disabled])": {
          boxShadow,
        },
      },
      item: {
        textStyle: "detail",
        p: 2,
        borderRadius: "mini",
        bg: "ui-02",
        _hover: {
          bg: "hover-ui",
          _active: {
            bg: "onclick-ui",
          },
        },
        _focus: {
          bg: "onclick-ui",
        },
      },
      divider: {
        borderColor: "ui-bg",
        my: 2,
        opacity: 1,
      },
    };
  },
  sizes: {
    xs: {
      button: {
        py: 1,
        height: 8, // 32px
      },
    },
    sm: {
      button: {
        py: 2,
        height: 10, // 40px
      },
      item: {
        height: 10, // 40px
      },
    },
    md: {
      button: {
        py: 3,
        height: 12, // 48px
      },
      item: {
        height: 12, // 48px
      },
    },
    lg: {
      button: {
        height: 14, // 56px
      },
      item: {
        height: 14, // 56px
      },
    },
  },
  variants: {
    lowcontrast: {
      button: {
        bg: "field-01",
      },
      list: {
        bg: "field-01",
      },
      item: {
        bg: "field-01",
      },
    },
  },
  defaultProps: {
    size: "md",
  },
};
