import { useCallback, useState } from "react";
import { HStack, ModalBody, Spinner, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Modal, ModalHeaderLegacy, Text } from "@looksrare/uikit";
import { MoDContract } from "@looksrare/yolo-games-gql-typegen";
import { MoDUnclaimedEntries } from "../../../network/graphql/hooks";
import { getTotalUnclaimedRounds } from "../utils/getTotalUnclaimedRounds";
import { getAssetPairByContractName } from "../../../utils/transformContract";
import { ClaimTransactionStepRow } from "./ClaimTransactionStepRow";
import { ClaimModalValueBreakdown } from "./ClaimModalValueBreakdown";

interface ClaimModalProps {
  totalUnclaimedAmount: bigint;
  unclaimedEntries: MoDUnclaimedEntries;
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
}

export const ClaimModal = ({
  totalUnclaimedAmount,
  unclaimedEntries,
  isOpen,
  onClose,
  onComplete,
}: ClaimModalProps) => {
  const { t } = useTranslation();
  const [currentStepIndex, setCurrentStepIndex] = useState("0-0");

  const unclaimedRoundsAmount = getTotalUnclaimedRounds(unclaimedEntries);

  const handleComplete = useCallback(
    (contractIdx: number, entryIdx: number) => {
      const amountOfContracts = Object.keys(unclaimedEntries).length;
      const amountOfEntriesInContract =
        unclaimedEntries[Object.keys(unclaimedEntries)[contractIdx] as MoDContract].length;

      const isLastEntryInContract = entryIdx >= amountOfEntriesInContract - 1;
      const isLastContract = contractIdx >= amountOfContracts - 1;

      if (isLastEntryInContract && isLastContract) {
        onComplete();
        return;
      }

      if (isLastEntryInContract) {
        setCurrentStepIndex(`${contractIdx + 1}-0`);
        return;
      }

      setCurrentStepIndex(`${contractIdx}-${entryIdx + 1}`);
    },
    [onComplete, unclaimedEntries]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} modalContentProps={{ backgroundColor: "ui-bg" }}>
      <ModalHeaderLegacy
        title={t("mod::Rounds to Claim: {{amount}}", { amount: unclaimedRoundsAmount })}
        onClose={onClose}
        showCloseButton
      />
      <ModalBody>
        <ClaimModalValueBreakdown totalUnclaimedAmount={totalUnclaimedAmount} unclaimedEntries={unclaimedEntries} />
      </ModalBody>
      <ModalBody backgroundColor="ui-01">
        <Stack spacing={4}>
          <HStack spacing={4}>
            <Spinner boxSize={6} color="interactive-03" />
            <Text textStyle="body" color="text-02" bold>
              {t("Claim Winnings")}
            </Text>
          </HStack>

          <Stack spacing={4} px={4} py={6} borderRadius="container" backgroundColor="ui-bg">
            {Object.entries(unclaimedEntries).map(([contract, entries], contractIdx) => {
              const modContract = contract as MoDContract;
              const assetPair = getAssetPairByContractName(modContract);

              return entries.map(({ roundOnChainIds, isRefund, amount }, entryIdx) => {
                const ctaText = isRefund
                  ? t("mod::Claim Canceled {{assetPair}}", { assetPair })
                  : t("mod::Claim {{assetPair}} Winnings", { assetPair });

                return (
                  <ClaimTransactionStepRow
                    key={`${contract}${amount}${entryIdx}`}
                    contract={modContract}
                    onChainIds={roundOnChainIds}
                    ctaText={ctaText}
                    isStepActive={currentStepIndex === `${contractIdx}-${entryIdx}`}
                    onComplete={() => handleComplete(contractIdx, entryIdx)}
                  />
                );
              });
            })}
          </Stack>
        </Stack>
      </ModalBody>
    </Modal>
  );
};
