import { useEffect, useRef } from "react";

export const useScrollToBottom = (updateKey: number) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!!nodeRef.current) {
      nodeRef.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget } = event;
        if (currentTarget instanceof Element) {
          currentTarget.scrollTo({ top: currentTarget.scrollHeight, behavior: "smooth" });
        }
      });
    }
  }, [nodeRef, updateKey]);
  return nodeRef;
};
