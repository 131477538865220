import { Icon, IconProps } from "../Icon";

const CartInCart = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.82 6.99996H28C28.3081 6.99239 28.6026 7.12734 28.798 7.36573C28.9934 7.60412 29.0679 7.9193 29 8.21996L27.3502 15.6442C26.3147 15.2286 25.184 15 24 15C21.3345 15 18.9397 16.1587 17.2917 18H8L8.82 22H15.223C15.0771 22.6432 15 23.3126 15 24H8C7.51724 24.0097 7.09647 23.6731 7 23.2L3.18 3.99996H0V1.99996H4C4.48276 1.99023 4.90353 2.32684 5 2.79996L5.82 6.99996ZM10 30C11.1046 30 12 29.1045 12 28C12 26.8954 11.1046 26 10 26C8.89543 26 8 26.8954 8 28C8 29.1045 8.89543 30 10 30ZM31 24C31 27.866 27.866 31 24 31C20.134 31 17 27.866 17 24C17 20.134 20.134 17 24 17C27.866 17 31 20.134 31 24ZM23 25.0859L27.2929 20.793L28.7071 22.2072L23 27.9143L19.2929 24.2072L20.7071 22.793L23 25.0859Z"
      fill="currentColor"
    />
  </Icon>
);

export default CartInCart;
