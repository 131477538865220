import { Icon, IconProps } from "../Icon";

const TicketRedeemablesFill = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 12C30 12.5523 29.5523 13 29 13C27.3431 13 26 14.3431 26 16C26 17.6569 27.3431 19 29 19C29.5523 19 30 19.4477 30 20V24C30 25.1046 29.1046 26 28 26H21V22H19V26H4C2.89543 26 2 25.1046 2 24V20C2 19.4477 2.44772 19 3 19C4.65685 19 6 17.6569 6 16C6 14.3431 4.65685 13 3 13C2.44772 13 2 12.5523 2 12V8C2 6.89543 2.89543 6 4 6H19V10H21V6H28C29.1046 6 30 6.89543 30 8V12ZM21 13H19V19H21V13Z"
      fill="currentColor"
    />
  </Icon>
);

export default TicketRedeemablesFill;
