import { useAccount } from "wagmi";
import { Address } from "viem";
import { useRouter } from "next/router";
import { USER_ACCOUNT_URI } from "@looksrare/config";

/**
 * Helper hook to get the address from the URL. In the case of "/accounts/me" it will return
 * the connected account
 *
 * @returns address
 */
export const useAddressFromQuery = () => {
  const { address } = useAccount();
  const { query } = useRouter();
  const routeAddress = query?.address as string | undefined;

  // Url is "/me"
  if (routeAddress === USER_ACCOUNT_URI && address) {
    return address;
  }

  // Url is "/me" but no connected account
  if (routeAddress === USER_ACCOUNT_URI && !address) {
    return undefined;
  }

  return routeAddress as Address;
};
