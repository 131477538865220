import { Icon, IconProps } from "../Icon";

const GmOutline = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 12.287 28.525 8.72601 25.8995 6.1005C23.274 3.475 19.713 2 16 2ZM16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16C28 22.6274 22.6274 28 16 28ZM13.8898 14.4403H11.9969C11.7603 13.6144 11.1107 13.1196 10.1514 13.1196C8.80489 13.1196 7.88857 14.1564 7.88857 15.9891C7.88857 17.8303 8.77047 18.8757 10.1686 18.8757C11.4076 18.8757 12.1518 18.1831 12.1776 17.0904H10.2848V15.6879H13.9716V16.7978C13.9716 19.1166 12.3841 20.5233 10.16 20.5233C7.67777 20.5233 6 18.7939 6 16.0063C6 13.1498 7.79392 11.472 10.1213 11.472C12.1131 11.472 13.6489 12.698 13.8898 14.4403ZM15.3708 20.4029V11.5924H17.668L20.0943 17.512H20.1976L22.6239 11.5924H24.9211V20.4029H23.1143V14.6683H23.0412L20.7611 20.3599H19.5308L17.2507 14.6468H17.1776V20.4029H15.3708Z"
      fill="currentColor"
    />
  </Icon>
);

export default GmOutline;
