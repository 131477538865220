import { IconButton, IconButtonProps, useClipboard } from "@chakra-ui/react";
import { CopyFileIcon, Popover, TooltipText } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";

interface CopyButtonProps extends IconButtonProps {
  value: string;
}

export const CopyButton = ({ value, ...props }: CopyButtonProps) => {
  const { t } = useTranslation();
  const { onCopy, hasCopied } = useClipboard(value);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (hasCopied) {
      setTooltipIsOpen(true);
      timer = setTimeout(() => {
        setTooltipIsOpen(false);
      }, 2000);
    }

    return () => {
      if (timer) {
        setTooltipIsOpen(false);
        clearTimeout(timer);
      }
    };
  }, [hasCopied, setTooltipIsOpen]);

  return (
    <Popover isOpen={tooltipIsOpen} placement="top" label={<TooltipText>{t("Copied!")}</TooltipText>}>
      <IconButton colorScheme="secondary" onClick={onCopy} {...props}>
        <CopyFileIcon />
      </IconButton>
    </Popover>
  );
};
