import { format, fromUnixTime } from "date-fns";

/**
 * Format timestamp to date string
 * @param timestamp
 * @param formatToken
 * @returns string
 */
export const formatTimestampAsDateString = (timestamp: number, formatToken = "HH:mm, LLL. d, yyyy"): string => {
  const isUnix = timestamp.toString().length === 10; // from Sep 2001 - Nov 2286, unix seconds will be 10 digits.
  return format(isUnix ? fromUnixTime(timestamp) : new Date(timestamp), formatToken);
};
