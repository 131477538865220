import { ReactNode } from "react";
import { useTranslation } from "next-i18next";
import { format } from "date-fns/format";
import { isBefore } from "date-fns/isBefore";
import { isEqual } from "date-fns/isEqual";
import noop from "lodash/noop";
import { ModalBody, Stack } from "@chakra-ui/react";
import { OfferFillIcon } from "../Icons";
import { Button } from "../Button";
import { NextLink } from "../NextLink";
import { Modal } from "./Modal";
import { ModalHeroHeader } from "./ModalHeaderLegacy";

export const selfExclusionForeverDate = new Date("2222-02-02 00:00");

interface SelfExclusionModalProps {
  endDate: string;
  children?: ReactNode;
}

export const SelfExclusionModal = ({ endDate, children }: SelfExclusionModalProps) => {
  const { t } = useTranslation();

  const formattedDate = format(endDate, "HH:mm dd MMMM yyyy");
  const isForever = isEqual(selfExclusionForeverDate, endDate);
  const isExpired = isBefore(endDate, Date.now());

  return (
    <Modal isOpen={!isExpired} onClose={noop}>
      <ModalHeroHeader
        icon={OfferFillIcon}
        title={t("Wallet Blocked")}
        label={t("You’ve blocked your wallet from playing all YOLO Games until: {{duration}}", {
          duration: isForever ? t("Forever") : formattedDate,
        })}
        onClose={noop}
      />
      <ModalBody>
        <Stack>
          <Button as={NextLink} href="/" width="100%">
            {t("Take Me Home")}
          </Button>

          {children}
        </Stack>
      </ModalBody>
    </Modal>
  );
};
