import type { FC, PropsWithChildren } from "react";
import { Box, BoxProps, HStack, type UseDisclosureReturn } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button, type ButtonProps, Chevron, Text, UserAvatarFilledIcon } from "@looksrare/uikit";
import { useActiveCaveRoundInfo } from "../../hooks";

interface PlayerListButtonTextProps extends BoxProps {
  current: number;
  total: number;
}

const PlayerListButtonText: FC<PropsWithChildren<PlayerListButtonTextProps>> = ({
  current,
  total,
  children,
  ...props
}) => (
  <Box {...props}>
    <Text as="div" textAlign="right" bold textStyle="detail">
      {`${current} / ${total}`}
    </Text>
    <Text as="div" textAlign="right" textStyle="caption" color="text-03">
      {children}
    </Text>
  </Box>
);

interface PlayerListButtonProps extends ButtonProps {
  onOpenPlayerList: UseDisclosureReturn["onOpen"];
}

export const PlayerListButton = ({ onOpenPlayerList, ...props }: PlayerListButtonProps) => {
  const { t } = useTranslation();
  const { data } = useActiveCaveRoundInfo();

  const successfulPokers = data?.players.filter((player) => !!player.poke?.pokedAt && player.lost !== true) || [];

  const currentPlayers = data?.players.length || 0;
  const totalPlayers = data?.cave.playersPerRound || 0;
  const totalPlayersWhoHaveNotLost = totalPlayers - successfulPokers.length;

  return (
    <Button width="100%" colorScheme="secondary" py={1} pl={2} pr={1} onClick={onOpenPlayerList} {...props}>
      <HStack spacing={2}>
        {(() => {
          // Note: The following conditions match GameActions.tsx
          if (data?.meta.isRoundOpen || data?.meta.isRoundNone) {
            return (
              <PlayerListButtonText current={currentPlayers} total={totalPlayers}>
                {t("ptb::Joined")}
              </PlayerListButtonText>
            );
          }
          if (data?.meta.isRoundDrawn || data?.meta.isRoundDrawing) {
            return (
              <PlayerListButtonText current={totalPlayersWhoHaveNotLost} total={totalPlayers}>
                {t("ptb::Remaining")}
              </PlayerListButtonText>
            );
          }
          if (data?.meta.isRoundRevealed || data?.meta.isRoundCancelled) {
            return (
              <PlayerListButtonText current={totalPlayers - 1} total={totalPlayers}>
                {t("ptb::Survived")}
              </PlayerListButtonText>
            );
          }
          return null;
        })()}
        <HStack spacing={0}>
          <UserAvatarFilledIcon color="text-03" boxSize={5} />
          <Chevron color="text-03" boxSize={4} direction="right" />
        </HStack>
      </HStack>
    </Button>
  );
};
