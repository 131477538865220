import { useRouter } from "next/router";
import { type PtbSupportedNetwork } from "..";

type PtbQuery = {
  network: PtbSupportedNetwork;
};

export const useGetHistoryQueryParams = (): PtbQuery => {
  const router = useRouter();
  const { network } = router.query as PtbQuery;
  return { network };
};
