import { useCallback } from "react";
import { useAccount, useDisconnect } from "wagmi";
import { useGetFormattedErrorAndTitle } from "@looksrare/utils";
import { useToast } from "@/hooks";
import { logout } from "@/utils";

export const useDisconnectWallet = () => {
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { toast } = useToast();
  const getFormattedErrorAndTitle = useGetFormattedErrorAndTitle();

  return useCallback(async () => {
    disconnect();
    if (address) {
      try {
        await logout(address);
      } catch (error) {
        console.error(error);
        const { title, description } = getFormattedErrorAndTitle(error);
        toast({ status: "error", title, description, dataIdSuffix: "disconnect-wallet" });
      }
    }
  }, [address, disconnect, getFormattedErrorAndTitle, toast]);
};
