export const AccordionTheme = {
  baseStyle: {
    container: {
      border: "none",
    },
    button: {
      borderRadius: "container",
      py: 4,
      px: 4,
    },
    panel: {
      px: 4,
    },
  },
  variants: {
    default: {
      container: {
        bg: "ui-01",
        borderRadius: "container",
      },
      panel: {
        py: 6,
        bg: "ui-bg",
        border: "1px solid",
        borderColor: "border-01",
        borderBottomLeftRadius: "container",
        borderBottomRightRadius: "container",
      },
      button: {
        bg: "ui-01",
        border: "1px solid",
        borderColor: "border-01",
      },
    },
    segment: {
      container: {
        bg: "ui-01",
        borderBottomLeftRadius: "container",
        borderBottomRightRadius: "container",
      },
      panel: {
        bg: "ui-bg",
        border: "1px solid",
        borderColor: "border-01",
        borderBottomLeftRadius: "container",
        borderBottomRightRadius: "container",
      },
      button: {
        bg: "ui-01",
        border: "1px solid",
        borderColor: "border-01",
        borderTop: "initial",
        borderTopLeftRadius: "0",
        borderTopRightRadius: "0",
      },
    },
    compact: {
      container: {
        bg: "ui-01",
        borderRadius: "container",
      },
      panel: {
        px: 2,
        py: 3,
        borderTop: "1px solid",
        borderColor: "border-01",
        borderBottomLeftRadius: "container",
        borderBottomRightRadius: "container",
      },
      button: {
        px: 2,
        py: 3,
      },
    },
    stepper: {
      panel: {
        pt: 8,
        pb: 0,
        pl: 16,
      },
      button: {
        textAlign: "start",
        minHeight: "44px",
        py: 0,
        _focus: {
          _hover: {
            _focus: {
              shadow: "border-01",
            },
          },
        },
        _hover: {
          shadow: "border-01",
        },
      },
      container: {
        pb: 12,
      },
    },
  },
  defaultProps: {
    variant: "default",
  },
};
