import { Icon, IconProps } from "../Icon";

const EthMono = (props: IconProps) => (
  <Icon {...props}>
    <path d="M15.9974 0L25.8184 16.2959L15.9975 22.1019L6.1759 16.2965L15.9974 0Z" fill="currentColor" />
    <path d="M6.1759 18.16L15.9971 23.9622L25.8241 18.16L15.9975 31.999L6.1759 18.16Z" fill="currentColor" />
  </Icon>
);

export default EthMono;
