import { Icon, IconProps } from "../Icon";

const LightFilled = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 1.5H12.75V5.22H11.25V1.5ZM16.2651 6.66751L18.8954 4.03801L19.9559 5.09851L17.3256 7.72876L16.2651 6.66751ZM18.7802 11.25H22.5002V12.75H18.7802V11.25ZM16.2697 17.328L17.3302 16.2675L19.9604 18.8978L18.8999 19.9575L16.2697 17.328ZM11.25 18.78H12.75V22.5H11.25V18.78ZM4.04688 18.9015L6.67788 16.2712L7.73838 17.3317L5.10813 19.962L4.04688 18.9015ZM1.5 11.25H5.22V12.75H1.5V11.25ZM4.04234 5.10226L5.10359 4.04176L7.73384 6.67201L6.67334 7.73251L4.04234 5.10226ZM12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5 9.51472 14.4853 7.5 12 7.5Z"
      fill="currentColor"
    />
  </Icon>
);

export default LightFilled;
