import { useEffect } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { ChainId, LS_PTB_MUTED_KEY } from "@looksrare/config";
import { subscribeWithSelector } from "zustand/middleware";
import { IS_TESTNET } from "@looksrare/config";
import { useAccount } from "wagmi";
import { StateSetter } from "../types";
import { setLocalStorageItem } from "../localStorage";
import { useIsChainSupported } from "./useIsChainSupported";

type SupportedModals = "bridgeFunds" | "bridgeLooks";

interface GlobalState {
  chainId: ChainId;
  isPtbSoundMuted: boolean;
  modals: Record<SupportedModals, boolean>;
  isPlaying: boolean;
}

interface GlobalStore extends GlobalState {
  setChainId: StateSetter<ChainId>;
  openModal: StateSetter<SupportedModals>;
  closeModal: StateSetter<SupportedModals>;
  toggleModal: StateSetter<SupportedModals>;
  setPtbSoundMuted: StateSetter<boolean>;
  togglePtbSoundMuted: () => void;
  setIsPlaying: StateSetter<GlobalState["isPlaying"]>;
}

const getInitialChainId = () => {
  return IS_TESTNET ? ChainId.SEPOLIA : ChainId.MAINNET;
};

export const useGlobalStore = create(
  subscribeWithSelector(
    immer<GlobalStore>((set) => ({
      chainId: getInitialChainId(),
      isPtbSoundMuted: false,
      modals: {
        bridgeFunds: false,
        bridgeLooks: false,
      },
      setChainId: (newChainId) => {
        set((state) => {
          state.chainId = newChainId;
        });
      },
      openModal: (modal) => {
        set((state) => {
          state.modals[modal] = true;
        });
      },
      closeModal: (modal) => {
        set((state) => {
          state.modals[modal] = false;
        });
      },
      toggleModal: (modal) => {
        set((state) => {
          state.modals[modal] = !state.modals[modal];
        });
      },
      setPtbSoundMuted: (newIsPtbSoundMuted) => {
        set((state) => {
          state.isPtbSoundMuted = newIsPtbSoundMuted;
        });
      },
      togglePtbSoundMuted: () => {
        set((state) => {
          state.isPtbSoundMuted = !state.isPtbSoundMuted;
        });
      },
      isPlaying: false, // Used to hide wallet balance updates while games animations are running
      setIsPlaying: (newIsPlaying) => {
        set((state) => {
          state.isPlaying = newIsPlaying;
        });
      },
    }))
  )
);

// State subscriptions
useGlobalStore.subscribe(
  (state) => state.isPtbSoundMuted,
  (isMuted) => {
    setLocalStorageItem(LS_PTB_MUTED_KEY, Boolean(isMuted));
  }
);

export const useUpdateChainId = () => {
  const setChainId = useGlobalStore((state) => state.setChainId);
  const { chain } = useAccount();
  const isChainSupported = useIsChainSupported();
  useEffect(() => {
    if (!!chain && isChainSupported) {
      setChainId(chain.id as ChainId);
    }
  }, [chain, setChainId, isChainSupported]);
};

export const useCurrentChainId = () => useGlobalStore((state) => state.chainId);
