import { useEffect, useRef } from "react";
import type { Options } from "flatpickr/dist/types/options";
import { Input } from "../Input";

/**
 * @see https://flatpickr.js.org/options/
 */
const defaultOptions: Options = {
  dateFormat: "Y/m/d",
};

export const Datepicker = (props: Options) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const initializeDatepicker = async () => {
      if (ref.current) {
        const flatpickr = (await import("flatpickr")).default;
        const flatPickrOptions: Options = { ...defaultOptions, ...props };
        flatpickr(ref.current, flatPickrOptions);
      }
    };
    initializeDatepicker();
  }, [ref, props]);

  return <Input ref={ref} />;
};
