import { JwtPayload } from "jwt-decode";
import { Address } from "viem";

export enum JwtScope {
  /**
   * The short lived JWT which is used as the default required scope across the
   * application. Good for infrequent or uncommon user actions.
   */
  ShortLived = "SHORT_LIVED",
  /**
   * A long lived JWT which is used for actions which are non-critical and do not
   * require the same level of security.
   */
  LongLived = "LONG_LIVED",
}

export interface AuthPayload extends JwtPayload {
  address: Address;
  name: string;
}

export interface LoginPayload {
  address: Address;
  token: string;
}
