import { Box, Grid, HStack, IconButton } from "@chakra-ui/react";
import { ArrowLeftIcon, Container, type ButtonProps, NextLink, Text } from "@looksrare/uikit";
import { useActiveCaveRoundInfo, useGetCaveQueryParams } from "../../hooks";
import { MuteButton } from "../MuteButton";
import {
  CurrentButton,
  HistoryButton,
  NextJoinableRoundButton,
  NextRoundButton,
  PreviousRoundButton,
} from "../Buttons";
import { usePtbConfig } from "../..";

export const CaveHeader = () => {
  const { caveOnChainId, roundOnChainId, network } = useGetCaveQueryParams();
  const activeCaveRoundQuery = useActiveCaveRoundInfo();
  const { showLocalMuteButton } = usePtbConfig();

  const sharedButtonProps: Partial<ButtonProps> = {
    size: "xs",
    variant: "ghost",
    colorScheme: "secondary",
  };
  const gridColumns = showLocalMuteButton ? "32px minmax(0, 1fr) auto 32px" : "32px minmax(0, 1fr) auto";

  return (
    <Container
      as={Grid}
      height="48px"
      bgGradient="linear(to-b, ui-bg, ui-01)"
      gridTemplateColumns={gridColumns}
      alignItems="center"
      gridGap={3}
    >
      <IconButton
        size="xs"
        as={NextLink}
        href="/poke-the-bear"
        colorScheme="secondary"
        variant="outline"
        aria-label="Back to Lobby"
      >
        <ArrowLeftIcon boxSize={5} />
      </IconButton>
      <Box>
        <Text color="text-02" textStyle="detail" noOfLines={1} bold>
          {activeCaveRoundQuery.data?.cave.name}
        </Text>
        <Text color="text-03" textStyle="caption" noOfLines={1}>
          {`Round #${roundOnChainId}`}
        </Text>
      </Box>
      <HStack spacing={0}>
        <HistoryButton
          network={network}
          caveOnChainId={caveOnChainId}
          variant="ghost"
          colorScheme="secondary"
          {...sharedButtonProps}
        />
        <PreviousRoundButton {...sharedButtonProps} />
        <NextRoundButton {...sharedButtonProps} />
        <CurrentButton {...sharedButtonProps} />
        <NextJoinableRoundButton {...sharedButtonProps} />
      </HStack>
      {showLocalMuteButton && <MuteButton size="xs" colorScheme="secondary" variant="solid" />}
    </Container>
  );
};
