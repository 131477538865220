import { useMemo } from "react";
import { useTranslation } from "next-i18next";
import { FireIcon } from "@looksrare/uikit";
import times from "lodash/times";
import type { PtbSupportedNetwork } from "../types";
import { useCaves, useGetCaveQueryParams } from "../hooks";
import { getHotCaves, getNetworkFromPtbContractName, getPtbContractNameFromNetwork } from "../utils";
import { CaveListItem, CaveListItemSkeleton } from "./CaveListItem";
import { PtbAccordionButton, PtbAccordionItem, PtbAccordionPanel } from "./ClaimPanel/shared";

interface HotCavesAccordionProps {
  network: PtbSupportedNetwork;
}

export const HotCavesAccordion = ({ network }: HotCavesAccordionProps) => {
  const { t } = useTranslation();
  const { caveOnChainId, network: urlNetwork } = useGetCaveQueryParams();
  const cavesQuery = useCaves(getPtbContractNameFromNetwork(network));
  const hotCaves = useMemo(() => {
    return getHotCaves(cavesQuery.data);
  }, [cavesQuery.data]);
  return (
    <PtbAccordionItem>
      <PtbAccordionButton icon={<FireIcon color="link-01" />}>{t("Hot Caves")}</PtbAccordionButton>
      <PtbAccordionPanel>
        {(() => {
          if (cavesQuery.isLoading) {
            return times(5).map((n) => <CaveListItemSkeleton key={n} />);
          }
          if (cavesQuery.isSuccess) {
            return hotCaves.map((cave) => (
              <CaveListItem
                key={cave.id}
                isActive={
                  caveOnChainId === cave.onChainId && urlNetwork === getNetworkFromPtbContractName(cave.contract)
                }
                caveOnChainId={cave.onChainId}
                roundOnChainId={cave.round?.onChainId}
                name={cave.name}
                currency={cave.currency}
                network={network}
                enterAmount={cave.enterAmount}
                players={cave.round?.players}
                playersPerRound={cave.playersPerRound}
              />
            ));
          }
          return null;
        })()}
      </PtbAccordionPanel>
    </PtbAccordionItem>
  );
};
