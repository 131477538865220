import { getUnixTime } from "date-fns";
import jwt_decode from "jwt-decode";
import { AuthPayload, JwtScope } from "../types";

const ONE_DAY_IN_SECONDS = 60 * 60 * 24;

export const JwtExpiry = {
  [JwtScope.ShortLived]: ONE_DAY_IN_SECONDS, // 1 day in seconds
  [JwtScope.LongLived]: ONE_DAY_IN_SECONDS * 30, // 30 days in seconds
} as const;

/**
 * Check the JWT validity
 * @param jwt
 * @param scope Scope for validity check - defaults to ShortLived
 * @returns true if the JWT can be parsed, is not expired and was issued within the validity period for the scope
 */
export const isJwtValid = (jwt: string, scope: JwtScope = JwtScope.ShortLived): boolean => {
  try {
    const decode = jwt_decode<AuthPayload>(jwt);
    const expireIn = decode.exp ?? 0;
    const issuedAt = decode.iat ?? 0;
    const now = getUnixTime(new Date());
    const secondsSinceIssued = now - issuedAt;
    const validFor = expireIn - now;
    const isValid = validFor > 0;

    // Return early if JWT has expired
    if (!isValid) {
      return false;
    }

    const isValidShortLived = secondsSinceIssued <= JwtExpiry.SHORT_LIVED;
    const isValidLongLived = secondsSinceIssued <= JwtExpiry.LONG_LIVED;

    if (scope === JwtScope.LongLived) {
      return isValidLongLived;
    }
    return isValidShortLived;
  } catch (error) {
    console.error(error);
    return false;
  }
};
