import { StyleFunctionProps } from "@chakra-ui/theme-tools";
import { colors } from "@looksrare/chakra-theme";

export const TagTheme = {
  baseStyle: {
    container: {
      fontWeight: 600,
      transition: "background-color 0.2s ease-out",
      bg: "ui-02",
    },
    closeButton: {
      opacity: 1,
      mr: "-4px",
    },
  },
  variants: {
    solid: {
      container: {
        bg: "ui-02",
        color: "link-02",
      },
    },
    // note outline can be any color, not just our semantic colors
    outline: (props: StyleFunctionProps) => {
      const { colorScheme, colorMode } = props;
      const isLightMode = colorMode === "light";
      const iconAndTextShade = isLightMode ? "400" : "300";
      const borderShade = isLightMode ? "200" : "600";

      const textColor = colors[colorScheme!][iconAndTextShade];

      return {
        color: textColor,
        container: {
          bg: "transparent",
          border: "1px solid",
          borderColor: colors[colorScheme][borderShade],
          color: textColor,
          boxShadow: "none",
        },
      };
    },
  },
  sizes: {
    sm: {
      container: {
        textStyle: "helper",
        borderRadius: "circular",
        py: "0",
        px: "6px",
      },
    },
    md: {
      container: {
        textStyle: "detail",
        borderRadius: "circular",
        py: "6px",
        px: "12px",
      },
    },
  },
  defaultProps: {
    colorScheme: "gray",
    variant: "solid",
  },
};
