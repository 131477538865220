/**
 * Chakra : Figma
 * 900 = 100
 * 800 = 90
 * 700 = 80
 * 600 = 70
 * 500 = 60
 * 400 = 50
 * 300 = 40
 * 200 = 30
 * 100 = 20
 * 50 = 10
 */
export const palette = {
  gray: {
    950: "#0d121c",
    900: "#111927",
    850: "#1A1E22", // LR
    800: "#1f2a37 ",
    750: "#262C31", // LR
    700: "#384250",
    600: "#4d5761",
    500: "#6c737f",
    400: "#9da4ae",
    300: "#d2d6db",
    200: "#e5e7eb",
    150: "#DEDEE3", // LR
    100: "#f3f4f6",
    75: "#EBEBF1", // lR
    50: "#f9fafb",
    25: "#fcfcfd",
  },
  green: {
    900: "#054f31",
    800: "#05603a",
    700: "#027a48",
    600: "#039855",
    500: "#12B76a",
    400: "#13e87d",
    300: "#67E9A3",
    200: "#a6f4c5",
    100: "#d1fadf",
    50: "#ecfdf3",
    25: "#f6fef9",
  },
  blue: {
    900: "#194185",
    800: "#1849a9",
    700: "#175cd3",
    600: "#1570ef",
    500: "#2e90fa",
    400: "#53b1fd",
    300: "#84caff",
    200: "#b2ddff",
    100: "#d1e9ff",
    50: "#eff8ff",
    25: "#f5faff",
  },
  purple: {
    900: "#3e1c96",
    800: "#4a1fb8",
    700: "#5925dc",
    600: "#6938ef",
    500: "#7a5af8",
    400: "#9b8afb",
    300: "#bdb4fe",
    200: "#d9d6fe",
    100: "#ebe9fe",
    50: "#f4f3ff",
    25: "#fafaff",
  },
  magenta: {
    900: "#851651",
    800: "#9e165f",
    700: "#c11574",
    600: "#dd2590",
    500: "#ee46bc",
    400: "#f670c7",
    300: "#faa7e0",
    200: "#fcceee",
    100: "#fce7f6",
    50: "#fdf2fa",
    25: "#fef6fb",
  },
  pink: {
    900: "#851651",
    800: "#9e165f",
    700: "#c11574",
    600: "#dd2590",
    500: "#ee46bc",
    400: "#f670c7",
    300: "#faa7e0",
    200: "#fcceee",
    100: "#fce7f6",
    50: "#fdf2fa",
    25: "#fef6fb",
  },
  red: {
    900: "#7a271a",
    800: "#912018",
    700: "#b42318",
    600: "#d92d20",
    500: "#f04438",
    400: "#f97066",
    300: "#fda29b",
    200: "#fecdca",
    100: "#fee4e2",
    50: "#fef3f2",
    25: "#fffbfa",
  },
  yellow: {
    900: "#7a2e0e",
    800: "#93370d",
    700: "#b54708",
    600: "#dc6803",
    500: "#f79009",
    400: "#fdb022",
    300: "#fec84b",
    200: "#fedf89",
    100: "#fef0c7",
    50: "#fffaeb",
    25: "#fffcf5",
  },
  orange: {
    900: "#7e2410",
    800: "#9c2a10",
    700: "#c4320a",
    600: "#ec4a0a",
    500: "#fb6514",
    400: "#fd853a",
    300: "#feb273",
    200: "#fddcab",
    100: "#ffead5",
    50: "#fff6ed",
    25: "#fffaf5",
  },
  black: {
    900: "#000000",
    800: "#000000",
    700: "#000000",
    600: "#000000",
    500: "#000000",
    400: "#000000",
    300: "#000000",
    200: "#000000",
    100: "#000000",
    50: "#000000",
  },
  white: {
    900: "#FFFFFF",
    800: "#FFFFFF",
    700: "#FFFFFF",
    600: "#FFFFFF",
    500: "#FFFFFF",
    400: "#FFFFFF",
    300: "#FFFFFF",
    200: "#FFFFFF",
    100: "#FFFFFF",
    50: "#FFFFFF",
  },
  blackAlpha: {
    900: "#000000EB",
    800: "#000000CC",
    700: "#000000A3",
    600: "#0000007A",
    500: "#0000005C",
    400: "#0000003D",
    300: "#00000029",
    200: "#0000001A",
    100: "#00000014",
    50: "#0000000A",
  },
  grayAlpha: {
    900: "#000000EB",
    800: "#000000CC",
    700: "#000000A3",
    600: "#0000007A",
    500: "#0000005C",
    400: "#0000003D",
    300: "#00000029",
    200: "#00000014",
    100: "#0000000F",
    50: "#0000000A",
  },
  whiteAlpha: {
    900: "#FFFFFFEB",
    800: "#FFFFFFCC",
    700: "#FFFFFFA3",
    600: "#FFFFFF7A",
    500: "#FFFFFF5C",
    400: "#FFFFFF3D",
    300: "#FFFFFF29",
    200: "#FFFFFF1A",
    100: "#FFFFFF14",
    50: "#FFFFFF0A",
  },
  teal: {
    950: "#0a2926",
    900: "#134e48",
    800: "#125d56",
    700: "#107569",
    600: "#0e9384",
    500: "#15b79e",
    400: "#2ed3b7",
    300: "#5fe9d0",
    200: "#99f5e0",
    100: "#ccfbef",
    50: "#f0fdf9",
    25: "#f6fefc",
  },
  acid: {
    900: "#304200",
    800: "#3a5000",
    700: "#486400",
    600: "#5d7a00",
    500: "#749900",
    400: "#93bc00",
    300: "#b2e400",
    200: "#ccfd07 ",
    100: "#e7ff87",
    50: "#f5ffce",
    25: "#faffe7",
  },
  indigo: {
    900: "#2d3282",
    800: "#2d31a6",
    700: "#3538cd",
    600: "#444ce7",
    500: "#6172f3",
    400: "#8098f9",
    300: "#a4bcdf",
    200: "#c7d7fe",
    100: "#e0eaff",
    50: "#eef4ff",
    25: "#f5f8ff",
  },
};

export const semanticTokens = {
  colors: {
    // UI
    "ui-bg": {
      _dark: "gray.900",
      _light: "gray.900",
    },
    "ui-01": {
      _dark: "gray.800",
      _light: "gray.800",
    },
    "ui-02": {
      _dark: "gray.700",
      _light: "gray.700",
    },
    "ui-01-inverse": {
      // LR
      _dark: "white",
      _light: "white",
    },
    "ui-inverse": {
      _dark: "white",
      _light: "white",
    },
    "border-01": {
      _dark: "rgba(255, 255, 255, 0.08)",
      _light: "rgba(255, 255, 255, 0.08)",
    },
    "border-02": {
      // LR
      _dark: "gray.700",
      _light: "gray.700",
    },
    "ui-bg-translucent": {
      _dark: `${palette.gray["900"]}B3`,
      _light: `${palette.gray["900"]}B3`,
    },
    "ui-glass": {
      _dark: "rgba(0, 0, 0, 0.24)",
      _light: "rgba(0, 0, 0, 0.24)",
    },

    // Interactive
    "interactive-01": {
      _dark: "gray.50",
      _light: "gray.50",
    },
    "interactive-02": {
      _dark: "rgba(255, 255, 255, 0.04)",
      _light: "rgba(255, 255, 255, 0.04)",
    },
    "interactive-03": {
      _dark: "acid.200",
      _light: "acid.200",
    },
    "field-01": {
      _dark: "rgba(255, 255, 255, 0.04)",
      _light: "rgba(255, 255, 255, 0.04)",
    },
    "field-02": {
      _dark: "rgba(255, 255, 255, 0.08)",
      _light: "rgba(255, 255, 255, 0.08)",
    },
    "switch-checked-bg": "green.500",

    // Text
    "text-01": {
      _dark: "gray.50",
      _light: "gray.50",
    },
    "text-02": {
      _dark: "gray.200",
      _light: "gray.200",
    },
    "text-03": {
      _dark: "gray.400",
      _light: "gray.400",
    },
    "text-placeholder": {
      _dark: "gray.500",
      _light: "gray.500",
    },
    "text-disabled": {
      _dark: "gray.600",
      _light: "gray.600",
    },
    "text-primarybutton": {
      _dark: "gray.900",
      _light: "gray.900",
    },
    "text-inverse": {
      _dark: "gray.900",
      _light: "gray.900",
    },
    "text-inverse-02": {
      _dark: "gray.700",
      _light: "gray.700",
    },
    "text-inverse-03": {
      _dark: "gray.600",
      _light: "gray.600",
    },
    "text-error": {
      _dark: "red.500",
      _light: "red.500",
    },
    "text-warning": {
      _dark: "yellow.300",
      _light: "yellow.300",
    },
    "text-gradient-01": {
      _dark: "linear-gradient(135deg, #FFFFFF 30%, rgba(255, 255, 255, 0.38) 100%)",
      _light: "linear-gradient(135deg, #FFFFFF 30%, rgba(255, 255, 255, 0.38) 100%)",
    },
    "text-gradient-02": {
      _dark: "linear-gradient(180deg, #FFDE65 34.38%, #8A3FFC 100%), #FFFFFF",
      _light: "linear-gradient(180deg, #FFDE65 34.38%, #8A3FFC 100%), #FFFFFF",
    },
    "link-01": {
      _dark: "acid.200",
      _light: "acid.200",
    },
    "link-02": {
      _dark: "gray.50",
      _light: "gray.50",
    },
    "link-inverse": {
      _dark: "acid.500 ",
      _light: "acid.500 ",
    },
    goldrush: {
      _dark: "#FCFC06",
      _light: "#FCFC06",
    },

    // States
    focus: {
      _dark: "gray.200",
      _light: "gray.200",
    },
    "focus-inverse": {
      _dark: "teal.700",
      _light: "teal.700",
    },
    "hover-ui": {
      _dark: "rgba(255, 255, 255, 0.08)",
      _light: "rgba(255, 255, 255, 0.08)",
    },
    "onclick-ui": {
      _dark: "rgba(255, 255, 255, 0.04)",
      _light: "rgba(255, 255, 255, 0.04)",
    },
    "hover-ui-inverse": {
      _dark: "gray.100",
      _light: "gray.100",
    },
    "hover-interactive-01": {
      _dark: "gray.100",
      _light: "gray.100",
    },
    "onclick-interactive-01": {
      _dark: "gray.200",
      _light: "gray.200",
    },

    "hover-interactive-02": {
      _dark: "rgba(255, 255, 255, 0.1)",
      _light: "rgba(255, 255, 255, 0.1)",
    },
    "onclick-interactive-02": {
      _dark: "rgba(255, 255, 255, 0.06)",
      _light: "rgba(255, 255, 255, 0.06)",
    },
    "hover-interactive-03": {
      _dark: "gray.100",
      _light: "gray.100",
    },
    "onclick-interactive-03": {
      _dark: "acid.300",
      _light: "acid.300",
    },
    "hover-error": {
      _dark: "red.400",
      _light: "red.400",
    },
    "onclick-error": {
      _dark: "red.600",
      _light: "red.600",
    },
    "hover-link-01": {
      _dark: "acid.400",
      _light: "acid.400",
    },
    "hover-goldrush": {
      _dark: "gray.50",
      _light: "gray.50",
    },
    "onclick-goldrush": {
      _dark: "yellow.400",
      _light: "yellow.400",
    },

    // Support
    "support-error": {
      _dark: "red.500",
      _light: "red.500",
    },
    "support-error-alpha": {
      _dark: palette.red[500] + "80", // 50% opacity
      _light: palette.red[500] + "80", // 50% opacity
    },
    "support-error-inverse": {
      _dark: "red.600",
      _light: "red.600",
    },
    "support-error-bg": {
      _dark: "gray.800",
      _light: "gray.800",
    },
    "support-error-bg-inverse": {
      _dark: "red.50",
      _light: "red.50",
    },
    "support-success": {
      _dark: "green.400",
      _light: "green.400",
    },
    "support-success-alpha": {
      _dark: palette.green[400] + "80", // 50% opacity
      _light: palette.green[400] + "80", // 50% opacity
    },
    "support-success-inverse": {
      _dark: "green.500",
      _light: "green.500",
    },
    "support-success-bg": {
      _dark: "gray.800",
      _light: "gray.800",
    },
    "support-success-bg-inverse": {
      _dark: "green.50",
      _light: "green.50",
    },
    "support-warning": {
      _dark: "yellow.300",
      _light: "yellow.300",
    },
    "support-warning-inverse": {
      _dark: "yellow.400",
      _light: "yellow.400",
    },
    "support-warning-bg": {
      _dark: "gray.800",
      _light: "gray.800",
    },
    "support-warning-bg-inverse": {
      _dark: "yellow.50",
      _light: "yellow.50",
    },
    "support-info": {
      _dark: "blue.500",
      _light: "blue.500",
    },
    "support-info-inverse": {
      _dark: "blue.600",
      _light: "blue.600",
    },
    "support-info-bg": {
      _dark: "gray.800",
      _light: "gray.800",
    },
    "support-info-bg-inverse": {
      _dark: "blue.600",
      _light: "blue.600",
    },

    // Gradient pairs
    "ui-gradient-02-a": {
      _dark: "gray.900",
      _light: "gray.900",
    },
    "ui-gradient-02-b": {
      _dark: "gray.800",
      _light: "gray.800",
    },

    "ui-gradient-rainbow-a": {
      _dark: "acid.200",
      _light: "acid.200",
    },
    "ui-gradient-rainbow-b": {
      _dark: "acid.200",
      _light: "acid.200",
    },
    "ui-gradient-goldrush-a": {
      _dark: "yellow.300",
      _light: "yellow.300",
    },
    "ui-gradient-goldrush-b": {
      _dark: "goldrush",
      _light: "goldrush",
    },
    "ui-liquidity-a": {
      _dark: "gray.900",
      _light: "gray.900",
    },
    "ui-liquidity-b": {
      _dark: "purple.900",
      _light: "purple.900",
    },

    // Confetti
    "confetti-01": {
      _dark: "acid.200",
      _light: "acid.200",
    },
    "confetti-02": {
      _dark: "acid.300",
      _light: "acid.300",
    },
    "confetti-03": {
      _dark: "acid.100",
      _light: "acid.100",
    },
    "confetti-04": {
      _dark: "acid.200",
      _light: "acid.200",
    },
    "confetti-05": {
      _dark: "acid.300",
      _light: "acid.300",
    },
    "confetti-06": {
      _dark: "white.100",
      _light: "white.100",
    },
  },
};

/**
 * Gradient values
 * @todo-yg Update with yg values when finalized
 */
export const gradients = {
  "gradient-fill-gem": "linear(316deg, magenta.400, purple.500)",
  "gradient-border-rainbow": "linear-gradient(180deg, #FEDF89 0%, #EE46BC 33.5%, #33DEBF 100%)",
  rainbow: "linear(to-t, acid.200, acid.300)",
  "ui-gradient-v-01": "linear(to-t, ui-gradient-02-a, ui-gradient-02-b)",
  "ui-gradient-v-02": "linear(to-t, ui-01, ui-bg)",
  "ui-gradient-goldrush": "linear(to-t, ui-gradient-goldrush-a, ui-gradient-goldrush-b)",
  "new-ui-gradient-v-01": "linear-gradient(to-b, gray.800 0%, gray.900 100%)",
  "new-ui-gradient-v-02": "linear-gradient(to-b,gray.900 0%, gray.800 100%)",
};

/**
 * Semantic color values
 */
export const base = {};

export const colors = {
  ...palette,
  base,
};
