import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const YoloWeth = (props: IconProps) => {
  const [id1] = useState(uniqueId());
  const [id2] = useState(uniqueId());
  const [id3] = useState(uniqueId());

  return (
    <Icon width="64px" height="48px" viewBox="0 0 64 48" {...props}>
      <g clipPath={`url(#${id1})`}>
        <circle cx="40" cy="24" r="24" fill="#CCFD07" />
        <path
          d="M43.7541 13.0137H52.1937L44.0719 37.6178H35.6323L38.7045 28.3604H32.5601L27.793 13.0137H36.1267L39.6579 25.4389L43.7541 13.0137Z"
          fill="#1F2A37"
        />
      </g>
      <g clipPath={`url(#${id2})`}>
        <circle cx="15" cy="33" r="15" fill="#EEDADA" />
        <g clipPath={`url(#${id3})`}>
          <path
            d="M14.9989 22.3125L14.8555 22.7996V36.9337L14.9989 37.0768L21.5596 33.1987L14.9989 22.3125Z"
            fill="#DF5960"
          />
          <path d="M14.9984 22.3125L8.4375 33.1987L14.9984 37.0768V30.2165V22.3125Z" fill="#EE9398" />
          <path
            d="M14.9988 38.3194L14.918 38.4179V43.4525L14.9988 43.6885L21.5634 34.4434L14.9988 38.3194Z"
            fill="#DF5960"
          />
          <path d="M14.9982 43.6885V38.3194L8.4375 34.4434L14.9982 43.6885Z" fill="#EE9398" />
          <path d="M15 37.0759L21.5606 33.1979L15 30.2158V37.0759Z" fill="#CF373E" />
          <path d="M8.4375 33.1979L14.9982 37.0759V30.2158L8.4375 33.1979Z" fill="#DF5960" />
        </g>
      </g>
      <defs>
        <clipPath id={id1}>
          <rect width="48" height="48" fill="white" transform="translate(16)" />
        </clipPath>
        <clipPath id={id2}>
          <rect width="30" height="30" fill="white" transform="translate(0 18)" />
        </clipPath>
        <clipPath id={id3}>
          <rect width="13.125" height="26.25" fill="white" transform="translate(8.4375 19.875)" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default YoloWeth;
