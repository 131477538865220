export const RaffleV2Abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_weth",
        type: "address",
      },
      {
        internalType: "bytes32",
        name: "_keyHash",
        type: "bytes32",
      },
      {
        internalType: "uint64",
        name: "_subscriptionId",
        type: "uint64",
      },
      {
        internalType: "address",
        name: "_vrfCoordinator",
        type: "address",
      },
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "_protocolFeeRecipient",
        type: "address",
      },
      {
        internalType: "uint16",
        name: "_protocolFeeBp",
        type: "uint16",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [],
    name: "CutoffTimeNotReached",
    type: "error",
  },
  {
    inputs: [],
    name: "CutoffTimeReached",
    type: "error",
  },
  {
    inputs: [],
    name: "DrawExpirationTimeNotReached",
    type: "error",
  },
  {
    inputs: [],
    name: "ERC1155SafeTransferFromFail",
    type: "error",
  },
  {
    inputs: [],
    name: "ERC20TransferFail",
    type: "error",
  },
  {
    inputs: [],
    name: "ERC20TransferFromFail",
    type: "error",
  },
  {
    inputs: [],
    name: "ERC721TransferFromFail",
    type: "error",
  },
  {
    inputs: [],
    name: "InsufficientNativeTokensSupplied",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidCaller",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidCount",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidCurrency",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidCutoffTime",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidIndex",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidPricingOption",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidPricingOptionsCount",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidPrize",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidPrizesCount",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidProtocolFeeBp",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidProtocolFeeRecipient",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidStatus",
    type: "error",
  },
  {
    inputs: [],
    name: "InvalidWinnersCount",
    type: "error",
  },
  {
    inputs: [],
    name: "IsPaused",
    type: "error",
  },
  {
    inputs: [],
    name: "MaximumEntriesPerParticipantReached",
    type: "error",
  },
  {
    inputs: [],
    name: "MaximumEntriesReached",
    type: "error",
  },
  {
    inputs: [],
    name: "NoOngoingTransferInProgress",
    type: "error",
  },
  {
    inputs: [],
    name: "NotAContract",
    type: "error",
  },
  {
    inputs: [],
    name: "NotEnoughEntries",
    type: "error",
  },
  {
    inputs: [],
    name: "NotOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "NotPaused",
    type: "error",
  },
  {
    inputs: [],
    name: "NothingToClaim",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "have",
        type: "address",
      },
      {
        internalType: "address",
        name: "want",
        type: "address",
      },
    ],
    name: "OnlyCoordinatorCanFulfill",
    type: "error",
  },
  {
    inputs: [],
    name: "RandomnessRequestAlreadyExists",
    type: "error",
  },
  {
    inputs: [],
    name: "RandomnessRequestDoesNotExist",
    type: "error",
  },
  {
    inputs: [],
    name: "ReentrancyFail",
    type: "error",
  },
  {
    inputs: [],
    name: "RenouncementNotInProgress",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferAlreadyInProgress",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferNotInProgress",
    type: "error",
  },
  {
    inputs: [],
    name: "WrongPotentialOwner",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [],
    name: "CancelOwnershipTransfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address[]",
        name: "currencies",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "isAllowed",
        type: "bool",
      },
    ],
    name: "CurrenciesStatusUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "buyer",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint208",
        name: "amount",
        type: "uint208",
      },
    ],
    name: "EntryRefunded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "buyer",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint40",
        name: "entriesCount",
        type: "uint40",
      },
      {
        indexed: false,
        internalType: "uint208",
        name: "price",
        type: "uint208",
      },
    ],
    name: "EntrySold",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "FeesClaimed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "InitiateOwnershipRenouncement",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "potentialOwner",
        type: "address",
      },
    ],
    name: "InitiateOwnershipTransfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "NewOwner",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Paused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "winnerIndex",
        type: "uint256",
      },
    ],
    name: "PrizeClaimed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256[]",
        name: "winnerIndex",
        type: "uint256[]",
      },
    ],
    name: "PrizesClaimed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint16",
        name: "protocolFeeBp",
        type: "uint16",
      },
    ],
    name: "ProtocolFeeBpUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "protocolFeeRecipient",
        type: "address",
      },
    ],
    name: "ProtocolFeeRecipientUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum IRaffleV2.RaffleStatus",
        name: "status",
        type: "uint8",
      },
    ],
    name: "RaffleStatusUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "requestId",
        type: "uint256",
      },
    ],
    name: "RandomnessRequested",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Unpaused",
    type: "event",
  },
  {
    inputs: [],
    name: "MAXIMUM_NUMBER_OF_PRIZES_PER_RAFFLE",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "MAXIMUM_NUMBER_OF_WINNERS_PER_RAFFLE",
    outputs: [
      {
        internalType: "uint40",
        name: "",
        type: "uint40",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "MAXIMUM_PRICING_OPTIONS_PER_RAFFLE",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "MAXIMUM_PROTOCOL_FEE_BP",
    outputs: [
      {
        internalType: "uint16",
        name: "",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "SUBSCRIPTION_ID",
    outputs: [
      {
        internalType: "uint64",
        name: "",
        type: "uint64",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
    ],
    name: "cancel",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
    ],
    name: "cancelAfterRandomnessRequest",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "cancelOwnershipTransfer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
    ],
    name: "claimFees",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "winnerIndex",
        type: "uint256",
      },
    ],
    name: "claimPrize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "raffleId",
            type: "uint256",
          },
          {
            internalType: "uint256[]",
            name: "winnerIndices",
            type: "uint256[]",
          },
        ],
        internalType: "struct IRaffleV2.ClaimPrizesCalldata[]",
        name: "claimPrizesCalldata",
        type: "tuple[]",
      },
    ],
    name: "claimPrizes",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "raffleIds",
        type: "uint256[]",
      },
    ],
    name: "claimRefund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "confirmOwnershipRenouncement",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "confirmOwnershipTransfer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint40",
            name: "cutoffTime",
            type: "uint40",
          },
          {
            internalType: "bool",
            name: "isMinimumEntriesFixed",
            type: "bool",
          },
          {
            internalType: "uint40",
            name: "minimumEntries",
            type: "uint40",
          },
          {
            internalType: "uint40",
            name: "maximumEntriesPerParticipant",
            type: "uint40",
          },
          {
            internalType: "uint16",
            name: "protocolFeeBp",
            type: "uint16",
          },
          {
            internalType: "address",
            name: "feeTokenAddress",
            type: "address",
          },
          {
            components: [
              {
                internalType: "uint40",
                name: "winnersCount",
                type: "uint40",
              },
              {
                internalType: "uint40",
                name: "cumulativeWinnersCount",
                type: "uint40",
              },
              {
                internalType: "enum IRaffleV2.TokenType",
                name: "prizeType",
                type: "uint8",
              },
              {
                internalType: "uint8",
                name: "prizeTier",
                type: "uint8",
              },
              {
                internalType: "address",
                name: "prizeAddress",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "prizeId",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "prizeAmount",
                type: "uint256",
              },
            ],
            internalType: "struct IRaffleV2.Prize[]",
            name: "prizes",
            type: "tuple[]",
          },
          {
            components: [
              {
                internalType: "uint40",
                name: "entriesCount",
                type: "uint40",
              },
              {
                internalType: "uint208",
                name: "price",
                type: "uint208",
              },
            ],
            internalType: "struct IRaffleV2.PricingOption[]",
            name: "pricingOptions",
            type: "tuple[]",
          },
        ],
        internalType: "struct IRaffleV2.CreateRaffleCalldata",
        name: "params",
        type: "tuple",
      },
    ],
    name: "createRaffle",
    outputs: [
      {
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
    ],
    name: "drawWinners",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "raffleId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "pricingOptionIndex",
            type: "uint256",
          },
          {
            internalType: "uint40",
            name: "count",
            type: "uint40",
          },
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
        ],
        internalType: "struct IRaffleV2.EntryCalldata[]",
        name: "entries",
        type: "tuple[]",
      },
    ],
    name: "enterRaffles",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
    ],
    name: "getEntries",
    outputs: [
      {
        components: [
          {
            internalType: "uint40",
            name: "currentEntryIndex",
            type: "uint40",
          },
          {
            internalType: "address",
            name: "participant",
            type: "address",
          },
        ],
        internalType: "struct IRaffleV2.Entry[]",
        name: "entries",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
    ],
    name: "getPricingOptions",
    outputs: [
      {
        components: [
          {
            internalType: "uint40",
            name: "entriesCount",
            type: "uint40",
          },
          {
            internalType: "uint208",
            name: "price",
            type: "uint208",
          },
        ],
        internalType: "struct IRaffleV2.PricingOption[]",
        name: "pricingOptions",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
    ],
    name: "getPrizes",
    outputs: [
      {
        components: [
          {
            internalType: "uint40",
            name: "winnersCount",
            type: "uint40",
          },
          {
            internalType: "uint40",
            name: "cumulativeWinnersCount",
            type: "uint40",
          },
          {
            internalType: "enum IRaffleV2.TokenType",
            name: "prizeType",
            type: "uint8",
          },
          {
            internalType: "uint8",
            name: "prizeTier",
            type: "uint8",
          },
          {
            internalType: "address",
            name: "prizeAddress",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "prizeId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "prizeAmount",
            type: "uint256",
          },
        ],
        internalType: "struct IRaffleV2.Prize[]",
        name: "prizes",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
    ],
    name: "getWinners",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "participant",
            type: "address",
          },
          {
            internalType: "bool",
            name: "claimed",
            type: "bool",
          },
          {
            internalType: "uint8",
            name: "prizeIndex",
            type: "uint8",
          },
          {
            internalType: "uint40",
            name: "entryIndex",
            type: "uint40",
          },
        ],
        internalType: "struct IRaffleV2.Winner[]",
        name: "winners",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "initiateOwnershipRenouncement",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newPotentialOwner",
        type: "address",
      },
    ],
    name: "initiateOwnershipTransfer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "isCurrencyAllowed",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    name: "onERC1155Received",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ownershipStatus",
    outputs: [
      {
        internalType: "enum IOwnableTwoSteps.Status",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "potentialOwner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "protocolFeeBp",
    outputs: [
      {
        internalType: "uint16",
        name: "",
        type: "uint16",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "protocolFeeRecipient",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "raffles",
    outputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "enum IRaffleV2.RaffleStatus",
        name: "status",
        type: "uint8",
      },
      {
        internalType: "bool",
        name: "isMinimumEntriesFixed",
        type: "bool",
      },
      {
        internalType: "uint40",
        name: "cutoffTime",
        type: "uint40",
      },
      {
        internalType: "uint40",
        name: "drawnAt",
        type: "uint40",
      },
      {
        internalType: "uint40",
        name: "minimumEntries",
        type: "uint40",
      },
      {
        internalType: "uint40",
        name: "maximumEntriesPerParticipant",
        type: "uint40",
      },
      {
        internalType: "address",
        name: "feeTokenAddress",
        type: "address",
      },
      {
        internalType: "uint16",
        name: "protocolFeeBp",
        type: "uint16",
      },
      {
        internalType: "uint208",
        name: "claimableFees",
        type: "uint208",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "rafflesCount",
    outputs: [
      {
        internalType: "uint80",
        name: "",
        type: "uint80",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "rafflesParticipantsStats",
    outputs: [
      {
        internalType: "uint208",
        name: "amountPaid",
        type: "uint208",
      },
      {
        internalType: "uint40",
        name: "entriesCount",
        type: "uint40",
      },
      {
        internalType: "bool",
        name: "refunded",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "randomnessRequests",
    outputs: [
      {
        internalType: "bool",
        name: "exists",
        type: "bool",
      },
      {
        internalType: "uint248",
        name: "randomWord",
        type: "uint248",
      },
      {
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "requestId",
        type: "uint256",
      },
      {
        internalType: "uint256[]",
        name: "randomWords",
        type: "uint256[]",
      },
    ],
    name: "rawFulfillRandomWords",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "refundableRaffleIds",
        type: "uint256[]",
      },
      {
        components: [
          {
            internalType: "uint256",
            name: "raffleId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "pricingOptionIndex",
            type: "uint256",
          },
          {
            internalType: "uint40",
            name: "count",
            type: "uint40",
          },
          {
            internalType: "address",
            name: "recipient",
            type: "address",
          },
        ],
        internalType: "struct IRaffleV2.EntryCalldata[]",
        name: "entries",
        type: "tuple[]",
      },
    ],
    name: "rollover",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "requestId",
        type: "uint256",
      },
    ],
    name: "selectWinners",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint16",
        name: "_protocolFeeBp",
        type: "uint16",
      },
    ],
    name: "setProtocolFeeBp",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_protocolFeeRecipient",
        type: "address",
      },
    ],
    name: "setProtocolFeeRecipient",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "togglePaused",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "currencies",
        type: "address[]",
      },
      {
        internalType: "bool",
        name: "isAllowed",
        type: "bool",
      },
    ],
    name: "updateCurrenciesStatus",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "raffleId",
        type: "uint256",
      },
    ],
    name: "withdrawPrizes",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;
