import { useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Address, zeroAddress } from "viem";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import {
  BigIntish,
  NoPublicClientError,
  getChainIdFromSupportedNetwork,
  isAddressEqual,
  multiplyWeiByNumber,
} from "@looksrare/utils";
import { PtbUnclaimedEntry, getUnclaimedWinnings } from "../graphql";
import { PlayerWithdrawalCallData, PtbSupportedNetwork, WithdrawalCallData } from "../types";
import { getNetWinnings, getPrizePerPlayerConsideringFees } from "../utils";
import { usePtbContractInfo } from "./usePtbContractInfo";

/**
 * Transform graphql response to call data for contract.
 * Sort WithdrawalCallData by currency type
 */
const transformToWithdrawalCallData = (unclaimedWinnings: PtbUnclaimedEntry[]) => {
  return [...unclaimedWinnings]
    .sort((a, b) => {
      if (a.cave.currency < b.cave.currency) {
        return -1;
      }
      if (a.cave.currency > b.cave.currency) {
        return 1;
      }
      return 0;
    })
    .reduce<WithdrawalCallData[]>((acc, unclaimedWinning) => {
      const {
        cave: { onChainId: caveId },
        round: { onChainId: roundId },
        entryIndex,
      } = unclaimedWinning;

      const caveIdBI = BigInt(caveId.toString());
      const roundIdBI = BigInt(roundId);

      const playerWithdrawalCallData = { roundId: roundIdBI, playerIndex: BigInt(entryIndex) };

      const cave = acc.find((c) => c.caveId === caveIdBI);

      // cave already exists in the array. push new player details
      if (cave) {
        cave.playerDetails.push(playerWithdrawalCallData);
        return acc;
      }

      // cave does not exist in the array. create new entry for the cave
      acc.push({ caveId: caveIdBI, playerDetails: [playerWithdrawalCallData] });
      return acc;
    }, []);
};

type GetRolloverAndClaimDataArgs = {
  unclaimedRefunds: PtbUnclaimedEntry[];
  unclaimedWinnings: PtbUnclaimedEntry[];
  caveId: bigint;
  enterAmount: BigIntish;
  protocolFeeBp: BigIntish;
  playersPerRound: number;
};

/**
 * In the Purchase Entries modal (EnterCaveModal), we show the user the amount of rollover they have available for this cave.
 *
 * We also show the user the amount of winnings that will be paid out for rolling over their funds.
 * getRolloverAndClaimData returns the rollover amount for that cave, and should also return the callData for the rollover function.
 */
export const getRolloverAndClaimData = ({
  unclaimedRefunds,
  unclaimedWinnings,
  caveId,
  enterAmount,
  protocolFeeBp,
  playersPerRound,
}: GetRolloverAndClaimDataArgs) => {
  const unclaimedRefundsForCave = unclaimedRefunds.filter((refund) => BigInt(refund.cave.onChainId) === caveId);
  const unclaimedWinningsForCave = unclaimedWinnings.filter((winning) => BigInt(winning.cave.onChainId) === caveId);

  // The `playerDetails` array is used to construct the callData for the rollover function. Later we sort the playerDetails array by the
  // winnings available for the corresponding roundId
  const refundPlayerDetails = unclaimedRefundsForCave.map<PlayerWithdrawalCallData>((refund) => {
    return {
      roundId: BigInt(refund.round.onChainId),
      playerIndex: BigInt(refund.entryIndex),
    };
  });
  const winningsPlayerDetails = unclaimedWinningsForCave.map<PlayerWithdrawalCallData>((winning) => {
    return {
      roundId: BigInt(winning.round.onChainId),
      playerIndex: BigInt(winning.entryIndex),
    };
  });
  // Prioritize the "winnings" player details. Using these roundId's in the rollover call will also send the profits to the user.
  const sortedPlayerDetails = [...winningsPlayerDetails, ...refundPlayerDetails];

  // Sum `totalRolloverAvailable`
  const refundRolloverAmount = multiplyWeiByNumber(BigInt(enterAmount), refundPlayerDetails.length);
  const winningRolloverAmount = multiplyWeiByNumber(BigInt(enterAmount), winningsPlayerDetails.length);
  const totalRolloverAvailable = refundRolloverAmount + winningRolloverAmount;

  // The `maxWinningsToClaim` is the ceiling, or winnings amount if all of the "winnings" rounds are rolled over.
  // At this point, we do not know how many rounds the user will be purchasing & therefore claiming winnings for.
  const prizePerPlayerAfterFeeWithoutPrincipal = getNetWinnings(enterAmount, playersPerRound, protocolFeeBp);

  const maxWinningsToClaim = multiplyWeiByNumber(
    prizePerPlayerAfterFeeWithoutPrincipal,
    unclaimedWinningsForCave.length
  );

  return {
    maxWinningsToClaim,
    totalRolloverAvailable,
    // note this array of WithdrawalCallData must later be sliced to the number of entries being purchased
    playerDetails: sortedPlayerDetails,
  };
};

/**
 * Summing up the total amounts into looks and eth values.
 * For each round, find the amount of currency that was won.
 * Winning amount is calculated by taking the (enterAmount * players), subtracting the protocol fee percentage
 */
export const getTokenClaimAmounts = ({
  unclaimedWinnings,
  unclaimedRefunds,
}: {
  unclaimedWinnings: PtbUnclaimedEntry[];
  unclaimedRefunds: PtbUnclaimedEntry[];
}) => {
  // For each unclaimed refund, simply sum the enterAmount
  const { refundEthAmount, refundLooksAmount } = unclaimedRefunds.reduce(
    (acc, unclaimedRefund) => {
      const {
        cave: { currency, enterAmount },
      } = unclaimedRefund;

      const isCurrencyEth = isAddressEqual(currency, zeroAddress);
      const enterAmountBI = BigInt(enterAmount);
      if (isCurrencyEth) {
        acc.refundEthAmount += enterAmountBI;
      } else {
        acc.refundLooksAmount += enterAmountBI;
      }

      return acc;
    },
    {
      refundEthAmount: 0n,
      refundLooksAmount: 0n,
    }
  );

  // For each unclaimed winning, sum the enterAmount (principal) plus the (`prizePerPlayer` minus the protocol fee percentage)
  const { winningEthAmount, winningLooksAmount } = unclaimedWinnings.reduce(
    (acc, unclaimedWinning) => {
      const {
        cave: { currency, enterAmount, protocolFeeBp, playersPerRound },
      } = unclaimedWinning;

      const totalWinAmount = getPrizePerPlayerConsideringFees({
        enterAmount: BigInt(enterAmount),
        playersPerRound,
        protocolFeeBp,
      });

      const isCurrencyEth = isAddressEqual(currency, zeroAddress);
      if (isCurrencyEth) {
        acc.winningEthAmount += totalWinAmount;
      } else {
        acc.winningLooksAmount += totalWinAmount;
      }

      return acc;
    },
    {
      winningEthAmount: 0n,
      winningLooksAmount: 0n,
    }
  );

  return {
    ethAmount: winningEthAmount + refundEthAmount,
    looksAmount: winningLooksAmount + refundLooksAmount,
  };
};

interface UnclaimedPtbArgs {
  address?: Address;
  network: PtbSupportedNetwork;
}

const getUnclaimedPtbFundsKey = ({ address, network }: UnclaimedPtbArgs) => ["unclaimedPtbWinnings", address, network];

export const useInvalidateUnclaimedPtbFunds = () => {
  const queryClient = useQueryClient();
  return useCallback(
    (args: UnclaimedPtbArgs) => {
      queryClient.invalidateQueries({ queryKey: getUnclaimedPtbFundsKey(args) });
    },
    [queryClient]
  );
};

/**
 * Abstraction for fetching & claiming ptb prizes and refunds
 */
export const useUnclaimedPtbFunds = (network: PtbSupportedNetwork) => {
  const { address } = useAccount();
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  const contractInfo = usePtbContractInfo();
  const chainIdFromNetwork = getChainIdFromSupportedNetwork(network);

  const { data: ptbUserUnclaimedFunds, isLoading } = useQuery({
    queryKey: getUnclaimedPtbFundsKey({ address, network }),
    queryFn: () => getUnclaimedWinnings(address!, network),
    gcTime: 10 * 1_000,
    refetchInterval: 10 * 1_000,
    enabled: !!address,
  });

  const { ptbUnclaimedWinnings: unclaimedWinnings = [], ptbUnclaimedRefunds: unclaimedRefunds = [] } =
    ptbUserUnclaimedFunds || {};

  const claimWinnings = useCallback(async () => {
    if (!walletClient) {
      throw Error("No wallet client found");
    }

    if (!publicClient) {
      throw new NoPublicClientError();
    }

    if (!unclaimedWinnings.length) {
      // @note This should be blocked upstream
      throw Error("No winnings to claim");
    }

    // @todo-wagmiv2 "& readonly never[]" HAS to be a bug
    const withdrawalCallData = transformToWithdrawalCallData(unclaimedWinnings) as WithdrawalCallData[] &
      readonly never[];
    const [account] = await walletClient.getAddresses();
    const { request } = await publicClient.simulateContract({
      ...contractInfo[chainIdFromNetwork].ptb,
      functionName: "claimPrizes",
      args: [withdrawalCallData],
      account,
    });
    return await walletClient.writeContract(request);
  }, [publicClient, unclaimedWinnings, walletClient, contractInfo, chainIdFromNetwork]);

  const claimRefund = useCallback(async () => {
    if (!walletClient) {
      throw Error("No wallet client found");
    }

    if (!publicClient) {
      throw new NoPublicClientError();
    }

    if (!unclaimedRefunds.length) {
      // @note This should be blocked upstream
      throw Error("No refunds to claim");
    }

    const withdrawalCallData = transformToWithdrawalCallData(unclaimedRefunds) as WithdrawalCallData[] &
      readonly never[];
    const [account] = await walletClient.getAddresses();
    const { request } = await publicClient.simulateContract({
      ...contractInfo[chainIdFromNetwork].ptb,
      functionName: "refund",
      args: [withdrawalCallData],
      account,
    });
    return await walletClient.writeContract(request);
  }, [publicClient, unclaimedRefunds, walletClient, contractInfo, chainIdFromNetwork]);

  // this is the sum of all claimable funds - winnings & refunds
  const { ethAmount, looksAmount } = getTokenClaimAmounts({ unclaimedWinnings, unclaimedRefunds });

  return {
    isLoading,
    claimWinnings,
    claimRefund,
    unclaimedWinnings,
    unclaimedRefunds,
    ethAmount,
    looksAmount,
    hasUnclaimedFunds: ethAmount > 0n || looksAmount > 0n,
  };
};
