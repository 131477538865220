import { Icon, IconProps } from "../Icon";

const Telegram = (props: IconProps) => (
  <Icon {...props}>
    <path
      d="M3.6553 15.2483L19.7383 8.62181C21.3259 7.93155 26.7099 5.72273 26.7099 5.72273C26.7099 5.72273 29.1948 4.75637 28.9877 7.10324C28.9187 8.0696 28.3665 11.4519 27.8143 15.1102L26.0887 25.9473C26.0887 25.9473 25.9506 27.5349 24.7772 27.811C23.6037 28.0871 21.671 26.8446 21.3259 26.5685C21.0498 26.3614 16.149 23.2553 14.3543 21.7367C13.8711 21.3225 13.3189 20.4942 14.4233 19.5279C16.9082 17.25 19.8763 14.42 21.671 12.6253C22.4993 11.797 23.3276 9.86427 19.8763 12.2111L10.1437 18.7686C10.1437 18.7686 9.03931 19.4588 6.96854 18.8376C4.89777 18.2164 2.48186 17.3881 2.48186 17.3881C2.48186 17.3881 0.825247 16.3527 3.6553 15.2483Z"
      fill="currentColor"
    />
  </Icon>
);

export default Telegram;
