import { useState } from "react";
import { useRouter } from "next/router";
import { Flex, FlexProps, IconButton, Stack } from "@chakra-ui/react";
import { ArrowLeftIcon, CloseIcon, DiscordIcon, Link, NextLink, Text, TwitterIcon } from "@looksrare/uikit";
import { YoloLogoColorOnDark } from "@/components/Assets";
import { navItems } from "@/components/Layout/SideNav/config";
import { NavButton, NavButtonType } from "./NavButton";

interface SideNavMobileProps extends FlexProps {
  onClose?: () => void;
}

export interface SubMenuState {
  title: string;
  type: NavButtonType;
  drawerContent: JSX.Element;
}

/**
 * Our mobile SideNav is a chakra drawer with one-level-deep submenus.
 * If the submenu is rendered, replace the header logo with the submenu title.
 */
export const SideNavMobile = ({ onClose, ...props }: SideNavMobileProps) => {
  const { pathname } = useRouter();
  const [activeSubMenu, setActiveSubMenu] = useState<SubMenuState | null>(null);

  return (
    <Flex
      flexDirection="column"
      width="360px"
      alignItems="flex-start"
      borderTopRightRadius="dialog"
      borderRight="1px solid"
      borderColor="border-01"
      bg="ui-01"
      maxH="100vh"
      overflowY="auto"
      {...props}
    >
      {/* Header */}
      <Flex alignItems="center" width="100%" p={4} gap={4}>
        {/* Back button */}
        {!!activeSubMenu && (
          <IconButton
            aria-label="close-sub-nav"
            onClick={() => setActiveSubMenu(null)}
            variant="ghost"
            size="xs"
            colorScheme="secondary"
          >
            <ArrowLeftIcon />
          </IconButton>
        )}

        {/* Title */}
        <Flex flexGrow={1}>
          {!activeSubMenu ? (
            <NextLink href="/">
              <YoloLogoColorOnDark width="78px" height="39px" />
            </NextLink>
          ) : (
            <Text textStyle="heading-04" bold>
              {activeSubMenu?.title}
            </Text>
          )}
        </Flex>

        {/* Close button */}
        {onClose && (
          <IconButton onClick={onClose} aria-label="close-mobile-nav" variant="ghost" colorScheme="secondary">
            <CloseIcon />
          </IconButton>
        )}
      </Flex>

      {/* Drawer Body */}
      {!!activeSubMenu ? (
        activeSubMenu.drawerContent
      ) : (
        <>
          <Stack width="100%" flexGrow={1} p={4}>
            {navItems.map(({ isComingSoon, type, pathnames, pathMatch }) => {
              return (
                <NavButton
                  onClose={onClose}
                  key={type}
                  type={type}
                  isActive={
                    pathnames &&
                    pathnames.filter((p) => (pathMatch === "exact" ? pathname === p : pathname.includes(p))).length > 0
                  }
                  isMobile
                  isComingSoon={isComingSoon}
                  onOpenSubMenu={setActiveSubMenu}
                />
              );
            })}
          </Stack>
          <Stack p={4} pt={0} width="100%" spacing={3} alignSelf="flex-end">
            <NavButton isMobile type="help" isActive={pathname === "/faq"} />
            <Flex gap={3} justifyContent="center" alignItems="center">
              <IconButton
                size="sm"
                colorScheme="secondary"
                variant="outline"
                aria-label="x-link"
                as={Link}
                href="https://twitter.com/YOLO_Blast"
                isExternal
              >
                <TwitterIcon />
              </IconButton>
              <IconButton
                size="sm"
                colorScheme="secondary"
                variant="outline"
                aria-label="discord-link"
                as={Link}
                href="https://discord.gg/7SHJdtZz37"
                isExternal
              >
                <DiscordIcon />
              </IconButton>
            </Flex>
          </Stack>
        </>
      )}
    </Flex>
  );
};
