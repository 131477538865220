import { Icon, IconProps } from "@looksrare/uikit";
import uniqueId from "lodash/uniqueId";

const Survived = (props: IconProps) => {
  const id = uniqueId();
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32px 32px" fill="none" {...props}>
      <g id="Person / User / face--happy">
        <path
          id="Fill"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 16C2 8.26801 8.26801 2 16 2C19.713 2 23.274 3.475 25.8995 6.1005C28.525 8.72601 30 12.287 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16ZM4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16ZM16.0003 24C18.808 23.9952 21.4078 22.5189 22.8503 20.11L21.1403 19.11C20.0548 20.9125 18.1044 22.0148 16.0003 22.0148C13.8961 22.0148 11.9457 20.9125 10.8603 19.11L9.15027 20.11C10.5928 22.5189 13.1925 23.9952 16.0003 24ZM11.5 11C10.1193 11 9 12.1193 9 13.5C9 14.8807 10.1193 16 11.5 16C12.8807 16 14 14.8807 14 13.5C14 12.837 13.7366 12.2011 13.2678 11.7322C12.7989 11.2634 12.163 11 11.5 11ZM20.5 11C19.1193 11 18 12.1193 18 13.5C18 14.8807 19.1193 16 20.5 16C21.8807 16 23 14.8807 23 13.5C23 12.1193 21.8807 11 20.5 11Z"
          fill={`url(#${id})`}
        />
      </g>
      <defs>
        <linearGradient id={id} x1="16" y1="2" x2="16" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#0CE466" />
          <stop offset="1" stopColor="#0099AE" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export default Survived;
