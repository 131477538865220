import { ReactNode } from "react";
import noop from "lodash/noop";
import { HStack, StackProps } from "@chakra-ui/react";
import { ArrowUpRightIcon, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { EntryPosition } from "../../../types";

interface RoundEntryButtonProps extends StackProps {
  entryPosition: EntryPosition;
  onClick: () => void;
  rightElement?: ReactNode;
  isDisabled?: boolean;
}

export const RoundEntryButton = ({
  entryPosition,
  onClick,
  rightElement,
  isDisabled,
  ...props
}: RoundEntryButtonProps) => {
  const { t } = useTranslation();
  const isMoon = entryPosition === "moon";

  return (
    <HStack
      justifyContent="space-between"
      onClick={isDisabled ? noop : onClick}
      px={4}
      py="3.5"
      width="fit-content"
      borderRadius="button"
      layerStyle={isMoon ? "moondoomClickableMoon" : "moondoomClickableDoom"}
      opacity={isDisabled ? 0.5 : 1}
      pointerEvents={isDisabled ? "none" : "auto"}
      transition="background-color 200ms, opacity 200ms"
      {...props}
    >
      <HStack spacing={1}>
        <Text textStyle="detail" color="inherit" transition="color 200ms" bold>
          {t("mod::Enter {{position}}", { position: t(`mod::${entryPosition.toUpperCase()}`) })}
        </Text>

        <ArrowUpRightIcon
          boxSize={5}
          color="inherit"
          transform={isMoon ? "rotate(0deg)" : "rotate(90deg)"}
          transition="transform 200ms, color 200ms"
        />
      </HStack>

      {rightElement}
    </HStack>
  );
};
