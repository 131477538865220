import { useEffect } from "react";
import debounce from "lodash/debounce";

export const useOnWindowFocus = (callback: () => void) => {
  useEffect(() => {
    const onFocus = debounce(callback, 150);

    window.addEventListener("focus", onFocus);

    return () => window.removeEventListener("focus", onFocus);
  }, [callback]);
};
