export const layerStyles = {
  moondoomClickableMoon: {
    cursor: "pointer",
    color: "moondoom-text-on-moon",
    bgColor: "moondoom-moon",
    _hover: {
      bgColor: "moondoom-moon-hover",
    },
    _active: {
      bgColor: "moondoom-moon-onclick",
    },
  },
  moondoomClickableDoom: {
    cursor: "pointer",
    color: "moondoom-text-on-doom",
    bgColor: "moondoom-doom",
    _hover: {
      bgColor: "moondoom-doom-hover",
    },
    _active: {
      bgColor: "moondoom-doom-onclick",
    },
  },
};
