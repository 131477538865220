import { type RQueryOptions, type BigIntish } from "@looksrare/utils";
import { useQuery } from "@tanstack/react-query";
import { getActiveRoundOnChainId, getRoundLogs, type RoundLogsReturn } from "../graphql";
import type { PtbContractName } from "../types";

export const useNextAvailableRoundLogs = (
  caveOnChainId: BigIntish,
  contract: PtbContractName,
  options?: RQueryOptions<RoundLogsReturn | null>
) => {
  return useQuery({
    queryKey: ["ptb", "next-available-round-logs", contract, caveOnChainId],
    queryFn: async () => {
      const nextAvailableRound = await getActiveRoundOnChainId({ contract, caveOnChainId });
      const nextAvailableRoundNum = Number(nextAvailableRound);

      if (!nextAvailableRound || Number.isNaN(nextAvailableRoundNum)) {
        return null;
      }

      const roundInfo = await getRoundLogs(caveOnChainId, nextAvailableRound, contract);
      return roundInfo;
    },
    refetchInterval: 15 * 1_000,
    ...options,
  });
};
