import { Box, BoxProps, HStack, Spinner, VStack } from "@chakra-ui/react";
import { HandleModalStatus } from "@looksrare/utils";
import React from "react";
import { Text } from "../Text";
import { CheckmarkFilledIcon, ClockIcon } from "../Icons";

export type BulkTransactionStepStatus = "done" | "pending" | "error" | "wait" | "skipped";
export type StepStatus = "past" | "current" | "future";

export const statusIcons: Record<StepStatus, React.FC<React.PropsWithChildren<unknown>>> = {
  past: () => <CheckmarkFilledIcon color="text-disabled" />,
  current: () => <Spinner color="interactive-03" />,
  future: () => <ClockIcon color="text-03" />,
};

/**
 * Helper to translate HandleModalStep statuses to BulkTransactionStepStatus
 * HandleModalStatus.REJECTED -> BulkTransactionStepStatus.error
 * HandleModalStatus.IDLE -> BulkTransactionStepStatus.wait
 * HandleModalStatus.ACCEPTED -> BulkTransactionStepStatus.done
 * default -> BulkTransactionStepStatus.pending
 */
export const getBulkTransactionStepStatus = (status: HandleModalStatus): BulkTransactionStepStatus => {
  switch (status) {
    case HandleModalStatus.REJECTED:
      return "error";
    case HandleModalStatus.IDLE:
      return "wait";
    case HandleModalStatus.ACCEPTED:
      return "done";
    default:
      return "pending";
  }
};

export interface BulkTransactionStepProps extends BoxProps {
  title: string;
  status: StepStatus;
  collapse?: boolean;
  description?: string;
}

export const BulkTransactionStep = ({
  title,
  description,
  status,
  collapse = false,
  children,
  ...props
}: BulkTransactionStepProps) => {
  const StatusIcon = statusIcons[status];

  return (
    <VStack spacing={4} alignItems="stretch" {...props}>
      <HStack spacing={4}>
        <StatusIcon />
        <Text bold color={status === "current" ? "text-02" : "text-disabled"}>
          {title}
        </Text>
      </HStack>
      {status === "current" && !!description && (
        <Text textStyle="helper" bold color="text-03">
          {description}
        </Text>
      )}
      {!collapse && (
        <Box p={4} bg="ui-bg" borderRadius="container">
          {children}
        </Box>
      )}
    </VStack>
  );
};

/**
 * Helper to get the Status of a step in list of step
 * @param activeIndex index of the active item
 * @param itemIndex index of the item being displayed
 * @returns Status
 */
export const getStepStatus = (itemIndex: number, activeIndex: number): StepStatus => {
  if (itemIndex < activeIndex) {
    return "past";
  }
  if (itemIndex > activeIndex) {
    return "future";
  }
  return "current";
};
