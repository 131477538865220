import { Container, useGameSupportedNetworks } from "@looksrare/uikit";
import { Box, Center, Flex, Stack } from "@chakra-ui/react";
import Image from "next/image";
import { FAQ } from "../../components/FAQ";
import { NetworkCaveListGrid } from "./NetworkCaveListGrid";

export const DesktopLobby = () => {
  const supportedNetworks = useGameSupportedNetworks();

  return (
    <Container position="relative" mt={8} pb={8} pt={0} maxWidth="1440px">
      <Stack spacing={{ base: 9, md: 12 }}>
        {/* Logo */}
        <Stack spacing={4}>
          <Flex width="100%" justifyContent="center">
            <Box width={{ base: "223px", md: "282px" }} height={{ base: "251px", md: "317px" }} position="relative">
              <Image
                src="/images/poke-the-bear/logo.png"
                sizes="(max-width: 251px) 223px, 282px"
                alt="Poke The Bear"
                layout="fill"
              />
            </Box>
          </Flex>
        </Stack>
        {supportedNetworks.map((network) => (
          <NetworkCaveListGrid key={network} network={network} />
        ))}
        {/* FAQ */}
        <Center>
          <FAQ maxWidth="768px" />
        </Center>
      </Stack>
    </Container>
  );
};
