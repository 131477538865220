import { Icon, IconProps } from "../Icon";

const Trophy = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 6V14C10 14.9926 10.241 15.9289 10.6677 16.7535L10.7071 16.7929L10.6948 16.8052C11.7017 18.7055 13.6997 20 16 20C18.3003 20 20.2983 18.7055 21.3052 16.8052L21.2929 16.7929L21.3323 16.7535C21.759 15.9289 22 14.9926 22 14V6H10ZM22.9507 17.9635C21.7296 20.1004 19.5513 21.62 17 21.9381V26H21V28H17H15H11V26H15V21.9381C12.4487 21.62 10.2704 20.1004 9.04925 17.9635L6.08239 14.9966C4.10877 13.023 3 10.3462 3 7.55505C3 6.97236 3.47236 6.5 4.05505 6.5H8V5.7866C8 4.79989 8.79989 4 9.7866 4H22.2134C23.2001 4 24 4.79989 24 5.7866V6.5H27.9449C28.5276 6.5 29 6.97236 29 7.55505C29 10.3462 27.8912 13.023 25.9176 14.9966L22.9507 17.9635ZM24 8.5H26.9475C26.7342 10.413 25.8776 12.2082 24.5034 13.5824L23.9995 14.0862C23.9998 14.0575 24 14.0288 24 14V8.5ZM8 14C8 14.0288 8.00015 14.0575 8.00046 14.0862L7.49661 13.5824C6.12238 12.2082 5.26585 10.413 5.05251 8.5H8V14Z"
      fill="currentColor"
    />
  </Icon>
);

export default Trophy;
