import { Icon, IconProps } from "../Icon";

const Fork = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9998 18.0001C24.181 18.0055 22.5952 19.2386 22.1418 21.0001H16.9998V11.0001H22.1418C22.6454 12.9504 24.5206 14.222 26.5188 13.9682C28.5171 13.7144 30.0148 12.0143 30.0148 10.0001C30.0148 7.98578 28.5171 6.28574 26.5188 6.03193C24.5206 5.77813 22.6454 7.04975 22.1418 9.00005H16.9998C15.8957 9.00115 15.0009 9.89594 14.9998 11.0001V15.0001H9.85785C9.35428 13.0497 7.47906 11.7781 5.48085 12.0319C3.48263 12.2857 1.98486 13.9858 1.98486 16.0001C1.98486 18.0143 3.48263 19.7144 5.48085 19.9682C7.47906 20.222 9.35428 18.9504 9.85785 17.0001H14.9998V21.0001C15.0009 22.1042 15.8957 22.9989 16.9998 23.0001H22.1418C22.6651 25.0076 24.6385 26.2801 26.6831 25.9282C28.7277 25.5762 30.1622 23.7172 29.984 21.6502C29.8059 19.5832 28.0745 17.997 25.9998 18.0001ZM26 8.00006C27.1046 8.00006 28 8.89549 28 10.0001C28 11.1046 27.1046 12.0001 26 12.0001C24.8954 12.0001 24 11.1046 24 10.0001C24.0011 8.89595 24.8959 8.00116 26 8.00006ZM6.00001 18.0001C4.89544 18.0001 4.00001 17.1046 4.00001 16.0001C4.00001 14.8955 4.89544 14.0001 6.00001 14.0001C7.10458 14.0001 8.00001 14.8955 8.00001 16.0001C7.9989 17.1042 7.10412 17.999 6.00001 18.0001ZM26 24.0001C24.8954 24.0001 24 23.1046 24 22.0001C24 20.8955 24.8954 20.0001 26 20.0001C27.1046 20.0001 28 20.8955 28 22.0001C27.9989 23.1042 27.1041 23.999 26 24.0001Z"
      fill="currentColor"
    />
  </Icon>
);

export default Fork;
