import { FC, PropsWithChildren } from "react";
import { Flex, FlexProps } from "@chakra-ui/react";
import { TailIcon } from "./Icons";

export const BannerWithTail: FC<PropsWithChildren<FlexProps>> = ({ children, color = "interactive-03", ...props }) => {
  return (
    <Flex {...props}>
      <TailIcon width="9px" height="20px" color={color} />
      <Flex alignItems="center" justifyContent="center" flex={1} height="100%" bgColor={color}>
        {children}
      </Flex>
    </Flex>
  );
};
