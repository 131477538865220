export const LiquidityPoolRouterAbi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "_weth",
        type: "address",
      },
      {
        internalType: "address",
        name: "_usdb",
        type: "address",
      },
      {
        internalType: "address",
        name: "_transferManager",
        type: "address",
      },
      {
        internalType: "address",
        name: "_blast",
        type: "address",
      },
      {
        internalType: "address",
        name: "_blastPoints",
        type: "address",
      },
      {
        internalType: "address",
        name: "_blastPointsOperator",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "target",
        type: "address",
      },
    ],
    name: "AddressEmptyCode",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "AddressInsufficientBalance",
    type: "error",
  },
  {
    inputs: [],
    name: "ERC20TransferFail",
    type: "error",
  },
  {
    inputs: [],
    name: "FailedInnerCall",
    type: "error",
  },
  {
    inputs: [],
    name: "IsPaused",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__DepositAmountTooHigh",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__DepositAmountTooLow",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__FinalizationForAllDelayTooHigh",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__FinalizationForAllIsNotOpen",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__FinalizationIncentiveNotPaid",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__FinalizationIncentiveTooHigh",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__InvalidTimelockDelay",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__MaxDepositAmountTooHigh",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__MinDepositAmountTooHigh",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__NoLiquidityPoolForToken",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__NoOngoingDeposit",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__NoOngoingRedemption",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__OngoingDeposit",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__OngoingRedemption",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__TimelockIsNotOver",
    type: "error",
  },
  {
    inputs: [],
    name: "LiquidityPoolRouter__TokenAlreadyHasLiquidityPool",
    type: "error",
  },
  {
    inputs: [],
    name: "NoOngoingTransferInProgress",
    type: "error",
  },
  {
    inputs: [],
    name: "NotAContract",
    type: "error",
  },
  {
    inputs: [],
    name: "NotOwner",
    type: "error",
  },
  {
    inputs: [],
    name: "NotPaused",
    type: "error",
  },
  {
    inputs: [],
    name: "ReentrancyFail",
    type: "error",
  },
  {
    inputs: [],
    name: "RenouncementNotInProgress",
    type: "error",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "SafeERC20FailedOperation",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferAlreadyInProgress",
    type: "error",
  },
  {
    inputs: [],
    name: "TransferNotInProgress",
    type: "error",
  },
  {
    inputs: [],
    name: "WrongPotentialOwner",
    type: "error",
  },
  {
    anonymous: false,
    inputs: [],
    name: "CancelOwnershipTransfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [],
    name: "InitiateOwnershipRenouncement",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "potentialOwner",
        type: "address",
      },
    ],
    name: "InitiateOwnershipTransfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "caller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "liquidityPool",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "sharesMinted",
        type: "uint256",
      },
    ],
    name: "LiquidityPoolRouter__DepositFinalized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "liquidityPool",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "expectedShares",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "finalizationIncentive",
        type: "uint256",
      },
    ],
    name: "LiquidityPoolRouter__DepositInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "liquidityPool",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "minDepositAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "maxDepositAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "maxBalance",
        type: "uint256",
      },
    ],
    name: "LiquidityPoolRouter__DepositLimitUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "timelockDelay",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "finalizationForAllDelay",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "finalizationIncentive",
        type: "uint256",
      },
    ],
    name: "LiquidityPoolRouter__FinalizationParamsUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "liquidityPool",
        type: "address",
      },
    ],
    name: "LiquidityPoolRouter__LiquidityPoolAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "caller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "liquidityPool",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "assetsRedeemed",
        type: "uint256",
      },
    ],
    name: "LiquidityPoolRouter__RedemptionFinalized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "liquidityPool",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "expectedAssets",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "finalizationIncentive",
        type: "uint256",
      },
    ],
    name: "LiquidityPoolRouter__RedemptionInitialized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "NewOwner",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Paused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Unpaused",
    type: "event",
  },
  {
    inputs: [],
    name: "DEPOSIT_FEE_BASIS_POINTS",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "TRANSFER_MANAGER",
    outputs: [
      {
        internalType: "contract ITransferManager",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "USDB",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "WETH",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "liquidityPool",
        type: "address",
      },
    ],
    name: "addLiquidityPool",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "cancelOwnershipTransfer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "receiver",
        type: "address",
      },
    ],
    name: "claimYield",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "confirmOwnershipRenouncement",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "confirmOwnershipTransfer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "deposit",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "depositETH",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "liquidityPool",
        type: "address",
      },
    ],
    name: "depositLimit",
    outputs: [
      {
        internalType: "uint256",
        name: "minDepositAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxDepositAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxBalance",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "deposits",
    outputs: [
      {
        internalType: "address",
        name: "liquidityPool",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "expectedShares",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "initializedAt",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "finalizationIncentive",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "finalizationParams",
    outputs: [
      {
        internalType: "uint80",
        name: "timelockDelay",
        type: "uint80",
      },
      {
        internalType: "uint80",
        name: "finalizationForAllDelay",
        type: "uint80",
      },
      {
        internalType: "uint80",
        name: "finalizationIncentive",
        type: "uint80",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "depositor",
        type: "address",
      },
    ],
    name: "finalizeDeposit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "redeemer",
        type: "address",
      },
    ],
    name: "finalizeRedemption",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "initiateOwnershipRenouncement",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newPotentialOwner",
        type: "address",
      },
    ],
    name: "initiateOwnershipTransfer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "liquidityPools",
    outputs: [
      {
        internalType: "address",
        name: "liquidityPool",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ownershipStatus",
    outputs: [
      {
        internalType: "enum IOwnableTwoSteps.Status",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "liquidityPool",
        type: "address",
      },
    ],
    name: "pendingDeposits",
    outputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "potentialOwner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "redeem",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "redemptions",
    outputs: [
      {
        internalType: "address",
        name: "liquidityPool",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "shares",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "expectedAssets",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "initializedAt",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "finalizationIncentive",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "liquidityPool",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "minDepositAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxDepositAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "maxBalance",
        type: "uint256",
      },
    ],
    name: "setDepositLimit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint80",
        name: "_timelockDelay",
        type: "uint80",
      },
      {
        internalType: "uint80",
        name: "_finalizationForAllDelay",
        type: "uint80",
      },
      {
        internalType: "uint80",
        name: "_finalizationIncentive",
        type: "uint80",
      },
    ],
    name: "setFinalizationParams",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "togglePaused",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
] as const;
