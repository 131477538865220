import { useAccount } from "wagmi";
import { Flex, SimpleGrid, Stack, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import capitalize from "lodash/capitalize";
import { SupportedNetwork, isAddressEqual } from "@looksrare/utils";
import { Text, useGameAddresses } from "@looksrare/uikit";
import { useCaves, useUnclaimedPtbFunds } from "../../hooks";
import { getPtbContractNameFromNetwork } from "../../utils";
import { ClaimBox } from "../ClaimBox";
import { LobbyCaveCard } from "../LobbyCaveCard/LobbyCaveCard";
import { PtbRoundStatus } from "../../types";
import { ClaimFundsModal } from "../Modals/ClaimFunds";

interface NetworkCaveListGridProps {
  network: SupportedNetwork;
}

export const NetworkCaveListGrid = ({ network }: NetworkCaveListGridProps) => {
  const disclosure = useDisclosure();
  const { address } = useAccount();
  const cavesQuery = useCaves(getPtbContractNameFromNetwork(network));
  const { isLoading, ethAmount, looksAmount } = useUnclaimedPtbFunds(network);
  const ptbAddresses = useGameAddresses(network);
  const { t } = useTranslation();

  return (
    <Stack spacing={6}>
      <Flex width="100%" justifyContent="space-between" alignItems="flex-end" gap={4}>
        <Text textStyle="heading-03" bold flex={1} textAlign={{ base: "center", md: "left" }}>
          {t("{{network}} Caves", { network: capitalize(network) })}
        </Text>
        <ClaimBox
          display={{ base: "none", md: "flex" }}
          flex={1}
          maxWidth="400px"
          network={network}
          onClickClaim={() => disclosure.onOpen()}
          ethAmount={ethAmount}
          looksAmount={looksAmount}
          address={address}
          isLoading={isLoading}
        />
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 2, "2xl": 4 }} spacing={4}>
        {cavesQuery.data?.map((cave) => {
          return (
            <LobbyCaveCard
              key={cave.id}
              network={network}
              caveOnChainId={cave.onChainId}
              roundOnChainId={cave.round?.onChainId}
              caveName={cave.name}
              entryFeeWei={BigInt(cave.enterAmount)}
              currentPlayers={cave.round?.players.length || 0}
              maxPlayers={cave.playersPerRound}
              variant={isAddressEqual(cave.currency, ptbAddresses.ETH) ? "eth" : "looks"}
              isCanceled={cave.round?.status === PtbRoundStatus.CANCELLED}
            />
          );
        })}
      </SimpleGrid>
      <ClaimFundsModal network={network} isOpen={disclosure.isOpen} onClose={disclosure.onClose} />
    </Stack>
  );
};
