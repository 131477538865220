import { ModalFooter, ModalFooterProps, Stack, StackProps } from "@chakra-ui/react";

export interface ModalFooterStackProps extends ModalFooterProps {
  stackProps?: StackProps;
}

export const ModalFooterStack: React.FC<React.PropsWithChildren<ModalFooterStackProps>> = ({
  children,
  stackProps,
  ...props
}) => (
  <ModalFooter {...props}>
    <Stack direction="row" width="100%" spacing={3} {...stackProps}>
      {children}
    </Stack>
  </ModalFooter>
);
