import { useCallback } from "react";
import { type Address } from "viem";
import { type BigIntish, StateSetter } from "@looksrare/utils";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { useGetCaveQueryParams } from "./useGetCaveQueryParams";

export type GameStatus = "idle" | "shuffling" | "mauled" | "survived" | "countdown" | "poking" | "finished";

interface State {
  gameStatus: Record<string, GameStatus>;
  isEnterCaveModalOpen: boolean;
  nextPlayerUp: Address | null;
}

interface GameState extends State {
  setGameStatus: StateSetter<{ caveOnChainId: BigIntish; roundOnChainId: BigIntish; newStatus: GameStatus }>;
  setIsEnterCaveModalOpen: StateSetter<boolean>;
  setNextPlayerUp: StateSetter<Address | null>;
}

export const useGameState = create(
  immer<GameState>((set) => ({
    gameStatus: {},
    isEnterCaveModalOpen: false,
    nextPlayerUp: null,
    setGameStatus: ({ caveOnChainId, roundOnChainId, newStatus }) => {
      set((state) => {
        state.gameStatus[`${caveOnChainId}${roundOnChainId}`] = newStatus;
      });
    },
    setIsEnterCaveModalOpen: (newIsEnterCaveModalOpen) => {
      set((state) => {
        state.isEnterCaveModalOpen = newIsEnterCaveModalOpen;
      });
    },
    setNextPlayerUp: (nextPlayerAddress) => {
      set((state) => {
        state.nextPlayerUp = nextPlayerAddress;
      });
    },
  }))
);

export const useCurrentGameStatus = () => {
  const { caveOnChainId, roundOnChainId } = useGetCaveQueryParams();
  const gameStatus = useGameState((state) => state.gameStatus[`${caveOnChainId}${roundOnChainId}`] || "idle");
  return gameStatus;
};

export const useSetCurrentGameStatus = () => {
  const { caveOnChainId, roundOnChainId } = useGetCaveQueryParams();
  const setGameStatus = useGameState((state) => state.setGameStatus);
  return useCallback(
    (newStatus: GameStatus) => setGameStatus({ caveOnChainId, roundOnChainId, newStatus }),
    [caveOnChainId, roundOnChainId, setGameStatus]
  );
};
