import { type RQueryOptions, type BigIntish } from "@looksrare/utils";
import { useQuery } from "@tanstack/react-query";
import type { PtbContractName } from "../types";
import { getActiveRoundOnChainId, type CaveInfoReturn, getRoundInfo } from "../graphql";

export const useNextAvailableRound = (
  caveOnChainId: BigIntish,
  contract: PtbContractName,
  options?: RQueryOptions<CaveInfoReturn | null>
) => {
  return useQuery({
    queryKey: ["ptb", "next-available-round", contract, caveOnChainId],
    queryFn: async () => {
      const nextAvailableRound = await getActiveRoundOnChainId({ caveOnChainId, contract });

      if (!nextAvailableRound) {
        return null;
      }

      const roundInfo = await getRoundInfo(caveOnChainId, contract, nextAvailableRound);
      return roundInfo;
    },
    refetchInterval: 15 * 1_000,
    ...options,
  });
};
