import { StyleFunctionProps } from "@chakra-ui/theme-tools";

export const ModalTheme = {
  baseStyle: (props: StyleFunctionProps) => {
    const { scrollBehavior } = props;
    return {
      dialog: {
        borderRadius: "dialog",
        borderColor: "border-01",
        borderWidth: "1px",
        bg: "ui-01",
        shadow: "none",
        maxH: scrollBehavior === "inside" ? "calc(100% - var(--yg-space-20))" : undefined,
      },
      header: {
        color: "text-01",
        minHeight: "64px",
        px: 4,
        overflow: "hidden",
        display: "flex",
        justifyContent: "space-between",
        borderTopRadius: "dialog",
      },
      body: {
        color: "text-02",
        py: 6,
      },
      footer: {
        pt: 6,
      },
      overlay: {
        backdropFilter: "blur(8px)",
        zIndex: "overlay",
      },
    };
  },
  variants: {
    standard: {
      overlay: {
        bg: "rgba(0, 0, 0, 0.5)",
      },
    },
    rainbow: {
      overlay: {
        // @todo-yg Polish this filter & gradient, it is similar but not to spec
        filter: "blur(2px)",
        bg: "linear-gradient(180deg, rgba(204, 253, 7, 0.2) 0%, rgba(178, 228, 0, 0.2) 100%)",
        transform: "matrix(1, 0, 0, -1, 0, 0)",
        width: "150%",
        height: "150%",
        left: "-50%",
        top: "-25%",
      },
    },
  },
  sizes: {
    sm: {
      dialogContainer: {
        m: 0,
        bottom: "0px",
        top: "auto",
        overflow: "hidden",
        alignItems: "flex-end",
      },
      dialog: {
        maxWidth: "auto",
        width: "100%",
        mt: 0,
        mb: 0,
        borderBottomRadius: "0",
      },
      body: {
        px: 4,
      },
      footer: {
        px: 4,
        pb: 6,
      },
    },
    md: {
      dialog: {
        mx: 4,
        maxWidth: "512px",
        width: "100%",
      },
      body: {
        px: 8,
      },
      footer: {
        px: 8,
        pb: 8,
      },
    },
    lg: {
      dialog: {
        mx: 4,
        maxWidth: "1200px",
        width: "90%",
      },
      body: {
        px: 8,
      },
      footer: {
        px: 8,
        pb: 8,
      },
    },
    xl: {
      dialogContainer: {
        display: "flex",
        alignItems: "stretch",
        justifyContent: "stretch",
      },
      dialog: {
        maxW: "100%",
        mt: 8,
        mb: 0,
      },
    },
  },
  defaultProps: {
    variant: "standard",
  },
};
