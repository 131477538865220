import { GemIcon } from "@looksrare/uikit/components/Icons";

type Multiplier = number;
type PercentageElapsed = number;

const fractionOfRound = 1 / 6;

export const multiplierThresholds: Record<Multiplier, PercentageElapsed> = {
  5: fractionOfRound * 2 * 100,
  4: fractionOfRound * 3 * 100,
  3: fractionOfRound * 4 * 100,
  2: fractionOfRound * 5 * 100,
};

export const pointsPresets = {
  gems: {
    pointsLabel: "Gems",
    PointsIcon: GemIcon,
    pointsAccentColor: "purple.400",
    pointsBgGradient: "linear-gradient(316.51deg, #EE5396 24.34%, #8A3FFC 75.66%)",
  },
  points: {
    pointsLabel: "Pts",
    PointsIcon: () => null,
    pointsAccentColor: "acid.200",
    pointsBgGradient: "transparent",
  },
};
