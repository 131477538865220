import { Icon, IconProps } from "../Icon";
const InformationCircleFilled = (props: IconProps) => (
  <Icon viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0.25C4.16751 0.25 0.25 4.16751 0.25 9C0.25 13.8325 4.16751 17.75 9 17.75C13.8325 17.75 17.75 13.8325 17.75 9C17.75 6.67936 16.8281 4.45376 15.1872 2.81282C13.5462 1.17187 11.3206 0.25 9 0.25ZM9 3.375C9.51777 3.375 9.9375 3.79473 9.9375 4.3125C9.9375 4.83027 9.51777 5.25 9 5.25C8.48223 5.25 8.0625 4.83027 8.0625 4.3125C8.0625 3.79473 8.48223 3.375 9 3.375ZM8.3 14.075V7.05H9.70625V14.075H8.3Z"
      fill="currentColor"
    />
  </Icon>
);

export default InformationCircleFilled;
