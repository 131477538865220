import { Icon, IconProps } from "../Icon";

const Adjust = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 7.00001H24.9C24.4268 4.66968 22.3779 2.995 20 2.995C17.6221 2.995 15.5732 4.66968 15.1 7.00001H2V9.00001H15.1C15.5732 11.3303 17.6221 13.005 20 13.005C22.3779 13.005 24.4268 11.3303 24.9 9.00001H30V7.00001ZM20 11C18.3431 11 17 9.65687 17 8.00002C17 6.34316 18.3431 5.00002 20 5.00002C21.6569 5.00002 23 6.34316 23 8.00002C23 9.65687 21.6569 11 20 11ZM2 25H7.1C7.57319 27.3303 9.62212 29.005 12 29.005C14.3779 29.005 16.4268 27.3303 16.9 25H30V23H16.9C16.4268 20.6697 14.3779 18.995 12 18.995C9.62212 18.995 7.57319 20.6697 7.1 23H2V25ZM9 24C9 22.3432 10.3431 21 12 21C13.6569 21 15 22.3432 15 24C15 25.6569 13.6569 27 12 27C10.3431 27 9 25.6569 9 24Z"
      fill="currentColor"
    />
  </Icon>
);

export default Adjust;
