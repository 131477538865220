import { HStack, IconButton, Stack } from "@chakra-ui/react";
import { EthHalfIcon, HelpIcon, Popover, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { gradients } from "@looksrare/chakra-theme";
import { formatToSignificant } from "@looksrare/utils";
import { MoDRoundFragment } from "@looksrare/yolo-games-gql-typegen";
import { RoundStats } from "../../RoundStats/RoundStats";
import { RoundExplanation } from "./RoundExplanation";

interface RoundEntryHeaderProps {
  round: MoDRoundFragment;
  hideStats?: boolean;
}

export const RoundEntryHeader = ({ round, hideStats }: RoundEntryHeaderProps) => {
  const { t } = useTranslation();

  const totalAmountDisplay = round.totalAmount !== undefined ? formatToSignificant(round.totalAmount, 2) : "-";

  return (
    <Stack spacing={4} p={4} bgGradient={gradients["ui-gradient-v-01"]}>
      <HStack spacing={2}>
        <Text textStyle="display-body" color="text-01" bold>
          {t("Enter Next Round")}
        </Text>

        <Popover label={<RoundExplanation />} renderInPortal>
          <IconButton
            icon={<HelpIcon boxSize={5} color="text-03" />}
            variant="ghost"
            size="xs"
            aria-label={`${t("mod::Round explanation")}?`}
          />
        </Popover>
      </HStack>

      {!hideStats && (
        <Stack spacing={2}>
          <RoundStats
            moonPayoutRatio={round.moonPayoutRatio}
            doomPayoutRatio={round.doomPayoutRatio}
            moonAmount={round.moonAmount}
            doomAmount={round.doomAmount}
          />

          <HStack justifyContent="space-between">
            <Text textStyle="detail" color="text-03" bold>
              {t("mod::Total Prize Pool")}:
            </Text>

            <HStack spacing={2}>
              <Text textStyle="display-body" color="text-01" bold>
                {totalAmountDisplay}
              </Text>

              <EthHalfIcon height={4} width={2} />
            </HStack>
          </HStack>
        </Stack>
      )}
    </Stack>
  );
};
