import type { FC, PropsWithChildren } from "react";
import { Badge, Box, HStack, type StackProps } from "@chakra-ui/react";
import { ArrowAnchorIcon, NextLink } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";

interface NavSubMenuItemSubProps extends StackProps {
  href: string;
  isNew?: boolean;
}

export const NavSubMenuItemSub: FC<PropsWithChildren<NavSubMenuItemSubProps>> = ({
  children,
  href,
  isNew = false,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <HStack as={NextLink} borderRadius="button" bg="ui-02" href={href} spacing={4} px={4} height={10} {...props}>
      <ArrowAnchorIcon color="text-03" />
      <Box flex={1}>{children}</Box>
      {isNew && (
        <Badge bg="text-error" color="white" textTransform="uppercase">
          {t("New")}
        </Badge>
      )}
    </HStack>
  );
};
