import { Icon, IconProps } from "@chakra-ui/react";

export const RankOne = (props: IconProps) => (
  <Icon width="96" height="96" viewBox="0 0 96 96" fill="none" {...props}>
    <path
      d="M45.1439 7.54589C46.9258 6.58141 49.0742 6.58141 50.8561 7.54589L86.4253 26.7983C88.3624 27.8468 89.5692 29.8723 89.5692 32.0749V69.9251C89.5692 72.1277 88.3624 74.1532 86.4253 75.2017L50.8561 94.4541C49.0742 95.4186 46.9258 95.4186 45.1439 94.4541L9.57472 75.2017C7.63765 74.1532 6.43078 72.1277 6.43078 69.9251V32.0749C6.43078 29.8723 7.63765 27.8468 9.57472 26.7983L45.1439 7.54589Z"
      fill="url(#paint0_radial_4248_210459)"
    />
    <g style={{ mixBlendMode: "overlay" }}>
      <path
        d="M45.1439 7.54589C46.9258 6.58141 49.0742 6.58141 50.8561 7.54589L86.4253 26.7983C88.3624 27.8468 89.5692 29.8723 89.5692 32.0749V69.9251C89.5692 72.1277 88.3624 74.1532 86.4253 75.2017L50.8561 94.4541C49.0742 95.4186 46.9258 95.4186 45.1439 94.4541L9.57472 75.2017C7.63765 74.1532 6.43078 72.1277 6.43078 69.9251V32.0749C6.43078 29.8723 7.63765 27.8468 9.57472 26.7983L45.1439 7.54589Z"
        fill="#D6A800"
      />
    </g>
    <path
      d="M45.1439 1.54589C46.9258 0.581407 49.0742 0.581406 50.8561 1.54589L86.4253 20.7983C88.3624 21.8468 89.5692 23.8723 89.5692 26.0749V63.9251C89.5692 66.1277 88.3624 68.1532 86.4253 69.2017L50.8561 88.4541C49.0742 89.4186 46.9258 89.4186 45.1439 88.4541L9.57472 69.2017C7.63765 68.1532 6.43078 66.1277 6.43078 63.9251V26.0749C6.43078 23.8723 7.63765 21.8468 9.57472 20.7983L45.1439 1.54589Z"
      fill="url(#paint1_radial_4248_210459)"
    />
    <g style={{ mixBlendMode: "overlay" }}>
      <path
        d="M45.1439 1.54589C46.9258 0.581407 49.0742 0.581406 50.8561 1.54589L86.4253 20.7983C88.3624 21.8468 89.5692 23.8723 89.5692 26.0749V63.9251C89.5692 66.1277 88.3624 68.1532 86.4253 69.2017L50.8561 88.4541C49.0742 89.4186 46.9258 89.4186 45.1439 88.4541L9.57472 69.2017C7.63765 68.1532 6.43078 66.1277 6.43078 63.9251V26.0749C6.43078 23.8723 7.63765 21.8468 9.57472 20.7983L45.1439 1.54589Z"
        fill="#F8CC32"
      />
    </g>
    <g style={{ mixBlendMode: "soft-light" }} opacity="0.7">
      <path d="M18 72L54 6" stroke="url(#paint2_linear_4248_210459)" strokeWidth="12" />
    </g>
    <g style={{ mixBlendMode: "soft-light" }} opacity="0.5">
      <path d="M39 84L75 18" stroke="url(#paint3_linear_4248_210459)" strokeWidth="18" />
    </g>
    <path
      d="M45.8579 2.86505C47.1944 2.14169 48.8056 2.14169 50.142 2.86505L85.7113 22.1175C87.1641 22.9038 88.0692 24.423 88.0692 26.0749V63.9251C88.0692 65.577 87.1641 67.0962 85.7113 67.8825L50.1421 87.1349C48.8056 87.8583 47.1944 87.8583 45.858 87.1349L10.2887 67.8825C8.83593 67.0962 7.93078 65.577 7.93078 63.9251V26.0749C7.93078 24.423 8.83593 22.9038 10.2887 22.1175L45.8579 2.86505Z"
      stroke="url(#paint4_linear_4248_210459)"
      strokeWidth="3"
    />
    <g style={{ mixBlendMode: "overlay" }}>
      <path
        d="M45.8579 2.86505C47.1944 2.14169 48.8056 2.14169 50.142 2.86505L85.7113 22.1175C87.1641 22.9038 88.0692 24.423 88.0692 26.0749V63.9251C88.0692 65.577 87.1641 67.0962 85.7113 67.8825L50.1421 87.1349C48.8056 87.8583 47.1944 87.8583 45.858 87.1349L10.2887 67.8825C8.83593 67.0962 7.93078 65.577 7.93078 63.9251V26.0749C7.93078 24.423 8.83593 22.9038 10.2887 22.1175L45.8579 2.86505Z"
        stroke="#F8CC32"
        strokeWidth="3"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }} filter="url(#filter0_i_4248_210459)">
      <path
        d="M37.9307 63V57.432H45.7067V37.176C44.8107 38.04 43.8187 38.648 42.7307 39C41.6427 39.32 40.2827 39.48 38.6507 39.48V33.624C40.5387 33.624 42.0427 33.288 43.1627 32.616C44.3147 31.912 45.2587 30.84 45.9947 29.4H52.0427V57.432H59.8187V63H37.9307Z"
        fill="#B28600"
      />
    </g>
    <path
      d="M11.4373 79.5207C11.6306 78.9983 12.3694 78.9983 12.5627 79.5207L14.3351 84.3104C14.3958 84.4747 14.5253 84.6042 14.6896 84.6649L19.4793 86.4373C20.0017 86.6306 20.0017 87.3694 19.4793 87.5627L14.6896 89.3351C14.5253 89.3958 14.3958 89.5253 14.3351 89.6896L12.5627 94.4793C12.3694 95.0017 11.6306 95.0017 11.4373 94.4793L9.66493 89.6896C9.60416 89.5253 9.47467 89.3958 9.31044 89.3351L4.5207 87.5627C3.99833 87.3694 3.99833 86.6306 4.5207 86.4373L9.31044 84.6649C9.47467 84.6042 9.60416 84.4747 9.66493 84.3104L11.4373 79.5207Z"
      fill="#FCF2BE"
    />
    <g style={{ mixBlendMode: "overlay" }} filter="url(#filter1_i_4248_210459)">
      <path
        d="M11.4373 79.5207C11.6306 78.9983 12.3694 78.9983 12.5627 79.5207L14.3351 84.3104C14.3958 84.4747 14.5253 84.6042 14.6896 84.6649L19.4793 86.4373C20.0017 86.6306 20.0017 87.3694 19.4793 87.5627L14.6896 89.3351C14.5253 89.3958 14.3958 89.5253 14.3351 89.6896L12.5627 94.4793C12.3694 95.0017 11.6306 95.0017 11.4373 94.4793L9.66493 89.6896C9.60416 89.5253 9.47467 89.3958 9.31044 89.3351L4.5207 87.5627C3.99833 87.3694 3.99833 86.6306 4.5207 86.4373L9.31044 84.6649C9.47467 84.6042 9.60416 84.4747 9.66493 84.3104L11.4373 79.5207Z"
        fill="url(#paint5_linear_4248_210459)"
      />
    </g>
    <g style={{ mixBlendMode: "overlay" }} filter="url(#filter2_di_4248_210459)">
      <path
        d="M76.8746 3.04141C77.2612 1.99667 78.7388 1.99667 79.1254 3.04141L82.6701 12.6209C82.7917 12.9493 83.0507 13.2083 83.3791 13.3299L92.9586 16.8746C94.0033 17.2612 94.0033 18.7388 92.9586 19.1254L83.3791 22.6701C83.0507 22.7917 82.7917 23.0507 82.6701 23.3791L79.1254 32.9586C78.7388 34.0033 77.2612 34.0033 76.8746 32.9586L73.3299 23.3791C73.2083 23.0507 72.9493 22.7917 72.6209 22.6701L63.0414 19.1254C61.9967 18.7388 61.9967 17.2612 63.0414 16.8746L72.6209 13.3299C72.9493 13.2083 73.2083 12.9493 73.3299 12.6209L76.8746 3.04141Z"
        fill="url(#paint6_linear_4248_210459)"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      d="M76.8746 3.04141C77.2612 1.99667 78.7388 1.99667 79.1254 3.04141L82.6701 12.6209C82.7917 12.9493 83.0507 13.2083 83.3791 13.3299L92.9586 16.8746C94.0033 17.2612 94.0033 18.7388 92.9586 19.1254L83.3791 22.6701C83.0507 22.7917 82.7917 23.0507 82.6701 23.3791L79.1254 32.9586C78.7388 34.0033 77.2612 34.0033 76.8746 32.9586L73.3299 23.3791C73.2083 23.0507 72.9493 22.7917 72.6209 22.6701L63.0414 19.1254C61.9967 18.7388 61.9967 17.2612 63.0414 16.8746L72.6209 13.3299C72.9493 13.2083 73.2083 12.9493 73.3299 12.6209L76.8746 3.04141Z"
      fill="#FCF2BE"
    />
    <defs>
      <filter
        id="filter0_i_4248_210459"
        x="37.9307"
        y="29.4"
        width="21.8879"
        height="36.6"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="luminosity" in2="shape" result="effect1_innerShadow_4248_210459" />
      </filter>
      <filter
        id="filter1_i_4248_210459"
        x="-1.87109"
        y="79.1289"
        width="21.7422"
        height="21.7422"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-6" dy="6" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_4248_210459" />
      </filter>
      <filter
        id="filter2_di_4248_210459"
        x="50.2578"
        y="2.25787"
        width="55.4844"
        height="55.4843"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4248_210459" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4248_210459" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-6" dy="6" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_4248_210459" />
      </filter>
      <radialGradient
        id="paint0_radial_4248_210459"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 6) rotate(43.1524) scale(131.59 131.317)"
      >
        <stop offset="0.34375" stopColor="#827F7F" />
        <stop offset="1" stopColor="#5F5F5F" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_4248_210459"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(43.1524) scale(131.59 131.317)"
      >
        <stop offset="0.34375" stopColor="#827F7F" />
        <stop offset="1" stopColor="#5F5F5F" />
      </radialGradient>
      <linearGradient id="paint2_linear_4248_210459" x1="36" y1="72" x2="36" y2="6" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint3_linear_4248_210459" x1="57" y1="84" x2="57" y2="18" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4248_210459"
        x1="96"
        y1="0"
        x2="1.43051e-06"
        y2="94.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#697077" />
        <stop offset="1" stopColor="#C7C7CC" />
      </linearGradient>
      <linearGradient id="paint5_linear_4248_210459" x1="12" y1="78" x2="12" y2="96" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint6_linear_4248_210459" x1="78" y1="0" x2="78" y2="36" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </Icon>
);

export const RankOneGradient = (props: IconProps) => (
  <Icon width="284" height="128" viewBox="0 0 284 128" fill="none" {...props}>
    <g clipPath="url(#clip0_4248_209461)">
      <g opacity="0.5" filter="url(#filter0_f_4248_209461)">
        <path d="M248.5 -24C248.5 46.6924 121.349 104 -35.5 104V-24H248.5Z" fill="url(#paint0_radial_4248_209461)" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_4248_209461"
        x="-51.5"
        y="-40"
        width="316"
        height="160"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_4248_209461" />
      </filter>
      <radialGradient
        id="paint0_radial_4248_209461"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-35.5 -24.0007) rotate(90) scale(128 284)"
      >
        <stop stopColor="#F8CC32" />
        <stop offset="1" stopColor="#F8CC32" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_4248_209461">
        <rect width="284" height="128" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
