import { Box, HStack } from "@chakra-ui/react";
import { ButtonProps } from "@looksrare/uikit";
import { useGetCaveQueryParams } from "../../../hooks";
import { CaveCountdown } from "../../CaveCountdown";
import { PanelHeader } from "../../layout";
import {
  CurrentButton,
  HistoryButton,
  NextJoinableRoundButton,
  NextRoundButton,
  PreviousRoundButton,
} from "../../Buttons";

export const GamePlayHeader = () => {
  const { caveOnChainId, network } = useGetCaveQueryParams();
  const sharedButtonProps: Partial<ButtonProps> = {
    size: "xs",
    colorScheme: "secondary",
    variant: "outline",
  };

  return (
    <PanelHeader position="relative" zIndex={5} height={14} justifyContent="flex-end">
      <Box position="absolute" top="-17px" left="-17px">
        <CaveCountdown />
      </Box>
      <HStack gap={2}>
        <HistoryButton network={network} caveOnChainId={caveOnChainId} {...sharedButtonProps} />
        <PreviousRoundButton {...sharedButtonProps} />
        <NextRoundButton {...sharedButtonProps} />

        <CurrentButton {...sharedButtonProps} />
        <NextJoinableRoundButton {...sharedButtonProps} />
      </HStack>
    </PanelHeader>
  );
};
