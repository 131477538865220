import { Box } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";
import { Trans } from "next-i18next";

export const RoundExplanation = () => {
  return (
    <Trans i18nKey="mod::roundExplanation2">
      <Text textStyle="detail" color="text-inverse">
        You can enter the next round any time until the current round starts closing. Payout Ratios shown here can
        change at any time as players enter the round.
        <Box as="ul" pl={4}>
          <li>MOON Payout = Total Entries ÷ Entries in MOON Pool</li>
          <li>DOOM Payout = Total Entries ÷ Entries in DOOM Pool</li>
          <li>Final payout = Payout Ratio × Position × (1% protocol fee)</li>
        </Box>
      </Text>
    </Trans>
  );
};
