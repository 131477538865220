import { ReactElement } from "react";
import { Box, BoxProps, forwardRef, Link as ChakraLink, LinkProps as ChakraLinkProps } from "@chakra-ui/react";
import { LaunchOpenInNewIcon } from "../Icons";

export type LinkProps = ChakraLinkProps;
export interface ExternalLinkProps extends LinkProps {
  rightIcon?: ReactElement | null;
  noOfLines?: BoxProps["noOfLines"];
  contentContainerProps?: BoxProps;
}

export const Link = forwardRef<LinkProps, "a">((props, ref) => (
  <ChakraLink ref={ref} {...(props.isExternal ? { rel: "noopener noreferrer" } : {})} {...props} />
));

const defaultRightIcon = <LaunchOpenInNewIcon ml={1} boxSize="1.2em" />;

export const ExternalLink = forwardRef<ExternalLinkProps, "a">(
  ({ children, rightIcon = defaultRightIcon, noOfLines, contentContainerProps, ...props }, ref) => (
    <Link isExternal ref={ref} {...props}>
      <Box display="flex" alignItems="center" {...contentContainerProps}>
        {/* this extra element is required because noOfLines sets display to "-webkit-box" to support multiline
          ellipsis, but breaking objects alignment */}
        <Box noOfLines={noOfLines}>{children}</Box>
        {rightIcon}
      </Box>
    </Link>
  )
);

Link.defaultProps = {
  color: "link-01",
};
