import { Icon, IconProps } from "../Icon";

const Timer = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 2H13V4H19V2ZM26.5801 7.58999L28.0001 8.99999L25.5101 11.49C28.4546 16.5387 26.9106 23.0102 22.0039 26.1858C17.0973 29.3613 10.5611 28.1193 7.16126 23.3653C3.76143 18.6113 4.69881 12.0245 9.28992 8.40774C13.881 4.79096 20.5042 5.42167 24.3301 9.83999L26.5801 7.58999ZM6.99997 17C6.99997 21.9705 11.0294 26 16 26C20.9705 26 25 21.9705 25 17C25 12.0294 20.9705 7.99998 16 7.99998C11.0294 7.99998 6.99997 12.0294 6.99997 17ZM15 11H17V20H15V11Z"
      fill="currentColor"
    />
  </Icon>
);

export default Timer;
