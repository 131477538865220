import { zeroAddress } from "viem";
import { ChainId, type GameAddresses } from "@looksrare/config";

const emptyGameAddresses: GameAddresses = {
  ERC20_LIQUITIDY_POOL: "0x",
  ETH: zeroAddress,
  ETH_LIQUITIDY_POOL: "0x",
  BLAST: "0x",
  BLOOKS: "0x",
  BOOST: "0x",
  DONT_FALL_IN: "0x",
  FLIPPER: "0x",
  GAME_CONFIG_MANAGER: "0x",
  JUICE: "0x",
  LASER_BLAST: "0x",
  LIQUIDITY_POOL_ROUTER: "0x",
  LIQUIDITY_TRANSFER_MANAGER: "0x",
  LOOKS: "0x",
  MOON_OR_DOOM_NATIVE_TOKEN_BTC: "0x",
  MOON_OR_DOOM_NATIVE_TOKEN_ETH: "0x",
  ORBIT: "0x",
  PAC: "0x",
  PTB: "0x",
  PTB_TRANSFER_MANAGER: "0x",
  QUANTUM: "0x",
  USDB: "0x",
  USDC: "0x",
  USDT: "0x",
  WETH: "0x",
  WLOOKS: "0x",
  YES: "0x",
  YIELD: "0x",
  YOLO: "0x",
  YOLO_PLAY: "0x",
  YOLO_TRANSFER_MANAGER: "0x",
  YOLO_V1_LIMITED: "0x",
  YOLO_V2: "0x",
} as const;

export const sepoliaGameAddresses: GameAddresses = {
  ...emptyGameAddresses,
  BOOST: "0x2bb538c1Ac2A7B5c8B75B4d60B8dD0246bb3C313",
  PTB: "0x5E1F4e3fCF93aCa42B3A59747531DF53A90508a0",
  PTB_TRANSFER_MANAGER: "0xFD1DcA440E83FF9A1AED83e0f2D4b3EE85Cc2d44",
  YOLO_TRANSFER_MANAGER: "0xFD1DcA440E83FF9A1AED83e0f2D4b3EE85Cc2d44",
  YOLO_PLAY: "0x0248fCC156908cbe90A821FC141F6eEd57177991",
  YOLO_V2: "0x7a8E6D6c6d6BeC39f5aA3D28373916c8e5c02098",
  YOLO_V1_LIMITED: "0xC0530881233cE0890E71956280Ef2413129A15e4",
  MOON_OR_DOOM_NATIVE_TOKEN_BTC: "0xa64A0736178B6FBf40DaEd2558192dfE1291CDc3",
  MOON_OR_DOOM_NATIVE_TOKEN_ETH: "0xE27BFa7760e8849A49F6f40E20FA34eE7A811e83",
  USDB: "0x4200000000000000000000000000000000000022",
  GAME_CONFIG_MANAGER: "0x15a521C7Fb65E86f31C46c12Ca121b11a682F2a6",
  ETH_LIQUITIDY_POOL: "0xC3833423584AeA13636efaEd48B565D2C16786E4",
  ERC20_LIQUITIDY_POOL: "0x8914De3b60e72407BA4CECC435ae782019F395b9",
  QUANTUM: "0x4A725CFE8D19D6416f9574F3Ca89b5639FEFb928",
  LIQUIDITY_POOL_ROUTER: "0x2c1b952cc31E87fb37CB43025B05E0187B01268E",
  WETH: "0x4200000000000000000000000000000000000023",
  LIQUIDITY_TRANSFER_MANAGER: "0xFD1DcA440E83FF9A1AED83e0f2D4b3EE85Cc2d44",
  LASER_BLAST: "0x2B593Fdbc1a9461f1Ee43830f9475Bc00f44E710",
  DONT_FALL_IN: "0x7b1b43E6581C4E5bC23E0C08AF7A38B7cd1a1b9f",
} as const;

// @todo: Standardize usage of projects/config/addresses instead of duplicating
export const mainnetGameAddresses: GameAddresses = {
  ...emptyGameAddresses,
  BLAST: "0xb1a5700fA2358173Fe465e6eA4Ff52E36e88E2ad",
  BOOST: "0x63D758C3AD4c1BDF3279A3f8fEA04471462952E1",
  PTB: "0x0000000000aCc01064aA5280da3F1C41A35827bc",
  PTB_TRANSFER_MANAGER: "0x00000000007fe8d7666bb0da2a5d13f72b8dabab",
  YOLO_TRANSFER_MANAGER: "0x00000000007fe8d7666bb0da2a5d13f72b8dabab",
  YOLO_PLAY: "0xdCCD7560C173eC7043Ac15E52608e818746e4992",
  YOLO_V2: "0x0000000000E14E87e5c80A8A90817308fFF715d3",
  YOLO_V1_LIMITED: "0x28EF3eaE1AbB6D6e22e9bFc7a0944f707E4726b3",
  MOON_OR_DOOM_NATIVE_TOKEN_BTC: "0xA56A95F41e64Bc76CDE7423aB2A2ee1763bD8Bcc",
  MOON_OR_DOOM_NATIVE_TOKEN_ETH: "0x693B37a9859Ce9465Fb2aAdeB03811a26A0c37C0",
  //Third-party ERC20s are only added to mainnet if they don't have a mintable testnet counterpart
  USDB: "0x4300000000000000000000000000000000000003",
  YES: "0x20fE91f17ec9080E3caC2d688b4EcB48C5aC3a9C",
  YIELD: "0x67fa2887914fA3729e9EED7630294Fe124f417A0",
  ORBIT: "0x42E12D42b3d6C4A74a88A61063856756Ea2DB357",
  JUICE: "0x818a92bc81Aad0053d72ba753fb5Bc3d0C5C0923",
  PAC: "0x5ffd9EbD27f2fcAB044c0f0a26A45Cb62fa29c06",
  WETH: "0x4300000000000000000000000000000000000004",
  GAME_CONFIG_MANAGER: "0x572a1FA9e45c2ec681ABa11B9Fdb829A5Ba9E50d",
  ETH_LIQUITIDY_POOL: "0xCf09205D04647B0dbF99fe702113358DF764cED3",
  ERC20_LIQUITIDY_POOL: "0x8c3EDe5dB70719aB9191655994880b088DD03917",
  LIQUIDITY_POOL_ROUTER: "0x4d90cc30B9E75679d3Cf89B011053946DfE8763b",
  // @note this is the same as projects/config TRANSFER_MANAGER_CLASSIC_GAMES
  LIQUIDITY_TRANSFER_MANAGER: "0x00000000007fe8d7666bb0da2a5d13f72b8dabab",
  LASER_BLAST: "0xc6f17f7935B12a38fe4a80f4f4Db7DBaf324224c",
  QUANTUM: "0xA596f7D6587DE656d0Ef099d2F28fe699060BF97",
  DONT_FALL_IN: "0x13F0Eb6747114533ee643EDd84F9f82a77D0025b",
  BLOOKS: "0x406F10d635be12ad33D6B133C6DA89180f5B999e",
  // YOLO erc20
  YOLO: "0xf77dd21c5ce38ac08786BE35Ef1d1DeC1a6a15F3",
} as const;

/**
 * Main address config
 */
export const gameAddresses = {
  [ChainId.BLAST_SEPOLIA]: sepoliaGameAddresses,
  [ChainId.BLAST_MAINNET]: mainnetGameAddresses,
};
