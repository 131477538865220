import { useTranslation } from "next-i18next";
import { Address } from "viem";
import { Modal } from "../../Modal/Modal";
import { ConvertEthView } from "./ConvertEthView";

interface ModalWrapperProps {
  isOpen: boolean;
  onClose: () => void;
  account: Address;
  defaultOutputAmount?: number; // @NOTE uses decimals not WEI
}

export const ConvertEthModal: React.FC<React.PropsWithChildren<ModalWrapperProps>> = ({
  isOpen,
  onClose,
  account,
  defaultOutputAmount,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t("Swap ETH/WETH")}>
      <ConvertEthView isOpen={isOpen} onClose={onClose} account={account} defaultOutputAmount={defaultOutputAmount} />
    </Modal>
  );
};
