import { Icon, IconProps } from "../Icon";

const OHLCChart = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 6V2L22 2V6H24ZM20 8.00003L26 8.00003V20H20L20 8.00003ZM18 22L18 6.00003L28 6.00003V22H18ZM24 22.0001V26.0001H22V22.0001H24ZM14 10L10 10V6.00006H8V10H4L4 26H14L14 10ZM6 24H12L12 12H6L6 24ZM8 26L8 30H10L10 26L8 26Z"
      fill="currentColor"
    />
  </Icon>
);

export default OHLCChart;
