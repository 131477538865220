import { useLayoutEffect, useState } from "react";
import throttle from "lodash/throttle";

export const useIsPageScrolled = (thresholdInPx = 10) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useLayoutEffect(() => {
    const onScroll = throttle(() => {
      if (window.scrollY > thresholdInPx) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }, 100);

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [thresholdInPx]);

  return isScrolled;
};
