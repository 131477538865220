import {
  Box,
  Divider,
  Flex,
  type FlexProps,
  HStack,
  LinkBox,
  LinkOverlay,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useAccount } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import times from "lodash/times";
import capitalize from "lodash/capitalize";
import { ChainIcon, LogoRoundIcon, NextLink, Text, useGameAddresses } from "@looksrare/uikit";
import { BigIntish, formatToSignificant, getChainIdFromSupportedNetwork } from "@looksrare/utils";
import { SeatAvailableIcon, SeatFilledIcon } from "../Icons";
import { BannerWithTail } from "../BannerWithTail";
import { EnterCaveModal } from "../Modals/EnterCave";
import { getPtbContractNameFromNetwork } from "../../utils";
import { type PtbSupportedNetwork } from "../../types";
import { BASE_URL } from "../../config";
import { CurrencyTokenIcon } from "../CurrencyTokenIcon";
import { LobbyCaveCardCountdown } from "./LobbyCaveCardCountdown";

type Variant = "eth" | "looks";
const MAX_SEATS_RENDER_COUNT = 10;

interface LobbyCaveCardProps extends FlexProps {
  caveOnChainId: BigIntish;
  roundOnChainId?: BigIntish;
  network: PtbSupportedNetwork;
  caveName: string;
  entryFeeWei: bigint;
  currentPlayers: number;
  maxPlayers: number;
  variant: Variant;
  isCanceled?: boolean;
}

export const LobbyCaveCard = ({
  caveOnChainId,
  roundOnChainId,
  network,
  caveName,
  entryFeeWei,
  currentPlayers,
  maxPlayers,
  variant,
  isCanceled = false,
  ...props
}: LobbyCaveCardProps) => {
  const { isConnected } = useAccount();
  const { t } = useTranslation();
  const { openConnectModal } = useConnectModal();
  const contractName = getPtbContractNameFromNetwork(network);
  const caveRoundHref = `${BASE_URL}/cave/${network}/${caveOnChainId}/${roundOnChainId}`;
  const enterCaveDisclosure = useDisclosure();
  const router = useRouter();
  const ptbAddresses = useGameAddresses();

  const seatsRemainingCount = maxPlayers - currentPlayers;

  /**
   * Determine how many available & filled seats to render. Display a max of MAX_SEATS_RENDER_COUNT seats,
   * and display available seats if possible.
   */
  const numberSeatsToRender = Math.min(maxPlayers, MAX_SEATS_RENDER_COUNT);
  const availableSeatsRenderCount = Math.min(seatsRemainingCount, numberSeatsToRender);
  const filledSeatsRenderCount = Math.min(currentPlayers, numberSeatsToRender - availableSeatsRenderCount);

  const countdownStatus = (() => {
    if (isCanceled) {
      return "canceled";
    }
    if (seatsRemainingCount > 0) {
      return "readyToJoin";
    }

    if (currentPlayers === maxPlayers) {
      return "inProgress";
    }

    return "ended";
  })();

  const handleEnter = () => {
    router.push(caveRoundHref);
  };

  return (
    <LinkBox
      display="flex"
      minWidth="312px"
      borderRadius="container"
      border="1px solid"
      borderColor="border-01"
      overflow="hidden"
      flexDirection="column"
      {...props}
    >
      <LinkOverlay as={NextLink} href={caveRoundHref} prefetch={false}>
        {/* Header */}
        <Flex
          p={4}
          gap={2}
          alignItems="center"
          bg="ui-bg"
          borderBottom="1px solid"
          borderBottomColor="border-01"
          width="100%"
          position="relative"
        >
          <ChainIcon network={network} boxSize={7} />
          <Flex direction={"column"}>
            <Text textStyle="detail" bold>
              {caveName}
            </Text>
            {/* @todo-ptbl2 Use semantic colors and textStyle */}
            <Text noOfLines={1} fontSize={13} lineHeight="1rem" color={network === "arbitrum" ? "#12AAFF" : "#8D8BFC"}>
              {capitalize(network)}
            </Text>
          </Flex>
          {variant === "looks" && (
            <BannerWithTail position="absolute" right={0} top="19px">
              <Text textStyle="helper" bold color="text-primarybutton" p="2px 12px 2px">
                50% Less Fees!
              </Text>
            </BannerWithTail>
          )}
        </Flex>
        {/* Body */}
        <Stack p={4} spacing={4} bg="ui-glass">
          <Flex gap={4} alignItems="center">
            <Box flex={1}>
              <Text>{t("ptb::Entry Fee")}</Text>
              <Flex alignItems="center" gap={1}>
                <CurrencyTokenIcon
                  network={network}
                  boxSize={8}
                  currency={variant === "looks" ? ptbAddresses.LOOKS : undefined}
                />
                {variant === "looks" && <LogoRoundIcon width="30px" height="30px" />}
                <Text textStyle="display-03" bold>
                  {formatToSignificant(entryFeeWei, 6)}
                </Text>
              </Flex>
            </Box>
            <LobbyCaveCardCountdown status={countdownStatus} />
          </Flex>
          <Stack spacing={2}>
            <Flex alignItems="center" gap={2}>
              <Divider orientation="horizontal" flex={1} borderColor="text-02" />
              <Text textStyle="helper" color="text-02">
                {t("ptb::{{seatsRemainingCount}} Spots Remaining", { seatsRemainingCount })}
              </Text>
            </Flex>
            <HStack spacing={1}>
              {times(filledSeatsRenderCount).map((i) => (
                <SeatFilledIcon key={i} boxSize={6} />
              ))}
              {times(availableSeatsRenderCount).map((i) => (
                <SeatAvailableIcon
                  key={i}
                  zIndex={2}
                  cursor="pointer"
                  boxSize={6}
                  onClick={(e) => {
                    e.preventDefault();
                    if (!isConnected) {
                      openConnectModal?.();
                      // @todo-ptb on connect, open the modal
                      return;
                    }

                    enterCaveDisclosure.onOpen();
                  }}
                />
              ))}
            </HStack>
          </Stack>
        </Stack>
      </LinkOverlay>
      <EnterCaveModal
        chainId={getChainIdFromSupportedNetwork(network)}
        contractName={contractName}
        caveOnChainId={caveOnChainId}
        isOpen={enterCaveDisclosure.isOpen}
        onClose={enterCaveDisclosure.onClose}
        onEnter={handleEnter}
      />
    </LinkBox>
  );
};
