import { Icon, IconProps } from "../Icon";

const Liquidity = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M7.65185 18.929L15.5 1L23.3482 18.929C25.657 24.2035 21.5479 30 15.5 30C9.45206 30 5.34298 24.2035 7.65185 18.929Z"
      fill="currentColor"
    />
  </Icon>
);

export default Liquidity;
