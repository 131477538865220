import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const RankThree = (props: IconProps) => {
  const [uid0] = useState(uniqueId());
  const [uid1] = useState(uniqueId());
  const [uid2] = useState(uniqueId());
  const [uid3] = useState(uniqueId());

  return (
    <Icon viewBox="0 0 256 256" {...props}>
      <path
        d="M112 9.24031C121.901 3.52406 134.099 3.52406 144 9.24031L222.851 54.7651C232.752 60.4814 238.851 71.0454 238.851 82.4779V173.528C238.851 184.96 232.752 195.524 222.851 201.24L144 246.765C134.099 252.481 121.901 252.481 112 246.765L33.1484 201.24C23.2476 195.524 17.1484 184.96 17.1484 173.528V82.4779C17.1484 71.0454 23.2476 60.4814 33.1484 54.7651L112 9.24031Z"
        fill={`url(#${uid0})`}
      />
      <path
        d="M81.813 48.001C110.394 31.4997 145.608 31.4997 174.189 48.001C202.77 64.5024 220.377 94.9983 220.377 128.001C220.377 161.004 202.77 191.5 174.189 208.001C145.608 224.502 110.394 224.502 81.813 208.001C53.2318 191.5 35.625 161.004 35.625 128.001C35.625 94.9983 53.2318 64.5024 81.813 48.001Z"
        fill={`url(#${uid1})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.313 48.8671C54.0412 65.1898 36.625 95.3555 36.625 128.001C36.625 160.647 54.0412 190.812 82.313 207.135C110.585 223.458 145.417 223.458 173.689 207.135C201.961 190.812 219.377 160.647 219.377 128.001C219.377 95.3555 201.961 65.1898 173.689 48.8671C145.417 32.5443 110.585 32.5443 82.313 48.8671ZM174.189 48.001C145.608 31.4997 110.394 31.4997 81.813 48.001C53.2318 64.5024 35.625 94.9983 35.625 128.001C35.625 161.004 53.2318 191.5 81.813 208.001C110.394 224.502 145.608 224.502 174.189 208.001C202.77 191.5 220.377 161.004 220.377 128.001C220.377 94.9983 202.77 64.5024 174.189 48.001Z"
        fill="black"
      />
      <g filter={`url(#${uid2})`}>
        <path
          d="M63.8145 107.925V86.9369C70.2825 79.8089 80.5785 77.9609 96.9465 77.9609H163.606C179.974 77.9609 193.174 89.8409 193.174 106.209V108.585C193.174 120.333 185.782 124.821 179.314 125.613C185.782 126.537 193.174 131.685 193.174 142.113V144.225C193.174 160.593 179.974 173.001 163.606 173.001H97.2105C80.0505 173.001 70.2825 168.645 63.8145 162.837V140.793C70.5465 146.205 80.0505 151.221 97.2105 151.221C113.314 151.221 140.638 151.221 160.834 151.221C173.506 151.221 174.958 134.325 160.834 134.325H102.358V116.637H160.966C175.354 116.637 174.034 99.7409 160.834 99.7409H96.9465C80.5785 99.7409 70.2825 101.589 63.8145 107.925Z"
          fill="#D9D9D9"
        />
        <path
          d="M63.8145 107.925V86.9369C70.2825 79.8089 80.5785 77.9609 96.9465 77.9609H163.606C179.974 77.9609 193.174 89.8409 193.174 106.209V108.585C193.174 120.333 185.782 124.821 179.314 125.613C185.782 126.537 193.174 131.685 193.174 142.113V144.225C193.174 160.593 179.974 173.001 163.606 173.001H97.2105C80.0505 173.001 70.2825 168.645 63.8145 162.837V140.793C70.5465 146.205 80.0505 151.221 97.2105 151.221C113.314 151.221 140.638 151.221 160.834 151.221C173.506 151.221 174.958 134.325 160.834 134.325H102.358V116.637H160.966C175.354 116.637 174.034 99.7409 160.834 99.7409H96.9465C80.5785 99.7409 70.2825 101.589 63.8145 107.925Z"
          fill={`url(#${uid3})`}
        />
      </g>
      <defs>
        <filter
          id={uid2}
          x="43.322"
          y="57.4685"
          width="180.59"
          height="146.27"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="5.12311" dy="5.12311" />
          <feGaussianBlur stdDeviation="12.8078" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
          <feBlend mode="overlay" in2="BackgroundImageFix" result="effect1_dropShadow_2357_95323" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2357_95323" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1.28078" dy="1.28078" />
          <feGaussianBlur stdDeviation="0.640389" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="soft-light" in2="shape" result="effect2_innerShadow_2357_95323" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1.28078" dy="-1.28078" />
          <feGaussianBlur stdDeviation="0.640389" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.9 0" />
          <feBlend mode="overlay" in2="effect2_innerShadow_2357_95323" result="effect3_innerShadow_2357_95323" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1.28078" dy="-1.28078" />
          <feGaussianBlur stdDeviation="0.640389" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="effect3_innerShadow_2357_95323" result="effect4_innerShadow_2357_95323" />
        </filter>
        <linearGradient id={uid0} x1="256" y1="0.00272849" x2="-44.6602" y2="182.377" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFA450" />
          <stop offset="1" stopColor="#EF6B20" />
        </linearGradient>
        <linearGradient id={uid1} x1="128.001" y1="21.3344" x2="128.001" y2="234.668" gradientUnits="userSpaceOnUse">
          <stop stopColor="#191B20" />
          <stop offset="1" stopColor="#3D4048" />
        </linearGradient>
        <linearGradient id={uid3} x1="153.386" y1="89.8016" x2="70.5373" y2="163.429" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFA450" />
          <stop offset="1" stopColor="#EF6B20" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export default RankThree;
