import { Icon, IconProps } from "../Icon";

const SettingsFill = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9723 2H19.0276L19.7066 6.70945C20.626 7.07659 21.4782 7.57626 22.2395 8.18484L26.7223 6.40191L29.75 11.5981L25.9016 14.5913C25.9664 15.0515 26 15.5219 26 16C26 16.4781 25.9664 16.9485 25.9016 17.4087L29.75 20.4019L26.7223 25.5981L22.2394 23.8152C21.4782 24.4237 20.626 24.9234 19.7066 25.2905L19.0276 30H12.9723L12.2933 25.2905C11.3739 24.9234 10.5218 24.4237 9.76053 23.8151L5.27767 25.5981L2.25 20.4019L6.09845 17.4087C6.03356 16.9484 6 16.4781 6 16C6 15.5219 6.03356 15.0516 6.09845 14.5913L2.25 11.5981L5.27767 6.40194L9.76053 8.18486C10.5218 7.57628 11.3739 7.07662 12.2933 6.70948L12.9723 2ZM21 16C21 18.7614 18.7614 21 16 21C13.2386 21 11 18.7614 11 16C11 13.2386 13.2386 11 16 11C18.7614 11 21 13.2386 21 16Z"
      fill="currentColor"
    />
  </Icon>
);

export default SettingsFill;
