import { type Address } from "viem";
import { type BigIntish } from "@looksrare/utils";
import type { PtbContractName, PtbRoundLogType, PtbRoundStatus } from "../types";

export interface PtbUser {
  address: Address;
  name?: string;
  isProfileImageVisible?: boolean | null;
  avatar?: {
    image: {
      src: string;
      contentType: string;
    };
  };
}

export interface PtbPoke {
  automated: boolean;
  pokedAt: string | null;
  index: number;
  isPokingUntil: string | null;
}

export interface PtbPlayer {
  entryIndex: number;
  poke: PtbPoke | null;
  user: PtbUser;
  futureCommittedRoundsCount: number;
  claimed: boolean | null;
  refunded: boolean | null;
  lost: boolean | null;
  gemsEarned: BigIntish | null;
}

export interface PtbRound {
  id: string;
  onChainId: string;
  status: PtbRoundStatus;
  openedAt: string | null;
}

export interface PtbRoundReturn {
  id: string;
  onChainId: string;
  status: PtbRoundStatus;
  players: PtbPlayer[];
  drawnTransactionHash: Address | null;
  openedAt: string | null;
  revealTransactionHash: Address | null;
  revealedAt: string | null;
}

// For situations where you are querying rounds directly
export interface ExtendedPtbRound extends PtbRoundReturn {
  cave: {
    onChainId: BigIntish;
    isActive: boolean;
    name: string;
    contract: PtbContractName;
    enterAmount: BigIntish;
    playersPerRound: number;
    currency: Address;
  };
}
export interface PaginatedExtendedPtbRounds {
  total: number;
  rounds: ExtendedPtbRound[];
}

export interface PtbCave {
  id: string;
  name: string;
  currency: Address;
  enterAmount: BigIntish;
  protocolFeeBp: BigIntish;
  contract: PtbContractName;
  onChainId: BigIntish;
  playersPerRound: number;
  roundDurationSecs: string;
  isActive: boolean;
}

export interface PtbCaveReturn extends PtbCave {
  round: PtbRoundReturn | null;
}

export interface PtbRoundsFilter {
  caveOnChainIds?: number[];
  player?: Address;
  status?: PtbRoundStatus[];
}

// @todo-ptb this is very generic type
export interface OffsetPaginationInput {
  first: number;
  offset: number;
}

export enum PtbRoundsSort {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
}

export interface PtbUnclaimedEntry {
  cave: {
    onChainId: PtbCave["onChainId"];
    currency: PtbCave["currency"];
    enterAmount: PtbCave["enterAmount"];
    protocolFeeBp: PtbCave["protocolFeeBp"];
    playersPerRound: PtbCave["playersPerRound"];
  };
  round: {
    onChainId: PtbRoundReturn["onChainId"];
  };
  entryIndex: number;
}

// Specifying GraphQL because the websocket event only returns address
export interface PtbGraphQlLog {
  type: PtbRoundLogType;
  timestamp: string;
  user: PtbUser[] | null;
}

// Log event coming back from the websocket
export interface CaveLog {
  type: PtbRoundLogType;
  user: Address[];
  timestamp: string;
  cave: BigIntish;
  round: BigIntish;
}

export interface RoundLogsReturn {
  caveOnChainId: BigIntish;
  roundOnChainId: BigIntish | null;
  enterAmount: BigIntish;
  playersPerRound: number;
  logs: PtbGraphQlLog[];
}

export interface CaveInfoReturn {
  cave: PtbCave;
  round: PtbRound | null;
  players: PtbPlayer[];
  meta: {
    totalPrizePool: bigint;
    prizePerPlayer: bigint;
    prizePerPlayerNet: bigint;
    isRoundCancelled: boolean;
    isRoundRevealed: boolean;
    isRoundOpen: boolean;
    isRoundDrawn: boolean;
    isRoundDrawing: boolean;
    isRoundNone: boolean;
  };
}
