import { Address } from "viem";
import { graphql } from "@looksrare/utils";
import { PaginationInput, RakebacksFilterInput, graphql as generatedGql } from "@looksrare/yolo-games-gql-typegen";

export const getUserRakebackData = async (address: Address) => {
  const query = generatedGql(/* GraphQL */ `
    query GetUserRakebackData($address: Address!) {
      user(address: $address) {
        rakebacks {
          lastRoundId
          totalClaimed {
            currency {
              address
              decimals
              name
              symbol
            }
            amountWei
            amountEthWei
          }
          totalPending {
            currency {
              address
              decimals
              name
              symbol
            }
            amountWei
            amountEthWei
          }
          rakebacks {
            locked {
              ...RakebackFragment
            }
            unlocked {
              ...RakebackFragment
            }
            history {
              ...RakebackFragment
            }
          }
        }
        nextRakeback {
          ...RakebackFragment
        }
        rakebackClaimDetails {
          cumulativeAmountWei
          endsAt
          proof
          currency {
            address
            decimals
            name
            symbol
          }
        }
      }
    }
  `);

  const res = await graphql({ query, params: { address } });
  return res.user;
};

export interface UserRakebacksInput {
  address: Address;
  filter: RakebacksFilterInput;
  pagination?: PaginationInput;
}

export const getUserRakebacks = async ({ address, filter, pagination }: UserRakebacksInput) => {
  const query = generatedGql(/* GraphQL */ `
    query GetUserRakebacks($address: Address!, $filter: RakebacksFilterInput, $pagination: PaginationInput) {
      user(address: $address) {
        rakebacks(filter: $filter, pagination: $pagination) {
          lastRoundId
          rakebacks {
            locked {
              ...RakebackFragment
            }
            unlocked {
              ...RakebackFragment
            }
            history {
              ...RakebackFragment
            }
          }
        }
      }
    }
  `);

  const res = await graphql({ query, params: { address, filter, pagination } });
  return res.user?.rakebacks;
};
