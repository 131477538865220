import { Theme, lightTheme as lightRainbowTheme, darkTheme as darkRainbowTheme } from "@rainbow-me/rainbowkit";
import { palette } from "@/theme/colors";
import { global } from "@/theme/global";

/**
 * @see https://www.rainbowkit.com/docs/custom-theme
 * @NOTE default theme included to ensure any updates will be reflected in the theme object
 */
const defaultDarkTheme = darkRainbowTheme();
const darkTheme = {
  ...defaultDarkTheme,
  colors: {
    ...defaultDarkTheme.colors,
    accentColor: palette.acid[200],
    accentColorForeground: "black", // text-primarybutton
    error: palette.red[400],
    menuItemBackground: palette.gray[850],
    modalBackground: palette.gray[800],
    modalText: palette.gray[50],
    modalTextSecondary: palette.gray[400],
  },
  fonts: {
    ...defaultDarkTheme.fonts,
    body: global.body.fontFamily,
  },
  radii: {
    ...defaultDarkTheme.radii,
    actionButton: "4px",
    menuButton: "4px",
    modal: "16px",
    modalMobile: "8px",
  },
};

// @TODO support light mode
const defaultLightTheme = lightRainbowTheme();
const lightTheme = {
  ...defaultLightTheme,
  colors: {
    ...defaultLightTheme.colors,
    accentColor: palette.green[400],
    accentColorForeground: "white", // text-primarybutton
    error: palette.red[500],
    menuItemBackground: palette.gray[75],
    modalBackground: palette.gray[50],
    modalText: palette.gray[800],
    modalTextSecondary: palette.gray[500],
  },
  fonts: {
    ...defaultLightTheme.fonts,
    body: global.body.fontFamily,
  },
  radii: {
    ...defaultLightTheme.radii,
    actionButton: "4px",
    menuButton: "4px",
    modal: "16px",
    modalMobile: "8px",
  },
};

export const theme: Theme = {
  lightMode: lightTheme, // @NOTE currently not supported
  darkMode: darkTheme,
};
