import { Box, BoxProps, HStack, Stack } from "@chakra-ui/react";
import { useMemo } from "react";
import dynamic from "next/dynamic";
import { Link, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { useOnAccountChange } from "@looksrare/utils";
import { MoDAssetPair, MoDRoundFragment } from "@looksrare/yolo-games-gql-typegen";
import { OracleChart } from "../../../components/OracleChart/OracleChart";
import { useMoDHistoricalPrices } from "../../../network/graphql/hooks";
import { usePythPriceRealtimeHistory } from "../../../network/pyth/hooks";
import { mapHistoricalPricesToTuple } from "../../../utils/mapHistoricalPricesToTuple";
import { useMoDStore } from "../../../stores/modStore";

const TradingviewChart = dynamic(
  () => import("../../../components/TradingViewChart/TradingViewChart").then((module) => module.TradingViewChart),
  { ssr: false }
);

interface MoDChartsProps extends BoxProps {
  assetPair: MoDAssetPair;
  pastRounds?: MoDRoundFragment[];
  currentRound?: MoDRoundFragment;
  nextRound?: MoDRoundFragment;
}

export const MoDCharts = ({ assetPair, pastRounds, currentRound, nextRound, ...props }: MoDChartsProps) => {
  const { t } = useTranslation();
  const { setIsTradingViewModalOpen, shouldSmoothData } = useMoDStore();

  const { selectedChart } = useMoDStore();
  const { data: pythPriceHistory, refetch: refetchPythPriceHistory } = useMoDHistoricalPrices(assetPair, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: 0,
  });

  useOnAccountChange(() => refetchPythPriceHistory());

  const prices = usePythPriceRealtimeHistory(assetPair, {
    isEnabled: !!pastRounds && !!currentRound && !!pythPriceHistory,
    shouldSmooth: shouldSmoothData,
  });

  const chartData = useMemo(() => {
    if (!pythPriceHistory || !prices) {
      return [];
    }

    return [...mapHistoricalPricesToTuple(pythPriceHistory), ...prices];
  }, [prices, pythPriceHistory]);

  if (!pastRounds || !currentRound || !nextRound) {
    return null;
  }

  return (
    <>
      <Stack px={{ base: 0, sm: 2 }} py={{ base: 0, sm: 4 }} {...props}>
        <Box position="relative" width="100%" height="100%">
          {selectedChart === "oracle" && (
            <OracleChart data={chartData} pastRounds={pastRounds} currentRound={currentRound} nextRound={nextRound} />
          )}
          {selectedChart === "tradingview" && <TradingviewChart assetPair={assetPair} position="absolute" inset={0} />}
        </Box>
        {selectedChart === "tradingview" && (
          <HStack spacing={0} width="100%">
            <Text textStyle="caption" color="text-02" noOfLines={1}>
              {t(
                "mod::TradingView charts are provided for your reference only, and the price shown may differ from the price used to determine the open and close price of a round.\n\nThis is because round open and close prices are determined by a third-party price oracle. The Default chart shows the accurate price provided by this price oracle.\n\nIn case of differences between TradingView and the price oracle, you can verify the fairness of a round after it closes via the History page."
              )}
            </Text>
            <Link onClick={() => setIsTradingViewModalOpen(true)} textStyle="caption">
              {t("More")}
            </Link>
          </HStack>
        )}
      </Stack>
    </>
  );
};
