import { Trans } from "next-i18next";
import { PtbRoundLogType } from "../../../types";
import { LogText, LogTextHighlight } from "./LogText";
import type { LogTextProps, RoundLogTextGetter } from "./types";

const PlayerPassText1 = ({ user1, user2, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerPass1">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      passes the stick to{" "}
      <LogTextHighlight>
        <>{{ user2 }}</>
      </LogTextHighlight>
      .
    </Trans>
  </LogText>
);

export const playerPassLogs: RoundLogTextGetter = ({ user1, user2 }) => [
  <PlayerPassText1 key={`${PtbRoundLogType.PLAYER_PASS}1`} user1={user1} user2={user2} />,
];
