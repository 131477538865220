import { formatNumberToLocale } from "@looksrare/utils";
import { Text, TextProps } from "../../";

export interface PercentChangeLabelProps extends TextProps {
  value: number;
  positiveColorOverride?: string;
  negativeColorOverride?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

export const PercentChangeLabel = ({
  value,
  positiveColorOverride,
  negativeColorOverride,
  minimumFractionDigits,
  maximumFractionDigits,
  ...props
}: PercentChangeLabelProps) => {
  const numberAsLocale = formatNumberToLocale(value, minimumFractionDigits, maximumFractionDigits);
  const { textColor, prefix }: { textColor: string; prefix: string } = (() => {
    if (value > 0) {
      return { textColor: positiveColorOverride || "support-success", prefix: "+" };
    }

    if (value < 0) {
      return { textColor: negativeColorOverride || "text-error", prefix: "" };
    }

    return { textColor: "text-03", prefix: "" };
  })();

  return (
    <Text textStyle="detail" color={textColor} {...props}>
      {`${prefix}${numberAsLocale}%`}
    </Text>
  );
};
