import { useEffect } from "react";

export const useResizeObserver = (runnable: () => void, elementToObserve?: HTMLElement | null): void => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver(runnable);

    resizeObserver.observe(elementToObserve ?? document.body);

    return () => resizeObserver.disconnect();
  }, [runnable, elementToObserve]);
};
