import { Icon, IconProps } from "../Icon";

const UserAvatar = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 16C2 8.26801 8.26801 2 16 2C19.713 2 23.274 3.475 25.8995 6.1005C28.525 8.72601 30 12.287 30 16C30 23.732 23.732 30 16 30C8.26801 30 2 23.732 2 16ZM16 7C13.2386 7 11 9.23858 11 12C11 14.7614 13.2386 17 16 17C18.7614 17 21 14.7614 21 12C21 9.23858 18.7614 7 16 7ZM16 15C14.3431 15 13 13.6569 13 12C13 10.3431 14.3431 9 16 9C17.6569 9 19 10.3431 19 12C19 13.6569 17.6569 15 16 15ZM9.99967 26.38V24.38C9.9083 22.6229 11.2442 21.1178 12.9997 21H18.9997C20.7593 21.1178 22.0967 22.6291 21.9997 24.39V26.39C18.2913 28.5502 13.708 28.5502 9.99967 26.39V26.38ZM24 24.31V24.9C27.7084 21.5831 28.9854 16.3217 27.2101 11.6739C25.4347 7.026 20.9754 3.95574 16 3.95574C11.0246 3.95574 6.56526 7.026 4.78993 11.6739C3.0146 16.3217 4.29156 21.5831 8 24.9V24.31C7.93787 21.4719 10.1633 19.1085 13 19H19C21.8343 19.1138 24.0567 21.474 24 24.31Z"
      fill="currentColor"
    />
  </Icon>
);

export default UserAvatar;
