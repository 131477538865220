import { IconButton } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { NextLink, type ButtonProps, Chevron } from "@looksrare/uikit";
import { getRoundUrl } from "../../utils";
import { useGetCaveQueryParams } from "../../hooks";

export const PreviousRoundButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const { caveOnChainId, roundOnChainId, network } = useGetCaveQueryParams();
  const paramRoundOnChainId = Number(roundOnChainId);
  const baseCaveUrl = getRoundUrl({ caveOnChainId, network });

  return (
    <IconButton
      aria-label={t("ptb::Previous Round")}
      title={t("ptb::Previous Round")}
      size="xs"
      colorScheme="secondary"
      isDisabled={paramRoundOnChainId === 1}
      {...(paramRoundOnChainId !== 1 && {
        as: NextLink,
        href: `${baseCaveUrl}/${paramRoundOnChainId - 1}`,
      })}
      {...props}
    >
      <Chevron direction="left" />
    </IconButton>
  );
};
