import { GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import { UseBreakpointOptions, useBreakpointValue } from "@chakra-ui/react";
import { breakpoints } from "@looksrare/chakra-theme";
import isMobile from "ismobilejs";

export const layoutInfo: { screenSize: string | undefined } = { screenSize: undefined };

// @see https://github.com/timfee/nextauthjs-server-side-props-wrapper/blob/fafba4584953e8d9d789af465e787e65d7ebfdaa/utils/withSSR.ts#L5-L34
export const withGuessedBreakpoint = <P extends { [key: string]: unknown } = { [key: string]: unknown }>(
  getServersideProps: (context: GetServerSidePropsContext) => Promise<GetServerSidePropsResult<P>>
) => {
  return async (context: GetServerSidePropsContext) => {
    const detectedSize = isMobile(context.req.headers["user-agent"] || "");
    const breakpoint = detectedSize.phone ? "xs" : detectedSize.tablet ? "sm" : "lg";
    const result: any = await getServersideProps(context); // @TODO refine "any" type
    if (result?.props) {
      result.props.breakpoint = breakpoint;
    }
    return result;
  };
};

export const useUpdateDefaultBreakpoint = (value: string | undefined) => {
  // assigning directly as opposed to using, for example a context,
  // because it's only meaningful during first render and then it's not supposed to change
  layoutInfo.screenSize = value;
};

type SupportedBreakpoints = keyof typeof breakpoints | "base";

export const useBreakpointValueSsr = <T = any>(
  values: Partial<Record<SupportedBreakpoints, T>>,
  options?: UseBreakpointOptions
) => {
  return useBreakpointValue<T>(values, { fallback: layoutInfo.screenSize, ...options });
};
