import { Icon, IconProps } from "../Icon";

const WethLooks = (props: IconProps) => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g clipPath="url(#clip0_1483_31120)">
      <circle cx="16.6666" cy="16.6674" r="16.6667" fill="#F3F5F7" />
      <g clipPath="url(#clip1_1483_31120)">
        <path
          d="M16.664 4.79199L16.5047 5.33324V21.0378L16.664 21.1967L23.9538 16.8877L16.664 4.79199Z"
          fill="#DF5960"
        />
        <path d="M16.6648 4.79199L9.37494 16.8877L16.6648 21.1967V13.5742V4.79199Z" fill="#EE9398" />
        <path
          d="M16.6641 22.5772L16.5743 22.6867V28.2807L16.6641 28.5429L23.9581 18.2705L16.6641 22.5772Z"
          fill="#DF5960"
        />
        <path d="M16.6646 28.5426V22.5769L9.37494 18.2703L16.6646 28.5426Z" fill="#EE9398" />
        <path d="M16.6648 21.1958L23.9543 16.8869L16.6648 13.5735V21.1958Z" fill="#CF373E" />
        <path d="M9.37494 16.8872L16.6646 21.196V13.5737L9.37494 16.8872Z" fill="#DF5960" />
      </g>
    </g>
    <g clipPath="url(#clip2_1483_31120)">
      <circle cx="31.6666" cy="31.6661" r="8.33333" fill="#0CE466" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.6666 32.5671C30.7133 32.5671 29.9398 31.7944 29.9398 30.8404C29.9398 29.8864 30.7133 29.1137 31.6666 29.1137C32.6198 29.1137 33.3933 29.8864 33.3933 30.8404C33.3933 31.7944 32.6198 32.5671 31.6666 32.5671ZM30.9158 30.8404C30.9158 31.2552 31.2521 31.5912 31.6666 31.5912C32.081 31.5912 32.4173 31.2552 32.4173 30.8404C32.4173 30.4256 32.081 30.0897 31.6666 30.0897C31.2521 30.0897 30.9158 30.4256 30.9158 30.8404Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.111 30.8427L29.4143 27.5371H33.9188L37.2221 30.8427L31.6666 36.396L26.111 30.8427ZM34.2191 29.6392C32.8156 28.2295 30.5176 28.2295 29.114 29.6392L27.9128 30.8404L29.114 32.0416C30.5176 33.4513 32.8156 33.4513 34.2191 32.0416L35.4203 30.8404L34.2191 29.6392Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1483_31120">
        <rect width="33.3333" height="33.3333" fill="white" transform="translate(-6.10352e-05 0.000732422)" />
      </clipPath>
      <clipPath id="clip1_1483_31120">
        <rect width="14.5833" height="29.1667" fill="white" transform="translate(9.37494 2.08398)" />
      </clipPath>
      <clipPath id="clip2_1483_31120">
        <rect width="16.6667" height="16.6667" fill="white" transform="translate(23.3333 23.3328)" />
      </clipPath>
    </defs>
  </Icon>
);

export default WethLooks;
