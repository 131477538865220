import { Icon, IconProps } from "../Icon";

const GamesFill = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.06994 25.8101C6.53938 25.937 7.02363 26.0009 7.50993 26.0001C8.44786 25.9901 9.36741 25.7387 10.1799 25.2701C11.4654 24.5265 12.3985 23.2979 12.7699 21.8601L12.9999 21.0001H18.9999L19.1799 21.8801C19.5513 23.3179 20.4845 24.5465 21.7699 25.2901C23.0256 26.0173 24.52 26.2118 25.9199 25.8301C28.8649 24.9986 30.5972 21.9572 29.8099 19.0001L27.4799 10.1401C27.1055 8.70368 26.1732 7.4762 24.8899 6.73005C23.6343 6.00279 22.1399 5.80833 20.7399 6.19005C19.2486 6.60032 17.9996 7.62063 17.2999 9.00005H14.6999C13.9973 7.62301 12.7495 6.60369 11.2599 6.19005C9.86002 5.80833 8.36555 6.00279 7.10994 6.73005C5.8202 7.47826 4.88651 8.71477 4.51994 10.1601L2.18994 19.0001C1.41451 21.9477 3.13886 24.9742 6.06994 25.8101ZM8.00439 13.9988C8.00439 15.1033 8.89983 15.9988 10.0044 15.9988C11.109 15.9988 12.0044 15.1033 12.0044 13.9988C12.0044 12.8942 11.109 11.9988 10.0044 11.9988C8.89983 11.9988 8.00439 12.8942 8.00439 13.9988ZM22.0044 12.9988C22.5567 12.9988 23.0044 12.5511 23.0044 11.9988C23.0044 11.4465 22.5567 10.9988 22.0044 10.9988C21.4521 10.9988 21.0044 11.4465 21.0044 11.9988C21.0044 12.5511 21.4521 12.9988 22.0044 12.9988ZM23.0044 15.9988C23.0044 16.5511 22.5567 16.9988 22.0044 16.9988C21.4521 16.9988 21.0044 16.5511 21.0044 15.9988C21.0044 15.4465 21.4521 14.9988 22.0044 14.9988C22.5567 14.9988 23.0044 15.4465 23.0044 15.9988ZM20.0044 14.9988C20.5567 14.9988 21.0044 14.5511 21.0044 13.9988C21.0044 13.4465 20.5567 12.9988 20.0044 12.9988C19.4521 12.9988 19.0044 13.4465 19.0044 13.9988C19.0044 14.5511 19.4521 14.9988 20.0044 14.9988ZM25.0044 13.9988C25.0044 14.5511 24.5567 14.9988 24.0044 14.9988C23.4521 14.9988 23.0044 14.5511 23.0044 13.9988C23.0044 13.4465 23.4521 12.9988 24.0044 12.9988C24.5567 12.9988 25.0044 13.4465 25.0044 13.9988Z"
      fill="currentColor"
    />
  </Icon>
);

export default GamesFill;
