import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { MoDRoundFragment } from "@looksrare/yolo-games-gql-typegen";
import { useRoundStatsChart } from "../hooks/useRoundStatsChart";
import { RoundStatsChartStyles } from "./RoundStatsChartStyles";

interface RoundStatsChartProps {
  moonPayoutRatio?: MoDRoundFragment["moonPayoutRatio"];
  doomPayoutRatio?: MoDRoundFragment["doomPayoutRatio"];
}

export const RoundStatsChart = ({ moonPayoutRatio, doomPayoutRatio }: RoundStatsChartProps) => {
  const { chartElementRef, highchartsChart } = useRoundStatsChart();

  useEffect(() => {
    if (highchartsChart.current) {
      highchartsChart.current.series[0].setData([
        {
          name: "doomRatio",
          y: doomPayoutRatio ?? 0,
          className: "doomRatio",
        },
        {
          name: "moonRatio",
          y: moonPayoutRatio ?? 0,
          className: "moonRatio",
        },
      ]);
    }
  }, [doomPayoutRatio, highchartsChart, moonPayoutRatio]);

  return (
    <>
      <Box className="round-entry-header-chart" ref={chartElementRef} />
      <RoundStatsChartStyles />
    </>
  );
};
