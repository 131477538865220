import { useEffect, useRef } from "react";
import { useAccount } from "wagmi";
import { type BigIntish, isAddressEqual } from "@looksrare/utils";
import { PtbSupportedNetwork } from "../types";
import { useCurrentGameStatus, useSetCurrentGameStatus } from "./useGameState";
import { usePokeTheBear } from "./usePokeTheBear";
import { usePokingPlayer } from "./useActiveCaveRoundInfo";

const MAX_POKE = 1;

export const usePokeCursor = (caveOnChainId: BigIntish, roundOnChainId: BigIntish, network: PtbSupportedNetwork) => {
  const nodeRef = useRef<HTMLDivElement>(null);
  const numberOfClicks = useRef(0);
  const gameStatus = useCurrentGameStatus();
  const setGameStatus = useSetCurrentGameStatus();
  const { address } = useAccount();
  const { mutate } = usePokeTheBear({
    onSettled: () => {
      numberOfClicks.current = 0;
    },
    onSuccess: () => {
      setGameStatus("idle");
    },
  });
  const pokingPtbPlayer = usePokingPlayer();
  const isConnectedPlayer = isAddressEqual(address, pokingPtbPlayer?.user.address);

  // Poke after a certain number of clicks
  useEffect(() => {
    const { current: node } = nodeRef;
    if (!!node) {
      const handleClick = async () => {
        if (numberOfClicks.current === MAX_POKE) {
          if (roundOnChainId) {
            mutate({ caveOnChainId, roundOnChainId, network });
          }
        }
        numberOfClicks.current = numberOfClicks.current + 1;
      };

      if (gameStatus === "poking" && isConnectedPlayer) {
        node.addEventListener("click", handleClick);
      }
      return () => {
        numberOfClicks.current = 0;
        node.removeEventListener("click", handleClick);
      };
    }
  }, [
    numberOfClicks,
    mutate,
    isConnectedPlayer,
    caveOnChainId,
    roundOnChainId,
    pokingPtbPlayer,
    nodeRef,
    gameStatus,
    network,
  ]);

  // Enable poking cursor flow when the game is poking
  useEffect(() => {
    const { current: node } = nodeRef;

    if (!!node) {
      const enablePokeCursor = () => {
        node.style.cursor = "url('/images/poke-the-bear/stick-2-flipped.png'), auto";
      };
      const disablePokeCursor = () => {
        node.style.cursor = "url('/images/poke-the-bear/stick-2.png'), auto";
      };

      if (gameStatus === "poking" && isConnectedPlayer) {
        enablePokeCursor();
        node.addEventListener("mousedown", enablePokeCursor);
        node.addEventListener("mouseup", disablePokeCursor);
      } else {
        disablePokeCursor();
        node.style.cursor = "";
        node.removeEventListener("mousedown", enablePokeCursor);
        node.removeEventListener("mouseup", disablePokeCursor);
      }

      return () => {
        node.removeEventListener("mousedown", enablePokeCursor);
        node.removeEventListener("mouseup", disablePokeCursor);
      };
    }
  }, [gameStatus, isConnectedPlayer, nodeRef]);

  return nodeRef;
};
