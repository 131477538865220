import { Icon, IconProps } from "../Icon";

const View = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.9399 15.66C28.5359 9.4417 22.662 5.25053 15.9999 5C9.3378 5.25053 3.46386 9.4417 1.0599 15.66C0.980462 15.8797 0.980462 16.1203 1.0599 16.34C3.46386 22.5583 9.3378 26.7495 15.9999 27C22.662 26.7495 28.5359 22.5583 30.9399 16.34C31.0193 16.1203 31.0193 15.8797 30.9399 15.66ZM10.0003 16C10.0003 12.6863 12.6866 10 16.0003 10C19.314 10 22.0003 12.6863 22.0003 16C22.0003 19.3137 19.314 22 16.0003 22C12.6866 22 10.0003 19.3137 10.0003 16ZM12.0003 16C12.0003 18.2091 13.7912 20 16.0003 20C17.0612 20 18.0786 19.5786 18.8287 18.8284C19.5789 18.0783 20.0003 17.0609 20.0003 16C20.0003 13.7909 18.2095 12 16.0003 12C13.7912 12 12.0003 13.7909 12.0003 16ZM3.07008 16C5.10008 21.07 10.7001 25 16.0001 25C21.3001 25 26.9001 21.07 28.9301 16C26.9001 10.93 21.3001 7 16.0001 7C10.7001 7 5.10008 10.93 3.07008 16Z"
      fill="currentColor"
    />
  </Icon>
);

export default View;
