import { useCallback, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { getLocalStorageItem, setLocalStorageItem } from "../localStorage";
import { useOnWindowFocus } from "./useOnWindowFocus";

export const useLocalStorage = <T>(baseKey: string, scopeAccountKey = false) => {
  const { address } = useAccount();
  const lsKey = scopeAccountKey ? `${baseKey}_${address || ""}` : baseKey;
  const initialValue = getLocalStorageItem<T>(lsKey);
  const [currentValue, setCurrentValue] = useState<T | null>(initialValue);

  const getCurrentValue = useCallback(() => {
    const value = getLocalStorageItem<T>(lsKey);
    setCurrentValue(value);
  }, [lsKey]);

  const setValue = useCallback(
    (newValue: T) => {
      setLocalStorageItem(lsKey, newValue);
      setCurrentValue(newValue);
    },
    [lsKey]
  );

  useEffect(() => {
    getCurrentValue();
  }, [lsKey, getCurrentValue]);

  // Fetch current value from localStorage on window focus (the value may have been changed from another window)
  useOnWindowFocus(getCurrentValue);

  return { value: currentValue, setValue };
};
