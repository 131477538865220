import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const Stables = (props: IconProps) => {
  const [id] = useState(uniqueId());
  return (
    <Icon viewBox="0 0 20 20" {...props}>
      <g clipPath={`url(#${id})`}>
        <path
          d="M10 20C15.5417 20 20 15.5417 20 10C20 4.4583 15.5417 0 10 0C4.4583 0 0 4.4583 0 10C0 15.5417 4.4583 20 10 20Z"
          fill="#4B5565"
        />
        <path
          d="M14 12.0622C14 10.0291 12.7463 9.33198 10.2388 9.04157C8.44773 8.80916 8.08952 8.34447 8.08952 7.53111C8.08952 6.71774 8.68658 6.19506 9.88057 6.19506C10.9552 6.19506 11.5523 6.5436 11.8507 7.41497C11.9105 7.58924 12.0896 7.70538 12.2687 7.70538H13.2238C13.4627 7.70538 13.6418 7.53111 13.6418 7.29883V7.2407C13.4029 5.96265 12.3283 4.97514 10.9552 4.85901V3.46482C10.9552 3.23241 10.7761 3.05814 10.4776 3H9.58211C9.34326 3 9.16415 3.17427 9.1044 3.46482V4.80087C7.31335 5.03328 6.17911 6.19506 6.17911 7.64738C6.17911 9.56439 7.3731 10.3195 9.88057 10.61C11.5523 10.9004 12.0896 11.249 12.0896 12.1785C12.0896 13.108 11.2537 13.7469 10.1194 13.7469C8.56708 13.7469 8.02977 13.1079 7.85066 12.2365C7.79106 12.0042 7.61195 11.8879 7.43284 11.8879H6.41782C6.17911 11.8879 6 12.0622 6 12.2946V12.3528C6.23871 13.8049 7.19399 14.8506 9.16415 15.1411V16.5353C9.16415 16.7676 9.34326 16.9419 9.64172 17H10.5372C10.7761 17 10.9552 16.8257 11.015 16.5353V15.1411C12.806 14.8506 14 13.6307 14 12.0622Z"
          fill="#CCFD07"
        />
      </g>
      <defs>
        <clipPath id={id}>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default Stables;
