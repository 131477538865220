import { Address } from "viem";
import { graphql } from "@looksrare/utils";
import { graphql as generatedGql, MoDAssetPair, MoDContract, MoDFilterInput } from "@looksrare/yolo-games-gql-typegen";
import { getPythPriceAsFloat } from "../../utils/getPythPriceAsFloat";

export interface MoDPagination {
  first: number;
  cursor?: string;
}

export interface MoDRoundsInput {
  filter: MoDFilterInput;
  pagination?: MoDPagination;
  player?: Address;
}

export const getMoDRounds = async (input: MoDRoundsInput) => {
  const query = generatedGql(/* GraphQL */ `
    query MoDRounds($filter: MoDFilterInput!, $player: Address, $pagination: PaginationInput) {
      modRounds(filter: $filter, pagination: $pagination) {
        ...MoDRound
      }
    }
  `);

  const res = await graphql({
    query,
    params: {
      ...input,
    },
  });

  return res.modRounds.map(({ lockPrice, closePrice, ...round }) => ({
    ...round,
    lockPrice: lockPrice ? getPythPriceAsFloat(lockPrice) : undefined,
    closePrice: closePrice ? getPythPriceAsFloat(closePrice) : undefined,
  }));
};

export interface MoDRoundInput {
  contract: MoDContract;
  id?: number;
  player?: Address;
}

export const getMoDRound = async ({ contract, id, player }: MoDRoundInput) => {
  const query = generatedGql(/* GraphQL */ `
    query MoDRound($contract: MoDContract!, $id: Int, $player: Address) {
      modRound(contract: $contract, id: $id) {
        ...MoDRound
      }
    }
  `);

  const { modRound } = await graphql({
    query,
    params: {
      contract,
      id,
      player,
    },
  });

  if (!modRound) {
    return modRound;
  }

  return {
    ...modRound,
    lockPrice: modRound.lockPrice ? getPythPriceAsFloat(modRound.lockPrice) : undefined,
    closePrice: modRound.closePrice ? getPythPriceAsFloat(modRound.closePrice) : undefined,
  };
};

export const getMoDStats = async (contract: MoDContract) => {
  const query = generatedGql(/* GraphQL */ `
    query MoDStats($contract: MoDContract!) {
      modStats(contract: $contract) {
        ...MoDStats
      }
    }
  `);

  const res = await graphql({
    query,
    params: {
      contract,
    },
  });

  return res.modStats;
};

export const getMoDHistoricalPrices = async (pair: MoDAssetPair) => {
  const query = generatedGql(/* GraphQL */ `
    query MoDHistoricalPrices($pair: MoDAssetPair!) {
      modHistoricalPrices(pair: $pair) {
        timestamp
        price
      }
    }
  `);

  const res = await graphql({
    query,
    params: {
      pair,
    },
  });

  return res.modHistoricalPrices.map(({ timestamp, price }) => ({
    timestamp,
    price: getPythPriceAsFloat(price),
  }));
};

export const getMoDUnclaimedEntries = async (address: Address, contract: MoDContract) => {
  const query = generatedGql(/* GraphQL */ `
    query MoDUnclaimedEntries($address: Address!, $contract: MoDContract!) {
      user(address: $address) {
        ...MoDUnclaimedEntries
      }
    }
  `);

  const res = await graphql({
    query,
    params: {
      address,
      contract,
    },
  });

  return res.user?.modUnclaimedEntries;
};
