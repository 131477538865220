import { HStack, Stack, StackProps } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";
import { formatNumberToLocale, formatUsd } from "@looksrare/utils";
import { useTranslation } from "next-i18next";
import { MoDAssetPair, MoDRoundFragment } from "@looksrare/yolo-games-gql-typegen";
import { usePythPriceRealtime } from "../../../network/pyth/hooks";
import { getPriceChangeColors } from "../../../utils/getPriceChangeColor";
import { PriceChangeIndicator } from "../../../components/PriceChangeIndicator/PriceChangeIndicator";
import { YourEntry, YourEntryProps } from "../../../components/YourEntry/YourEntry";
import { RoundStats } from "../../../components/RoundStats/RoundStats";
import { ChartSwitcher } from "../../../components/ChartSwitcher/ChartSwitcher";
import { MoDDataPoint } from "./MoDDataPoint";

interface MoDDataBarProps extends StackProps {
  assetPair: MoDAssetPair;
  lockPrice: number;
  moonPayoutRatio: MoDRoundFragment["moonPayoutRatio"];
  doomPayoutRatio: MoDRoundFragment["doomPayoutRatio"];
  moonAmount: MoDRoundFragment["moonAmount"];
  doomAmount: MoDRoundFragment["doomAmount"];
  status: MoDRoundFragment["status"];
  entryType?: YourEntryProps["entryType"];
  entryAmountInEth?: YourEntryProps["entryAmount"];
}

export const MoDDataBar = ({
  assetPair,
  lockPrice,
  moonPayoutRatio,
  doomPayoutRatio,
  moonAmount,
  doomAmount,
  entryType,
  entryAmountInEth,
  ...props
}: MoDDataBarProps) => {
  const { t } = useTranslation();
  const currentPrice = usePythPriceRealtime(assetPair);

  const priceChange = currentPrice - lockPrice;
  const { color } = getPriceChangeColors(priceChange);

  const moonPayoutRatioDisplay =
    moonPayoutRatio !== undefined && moonPayoutRatio !== null
      ? `${formatNumberToLocale(Number(moonPayoutRatio), 0)}x`
      : "-x";

  const doomPayoutRatioDisplay =
    doomPayoutRatio !== undefined && doomPayoutRatio !== null
      ? `${formatNumberToLocale(Number(doomPayoutRatio), 0)}x`
      : "-x";

  return (
    <Stack direction={{ base: "column", md: "row" }} justifyContent="space-between" {...props}>
      <Stack spacing={3}>
        <Text textStyle="display-02" color={color} sx={{ fontFeatureSettings: '"tnum" on, "lnum" on' }} bold>
          {formatUsd(currentPrice)}
        </Text>

        <HStack spacing={2}>
          <PriceChangeIndicator startPrice={lockPrice} currentPrice={currentPrice} size={{ base: "xxs", sm: "md" }} />

          {!!entryType && !!entryAmountInEth && (
            <YourEntry
              entryType={entryType}
              entryAmount={entryAmountInEth}
              size="xxs"
              display={{ base: "flex", sm: "none" }}
            />
          )}
        </HStack>
      </Stack>

      <Stack spacing={4} display={{ base: "none", md: "flex" }}>
        <HStack justifyContent="space-between">
          <Text textStyle="body" bold>
            {t("Current Round")}
          </Text>
          <ChartSwitcher />
        </HStack>

        <RoundStats
          moonPayoutRatio={moonPayoutRatio}
          doomPayoutRatio={doomPayoutRatio}
          moonAmount={moonAmount}
          doomAmount={doomAmount}
        />
      </Stack>

      <HStack spacing={4} justifyContent="space-between" display={{ base: "flex", md: "none" }}>
        <MoDDataPoint value={formatUsd(lockPrice)} label={t("Open Price")} />
        <MoDDataPoint value={moonPayoutRatioDisplay} label={t("mod::MOON payout")} />
        <MoDDataPoint value={doomPayoutRatioDisplay} label={t("mod::DOOM payout")} />
      </HStack>
    </Stack>
  );
};
