import { Address } from "viem";
import { sub } from "date-fns";
import { XAxisLabelsOptions } from "highcharts";
import { type BigIntish, formatUsd, fromDecimals, useCoinPrices, roundToPreviousHour } from "@looksrare/utils";
import { Text, TextProps } from "../Text";

export type XYDataType = [number, number][];
export type VolumeDataType = XYDataType;
export type FloorDataType = {
  x: number;
  y: number;
  ohlc: {
    // OHLC data for tooltip even if it's a line chart
    open: number;
    high: number;
    low: number;
    close: number;
  };
}[];
export type ListingsDataType = XYDataType;
export type HoldersDataType = XYDataType;
export type FloorDepthDataType = XYDataType;
export type FloorOHLCDataType = [number, number, number, number, number][];
export type SalesDataType = [number, number, string][];
export type SentimentDataType = [number, number | null, number, number, number, number][];
export type SentimentTotalDataType = [number[], number[], number[]];
export type SegmentedSentimentDataType = [XYDataType, XYDataType, XYDataType];
export type PortfolioBreakdownDataType = {
  name: string; // collection name
  y: number;
  contractAddress: Address | null;
  count: number;
  portfolioCount: number;
  valueEth: bigint;
  portfolioValueEth: bigint;
}[];
export type PortfolioTrendDataType = XYDataType;

export type TimeRange = "12h" | "24h" | "3d" | "7d" | "14d" | "30d" | "3M" | "6M" | "1y";
export type TimeIntervalUnit = "1m" | "5m" | "30m" | "1h" | "2h" | "4h" | "1d";

export interface TimeRangeMap {
  timeRanges: TimeRange[];
  default: TimeRange;
  max: TimeRange;
}

export const FloorTimeRangeMap: TimeRangeMap = {
  timeRanges: ["24h", "7d", "30d", "3M", "6M"],
  default: "7d",
  max: "1y",
};

export const HoldersTimeRangeMap: TimeRangeMap = {
  timeRanges: ["24h", "7d", "30d", "6M"],
  default: "24h",
  max: "6M",
};

export const ListingsTimeRangeMap: TimeRangeMap = {
  timeRanges: ["24h", "7d", "30d", "6M"],
  default: "24h",
  max: "6M",
};

export const SalesTimeRangeMap: TimeRangeMap = {
  timeRanges: ["12h", "24h", "3d", "7d", "14d", "30d", "3M"],
  default: "12h",
  max: "3M",
};

export const SentimentTimeRangeMap: TimeRangeMap = {
  timeRanges: ["24h", "3d", "7d", "14d", "30d"],
  default: "7d",
  max: "30d",
};

export type ChartRange = {
  startTime: string;
  endTime: string;
};

export type PortfolioDonutChartViewMode = "quantity" | "valueEth";

export const getChartRange = (
  timeRange: TimeRange = "3d",
  now = roundToPreviousHour(new Date()) // now is rounded by default to hit cache
): ChartRange => {
  const endTime = now.toISOString();

  switch (timeRange) {
    case "1y":
      return {
        startTime: sub(now, { years: 1 }).toISOString(),
        endTime,
      };
    case "6M":
      return {
        startTime: sub(now, { months: 6 }).toISOString(),
        endTime,
      };
    case "3M":
      return {
        startTime: sub(now, { months: 3 }).toISOString(),
        endTime,
      };
    case "30d":
      return {
        startTime: sub(now, { days: 30 }).toISOString(),
        endTime,
      };
    case "14d":
      return {
        startTime: sub(now, { days: 14 }).toISOString(),
        endTime,
      };
    case "7d":
      return {
        startTime: sub(now, { days: 7 }).toISOString(),
        endTime,
      };
    case "3d":
      return {
        startTime: sub(now, { days: 3 }).toISOString(),
        endTime,
      };
    case "24h":
      return {
        startTime: sub(now, { hours: 24 }).toISOString(),
        endTime,
      };
    case "12h":
    default:
      return {
        startTime: sub(now, { hours: 12 }).toISOString(),
        endTime,
      };
  }
};

/**
 * Returns the time interval unit for given time range
 * The time interval unit is unit for one interval, or the time between each datapoint
 */
export const getTimeIntervalUnit = (timeRange: TimeRange = "3d"): TimeIntervalUnit => {
  switch (timeRange) {
    case "1y":
    case "6M":
    case "3M":
    case "30d":
      return "1d";
    case "14d":
    case "7d":
      return "2h";
    case "3d":
    case "24h":
    case "12h":
    default:
      return "1h";
  }
};

/**
 * Helper to return the correct time display based on the interval.
 * @see https://api.highcharts.com/class-reference/Highcharts.Time#dateFormat
 */
export const getXAxisFormat = (timeRange: TimeRange): XAxisLabelsOptions["format"] => {
  switch (timeRange) {
    case "1y": // Aug 29th
    case "6M":
    case "3M":
    case "30d":
    case "14d":
    case "7d":
    case "3d":
      return "{value:%b %e}";
    case "24h": // 13:00
    case "12h":
    default:
      return "{value:%k:%M}";
  }
};

/**
 * Formats x,y data points from Nft Go Api to a Highcharts compatible format.
 * @NOTE x is an array of timestamps in seconds, and y is the data point e.g. floor price
 * @param x - array of timestamps in seconds
 * @param y - array of data points
 * @param removeNegativeValues - if true, negative values will be replaced with 0
 * @param timestampUsesSeconds - if true, timestamps are in seconds, otherwise milliseconds
 */
export const formatXYToData = (
  x: number[],
  y: number[],
  removeNegativeValues = false,
  timestampUsesSeconds = true
): XYDataType => {
  return x.reduce<XYDataType>((accum, timestamp, index) => {
    const value = y[index] < 0 && removeNegativeValues ? 0 : y[index]; // @NOTE volume can be negative on NFTGo due to a bug
    const timestampValue = timestampUsesSeconds ? timestamp * 1000 : timestamp;
    accum.push([timestampValue, value]);
    return accum;
  }, []);
};

interface GetFloorDepthFromListingsDataReturn {
  floorDepth?: XYDataType;
  cumulativeFloorDepth?: XYDataType;
  stepRange?: number; // Used by the tooltip
  rangeStart?: number; // Used by the tooltip
}

/**
 * @param sortedData - an array of numbers sorted in ascending order
 * @param maxColumns - the maximum number of columns to return. The value will be the minimum of this and the length of the data
 */
export const getFloorDepthFromListingsData = (
  sortedData?: number[] | null,
  maxColumns = 20
): GetFloorDepthFromListingsDataReturn => {
  if (!sortedData || sortedData.length === 0) {
    return {
      floorDepth: undefined,
      cumulativeFloorDepth: undefined,
      stepRange: undefined,
      rangeStart: undefined,
    };
  }

  const intervalsCount = Math.min(sortedData.length, maxColumns);
  const minValue = sortedData[0];
  const maxValue = sortedData.at(-1)!; // Guaranteed to be non-empty
  const chartRange = maxValue - minValue;
  const stepRange = chartRange / (intervalsCount - 1);

  const floorDepth: [number, number][] = [[minValue, 0]];

  let currentThreshold = minValue;
  let currentColumnIndex = 0;

  sortedData.forEach((value) => {
    currentColumnIndex = floorDepth.length - 1;
    while (value > currentThreshold) {
      // Data is sorted, so if current value is beyond this interval, every other value will be.
      // S the current interval as empty and bump the threshold
      currentColumnIndex += 1;
      currentThreshold = currentColumnIndex * stepRange + minValue;
      floorDepth.push([currentThreshold, 0]);
    }
    // Value falls within the current interval, so increment the count
    floorDepth[currentColumnIndex][1]++;
  });

  // Data for the base of ladder chart: each value is the sum of all previous values
  const cumulativeFloorDepth = floorDepth.reduce<FloorDepthDataType>((accumulator, priceOccurrencesTuple, index) => {
    const [price] = priceOccurrencesTuple;
    const previousData = floorDepth[index - 1];
    const previousCumulatedData = accumulator[index - 1];
    // Ignore the first data point
    const prevCount = index === 0 ? 0 : previousData[1] + previousCumulatedData[1];

    accumulator.push([price, prevCount]);
    return accumulator;
  }, []);
  return { floorDepth, cumulativeFloorDepth, stepRange, rangeStart: minValue };
};

interface UsdAmountProps extends TextProps {
  valueWei?: BigIntish | null;
}
export const UsdAmount = ({ valueWei, ...props }: UsdAmountProps) => {
  const coinPriceQuery = useCoinPrices();
  const ethPriceUsd = coinPriceQuery.data ? coinPriceQuery.data.eth.price : 0;
  const currentValueInEth = !!valueWei ? fromDecimals(valueWei) : undefined;
  const valueInUsd = !!currentValueInEth ? parseFloat(currentValueInEth) * ethPriceUsd : undefined;
  return (
    <Text textAlign="right" textStyle="helper" color="text-03" {...props}>
      {valueInUsd && `(${formatUsd(valueInUsd)})`}
    </Text>
  );
};
