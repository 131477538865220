import { type Address, isAddressEqual } from "viem";
import qs from "qs";
import padStart from "lodash/padStart";
import { multiplyWeiByNumber, type BigIntish, divideWeiByNumber, fromDecimals, toDecimals } from "@looksrare/utils";
import { BASE_URL } from "./config";
import { type PtbCaveReturn, type PtbCave, type PtbPlayer } from "./graphql";
import { PtbRoundStatus, type PtbSupportedNetwork, type PtbContractName } from "./types";

/**
 * Returns the network name for a given PTB contract name
 */
export const getNetworkFromPtbContractName = (contractName: PtbContractName): PtbSupportedNetwork => {
  switch (contractName) {
    case "PTB_V1_BLAST":
      return "blast";
    case "PTB_V1_ARBITRUM":
      return "arbitrum";
    case "PTB_V1_BLAST":
      return "blast";
    case "PTB_V1":
    default:
      return "ethereum";
  }
};

/**
 * Returns the network name for a given PTB contract name
 */
export const getPtbContractNameFromNetwork = (network: PtbSupportedNetwork): PtbContractName => {
  switch (network) {
    case "arbitrum":
      return "PTB_V1_ARBITRUM";
    case "blast":
      return "PTB_V1_BLAST";
    case "ethereum":
    default:
      return "PTB_V1";
  }
};

/**
 * Helper utility to get player state
 */
export const getPlayerMeta = (playerAddress: Address, players: PtbPlayer[]) => {
  const ptbPlayer = players.find((player) => isAddressEqual(playerAddress, player.user.address));

  if (!ptbPlayer) {
    return { hasWon: false, hasLost: false, isPokingUntil: null };
  }

  const hasLost = !!ptbPlayer.lost;

  return {
    // User has survived if they haven't lost AND they have poked
    hasWon: !hasLost && !!ptbPlayer.poke?.pokedAt,
    hasLost,
    isPokingUntil: ptbPlayer.poke?.isPokingUntil || null,
  };
};

/**
 * Generate round meta helpers
 */
export const getRoundMeta = (roundStatus?: PtbRoundStatus) => {
  const isRoundOpen = roundStatus === PtbRoundStatus.OPEN;

  return {
    isRoundOpen,
    isRoundCancelled: roundStatus === PtbRoundStatus.CANCELLED,
    isRoundRevealed: roundStatus === PtbRoundStatus.REVEALED,
    isRoundDrawn: roundStatus === PtbRoundStatus.DRAWN,
    isRoundDrawing: roundStatus === PtbRoundStatus.DRAWING,
    isRoundNone: roundStatus === PtbRoundStatus.NONE,
  };
};

interface RoundUrlArgs {
  caveOnChainId: BigIntish;
  network: PtbSupportedNetwork;
  roundOnChainId?: BigIntish;
  queryParams?: Record<string, BigIntish>;
}

/**
 * Helper to construct a round url2
 */
export const getRoundUrl = ({ caveOnChainId, network, roundOnChainId, queryParams }: RoundUrlArgs) => {
  const baseCaveUrl = `${BASE_URL}/cave/${network}/${caveOnChainId}`;
  const queryStr = !!queryParams ? `?${qs.stringify(queryParams)}` : "";
  return !!roundOnChainId ? `${baseCaveUrl}/${roundOnChainId}${queryStr}` : `${baseCaveUrl}${queryStr}`;
};

/**
 * Returns the net amount each player contributes to the prize pool
 * e.g. 5000 LOOKS, 2.5% fee equals 4,875
 */
export const getNetEnterAmount = (enterAmount: BigIntish, protocolFeeBp: BigIntish = 0n) => {
  const enterAmountBI = BigInt(enterAmount);
  const protocolFeeAsPercentage = 1 - Number(protocolFeeBp) / 10_000;
  return multiplyWeiByNumber(enterAmountBI, protocolFeeAsPercentage);
};

/**
 * Returns net winnings
 * e.g. 5,000 LOOKS, 2.5% fee, 6 players equals 975 in net winnings
 */
export const getNetWinnings = (enterAmount: BigIntish, playersPerRound: number, protocolFeeBp: BigIntish = 0n) => {
  const netEnterAmount = getNetEnterAmount(enterAmount, protocolFeeBp);
  return divideWeiByNumber(netEnterAmount, playersPerRound - 1);
};

/**
 * Example: 5,000 entry, 250 BP fee, 6 players
 * 5000 * (1 - 0.025 ) / 5 + Principle
 */
export const getPrizePerPlayerConsideringFees = ({
  enterAmount,
  playersPerRound,
  protocolFeeBp = 0n,
}: Partial<PtbCave>) => {
  if (!enterAmount || !playersPerRound) {
    return 0n;
  }
  return BigInt(enterAmount) + getNetWinnings(enterAmount, playersPerRound, protocolFeeBp);
};

// @todo-ptbl2 Move these somewhere else, maybe looksrare/config
/**
 * Is arbitrum mainnet or sepolia chain id.
 */
export function isArbitrumChainId(id: number) {
  return id === 42161 || id === 421614;
}

/**
 * Is ethereum mainnet or sepolia chain id.
 */
export function isEthereumChainId(id: number) {
  return id === 1 || id === 11155111;
}

const defaultColor = { stroke: "text-03", text: "text-02" };
export const getStrokeColor2 = (secondsRemaining: number, isInActive: boolean) => {
  if (isInActive) {
    return defaultColor;
  }
  if (secondsRemaining <= 5) {
    return { stroke: "support-error", text: "support-error" };
  }
  if (secondsRemaining <= 10) {
    return { stroke: "support-warning", text: "support-warning" };
  }
  if (secondsRemaining <= 15) {
    return { stroke: "support-success", text: "support-success" };
  }
  return defaultColor;
};

export const padZero = (num: number) => padStart(num.toString(), 2, "0");

/**
 * Returns the value of LOOKS in ETH
 */
export const convertLooksToEth = (looksWei: bigint, ethPriceUsd: number, looksPriceUsd: number) => {
  if (ethPriceUsd === 0 || looksPriceUsd === 0) {
    return 0n;
  }

  const looksAsNum = parseFloat(fromDecimals(looksWei));
  const looksInUsd = looksAsNum * looksPriceUsd;
  const ethInLooks = looksInUsd / ethPriceUsd;

  return toDecimals(ethInLooks.toString());
};

export const getGradientBorder = (padding = 2, borderRadius = "button") => ({
  content: '""',
  position: "absolute",
  inset: 0,
  borderRadius,
  padding: `${padding}px`,
  zIndex: 0,
  background: "linear-gradient(85.93deg, #F8CC32 61.04%, #DF6D04 100%)",
  "-webkit-mask": "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
  "-webkit-mask-composite": "xor",
  maskComposite: "exclude",
});

/**
 * Sort caves by players joined and enter amount.
 */
export const getHotCaves = (caves?: PtbCaveReturn[], amountToShow = 3) => {
  if (!caves) {
    return [];
  }

  // Sort caves by slots left (e.g., 1/2) to favor larger, nearly full ones, and prioritize higher entry fees
  const sortByWeightedPlayerList = (a: PtbCaveReturn, b: PtbCaveReturn) => {
    const aPlayerCount = a.round?.players.length || 0.1;
    const aRoundsPlayed = Math.log(Number(a.round?.onChainId ?? "1"));
    const aValue = (aRoundsPlayed || 1) * (aPlayerCount / a.playersPerRound);

    const bPlayerCount = b.round?.players.length || 0.1;
    const bRoundsPlayed = Math.log(Number(b.round?.onChainId ?? "1"));
    const bValue = (bRoundsPlayed || 1) * (bPlayerCount / b.playersPerRound);

    return bValue - aValue;
  };

  const isEquivalentCave = (a: PtbCaveReturn, b: PtbCaveReturn) => {
    return a.enterAmount === b.enterAmount && a.playersPerRound === b.playersPerRound && a.currency === b.currency;
  };

  const hotCaves = caves
    .sort(sortByWeightedPlayerList)
    .map((hotCave) => {
      const hotCaveIsFull = hotCave.round?.players.length === hotCave.playersPerRound;

      // Remove caves which are full if they have a counterpart cave that is not
      // full, but shares the same parameters.
      if (hotCaveIsFull) {
        const replacementNotFullCave = caves
          .filter(
            (cave, index) =>
              !caves
                .slice(0, index + 1)
                .map(({ id }) => id)
                .includes(cave.id)
          )
          .filter((c) => isEquivalentCave(c, hotCave))
          .find((c) => c.round && c.round?.players.length < c.playersPerRound);

        return replacementNotFullCave ?? null;
      } else {
        return hotCave;
      }
    })
    .filter((x) => x !== null) as PtbCaveReturn[];

  // Remove caves that are the same as any previous cave to increase variety.
  // Only do this in the condition where the duplicate cave has one player or less.
  return hotCaves
    .filter((cave, i) => {
      const prevCaves = hotCaves.slice(0, i);
      const hasDuplicateInPrev = prevCaves.some((prev) => isEquivalentCave(prev, cave));
      const caveHasPlayer = cave.round?.players.length ?? 0;

      // IMPORTANT-NOTE
      // This algorithm was designed when every cave had 2-3 duplicates
      // this is no longer the case.
      //
      // We now have close to zero duplicates and so we want to make sure
      // we are always showing caves with players in them. If we go back to
      // having more duplicates we should only add back caves with players if
      // it's more than half full.
      if (hasDuplicateInPrev && !caveHasPlayer) {
        return false;
      } else {
        return true;
      }
    })
    .slice(0, amountToShow);
};
