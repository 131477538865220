import { Icon, IconProps } from "../Icon";

const GiftFilled = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 7.5C8 5.01472 10.0147 3 12.5 3C13.9136 3 15.175 3.65183 16 4.67134C16.825 3.65183 18.0864 3 19.5 3C21.9853 3 24 5.01472 24 7.5C24 8.42507 23.7209 9.28495 23.2422 10H26C27.1046 10 28 10.8954 28 12V14C28 15.1039 27.1057 15.9989 26.002 16L5.99807 16C4.89439 15.999 4 15.1039 4 14V12C4 10.8954 4.89543 10 6 10H8.75777C8.27914 9.28495 8 8.42507 8 7.5ZM15 10V7.5C15 6.11929 13.8807 5 12.5 5C11.1193 5 10 6.11929 10 7.5C10 8.88011 11.1183 9.99902 12.4982 10L15 10ZM19.5013 10C20.8814 9.99928 22 8.88027 22 7.5C22 6.11929 20.8807 5 19.5 5C18.1193 5 17 6.11929 17 7.5V10H19.5013Z"
      fill="currentColor"
    />
    <path d="M6 18L6 28C6 29.1046 6.89543 30 8 30H15V18L6 18Z" fill="currentColor" />
    <path d="M17 18V30H24C25.1046 30 26 29.1046 26 28V18H17Z" fill="currentColor" />
  </Icon>
);

export default GiftFilled;
