export const QuantumAbi = [
  {
    inputs: [
      { internalType: "address", name: "_gameConfigurationManager", type: "address" },
      { internalType: "address", name: "_transferManager", type: "address" },
      { internalType: "address", name: "_weth", type: "address" },
      { internalType: "address", name: "_vrfCoordinator", type: "address" },
      { internalType: "address", name: "_blast", type: "address" },
      { internalType: "address", name: "_usdb", type: "address" },
      { internalType: "address", name: "_owner", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  { inputs: [], name: "ERC20TransferFail", type: "error" },
  { inputs: [], name: "Game__InexactNativeTokensSupplied", type: "error" },
  { inputs: [], name: "Game__InvalidMultiplier", type: "error" },
  { inputs: [], name: "Game__InvalidStops", type: "error" },
  { inputs: [], name: "Game__InvalidValue", type: "error" },
  { inputs: [], name: "Game__LiquidityPoolConnected", type: "error" },
  { inputs: [], name: "Game__NoLiquidityPool", type: "error" },
  { inputs: [], name: "Game__NoOngoingRound", type: "error" },
  { inputs: [], name: "Game__OngoingRound", type: "error" },
  { inputs: [], name: "Game__PlayAmountPerRoundTooHigh", type: "error" },
  { inputs: [], name: "Game__PlayAmountPerRoundTooLow", type: "error" },
  { inputs: [], name: "Game__TooEarlyForARefund", type: "error" },
  { inputs: [], name: "Game__TooManyRounds", type: "error" },
  { inputs: [], name: "Game__WrongVrfCoordinator", type: "error" },
  { inputs: [], name: "Game__ZeroMultiplier", type: "error" },
  { inputs: [], name: "Game__ZeroNumberOfRounds", type: "error" },
  { inputs: [], name: "Game__ZeroPlayAmountPerRound", type: "error" },
  { inputs: [], name: "NoOngoingTransferInProgress", type: "error" },
  { inputs: [], name: "NotAContract", type: "error" },
  { inputs: [], name: "NotOwner", type: "error" },
  {
    inputs: [
      { internalType: "address", name: "have", type: "address" },
      { internalType: "address", name: "want", type: "address" },
    ],
    name: "OnlyCoordinatorCanFulfill",
    type: "error",
  },
  { inputs: [], name: "ReentrancyFail", type: "error" },
  { inputs: [], name: "RenouncementNotInProgress", type: "error" },
  { inputs: [], name: "TransferAlreadyInProgress", type: "error" },
  { inputs: [], name: "TransferNotInProgress", type: "error" },
  { inputs: [], name: "WrongPotentialOwner", type: "error" },
  { anonymous: false, inputs: [], name: "CancelOwnershipTransfer", type: "event" },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint256", name: "blockNumber", type: "uint256" },
      { indexed: false, internalType: "address", name: "player", type: "address" },
      { indexed: false, internalType: "uint256", name: "totalPlayAmount", type: "uint256" },
    ],
    name: "Game__Refunded",
    type: "event",
  },
  { anonymous: false, inputs: [], name: "InitiateOwnershipRenouncement", type: "event" },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "address", name: "previousOwner", type: "address" },
      { indexed: false, internalType: "address", name: "potentialOwner", type: "address" },
    ],
    name: "InitiateOwnershipTransfer",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: "address", name: "newOwner", type: "address" }],
    name: "NewOwner",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint256", name: "blockNumber", type: "uint256" },
      { indexed: false, internalType: "address", name: "player", type: "address" },
      { indexed: false, internalType: "uint256[]", name: "results", type: "uint256[]" },
      { indexed: false, internalType: "uint256[]", name: "payouts", type: "uint256[]" },
      { indexed: false, internalType: "uint256", name: "numberOfRoundsPlayed", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "protocolFee", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "liquidityPoolFee", type: "uint256" },
    ],
    name: "Quantum__GameCompleted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint256", name: "blockNumber", type: "uint256" },
      { indexed: false, internalType: "address", name: "player", type: "address" },
      { indexed: false, internalType: "uint256", name: "numberOfRounds", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "playAmountPerRound", type: "uint256" },
      { indexed: false, internalType: "address", name: "currency", type: "address" },
      { indexed: false, internalType: "int256", name: "stopGain", type: "int256" },
      { indexed: false, internalType: "int256", name: "stopLoss", type: "int256" },
      { indexed: false, internalType: "bool", name: "isAbove", type: "bool" },
      { indexed: false, internalType: "uint256", name: "multiplier", type: "uint256" },
    ],
    name: "Quantum__GameCreated",
    type: "event",
  },
  {
    inputs: [],
    name: "GAME_CONFIGURATION_MANAGER",
    outputs: [{ internalType: "contract IGameConfigurationManager", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "TRANSFER_MANAGER",
    outputs: [{ internalType: "contract ITransferManager", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "multiplier", type: "uint256" }],
    name: "calculateWinProbability",
    outputs: [{ internalType: "uint256", name: "winProbability", type: "uint256" }],
    stateMutability: "pure",
    type: "function",
  },
  { inputs: [], name: "cancelOwnershipTransfer", outputs: [], stateMutability: "nonpayable", type: "function" },
  {
    inputs: [{ internalType: "address", name: "receiver", type: "address" }],
    name: "claimYield",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  { inputs: [], name: "confirmOwnershipRenouncement", outputs: [], stateMutability: "nonpayable", type: "function" },
  { inputs: [], name: "confirmOwnershipTransfer", outputs: [], stateMutability: "nonpayable", type: "function" },
  {
    inputs: [{ internalType: "uint256", name: "winProbability", type: "uint256" }],
    name: "defineBoundary",
    outputs: [{ internalType: "uint256", name: "boundary", type: "uint256" }],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "player", type: "address" }],
    name: "games",
    outputs: [
      {
        components: [
          { internalType: "uint40", name: "blockNumber", type: "uint40" },
          { internalType: "uint40", name: "randomnessRequestedAt", type: "uint40" },
          { internalType: "uint16", name: "numberOfRounds", type: "uint16" },
          { internalType: "address", name: "currency", type: "address" },
          { internalType: "uint256", name: "playAmountPerRound", type: "uint256" },
          { internalType: "int256", name: "stopGain", type: "int256" },
          { internalType: "int256", name: "stopLoss", type: "int256" },
          { internalType: "uint256", name: "vrfFee", type: "uint256" },
        ],
        internalType: "struct Game.Game__GameParams",
        name: "params",
        type: "tuple",
      },
      { internalType: "bool", name: "isAbove", type: "bool" },
      { internalType: "uint248", name: "multiplier", type: "uint248" },
    ],
    stateMutability: "view",
    type: "function",
  },
  { inputs: [], name: "initiateOwnershipRenouncement", outputs: [], stateMutability: "nonpayable", type: "function" },
  {
    inputs: [{ internalType: "address", name: "newPotentialOwner", type: "address" }],
    name: "initiateOwnershipTransfer",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "multiplier", type: "uint256" }],
    name: "kellyFraction",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "currency", type: "address" },
      { internalType: "uint256", name: "multiplier", type: "uint256" },
    ],
    name: "maxPlayAmountPerGame",
    outputs: [{ internalType: "uint256", name: "maxPlayAmount", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "currency", type: "address" },
      { internalType: "uint256", name: "multiplier", type: "uint256" },
    ],
    name: "minPlayAmountPerGame",
    outputs: [{ internalType: "uint256", name: "minPlayAmount", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "ownershipStatus",
    outputs: [{ internalType: "enum IOwnableTwoSteps.Status", name: "", type: "uint8" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint16", name: "numberOfRounds", type: "uint16" },
      { internalType: "uint256", name: "playAmountPerRound", type: "uint256" },
      { internalType: "address", name: "currency", type: "address" },
      { internalType: "int256", name: "stopGain", type: "int256" },
      { internalType: "int256", name: "stopLoss", type: "int256" },
      { internalType: "bool", name: "isAbove", type: "bool" },
      { internalType: "uint248", name: "multiplier", type: "uint248" },
    ],
    name: "play",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "potentialOwner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "requestId", type: "uint256" }],
    name: "randomnessRequests",
    outputs: [{ internalType: "address", name: "requester", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "requestId", type: "uint256" },
      { internalType: "uint256[]", name: "randomWords", type: "uint256[]" },
    ],
    name: "rawFulfillRandomWords",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  { inputs: [], name: "refund", outputs: [], stateMutability: "nonpayable", type: "function" },
] as const;
