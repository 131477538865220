import { useCallback } from "react";
import { useRouter } from "next/router";
import qs from "qs";

/**
 * Helper hook to get and set URL params. Redirects on params change. Only works with simple string params (e.g. stringified arrays won't work).
 */
export const useURLParams = () => {
  const { replace, asPath, query } = useRouter();
  const queryString = decodeURI(asPath).split("?")[1];

  // Simple key-value object
  type Params = { [key: string]: string | undefined };
  const params: Params = qs.parse(queryString) as Params;
  const setParams = useCallback(
    (queryCreator: (currentParams: Params) => Params) => {
      const newQuery = queryCreator({ ...query, ...params } as Params);
      replace({ query: newQuery }, undefined, { shallow: true });
    },
    [params, query, replace]
  );

  const removeParam = useCallback(
    (key: string) => {
      if (!params[key]) {
        return;
      }
      const newQuery = { ...query, ...params } as Params;
      delete newQuery[key];
      replace({ query: newQuery }, undefined, { shallow: true });
    },
    [params, query, replace]
  );

  return { params, setParams, removeParam };
};
