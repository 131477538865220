import { AutoTransactionStepRow, TransactionSetter, useHandleModalStep } from "@looksrare/uikit";
import { usePublicClient } from "wagmi";
import { NoPublicClientError } from "@looksrare/utils";
import { MoDContract } from "@looksrare/yolo-games-gql-typegen";
import { useClaimEntries } from "../../../network/contract/write/hooks";

interface ClaimTransactionStepRowProps {
  contract: MoDContract;
  onChainIds: number[];
  ctaText: string;
  isStepActive: boolean;
  onComplete: () => void;
}

export const ClaimTransactionStepRow = ({
  contract,
  onChainIds,
  ctaText,
  isStepActive,
  onComplete,
}: ClaimTransactionStepRowProps) => {
  const claimEntries = useClaimEntries(contract);
  const publicClient = usePublicClient();

  const useHandleTransaction = (setTransaction: TransactionSetter) => {
    return useHandleModalStep({
      onSubmit: async () => {
        if (!publicClient) {
          throw new NoPublicClientError();
        }

        const hash = await claimEntries(onChainIds.map((id) => BigInt(id)));
        setTransaction(hash);
        const receipt = await publicClient.waitForTransactionReceipt({ hash });

        if (receipt.status === "success") {
          setTransaction(undefined);
        } else {
          throw new Error(`Claiming entries failed. Transaction hash ${receipt.transactionHash}`);
        }

        onComplete();
      },
    });
  };

  return (
    <AutoTransactionStepRow useHandleTransaction={useHandleTransaction} ctaText={ctaText} isStepActive={isStepActive} />
  );
};
