/* TODO pick one between this and `useHandleModalStep` */
import { Hash, TransactionReceipt } from "viem";
import noop from "lodash/noop";
import { usePublicClient } from "wagmi";
import { useCallback, useState } from "react";

interface State {
  isTxSending: boolean;
  isTxWaiting: boolean;
  isTxError: boolean;
  isTxConfirmed: boolean;
  txReceipt: TransactionReceipt | null | undefined;
  txResponse: Hash | null | undefined;
  txError?: any;
}

type AnyArgs = Record<any, any>;

export type TransactionHandler<T = AnyArgs> = State & {
  submitTransaction: (args?: T) => Promise<void>;
};

const initialState: State = {
  isTxSending: false,
  isTxWaiting: false,
  isTxError: false,
  isTxConfirmed: false,
  txReceipt: null,
  txResponse: null,
  txError: null,
};

interface SubmitTransaction<T> {
  onSend: (args: T) => Promise<Hash | null | undefined>;
  onSuccess?: (txReceipt: TransactionReceipt, args: T) => void;
  onError?: (error: any, args: T) => void;
}

/**
 * Helper when submitting on-chain write transactions
 *
 * @param onSend A function returning the contract method
 * @returns
 */
export const useSubmitTransaction = <T = AnyArgs>({
  onSend,
  onSuccess = noop,
  onError = noop,
}: SubmitTransaction<T>): TransactionHandler<T> => {
  const publicClient = usePublicClient();
  const [state, setState] = useState<State>(initialState);

  const submitTransaction = useCallback(
    async (handlerArgs: any) => {
      if (!publicClient) {
        throw new Error("No public client found");
      }
      //@TODO refine type if deemed necessary
      try {
        setState({ ...initialState, isTxSending: true });
        const hash = await onSend(handlerArgs);

        if (!hash) {
          setState({
            isTxSending: false,
            isTxConfirmed: true,
            isTxWaiting: false,
            isTxError: true,
            txError: null,
            txResponse: null,
            txReceipt: null,
          });
        }

        setState((prevState) => ({
          ...prevState,
          isTxSending: false,
          isTxWaiting: true,
          txResponse: hash,
        }));

        const txReceipt = await publicClient.waitForTransactionReceipt({ hash: hash! });
        if (txReceipt.status === "success") {
          setState({
            isTxSending: false,
            isTxConfirmed: true,
            isTxWaiting: false,
            isTxError: false,
            txError: null,
            txResponse: hash,
            txReceipt,
          });
          onSuccess(txReceipt, handlerArgs);
        } else {
          setState({
            isTxSending: false,
            isTxConfirmed: true,
            isTxWaiting: false,
            isTxError: true,
            txError: null,
            txResponse: hash,
            txReceipt,
          });
        }
      } catch (error) {
        setState({
          isTxSending: false,
          isTxConfirmed: false,
          isTxWaiting: false,
          isTxError: true,
          txReceipt: null,
          txResponse: null,
          txError: error,
        });
        onError(error, handlerArgs);
      }
    },
    [onSend, onSuccess, onError, publicClient]
  );

  return { ...state, submitTransaction };
};
