import { useCallback, useMemo } from "react";
import { Address } from "viem";
import { useMutation, UseMutationOptions, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAccount } from "wagmi";
import { add } from "date-fns";
import { RQueryOptions, RQueryOptionsForFetcher } from "@looksrare/utils";
import {
  CurrentPointsSeason,
  PointsCurrentSeasonLeaderboard,
  OffsetPagination,
  MilestoneLevelCode,
  DailyQuestCode,
  UserMilestones,
  UserDailyQuests,
} from "@/types";
import {
  getCurrentPointsSeason,
  getCurrentSeasonLeaderboard,
  getPointsSeasonDetails,
  getHistoricSeasonLeaderboard,
  getUserSeasonPoints,
  getUserSeasonRewards,
  LeaderboardSortInput,
  getUserMilestones,
  claimMilestoneLevel,
  claimDailyQuest,
  getUserDailyQuests,
  getCurrentEpochLeaderboard,
} from "@/queries/points";
import { baseQueryKeys } from "@/config";

/**
 * @todo-yg Replace "Gems" with "Points"
 */
export const gemsKeys = {
  ...baseQueryKeys("gems"),
  userSeasonPoints: (address: Address) => [...gemsKeys.single(), "UserSeasonPoints", address],
  userSeasonRewards: (address: Address, season: number) => [...gemsKeys.single(), "season-rewards", address, season],
  pointsSeason: (season?: number) => [...gemsKeys.single(), "pointsSeason", season],
  pointsSeasonLeaderboard: ({ pagination, sort }: Partial<UseCurrentPointsSeasonLeaderboardParams>) => [
    ...gemsKeys.infiniteQueries(),
    "pointsSeasonLeaderboard",
    pagination,
    sort,
  ],
  epochLeaderboard: ({ pagination }: Partial<UseCurrentEpochLeaderboardParams>) => [
    ...gemsKeys.infiniteQueries(),
    "epochLeaderboard",
    pagination,
  ],
  historicSeasonLeaderboard: ({ pagination, season }: Partial<UseHistoricPointsSeasonLeaderboardParams>) => [
    ...gemsKeys.infiniteQueries(),
    "historicSeasonLeaderboard",
    season,
    pagination,
  ],
  userMilestones: (address: Address) => [...gemsKeys.single(), "userMilestones", address],
  userDailyQuests: (address?: string) => [...gemsKeys.single(), "userDailyQuests", address],
};

export const useUserSeasonPoints = (
  address: Address,
  queryOptions?: RQueryOptionsForFetcher<typeof getUserSeasonPoints>
) => {
  return useQuery({
    queryKey: gemsKeys.userSeasonPoints(address),
    queryFn: () => getUserSeasonPoints(address),
    refetchOnWindowFocus: false,
    staleTime: 15 * 1000,
    refetchInterval: 30 * 1000,
    ...queryOptions,
  });
};

export const useCurrentPointsSeason = (queryOptions?: RQueryOptions<CurrentPointsSeason>) => {
  return useQuery({
    queryKey: gemsKeys.pointsSeason(),
    queryFn: () => getCurrentPointsSeason(),
    refetchOnWindowFocus: false,
    ...queryOptions,
  });
};

export const usePointsSeason = (
  season: number,
  queryOptions?: RQueryOptionsForFetcher<typeof getPointsSeasonDetails>
) => {
  return useQuery({
    queryKey: gemsKeys.pointsSeason(season),
    queryFn: () => getPointsSeasonDetails(season),
    refetchOnWindowFocus: false,
    ...queryOptions,
  });
};

export const useInvalidatePointsSeason = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (season: number) => {
      queryClient.invalidateQueries({ queryKey: gemsKeys.pointsSeason(season) });
    },
    [queryClient]
  );
};

export const LISTING_SEASON_PER_PAGE = 20;

interface UseCurrentPointsSeasonLeaderboardParams {
  pagination?: OffsetPagination;
  sort?: LeaderboardSortInput;
}
export const useCurrentPointsSeasonLeaderboard = (
  { pagination, sort = LeaderboardSortInput.DAILY_RANK_ASC }: UseCurrentPointsSeasonLeaderboardParams,
  queryOptions?: RQueryOptions<PointsCurrentSeasonLeaderboard>
) => {
  const { offset = 0, first = LISTING_SEASON_PER_PAGE } = pagination || {};
  return useQuery({
    queryKey: gemsKeys.pointsSeasonLeaderboard({ pagination, sort }),
    queryFn: () => getCurrentSeasonLeaderboard({ pagination: { offset, first }, sort }),
    refetchOnWindowFocus: false,
    ...queryOptions,
  });
};

interface UseCurrentEpochLeaderboardParams {
  pagination?: OffsetPagination;
}
export const useCurrentEpochLeaderboard = (
  { pagination }: UseCurrentEpochLeaderboardParams,
  queryOptions?: RQueryOptions<PointsCurrentSeasonLeaderboard>
) => {
  const { offset = 0, first = LISTING_SEASON_PER_PAGE } = pagination || {};
  return useQuery({
    queryKey: gemsKeys.epochLeaderboard({ pagination }),
    queryFn: () => getCurrentEpochLeaderboard({ pagination: { offset, first } }),
    refetchOnWindowFocus: false,
    ...queryOptions,
  });
};

interface UseHistoricPointsSeasonLeaderboardParams {
  pagination?: OffsetPagination;
  season: number;
}
export const useHistoricPointsSeasonLeaderboard = (
  { pagination, season }: UseHistoricPointsSeasonLeaderboardParams,
  queryOptions?: RQueryOptionsForFetcher<typeof getHistoricSeasonLeaderboard>
) => {
  const { offset = 0, first = LISTING_SEASON_PER_PAGE } = pagination || {};
  return useQuery({
    queryKey: gemsKeys.historicSeasonLeaderboard({ pagination, season }),
    queryFn: () => getHistoricSeasonLeaderboard({ pagination: { offset, first }, season }),
    refetchOnWindowFocus: false,
    ...queryOptions,
  });
};

export const useUserSeasonRewards = (
  address: Address,
  season: number,
  queryOptions?: RQueryOptionsForFetcher<typeof getUserSeasonRewards>
) => {
  return useQuery({
    queryKey: gemsKeys.userSeasonRewards(address, season),
    queryFn: async () => getUserSeasonRewards(address, season),
    ...queryOptions,
  });
};

export const useInvalidateUserSeasonRewards = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (address: Address, season: number) => {
      queryClient.invalidateQueries({ queryKey: gemsKeys.userSeasonRewards(address, season) });
    },
    [queryClient]
  );
};

interface UseClaimableSeasonNumberReturn {
  claimableSeason: number | null;
  isLoading: boolean;
  isFetching: boolean;
}
/**
 *  Centralized logic for determining which season is claimable
 *  @returns  {
 *   claimableSeason: number | null - Seasons start at 1
 *   isLoading: boolean
 *   isFetching: boolean
 *  }
 */
export const useClaimableSeasonNumber = (): UseClaimableSeasonNumberReturn => {
  const { data: currentSeasonData, isLoading, isFetching } = useCurrentPointsSeason();

  return useMemo(() => {
    let claimableSeason: number | null = null;

    if (currentSeasonData) {
      // A season is claimable 1 hour after it ends, to allow some time for offline tasks
      const now = new Date();
      const currentSeasonClaimStart = add(new Date(currentSeasonData.endTime), { hours: 1 });
      const isCurrentSeasonClaimingStarted = now > currentSeasonClaimStart;

      if (isCurrentSeasonClaimingStarted) {
        claimableSeason = currentSeasonData.season;
      } else {
        // If current season is not over, then the previous season is claimable. Seasons start at 1
        claimableSeason = Math.max(1, currentSeasonData.season - 1);
      }
    }

    return { claimableSeason, isLoading, isFetching };
  }, [currentSeasonData, isFetching, isLoading]);
};

export const useUserMilestones = (address: Address, queryOptions?: RQueryOptions<UserMilestones>) => {
  return useQuery({
    queryKey: gemsKeys.userMilestones(address),
    queryFn: async () => getUserMilestones(address),
    refetchOnWindowFocus: false,
    ...queryOptions,
  });
};

export const useUserDailyQuests = (address?: string, queryOptions?: RQueryOptions<UserDailyQuests>) => {
  return useQuery({
    queryKey: gemsKeys.userDailyQuests(address),
    queryFn: async () => getUserDailyQuests(address),
    refetchOnWindowFocus: false,
    ...queryOptions,
  });
};

export const useClaimMilestoneLevel = (options?: UseMutationOptions<boolean, any, MilestoneLevelCode>) => {
  const { address } = useAccount();
  const queryClient = useQueryClient();
  const { onSuccess: origOnSuccess, ...others } = options || {};

  return useMutation({
    mutationFn: (code: MilestoneLevelCode) => {
      if (!address) {
        throw new Error("No wallet connected");
      }
      return claimMilestoneLevel({ code, connectedAddress: address });
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: gemsKeys.userMilestones(address!) });
      queryClient.invalidateQueries({ queryKey: gemsKeys.userSeasonPoints(address!) });
      origOnSuccess && origOnSuccess(data, variables, context);
    },
    ...others,
  });
};

export const useClaimDailyQuest = (options?: UseMutationOptions<boolean, any, DailyQuestCode>) => {
  const { address } = useAccount();
  const queryClient = useQueryClient();
  const { onSuccess: origOnSuccess, ...others } = options || {};

  return useMutation({
    mutationFn: (code: DailyQuestCode) => {
      if (!address) {
        throw new Error("No wallet connected");
      }
      return claimDailyQuest({ code, connectedAddress: address });
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: gemsKeys.userDailyQuests(address) });
      queryClient.invalidateQueries({ queryKey: gemsKeys.userSeasonPoints(address!) });
      origOnSuccess && origOnSuccess(data, variables, context);
    },
    ...others,
  });
};
