export const YoloPlayAbi = [
  {
    type: "constructor",
    inputs: [
      {
        name: "params",
        type: "tuple",
        internalType: "struct IYoloPlay.ConstructorCalldata",
        components: [
          { name: "owner", type: "address", internalType: "address" },
          { name: "operator", type: "address", internalType: "address" },
          { name: "maximumNumberOfParticipantsPerRound", type: "uint40", internalType: "uint40" },
          { name: "roundDuration", type: "uint40", internalType: "uint40" },
          { name: "valuePerEntry", type: "uint96", internalType: "uint96" },
          { name: "keyHash", type: "bytes32", internalType: "bytes32" },
          { name: "subscriptionId", type: "uint64", internalType: "uint64" },
          { name: "vrfCoordinator", type: "address", internalType: "address" },
          { name: "transferManager", type: "address", internalType: "address" },
          { name: "boost", type: "address", internalType: "address" },
          { name: "minimumRequestConfirmations", type: "uint16", internalType: "uint16" },
          { name: "blast", type: "address", internalType: "address" },
        ],
      },
    ],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "BOOST",
    inputs: [],
    outputs: [{ name: "", type: "address", internalType: "address" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "DEFAULT_ADMIN_ROLE",
    inputs: [],
    outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "cancel",
    inputs: [{ name: "numberOfRounds", type: "uint256", internalType: "uint256" }],
    outputs: [],
    stateMutability: "nonpayable",
  },
  { type: "function", name: "cancel", inputs: [], outputs: [], stateMutability: "nonpayable" },
  {
    type: "function",
    name: "cancelAfterRandomnessRequest",
    inputs: [],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "claimPrizes",
    inputs: [
      {
        name: "withdrawalCalldata",
        type: "tuple[]",
        internalType: "struct IYoloPlay.WithdrawalCalldata[]",
        components: [
          { name: "roundId", type: "uint256", internalType: "uint256" },
          { name: "depositIndices", type: "uint256[]", internalType: "uint256[]" },
        ],
      },
    ],
    outputs: [],
    stateMutability: "payable",
  },
  {
    type: "function",
    name: "deposit",
    inputs: [
      { name: "roundId", type: "uint256", internalType: "uint256" },
      {
        name: "deposits",
        type: "tuple[]",
        internalType: "struct IYoloPlay.DepositCalldata[]",
        components: [
          { name: "tokenType", type: "uint8", internalType: "enum IYoloPlay.YoloPlay__TokenType" },
          { name: "tokenAddress", type: "address", internalType: "address" },
          { name: "tokenIdsOrAmounts", type: "uint256[]", internalType: "uint256[]" },
        ],
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "depositCount",
    inputs: [
      { name: "roundId", type: "uint256", internalType: "uint256" },
      { name: "depositor", type: "address", internalType: "address" },
    ],
    outputs: [{ name: "depositCount", type: "uint256", internalType: "uint256" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "depositETHIntoMultipleRounds",
    inputs: [
      { name: "startingRoundId", type: "uint256", internalType: "uint256" },
      { name: "amounts", type: "uint256[]", internalType: "uint256[]" },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  { type: "function", name: "drawWinner", inputs: [], outputs: [], stateMutability: "nonpayable" },
  {
    type: "function",
    name: "getRoleAdmin",
    inputs: [{ name: "role", type: "bytes32", internalType: "bytes32" }],
    outputs: [{ name: "", type: "bytes32", internalType: "bytes32" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "getRound",
    inputs: [{ name: "roundId", type: "uint256", internalType: "uint256" }],
    outputs: [
      { name: "status", type: "uint8", internalType: "enum IYoloPlay.RoundStatus" },
      { name: "maximumNumberOfParticipants", type: "uint40", internalType: "uint40" },
      { name: "roundProtocolFeeBp", type: "uint16", internalType: "uint16" },
      { name: "cutoffTime", type: "uint40", internalType: "uint40" },
      { name: "drawnAt", type: "uint40", internalType: "uint40" },
      { name: "numberOfParticipants", type: "uint40", internalType: "uint40" },
      { name: "winner", type: "address", internalType: "address" },
      { name: "roundValuePerEntry", type: "uint96", internalType: "uint96" },
      { name: "protocolFeeOwed", type: "uint256", internalType: "uint256" },
      {
        name: "deposits",
        type: "tuple[]",
        internalType: "struct IYoloPlay.Deposit[]",
        components: [
          { name: "tokenType", type: "uint8", internalType: "enum IYoloPlay.YoloPlay__TokenType" },
          { name: "tokenAddress", type: "address", internalType: "address" },
          { name: "tokenId", type: "uint256", internalType: "uint256" },
          { name: "tokenAmount", type: "uint256", internalType: "uint256" },
          { name: "depositor", type: "address", internalType: "address" },
          { name: "withdrawn", type: "bool", internalType: "bool" },
          { name: "currentEntryIndex", type: "uint40", internalType: "uint40" },
        ],
      },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "grantRole",
    inputs: [
      { name: "role", type: "bytes32", internalType: "bytes32" },
      { name: "account", type: "address", internalType: "address" },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "hasRole",
    inputs: [
      { name: "role", type: "bytes32", internalType: "bytes32" },
      { name: "account", type: "address", internalType: "address" },
    ],
    outputs: [{ name: "", type: "bool", internalType: "bool" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "maximumNumberOfParticipantsPerRound",
    inputs: [],
    outputs: [{ name: "", type: "uint40", internalType: "uint40" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "outflowAllowed",
    inputs: [],
    outputs: [{ name: "", type: "bool", internalType: "bool" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "paused",
    inputs: [],
    outputs: [{ name: "", type: "bool", internalType: "bool" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "randomnessRequests",
    inputs: [{ name: "requestId", type: "uint256", internalType: "uint256" }],
    outputs: [
      { name: "exists", type: "bool", internalType: "bool" },
      { name: "roundId", type: "uint40", internalType: "uint40" },
      { name: "randomWord", type: "uint256", internalType: "uint256" },
    ],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "rawFulfillRandomWords",
    inputs: [
      { name: "requestId", type: "uint256", internalType: "uint256" },
      { name: "randomWords", type: "uint256[]", internalType: "uint256[]" },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "renounceRole",
    inputs: [
      { name: "role", type: "bytes32", internalType: "bytes32" },
      { name: "account", type: "address", internalType: "address" },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "revokeRole",
    inputs: [
      { name: "role", type: "bytes32", internalType: "bytes32" },
      { name: "account", type: "address", internalType: "address" },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "rolloverETH",
    inputs: [
      { name: "roundId", type: "uint256", internalType: "uint256" },
      {
        name: "withdrawalCalldata",
        type: "tuple[]",
        internalType: "struct IYoloPlay.WithdrawalCalldata[]",
        components: [
          { name: "roundId", type: "uint256", internalType: "uint256" },
          { name: "depositIndices", type: "uint256[]", internalType: "uint256[]" },
        ],
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "roundDuration",
    inputs: [],
    outputs: [{ name: "", type: "uint40", internalType: "uint40" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "roundsCount",
    inputs: [],
    outputs: [{ name: "", type: "uint40", internalType: "uint40" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "supportsInterface",
    inputs: [{ name: "interfaceId", type: "bytes4", internalType: "bytes4" }],
    outputs: [{ name: "", type: "bool", internalType: "bool" }],
    stateMutability: "view",
  },
  { type: "function", name: "toggleOutflowAllowed", inputs: [], outputs: [], stateMutability: "nonpayable" },
  { type: "function", name: "togglePaused", inputs: [], outputs: [], stateMutability: "nonpayable" },
  {
    type: "function",
    name: "updateMaximumNumberOfParticipantsPerRound",
    inputs: [{ name: "_maximumNumberOfParticipantsPerRound", type: "uint40", internalType: "uint40" }],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "updateRoundDuration",
    inputs: [{ name: "_roundDuration", type: "uint40", internalType: "uint40" }],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "updateValuePerEntry",
    inputs: [{ name: "_valuePerEntry", type: "uint96", internalType: "uint96" }],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "function",
    name: "valuePerEntry",
    inputs: [],
    outputs: [{ name: "", type: "uint96", internalType: "uint96" }],
    stateMutability: "view",
  },
  {
    type: "function",
    name: "withdrawDeposits",
    inputs: [
      {
        name: "withdrawalCalldata",
        type: "tuple[]",
        internalType: "struct IYoloPlay.WithdrawalCalldata[]",
        components: [
          { name: "roundId", type: "uint256", internalType: "uint256" },
          { name: "depositIndices", type: "uint256[]", internalType: "uint256[]" },
        ],
      },
    ],
    outputs: [],
    stateMutability: "nonpayable",
  },
  {
    type: "event",
    name: "Deposited",
    inputs: [
      { name: "depositor", type: "address", indexed: false, internalType: "address" },
      { name: "roundId", type: "uint256", indexed: false, internalType: "uint256" },
      { name: "entriesCount", type: "uint256", indexed: false, internalType: "uint256" },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "DepositsWithdrawn",
    inputs: [
      { name: "depositor", type: "address", indexed: false, internalType: "address" },
      {
        name: "withdrawalCalldata",
        type: "tuple[]",
        indexed: false,
        internalType: "struct IYoloPlay.WithdrawalCalldata[]",
        components: [
          { name: "roundId", type: "uint256", internalType: "uint256" },
          { name: "depositIndices", type: "uint256[]", internalType: "uint256[]" },
        ],
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "MaximumNumberOfParticipantsPerRoundUpdated",
    inputs: [{ name: "maximumNumberOfParticipantsPerRound", type: "uint40", indexed: false, internalType: "uint40" }],
    anonymous: false,
  },
  {
    type: "event",
    name: "MultipleRoundsDeposited",
    inputs: [
      { name: "depositor", type: "address", indexed: false, internalType: "address" },
      { name: "startingRoundId", type: "uint256", indexed: false, internalType: "uint256" },
      { name: "amounts", type: "uint256[]", indexed: false, internalType: "uint256[]" },
      { name: "entriesCounts", type: "uint256[]", indexed: false, internalType: "uint256[]" },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "OutflowAllowedUpdated",
    inputs: [{ name: "isAllowed", type: "bool", indexed: false, internalType: "bool" }],
    anonymous: false,
  },
  {
    type: "event",
    name: "Paused",
    inputs: [{ name: "account", type: "address", indexed: false, internalType: "address" }],
    anonymous: false,
  },
  {
    type: "event",
    name: "PrizesClaimed",
    inputs: [
      { name: "winner", type: "address", indexed: false, internalType: "address" },
      {
        name: "withdrawalCalldata",
        type: "tuple[]",
        indexed: false,
        internalType: "struct IYoloPlay.WithdrawalCalldata[]",
        components: [
          { name: "roundId", type: "uint256", internalType: "uint256" },
          { name: "depositIndices", type: "uint256[]", internalType: "uint256[]" },
        ],
      },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RandomnessRequested",
    inputs: [
      { name: "roundId", type: "uint256", indexed: false, internalType: "uint256" },
      { name: "requestId", type: "uint256", indexed: false, internalType: "uint256" },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RoleAdminChanged",
    inputs: [
      { name: "role", type: "bytes32", indexed: true, internalType: "bytes32" },
      { name: "previousAdminRole", type: "bytes32", indexed: true, internalType: "bytes32" },
      { name: "newAdminRole", type: "bytes32", indexed: true, internalType: "bytes32" },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RoleGranted",
    inputs: [
      { name: "role", type: "bytes32", indexed: true, internalType: "bytes32" },
      { name: "account", type: "address", indexed: true, internalType: "address" },
      { name: "sender", type: "address", indexed: true, internalType: "address" },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RoleRevoked",
    inputs: [
      { name: "role", type: "bytes32", indexed: true, internalType: "bytes32" },
      { name: "account", type: "address", indexed: true, internalType: "address" },
      { name: "sender", type: "address", indexed: true, internalType: "address" },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Rollover",
    inputs: [
      { name: "depositor", type: "address", indexed: false, internalType: "address" },
      {
        name: "withdrawalCalldata",
        type: "tuple[]",
        indexed: false,
        internalType: "struct IYoloPlay.WithdrawalCalldata[]",
        components: [
          { name: "roundId", type: "uint256", internalType: "uint256" },
          { name: "depositIndices", type: "uint256[]", internalType: "uint256[]" },
        ],
      },
      { name: "enteredRoundId", type: "uint256", indexed: false, internalType: "uint256" },
      { name: "entriesCount", type: "uint256", indexed: false, internalType: "uint256" },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RoundDurationUpdated",
    inputs: [{ name: "roundDuration", type: "uint40", indexed: false, internalType: "uint40" }],
    anonymous: false,
  },
  {
    type: "event",
    name: "RoundStatusUpdated",
    inputs: [
      { name: "roundId", type: "uint256", indexed: false, internalType: "uint256" },
      { name: "status", type: "uint8", indexed: false, internalType: "enum IYoloPlay.RoundStatus" },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "RoundsCancelled",
    inputs: [
      { name: "startingRoundId", type: "uint256", indexed: false, internalType: "uint256" },
      { name: "numberOfRounds", type: "uint256", indexed: false, internalType: "uint256" },
    ],
    anonymous: false,
  },
  {
    type: "event",
    name: "Unpaused",
    inputs: [{ name: "account", type: "address", indexed: false, internalType: "address" }],
    anonymous: false,
  },
  {
    type: "event",
    name: "ValuePerEntryUpdated",
    inputs: [{ name: "valuePerEntry", type: "uint256", indexed: false, internalType: "uint256" }],
    anonymous: false,
  },
  { type: "error", name: "AlreadyWithdrawn", inputs: [] },
  { type: "error", name: "CutoffTimeNotReached", inputs: [] },
  { type: "error", name: "DrawExpirationTimeNotReached", inputs: [] },
  { type: "error", name: "ERC20TransferFail", inputs: [] },
  { type: "error", name: "InsufficientParticipants", inputs: [] },
  { type: "error", name: "InvalidIndex", inputs: [] },
  { type: "error", name: "InvalidLength", inputs: [] },
  { type: "error", name: "InvalidStatus", inputs: [] },
  { type: "error", name: "InvalidValue", inputs: [] },
  { type: "error", name: "IsPaused", inputs: [] },
  { type: "error", name: "MaximumNumberOfDepositsReached", inputs: [] },
  { type: "error", name: "MaximumNumberOfParticipantsReached", inputs: [] },
  { type: "error", name: "NotAContract", inputs: [] },
  { type: "error", name: "NotDepositor", inputs: [] },
  { type: "error", name: "NotOwner", inputs: [] },
  { type: "error", name: "NotPaused", inputs: [] },
  { type: "error", name: "NotWinner", inputs: [] },
  { type: "error", name: "OnePlayerCannotFillUpTheWholeRound", inputs: [] },
  {
    type: "error",
    name: "OnlyCoordinatorCanFulfill",
    inputs: [
      { name: "have", type: "address", internalType: "address" },
      { name: "want", type: "address", internalType: "address" },
    ],
  },
  { type: "error", name: "OutflowNotAllowed", inputs: [] },
  { type: "error", name: "RandomnessRequestAlreadyExists", inputs: [] },
  { type: "error", name: "ReentrancyFail", inputs: [] },
  { type: "error", name: "RoundCannotBeClosed", inputs: [] },
  { type: "error", name: "ZeroDeposits", inputs: [] },
  { type: "error", name: "ZeroEntries", inputs: [] },
  { type: "error", name: "ZeroRounds", inputs: [] },
  { type: "error", name: "ZeroWithdrawAmount", inputs: [] },
] as const;
