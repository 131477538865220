export const TableTheme = {
  baseStyle: {
    table: {
      fontVariantNumeric: "initial",
    },
    thead: {
      th: {
        textStyle: "helper",
        p: 2,
        textTransform: "none",
        fontSize: "0.75rem",
        lineHeight: "1rem",
        color: "text-03",
        fontWeight: 400,
        letterSpacing: "normal",
      },
    },
    tbody: {
      td: {
        px: 2,
        py: 2,
        border: "none",
      },
    },
  },
  sizes: {
    lg: {
      thead: {
        th: {
          p: 4,
        },
      },
      tbody: {
        td: {
          px: 4,
          py: 4,
        },
      },
    },
    md: {
      thead: {
        th: {
          px: 4,
          py: 4,
        },
      },
      tbody: {
        td: {
          px: 4,
          py: 3,
        },
      },
    },
  },
};
