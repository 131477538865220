import { Address } from "viem";
import { formatAddressUsername } from "../formatting/formatAddressUsername";

export interface FormatDisplayNameArgs {
  address?: Address;
  userName?: string | null;
  ensName?: string | null;
  fallback?: string;
}

export const formatDisplayName = ({ address, userName, ensName, fallback }: FormatDisplayNameArgs) => {
  if (!!userName) {
    return userName;
  }
  if (!!ensName) {
    return ensName;
  }
  return fallback || formatAddressUsername(address || "0x");
};
