import { Trans, useTranslation } from "next-i18next";
import { PtbRoundLogType } from "../../../types";
import { LogText, LogTextHighlight } from "./LogText";
import type { LogTextProps, RoundLogTextGetter } from "./types";

const PlayerSurvivedText1 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerSurvived1">
      The bear lets out a sharp exhale, but his breathing then returns to normal. You&apos;re safe,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      .
    </Trans>
  </LogText>
);

const PlayerSurvivedText2 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.playerSurvived2")}</LogText>;
};

const PlayerSurvivedText3 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerSurvived3">
      Crickets. You sure you even touched him,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      ?
    </Trans>
  </LogText>
);

const PlayerSurvivedText4 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.playerSurvived4")}</LogText>;
};

const PlayerSurvivedText5 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.playerSurvived5")}</LogText>;
};

const PlayerSurvivedText6 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.playerSurvived6")}</LogText>;
};

const PlayerSurvivedText7 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.playerSurvived7")}</LogText>;
};

const PlayerSurvivedText8 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.playerSurvived8")}</LogText>;
};

const PlayerSurvivedText9 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.playerSurvived9")}</LogText>;
};

const PlayerSurvivedText10 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerSurvived10">
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      soils their pants in fear, but the bear doesn&apos;t react.
    </Trans>
  </LogText>
);

const PlayerSurvivedText11 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.playerSurvived11")}</LogText>;
};

const PlayerSurvivedText12 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.playerSurvived12")}</LogText>;
};

const PlayerSurvivedText13 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.playerSurvived13")}</LogText>;
};

export const playerSurvivedLogs: RoundLogTextGetter = ({ user1 }) => [
  <PlayerSurvivedText1 key={`${PtbRoundLogType.PLAYER_SURVIVED}1`} user1={user1} />,
  <PlayerSurvivedText2 key={`${PtbRoundLogType.PLAYER_SURVIVED}2`} />,
  <PlayerSurvivedText3 key={`${PtbRoundLogType.PLAYER_SURVIVED}3`} user1={user1} />,
  <PlayerSurvivedText4 key={`${PtbRoundLogType.PLAYER_SURVIVED}4`} />,
  <PlayerSurvivedText5 key={`${PtbRoundLogType.PLAYER_SURVIVED}5`} />,
  <PlayerSurvivedText6 key={`${PtbRoundLogType.PLAYER_SURVIVED}6`} />,
  <PlayerSurvivedText7 key={`${PtbRoundLogType.PLAYER_SURVIVED}7`} />,
  <PlayerSurvivedText8 key={`${PtbRoundLogType.PLAYER_SURVIVED}8`} />,
  <PlayerSurvivedText9 key={`${PtbRoundLogType.PLAYER_SURVIVED}9`} />,
  <PlayerSurvivedText10 key={`${PtbRoundLogType.PLAYER_SURVIVED}10`} user1={user1} />,
  <PlayerSurvivedText11 key={`${PtbRoundLogType.PLAYER_SURVIVED}11`} user1={user1} />,
  <PlayerSurvivedText12 key={`${PtbRoundLogType.PLAYER_SURVIVED}12`} user1={user1} />,
  <PlayerSurvivedText13 key={`${PtbRoundLogType.PLAYER_SURVIVED}13`} user1={user1} />,
];
