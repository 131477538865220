import { Icon, IconProps } from "../Icon";

const LowEth = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9954 16.2409L10.9979 2.9707L3 16.2414L10.998 20.9689L18.9954 16.2409ZM10.9977 22.4837L3 17.7588L10.998 29.0283L19 17.7588L10.9977 22.4837ZM26 25.2L29.6 21.6L31 23L25 29L19 23L20.4 21.6L24 25.2V15H26V25.2Z"
      fill="currentColor"
    />
  </Icon>
);

export default LowEth;
