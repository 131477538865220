import { Icon, IconProps } from "../Icon";

const RaritySniper = (props: IconProps) => (
  <Icon {...props}>
    <path
      d="M30.8832 14.1378L23.8472 7.0594L28.4273 12.9879L16 28.0977L18.6651 30.7643C17.7855 30.9216 16.8936 31.0005 16 31C7.71687 31 1.00098 24.285 1.00098 16.001C1.00098 7.71696 7.71599 1.00195 16 1.00195C23.6526 1.00195 29.9666 6.73349 30.8836 14.138L30.8832 14.1378Z"
      fill="#FF4D05"
    />
    <path d="M11.2912 12.9863H3.59424L8.14802 7.05847L11.2912 12.9863Z" fill="#A7FFF1" />
    <path d="M16 7.05847L11.2912 12.9863L8.14801 7.05847H16Z" fill="#00E4EF" />
    <path d="M23.8471 7.05847L20.7357 12.9863L16 7.05847H23.8471Z" fill="#5ECBF1" />
    <path d="M28.4273 12.9863H20.7357L23.8472 7.05847L28.4273 12.9863Z" fill="#00E4EF" />
    <path d="M28.4273 12.9863L16 28.0957L20.7357 12.9863H28.4273Z" fill="#A7FFF1" />
    <path d="M20.7357 12.9863L16 28.0957V12.9863H20.7357Z" fill="#5ECBF1" />
    <path d="M16 28.0957L3.59424 12.9863H11.2912L16 28.0957Z" fill="#8EEFEA" />
    <path d="M20.7357 12.9863H16V7.05847L20.7357 12.9863Z" fill="#00E4EF" />
    <path d="M16 7.05847V12.9863H11.2911L16 7.05847Z" fill="#A7FFF1" />
    <path
      d="M16 12.9863V28.0957L13.7273 20.8033L13.4101 19.7851L11.7119 14.3365L11.3953 13.3202L11.2911 12.9863H16Z"
      fill="#00E4EF"
    />
    <path
      d="M30.999 16.0001C30.999 23.3738 25.6778 29.5046 18.6659 30.7623L16 28.0968L28.4272 12.987L23.8471 7.05847L30.8831 14.1368V14.1379C30.96 14.7557 30.9987 15.3776 30.999 16.0001Z"
      fill="#C30047"
    />
    <path
      d="M12.5201 12.9869C12.9506 12.4852 13.4845 12.0824 14.0851 11.806C14.6857 11.5297 15.3389 11.3863 16 11.3858V10.6593C15.1334 10.6601 14.28 10.8725 13.5142 11.2782C12.7483 11.6838 12.0931 12.2704 11.6056 12.9869C11.5317 13.0955 11.4616 13.2068 11.3953 13.3208C10.927 14.13 10.68 15.0483 10.6791 15.9832C10.6782 16.9182 10.9233 17.8369 11.39 18.6471C11.8567 19.4572 12.5284 20.1303 13.3376 20.5986C14.1468 21.0668 15.0651 21.3139 16 21.3148V20.5884C15.1198 20.5875 14.2584 20.334 13.5179 19.8581C12.7774 19.3822 12.1891 18.7038 11.8227 17.9035C11.4563 17.1031 11.3273 16.2145 11.4509 15.343C11.5745 14.4715 11.9456 13.6538 12.5201 12.9869Z"
      fill="#FF6826"
    />
    <path
      d="M21.3358 15.9872C21.3358 18.9296 18.9506 21.3148 16.0082 21.3148H16V20.5884H16.0082C18.5494 20.5884 20.6094 18.5279 20.6094 15.9872C20.6094 13.4464 18.5489 11.3858 16.0082 11.3858H16V10.6593H16.0082C18.9506 10.6593 21.3358 13.0447 21.3358 15.9872Z"
      fill="#C30047"
    />
    <path
      d="M16.8346 15.9873C16.8346 16.0958 16.8132 16.2032 16.7717 16.3035C16.7301 16.4038 16.6693 16.4949 16.5925 16.5716C16.5158 16.6483 16.4247 16.7092 16.3244 16.7507C16.2242 16.7923 16.1167 16.8136 16.0082 16.8136H16V15.1606H16.0082C16.1167 15.1606 16.2242 15.182 16.3245 15.2236C16.4248 15.2651 16.5159 15.326 16.5926 15.4028C16.6693 15.4795 16.7302 15.5707 16.7717 15.6709C16.8132 15.7712 16.8346 15.8787 16.8346 15.9873Z"
      fill="#C30047"
    />
    <path
      d="M16 15.1606V16.8136C15.7832 16.8101 15.5764 16.7215 15.4243 16.5668C15.2722 16.4122 15.187 16.204 15.187 15.9871C15.187 15.7703 15.2722 15.5621 15.4243 15.4074C15.5764 15.2528 15.7832 15.1642 16 15.1606Z"
      fill="#FF6826"
    />
    <path d="M22.1335 15.658H18.1218V16.353H22.1335V15.658Z" fill="#C30047" />
    <path d="M13.8576 15.658H9.84595V16.353H13.8576V15.658Z" fill="#FF6826" />
    <path d="M16.3556 18.1455H16V22.1571H16.3556V18.1455Z" fill="#C30047" />
    <path d="M16 18.1455H15.6606V22.1571H16V18.1455Z" fill="#FF6826" />
    <path d="M16.3556 9.8374H16V13.849H16.3556V9.8374Z" fill="#C30047" />
    <path d="M16 9.8374H15.6606V13.849H16V9.8374Z" fill="#FF6826" />
  </Icon>
);

export default RaritySniper;
