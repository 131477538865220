export const semanticTokens = {
  colors: {
    "moondoom-moon": {
      _dark: "green.400",
      _light: "green.500",
    },
    "moondoom-moon-subtle": {
      _dark: "rgba(50, 213, 131, 0.2)", // Green 400 w/ 20% opacity
      _light: "rgba(18, 183, 106, 0.2)", // Green 500 w/ 20% opacity
    },
    "moondoom-moon-hover": {
      _dark: "green.500",
      _light: "green.600",
    },
    "moondoom-moon-onclick": {
      _dark: "green.600",
      _light: "green.700",
    },
    "moondoom-text-on-moon": {
      _dark: "gray.800",
      _light: "gray.50",
    },
    "moondoom-doom": {
      _dark: "red.400",
      _light: "red.400",
    },
    "moondoom-doom-subtle": {
      _dark: "rgba(249, 112, 102, 0.2)", // Red 400 w/ 20% opacity
      _light: "rgba(249, 112, 102, 0.2)", // Red 400 w/ 20% opacity
    },
    "moondoom-doom-hover": {
      _dark: "red.500",
      _light: "red.500",
    },
    "moondoom-doom-onclick": {
      _dark: "red.600",
      _light: "red.600",
    },
    "moondoom-text-on-doom": {
      _dark: "gray.50",
      _light: "gray.50",
    },
  },
};
