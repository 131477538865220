import { Icon, IconProps } from "../Icon";

const TokenYoloMono = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 96 96" {...props}>
      <circle cx="48" cy="48" r="48" fill="#F8FAFC" opacity="0.08" />
      <path
        d="M55.5099 26.0272H72.3892L56.1456 75.2355H39.2663L45.4106 56.7208H33.122L23.5876 26.0272H40.255L47.3175 50.8777L55.5099 26.0272Z"
        fill="white"
      />
    </Icon>
  );
};

export default TokenYoloMono;
