import { Address, zeroAddress } from "viem";
import { ChainId } from "../chains";

// Seaport's address are the same on Mainnet and Sepolia
// @todo-l2 check if this is the case for other chains
export const SEAPORT_14: Address = "0x00000000000001ad428e4906aE43D8F9852d0dD6";
export const SEAPORT_15: Address = "0x00000000000000ADc04C56Bf30aC9d3c0aAF14dC";

export type TokenStandard = "ERC721" | "ERC1155";

export interface ChainIdMap {
  [chainId: number]: string;
}

export interface Addresses {
  /**
   * LOOKS compounder
   */
  AGGREGATOR_FEE_SHARING_WITH_UNISWAP_V3: Address;
  /**
   * V2 Staking. Auto-compounds WETH rewards into LOOKS and reinvests them into the StakingRewards contract.
   */
  AUTO_COMPOUNDER: Address;
  /**
   * DUST ERC20
   */
  DUST: Address;
  /**
   * YOLO oracle
   */
  ERC_20_PRICE_ORACLE: Address;
  /**
   * Network native currency
   */
  ETH: Address;
  /**
   * V2 marketplace contract
   */
  EXCHANGE_V2: Address;
  /**
   * Standard LOOKS committing
   */
  FEE_SHARING_SYSTEM: Address;
  /**
   * Infiltration collection and game
   */
  INFILTRATION: Address;
  /**
   * Contract used to handle swapping ETH for LOOKS prior to calling Infiltration heal()
   */
  INFILTRATION_PERIPHERY: Address;
  /**
   * LOOKS ERC20
   */
  LOOKS: Address;
  /**
   * Uniswap V3 LOOKS/ETH pool
   */
  LOOKS_LP_V3: Address;
  /**
   * Moon or doom contract for ETH
   */
  MOON_OR_DOOM_NATIVE_TOKEN_ETH: Address;
  /**
   * Moon or doom contract for BTC
   */
  MOON_OR_DOOM_NATIVE_TOKEN_BTC: Address;
  /**
   * Trading rewards + Listing rewards (deprecated) claims
   */
  MULTI_REWARDS_DISTRIBUTOR: Address;
  /**
   * Third party - OS Conduit contract
   */
  OS_CONDUIT: Address;
  /**
   * LR Protocol Fee recipient contract
   */
  PROTOCOL_FEE_RECIPIENT: Address;
  /**
   * Raffle V1 contract
   */
  RAFFLE_V1: Address;
  /**
   * Raffle V2
   */
  RAFFLE_V2: Address;
  /**
   * Staking V2. Receives protocol fees and transfers them to StakingRewards.
   */
  REWARDS_DISTRIBUTION: Address;
  /**
   * Third party - ENS ReverseRecords contract
   */
  REVERSE_RECORDS: Address;
  /**
   * Third party - Seaport 1.4
   */
  "SEAPORT_PROTOCOLS_1.4": Address;
  /**
   *  Third party - Seaport 1.5
   */
  "SEAPORT_PROTOCOLS_1.5": Address;
  /**
   * Gems claim contract
   */
  SEASON_REWARDS_DISTRIBUTOR_ADDRESS: Address;
  /**
   * Staking V2. Allows staking an ERC20 token and receiving another as reward.
   */
  STAKING_REWARDS: Address;
  /**
   * V1 collection offer strategy with no royalty
   */
  STRATEGY_COLLECTION_SALE: Address;
  /**
   * Deprecated V1 collection offer strategy with royalty
   */
  STRATEGY_COLLECTION_SALE_DEPRECATED: Address;
  /**
   * Distribution of LOOKS token to stakers
   */
  TOKEN_DISTRIBUTOR: Address;
  /**
   * Exchange V2 transfer manager
   */
  TRANSFER_MANAGER_EXCHANGE_V2: Address;
  /**
   * Transfer manager used for all non-exchange products (raffle, yolo, ptb, staking etc)
   */
  TRANSFER_MANAGER: Address;
  /**
   * Transfer manager for classic games
   */
  TRANSFER_MANAGER_CLASSIC_GAMES: Address;
  /**
   * Third party - WETH ERC20
   */
  WETH: Address;
  /**
   * Staking V2. Wrapped LOOKS - used for staking and auto-compounding
   */
  WLOOKS: Address;
  /**
   * YOLO V1
   */
  YOLO_V1: Address;
  /**
   * YOLO V2
   */
  YOLO_V2: Address;
  /**
   * Poke The Bear
   */
  POKE_THE_BEAR: Address;
  /**
   * Flipper
   */
  FLIPPER: Address;
  /**
   * Raffle creator
   */
  RAFFLE_CREATOR: Address;
  /**
   * USDC
   */
  USDC: Address;
  /**
   * USDT
   */
  USDT: Address;
  /**
   * BOOST token - used temporarily for promoting yolo games
   */
  BOOST: Address;
  /**
   * Stablecoin on Blast network
   */
  USDB: Address;
  /**
   * YIELD token. ERC20 playable on Yolo Games
   */
  YIELD: Address;
  /**
   * YES token. ERC20 playable on Yolo Games
   */
  YES: Address;
  /**
   * ORBIT token. ERC20 playable on Yolo Games
   */
  ORBIT: Address;
  /**
   * JUICE token. ERC20 playable on Yolo Games
   */
  JUICE: Address;
  /**
   * PAC token. ERC20 playable on Yolo Games
   */
  PAC: Address;
  /**
   * Protocol fees distributor for Blast network for ETH rakeback
   */
  PROTOCOL_FEES_DISTRIBUTOR_ETH: Address;
  /**
   * Protocol fees distributor for Blast network for YOLO rakeback
   */
  PROTOCOL_FEES_DISTRIBUTOR_YOLO: Address;

  /**
   * Invasion collection and game
   */
  INVASION: Address;
  /**
   * Contract used to handle swapping ETH for LOOKS prior to calling Invasion heal()
   */
  INVASION_PERIPHERY: Address;
  /**
   * Address for bridging LOOKS
   */
  BRIDGE_VAULT_CONTRACT: Address;
  /**
   * Address for bridge connector
   */
  BRIDGE_CONNECTOR: Address;
  /**
   * Quantum contract
   */
  QUANTUM: Address;
  /**
   * Game config manager contract
   */
  GAME_CONFIG_MANAGER: Address;
  /**
   * Yolo Games Season 1 rewards distributor
   */
  YG_SEASON_1_REWARDS_DISTRIBUTOR: Address;
  /**
   * Liquidity router contract. FE uses it to claim vault shares
   */
  YG_SEASON_1_SHARES_DISTRIBUTOR: Address;
  /**
   * ERC20 "YOLO" Token Address
   */
  YOLO: Address;
  /**
   * ERC20 "yYOLO" Token Address
   */
  VAULT_SHARES_YYOLO_TOKEN: Address;
  /**
   * ERC20 "yETH" Token Address
   */
  VAULT_SHARES_YETH_TOKEN: Address;
  LASER_BLAST: Address;
  DONT_FALL_IN: Address;
  /**
   * ERC20 "bLOOKS" - Looks on the Blast network
   */
  BLOOKS: Address;
  /**
   * ERC20 "Blast"
   */
  BLAST: Address;
}

const baseEmptyAddresses: Addresses = {
  AGGREGATOR_FEE_SHARING_WITH_UNISWAP_V3: "0x",
  AUTO_COMPOUNDER: "0x",
  BLAST: "0x",
  BLOOKS: "0x",
  BOOST: "0x",
  BRIDGE_CONNECTOR: "0x",
  BRIDGE_VAULT_CONTRACT: "0x",
  DONT_FALL_IN: "0x",
  DUST: "0x",
  ERC_20_PRICE_ORACLE: "0x",
  ETH: zeroAddress,
  EXCHANGE_V2: "0x",
  FEE_SHARING_SYSTEM: "0x",
  FLIPPER: "0x",
  GAME_CONFIG_MANAGER: "0x",
  INFILTRATION: "0x",
  INFILTRATION_PERIPHERY: "0x",
  INVASION: "0x",
  INVASION_PERIPHERY: "0x",
  JUICE: "0x",
  LASER_BLAST: "0x",
  LOOKS: "0x",
  LOOKS_LP_V3: "0x",
  MOON_OR_DOOM_NATIVE_TOKEN_BTC: "0x",
  MOON_OR_DOOM_NATIVE_TOKEN_ETH: "0x",
  MULTI_REWARDS_DISTRIBUTOR: "0x",
  ORBIT: "0x",
  OS_CONDUIT: "0x",
  PAC: "0x",
  POKE_THE_BEAR: "0x",
  PROTOCOL_FEE_RECIPIENT: "0x",
  PROTOCOL_FEES_DISTRIBUTOR_ETH: "0x",
  PROTOCOL_FEES_DISTRIBUTOR_YOLO: "0x",
  QUANTUM: "0x",
  RAFFLE_CREATOR: "0x",
  RAFFLE_V1: "0x",
  RAFFLE_V2: "0x",
  REVERSE_RECORDS: "0x",
  REWARDS_DISTRIBUTION: "0x",
  "SEAPORT_PROTOCOLS_1.4": "0x",
  "SEAPORT_PROTOCOLS_1.5": "0x",
  SEASON_REWARDS_DISTRIBUTOR_ADDRESS: "0x",
  STAKING_REWARDS: "0x",
  STRATEGY_COLLECTION_SALE: "0x",
  STRATEGY_COLLECTION_SALE_DEPRECATED: "0x",
  TOKEN_DISTRIBUTOR: "0x",
  TRANSFER_MANAGER: "0x",
  TRANSFER_MANAGER_CLASSIC_GAMES: "0x",
  TRANSFER_MANAGER_EXCHANGE_V2: "0x",
  USDB: "0x",
  USDC: "0x",
  USDT: "0x",
  VAULT_SHARES_YETH_TOKEN: "0x",
  VAULT_SHARES_YYOLO_TOKEN: "0x",
  WETH: "0x",
  WLOOKS: "0x",
  YES: "0x",
  YG_SEASON_1_REWARDS_DISTRIBUTOR: "0x",
  YG_SEASON_1_SHARES_DISTRIBUTOR: "0x",
  YIELD: "0x",
  YOLO: "0x",
  YOLO_V1: "0x",
  YOLO_V2: "0x",
};

export const mainnetAddresses: Addresses = {
  ...baseEmptyAddresses,
  AGGREGATOR_FEE_SHARING_WITH_UNISWAP_V3: "0x3ab16Af1315dc6C95F83Cbf522fecF98D00fd9ba",
  AUTO_COMPOUNDER: "0x000000000077Ee1fCFE351dF1Ff22736e995806B",
  BRIDGE_CONNECTOR: "0x8843557Fd6005d617A735731BF1bAb0461af55E4",
  BRIDGE_VAULT_CONTRACT: "0xa83B4006c16DAeAb2718294696c0122519195137",
  DUST: "0xB5b1b659dA79A2507C27AaD509f15B4874EDc0Cc",
  ERC_20_PRICE_ORACLE: "0x00000000000A95dBfC66D37F3FC5E597C0b03Daf",
  ETH: zeroAddress,
  EXCHANGE_V2: "0x0000000000E655fAe4d56241588680F86E3b2377",
  FEE_SHARING_SYSTEM: "0xBcD7254A1D759EFA08eC7c3291B2E85c5dCC12ce",
  INFILTRATION: "0x00000000005316Fe469550d85f2E5AE85b7db719",
  INFILTRATION_PERIPHERY: "0x0000000000f92408E1e1Ea365Ac186848468adfF",
  LOOKS: "0xf4d2888d29D722226FafA5d9B24F9164c092421E",
  LOOKS_LP_V3: "0x4b5Ab61593A2401B1075b90c04cBCDD3F87CE011",
  MOON_OR_DOOM_NATIVE_TOKEN_BTC: "0x",
  MOON_OR_DOOM_NATIVE_TOKEN_ETH: "0x",
  MULTI_REWARDS_DISTRIBUTOR: "0x0554f068365eD43dcC98dcd7Fd7A8208a5638C72",
  OS_CONDUIT: "0x1E0049783F008A0085193E00003D00cd54003c71",
  POKE_THE_BEAR: "0x00000000009f22B55D3105e5CfF7D1a6503cF3cE",
  PROTOCOL_FEE_RECIPIENT: "0x1838De7d4e4e42c8eB7b204A91e28E9fad14F536",
  RAFFLE_CREATOR: "0xB5a9e5a319c7fDa551a30BE592c77394bF935c6f",
  RAFFLE_V1: "0x0000000000009703EcD0fFEa3143fc9096DE91B0",
  RAFFLE_V2: "0x0000000000aDEaD599C11A0C9a7475B67852c1D0",
  REVERSE_RECORDS: "0x3671aE578E63FdF66ad4F3E12CC0c0d71Ac7510C",
  REWARDS_DISTRIBUTION: "0x000000000018658fC319f3EeAc9bbD054dEd4856",
  "SEAPORT_PROTOCOLS_1.4": SEAPORT_14,
  "SEAPORT_PROTOCOLS_1.5": SEAPORT_15,
  SEASON_REWARDS_DISTRIBUTOR_ADDRESS: "0x5C073CeCaFC56EE9f4335230A09933965C8ed472",
  STAKING_REWARDS: "0x0000000000017b2a2a6a336079Abc67f6f48aB9A",
  STRATEGY_COLLECTION_SALE: "0x09F93623019049c76209C26517AcC2aF9d49c69b",
  STRATEGY_COLLECTION_SALE_DEPRECATED: "0x86F909F70813CdB1Bc733f4D97Dc6b03B8e7E8F3",
  TOKEN_DISTRIBUTOR: "0x465A790B428268196865a3AE2648481ad7e0d3b1",
  TRANSFER_MANAGER: "0x00000000000ea4af05656C17b90f4d64AdD29e1d",
  TRANSFER_MANAGER_EXCHANGE_V2: "0x000000000060C4Ca14CfC4325359062ace33Fe3D",
  USDC: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  USDT: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
  WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  WLOOKS: "0xe7baC7d798D66D353b9e50EbFC6859950fE13Ce4",
  YOLO_V1: "0x00000000007767d79f9F4aA1Ff0d71b8E2E4a231",
  YOLO_V2: "0x0000000000E0e3aAc70baeD78DAEc3B5BE005343",
};

export const goerliAddresses: Addresses = {
  ...baseEmptyAddresses,
  AGGREGATOR_FEE_SHARING_WITH_UNISWAP_V3: "0x63c38B3BE3eF075a00a5edaeC36F499088c7334C",
  ERC_20_PRICE_ORACLE: "0xe2A041F1BaE3b155c62DEE5e053E566D7668434e",
  ETH: zeroAddress,
  EXCHANGE_V2: "0x35C2215F2FFe8917B06454eEEaba189877F200cf",
  FEE_SHARING_SYSTEM: "0x8A29E7b241A32158Fd7F267d484199aAD8dd2E31",
  LOOKS: "0x20A5A36ded0E4101C3688CBC405bBAAE58fE9eeC",
  LOOKS_LP_V3: "0x87C81267796Cd65347130e789CADdCdAf7bD2231",
  MULTI_REWARDS_DISTRIBUTOR: "0xdC6dC8d1B784890BA2c38947218F89E963EC2673",
  OS_CONDUIT: "0x1E0049783F008A0085193E00003D00cd54003c71",
  PROTOCOL_FEE_RECIPIENT: "0xdbBE0859791E44B52B98FcCA341DFb7577C0B077",
  RAFFLE_V1: "0xC5F7FCde87e30Aa339d3d61B4fe3c1C261f6EEe2",
  RAFFLE_V2: "0xda28aC345040C9abC0E19AfD6025c4f5A45C4b30",
  REVERSE_RECORDS: "0x333Fc8f550043f239a2CF79aEd5e9cF4A20Eb41e",
  SEASON_REWARDS_DISTRIBUTOR_ADDRESS: "0x65E382e96e043D99Fe954769427960DBe2941a60",
  STRATEGY_COLLECTION_SALE: "0xAfb81825DC076500BF19CBf69d443684F2EBd3f6",
  STRATEGY_COLLECTION_SALE_DEPRECATED: "0xeF722abf61a1937E76DAcFcd58d51c2358dE7D1A",
  TOKEN_DISTRIBUTOR: "0x861fDb71CCc266b3c0A4b8da8A929E52E83F5e7c",
  TRANSFER_MANAGER: "0xb737687983D6CcB4003A727318B5454864Ecba9d",
  TRANSFER_MANAGER_EXCHANGE_V2: "0xC20E0CeAD98abBBEb626B77efb8Dc1E5D781f90c",
  WETH: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
  YOLO_V1: "0x6D0aD9426525f771b41F70d30521a1149D0Bc94e",
};

export const sepoliaAddresses: Addresses = {
  ...baseEmptyAddresses,
  AGGREGATOR_FEE_SHARING_WITH_UNISWAP_V3: "0x",
  AUTO_COMPOUNDER: "0x0848450060471EE8257D390c522C1657BE0253D1",
  DUST: "0x",
  ERC_20_PRICE_ORACLE: "0x5282Dec40c65cf3cf5d5d0E377EDff7C1083F327",
  ETH: zeroAddress,
  EXCHANGE_V2: "0x34098cc15a8a48Da9d3f31CC0F63F01f9aa3D9F3",
  INFILTRATION: "0x2109136ffcDa58C8De21979EEA41FC719a6ae4B5",
  INFILTRATION_PERIPHERY: "0x5eDED0A873256Cc4147A04Dd9E23Be0cdc0E4FEA",
  LOOKS: "0xa68c2CaA3D45fa6EBB95aA706c70f49D3356824E", // @note - not strictly "LOOKS", but a test ERC20
  LOOKS_LP_V3: "0x",
  OS_CONDUIT: "0x1E0049783F008A0085193E00003D00cd54003c71",
  POKE_THE_BEAR: "0x911a121D1bf1D3965bF609D1E37813A4b56C442C",
  PROTOCOL_FEE_RECIPIENT: "0x5DDAcd5F10915C08136f72eaa8cE9771DC22A1C9",
  RAFFLE_CREATOR: "0xc8Bc7543aeB7Fbf833fE3dA2fA6c2E5443184D56",
  RAFFLE_V1: "0xb0C8a1a0569F7302d36e380755f1835C3e59aCB9",
  RAFFLE_V2: "0xaE7Cf912aB5D9f6aa53126eA7aabd007f844CEEC",
  REWARDS_DISTRIBUTION: "0x3Bb7307bB9Fce87ce95842aa195252d54bcaF61C",
  "SEAPORT_PROTOCOLS_1.4": SEAPORT_14,
  "SEAPORT_PROTOCOLS_1.5": SEAPORT_15,
  SEASON_REWARDS_DISTRIBUTOR_ADDRESS: "0x7a24d335B88CbD1A9106C1019CbB21702f3FE49B",
  STAKING_REWARDS: "0x4284B0f80aa90513097dfECA3a06984Fc7f35da2",
  TRANSFER_MANAGER: "0x8B43b6C4601FaCF70Fe17D057b3912Bde0206CFB",
  TRANSFER_MANAGER_EXCHANGE_V2: "0xb46f116ecBa8451E661189F4b2B63aC60a618092",
  USDT: "0x0535208A1Db725f7a2f1ad2452fac4c177617f7e", // @NOTE our unofficial clone for testing
  WETH: "0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14",
  WLOOKS: "0x190C6eee56FAd7bF8Faead7D6a0574f1B18c295b",
  YOLO_V1: "0xD7Af15a95351c8ae6B628f6571CFD24d56e06E21",
  YOLO_V2: "0x8894d17875D81e4d2Cf17Fd4fFF63F6bBCABf4e1",
};

export const arbitrumAddresses: Addresses = {
  ...baseEmptyAddresses,
  ERC_20_PRICE_ORACLE: "0x0000000000eEa0D202784C0eB8203bEC371E5BB6",
  MOON_OR_DOOM_NATIVE_TOKEN_BTC: "0x000000000088B51A56E374FC4B01936becedab7b",
  MOON_OR_DOOM_NATIVE_TOKEN_ETH: "0x000000000027120A427854E9B306E4B94b3c8F9A",
  POKE_THE_BEAR: "0x000000000083DBf7364e6A22FEDd0ad64aF3248d",
  TRANSFER_MANAGER: "0x0000000000A3573e1caFe02fe1C3Ac48473C9332",
  USDT: "0xF9C20B8bb6D552f8aCC7c0301C20c929aa107797",
  YOLO_V2: "0x0000000000d7309ABDc5Ca5DD6b077746F5B05Cd",
};

export const arbitrumSepoliaAddresses: Addresses = {
  ...baseEmptyAddresses,
  MOON_OR_DOOM_NATIVE_TOKEN_BTC: "0x2ed116ee0756b827e9e8D4372ac95eee00518268",
  MOON_OR_DOOM_NATIVE_TOKEN_ETH: "0x5a285567B6f0CcB989283339946D7edE708176d9",
  POKE_THE_BEAR: "0xb0C8a1a0569F7302d36e380755f1835C3e59aCB9",
  TRANSFER_MANAGER: "0x21D44Cd218895d08bB57E2161A5c8De1CE898165",
  YOLO_V2: "0xCb9f4968326B69b266E0282D65C58a307b7d2376",
};

export const blastLooksrareSepoliaAddresses: Addresses = {
  ...baseEmptyAddresses,
  BOOST: "0x2bb538c1Ac2A7B5c8B75B4d60B8dD0246bb3C313",
  ERC_20_PRICE_ORACLE: "0x139bF195099B17137B84f5ba812C218b4CE628ea",
  INVASION: "0x5DDAcd5F10915C08136f72eaa8cE9771DC22A1C9",
  LOOKS: "0xFbFcB53423ae0ffe217baF0967BE4a7af378630a", // @todo-invasion use actual bridged looks address
  POKE_THE_BEAR: "0x01a7481C3e4FdEe1f2667845EfBf52fE6320535b",
  PROTOCOL_FEES_DISTRIBUTOR_ETH: "0x4367CD55B805C0AeA3eaFb0ccF633C1E7cC1eD86",
  PROTOCOL_FEES_DISTRIBUTOR_YOLO: "0x5fB90182CeFc70e42Ee506616B0A5E4E4e8ff41f",
  TRANSFER_MANAGER: "0xca229a4596B9043AfB96e995a16245A191525c9c",
  USDB: "0x4200000000000000000000000000000000000022",
  YOLO_V2: "0xE3ac96527bF4E785E4ba299470b6Ddb3eA88C1Ff",
};

// @TODO-invasion - Refactor this please. Assign contract addresses once per network. Infiltration can compose values off the config but they should not be duplicated within this file.
// EXPLANATION
// bodhi came across an issue where the transfer manager needed a different address in invasion.
// A quick fix for this was to change the TRANFER_MANAGER value to the address he needed.
// Now, in order to not affect any other piece of frontend/yolo-games,
// he made sure to scope this to invasion by copy-pasting the addresses and making a special case in useAddressesByNetwork
export const blastLooksrareMainnetAddresses: Addresses = {
  ...baseEmptyAddresses,
  BOOST: "0x63D758C3AD4c1BDF3279A3f8fEA04471462952E1",
  ERC_20_PRICE_ORACLE: "0x9C9D8C8ee91f21004A40c4664D49CC1b9DFa69f9",
  INVASION: "0x5aC3caAeb36383853c3AA00D9314B1EB7D44514F",
  INVASION_PERIPHERY: "0x2b4A3794ABBb3E1761Aba5f4182889840408790f",
  JUICE: "0x818a92bc81Aad0053d72ba753fb5Bc3d0C5C0923",
  LOOKS: "0x406F10d635be12ad33D6B133C6DA89180f5B999e",
  MOON_OR_DOOM_NATIVE_TOKEN_BTC: "0xA56A95F41e64Bc76CDE7423aB2A2ee1763bD8Bcc",
  MOON_OR_DOOM_NATIVE_TOKEN_ETH: "0x693B37a9859Ce9465Fb2aAdeB03811a26A0c37C0",
  ORBIT: "0x42E12D42b3d6C4A74a88A61063856756Ea2DB357",
  PAC: "0x5ffd9EbD27f2fcAB044c0f0a26A45Cb62fa29c06",
  POKE_THE_BEAR: "0x0000000000aCc01064aA5280da3F1C41A35827bc",
  PROTOCOL_FEES_DISTRIBUTOR_ETH: "0xBf9Ae848bEd1827176640088E6c5e2E284407040",
  PROTOCOL_FEES_DISTRIBUTOR_YOLO: "0x6c8e7B36EFCf658CEA4168931Fb22ACC94Cb0C20",
  TRANSFER_MANAGER: "0x1bC9fCB0dC87b6A4872A0b969617d844f851d634",
  USDB: "0x4300000000000000000000000000000000000003",
  YES: "0x20fE91f17ec9080E3caC2d688b4EcB48C5aC3a9C",
  YIELD: "0x67fa2887914fA3729e9EED7630294Fe124f417A0",
  YOLO_V2: "0x0000000000E14E87e5c80A8A90817308fFF715d3",
};

export const blastSepoliaAddresses: Addresses = {
  ...baseEmptyAddresses,
  BLAST: "0x",
  BOOST: "0x2bb538c1Ac2A7B5c8B75B4d60B8dD0246bb3C313",
  DONT_FALL_IN: "0x7b1b43E6581C4E5bC23E0C08AF7A38B7cd1a1b9f",
  ERC_20_PRICE_ORACLE: "0x139bF195099B17137B84f5ba812C218b4CE628ea",
  FLIPPER: "0x48242B873FcEf9e8C66244d0E43A38eb23D5486c",
  GAME_CONFIG_MANAGER: "0x15a521C7Fb65E86f31C46c12Ca121b11a682F2a6",
  INVASION: "0x5DDAcd5F10915C08136f72eaa8cE9771DC22A1C9",
  LASER_BLAST: "0x2B593Fdbc1a9461f1Ee43830f9475Bc00f44E710",
  LOOKS: "0xFbFcB53423ae0ffe217baF0967BE4a7af378630a",
  POKE_THE_BEAR: "0x01a7481C3e4FdEe1f2667845EfBf52fE6320535b",
  PROTOCOL_FEES_DISTRIBUTOR_ETH: "0x4367CD55B805C0AeA3eaFb0ccF633C1E7cC1eD86",
  PROTOCOL_FEES_DISTRIBUTOR_YOLO: "0x5fB90182CeFc70e42Ee506616B0A5E4E4e8ff41f",
  QUANTUM: "0x4A725CFE8D19D6416f9574F3Ca89b5639FEFb928",
  TRANSFER_MANAGER_CLASSIC_GAMES: "0xFD1DcA440E83FF9A1AED83e0f2D4b3EE85Cc2d44",
  USDB: "0x4200000000000000000000000000000000000022",
  VAULT_SHARES_YETH_TOKEN: "0xe5A591c9D81e40c147b5f13182E0193bfd92EF0c",
  VAULT_SHARES_YYOLO_TOKEN: "0x666d8aE8E11c054A6516a07e1Eee4009e7b468fa",
  YG_SEASON_1_REWARDS_DISTRIBUTOR: "0xAc1fB32d78D9c0e057E708077FA89e1f03c118C3",
  YG_SEASON_1_SHARES_DISTRIBUTOR: "0x55d036F0067153AD121d4006121eA9FA79c996e0",
  YOLO: "0xD4Ae8bd7A706C55b94944066C3cd62DE8F5b71f5",
  YOLO_V2: "0xE3ac96527bF4E785E4ba299470b6Ddb3eA88C1Ff",
};

export const blastMainnetAddresses: Addresses = {
  ...baseEmptyAddresses,
  BLAST: "0xb1a5700fA2358173Fe465e6eA4Ff52E36e88E2ad",
  BLOOKS: "0x406F10d635be12ad33D6B133C6DA89180f5B999e",
  BOOST: "0x63D758C3AD4c1BDF3279A3f8fEA04471462952E1",
  BRIDGE_CONNECTOR: "0x5946F65cAc16cB1300d6031B3BbdF59e8539EAcE",
  BRIDGE_VAULT_CONTRACT: "0xDa0072593f6F9B7a510103602024d882eBc756fc",
  DONT_FALL_IN: "0x13F0Eb6747114533ee643EDd84F9f82a77D0025b",
  ERC_20_PRICE_ORACLE: "0x9C9D8C8ee91f21004A40c4664D49CC1b9DFa69f9",
  FLIPPER: "0x96648d17c273A932197aCF2232653Bed7D69EC6f",
  GAME_CONFIG_MANAGER: "0x572a1FA9e45c2ec681ABa11B9Fdb829A5Ba9E50d",
  INVASION: "0x5aC3caAeb36383853c3AA00D9314B1EB7D44514F",
  INVASION_PERIPHERY: "0x2b4A3794ABBb3E1761Aba5f4182889840408790f",
  JUICE: "0x818a92bc81Aad0053d72ba753fb5Bc3d0C5C0923",
  LASER_BLAST: "0xc6f17f7935B12a38fe4a80f4f4Db7DBaf324224c",
  LOOKS: "0x406F10d635be12ad33D6B133C6DA89180f5B999e",
  MOON_OR_DOOM_NATIVE_TOKEN_BTC: "0xA56A95F41e64Bc76CDE7423aB2A2ee1763bD8Bcc",
  MOON_OR_DOOM_NATIVE_TOKEN_ETH: "0x693B37a9859Ce9465Fb2aAdeB03811a26A0c37C0",
  ORBIT: "0x42E12D42b3d6C4A74a88A61063856756Ea2DB357",
  PAC: "0x5ffd9EbD27f2fcAB044c0f0a26A45Cb62fa29c06",
  POKE_THE_BEAR: "0x0000000000aCc01064aA5280da3F1C41A35827bc",
  PROTOCOL_FEES_DISTRIBUTOR_ETH: "0xBf9Ae848bEd1827176640088E6c5e2E284407040",
  PROTOCOL_FEES_DISTRIBUTOR_YOLO: "0x6c8e7B36EFCf658CEA4168931Fb22ACC94Cb0C20",
  QUANTUM: "0xA596f7D6587DE656d0Ef099d2F28fe699060BF97",
  TRANSFER_MANAGER: "0x00000000007FE8d7666BB0da2A5D13f72b8dABaB",
  TRANSFER_MANAGER_CLASSIC_GAMES: "0x00000000007FE8d7666BB0da2A5D13f72b8dABaB",
  USDB: "0x4300000000000000000000000000000000000003",
  VAULT_SHARES_YETH_TOKEN: "0xCf09205D04647B0dbF99fe702113358DF764cED3",
  VAULT_SHARES_YYOLO_TOKEN: "0x8c3EDe5dB70719aB9191655994880b088DD03917",
  YES: "0x20fE91f17ec9080E3caC2d688b4EcB48C5aC3a9C",
  YG_SEASON_1_REWARDS_DISTRIBUTOR: "0x119Ad581D07a0D8a5746798aA1367e8c54162961",
  YG_SEASON_1_SHARES_DISTRIBUTOR: "0x84A9B331a21B04Cc7F1d9719Cb5c1acA122cc1D8",
  YIELD: "0x67fa2887914fA3729e9EED7630294Fe124f417A0",
  YOLO: "0xf77dd21c5ce38ac08786BE35Ef1d1DeC1a6a15F3",
  YOLO_V2: "0x0000000000E14E87e5c80A8A90817308fFF715d3",
};

/**
 * @Warning Hardhat addresses should be added in `@looksrare/config` consumer. Certain parts of the application may break when using hardhat network.
 */
export const addressesByNetwork: { [chainId in ChainId]: Addresses } = {
  /**
   * YoloGames
   */
  [ChainId.BLAST_SEPOLIA]: blastSepoliaAddresses,
  [ChainId.BLAST_MAINNET]: blastMainnetAddresses,

  /**
   * LooksRare
   */
  [ChainId.MAINNET]: mainnetAddresses,
  [ChainId.GOERLI]: goerliAddresses,
  [ChainId.SEPOLIA]: sepoliaAddresses,
  [ChainId.ARB_MAINNET]: arbitrumAddresses,
  [ChainId.ARB_SEPOLIA]: arbitrumSepoliaAddresses,
  [ChainId.HARDHAT]: {} as Addresses,
};

/**
 * @Warning Hardhat addresses should be added in `@looksrare/config` consumer. Certain parts of the application may break when using hardhat network.
 */
export const addressesByNetworkInvasion: { [chainId in ChainId]: Addresses } = {
  [ChainId.BLAST_SEPOLIA]: blastLooksrareSepoliaAddresses,
  [ChainId.BLAST_MAINNET]: blastLooksrareMainnetAddresses,

  /**
   * To please type system but not needed.
   */
  [ChainId.MAINNET]: mainnetAddresses,
  [ChainId.GOERLI]: goerliAddresses,
  [ChainId.SEPOLIA]: sepoliaAddresses,
  [ChainId.ARB_MAINNET]: arbitrumAddresses,
  [ChainId.ARB_SEPOLIA]: arbitrumSepoliaAddresses,
  [ChainId.HARDHAT]: {} as Addresses,
};
