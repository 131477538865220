import { IS_TESTNET } from "@looksrare/config";

/**
 * Invasion is on Blast and so is YoloGames.
 * We have a shared config which conflicts and is mutuall exclusive.
 *
 * This is a workaround.
 */
export const useCurrentInvasionChainInfo = () => {
  if (IS_TESTNET) {
    return {
      label: "Blast Sepolia",
      appUrl: "https://sepolia.looksrare.org",
      explorer: "https://testnet.blastscan.io",
      rpcUrl: "https://sepolia.blast.io",
      baseApiUrl: "https://graphql-sepolia.looksrare.org",
      wsUrl: "wss://ws-sepolia.looksrare.org/ws",
      cdnUrl: "https://static-sepolia.looksnice.org",
      cloudinaryUrl: "https://looksrare.mo.cloudinary.net/sepolia",

      // N/A
      rewardsSubgraphUrl: "",
      osApiUrl: "",
    };
  } else {
    return {
      label: "Blast",
      appUrl: "https://looksrare.org",
      explorer: "https://blastscan.io",
      rpcUrl: "https://rpc.blast.io",
      baseApiUrl: "https://graphql.looksrare.org",
      wsUrl: "wss://ws.looksrare.org/ws",

      // For potential backwards compatibility of asset urls
      // use looksnice. Important to note this should not actually be called.
      cdnUrl: "https://static.looksnice.org",
      cloudinaryUrl: "https://looksrare.mo.cloudinary.net",

      // N/A
      rewardsSubgraphUrl: "",
      osApiUrl: "",
    };
  }
};
