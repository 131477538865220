import { gql } from "graphql-request";
import { useAccount } from "wagmi";
import { graphql } from "@looksrare/utils";
import { MutationOptions, useMutation, useQuery } from "@tanstack/react-query";
import { getAuthHeader } from "@/utils";
import { SuccessPayload } from "@/types";

const addAffiliateQuery = gql`
  mutation AddYgAffiliate($referralCode: String!) {
    addAffiliate(referralCode: $referralCode) {
      success
    }
  }
`;

export const useAddAffiliateMutation = (
  options?: MutationOptions<{ addAffiliate: SuccessPayload }, unknown, string>
) => {
  const { address } = useAccount();
  return useMutation({
    mutationFn: (referralCode) => {
      const headers = getAuthHeader(address);
      return graphql({
        query: addAffiliateQuery,
        params: { referralCode },
        requestHeaders: headers,
      });
    },
    ...options,
  });
};

interface ReferralCodeValidityResponse {
  isReferralCodeValid: boolean;
}

const getIsReferralCodeValid = async (referralCode: string) => {
  const query = gql`
    query IsReferralCodeValid($referralCode: String!) {
      isReferralCodeValid(referralCode: $referralCode)
    }
  `;

  const res: ReferralCodeValidityResponse = await graphql({ query, params: { referralCode } });
  return res.isReferralCodeValid;
};

export const useIsReferralCodeValid = (
  referralCode: string,
  { enabled, onSuccess }: { enabled?: boolean; onSuccess?: (data: boolean) => void }
) => {
  return useQuery({
    queryKey: ["isReferralCodeValid", referralCode],
    queryFn: async () => {
      const isValid = await getIsReferralCodeValid(referralCode);
      onSuccess?.(isValid);
      return isValid;
    },
    enabled,
  });
};
