import { Icon, IconProps } from "../Icon";

const Fire = (props: IconProps) => (
  <Icon {...props}>
    <path
      d="M10.0781 24.3093C10.0781 25.7185 10.5494 26.9539 10.9911 27.8021C11.2088 28.2203 10.8445 28.7172 10.4186 28.5147C8.72141 27.7078 6 25.6864 6 21.0193C6 12.5085 15.9639 11.2107 15.9639 4.78593C15.9639 4.26284 15.9247 3.80682 15.8693 3.42361C15.801 2.95109 16.3461 2.53455 16.721 2.83014C18.4976 4.23078 21.6014 7.46867 21.6014 12.9953C21.6014 14.505 21.4864 15.6513 21.357 16.4634C21.2802 16.9456 21.8926 17.3672 22.2306 17.0149C22.783 16.4392 23.1828 15.7193 23.3739 14.6311C23.4592 14.1447 24.0428 14.0046 24.2283 14.4623C24.7683 15.7944 25.4111 18.0085 25.4111 21C25.4111 26.33 22.2281 28.1445 20.5358 28.7326C20.1353 28.8718 19.8174 28.4337 19.9717 28.0388C20.2025 27.4481 20.4326 26.6296 20.4326 25.7128C20.4326 20.8051 16.1606 21.4042 15.6605 17.0459C15.6164 16.6621 15.1941 16.4211 14.8712 16.6332C11.278 18.9932 10.0781 21.8218 10.0781 24.3093Z"
      fill="currentColor"
    />
  </Icon>
);

export default Fire;
