import { useTranslation } from "next-i18next";
import { Button, ButtonProps } from "@looksrare/uikit";
import { useDisconnectWallet } from "@/hooks/useDisconnectWallet";

export const DisconnectWalletButton: React.FC<React.PropsWithChildren<ButtonProps>> = ({ children, ...props }) => {
  const { t } = useTranslation();
  const handleClick = useDisconnectWallet();

  return (
    <Button data-id="disconnect-wallet-button-disconnect" onClick={handleClick} {...props}>
      {children || t("Disconnect")}
    </Button>
  );
};
