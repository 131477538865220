import { Icon, IconProps } from "../Icon";

const ListRelaxed = (props: IconProps) => (
  <Icon {...props}>
    <g id="layout/list-relaxed">
      <path
        id="Fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4C2.89543 4 2 4.89543 2 6V12C2 13.1046 2.89543 14 4 14H28C29.1046 14 30 13.1046 30 12V6C30 4.89543 29.1046 4 28 4H4ZM4 6L28 6V12H4V6ZM4 18C2.89543 18 2 18.8954 2 20V26C2 27.1046 2.89543 28 4 28H28C29.1046 28 30 27.1046 30 26V20C30 18.8954 29.1046 18 28 18H4ZM4 20H28V26H4V20Z"
        fill="currentColor"
      />
    </g>
  </Icon>
);

export default ListRelaxed;
