import { Icon, IconProps } from "../Icon";

const LuckySpin = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.9231 8.55724C27.226 7.85896 26.277 7.47087 25.2904 7.48055L24.5159 7.48402L24.5193 6.7095C24.5275 5.72323 24.1394 4.77497 23.4419 4.07755C22.7445 3.38012 21.7963 2.99195 20.81 3.00013C18.7422 3.01856 17.0183 4.6417 16.9999 6.7095V15H25.2904C27.3582 14.9816 28.9813 13.2577 28.9998 11.1899C29.0083 10.2035 28.6204 9.25496 27.9231 8.55724Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.9231 23.4428C27.226 24.1411 26.277 24.5291 25.2904 24.5195L24.5159 24.516L24.5193 25.2905C24.5275 26.2768 24.1394 27.225 23.4419 27.9225C22.7445 28.6199 21.7963 29.0081 20.81 28.9999C18.7422 28.9814 17.0183 27.3583 16.9999 25.2905V17L25.2904 17.0521C27.3582 17.0705 28.9813 18.7423 28.9998 20.8101C29.0083 21.7965 28.6204 22.7451 27.9231 23.4428Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.07683 8.55723C4.77392 7.85895 5.72287 7.47086 6.7095 7.48054L7.48402 7.48402L7.48055 6.70949C7.47236 5.72322 7.86054 4.77497 8.55797 4.07754C9.25539 3.38012 10.2036 2.99194 11.1899 3.00013C13.2577 3.01856 14.9816 4.69032 15 6.75812V15H6.7094C4.6416 14.9816 3.01857 13.2577 3.00014 11.1899C2.99157 10.2035 3.37949 9.25495 4.07683 8.55723Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.07672 23.4428C4.77382 24.1411 5.72277 24.5291 6.7094 24.5195L7.48392 24.516L7.48044 25.2905C7.47226 26.2768 7.86044 27.225 8.55786 27.9225C9.25528 28.6199 10.2035 29.0081 11.1898 28.9999C13.2576 28.9814 14.9815 27.3583 14.9999 25.2905V17L6.7094 17.0521C4.6416 17.0705 3.01846 18.7423 3.00003 20.8101C2.99146 21.7965 3.37938 22.7451 4.07672 23.4428Z"
      fill="currentColor"
    />
  </Icon>
);

export default LuckySpin;
