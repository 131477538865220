import { Icon, IconProps } from "../Icon";

const LooksToken = (props: IconProps) => (
  <Icon viewBox="0 0 96 96" {...props}>
    <rect width="96" height="96" rx="48" fill="black" />
    <path
      d="M29.3362 34.0924C39.6439 23.8431 56.356 23.8431 66.6637 34.0924L75.5675 42.9459L66.6637 51.7994C56.356 62.0488 39.6439 62.0488 29.3362 51.7994L20.4324 42.9459L29.3362 34.0924Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 42.9599L34.2162 22.7297H61.7838L82 42.9599L48 76.9459L14 42.9599ZM63.6217 35.5945C55.0319 26.9671 40.9682 26.9671 32.3784 35.5945L25.0271 42.946L32.3784 50.2974C40.9682 58.9248 55.0319 58.9248 63.6217 50.2973L70.973 42.946L63.6217 35.5945Z"
      fill="#0CE466"
    />
    <path
      d="M47.9999 49.3784C44.4489 49.3784 41.5675 46.4999 41.5675 42.946C41.5675 39.392 44.4489 36.5135 47.9999 36.5135C51.551 36.5135 54.4324 39.392 54.4324 42.946C54.4324 46.4999 51.551 49.3784 47.9999 49.3784Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.4324 42.9459C37.4324 48.7846 42.166 53.5135 47.9999 53.5135C53.8339 53.5135 58.5675 48.7846 58.5675 42.9459C58.5675 37.1073 53.8339 32.3784 47.9999 32.3784C42.166 32.3784 37.4324 37.1073 37.4324 42.9459ZM43.4053 42.9459C43.4053 45.4845 45.4634 47.5405 47.9999 47.5405C50.5364 47.5405 52.5945 45.4845 52.5945 42.9459C52.5945 40.4074 50.5364 38.3514 47.9999 38.3514C45.4634 38.3514 43.4053 40.4074 43.4053 42.9459Z"
      fill="white"
    />
  </Icon>
);

export default LooksToken;
