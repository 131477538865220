import { ReactNode } from "react";
import { Hash } from "viem";
import { Box, Flex, Spinner, HStack, ModalBody } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { getExplorerLink } from "@looksrare/utils";
import { Button } from "../../Button";
import { CheckmarkOutlineIcon, MisuseAltIcon } from "../../Icons";
import { ExternalLink } from "../../Link";
import { ModalProps } from "../../Modal";
import { Text } from "../../Text";

export interface ConfirmInWalletModalBodyProps {
  onClose: ModalProps["onClose"];
  onRetry?: () => void;
  txConfirmedTitle?: string;
  txConfirmedText?: string;
  actionText?: string;
  actionHandler?: () => void;
  bodyMetaComponent?: ReactNode;
  isTxConfirmed?: boolean;
  isTxSending?: boolean;
  isTxWaiting?: boolean;
  isTxError?: boolean;
  txResponse?: Hash | null;
}

export const ConfirmInWalletModalBody = ({
  onClose,
  onRetry,
  actionText,
  actionHandler,
  bodyMetaComponent,
  isTxConfirmed,
  isTxSending,
  isTxWaiting,
  isTxError,
  txResponse,
}: ConfirmInWalletModalBodyProps) => {
  const { t } = useTranslation();
  const getStatusText = () => {
    if (isTxError) {
      return (
        <Text textStyle="detail" color="text-error" bold>
          {t("You declined the transaction")}
        </Text>
      );
    }

    if (isTxWaiting) {
      return (
        <Text textStyle="detail" bold>
          {t("Confirming on chain...")}
        </Text>
      );
    }

    if (isTxSending) {
      return (
        <Text textStyle="detail" bold>
          {t("Waiting for you to confirm in wallet")}
        </Text>
      );
    }

    return null;
  };

  const getIcon = () => {
    if (isTxError) {
      return <MisuseAltIcon boxSize={5} color="text-error" />;
    }

    if (isTxConfirmed) {
      return <CheckmarkOutlineIcon boxSize={5} color="text-disabled" />;
    }

    return <Spinner color="interactive-03" size="sm" />;
  };

  return (
    <>
      <Box px={8} py={6}>
        {bodyMetaComponent}
      </Box>
      <ModalBody pb={8} px={8} pt={0}>
        <Box py={6} px={4} bg="ui-bg" borderRadius="container">
          {!isTxConfirmed && <Box mb={4}>{getStatusText()}</Box>}
          <Flex alignItems="center">
            <Text textStyle="detail" color={isTxConfirmed ? "text-03" : "text-01"}>
              {t("Transaction")}
            </Text>
            {txResponse && (
              <Text textStyle="detail">
                <ExternalLink
                  color={isTxConfirmed ? "text-03" : "text-01"}
                  href={getExplorerLink(txResponse, "transaction")}
                />
              </Text>
            )}
            <Box ml="auto">{getIcon()}</Box>
          </Flex>
          {isTxError && onRetry && (
            <Flex mt={4}>
              <Button w="100%" size="xs" onClick={onRetry} autoFocus>
                {t("Retry")}
              </Button>
            </Flex>
          )}
        </Box>
      </ModalBody>
      {isTxConfirmed && (
        <HStack justifyContent="end" width="100%" px={8} pb={8}>
          <Button colorScheme="gray" onClick={onClose} flex={1} autoFocus>
            {t("Close")}
          </Button>
          {actionHandler && actionText && (
            <Button onClick={actionHandler} flex={1}>
              {actionText}
            </Button>
          )}
        </HStack>
      )}
    </>
  );
};
