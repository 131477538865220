import { Icon, IconProps } from "../Icon";

const Undo = (props: IconProps) => (
  <Icon {...props}>
    <g id="undo">
      <path
        id="Fill"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 15.0001C25.5 15.0001 27 16.5001 27 19.0001C27 21.5001 25.5 23.0001 23 23.0001H18V25.0001H23C26.5 25.0001 29 22.5001 29 19.0001C29 15.5001 26.5 13.0001 23 13.0001H6.8L10.8 9.0001L9.4 7.6001L3 14.0001L9.4 20.4001L10.8 19.0001L6.8 15.0001H23Z"
        fill="currentColor"
      />
    </g>
  </Icon>
);

export default Undo;
