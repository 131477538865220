import { FC, PropsWithChildren } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { AddFundsModal, SupportedCurrency } from "../Modals";
import { Button, ButtonProps } from "./Button";

interface AddFundsButtonProps extends ButtonProps {
  defaultBuyCurrency?: SupportedCurrency;
  onClose?: () => void;
  /* Amount in decimals (not WEI!) the user should buy. Note MoonPay has minimum and maximum ranges in fiat equivalent,
  which also vary by account, so the iframe input might reset to empty and display a relative warning */
  defaultBuyAmount?: number;
}

export const AddFundsButton: FC<PropsWithChildren<AddFundsButtonProps>> = ({
  children,
  defaultBuyCurrency,
  onClose,
  defaultBuyAmount,
  ...props
}) => {
  const { t } = useTranslation();
  const disclosure = useDisclosure();

  const handleClose = () => {
    disclosure.onClose();
    onClose?.();
  };

  return (
    <>
      <Button variant="outline" onClick={disclosure.onOpen} {...props}>
        {children || t("Buy {{token}}", { token: "LOOKS" })}
      </Button>
      <AddFundsModal
        isOpen={disclosure.isOpen}
        onClose={handleClose}
        defaultBuyCurrency={defaultBuyCurrency}
        defaultBuyAmount={defaultBuyAmount}
      />
    </>
  );
};
