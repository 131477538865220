import { InfiniteData, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { Address } from "viem";
import { RQueryOptions, RQueryOptionsForFetcher } from "@looksrare/utils";
import {
  MoDAssetPair,
  MoDContract,
  MoDHistoricalPrice,
  MoDRoundFragment,
  MoDUnclaimedEntryFragment,
} from "@looksrare/yolo-games-gql-typegen";
import {
  MoDPagination,
  MoDRoundInput,
  MoDRoundsInput,
  getMoDHistoricalPrices,
  getMoDRound,
  getMoDRounds,
  getMoDStats,
  getMoDUnclaimedEntries,
} from "./queries";

const BASE_MOONDOOM_QUERY_KEY = "moondoom";
const STALE_TIME_IN_MS = 5_000; // 5 seconds
const DEFAULT_PAGINATION = { first: 10 };

export const useMoDRounds = (input: MoDRoundsInput, { refetchInterval }: { refetchInterval: number }) => {
  return useInfiniteQuery<
    MoDRoundFragment[],
    Error,
    InfiniteData<MoDRoundFragment[], MoDPagination>,
    unknown[],
    MoDPagination
  >({
    queryKey: [BASE_MOONDOOM_QUERY_KEY, "rounds", input],
    queryFn: ({ pageParam }) => getMoDRounds({ ...input, pagination: pageParam }),
    getNextPageParam: (lastPage) => {
      const pagination = input.pagination ?? DEFAULT_PAGINATION;

      if (!lastPage || lastPage.length < pagination.first) {
        return undefined;
      }

      const lastItem = lastPage[lastPage.length - 1];

      return {
        ...pagination,
        cursor: lastItem.id.toString(),
      };
    },
    initialPageParam: DEFAULT_PAGINATION,
    refetchInterval,
  });
};

export const useMoDRound = (input: MoDRoundInput, queryOptions?: RQueryOptionsForFetcher<typeof getMoDRound>) => {
  return useQuery({
    queryKey: [BASE_MOONDOOM_QUERY_KEY, "round", input],
    queryFn: () => getMoDRound(input),
    ...queryOptions,
  });
};

export const useMoDRecentRounds = (
  input: Omit<MoDRoundsInput, "pagination">,
  queryOptions?: RQueryOptionsForFetcher<typeof getMoDRounds>
) => {
  return useQuery({
    queryKey: [BASE_MOONDOOM_QUERY_KEY, "recentRounds", input],
    queryFn: async () => getMoDRounds({ pagination: { first: 11 }, ...input }),
    staleTime: STALE_TIME_IN_MS,
    ...queryOptions,
  });
};

export const useMoDCurrentRound = (
  input: Omit<MoDRoundInput, "id">,
  queryOptions?: RQueryOptions<MoDRoundFragment>
) => {
  return useQuery({
    queryKey: [BASE_MOONDOOM_QUERY_KEY, "currentRound", input],
    queryFn: async () => {
      const results = await getMoDRounds({
        filter: { contract: input.contract },
        pagination: { first: 2 },
        player: input.player,
      });

      return results[1];
    },
    staleTime: STALE_TIME_IN_MS,
    ...queryOptions,
  });
};

export const useMoDNextRound = (input: Omit<MoDRoundInput, "id">, queryOptions?: RQueryOptions<MoDRoundFragment>) => {
  return useQuery({
    queryKey: [BASE_MOONDOOM_QUERY_KEY, "nextRound", input],
    queryFn: async () => {
      const results = await getMoDRounds({
        filter: { contract: input.contract },
        pagination: { first: 1 },
        player: input.player,
      });

      return results[0];
    },
    staleTime: STALE_TIME_IN_MS,
    ...queryOptions,
  });
};

export const useMoDStats = (contract: MoDContract, queryOptions?: RQueryOptionsForFetcher<typeof getMoDStats>) => {
  return useQuery({
    queryKey: [BASE_MOONDOOM_QUERY_KEY, "stats"],
    queryFn: () => getMoDStats(contract),
    ...queryOptions,
  });
};

export const useMoDHistoricalPrices = (pair: MoDAssetPair, queryOptions?: RQueryOptions<MoDHistoricalPrice[]>) => {
  return useQuery({
    queryKey: [BASE_MOONDOOM_QUERY_KEY, "historicalPrices", pair],
    queryFn: () => getMoDHistoricalPrices(pair),
    ...queryOptions,
  });
};

export type MoDUnclaimedEntries = Record<MoDContract, MoDUnclaimedEntryFragment[]>;

export const useMoDUnclaimedEntries = (
  address: Address,
  contracts: MoDContract[],
  queryOptions?: RQueryOptions<MoDUnclaimedEntries>
) => {
  return useQuery({
    queryKey: [BASE_MOONDOOM_QUERY_KEY, "unclaimedEntries", contracts],
    queryFn: async () => {
      const results = await Promise.all(contracts.map((contract) => getMoDUnclaimedEntries(address, contract)));

      return contracts.reduce((acc, contract, index) => {
        if (results?.[index] && results[index].length > 0) {
          acc[contract] = results[index];
        }

        return acc;
      }, {} as MoDUnclaimedEntries);
    },
    staleTime: STALE_TIME_IN_MS,
    ...queryOptions,
  });
};
