import { forwardRef, Input as ChakraInput, InputProps as ChakraInputProps } from "@chakra-ui/react";

export interface InputProps extends ChakraInputProps {
  isActive?: boolean;
}

export const Input = forwardRef<InputProps, "input">(({ isActive = false, ...props }, ref) => {
  const propsWithBg = { ...(props?.variant === "lowcontrast" && isActive ? { bg: "field-02" } : {}), ...props };

  return <ChakraInput ref={ref} {...propsWithBg} />;
});
