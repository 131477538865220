import { MoDResult } from "@looksrare/yolo-games-gql-typegen";

export const getMoDResultColor = (result: MoDResult): string => {
  switch (result) {
    case "MOON":
      return "moondoom-moon";
    case "DOOM":
      return "moondoom-doom";
    case "HOUSE":
      return "acid.200";
  }
};
