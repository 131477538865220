import { Box, HStack, Stack, StackProps, forwardRef } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { BtcTokenIcon, EthTokenIcon, NextLink, Popover, Text } from "@looksrare/uikit";
import { ChainId } from "@looksrare/config";
import { MoDAssetPair, MoDContract } from "@looksrare/yolo-games-gql-typegen";
import { getMoDUrl } from "../../../utils/getMoDUrl";
import { useMoDNextRound } from "../../../network/graphql/hooks";
import { AssetPairTopPayout } from "./AssetPairTopPayout";

interface AssetPairDataProps extends StackProps {
  chainId: ChainId;
  contract: MoDContract;
  assetPair: MoDAssetPair;
  isSelected: boolean;
  onClick?: () => void;
}

export const AssetPairData = forwardRef<AssetPairDataProps, "div">(
  ({ chainId, contract, assetPair, isSelected, onClick, ...props }, ref) => {
    const { t } = useTranslation();
    const { data: roundData } = useMoDNextRound({ contract }, { refetchInterval: 3_500 });

    const roundDurationInMins = Math.ceil((roundData?.setting.roundIntervalSecs ?? 60) / 60);
    const isNotEntered = BigInt(roundData?.moonAmount ?? "0") === 0n && BigInt(roundData?.doomAmount ?? "0") === 0n;

    const TokenIcon = (() => {
      switch (assetPair) {
        case "BTCUSD":
          return BtcTokenIcon;
        case "ETHUSD":
          return EthTokenIcon;
      }
    })();

    return (
      <HStack
        as={NextLink}
        ref={ref}
        href={getMoDUrl(chainId, assetPair)}
        onClick={onClick}
        spacing={2}
        px={3}
        py={1}
        {...props}
      >
        <TokenIcon boxSize={7} />

        <HStack justifyContent="space-between" flex={1}>
          <Stack spacing={0}>
            <Text textStyle="detail" color={isSelected ? "text-01" : "text-03"} transition="color 300ms" bold>
              {assetPair}
            </Text>
            <Text textStyle="caption" color={isSelected ? "text-02" : "text-03"} lineHeight="12px" bold>
              {t("{{minutes}} min", { minutes: roundDurationInMins })}
            </Text>
          </Stack>

          <Stack spacing={0} alignItems="end" display={{ base: "none", sm: "flex" }}>
            <Popover
              label={
                <Text textStyle="detail" color="text-inverse">
                  {t("mod::Top payout ratio of next round.")}
                </Text>
              }
              placement="top"
              variant="tooltip"
              renderInPortal
            >
              {isNotEntered ? (
                <Text textStyle="detail" color="text-03" bold>
                  —
                </Text>
              ) : (
                <Box>
                  <AssetPairTopPayout
                    moonPayoutRatio={roundData?.moonPayoutRatio ?? 0}
                    doomPayoutRatio={roundData?.doomPayoutRatio ?? 0}
                  />
                </Box>
              )}
            </Popover>
            <Text textStyle="caption" color="text-03" whiteSpace="nowrap">
              {t("mod::Top Payout")}
            </Text>
          </Stack>
        </HStack>
      </HStack>
    );
  }
);
