import { Box, Flex, ModalBody, Spinner } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button, MisuseAltIcon, Text } from "@looksrare/uikit";
import { SignInStatus } from "@looksrare/utils";
import { SignInModalProps } from ".";

type SignInModalBodyProps = Pick<SignInModalProps, "txStatus" | "signInHandler">;

export const SignInModalBody = ({ txStatus, signInHandler }: SignInModalBodyProps) => {
  const { t } = useTranslation();
  const isTrouble = [SignInStatus.SIGN_FAILED, SignInStatus.ERROR].includes(txStatus);

  const getStatusText = () => {
    switch (txStatus) {
      case SignInStatus.SIGNING:
        return (
          <Text textStyle="detail" textAlign="left" bold>
            {t("Waiting for you to sign in")}
          </Text>
        );
      case SignInStatus.ERROR:
        return (
          <Text textStyle="detail" textAlign="left" color="text-error" bold>
            {t("An error occurred")}
          </Text>
        );
      case SignInStatus.SIGN_FAILED:
        return (
          <Text textStyle="detail" textAlign="left" color="text-error" bold>
            {t("You declined the signature")}
          </Text>
        );
      default:
        return null;
    }
  };

  const getIcon = () => {
    switch (txStatus) {
      case SignInStatus.SIGN_FAILED:
      case SignInStatus.ERROR:
        return <MisuseAltIcon boxSize={5} color="text-error" />;
      case SignInStatus.SIGNING:
      default:
        return <Spinner color="interactive-03" size="sm" />;
    }
  };

  return (
    <>
      <ModalBody px={8} pb={8} pt={0}>
        <Box py={6} px={4} bg="ui-bg" borderRadius="container">
          <Box mb={4}>{getStatusText()}</Box>
          <Flex alignItems="center">
            <Text textStyle="detail" color="text-01">
              {t("Transaction")}
            </Text>

            <Box ml="auto">{getIcon()}</Box>
          </Flex>
          {isTrouble && (
            <Flex mt={4}>
              <Button w="100%" size="xs" onClick={signInHandler} autoFocus>
                {t("Retry")}
              </Button>
            </Flex>
          )}
        </Box>
      </ModalBody>
    </>
  );
};
