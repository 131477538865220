import { Address, WalletClient } from "viem";
import { getLoginMessage } from "../graphql";
import { login } from "./login";

/**
 * Request a signature from the user, and perform a login
 * @param signer Ethers signer
 * @param account User address
 * @returns Json Web Token
 */
export const signAndLogin = async (walletClient: WalletClient, account: Address): Promise<string> => {
  const message = await getLoginMessage();
  const signature = await walletClient.signMessage({ account, message });
  const jwt = await login(account, message, signature);
  return jwt;
};
