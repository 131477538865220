export const fontSizes = {
  "2xs": "0.625rem",
  "1xs": "0.6875rem",
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1rem",
  lg: "1.25rem",
  xl: "1.5rem",
  "1xl": "1.75rem",
  "2xl": "2rem",
  "3xl": "2.25rem",
  "4xl": "2.5rem",
  "5xl": "2.75rem",
  "6xl": "3rem",
  "7xl": "3.5rem",
  "8xl": "4rem",
  "9xl": "5.5rem",
};

export const fontWeights = {
  normal: 400,
  bold: 700,
};

export const lineHeights = {
  xs: "1rem",
  sm: "1.25rem",
  md: "1.5rem",
  lg: "2rem",
  xl: "2.5rem",
  "2xl": "3rem",
  "3xl": "3.25rem",
  "4xl": "3.5rem",
  "5xl": "4.5rem",
};

export const fonts = {
  heading: "'Basis Grotesque Pro', sans-serif",
  body: "'Basis Grotesque Pro', sans-serif",
};

export type FontSizesType = keyof typeof fontSizes;
export type FontWeightsType = keyof typeof fontWeights;
export type LineHeightsType = keyof typeof lineHeights;

export const typography = { fontSizes, fontWeights, lineHeights, fonts };
