import { useAccount } from "wagmi";
import { HStack, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Button, NextLink, Text } from "@looksrare/uikit";
import { isAddressEqual, type BigIntish } from "@looksrare/utils";
import type { PtbContractName } from "../../types";
import { ClaimFundsModal } from "../Modals/ClaimFunds";
import { EnterCaveButton } from "../Buttons";
import { getRoundUrl, getNetworkFromPtbContractName } from "../../utils";
import { useUnclaimedPtbFunds, useNextAvailableRound } from "../../hooks";
import { Wrapper } from "./shared";

interface RoundEndedProps {
  caveOnChainId: BigIntish;
  contract: PtbContractName;
  isRoundCancelled: boolean;
  isParticipating: boolean;
}

export const RoundEnded = ({ caveOnChainId, contract, isRoundCancelled, isParticipating }: RoundEndedProps) => {
  const { address } = useAccount();
  const { t } = useTranslation();
  const claimFundsModalDisclosure = useDisclosure();
  const network = getNetworkFromPtbContractName(contract);
  const { unclaimedWinnings, unclaimedRefunds } = useUnclaimedPtbFunds(network);
  const nextRoundQuery = useNextAvailableRound(caveOnChainId, contract);

  const hasWinnings = !!unclaimedWinnings?.length;
  const hasRefunds = !!unclaimedRefunds?.length;
  const isPlayerInNextRound = nextRoundQuery.data?.players.some((player) =>
    isAddressEqual(address, player.user.address)
  );

  const claimButton = (() => {
    if (!hasWinnings && !hasRefunds) {
      return null;
    }

    return (
      <Button variant="outline" colorScheme="secondary" onClick={claimFundsModalDisclosure.onOpen}>
        {hasWinnings ? t("Claim Winnings") : t("Claim Refund")}
      </Button>
    );
  })();

  const rolloverButton = (() => {
    if (isPlayerInNextRound) {
      return (
        <Button
          as={NextLink}
          href={getRoundUrl({
            caveOnChainId,
            network: getNetworkFromPtbContractName(contract),
            roundOnChainId: nextRoundQuery.data?.round?.onChainId,
          })}
        >
          {t("Next Round")}
        </Button>
      );
    }
    const canRollover = (() => {
      const hasRefundsInThisCave = unclaimedRefunds.some((refund) => refund.cave.onChainId === caveOnChainId);
      const hasWinningsInThisCave = unclaimedWinnings.some((winning) => winning.cave.onChainId === caveOnChainId);
      return isRoundCancelled && isParticipating && (hasRefundsInThisCave || hasWinningsInThisCave);
    })();
    return (
      <EnterCaveButton isLoading={nextRoundQuery.isLoading}>
        {canRollover ? t("Rollover Entry") : t("Join Next Round")}
      </EnterCaveButton>
    );
  })();

  return (
    <Wrapper>
      <Text>{isRoundCancelled ? t("This round has been canceled.") : t("This round has ended.")}</Text>
      <HStack spacing={2}>
        {claimButton}
        {rolloverButton}
      </HStack>
      <ClaimFundsModal
        network={network}
        isOpen={claimFundsModalDisclosure.isOpen}
        onClose={claimFundsModalDisclosure.onClose}
      />
    </Wrapper>
  );
};
