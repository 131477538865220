import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const RankOne = (props: IconProps) => {
  const [uid0] = useState(uniqueId());
  const [uid1] = useState(uniqueId());
  const [uid2] = useState(uniqueId());
  const [uid3] = useState(uniqueId());

  return (
    <Icon viewBox="0 0 256 256" {...props}>
      <path
        d="M112 9.24031C121.901 3.52406 134.099 3.52406 144 9.24031L222.851 54.7651C232.752 60.4814 238.851 71.0454 238.851 82.4779V173.528C238.851 184.96 232.752 195.524 222.851 201.24L144 246.765C134.099 252.481 121.901 252.481 112 246.765L33.1484 201.24C23.2476 195.524 17.1484 184.96 17.1484 173.528V82.4779C17.1484 71.0454 23.2476 60.4814 33.1484 54.7651L112 9.24031Z"
        fill={`url(#${uid0})`}
      />
      <path
        d="M81.813 48.001C110.394 31.4997 145.608 31.4997 174.189 48.001C202.77 64.5024 220.377 94.9983 220.377 128.001C220.377 161.004 202.77 191.5 174.189 208.001C145.608 224.502 110.394 224.502 81.813 208.001C53.2318 191.5 35.625 161.004 35.625 128.001C35.625 94.9983 53.2318 64.5024 81.813 48.001Z"
        fill={`url(#${uid1})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.313 48.8671C54.0412 65.1898 36.625 95.3555 36.625 128.001C36.625 160.647 54.0412 190.812 82.313 207.135C110.585 223.458 145.417 223.458 173.689 207.135C201.961 190.812 219.377 160.647 219.377 128.001C219.377 95.3555 201.961 65.1898 173.689 48.8671C145.417 32.5443 110.585 32.5443 82.313 48.8671ZM174.189 48.001C145.608 31.4997 110.394 31.4997 81.813 48.001C53.2318 64.5024 35.625 94.9983 35.625 128.001C35.625 161.004 53.2318 191.5 81.813 208.001C110.394 224.502 145.608 224.502 174.189 208.001C202.77 191.5 220.377 161.004 220.377 128.001C220.377 94.9983 202.77 64.5024 174.189 48.001Z"
        fill="black"
      />
      <g filter={`url(#${uid2})`}>
        <path
          d="M118.106 77.9609H139.886V151.221H177.242V173.001H80.75V151.221H118.106V100.797L80.75 111.885V89.0489L118.106 77.9609Z"
          fill="#D9D9D9"
        />
        <path
          d="M118.106 77.9609H139.886V151.221H177.242V173.001H80.75V151.221H118.106V100.797L80.75 111.885V89.0489L118.106 77.9609Z"
          fill={`url(#${uid3})`}
        />
      </g>
      <defs>
        <filter
          id={uid2}
          x="60.2576"
          y="57.4685"
          width="147.723"
          height="146.27"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="5.12311" dy="5.12311" />
          <feGaussianBlur stdDeviation="12.8078" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
          <feBlend mode="overlay" in2="BackgroundImageFix" result="effect1_dropShadow_2357_95346" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2357_95346" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1.28078" dy="1.28078" />
          <feGaussianBlur stdDeviation="0.640389" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
          <feBlend mode="soft-light" in2="shape" result="effect2_innerShadow_2357_95346" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1.28078" dy="-1.28078" />
          <feGaussianBlur stdDeviation="0.640389" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.9 0" />
          <feBlend mode="overlay" in2="effect2_innerShadow_2357_95346" result="effect3_innerShadow_2357_95346" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1.28078" dy="-1.28078" />
          <feGaussianBlur stdDeviation="0.640389" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="effect3_innerShadow_2357_95346" result="effect4_innerShadow_2357_95346" />
        </filter>
        <linearGradient id={uid0} x1="256" y1="0.00272228" x2="-21.5038" y2="230.065" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDF307" />
          <stop offset="1" stopColor="#D5CD0B" />
        </linearGradient>
        <linearGradient id={uid1} x1="128.001" y1="21.3344" x2="128.001" y2="234.668" gradientUnits="userSpaceOnUse">
          <stop stopColor="#191B20" />
          <stop offset="1" stopColor="#3D4048" />
        </linearGradient>
        <linearGradient id={uid3} x1="148.143" y1="89.8016" x2="70.4081" y2="142.942" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FDF307" />
          <stop offset="1" stopColor="#D5CD0B" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export default RankOne;
