import { useState } from "react";
import { PtbSupportedNetwork } from "../types";

interface State {
  isOpen: boolean;
  network: PtbSupportedNetwork;
}

/**
 * Helper to avoid duplicating claim funds modals
 */
export const useClaimFundsDisclosure = () => {
  const [state, setState] = useState<State>({
    isOpen: false,
    network: "ethereum",
  });

  const onOpen = (network: PtbSupportedNetwork) => {
    setState({ network, isOpen: true });
  };

  const onClose = () => {
    setState((prevState) => ({ ...prevState, isOpen: false }));
  };

  return { state, onOpen, onClose };
};
