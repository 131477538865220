import { type PropsWithChildren, type FC } from "react";
import { type Address } from "viem";
import { useAccount, useBalance } from "wagmi";
import { Trans, useTranslation } from "next-i18next";
import {
  Box,
  type BoxProps,
  HStack,
  ModalBody,
  type SystemStyleObject,
  Flex,
  Stack,
  type ModalProps,
} from "@chakra-ui/react";
import {
  type BigIntish,
  formatToSignificant,
  formatUsd,
  fromDecimals,
  useCoinPrices,
  multiplyWeiByNumber,
} from "@looksrare/utils";
import {
  AddFundsButton,
  Button,
  HelpIcon,
  IncrementButton,
  MoneyBagIcon,
  Popover,
  Text,
  TooltipText,
} from "@looksrare/uikit";
import { maxFutureRounds } from "../../../config";
import { useCurrencyLabel } from "../../../hooks";
import { CurrencyValueDisplay } from "../../CurrencyValueDisplay";
import { PtbContractName } from "../../../types";
import { getNetworkFromPtbContractName } from "../../../utils";
import { EnterCaveHeader, Row, TextDetail } from "./shared";
import { useEnterCaveStore } from "./state";

interface OverViewProps {
  currency: Address;
  contract: PtbContractName;
  enterAmount: BigIntish;
  totalRolloverBalance: bigint;
  isUsingEth: boolean;
  winningsToClaim?: bigint;
  onConfirm: () => void;
  onClose: ModalProps["onClose"];
}

// @todo-ptb copied from FE, should probably put this in the uitkit
const liSx: SystemStyleObject = {
  ":before": { content: `" \u2022 "`, fontWeight: "bold", marginRight: 2, color: "currentColor" },
};
const Ul: FC<PropsWithChildren<BoxProps>> = (props) => <Box as="ul" p={0} m={0} width="100%" {...props} />;
const Li: FC<PropsWithChildren<BoxProps>> = (props) => (
  <Box as="li" display="inline-flex" alignItems="center" whiteSpace="nowrap" sx={liSx} mr={2} {...props} />
);

const getUsdValue = (value: bigint, usdValue?: number) => {
  if (!usdValue) {
    return "-";
  }
  return formatUsd(parseFloat(fromDecimals(value)) * usdValue);
};

const presetValues = [5, 10, 20, 30];

export const OverView = ({
  currency,
  contract,
  enterAmount,
  totalRolloverBalance,
  isUsingEth,
  winningsToClaim = 0n,
  onConfirm,
  onClose,
}: OverViewProps) => {
  const { address } = useAccount();
  const { t } = useTranslation();
  const currencyLabel = useCurrencyLabel(currency);
  const coinPriceQuery = useCoinPrices();
  const { data: balance } = useBalance({ address, token: isUsingEth ? undefined : currency });
  const [numberOfRounds, setNumberOfRounds] = useEnterCaveStore((state) => [
    state.numberOfRounds,
    state.setNumberOfRounds,
  ]);

  const numberOfRoundsNum = numberOfRounds === 0 ? 1 : numberOfRounds;
  const numberOfRoundsValue = numberOfRounds === 0 ? "" : numberOfRounds;

  const totalValue = multiplyWeiByNumber(enterAmount, numberOfRoundsNum);

  // @NOTE: odd behavior, the balance can be a string before it becomes a bigint. unreliable return value from useBalance();
  const walletBalance = (() => {
    const walletBalanceValueInitial = balance ? balance.value : 0n;
    if (typeof walletBalanceValueInitial === "string") {
      return BigInt(walletBalanceValueInitial);
    }
    return walletBalanceValueInitial;
  })();

  const { rolloverBalanceToUse, walletBalanceToUse } = (() => {
    // Rollover balance covers it
    if (totalRolloverBalance >= totalValue) {
      return { rolloverBalanceToUse: totalValue, walletBalanceToUse: 0n };
    }

    // Some Rollover balance
    if (totalRolloverBalance > 0n) {
      return { rolloverBalanceToUse: totalRolloverBalance, walletBalanceToUse: totalValue - totalRolloverBalance };
    }

    return { rolloverBalanceToUse: 0n, walletBalanceToUse: totalValue };
  })();

  // Display values
  const walletBalanceDisplay = formatToSignificant(balance?.value || "0", 6);
  const walletBalanceToUseDisplay = formatToSignificant(walletBalanceToUse, 6);
  const totalRolloverBalanceDisplay = formatToSignificant(totalRolloverBalance, 6);
  const rolloverBalanceToUseDisplay = formatToSignificant(rolloverBalanceToUse, 6);
  const totalValueDisplay = formatToSignificant(totalValue, 6);
  const winningsClaimFormatted = formatToSignificant(winningsToClaim, 6);

  // USD values
  const pricePerToken = coinPriceQuery.data ? coinPriceQuery.data[currencyLabel.toLowerCase()].price : undefined;
  const totalValueUsdDisplay = getUsdValue(totalValue, pricePerToken);
  const rolloverBalanceToUseUsdDisplay = getUsdValue(rolloverBalanceToUse, pricePerToken);
  const walletBalanceToUseUsdDisplay = getUsdValue(walletBalanceToUse, pricePerToken);

  // Make sure the wallet has enough funds to cover the amount we calculated
  const isSufficientFunds = walletBalance >= walletBalanceToUse;

  const maxNumberOfRounds = maxFutureRounds[getNetworkFromPtbContractName(contract)];

  const handleChange = (_valueAsString: string, valueAsNumber: number) => {
    setNumberOfRounds(Number.isNaN(valueAsNumber) ? 0 : valueAsNumber);
  };

  return (
    <>
      <EnterCaveHeader onClose={onClose}>{t("Purchase Entries")}</EnterCaveHeader>
      <ModalBody bg="ui-bg" p={6}>
        <Row mb={2} gap={2}>
          <Popover
            renderInPortal
            label={
              <TooltipText>
                {t(
                  `ptb::Save on fees by purchasing entries into multiple rounds in one go. You'll automatically enter the current round, plus however many subsequent rounds you've bought into.`
                )}
              </TooltipText>
            }
            placement="top"
            variant="tooltip"
          >
            <HStack spacing={1}>
              <Text bold textStyle={{ base: "body", md: "display-body" }}>
                {t("ptb::Number of Rounds")}
              </Text>
              <HelpIcon boxSize={5} color="text-03" />
            </HStack>
          </Popover>
          <IncrementButton
            step={1}
            min={1}
            max={maxNumberOfRounds}
            precision={1}
            value={numberOfRoundsValue}
            onChange={handleChange}
          />
        </Row>
        <Row justifyContent="end" mb={2}>
          {presetValues.map((value) => {
            return (
              <Button
                key={value}
                size="xs"
                variant="outline"
                colorScheme="secondary"
                ml={2}
                minWidth={16}
                onClick={() => setNumberOfRounds(value)}
              >
                {value}
              </Button>
            );
          })}
          <Button
            size="xs"
            variant="outline"
            colorScheme="primary"
            ml={2}
            minWidth={16}
            onClick={() => setNumberOfRounds(maxNumberOfRounds)}
          >
            {t("Max")}
          </Button>
        </Row>
        <Row borderBottom="1px solid" borderColor="border-01" py={2} mb={3} pl={4}>
          <Text bold>{t("Total Cost")}</Text>
          <CurrencyValueDisplay currency={currency} total={formatToSignificant(totalValue, 6)} />
        </Row>
        <Text bold textStyle="display-body" mb={4}>
          {t("ptb::Payment Sources")}
        </Text>
        <Row mb={2} pl={4}>
          <Box>
            <Popover
              renderInPortal
              label={
                <TooltipText>
                  <Trans i18nKey="ptbRolloverPopover">
                    You{" "}
                    <Text as="span" color="currentcolor" textStyle="detail" bold>
                      can
                    </Text>{" "}
                    rollover your initial entries into:
                    <Ul mb={4} mt={1}>
                      <Li>Rounds that were canceled</Li>
                      <Li>Rounds that you survived</Li>
                    </Ul>
                    You{" "}
                    <Text as="span" color="currentcolor" textStyle="detail" bold>
                      can&apos;t
                    </Text>{" "}
                    rollover winnings you took from players who were killed by the bear. Winnings will be sent to your
                    wallet in the same transaction.
                  </Trans>
                </TooltipText>
              }
              placement="top"
              variant="tooltip"
            >
              <span>
                <HStack spacing={1}>
                  <Text color="text-02" bold>
                    {t("ptb::Rollover Balance")}
                  </Text>
                  <HelpIcon boxSize={5} color="text-03" />
                </HStack>
                <TextDetail>{`Balance: ${totalRolloverBalanceDisplay} ${currencyLabel}`}</TextDetail>
              </span>
            </Popover>
          </Box>
          <Flex alignItems="end" flexDirection="column">
            <CurrencyValueDisplay currency={currency} total={rolloverBalanceToUseDisplay} />
            <TextDetail>{rolloverBalanceToUseUsdDisplay}</TextDetail>
          </Flex>
        </Row>
        <Stack spacing={0}>
          <Row pl={4}>
            <Box>
              <Text color="text-02" bold>
                {t("{{currency}} from Wallet", { currency: currencyLabel })}
              </Text>
              <TextDetail>{`${t("Balance {{amount}}", { amount: walletBalanceDisplay })} ${currencyLabel}`}</TextDetail>
            </Box>
            <Flex alignItems="end" flexDirection="column">
              <CurrencyValueDisplay currency={currency} total={walletBalanceToUseDisplay} />
              <TextDetail>{walletBalanceToUseUsdDisplay}</TextDetail>
            </Flex>
          </Row>
          {!isSufficientFunds && (
            <Flex width="100%" justifyContent="flex-end">
              <TextDetail color="text-error">{t("Insufficient balance")}</TextDetail>
            </Flex>
          )}
        </Stack>
      </ModalBody>
      <Box p={6} bg="ui-01">
        <Stack spacing={6} mb={6}>
          <Row>
            <Box>
              <Text bold textStyle="display-body">
                {t("You Pay")}
              </Text>
              <Text color="text-03" textStyle="detail" bold>
                {t("{{numberOfRoundsNum}} Rounds", { numberOfRoundsNum })}
              </Text>
            </Box>
            <Flex alignItems="end" flexDirection="column">
              <CurrencyValueDisplay currency={currency} total={totalValueDisplay} />
              <TextDetail>{totalValueUsdDisplay}</TextDetail>
            </Flex>
          </Row>
          {!!winningsToClaim && winningsToClaim > 0n && (
            <Flex
              gap={2}
              alignItems="center"
              px={4}
              py={3}
              borderRadius="button"
              border="1px solid"
              borderColor="border-01"
            >
              <MoneyBagIcon color="text-01" />
              <Text textStyle="detail">
                {t(
                  "ptb::{{winningsClaimFormatted}} {{currency}} of unclaimed winnings will also be sent to your wallet",
                  {
                    winningsClaimFormatted,
                    currency: currencyLabel,
                  }
                )}
              </Text>
            </Flex>
          )}
        </Stack>
        {!isSufficientFunds ? (
          <AddFundsButton width="100%">
            {t("ptb::Add {{amount}} {{currency}}", {
              amount: formatToSignificant(walletBalanceToUse - walletBalance),
              currency: currencyLabel,
            })}
          </AddFundsButton>
        ) : (
          <Button width="100%" onClick={onConfirm} isDisabled={numberOfRounds === 0}>
            {t("ptb::Confirm and Pay")}
          </Button>
        )}
      </Box>
    </>
  );
};
