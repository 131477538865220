import { keyframes } from "@chakra-ui/system";
import { Box, BoxProps } from "@chakra-ui/react";

type StatusColor = "green" | "red" | "white";
type Theme = "solid" | "pulse";

interface StatusDotProps extends BoxProps {
  isPulsing?: boolean;
  statusColor?: StatusColor;
  theme?: Theme;
}

const pulse = keyframes({
  "0%": {
    opacity: 0.8,
    transform: "scale(1)",
  },
  "70%": {
    transform: "scale(2.5)",
  },
  "90%": {
    transform: "scale(2.5)",
    opacity: 0,
  },
  "100%": {
    transform: "scale(2.5)",
    opacity: 0,
  },
});

const getColor = (statusColor: StatusColor) => {
  switch (statusColor) {
    case "white":
      return "text-01";
    case "red":
      return "text-error";
    case "green":
    default:
      return "interactive-03";
  }
};

export const StatusDot = ({
  isPulsing = false,
  statusColor = "green",
  theme = "solid",
  boxSize = 1.5,
  ...props
}: StatusDotProps) => {
  const color = getColor(statusColor);
  return (
    <Box position="relative" {...props}>
      <Box position="absolute" borderRadius="circular" boxSize={boxSize} bg={color} />
      <Box
        borderRadius="circular"
        boxSize={boxSize}
        animation={isPulsing ? `${pulse} 2s infinite ease-out` : undefined}
        bg={color}
        transform={theme === "pulse" ? "scale(2)" : undefined}
        opacity={theme === "pulse" ? ".2" : undefined}
      />
    </Box>
  );
};
