import { ModalBody, Stack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useCurrentChainId } from "@looksrare/utils";
import { ChainId } from "@looksrare/config";
import { Button } from "../../Button";
import { Text } from "../../Text";
import { View } from ".";

interface SelectWidgetViewProps {
  setView: (view: View) => void;
}

export const SelectWidgetView = ({ setView }: SelectWidgetViewProps) => {
  const { t } = useTranslation();
  const chainId = useCurrentChainId();

  return (
    <ModalBody>
      <Stack spacing={2}>
        <Button
          onClick={() => setView(View.BRIDGE_FUNDS)}
          width="100%"
          colorScheme="primary"
          data-id="add-funds-modal-move-across-networks"
        >
          {t("Move Across Networks")}
        </Button>
        <Button
          onClick={() => setView(View.UNISWAP_WIDGET)}
          width="100%"
          colorScheme="secondary"
          data-id="add-funds-modal-buy-with-crypto"
        >
          {t("Buy with Crypto")}
        </Button>
        <Button
          onClick={() => setView(View.MOONPAY_WIDGET)}
          width="100%"
          colorScheme="secondary"
          variant="outline"
          data-id="add-funds-modal-buy-with-card"
        >
          {t("Buy with Card")}
        </Button>
        {chainId === ChainId.MAINNET && (
          <Stack spacing={4}>
            <Button
              onClick={() => setView(View.CONVERT_ETH_WETH)}
              width="100%"
              colorScheme="secondary"
              variant="outline"
              data-id="add-funds-modal-convert-eth-weth"
            >
              {t("Swap ETH <> WETH")}
            </Button>
            <Text width="100%" textAlign="center" textStyle="helper" color="text-03">
              {t("Swapping ETH and WETH is free: gas fees only")}
            </Text>
          </Stack>
        )}
      </Stack>
    </ModalBody>
  );
};
