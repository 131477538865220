import { useTranslation } from "next-i18next";
import { ModalLegacy, ModalLegacyProps } from "../../Modal";
import { CheckmarkOutlineIcon, WalletIcon } from "../../Icons";
import { ConfirmInWalletModalBody, ConfirmInWalletModalBodyProps } from "./ConfirmInWalletModalBody";

export interface ConfirmInWalletModalProps extends ConfirmInWalletModalBodyProps {
  isOpen: ModalLegacyProps["isOpen"];
  dataId?: string;
}

export const ConfirmInWalletModal = ({
  isOpen,
  onClose,
  onRetry,
  txConfirmedTitle,
  txConfirmedText,
  actionText,
  actionHandler,
  bodyMetaComponent,
  dataId,
  isTxConfirmed = false,
  isTxSending = false,
  isTxWaiting = false,
  isTxError = false,
  txResponse,
}: ConfirmInWalletModalProps) => {
  const { t } = useTranslation();

  const heroIcon = isTxConfirmed ? (
    <CheckmarkOutlineIcon boxSize={14} mb={4} color="interactive-03" />
  ) : (
    <WalletIcon boxSize={14} mb={4} color="interactive-03" />
  );

  const heroTitle = isTxConfirmed ? t("Confirmed") : t("Confirm in Wallet");

  const heroLabel = isTxConfirmed ? undefined : t("Open your wallet app and confirm the transaction.");

  return (
    <ModalLegacy
      label={heroLabel}
      heroElement={heroIcon}
      title={heroTitle}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      dataId={dataId}
    >
      <ConfirmInWalletModalBody
        onClose={onClose}
        onRetry={onRetry}
        isTxConfirmed={isTxConfirmed}
        isTxSending={isTxSending}
        isTxWaiting={isTxWaiting}
        isTxError={isTxError}
        txConfirmedTitle={txConfirmedTitle}
        txConfirmedText={txConfirmedText}
        actionText={actionText}
        actionHandler={actionHandler}
        bodyMetaComponent={bodyMetaComponent}
        txResponse={txResponse}
      />
    </ModalLegacy>
  );
};
