import dynamic from "next/dynamic";
import { Flex, FlexProps, IconButton, Skeleton } from "@chakra-ui/react";
import { Chevron, NextLink, Text } from "@looksrare/uikit";
import { InviteButton, MuteButton } from "@/components";

const WalletBalance = dynamic(() => import("./WalletBalance").then((module) => module.WalletBalance), {
  ssr: false,
  loading: () => <Skeleton height={10} width={10} />,
});
const ProfileNavButton = dynamic(() => import("./ProfileNavButton").then((module) => module.ProfileNavButton), {
  ssr: false,
  loading: () => <Skeleton height={10} width={10} />,
});

interface TopNavProps extends FlexProps {
  title?: string;
  titleElement?: React.ReactElement;
  breadcrumbHref?: string;
}

export const TopNav = ({ titleElement, title, breadcrumbHref, ...props }: TopNavProps) => {
  return (
    <Flex py={3} px={4} alignItems="center" bg="ui-bg" borderBottom="1px solid" borderColor="border-01" {...props}>
      <Flex width="100%" justifyContent="space-between">
        {/* Left side - Page title */}
        <Flex flexGrow={1} alignItems="center" gap={6}>
          {/* Nav Link */}
          {!!breadcrumbHref && (
            <IconButton
              variant="outline"
              colorScheme="secondary"
              size="sm"
              aria-label="back"
              as={NextLink}
              href={breadcrumbHref}
            >
              <Chevron direction="left" />
            </IconButton>
          )}
          {(titleElement || title) && (
            <>
              {titleElement ? (
                titleElement
              ) : (
                <Text textStyle="display-body" bold>
                  {title}
                </Text>
              )}
            </>
          )}
        </Flex>
        {/* Right side - Buttons & Icons */}
        <Flex gap={2}>
          <InviteButton borderColor="acid.200" />
          <WalletBalance />
          <ProfileNavButton />
          <MuteButton aria-label="mute-button" size="sm" variant="outline" colorScheme="secondary" />
        </Flex>
      </Flex>
    </Flex>
  );
};
