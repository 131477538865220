import { Icon, IconProps } from "../Icon";

const Activity = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 29C11.5963 28.998 11.2334 28.7534 11.08 28.38L6.33 17H2V15H7C7.4037 15.002 7.7666 15.2466 7.92 15.62L12 25.28L20.06 3.65001C20.2065 3.258 20.5815 2.99865 21 3.00001C21.4225 3.00739 21.7948 3.2796 21.93 3.68001L25.72 15H30V17H25C24.5701 17.0011 24.1876 16.7273 24.05 16.32L21 7.00001L12.94 28.35C12.7935 28.742 12.4185 29.0014 12 29Z"
      fill="currentColor"
    />
  </Icon>
);

export default Activity;
