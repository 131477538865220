import { zeroAddress, type Address } from "viem";
import { type SupportedNetwork, getChainIdFromSupportedNetwork, isAddressEqual } from "@looksrare/utils";
import { IconProps, LogoRoundIcon, chainTokenIconMap, useGameAddresses } from "@looksrare/uikit";

interface CurrencyTokenIconProps extends IconProps {
  network: SupportedNetwork;
  currency?: Address;
}

export const CurrencyTokenIcon = ({ network, currency = zeroAddress, ...props }: CurrencyTokenIconProps) => {
  const ptbAddresses = useGameAddresses(network);
  const CurrencyIcon = chainTokenIconMap[getChainIdFromSupportedNetwork(network)];

  if (network === "ethereum" && isAddressEqual(currency, ptbAddresses.LOOKS)) {
    return <LogoRoundIcon {...props} />;
  }

  return <CurrencyIcon {...props} />;
};
