/* TODO pick one between this and `useSubmitTransaction` */
import { useCallback, useRef, useState } from "react";
import { useToast } from "@looksrare/uikit/components/useToast";
import { useGetFormattedErrorAndTitle } from "./useGetFormattedErrorAndTitle";

/**
 * IDLE - The step function should only be called inside a useEffect in this state.
 * Once it has been called it will never go back to IDLE ensuring that is can only be called once.
 *
 * INITIALIZED - The step function has been called
 * ACCEPTED - The step function has not thrown an error
 * REJECTED - The step function threw an error
 */
export enum HandleModalStatus {
  IDLE = "IDLE",
  INITIALIZED = "INITIALIZED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}

interface HandleModalStepArgs {
  onSubmit: (args: any) => any;
  onSuccess?: () => any;
  onError?: (error: any) => any;
}

/**
 * Wraps transaction step functions with unified error handling
 */
export const useHandleModalStep = ({ onSubmit, onSuccess, onError }: HandleModalStepArgs) => {
  const hasCalledHandler = useRef(false);
  const [status, setStatus] = useState(HandleModalStatus.IDLE);
  const { toast } = useToast();
  const getFormattedErrorAndTitle = useGetFormattedErrorAndTitle();

  const handleSubmit = useCallback(
    async ({ handlerArgs, callOnlyOnce = false }: { callOnlyOnce?: boolean; handlerArgs?: any } = {}) => {
      if (hasCalledHandler.current && callOnlyOnce) {
        return;
      }
      hasCalledHandler.current = true;

      try {
        setStatus(HandleModalStatus.INITIALIZED);
        await onSubmit(handlerArgs);
        setStatus(HandleModalStatus.ACCEPTED);
        typeof onSuccess === "function" && onSuccess();
      } catch (e: any) {
        setStatus(HandleModalStatus.REJECTED);
        typeof onError === "function" && onError(e);
        const { title, description } = getFormattedErrorAndTitle(e);
        toast({ title: e.name || title, description, status: "error" });
      }
    },
    [onSubmit, onSuccess, onError, getFormattedErrorAndTitle, toast]
  );

  return {
    handleSubmit,
    status,
    isIdle: status === HandleModalStatus.IDLE,
    isInitialized: status === HandleModalStatus.INITIALIZED,
    isAccepted: status === HandleModalStatus.ACCEPTED,
    isRejected: status === HandleModalStatus.REJECTED,
  };
};
