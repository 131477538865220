import { useMemo } from "react";
import { WalletClient } from "viem";
import { useWalletClient } from "wagmi";
import { providers } from "ethers";

export const walletClientToProvider = (walletClient: WalletClient) => {
  const { chain, transport } = walletClient;

  if (!chain) {
    throw new Error("No connected account");
  }

  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  return new providers.Web3Provider(transport, network);
};

export const walletClientToSigner = (walletClient: WalletClient) => {
  const provider = walletClientToProvider(walletClient);
  const signer = provider.getSigner(walletClient.account?.address);
  return signer;
};

/** Hook to convert a viem Wallet Client to an ethers.js Web3Provider. */
export const useWalletProvider = ({ chainId }: { chainId?: number }) => {
  const { data: walletClient } = useWalletClient({ chainId });
  return useMemo(() => {
    if (walletClient) {
      return walletClientToProvider(walletClient);
    }
  }, [walletClient]);
};

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export const useEthersSigner = ({ chainId }: { chainId?: number } = {}) => {
  const { data: walletClient } = useWalletClient({ chainId });
  return useMemo(() => {
    if (walletClient) {
      return walletClientToSigner(walletClient);
    }
  }, [walletClient]);
};
