import { Icon, IconProps } from "../Icon";

const LogoPolygon = ({ color = "purple.400", ...props }: IconProps) => (
  <Icon viewBox="0 0 48 48" color={color} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0007 1.5L43.4863 12.75V35.25L24.0007 46.5L4.51514 35.25V12.75L24.0007 1.5ZM34.8813 18.8386L40.0721 24L34.8813 29.1614C28.8721 35.1367 19.1293 35.1367 13.1201 29.1614L7.92928 24L13.1201 18.8386C19.1293 12.8633 28.8721 12.8633 34.8813 18.8386ZM24.0007 31.3932C19.9194 31.3932 16.6078 28.0849 16.6078 24.0003C16.6078 19.9157 19.9194 16.6075 24.0007 16.6075C28.082 16.6075 31.3935 19.9157 31.3935 24.0003C31.3935 28.0849 28.0819 31.3932 24.0007 31.3932ZM23.9997 27.2144C22.2252 27.2144 20.7854 25.776 20.7854 24.0001C20.7854 22.2242 22.2252 20.7858 23.9997 20.7858C25.7742 20.7858 27.214 22.2242 27.214 24.0001C27.214 25.776 25.7742 27.2144 23.9997 27.2144Z"
      fill="currentColor"
    />
  </Icon>
);

export default LogoPolygon;
