import { Icon, IconProps } from "../Icon";

const StarFilled = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0005 2L11.4505 11.22L1.28049 12.69L8.64049 19.87L6.90049 30L16.0005 25.22L25.1005 30L23.3605 19.87L30.7205 12.7L20.5505 11.22L16.0005 2Z"
      fill="currentColor"
    />
  </Icon>
);

export default StarFilled;
