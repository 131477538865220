import type { FC, PropsWithChildren } from "react";
import { useTranslation } from "next-i18next";
import { Button, ButtonProps } from "@looksrare/uikit";
import { useGameState } from "../../hooks";

type EnterCaveButtonProps = ButtonProps;

export const EnterCaveButton: FC<PropsWithChildren<EnterCaveButtonProps>> = ({ children, ...props }) => {
  const { t } = useTranslation();
  const setIsEnterCaveModalOpen = useGameState((state) => state.setIsEnterCaveModalOpen);

  const handleClick = () => {
    setIsEnterCaveModalOpen(true);
  };

  return (
    <Button onClick={handleClick} {...props}>
      {children || t("ptb::Join Now!")}
    </Button>
  );
};
