import { useCallback } from "react";
import { useAccount } from "wagmi";
import { useTranslation } from "next-i18next";
import { ChainId, chainInfo } from "@looksrare/config";
import { getErrorMessage } from "../formatting";
import { useIsChainSupported } from "./useIsChainSupported";

/**
 * Helper to format error messages and titles for toast displays.
 * Certain error messages are matched & replaced with more user-friendly messages.
 * If no match, use defaults.
 */
export const useGetFormattedErrorAndTitle = () => {
  const { t } = useTranslation();
  const { chain } = useAccount();
  const isChainSupported = useIsChainSupported();

  return useCallback(
    (error: any) => {
      // Log the initial message to help users to debug
      console.error(error);

      // handle metamask contract error message
      if (error.code) {
        switch (error.code) {
          case "ACTION_REJECTED":
            return {
              title: t("Error"),
              description: t("MetaMask Tx Signature: User denied transaction signature."),
            };
          default:
            break;
        }
      }

      const defaultMessage = getErrorMessage(error);

      // handle special cases: overwrite given error message with more human-readable description
      if (defaultMessage.includes("execution reverted: T18")) {
        return {
          title: t("Error: NFT Can't Be Transferred"),
          description: t(
            "Can't complete order: This collection's code doesn't allow LooksRare to transfer their NFTs. Try another collection."
          ),
        };
      }

      if (!isChainSupported) {
        const currentChainLabel = chain ? chainInfo[chain.id as ChainId]?.label : undefined;

        return {
          title: t("Error: Wrong Network in Wallet"),
          description: t(
            "Current network: {{currentNetwork}} - Make sure your wallet is connected to a supported network and try again.",
            {
              currentNetwork: currentChainLabel ?? "Unknown",
            }
          ),
        };
      }

      if (defaultMessage.includes("execution reverted: Rewards: Invalid proof")) {
        return {
          title: t("Error: Rewards Can't Be Claimed Yet"),
          description: t("Slight delay in releasing rewards today: please try again in an hour"),
        };
      }

      // seedWatchlist returns this when the wallet has no tokens
      if (defaultMessage.includes("User does not own any known tokens.")) {
        return {
          title: t("No Valid Collections to Add"),
          description: t(
            "Couldn't add collections to watchlist: there aren't any valid collections in your wallet. Collect some NFTs and try again."
          ),
        };
      }

      return {
        title: t("Error"),
        description: defaultMessage,
      };
    },
    [chain, t, isChainSupported]
  );
};
