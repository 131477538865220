import { FC, PropsWithChildren } from "react";
import { Popover, PopoverProps } from "@looksrare/uikit";
import { NavSubMenu, NavSubMenuProps } from "@/components/Layout/SideNav/NavSubMenu";

interface NavSubMenuPopoverProps extends NavSubMenuProps {
  labelContent: React.ReactElement;
  onClose?: () => void;
  isMobile?: boolean;
  popoverProps?: Omit<PopoverProps, "label">;
}

export const NavSubMenuPopover: FC<PropsWithChildren<NavSubMenuPopoverProps>> = ({
  onClose,
  title,
  labelContent,
  children,
  popoverProps,
  isMobile = false,
  ...props
}) => {
  return (
    <Popover
      arrowSize={0}
      gutter={4}
      contentProps={{
        borderLeftRadius: "none",
        borderRightRadius: "dialog",
        variants: {},
      }}
      arrowProps={{
        display: "none",
      }}
      label={
        <NavSubMenu {...props} isMobile={isMobile} onClose={onClose} title={title}>
          {labelContent}
        </NavSubMenu>
      }
      placement="right"
      variant="menu"
      {...popoverProps}
    >
      {children}
    </Popover>
  );
};
