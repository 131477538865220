import { useAccount } from "wagmi";
import { Trans, useTranslation } from "next-i18next";
import { type BigIntish, isAddressEqual } from "@looksrare/utils";
import { Button, CheckmarkFilledIcon, ConnectWalletButton, Text } from "@looksrare/uikit";
import { pokeDurSec } from "../../config";
import { usePokeTheBear, usePokingPlayer } from "../../hooks";
import { PtbPlayer } from "../../graphql";
import { getPlayerMeta } from "../../utils";
import { PtbSupportedNetwork } from "../../types";
import { Wrapper } from "./shared";

interface InProgressProps {
  caveOnChainId: BigIntish;
  roundOnChainId: BigIntish;
  network: PtbSupportedNetwork;
  players: PtbPlayer[];
}

export const InProgress = ({ caveOnChainId, roundOnChainId, players, network }: InProgressProps) => {
  const { address } = useAccount();
  const { t } = useTranslation();
  const { mutate, isPending } = usePokeTheBear();
  const pokingPtbPlayer = usePokingPlayer();
  const connectedWalletIsPoker = isAddressEqual(address, pokingPtbPlayer?.user.address);

  const handleClick = async () => {
    if (!roundOnChainId) {
      throw new Error("Cannot find new round data");
    }

    mutate({ caveOnChainId, roundOnChainId, network });
  };

  const renderText = () => {
    if (!!address) {
      const { hasWon, hasLost, isPokingUntil } = getPlayerMeta(address, players);
      if (!!isPokingUntil) {
        return (
          <Trans i18nKey="ptbSecondsToPoke">
            <Text>
              <Text as="span" bold>{`You're Up.`}</Text> {`You have ${pokeDurSec} seconds to poke the bear.`}
            </Text>
          </Trans>
        );
      }
      if (hasLost) {
        return <Text>{t(`You woke him up. You've been mauled to death. GG.`)}</Text>;
      }
      if (hasWon) {
        return <Text>{t("You Survived!")}</Text>;
      }
      return <Text>{t("Waiting for your turn.")}</Text>;
    }
    return <Text>{t("Connect your wallet to begin.")}</Text>;
  };

  const renderButtonComponent = () => {
    if (!!address) {
      const { hasWon, hasLost } = getPlayerMeta(address, players);
      if (connectedWalletIsPoker) {
        return (
          <Button onClick={handleClick} isLoading={isPending} isDisabled={hasWon}>
            {t("Poke")}
          </Button>
        );
      }

      if (hasWon || hasLost) {
        return (
          <Button isDisabled rightIcon={<CheckmarkFilledIcon />}>
            {t("Poked")}
          </Button>
        );
      }

      return <Button isDisabled>{t("Poke")}</Button>;
    }
    return <ConnectWalletButton />;
  };

  return (
    <Wrapper>
      {renderText()}
      {renderButtonComponent()}
    </Wrapper>
  );
};
