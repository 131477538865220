import { ModalLegacy, ModalProps } from "@looksrare/uikit";
import { Referral } from "./Referral";

interface ReferralModalProps {
  isOpen: ModalProps["isOpen"];
  onClose: ModalProps["onClose"];
}

export const ReferralModal = ({ isOpen, onClose }: ReferralModalProps) => (
  <ModalLegacy hideHeader isOpen={isOpen} onClose={onClose}>
    <Referral onClose={onClose} />
  </ModalLegacy>
);
