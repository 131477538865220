import { Icon, IconProps } from "../Icon";

const Looks = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4171 3.24982L16.0007 1.33337L12.5842 3.24982L8.66732 3.29834L6.66684 6.66623L3.29895 8.66671L3.25043 12.5836L1.33398 16L3.25043 19.4165L3.29895 23.3334L6.66684 25.3338L8.66732 28.7017L12.5842 28.7503L16.0007 30.6667L19.4171 28.7503L23.334 28.7017L25.3345 25.3338L28.7024 23.3334L28.7509 19.4165L30.6673 16L28.7509 12.5836L28.7024 8.66671L25.3345 6.66623L23.334 3.29834L19.4171 3.24982ZM12.0035 8.89648L6 14.869L16 24.8175L26 14.869L19.9965 8.89648H12.0035ZM20.2753 12.7751C17.9138 10.4106 14.085 10.4106 11.7235 12.7751L9.68359 14.8175L11.7235 16.8599C14.085 19.2244 17.9138 19.2244 20.2753 16.8599L22.3152 14.8175L20.2753 12.7751ZM16 17.3175C14.6193 17.3175 13.5 16.2277 13.5 14.8833C13.5 13.5389 14.6193 12.4491 16 12.4491C17.3807 12.4491 18.5 13.5389 18.5 14.8833C18.5 16.2277 17.3807 17.3175 16 17.3175ZM16.0001 15.8702C15.419 15.8702 14.9475 15.3991 14.9475 14.8175C14.9475 14.2359 15.419 13.7649 16.0001 13.7649C16.5812 13.7649 17.0527 14.2359 17.0527 14.8175C17.0527 15.3991 16.5812 15.8702 16.0001 15.8702Z"
      fill="currentColor"
    />
  </Icon>
);

export default Looks;
