import { Stack } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";
import { ReactNode } from "react";

interface MoDDataPointProps {
  value: ReactNode;
  label: string;
}

export const MoDDataPoint = ({ value, label }: MoDDataPointProps) => {
  return (
    <Stack spacing={0} minWidth={{ base: "none", lg: "96px" }}>
      <Text textStyle="body" color="text-02" bold>
        {value}
      </Text>
      <Text textStyle="helper" color="text-03">
        {label}
      </Text>
    </Stack>
  );
};
