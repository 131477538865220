import { Icon, IconProps } from "../Icon";

const ImageMissing = (props: IconProps) => (
  <Icon width="178" height="178" viewBox="0 0 178 178" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.695 102.949L126.365 100.165L126.615 98.6105C129.231 82.6137 134.316 78.4116 142.776 74.7987C148.974 72.1356 153.957 68.8553 154.919 62.8652C155.94 56.5093 151.617 51.5932 145.444 50.6015C139.454 49.6392 133.282 52.7285 131.81 60.1378L113.154 57.1407C116.504 38.9163 131.435 32.4028 148.033 35.0694C166.186 37.9857 177.583 49.3854 175.012 65.3895C173.285 76.1351 166.627 81.9607 157.261 85.6626C149.338 88.8459 145.469 92.4927 143.945 101.395L143.695 102.949ZM143.409 121.666C142.386 127.74 136.524 131.817 130.672 130.877C124.636 129.907 120.438 124.214 121.46 118.14C122.377 112.142 128.147 108.05 134.183 109.02C140.036 109.96 144.325 115.668 143.409 121.666ZM76.4159 49.9637L121.926 76.0822L78.029 151.662L2.22363 108.156L28.5768 62.7821L76.4159 49.9637ZM33.1248 86.4442C43.4668 68.5055 66.3824 62.3653 84.3081 72.7297L99.7926 81.6825L90.8591 97.1781C80.5171 115.117 57.6015 121.257 39.6758 110.893L24.1913 101.94L33.1248 86.4442ZM70.7181 102.75C78.9818 100.536 83.9331 92.2177 81.7771 84.1715C79.6211 76.1253 71.1743 71.3975 62.9106 73.6118C54.6469 75.826 49.6956 84.1438 51.8516 92.19C54.0076 100.236 62.4544 104.964 70.7181 102.75ZM68.8761 94.2827C72.3542 93.3507 74.4208 89.7753 73.4881 86.2945C72.5554 82.8136 68.978 80.7505 65.4999 81.6825C62.0219 82.6144 59.9552 86.1898 60.8879 89.6707C61.8206 93.1516 65.3981 95.2146 68.8761 94.2827Z"
      fill="currentColor"
    />
  </Icon>
);

export default ImageMissing;
