import { IconProps } from "@chakra-ui/icon";
import { Icon } from "../Icon";

const FastForward = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99985 26L17.9999 16L7.99985 6L6.59985 7.4L15.1999 16L6.59985 24.6L7.99985 26ZM17.9999 26L27.9999 16L17.9999 6L16.5999 7.4L25.1999 16L16.5999 24.6L17.9999 26Z"
      fill="currentColor"
    />
  </Icon>
);

export default FastForward;
