import { HStack, StackProps, forwardRef } from "@chakra-ui/react";
import { ArrowDownRightIcon, ArrowUpRightIcon, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { fontSizes } from "@looksrare/chakra-theme";
import { EntryPosition } from "../../../types";

interface PositionSwitcherButtonProps extends StackProps {
  type: EntryPosition;
  isActive: boolean;
}

export const PositionSwitcherButton = forwardRef<PositionSwitcherButtonProps, "div">(
  ({ type, isActive, ...props }, ref) => {
    const { t } = useTranslation();
    const activeColor = type === "moon" ? "moondoom-moon" : "moondoom-doom";

    return (
      <HStack
        ref={ref}
        spacing={1}
        p={3}
        justifyContent="center"
        cursor="pointer"
        color={isActive ? activeColor : "text-03"}
        _hover={{
          color: isActive ? activeColor : "text-01",
        }}
        {...props}
      >
        {type === "doom" && <ArrowDownRightIcon color="inherit" boxSize={5} transition="color 200ms" />}

        <Text
          textStyle="display-03"
          fontSize={fontSizes.lg}
          transform="translateY(2px)"
          textAlign="center"
          color="inherit"
          transition="color 200ms"
          bold
        >
          {type === "moon" ? t("mod::MOON") : t("mod::DOOM")}
        </Text>

        {type === "moon" && <ArrowUpRightIcon color="inherit" boxSize={5} transition="color 200ms" />}
      </HStack>
    );
  }
);
