import { Box, Checkbox, HStack, ModalBody, ModalFooter, Stack } from "@chakra-ui/react";
import { Button, CloudinaryImage, Link, Modal, ModalHeaderLegacy, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { MOON_OR_DOOM_DOCS_PAGE } from "../../../config/constants";

interface RoundEntryWarningModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAgree: (snoozeIndefinitely: boolean) => void;
}

export const RoundEntryWarningModal = ({ isOpen, onClose, onAgree }: RoundEntryWarningModalProps) => {
  const { t } = useTranslation();
  const [isDontShowAgainChecked, setIsDontShowAgainChecked] = useState(false);

  const toggleIsDontShowAgainChecked = () => setIsDontShowAgainChecked((checked) => !checked);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeaderLegacy onClose={onClose} showCloseButton />
      <ModalBody pb={4} pt={0} justifyContent="center" display="flex">
        <Box width={{ base: "50%", md: "60%" }}>
          <CloudinaryImage src="/images/moon-or-doom/moon-or-doom.svg" width={671} height={426} alt="MOON or DOOM" />
        </Box>
      </ModalBody>
      <ModalBody p={8} pb={6}>
        <Stack spacing={6}>
          <Text textStyle="display-body" color="text-01" bold>
            {t("mod::Before We Begin...")}
          </Text>

          <Text textStyle="body" color="text-02">
            {t(
              "mod::By continuing, you acknowledge that you play MOON or DOOM at your own risk, and that losses incurred due to your actions are your responsibility."
            )}
          </Text>

          <HStack spacing={2}>
            <Checkbox isChecked={isDontShowAgainChecked} onChange={toggleIsDontShowAgainChecked} />

            <Text textStyle="detail" color="link-02" cursor="pointer" onClick={toggleIsDontShowAgainChecked}>
              {t("Don’t show me this again")}
            </Text>
          </HStack>
        </Stack>
      </ModalBody>

      <ModalFooter p={8} pt={6}>
        <Stack spacing={2} flex={1}>
          <Button onClick={() => onAgree(isDontShowAgainChecked)}>{t("I Understand — Let’s Play")}</Button>

          <Button as={Link} href={MOON_OR_DOOM_DOCS_PAGE} onClick={onClose} colorScheme="secondary" isExternal>
            {t("Review the Rules")}
          </Button>
        </Stack>
      </ModalFooter>
    </Modal>
  );
};
