import { Icon, IconProps } from "@looksrare/uikit";

const SeatAvailable = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12 12C14.8995 12 17.25 9.6495 17.25 6.75C17.25 3.85051 14.8995 1.5 12 1.5C9.10051 1.5 6.75 3.85051 6.75 6.75C6.75 9.6495 9.10051 12 12 12Z"
      fill="#F3F3F8"
    />
    <path
      d="M16.9601 11.2517C15.7431 12.5826 13.9757 13.426 12.0035 13.426C10.0296 13.426 8.2622 12.5817 7.04434 11.25C4.15834 13.1037 2.25 16.2856 2.25 19.9074C2.25 21.3394 3.44039 22.5 4.90909 22.5H19.0909C20.5596 22.5 21.75 21.3394 21.75 19.9074C21.75 16.2874 19.8443 13.1063 16.9601 11.2517Z"
      fill="#F3F3F8"
    />
  </Icon>
);

export default SeatAvailable;
