import { useMemo } from "react";
import { usePreviousValue } from "@looksrare/utils";

export type AnimatedLayerStyle = "animatedPriceIncrease" | "animatedPriceDecrease";

/**
 * Return relevant layerStyle based on whether amount has increased or decreased
 */
export const useAnimatedLayerStyle = (amount: number | bigint): AnimatedLayerStyle | undefined => {
  const previousAmount = usePreviousValue(amount);

  const priceAnimationLayerStyle = useMemo(() => {
    if (previousAmount && amount) {
      if (previousAmount > amount) {
        return "animatedPriceDecrease";
      } else if (previousAmount < amount) {
        return "animatedPriceIncrease";
      }
    }
  }, [amount, previousAmount]);

  return priceAnimationLayerStyle;
};
