export const UNKNOWN_ERROR = "Unknown Error";

/**
 * Help to format errors from the wallet provider
 * @param error
 * @param characterLimit
 * @returns string trimmed to characterLimit
 */
export const getErrorMessage = (error: any, characterLimit = 92): string => {
  let errorMessage;
  // Attempt to extract a readable message from the error
  const message = error.message ? error.message : error;

  try {
    // Contract error
    const match = message.match(/"?message"?:"(.*?)"/m);
    errorMessage = match && match.length >= 2 ? match[1] : message;
  } catch (err: any) {
    // API error
    if (!!message.errors && Array.isArray(message.errors)) {
      errorMessage = message.errors.map((errorItem: { message: string }) => errorItem.message).join(" ");
    } else {
      try {
        // First fallback - stringify message
        const stringified = JSON.stringify(message) as string | undefined; //@SEE https://github.com/microsoft/TypeScript/issues/18879
        errorMessage = stringified || UNKNOWN_ERROR;
      } catch {
        // Second fallback if stringifying throws as well
        errorMessage = UNKNOWN_ERROR;
      }
    }
  }

  const willTruncate = errorMessage.length > characterLimit;
  // Slice to character limit
  return willTruncate ? `${errorMessage.slice(0, characterLimit)}...` : errorMessage;
};

/**
 * Error to throw in page data fetches
 */
export class PageDataFetchError extends Error {
  constructor(path: string, error: any) {
    let message;
    try {
      message = JSON.stringify(error, null, 2);
    } catch {
      message = "Error could not be stringified";
    }
    super(`"${path}"\n${message}`);
    this.name = "PageDataFetchError";
  }
}

/**
 * Error to throw when an address in undefined
 */
export class AddressUndefinedError extends Error {
  constructor(message?: string) {
    const baseMessage = "Connected address undefined";
    super(message ? `${baseMessage}: ${message}` : baseMessage);
    this.name = "AddressUndefined";
  }
}

/**
 * Error to throw when an OrderValidatorV1 contract call returns a non-success code
 */
export class OrderValidatorV1Error extends Error {
  constructor(errorName: string, errorMessage: string) {
    super(`OrderValidatorV1Error ${errorName}`);
    this.name = errorName;
    this.message = errorMessage;
  }
}

/**
 * Error to throw when an OrderValidatorV2 contract call returns a non-success code
 */
export class OrderValidatorV2Error extends Error {
  constructor(errorName: string, errorMessage: string) {
    super(`OrderValidatorV2Error ${errorName}`);
    this.name = errorName;
    this.message = errorMessage;
  }
}

/**
 * Error to throw when no public client is found during execution
 */
export class NoPublicClientError extends Error {
  constructor() {
    super("No public client found");
    this.name = "NoPublicClientError";
  }
}
