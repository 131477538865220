import { type BigIntish, TopicName, getTopicName, useRealtimeSubscription } from "@looksrare/utils";
import type { PtbContractName } from "../types";

export const useActiveCaveViews = (caveOnChainId: BigIntish, contract: PtbContractName) => {
  const topicName = getTopicName({
    name: TopicName.ptbCaveActiveViews,
    caveOnChainId,
    contract,
  });

  return useRealtimeSubscription<{ count: number }>(topicName, { enabled: !!caveOnChainId });
};

export const usePtbPageViews = () => {
  const topicName = getTopicName({
    name: TopicName.ptbActiveViews,
  });

  return useRealtimeSubscription<{ count: number }>(topicName);
};

export const usePtbActivePlayers = () => {
  const topicName = getTopicName({
    name: TopicName.ptbActivePlayers,
  });

  return useRealtimeSubscription<number>(topicName);
};
