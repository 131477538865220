import { useRouter } from "next/router";
import { Box, Flex, VStack } from "@chakra-ui/react";
import { Trans } from "next-i18next";
import { CircularCountdown, Text } from "@looksrare/uikit";
import { useElapsedTime } from "use-elapsed-time";
import { useCurrentRoundOnChainId, useGameState, useGetCaveQueryParams } from "../hooks";
import { caveDelaySec } from "../config";
import { getRoundUrl } from "../utils";

export const NextRoundCountdown = () => {
  const { caveOnChainId, roundOnChainId, network } = useGetCaveQueryParams();
  const currentRoundQuery = useCurrentRoundOnChainId({ caveOnChainId, network });
  const isEnterCaveModalOpen = useGameState((state) => state.isEnterCaveModalOpen);

  const router = useRouter();
  const { elapsedTime } = useElapsedTime({
    isPlaying: currentRoundQuery.isSuccess && !isEnterCaveModalOpen,
    duration: caveDelaySec,
    onComplete: () => {
      // Don't redirect if the player is actively trying to enter a new round
      if (!isEnterCaveModalOpen) {
        router.push(getRoundUrl({ network, caveOnChainId, roundOnChainId: Number(roundOnChainId) + 1 }));
      }
    },
  });
  const countdown = caveDelaySec - elapsedTime;
  const countdownDisplay = Math.round(countdown);
  return (
    <Flex
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      zIndex={2}
    >
      <Box borderRadius="dialog" bg="ui-bg" p={4} border="1px solid" borderColor="border-01">
        {/* Manual adjustments to center countdown in the cave */}
        <CircularCountdown
          countdown={countdown}
          strokeWidth="4px"
          size={192}
          duration={caveDelaySec}
          strokeColor="text-03"
          trackColor="border-01"
        >
          <VStack>
            <Trans i18nKey="ptbNextRoundCountdown" ns="ptb">
              <Text color="text-03" textStyle="detail">
                Next round begins in
              </Text>
              <Text bold textStyle="display-01">
                <>{{ countdownDisplay }}</>
              </Text>
            </Trans>
          </VStack>
        </CircularCountdown>
      </Box>
    </Flex>
  );
};
