import { Context, useContext, ReactNode } from "react";
import type WidgetBotOptions from "@widgetbot/crate/dist/types/options";
import { BoxProps } from "@chakra-ui/react";
import { GameContext, GameProviderContext } from "@looksrare/uikit/components/GameProvider";
import { BigIntish, getStorageKey } from "@looksrare/utils";
import { PtbRoundLogType, type PtbSupportedNetwork } from "./types";

export const BASE_URL = "/poke-the-bear";
export const LS_DISCLAIMER_KEY = getStorageKey("ptb_disclaimer_key");

export const pokeDurSec = 15;
export const caveDelaySec = 10;

export const ptbWidgetBotOptions: WidgetBotOptions = {
  server: "907171253947858974",
  channel: "1168872328021684314",
  shard: "https://emerald.widgetbot.io",
  glyph: ["/images/win/chat.png", "32px"],
  color: "#04CD58",
  // override the responsive default css
  css: `button { right: 20px; bottom: 20px; border-bottom-right-radius: 56px; }`,
};

/**
 * Defines the maximum number of rounds that a user is able
 * to enter in advance. This is partially restricted by the underlying
 * `commitments` available in the contract.
 */
export const maxFutureRounds: Record<PtbSupportedNetwork, number> = {
  ethereum: 30,
  arbitrum: 500,
  blast: 500,
};

export const nonLoggableEvents = [PtbRoundLogType.ROUND_CANCELLED, PtbRoundLogType.PLAYER_COUNTDOWN];

// Used as a backup
export const caveDurationSec = "300";

export interface PtbConfig {
  /** Number of seconds before allowing a user to exit cave (based on openedAt) */
  refundCutoffSec: number;
  /** Show/hide cave background */
  hideCaveBackground: boolean;
  /** Cave sound mute setting */
  isMuted: boolean;
  /** Hide the overlay on the cave (FE) */
  showLocalMuteButton: boolean;
  /** Hot cave network to show */
  hotNetwork: PtbSupportedNetwork;
  /** Whether or not to render the claim box at the top of the history page */
  renderHistoryPageTopClaimBox: boolean;
  /** Render a component after the history table */
  historyPageBottomComponent?: () => ReactNode;
  /** Disable clickable profiles */
  historyPageWinnerProfileClickable: boolean;
  /** What to render in place of multi-netowrk claim panel */
  mobileClaimPanelComponent?: () => ReactNode;
  /** Show/hide mobile header */
  showMobileHeader: boolean;
  /** Mobile lobby wrapper props */
  mobileLobbyProps?: BoxProps;
  /** Player meta info (gems/points) component */
  playerListMetaComponent?: (gemsEarned?: BigIntish | null) => ReactNode;
  /** Global actions */
  actions: {
    toggleMute: () => void;
  };
  selfTimeoutUntil?: string | null;
  isWalletBlocked: boolean;
  points: {
    enabled: boolean;
  };
}

export const usePtbConfig = () => {
  const context = useContext(GameProviderContext as Context<GameContext<PtbConfig> | undefined>);
  if (!context?.config) {
    throw new Error("No config found");
  }

  return context.config;
};
