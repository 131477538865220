import { memo } from "react";
import { useAccount, useEnsName } from "wagmi";
import { type Address } from "viem";
import { useTranslation } from "next-i18next";
import { Flex, type GridProps, Box } from "@chakra-ui/react";
import { ProfileAvatar, Text } from "@looksrare/uikit";
import { type BigIntish, isAddressEqual, formatToSignificant, formatDisplayName } from "@looksrare/utils";
import { PtbRoundStatus } from "../../../types";
import { useGameState, usePtbUser } from "../../../hooks";
import { MauledIcon, SurvivedIcon } from "../../Icons";
import { usePtbConfig } from "../../..";
import { CurrencyValueDisplay } from "../../CurrencyValueDisplay";
import { TriangleIcon } from "./TriangleIcon";
import { PokeCountdown } from "./PokeCountdown";
import { PlayerCardContent, PlayerCardGrid, PlayerCardRank } from "./shared";

interface PlayerCardProps extends GridProps {
  num: number;
  playerAddress: Address;
  currency: Address;
  hasWon: boolean;
  hasLost: boolean;
  isPokingUntil: string | null;
  enterAmount?: BigIntish;
  prizePerPlayer?: bigint;
  roundStatus?: PtbRoundStatus;
  isCycling?: boolean;
  gemsEarned?: BigIntish | null;
}

export const PlayerCard = ({
  num,
  playerAddress,
  currency,
  hasWon,
  hasLost,
  isPokingUntil,
  enterAmount,
  prizePerPlayer = 0n,
  roundStatus,
  isCycling = false,
  gemsEarned,
  ...props
}: PlayerCardProps) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const ptbUserQuery = usePtbUser(playerAddress);
  const ensNameQuery = useEnsName({
    address: playerAddress,
    query: { enabled: ptbUserQuery.isSuccess && !ptbUserQuery.data },
  });
  const nextPlayerUp = useGameState((state) => state.nextPlayerUp);
  const { playerListMetaComponent, points } = usePtbConfig();

  const isNextPlayerUp = isAddressEqual(playerAddress, nextPlayerUp);
  const isCardActive = !!isPokingUntil || isCycling || isNextPlayerUp;
  const isPlayerConnectedWallet = isAddressEqual(address, playerAddress);

  const getPlayerStatus = () => {
    if (hasLost) {
      return <MauledIcon />;
    }

    if (roundStatus === PtbRoundStatus.REVEALED && enterAmount) {
      return (
        <Flex alignItems="center" justifyContent="center" height={8} minWidth={8}>
          <CurrencyValueDisplay
            currency={currency}
            total={formatToSignificant(prizePerPlayer, 6)}
            prefix="+"
            color="link-01"
            iconProps={{ boxSize: 3 }}
          />
        </Flex>
      );
    }

    if (hasWon) {
      return <SurvivedIcon />;
    }
    if (isPokingUntil) {
      return <PokeCountdown isPokingUntil={isPokingUntil} />;
    }
    return null;
  };

  return (
    <PlayerCardGrid isActive={isCardActive} {...props}>
      {isCardActive && <TriangleIcon position="absolute" left={0} top="50%" transform="translateY(-50%)" />}
      <PlayerCardRank>{num}</PlayerCardRank>
      <PlayerCardContent>
        <ProfileAvatar
          address={playerAddress}
          src={ptbUserQuery.data?.avatar?.image.src}
          size={32}
          isPrivate={ptbUserQuery.data?.isProfileImageVisible === false}
        />
        <Box flex={1}>
          {isPlayerConnectedWallet ? (
            <Text bold textStyle="detail" color="link-01" noOfLines={1}>
              {t("You")}
            </Text>
          ) : (
            <Text bold textStyle="detail" color="text-01" noOfLines={1}>
              {formatDisplayName({
                address: playerAddress,
                userName: ptbUserQuery.data?.name,
                ensName: ensNameQuery.data,
              })}
            </Text>
          )}

          {points.enabled && playerListMetaComponent?.(gemsEarned)}
        </Box>
        {getPlayerStatus()}
      </PlayerCardContent>
    </PlayerCardGrid>
  );
};

export const MemoizedPlayerCard = memo(PlayerCard);
