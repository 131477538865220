import { useCallback } from "react";
import { Address, WalletClient } from "viem";
import { signAndLoginIfJwtIsInvalid } from "../../auth";
import { JwtScope } from "../../types";

export const useSignAndLoginIfJwtIsInvalid = () => {
  return useCallback(
    (walletClient: WalletClient, account: Address, scope?: JwtScope) =>
      signAndLoginIfJwtIsInvalid(walletClient, account, scope),
    []
  );
};
