import Icon, { IconProps } from "@chakra-ui/icon";

const WatchlistFavorites = (props: IconProps) => (
  <Icon viewBox="0 0 80 80" {...props}>
    <g clipPath="url(#clip0_1662_67468)">
      <rect width="80" height="80" fill="url(#paint0_linear_1662_67468)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.9998 9.37305L30.0467 29.5418L7.7998 32.7574L23.8998 48.4637L20.0936 70.6231L39.9998 60.1668L59.9061 70.6231L56.0998 48.4637L72.1998 32.7793L49.9529 29.5418L39.9998 9.37305Z"
        fill="#F4F5FB"
      />
      <path
        d="M29.2254 36.9627C35.1497 31.0124 44.8494 31.0124 50.7737 36.9627L55.8438 42.0329L50.7737 47.1031C44.8494 53.0534 35.1497 53.0534 29.2254 47.1031L24.1553 42.0329L29.2254 36.9627Z"
        fill="black"
      />
      <path
        d="M39.9997 49.3228C35.9761 49.3228 32.7113 46.0614 32.7113 42.0345C32.7113 38.0076 35.9761 34.7461 39.9997 34.7461C44.0233 34.7461 47.2881 38.0076 47.2881 42.0345C47.2881 46.0614 44.0233 49.3228 39.9997 49.3228Z"
        fill="white"
      />
      <path
        d="M40.0001 45.1951C38.2507 45.1951 36.8312 43.7771 36.8312 42.0263C36.8312 40.2755 38.2507 38.8574 40.0001 38.8574C41.7495 38.8574 43.1689 40.2755 43.1689 42.0263C43.1689 43.7771 41.7495 45.1951 40.0001 45.1951Z"
        fill="black"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear_1662_67468" x1="40" y1="0" x2="40" y2="80" gradientUnits="userSpaceOnUse">
        <stop stopColor="#0CE466" />
        <stop offset="1" stopColor="#11A24D" />
      </linearGradient>
      <clipPath id="clip0_1662_67468">
        <rect width="80" height="80" rx="8" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

export default WatchlistFavorites;
