import { Flex, Stack } from "@chakra-ui/react";
import { Text } from "@looksrare/uikit";
import { BigIntish, formatToSignificant, formatUsd, fromDecimals, useCoinPrices } from "@looksrare/utils";
import { Address, isAddressEqual, zeroAddress } from "viem";
import { PtbSupportedNetwork } from "../../../types";
import { CurrencyTokenIcon } from "../../CurrencyTokenIcon";

interface TokenRowProps {
  currency: Address;
  network: PtbSupportedNetwork;
  amountWei: BigIntish;
}

export const TokenRow = ({ currency, amountWei, network }: TokenRowProps) => {
  const isEth = isAddressEqual(currency, zeroAddress);
  const coinPriceQuery = useCoinPrices();
  const ethPriceUsd = coinPriceQuery.data ? coinPriceQuery.data.eth.price : 0;
  const looksPriceUsd = coinPriceQuery.data ? coinPriceQuery.data.looks.price : 0;

  const formattedAmount = formatToSignificant(amountWei.toString(), 6);
  const formattedValueUsd = formatUsd(parseFloat(fromDecimals(amountWei)) * (isEth ? ethPriceUsd : looksPriceUsd));

  return (
    <Flex alignItems="center" gap={3}>
      <CurrencyTokenIcon network={network} currency={currency} boxSize={12} />
      <Stack spacing={0}>
        <Text textStyle="display-body" bold>
          {formattedAmount}
        </Text>
        <Text textStyle="detail" bold color="text-03">
          {formattedValueUsd}
        </Text>
      </Stack>
    </Flex>
  );
};
