import { AspectRatio, Box } from "@chakra-ui/react";
import { useCurrentGameStatus, useGetCaveQueryParams, usePokeCursor } from "../../hooks";
import { CountdowngVideo, MaulVideo, PokingVideo, ShufflingVideo, SleepingVideo } from "./videos";

const ratio = 1920 / 1080;

export const BearAnimation = () => {
  const { caveOnChainId, roundOnChainId, network } = useGetCaveQueryParams();
  const gameStatus = useCurrentGameStatus();
  const wrapperRef = usePokeCursor(caveOnChainId, roundOnChainId, network);

  return (
    <Box
      position="relative"
      backgroundImage="url('/images/poke-the-bear/pixel-cave-small.png')"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      ref={wrapperRef}
    >
      <AspectRatio ratio={ratio}>
        <Box />
      </AspectRatio>
      <SleepingVideo gameStatus={gameStatus} />
      <MaulVideo gameStatus={gameStatus} />
      <ShufflingVideo gameStatus={gameStatus} />
      <PokingVideo gameStatus={gameStatus} />
      <CountdowngVideo gameStatus={gameStatus} />
    </Box>
  );
};
