import { Icon, IconProps } from "../Icon";

const UserItems = (props: IconProps) => (
  <Icon {...props}>
    <path
      d="M3 5C3 3.89543 3.89543 3 5 3H24C25.1046 3 26 3.89543 26 5V11H24V5L5 5V24H11V26H5C3.89543 26 3 25.1046 3 24V5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 21C30 25.9706 25.9706 30 21 30C16.0294 30 12 25.9706 12 21C12 16.0294 16.0294 12 21 12C25.9706 12 30 16.0294 30 21ZM21 15.375C22.5533 15.375 23.8125 16.6342 23.8125 18.1875C23.8125 19.7408 22.5533 21 21 21C19.4467 21 18.1875 19.7408 18.1875 18.1875C18.1858 17.4411 18.4816 16.7247 19.0094 16.1969C19.5372 15.6691 20.2536 15.3733 21 15.375ZM16.0057 25.9048C17.2759 27.198 19.0443 28 21 28C22.9552 28 24.7231 27.1984 25.9931 25.906C25.8824 24.2642 24.5495 22.9422 22.8749 22.875H19.1249C17.4481 22.9389 16.1141 24.2631 16.0057 25.9048Z"
      fill="currentColor"
    />
  </Icon>
);

export default UserItems;
