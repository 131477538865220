import { HStack, InputGroup, InputRightElement, Stack } from "@chakra-ui/react";
import { Button, EthHalfIcon, NumberInput, Text } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { formatToSignificant } from "@looksrare/utils";
import { PositionSwitcher } from "../../PositionSwitcher/PositionSwitcher";
import { EntryPosition } from "../../../types";

const quickEntryOptions = ["0.01", "0.05", "0.1", "0.2"];

interface RoundEntryInputProps {
  entryPosition: EntryPosition;
  entryAmount: string;
  onChangeEntryPosition: (entryPosition: EntryPosition) => void;
  onChangeEntryAmount: (entryAmount: string) => void;
  isBalanceSufficient: boolean;
  minEntryAmountWei: bigint;
  isAboveMinEntry: boolean;
  ethBalanceWei?: bigint;
}

export const RoundEntryInput = ({
  entryPosition,
  entryAmount,
  onChangeEntryPosition,
  onChangeEntryAmount,
  isBalanceSufficient,
  minEntryAmountWei,
  isAboveMinEntry,
  ethBalanceWei,
}: RoundEntryInputProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack spacing={2} pt={6} px={4} pb={8}>
        <Text textStyle="detail" color="text-02" bold>
          {t("mod::Price Will...")}
        </Text>

        <PositionSwitcher selected={entryPosition} onChange={onChangeEntryPosition} />
      </Stack>

      <Stack spacing={2} px={4} pb={isBalanceSufficient && isAboveMinEntry ? 8 : 2}>
        <HStack spacing={2} justifyContent="space-between">
          <Text textStyle="detail" color="text-02" bold>
            {t("Your Entry")}
          </Text>

          <HStack spacing={2}>
            <Text textStyle="detail" color="text-03">
              {t("Balance")}
            </Text>

            <Text textStyle="detail" color="text-02" bold>
              {ethBalanceWei !== undefined ? formatToSignificant(ethBalanceWei) : "-"}
            </Text>

            <EthHalfIcon height={4} width={2} />
          </HStack>
        </HStack>

        <HStack spacing={2}>
          {quickEntryOptions.map((option) => (
            <Button
              key={option}
              variant="outline"
              colorScheme="secondary"
              size="sm"
              flex={1}
              onClick={() => onChangeEntryAmount(option)}
            >
              {option}
            </Button>
          ))}
        </HStack>

        <InputGroup>
          <NumberInput value={entryAmount} onTextChange={onChangeEntryAmount} size="md" />

          <InputRightElement height="48px" width="max-content" pr={4}>
            <Text textStyle="detail" color="text-03" whiteSpace="nowrap">
              ETH
            </Text>
          </InputRightElement>
        </InputGroup>

        {!isBalanceSufficient && isAboveMinEntry && (
          <Text textStyle="helper" color="text-error">
            {t("Insufficient Balance")}
          </Text>
        )}
        {!isAboveMinEntry && (
          <Text textStyle="helper" color="text-error">
            {t("Minimum amount is {{amount}} {{currency}}", {
              amount: formatToSignificant(minEntryAmountWei),
              currency: "ETH",
            })}
          </Text>
        )}
      </Stack>
    </>
  );
};
