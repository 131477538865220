import { formatUnits } from "viem";
import { BigIntish, FromDecimalsOptions } from "../types";
import { commify } from "./commify";
/**
 * Format a value from decimals to a user friendly value e.g. BigInt.from(1800000000000000000) -> "1.8"
 * @see https://viem.sh/docs/utilities/formatUnits.html
 * @param value
 * @param decimals
 * @returns string
 */
export const fromDecimals = (value: BigIntish, options?: FromDecimalsOptions) => {
  const { decimals = 18, significantDigits, shouldCommify = false } = options || {};
  const valueBi = BigInt(value.toString());
  const formattedUnit = formatUnits(valueBi, decimals);

  if (significantDigits !== undefined) {
    const reg = new RegExp(`^-?\\d+(?:\.\\d{0,${significantDigits === 0 ? -1 : significantDigits}})?`);
    const matchedStr = formattedUnit.match(reg);
    if (!!matchedStr) {
      return shouldCommify ? commify(matchedStr[0]) : matchedStr[0];
    }
  }

  return shouldCommify ? commify(formattedUnit) : formattedUnit;
};
