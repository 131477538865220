/**
 * Chakra : Figma
 * 900 = 100
 * 800 = 90
 * 700 = 80
 * 600 = 70
 * 500 = 60
 * 400 = 50
 * 300 = 40
 * 200 = 30
 * 100 = 20
 * 50 = 10
 */
export const palette = {
  gray: {
    900: "#121619",
    850: "#1A1E22",
    800: "#21252B",
    750: "#262C31",
    700: "#2A3136",
    600: "#4D5358",
    500: "#697077",
    400: "#8A8C95",
    300: "#ADADB3",
    200: "#C7C7CC",
    150: "#DEDEE3",
    100: "#E5E5EA",
    75: "#EBEBF1",
    50: "#F3F3F8",
    25: "#F4F5FB",
  },
  green: {
    900: "#071D09",
    800: "#022D0E",
    700: "#044318",
    600: "#0C5C2C",
    500: "#018134",
    400: "#11A24D",
    300: "#04CD58",
    200: "#0CE466",
    100: "#A7F1C6",
    50: "#D5FBE5",
  },
  blue: {
    900: "#05133C",
    800: "#0C215A",
    700: "#163188",
    600: "#1349B9",
    500: "#2165ED",
    400: "#4589FF",
    300: "#78A9FF",
    200: "#A6C8FF",
    100: "#D0E2FF",
    50: "#EDF5FF",
  },
  purple: {
    900: "#1C0F30",
    800: "#31135E",
    700: "#491D8B",
    600: "#6929C4",
    500: "#8A3FFC",
    400: "#A56EFF",
    300: "#BE95FF",
    200: "#D4BBFF",
    100: "#E8DAFF",
    50: "#F6F2FF",
  },
  magenta: {
    900: "#2A0A18",
    800: "#510224",
    700: "#740937",
    600: "#9F1853",
    500: "#D02670",
    400: "#EE5396",
    300: "#FF7EB6",
    200: "#FFAFD2",
    100: "#FFD6E8",
    50: "#FFF0F7",
  },
  pink: {
    900: "#2A0A18",
    800: "#510224",
    700: "#740937",
    600: "#9F1853",
    500: "#D02670",
    400: "#EE5396",
    300: "#FF7EB6",
    200: "#FFAFD2",
    100: "#FFD6E8",
    50: "#FFF0F7",
  },
  red: {
    900: "#2D0709",
    800: "#520408",
    700: "#750E13",
    600: "#A2191F",
    500: "#DA1E28",
    400: "#FA4D56",
    300: "#FF8389",
    200: "#FFB3B8",
    100: "#FFD7D9",
    50: "#FFF1F1",
  },
  yellow: {
    900: "#221611",
    800: "#372118",
    700: "#4E3418",
    600: "#6F4C12",
    500: "#93670F",
    400: "#B28600",
    300: "#D6A800",
    200: "#F8CC32",
    100: "#F7E08D",
    50: "#FEF5D7",
  },
  orange: {
    900: "#211001",
    800: "#3C1E02",
    700: "#5D2D01",
    600: "#814003",
    500: "#B15601",
    400: "#DF6D04",
    300: "#FF8A14",
    200: "#FFB675",
    100: "#FFDBB3",
    50: "#FFF4E5",
  },
  black: {
    900: "#000000",
    800: "#000000",
    700: "#000000",
    600: "#000000",
    500: "#000000",
    400: "#000000",
    300: "#000000",
    200: "#000000",
    100: "#000000",
    50: "#000000",
  },
  white: {
    900: "#FFFFFF",
    800: "#FFFFFF",
    700: "#FFFFFF",
    600: "#FFFFFF",
    500: "#FFFFFF",
    400: "#FFFFFF",
    300: "#FFFFFF",
    200: "#FFFFFF",
    100: "#FFFFFF",
    50: "#FFFFFF",
  },
  blackAlpha: {
    900: "#000000EB",
    800: "#000000CC",
    700: "#000000A3",
    600: "#0000007A",
    500: "#0000005C",
    400: "#0000003D",
    300: "#00000029",
    200: "#0000001A",
    100: "#00000014",
    50: "#0000000A",
  },
  grayAlpha: {
    900: "#000000EB",
    800: "#000000CC",
    700: "#000000A3",
    600: "#0000007A",
    500: "#0000005C",
    400: "#0000003D",
    300: "#00000029",
    200: "#00000014",
    100: "#0000000F",
    50: "#0000000A",
  },
  whiteAlpha: {
    900: "#FFFFFFEB",
    800: "#FFFFFFCC",
    700: "#FFFFFFA3",
    600: "#FFFFFF7A",
    500: "#FFFFFF5C",
    400: "#FFFFFF3D",
    300: "#FFFFFF29",
    200: "#FFFFFF1A",
    100: "#FFFFFF14",
    50: "#FFFFFF0A",
  },
  acid: {
    900: "#304200",
    800: "#3a5000",
    700: "#486400",
    600: "#5d7a00",
    500: "#749900",
    400: "#93bc00",
    300: "#b2e400",
    200: "#ccfd07 ",
    100: "#e7ff87",
    50: "#f5ffce",
    25: "#faffe7",
  },
};

/**
 * @see https://www.figma.com/file/iZfQj9UOec97V5utcBBC9R/%F0%9F%8E%A8-Day-Night-Palette?node-id=0%3A1
 */
export const semanticTokens = {
  colors: {
    // UI
    "ui-bg": {
      _dark: "gray.900",
      _light: "white",
    },
    "ui-01": {
      _dark: "gray.800",
      _light: "gray.50",
    },
    "ui-02": {
      _dark: "gray.700",
      _light: "gray.100",
    },
    "ui-01-inverse": {
      _dark: "gray.50",
      _light: "gray.800",
    },
    "ui-inverse": {
      _dark: "gray.50",
      _light: "gray.900",
    },
    "ui-acid": {
      _dark: "acid.200",
      _light: "acid.400",
    },
    "border-01": {
      _dark: "gray.700",
      _light: "gray.100",
    },
    "border-02": {
      _dark: "gray.700",
      _light: "gray.100",
    },
    "ui-bg-translucent": {
      _dark: "grayAlpha.900",
      _light: "whiteAlpha.900",
    },
    "ui-glass": {
      _dark: "rgba(0, 0, 0, 0.24)",
      _light: "rgba(255, 255, 255, 0.64)",
    },

    // Interactive
    "interactive-01": {
      _dark: "gray.50",
      _light: "gray.900",
    },
    "interactive-02": {
      _dark: "gray.700",
      _light: "gray.100",
    },
    "interactive-03": {
      _dark: "green.200",
      _light: "green.300",
    },
    "field-01": {
      _dark: "gray.800",
      _light: "gray.50",
    },
    "field-02": {
      _dark: "gray.700",
      _light: "gray.100",
    },
    "switch-checked-bg": "green.400",

    // Text
    "text-01": {
      _dark: "gray.50",
      _light: "gray.900",
    },
    "text-02": {
      _dark: "gray.200",
      _light: "gray.700",
    },
    "text-03": {
      _dark: "gray.400",
      _light: "gray.500",
    },
    "text-placeholder": {
      _dark: "gray.500",
      _light: "gray.400",
    },
    "text-disabled": {
      _dark: "gray.600",
      _light: "gray.300",
    },
    "text-primarybutton": {
      _dark: "gray.900",
      _light: "white",
    },
    "text-inverse": {
      _dark: "gray.900",
      _light: "gray.50",
    },
    "text-inverse-02": {
      _dark: "gray.700",
      _light: "gray.200",
    },
    "text-inverse-03": {
      _dark: "gray.500",
      _light: "gray.400",
    },
    "text-error": {
      _dark: "red.400",
      _light: "red.500",
    },
    "text-warning": {
      _dark: "yellow.200",
      _light: "yellow.400",
    },
    "text-acid": {
      _dark: "acid.200",
      _light: "acid.400",
    },
    "text-gradient-01": {
      _dark: "linear-gradient(135deg, #FFFFFF 30%, rgba(255, 255, 255, 0.38) 100%)",
      _light: "gray.900",
    },
    "text-gradient-02": {
      _dark: "linear-gradient(180deg, #FFDE65 34.38%, #8A3FFC 100%), #FFFFFF",
      _light: "linear-gradient(180deg, #FF7D1F 0%, #8A3FFC 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    },
    "link-01": {
      _dark: "green.200",
      _light: "green.400",
    },
    "link-02": {
      _dark: "white",
      _light: "gray.900",
    },
    "link-inverse": {
      _dark: "green.400",
      _light: "green.200",
    },

    // States
    focus: {
      _dark: "white",
      _light: "purple.400",
    },
    "focus-inverse": {
      _dark: "purple.400",
      _light: "white",
    },
    "hover-ui": {
      _dark: "gray.850",
      _light: "gray.75",
    },
    "onclick-ui": {
      _dark: "gray.750",
      _light: "gray.150",
    },
    "hover-ui-inverse": {
      _dark: "gray.75",
      _light: "gray.850",
    },
    "hover-interactive-01": {
      _dark: "gray.200",
      _light: "gray.700",
    },
    "onclick-interactive-01": {
      _dark: "gray.300",
      _light: "gray.800",
    },
    "hover-interactive-02": {
      _dark: "gray.750",
      _light: "gray.150",
    },
    "onclick-interactive-02": {
      _dark: "gray.850",
      _light: "gray.75",
    },
    "hover-interactive-03": {
      _dark: "green.300",
      _light: "green.400",
    },
    "onclick-interactive-03": {
      _dark: "green.400",
      _light: "green.500",
    },
    "hover-error": {
      _dark: "red.500",
      _light: "red.500",
    },
    "onclick-error": {
      _dark: "red.700",
      _light: "red.700",
    },
    "hover-link-01": {
      _dark: "green.400",
      _light: "green.500",
    },

    // Support
    "support-error": {
      _dark: "red.400",
      _light: "red.500",
    },
    "support-error-alpha": {
      _dark: palette.red[400] + "80", // 50% opacity
      _light: palette.red[500] + "80", // 50% opacity
    },
    "support-error-inverse": {
      _dark: "red.500",
      _light: "red.400",
    },
    "support-error-bg": {
      _dark: "gray.700",
      _light: "red.50",
    },
    "support-error-bg-inverse": {
      _dark: "red.50",
      _light: "gray.700",
    },
    "support-success": {
      _dark: "green.300",
      _light: "green.400",
    },
    "support-success-alpha": {
      _dark: palette.green[300] + "80", // 50% opacity
      _light: palette.green[400] + "80", // 50% opacity
    },
    "support-success-inverse": {
      _dark: "green.400",
      _light: "green.300",
    },
    "support-success-bg": {
      _dark: "gray.700",
      _light: "green.50",
    },
    "support-success-bg-inverse": {
      _dark: "green.50",
      _light: "gray.700",
    },
    "support-warning": {
      _dark: "yellow.200",
      _light: "yellow.300",
    },
    "support-warning-inverse": {
      _dark: "yellow.300",
      _light: "yellow.200",
    },
    "support-warning-bg": {
      _dark: "gray.700",
      _light: "yellow.50",
    },
    "support-warning-bg-inverse": {
      _dark: "yellow.50",
      _light: "gray.700",
    },
    "support-info": {
      _dark: "blue.400",
      _light: "blue.600",
    },
    "support-info-inverse": {
      _dark: "blue.600",
      _light: "blue.400",
    },
    "support-info-bg": {
      _dark: "gray.700",
      _light: "blue.50",
    },
    "support-info-bg-inverse": {
      _dark: "blue.50",
      _light: "gray.700",
    },
    // Gradient pairs
    "ui-gradient-02-a": {
      _dark: "gray.900",
      _light: "white",
    },
    "ui-gradient-02-b": {
      _dark: "gray.800",
      _light: "gray.50",
    },

    // Confetti
    "confetti-01": {
      _dark: "blue.300",
      _light: "blue.300",
    },
    "confetti-02": {
      _dark: "purple.400",
      _light: "purple.400",
    },
    "confetti-03": {
      _dark: "pink.400",
      _light: "pink.400",
    },
    "confetti-04": {
      _dark: "green.200",
      _light: "green.200",
    },
    "confetti-05": {
      _dark: "yellow.200",
      _light: "yellow.200",
    },
    "confetti-06": {
      _dark: "orange.300",
      _light: "orange.300",
    },
  },
};

/**
 * Gradient values
 */
export const gradients = {
  "gradient-border-purple":
    "linear-gradient(345deg, rgb(165, 110, 255, 0.2) 0%, rgb(165, 110, 255, 1) 65%, rgb(165, 110, 255, 0.2) 90%)",
  "gradient-border-green":
    "linear-gradient(345deg, rgb(17, 162, 77, 0.2) 0%, rgb(17, 162, 77, 1) 65%, rgb(17, 162, 77, 0.2) 90%)",

  "gradient-fill-purple": "linear(to-b, purple.500, purple.600)",
  "gradient-fill-gem": "linear(316deg, magenta.400, purple.500)",
  // yellow.200 to orange.400
  "gm-streak-radial": "radial-gradient(103.15% 104.36% at 22.39% 1.40%, #F8CC32 62.98%, #DF6D04 100%)",
  conicGradient:
    "conic-gradient(from 90deg at 50% 51.52%, #4589FF 0deg, #FF7EB6 141.23deg, #F1C21B 231.23deg, #49CD7A 287.48deg, #4589FF 360deg)",
  linearGradient: "linear-gradient(90deg, #EE5396 0%, #F1C21B 32.04%, #49CD7A 66%, #4589FF 100%)",
  "ui-gradient-v-01": "linear(to-t, ui-gradient-02-a, ui-gradient-02-b)",
  "ui-gradient-v-02": "linear(to-t, ui-gradient-02-b, ui-gradient-02-a)",
  "image-overlay":
    "linear-gradient(180deg, rgba(18, 22, 25, 0.00) 0%, rgba(18, 22, 25, 0.20) 48.96%, rgba(18, 22, 25, 0.50) 100%)",
};

/**
 * Semantic color values
 */
export const base = {
  conicGradient: gradients.conicGradient,
  linearGradient: gradients.linearGradient,
};

export const colors: Record<string, Record<string, string>> = {
  ...palette,
  base,
};

export const confettiColors = [
  "confetti-01",
  "confetti-02",
  "confetti-03",
  "confetti-04",
  "confetti-05",
  "confetti-06",
];
