import { useAccount, useConfig } from "wagmi";

export const useIsChainSupported = () => {
  const { chain: currentChain } = useAccount();
  const { chains } = useConfig();

  if (!currentChain) {
    return null;
  }
  return chains.some((chain) => chain.id === currentChain.id);
};
