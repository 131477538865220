import { VStack } from "@chakra-ui/react";
import { ChainId } from "@looksrare/config";
import { CurrentSubStep, StepDetails, StepRow } from "./shared";
import { Step, useEnterCaveStore } from "./state";

interface LoginStepProps {
  chainId: ChainId;
  onRetry: () => void;
}

export const LoginStep = ({ chainId, onRetry }: LoginStepProps) => {
  const step = useEnterCaveStore((state) => state.steps[Step.LOGIN]);
  return (
    <VStack spacing={4} alignItems="start" mb={4}>
      <StepRow stepStatus={step.status}>Sign In</StepRow>
      {step.status === "current" && (
        <StepDetails>
          <CurrentSubStep
            chainId={chainId}
            hasError={step.hasError}
            hashLinkText="Signature"
            totalSteps={1}
            currentStep={1}
            hash={step.hash}
            onRetry={onRetry}
          />
        </StepDetails>
      )}
    </VStack>
  );
};
