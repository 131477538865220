import { Icon, IconProps } from "../Icon";

const ChartCandlestick = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 6V2H22V6H18V22H22V26H24V22H28V6L24 6ZM20 8L26 8V20H20L20 8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 10L8 6H10V10L14 10L14 26H10L10 30H8L8 26H4V10H8ZM6 24H12L12 12H6L6 24Z"
      fill="currentColor"
    />
  </Icon>
);

export default ChartCandlestick;
