import { Box } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import { SupportedCurrency } from "..";
import { SectionLoader } from "../../../SectionLoader";

const MoonpayWidget = dynamic(() => import("./MoonpayWidget"), {
  loading: () => <SectionLoader spinnerProps={{ size: "lg" }} />,
});

export interface MoonpayWidgetViewProps {
  defaultBuyCurrency?: SupportedCurrency;
  defaultBuyAmount?: number;
}

export const MoonpayWidgetView = ({ defaultBuyCurrency, defaultBuyAmount }: MoonpayWidgetViewProps) => {
  return (
    <Box>
      <MoonpayWidget defaultBuyCurrency={defaultBuyCurrency} defaultBuyAmount={defaultBuyAmount} />
    </Box>
  );
};
