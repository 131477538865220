import { Icon, IconProps } from "../Icon";

const NoData = (props: IconProps) => (
  <Icon {...props}>
    <path
      d="M 25.832031 18.507812 L 22.71875 18.007812 L 22.761719 17.726562 C 23.234375 14.851562 24.148438 14.097656 25.667969 13.445312 C 26.78125 12.96875 27.675781 12.378906 27.851562 11.300781 C 28.035156 10.160156 27.257812 9.273438 26.148438 9.097656 C 25.070312 8.925781 23.960938 9.480469 23.695312 10.8125 L 20.34375 10.273438 C 20.945312 6.996094 23.628906 5.824219 26.613281 6.304688 C 29.875 6.828125 31.925781 8.878906 31.460938 11.753906 C 31.152344 13.6875 29.957031 14.734375 28.273438 15.398438 C 26.847656 15.972656 26.152344 16.628906 25.878906 18.226562 Z M 25.78125 21.871094 C 25.597656 22.964844 24.542969 23.699219 23.492188 23.527344 C 22.40625 23.355469 21.652344 22.332031 21.835938 21.238281 C 22 20.160156 23.039062 19.425781 24.121094 19.597656 C 25.175781 19.769531 25.945312 20.792969 25.78125 21.871094 Z M 13.738281 8.980469 L 21.917969 13.675781 L 14.027344 27.265625 L 0.398438 19.445312 L 5.136719 11.285156 Z M 5.953125 15.539062 C 7.8125 12.316406 11.933594 11.210938 15.15625 13.074219 L 17.941406 14.683594 L 16.335938 17.46875 C 14.476562 20.695312 10.355469 21.800781 7.132812 19.9375 L 4.347656 18.328125 Z M 12.714844 18.472656 C 14.199219 18.074219 15.089844 16.578125 14.703125 15.132812 C 14.3125 13.683594 12.796875 12.835938 11.308594 13.234375 C 9.824219 13.632812 8.933594 15.128906 9.320312 16.574219 C 9.710938 18.019531 11.226562 18.871094 12.714844 18.472656 Z M 12.382812 16.949219 C 13.007812 16.78125 13.378906 16.140625 13.210938 15.511719 C 13.042969 14.886719 12.402344 14.515625 11.773438 14.683594 C 11.148438 14.851562 10.777344 15.496094 10.945312 16.121094 C 11.113281 16.746094 11.757812 17.117188 12.382812 16.949219 Z M 12.382812 16.949219 "
      fill="currentColor"
      fillRule="evenodd"
    />
  </Icon>
);

export default NoData;
