import { Icon, IconProps } from "@chakra-ui/react";

export const RankTwo = (props: IconProps) => (
  <Icon width="96" height="96" viewBox="0 0 96 96" fill="none" {...props}>
    <path
      d="M43.7159 8.31884C46.3888 6.87211 49.6112 6.87211 52.2841 8.31884L84.8533 25.9474C87.7589 27.5201 89.5692 30.5585 89.5692 33.8624V68.1376C89.5692 71.4415 87.7589 74.4799 84.8533 76.0526L52.2841 93.6812C49.6112 95.1279 46.3888 95.1279 43.7159 93.6812L11.1467 76.0526C8.24108 74.4799 6.43078 71.4415 6.43078 68.1376V33.8624C6.43078 30.5585 8.24108 27.5201 11.1467 25.9474L43.7159 8.31884Z"
      fill="url(#paint0_radial_4248_210461)"
    />
    <g style={{ mixBlendMode: "overlay" }}>
      <path
        d="M43.7159 8.31884C46.3888 6.87211 49.6112 6.87211 52.2841 8.31884L84.8533 25.9474C87.7589 27.5201 89.5692 30.5585 89.5692 33.8624V68.1376C89.5692 71.4415 87.7589 74.4799 84.8533 76.0526L52.2841 93.6812C49.6112 95.1279 46.3888 95.1279 43.7159 93.6812L11.1467 76.0526C8.24108 74.4799 6.43078 71.4415 6.43078 68.1376V33.8624C6.43078 30.5585 8.24108 27.5201 11.1467 25.9474L43.7159 8.31884Z"
        fill="#8A8C95"
      />
    </g>
    <path
      d="M43.7159 2.31884C46.3888 0.872111 49.6112 0.872109 52.2841 2.31884L84.8533 19.9474C87.7589 21.5201 89.5692 24.5585 89.5692 27.8624V62.1376C89.5692 65.4415 87.7589 68.4799 84.8533 70.0526L52.2841 87.6812C49.6112 89.1279 46.3888 89.1279 43.7159 87.6812L11.1467 70.0526C8.24108 68.4799 6.43078 65.4415 6.43078 62.1376V27.8624C6.43078 24.5585 8.24108 21.5201 11.1467 19.9474L43.7159 2.31884Z"
      fill="url(#paint1_radial_4248_210461)"
    />
    <g style={{ mixBlendMode: "overlay" }}>
      <path
        d="M43.7159 2.31884C46.3888 0.872111 49.6112 0.872109 52.2841 2.31884L84.8533 19.9474C87.7589 21.5201 89.5692 24.5585 89.5692 27.8624V62.1376C89.5692 65.4415 87.7589 68.4799 84.8533 70.0526L52.2841 87.6812C49.6112 89.1279 46.3888 89.1279 43.7159 87.6812L11.1467 70.0526C8.24108 68.4799 6.43078 65.4415 6.43078 62.1376V27.8624C6.43078 24.5585 8.24108 21.5201 11.1467 19.9474L43.7159 2.31884Z"
        fill="#C7C7CC"
      />
    </g>
    <g style={{ mixBlendMode: "soft-light" }} opacity="0.7">
      <path d="M18 72L54 6" stroke="url(#paint2_linear_4248_210461)" strokeWidth="12" />
    </g>
    <g style={{ mixBlendMode: "soft-light" }} opacity="0.5">
      <path d="M39 84L75 18" stroke="url(#paint3_linear_4248_210461)" strokeWidth="18" />
    </g>
    <path
      d="M44.4299 3.638C46.6573 2.43239 49.3427 2.43239 51.5701 3.63799L84.1393 21.2666C86.5606 22.5772 88.0692 25.1091 88.0692 27.8624V62.1376C88.0692 64.8909 86.5606 67.4228 84.1393 68.7334L51.5701 86.362C49.3427 87.5676 46.6573 87.5676 44.4299 86.362L11.8607 68.7334C9.43936 67.4228 7.93078 64.8909 7.93078 62.1376V27.8624C7.93078 25.1091 9.43936 22.5772 11.8607 21.2666L44.4299 3.638Z"
      stroke="url(#paint4_linear_4248_210461)"
      strokeWidth="3"
    />
    <g style={{ mixBlendMode: "overlay" }}>
      <path
        d="M44.4299 3.638C46.6573 2.43239 49.3427 2.43239 51.5701 3.63799L84.1393 21.2666C86.5606 22.5772 88.0692 25.1091 88.0692 27.8624V62.1376C88.0692 64.8909 86.5606 67.4228 84.1393 68.7334L51.5701 86.362C49.3427 87.5676 46.6573 87.5676 44.4299 86.362L11.8607 68.7334C9.43936 67.4228 7.93078 64.8909 7.93078 62.1376V27.8624C7.93078 25.1091 9.43936 22.5772 11.8607 21.2666L44.4299 3.638Z"
        stroke="#C7C7CC"
        strokeWidth="3"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }} filter="url(#filter0_i_4248_210461)">
      <path
        d="M36.4424 63V58.488L49.2104 45.96C50.5864 44.584 51.5464 43.4 52.0904 42.408C52.6664 41.416 52.9544 40.312 52.9544 39.096C52.9544 37.656 52.5224 36.504 51.6584 35.64C50.7944 34.744 49.5784 34.296 48.0104 34.296C46.4744 34.296 45.2424 34.728 44.3144 35.592C43.3864 36.424 42.9224 37.576 42.9224 39.048C42.9224 39.848 43.0664 40.616 43.3544 41.352L37.2104 42.504C36.7944 41.448 36.5864 40.184 36.5864 38.712C36.5864 36.952 37.0344 35.304 37.9304 33.768C38.8584 32.232 40.1864 31.016 41.9144 30.12C43.6744 29.192 45.7064 28.728 48.0104 28.728C50.4104 28.728 52.4584 29.144 54.1544 29.976C55.8504 30.808 57.1304 31.96 57.9944 33.432C58.8584 34.872 59.2904 36.52 59.2904 38.376C59.2904 40.584 58.8584 42.52 57.9944 44.184C57.1624 45.816 55.6424 47.688 53.4344 49.8L45.8984 57.144H59.2424V63H36.4424Z"
        fill="#8A8C95"
      />
    </g>
    <path
      d="M11.4373 79.5207C11.6306 78.9983 12.3694 78.9983 12.5627 79.5207L14.3351 84.3104C14.3958 84.4747 14.5253 84.6042 14.6896 84.6649L19.4793 86.4373C20.0017 86.6306 20.0017 87.3694 19.4793 87.5627L14.6896 89.3351C14.5253 89.3958 14.3958 89.5253 14.3351 89.6896L12.5627 94.4793C12.3694 95.0017 11.6306 95.0017 11.4373 94.4793L9.66493 89.6896C9.60416 89.5253 9.47467 89.3958 9.31044 89.3351L4.5207 87.5627C3.99833 87.3694 3.99833 86.6306 4.5207 86.4373L9.31044 84.6649C9.47467 84.6042 9.60416 84.4747 9.66493 84.3104L11.4373 79.5207Z"
      fill="#E5E5EA"
    />
    <g style={{ mixBlendMode: "overlay" }} filter="url(#filter1_i_4248_210461)">
      <path
        d="M11.4373 79.5207C11.6306 78.9983 12.3694 78.9983 12.5627 79.5207L14.3351 84.3104C14.3958 84.4747 14.5253 84.6042 14.6896 84.6649L19.4793 86.4373C20.0017 86.6306 20.0017 87.3694 19.4793 87.5627L14.6896 89.3351C14.5253 89.3958 14.3958 89.5253 14.3351 89.6896L12.5627 94.4793C12.3694 95.0017 11.6306 95.0017 11.4373 94.4793L9.66493 89.6896C9.60416 89.5253 9.47467 89.3958 9.31044 89.3351L4.5207 87.5627C3.99833 87.3694 3.99833 86.6306 4.5207 86.4373L9.31044 84.6649C9.47467 84.6042 9.60416 84.4747 9.66493 84.3104L11.4373 79.5207Z"
        fill="url(#paint5_linear_4248_210461)"
      />
    </g>
    <g style={{ mixBlendMode: "overlay" }} filter="url(#filter2_di_4248_210461)">
      <path
        d="M76.8746 3.04141C77.2612 1.99667 78.7388 1.99667 79.1254 3.04141L82.6701 12.6209C82.7917 12.9493 83.0507 13.2083 83.3791 13.3299L92.9586 16.8746C94.0033 17.2612 94.0033 18.7388 92.9586 19.1254L83.3791 22.6701C83.0507 22.7917 82.7917 23.0507 82.6701 23.3791L79.1254 32.9586C78.7388 34.0033 77.2612 34.0033 76.8746 32.9586L73.3299 23.3791C73.2083 23.0507 72.9493 22.7917 72.6209 22.6701L63.0414 19.1254C61.9967 18.7388 61.9967 17.2612 63.0414 16.8746L72.6209 13.3299C72.9493 13.2083 73.2083 12.9493 73.3299 12.6209L76.8746 3.04141Z"
        fill="url(#paint6_linear_4248_210461)"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      d="M76.8746 3.04141C77.2612 1.99667 78.7388 1.99667 79.1254 3.04141L82.6701 12.6209C82.7917 12.9493 83.0507 13.2083 83.3791 13.3299L92.9586 16.8746C94.0033 17.2612 94.0033 18.7388 92.9586 19.1254L83.3791 22.6701C83.0507 22.7917 82.7917 23.0507 82.6701 23.3791L79.1254 32.9586C78.7388 34.0033 77.2612 34.0033 76.8746 32.9586L73.3299 23.3791C73.2083 23.0507 72.9493 22.7917 72.6209 22.6701L63.0414 19.1254C61.9967 18.7388 61.9967 17.2612 63.0414 16.8746L72.6209 13.3299C72.9493 13.2083 73.2083 12.9493 73.3299 12.6209L76.8746 3.04141Z"
      fill="#E5E5EA"
    />
    <defs>
      <filter
        id="filter0_i_4248_210461"
        x="36.4424"
        y="28.728"
        width="22.848"
        height="37.272"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="luminosity" in2="shape" result="effect1_innerShadow_4248_210461" />
      </filter>
      <filter
        id="filter1_i_4248_210461"
        x="-1.87109"
        y="79.1289"
        width="21.7422"
        height="21.7421"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-6" dy="6" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_4248_210461" />
      </filter>
      <filter
        id="filter2_di_4248_210461"
        x="50.2578"
        y="2.25784"
        width="55.4844"
        height="55.4843"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4248_210461" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4248_210461" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-6" dy="6" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_4248_210461" />
      </filter>
      <radialGradient
        id="paint0_radial_4248_210461"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 6) rotate(43.1524) scale(131.59 131.317)"
      >
        <stop offset="0.34375" stopColor="#827F7F" />
        <stop offset="1" stopColor="#5F5F5F" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_4248_210461"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(43.1524) scale(131.59 131.317)"
      >
        <stop offset="0.34375" stopColor="#827F7F" />
        <stop offset="1" stopColor="#5F5F5F" />
      </radialGradient>
      <linearGradient id="paint2_linear_4248_210461" x1="36" y1="72" x2="36" y2="6" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint3_linear_4248_210461" x1="57" y1="84" x2="57" y2="18" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4248_210461"
        x1="96"
        y1="0"
        x2="1.43051e-06"
        y2="94.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#697077" />
        <stop offset="1" stopColor="#C7C7CC" />
      </linearGradient>
      <linearGradient id="paint5_linear_4248_210461" x1="12" y1="78" x2="12" y2="96" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint6_linear_4248_210461" x1="78" y1="0" x2="78" y2="36" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </Icon>
);

export const RankTwoGradient = (props: IconProps) => (
  <Icon width="284" height="128" viewBox="0 0 284 128" fill="none" {...props}>
    <g clipPath="url(#clip0_4248_209470)">
      <g opacity="0.5" filter="url(#filter0_f_4248_209470)">
        <path d="M248.5 -24C248.5 46.6924 121.349 104 -35.5 104V-24H248.5Z" fill="url(#paint0_radial_4248_209470)" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_4248_209470"
        x="-51.5"
        y="-40"
        width="316"
        height="160"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_4248_209470" />
      </filter>
      <radialGradient
        id="paint0_radial_4248_209470"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-35.5 -24.0007) rotate(90) scale(128 284)"
      >
        <stop stopColor="#A56EFF" />
        <stop offset="0.0001" stopColor="#BE95FF" />
        <stop offset="1" stopColor="#A56EFF" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_4248_209470">
        <rect width="284" height="128" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
