import { type FC, type PropsWithChildren } from "react";
import { Flex, type FlexProps } from "@chakra-ui/react";
import padStart from "lodash/padStart";

export const CountdownWrapper: FC<PropsWithChildren<FlexProps>> = (props) => (
  <Flex
    bg="ui-bg"
    borderRadius="circular"
    height={{ base: "61px", sm: "114px" }}
    width={{ base: "62px", sm: "115px" }}
    alignItems="center"
    justifyContent="center"
    position="absolute"
    top="50%"
    left={0}
    transform="translateY(-50%)"
    {...props}
  />
);

const defaultColor = { stroke: "text-03", text: "text-02" };
export const getStrokeColor = (secondsRemaining: number, isInActive: boolean) => {
  if (isInActive) {
    return defaultColor;
  }
  if (secondsRemaining <= 5) {
    return { stroke: "support-error", text: "support-error" };
  }
  if (secondsRemaining <= 10) {
    return { stroke: "support-warning", text: "support-warning" };
  }
  if (secondsRemaining <= 15) {
    return { stroke: "support-success", text: "support-success" };
  }
  return defaultColor;
};

export const padZero = (num: number) => padStart(num.toString(), 2, "0");
