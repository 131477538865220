import { Icon, IconProps } from "../Icon";

const Github = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 1.99996C9.13491 2.00089 3.28331 6.97958 2.1829 13.7559C1.0825 20.5322 5.05797 27.1067 11.57 29.28C12.27 29.41 12.57 28.98 12.57 28.61V26.23C8.68 27.07 7.86 24.35 7.86 24.35C7.58645 23.4928 7.01072 22.7642 6.24 22.3C4.97 21.44 6.34 21.45 6.34 21.45C7.23895 21.577 8.02881 22.1121 8.48 22.9C9.28658 24.3466 11.113 24.8659 12.56 24.06C12.6165 23.3504 12.9293 22.6857 13.44 22.19C10.34 21.83 7.07 20.63 7.07 15.27C7.04733 13.8779 7.56322 12.5308 8.51 11.51C8.08146 10.3042 8.13156 8.97994 8.65 7.80996C8.65 7.80996 9.82 7.42996 12.5 9.23996C14.7915 8.61491 17.2085 8.61491 19.5 9.23996C22.17 7.42996 23.34 7.80996 23.34 7.80996C23.8584 8.97994 23.9085 10.3042 23.48 11.51C24.4268 12.5308 24.9427 13.8779 24.92 15.27C24.92 20.65 21.65 21.83 18.53 22.18C19.2062 22.8654 19.5527 23.8099 19.48 24.77V28.61C19.48 29.07 19.73 29.42 20.48 29.28C27.0011 27.1038 30.9774 20.5149 29.8633 13.7311C28.7493 6.94737 22.8746 1.97621 16 1.99996Z"
      fill="currentColor"
    />
  </Icon>
);

export default Github;
