import { Icon, IconProps } from "@chakra-ui/react";

export const RankThree = (props: IconProps) => (
  <Icon width="96" height="96" viewBox="0 0 96 96" fill="none" {...props}>
    <path
      d="M43.7159 8.31884C46.3888 6.87211 49.6112 6.87211 52.2841 8.31884L84.8533 25.9474C87.7589 27.5201 89.5692 30.5585 89.5692 33.8624V68.1376C89.5692 71.4415 87.7589 74.4799 84.8533 76.0526L52.2841 93.6812C49.6112 95.1279 46.3888 95.1279 43.7159 93.6812L11.1467 76.0526C8.24108 74.4799 6.43078 71.4415 6.43078 68.1376V33.8624C6.43078 30.5585 8.24108 27.5201 11.1467 25.9474L43.7159 8.31884Z"
      fill="url(#paint0_radial_4248_210460)"
    />
    <g style={{ mixBlendMode: "overlay" }}>
      <path
        d="M43.7159 8.31884C46.3888 6.87211 49.6112 6.87211 52.2841 8.31884L84.8533 25.9474C87.7589 27.5201 89.5692 30.5585 89.5692 33.8624V68.1376C89.5692 71.4415 87.7589 74.4799 84.8533 76.0526L52.2841 93.6812C49.6112 95.1279 46.3888 95.1279 43.7159 93.6812L11.1467 76.0526C8.24108 74.4799 6.43078 71.4415 6.43078 68.1376V33.8624C6.43078 30.5585 8.24108 27.5201 11.1467 25.9474L43.7159 8.31884Z"
        fill="#DF6D04"
      />
    </g>
    <path
      d="M43.7159 2.31884C46.3888 0.872111 49.6112 0.872109 52.2841 2.31884L84.8533 19.9474C87.7589 21.5201 89.5692 24.5585 89.5692 27.8624V62.1376C89.5692 65.4415 87.7589 68.4799 84.8533 70.0526L52.2841 87.6812C49.6112 89.1279 46.3888 89.1279 43.7159 87.6812L11.1467 70.0526C8.24108 68.4799 6.43078 65.4415 6.43078 62.1376V27.8624C6.43078 24.5585 8.24108 21.5201 11.1467 19.9474L43.7159 2.31884Z"
      fill="url(#paint1_radial_4248_210460)"
    />
    <g style={{ mixBlendMode: "overlay" }}>
      <path
        d="M43.7159 2.31884C46.3888 0.872111 49.6112 0.872109 52.2841 2.31884L84.8533 19.9474C87.7589 21.5201 89.5692 24.5585 89.5692 27.8624V62.1376C89.5692 65.4415 87.7589 68.4799 84.8533 70.0526L52.2841 87.6812C49.6112 89.1279 46.3888 89.1279 43.7159 87.6812L11.1467 70.0526C8.24108 68.4799 6.43078 65.4415 6.43078 62.1376V27.8624C6.43078 24.5585 8.24108 21.5201 11.1467 19.9474L43.7159 2.31884Z"
        fill="#FF8A14"
      />
    </g>
    <g style={{ mixBlendMode: "soft-light" }} opacity="0.7">
      <path d="M18 72L54 6" stroke="url(#paint2_linear_4248_210460)" strokeWidth="12" />
    </g>
    <g style={{ mixBlendMode: "soft-light" }} opacity="0.5">
      <path d="M39 84L75 18" stroke="url(#paint3_linear_4248_210460)" strokeWidth="18" />
    </g>
    <path
      d="M44.4299 3.638C46.6573 2.43239 49.3427 2.43239 51.5701 3.63799L84.1393 21.2666C86.5606 22.5772 88.0692 25.1091 88.0692 27.8624V62.1376C88.0692 64.8909 86.5606 67.4228 84.1393 68.7334L51.5701 86.362C49.3427 87.5676 46.6573 87.5676 44.4299 86.362L11.8607 68.7334C9.43936 67.4228 7.93078 64.8909 7.93078 62.1376V27.8624C7.93078 25.1091 9.43936 22.5772 11.8607 21.2666L44.4299 3.638Z"
      stroke="url(#paint4_linear_4248_210460)"
      strokeWidth="3"
    />
    <g style={{ mixBlendMode: "overlay" }}>
      <path
        d="M44.4299 3.638C46.6573 2.43239 49.3427 2.43239 51.5701 3.63799L84.1393 21.2666C86.5606 22.5772 88.0692 25.1091 88.0692 27.8624V62.1376C88.0692 64.8909 86.5606 67.4228 84.1393 68.7334L51.5701 86.362C49.3427 87.5676 46.6573 87.5676 44.4299 86.362L11.8607 68.7334C9.43936 67.4228 7.93078 64.8909 7.93078 62.1376V27.8624C7.93078 25.1091 9.43936 22.5772 11.8607 21.2666L44.4299 3.638Z"
        stroke="#FF8A14"
        strokeWidth="3"
      />
    </g>
    <g style={{ mixBlendMode: "multiply" }} filter="url(#filter0_i_4248_210460)">
      <path
        d="M47.9626 63.672C45.3706 63.672 43.1626 63.208 41.3386 62.28C39.5466 61.352 38.2026 60.104 37.3066 58.536C36.4106 56.968 35.9626 55.256 35.9626 53.4C35.9626 52.92 36.0106 52.408 36.1066 51.864C36.2026 51.288 36.3306 50.808 36.4906 50.424L42.4906 51.864C42.3626 52.248 42.2986 52.712 42.2986 53.256C42.2986 54.792 42.8106 56.024 43.8346 56.952C44.8906 57.848 46.2666 58.296 47.9626 58.296C49.6906 58.296 51.0346 57.832 51.9946 56.904C52.9866 55.944 53.4826 54.632 53.4826 52.968C53.4826 49.256 50.7306 47.4 45.2266 47.4V42.696C47.4986 42.696 49.1786 42.28 50.2666 41.448C51.3866 40.616 51.9466 39.416 51.9466 37.848C51.9466 36.664 51.5306 35.72 50.6986 35.016C49.8986 34.28 48.7946 33.912 47.3866 33.912C46.0106 33.912 44.9226 34.264 44.1226 34.968C43.3226 35.64 42.9226 36.584 42.9226 37.8C42.9226 38.44 43.0186 38.984 43.2106 39.432L37.3546 40.728C37.0346 39.768 36.8746 38.888 36.8746 38.088C36.8746 36.424 37.2746 34.888 38.0746 33.48C38.9066 32.04 40.1226 30.888 41.7226 30.024C43.3226 29.16 45.2266 28.728 47.4346 28.728C49.6746 28.728 51.5946 29.08 53.1946 29.784C54.7946 30.488 56.0106 31.464 56.8426 32.712C57.6746 33.96 58.0906 35.384 58.0906 36.984C58.0906 38.744 57.6426 40.296 56.7466 41.64C55.8826 42.952 54.7306 43.912 53.2906 44.52C55.2426 45.192 56.8106 46.232 57.9946 47.64C59.2106 49.048 59.8186 50.824 59.8186 52.968C59.8186 55.144 59.3226 57.048 58.3306 58.68C57.3706 60.28 55.9946 61.512 54.2026 62.376C52.4106 63.24 50.3306 63.672 47.9626 63.672Z"
        fill="#DF6D04"
      />
    </g>
    <path
      d="M11.4373 79.5207C11.6306 78.9983 12.3694 78.9983 12.5627 79.5207L14.3351 84.3104C14.3958 84.4747 14.5253 84.6042 14.6896 84.6649L19.4793 86.4373C20.0017 86.6306 20.0017 87.3694 19.4793 87.5627L14.6896 89.3351C14.5253 89.3958 14.3958 89.5253 14.3351 89.6896L12.5627 94.4793C12.3694 95.0017 11.6306 95.0017 11.4373 94.4793L9.66493 89.6896C9.60416 89.5253 9.47467 89.3958 9.31044 89.3351L4.5207 87.5627C3.99833 87.3694 3.99833 86.6306 4.5207 86.4373L9.31044 84.6649C9.47467 84.6042 9.60416 84.4747 9.66493 84.3104L11.4373 79.5207Z"
      fill="#FFDBB3"
    />
    <g style={{ mixBlendMode: "overlay" }} filter="url(#filter1_i_4248_210460)">
      <path
        d="M11.4373 79.5207C11.6306 78.9983 12.3694 78.9983 12.5627 79.5207L14.3351 84.3104C14.3958 84.4747 14.5253 84.6042 14.6896 84.6649L19.4793 86.4373C20.0017 86.6306 20.0017 87.3694 19.4793 87.5627L14.6896 89.3351C14.5253 89.3958 14.3958 89.5253 14.3351 89.6896L12.5627 94.4793C12.3694 95.0017 11.6306 95.0017 11.4373 94.4793L9.66493 89.6896C9.60416 89.5253 9.47467 89.3958 9.31044 89.3351L4.5207 87.5627C3.99833 87.3694 3.99833 86.6306 4.5207 86.4373L9.31044 84.6649C9.47467 84.6042 9.60416 84.4747 9.66493 84.3104L11.4373 79.5207Z"
        fill="url(#paint5_linear_4248_210460)"
      />
    </g>
    <g style={{ mixBlendMode: "overlay" }} filter="url(#filter2_di_4248_210460)">
      <path
        d="M76.8746 3.04141C77.2612 1.99667 78.7388 1.99667 79.1254 3.04141L82.6701 12.6209C82.7917 12.9493 83.0507 13.2083 83.3791 13.3299L92.9586 16.8746C94.0033 17.2612 94.0033 18.7388 92.9586 19.1254L83.3791 22.6701C83.0507 22.7917 82.7917 23.0507 82.6701 23.3791L79.1254 32.9586C78.7388 34.0033 77.2612 34.0033 76.8746 32.9586L73.3299 23.3791C73.2083 23.0507 72.9493 22.7917 72.6209 22.6701L63.0414 19.1254C61.9967 18.7388 61.9967 17.2612 63.0414 16.8746L72.6209 13.3299C72.9493 13.2083 73.2083 12.9493 73.3299 12.6209L76.8746 3.04141Z"
        fill="url(#paint6_linear_4248_210460)"
        shapeRendering="crispEdges"
      />
    </g>
    <path
      d="M76.8746 3.04141C77.2612 1.99667 78.7388 1.99667 79.1254 3.04141L82.6701 12.6209C82.7917 12.9493 83.0507 13.2083 83.3791 13.3299L92.9586 16.8746C94.0033 17.2612 94.0033 18.7388 92.9586 19.1254L83.3791 22.6701C83.0507 22.7917 82.7917 23.0507 82.6701 23.3791L79.1254 32.9586C78.7388 34.0033 77.2612 34.0033 76.8746 32.9586L73.3299 23.3791C73.2083 23.0507 72.9493 22.7917 72.6209 22.6701L63.0414 19.1254C61.9967 18.7388 61.9967 17.2612 63.0414 16.8746L72.6209 13.3299C72.9493 13.2083 73.2083 12.9493 73.3299 12.6209L76.8746 3.04141Z"
      fill="#FFB675"
    />
    <defs>
      <filter
        id="filter0_i_4248_210460"
        x="35.9626"
        y="28.728"
        width="23.856"
        height="37.944"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="luminosity" in2="shape" result="effect1_innerShadow_4248_210460" />
      </filter>
      <filter
        id="filter1_i_4248_210460"
        x="-1.87109"
        y="79.1289"
        width="21.7422"
        height="21.7422"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-6" dy="6" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_4248_210460" />
      </filter>
      <filter
        id="filter2_di_4248_210460"
        x="50.2578"
        y="2.25784"
        width="55.4844"
        height="55.4843"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="12" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4248_210460" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4248_210460" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-6" dy="6" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_4248_210460" />
      </filter>
      <radialGradient
        id="paint0_radial_4248_210460"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(0 6) rotate(43.1524) scale(131.59 131.317)"
      >
        <stop offset="0.34375" stopColor="#827F7F" />
        <stop offset="1" stopColor="#5F5F5F" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_4248_210460"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(43.1524) scale(131.59 131.317)"
      >
        <stop offset="0.34375" stopColor="#827F7F" />
        <stop offset="1" stopColor="#5F5F5F" />
      </radialGradient>
      <linearGradient id="paint2_linear_4248_210460" x1="36" y1="72" x2="36" y2="6" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint3_linear_4248_210460" x1="57" y1="84" x2="57" y2="18" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4248_210460"
        x1="96"
        y1="0"
        x2="1.43051e-06"
        y2="94.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#697077" />
        <stop offset="1" stopColor="#C7C7CC" />
      </linearGradient>
      <linearGradient id="paint5_linear_4248_210460" x1="12" y1="78" x2="12" y2="96" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint6_linear_4248_210460" x1="78" y1="0" x2="78" y2="36" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </Icon>
);

export const RankThreeGradient = (props: IconProps) => (
  <Icon width="284" height="128" viewBox="0 0 284 128" fill="none" {...props}>
    <g clipPath="url(#clip0_4248_209479)">
      <g opacity="0.5" filter="url(#filter0_f_4248_209479)">
        <path d="M248.5 -24C248.5 46.6924 121.349 104 -35.5 104V-24H248.5Z" fill="url(#paint0_radial_4248_209479)" />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_4248_209479"
        x="-51.5"
        y="-40"
        width="316"
        height="160"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="8" result="effect1_foregroundBlur_4248_209479" />
      </filter>
      <radialGradient
        id="paint0_radial_4248_209479"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(-35.5 -24.0007) rotate(90) scale(128 284)"
      >
        <stop stopColor="#FFA500" />
        <stop offset="1" stopColor="#FFA500" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_4248_209479">
        <rect width="284" height="128" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
