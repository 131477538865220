import { Icon, IconProps } from "../Icon";

const SegmentedChart = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 30H14H19H20V4H12V30ZM18 6H14V18H18V6ZM10 14H2V22V30H9H10V14ZM8 22V16H4V22H8ZM30 14H22V30H24H29H30V14ZM28 22H24V16H28V22Z"
      fill="currentColor"
    />
  </Icon>
);

export default SegmentedChart;
