import { Box, BoxProps } from "@chakra-ui/react";
import { StateMachineInput } from "@rive-app/canvas";
import { useRive, useStateMachineInput } from "@rive-app/react-canvas";
import { useEffect, useMemo } from "react";

export type UfoState = "idle" | "loading" | "moon" | "doom" | "ending-moon" | "ending-doom";

interface UfoProps extends BoxProps {
  state: UfoState;
}

export const Ufo = ({ state, ...props }: UfoProps) => {
  const { rive, RiveComponent } = useRive({
    src: "/rive/ufo.riv",
    stateMachines: "scene entry",
    autoplay: true,
    artboard: "scene",
  });

  const idle = useStateMachineInput(rive, "scene entry", "trigger-idle", false);
  const loading = useStateMachineInput(rive, "scene entry", "trigger-loading", false);
  const moon = useStateMachineInput(rive, "scene entry", "trigger-moon", false);
  const endingMoon = useStateMachineInput(rive, "scene entry", "trigger-end-moon", false);
  const doom = useStateMachineInput(rive, "scene entry", "trigger-doom", false);
  const endingDoom = useStateMachineInput(rive, "scene entry", "trigger-end-doom", false);

  const stateFunctionMap: Record<UfoState, StateMachineInput | null> = useMemo(() => {
    return {
      idle,
      loading,
      moon,
      "ending-moon": endingMoon,
      doom,
      "ending-doom": endingDoom,
    };
  }, [idle, loading, moon, endingMoon, doom, endingDoom]);

  // Fire input function when state changes
  useEffect(() => {
    stateFunctionMap[state]?.fire();
  }, [state, stateFunctionMap]);

  return (
    <Box {...props}>
      <RiveComponent style={{ width: "100%", height: "100%" }} />
    </Box>
  );
};
