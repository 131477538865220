import { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { Duration, add } from "date-fns";
import { getLocalStorageItem, setLocalStorageItem } from "../localStorage";

interface Props {
  baseKey: string;
  duration?: Duration;
  useAccountInKey?: boolean;
  shouldSnoozeDurationMultiply?: boolean;
  durationMultiplier?: number;
}

export interface SnoozeLsObject {
  endTime: number;
  startTime: number;
}

/**
 * Hook to handle setting a local storage key with a timestamp value,
 * The isSnoozed boolean reflects whether a period of time since that timestamp has passed
 * @param baseKey Base local storage key, minus account address
 * @param duration Optional Duration object for setting isSnoozed expiry
 * @param useAccountInKey Optional boolean for whether to use account in local storage key (making the snooze account-specific)
 * @returns Object
 */
export const useLocalStorageSnooze = ({
  baseKey,
  duration = { days: 1 },
  useAccountInKey = false,
  shouldSnoozeDurationMultiply = false,
  durationMultiplier = 5,
}: Props) => {
  const { address } = useAccount();
  const lsKey = useAccountInKey ? `${baseKey}_${address || ""}` : baseKey;
  const [isSnoozed, setIsSnoozed] = useState(true);
  useEffect(() => {
    const snoozeLsItem = getLocalStorageItem<SnoozeLsObject>(lsKey);
    const snoozeEndTime = snoozeLsItem?.endTime || snoozeLsItem || 0; // support legacy snoozes by checking the item itself if `endTime` does not exist
    setIsSnoozed(Date.now() < Number(snoozeEndTime));
    // lsKey updates after eager connect or account change - so setIsSnoozed evaluation should happen if the value changes
  }, [lsKey]);

  const handleSnooze = () => {
    const startDate = new Date();
    const previousEndTime = getLocalStorageItem<SnoozeLsObject>(lsKey)?.endTime;
    const previousStartTime = getLocalStorageItem<SnoozeLsObject>(lsKey)?.startTime;
    let endDate;
    endDate = add(startDate, duration);
    if (shouldSnoozeDurationMultiply && previousEndTime && previousStartTime) {
      const previousDurationMs = previousEndTime - previousStartTime;
      const newMultipliedDurationMs = previousDurationMs * durationMultiplier;
      endDate = new Date(startDate.getTime() + newMultipliedDurationMs);
    }
    setLocalStorageItem(lsKey, { endTime: endDate.getTime(), startTime: startDate.getTime() });
    setIsSnoozed(true);
  };

  const handleSnoozeIndefinitely = () => {
    const startDate = new Date();
    const endDate = add(startDate, { years: 100 });

    setLocalStorageItem(lsKey, { endTime: endDate.getTime(), startTime: startDate.getTime() });
    setIsSnoozed(true);
  };

  return { isSnoozed, handleSnooze, handleSnoozeIndefinitely };
};

export default useLocalStorageSnooze;
