import { Icon, IconProps } from "../Icon";

const ArrowDownRightFilled = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.002 4H5.998C4.89454 4 4 4.89454 4 5.998V26.002C4 27.1055 4.89454 28 5.998 28H26.002C26.5319 28 27.0401 27.7895 27.4148 27.4148C27.7895 27.0401 28 26.5319 28 26.002V5.998C28 5.4681 27.7895 4.9599 27.4148 4.5852C27.0401 4.2105 26.5319 4 26.002 4ZM10 24V22H20.59L8 9.41L9.41 8L22 20.59V10H24V24H10Z"
      fill="currentColor"
    />
  </Icon>
);

export default ArrowDownRightFilled;
