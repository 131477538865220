import { Icon, IconProps } from "../Icon";

const ViewOffFilled = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.94 15.66C29.7977 12.6708 27.8059 10.0811 25.21 8.21L30 3.41L28.59 2L1.99999 28.59L3.40999 30L8.50999 24.91C10.7864 26.2367 13.3655 26.9564 16 27C22.6621 26.7495 28.536 22.5583 30.94 16.34C31.0194 16.1203 31.0194 15.8797 30.94 15.66ZM15.9998 22.5C14.6214 22.4994 13.2793 22.0578 12.1698 21.24L13.9998 19.43C15.5584 20.284 17.4936 20.0072 18.7503 18.7505C20.007 17.4938 20.2838 15.5586 19.4298 14L21.2398 12.19C22.6763 14.1629 22.8845 16.7749 21.7787 18.9505C20.673 21.1261 18.4403 22.4975 15.9998 22.5ZM4.52988 21.81L9.52988 16.81C9.50388 16.5408 9.49386 16.2704 9.49988 16C9.50539 12.4124 12.4123 9.50551 15.9999 9.5C16.264 9.5014 16.5277 9.5181 16.7899 9.55L20.5699 5.78C19.0987 5.27471 17.5554 5.01129 15.9999 5C9.33778 5.25053 3.46385 9.4417 1.05988 15.66C0.980447 15.8797 0.980447 16.1203 1.05988 16.34C1.82791 18.3826 3.00921 20.2448 4.52988 21.81Z"
      fill="currentColor"
    />
  </Icon>
);

export default ViewOffFilled;
