import { Address } from "viem";
import { Flex, FlexProps, HStack, Stack } from "@chakra-ui/react";
import { Trans, useTranslation } from "next-i18next";
import { dialogRadius } from "@looksrare/chakra-theme";
import { formatToSignificant } from "@looksrare/utils";
import { Button, HelpIcon, LogoRoundIcon, Popover, Text, TooltipText } from "@looksrare/uikit";
import { type BigIntish } from "@looksrare/utils/types";
import { type PtbSupportedNetwork } from "../../types";
import { useAssertPtbNetwork } from "../../hooks/useAssertPtbNetwork";
import { CurrencyTokenIcon } from "../CurrencyTokenIcon";

interface ClaimBoxProps extends FlexProps {
  network: PtbSupportedNetwork;
  address?: Address;
  ethAmount?: BigIntish;
  isLoading?: boolean;
  looksAmount?: BigIntish;
  onClickClaim: () => void;
}

export const ClaimBox = ({
  ethAmount = 0n,
  looksAmount = 0n,
  network,
  onClickClaim,
  address,
  isLoading = false,
  ...props
}: ClaimBoxProps) => {
  const { t } = useTranslation();
  const handleClaim = useAssertPtbNetwork(onClickClaim);
  const isNoPrizes = looksAmount === 0n && ethAmount === 0n;

  const prizesElement = (() => {
    if (!address) {
      return (
        <Text bold color="text-disabled">
          —
        </Text>
      );
    }

    return (
      <HStack
        spacing={1}
        divider={
          <Text bold color="text-03" px={1}>
            /
          </Text>
        }
      >
        <Flex alignItems="center" gap={1}>
          <CurrencyTokenIcon network={network} />
          <Text bold color="text-01">
            {formatToSignificant(ethAmount, 6)}
          </Text>
        </Flex>
        {!!looksAmount && (
          <Flex alignItems="center" gap={1}>
            <LogoRoundIcon boxSize={4} />
            <Text bold color="text-01">
              {formatToSignificant(looksAmount)}
            </Text>
          </Flex>
        )}
      </HStack>
    );
  })();

  return (
    <Flex bg="interactive-01" p="1px" borderRadius="dialog" height="fit-content" {...props}>
      <Flex
        borderRadius={`${dialogRadius - 1}px`}
        p={4}
        gap={3}
        bgGradient="linear(to-b, ui-gradient-02-a, ui-gradient-02-b)"
        width="100%"
      >
        <Stack spacing={2} flexGrow={1}>
          <Flex alignItems="center" gap={1}>
            <Text color="text-03" textStyle="detail" bold>
              {t("ptb::Available to Claim")}
            </Text>
            <Popover
              variant="tooltip"
              label={
                <TooltipText>
                  <Trans i18nKey="ptbClaimBalanceText">
                    A combination of your <strong>Unclaimed Winnings</strong>, <strong>Refunds</strong> from canceled
                    rounds, and <strong>Rollover Balances</strong>.
                  </Trans>
                </TooltipText>
              }
            >
              <Flex>
                <HelpIcon boxSize={5} color="text-03" />
              </Flex>
            </Popover>
          </Flex>
          {prizesElement}
        </Stack>
        <Flex alignItems="center" justifyContent="flex-end" height="100%" flexBasis="none">
          <Button onClick={() => handleClaim(network)} isDisabled={isNoPrizes || isLoading}>
            {isNoPrizes ? t("ptb::Nothing to Claim") : t("Claim Now")}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
