import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const Hot = (props?: IconProps) => {
  const [uid] = useState(uniqueId());

  return (
    <Icon viewBox="0 0 24 24" color="#0CE466" {...props}>
      <g>
        <path
          d="M7.55861 18.2324C7.55861 19.2893 7.91209 20.2158 8.24329 20.852C8.4066 21.1657 8.13337 21.5383 7.81399 21.3865C6.54106 20.7813 4.5 19.2652 4.5 15.7649C4.5 9.38179 11.9729 8.40844 11.9729 3.58988C11.9729 3.19756 11.9435 2.85554 11.902 2.56813C11.8507 2.21374 12.2595 1.90134 12.5407 2.12303C13.8732 3.17351 16.2011 5.60193 16.2011 9.74689C16.2011 10.8792 16.1148 11.7389 16.0178 12.348C15.9601 12.7096 16.4194 13.0258 16.673 12.7616C17.0872 12.3299 17.3871 11.7899 17.5304 10.9737C17.5944 10.6089 18.0321 10.5039 18.1712 10.8472C18.5762 11.8463 19.0584 13.5068 19.0584 15.7504C19.0584 19.748 16.6711 21.1088 15.4018 21.5499C15.1015 21.6542 14.863 21.3257 14.9788 21.0296C15.1519 20.5865 15.3244 19.9726 15.3244 19.285C15.3244 15.6043 12.1204 16.0536 11.7454 12.7849C11.7123 12.497 11.3956 12.3163 11.1534 12.4753C8.4585 14.2454 7.55861 16.3668 7.55861 18.2324Z"
          fill={`url(#${uid})`}
        />
      </g>
      <defs>
        <linearGradient id={uid} x1="11.7792" y1="2.04883" x2="11.7792" y2="21.5697" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FA4D56" />
          <stop offset="1" stopColor="#FF8A14" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export default Hot;
