import { Address, zeroAddress } from "viem";
import capitalize from "lodash/capitalize";
import { useTranslation } from "next-i18next";
import { type FlexProps, Flex, HStack, Box, Skeleton } from "@chakra-ui/react";
import { formatToSignificant, formatUsd, fromDecimals } from "@looksrare/utils";
import { Button, ChainIcon, Text, useGameAddresses } from "@looksrare/uikit";
import times from "lodash/times";
import type { PtbSupportedNetwork } from "../../types";
import { useAssertPtbNetwork } from "../../hooks/useAssertPtbNetwork";
import { CurrencyValueDisplay } from "../CurrencyValueDisplay";
import { PtbAccordionButton, PtbAccordionItem, PtbAccordionPanel } from "./shared";

interface NetworkClaimAccordionProps {
  network: PtbSupportedNetwork;
  ethAmount: bigint;
  ethPriceUsd: number;
  looksAmount: bigint;
  looksPriceUsd: number;
  totalAmountInEth: bigint;
  onOpen: () => void;
  isLoading?: boolean;
}

interface BalanceRowProps extends FlexProps {
  total: bigint;
  currency: Address;
  usdPrice: number;
}

const BalanceRow = ({ total, currency, usdPrice, ...props }: BalanceRowProps) => {
  return (
    <Flex alignItems="center" justifyContent="space-between" pl={6} pr={2} py={2} mb={1} {...props}>
      <CurrencyValueDisplay
        total={formatToSignificant(total, 6)}
        currency={currency}
        textProps={{ textStyle: "detail" }}
        iconProps={{ boxSize: 5, mr: 2 }}
      />
      <Text color="text-03" textStyle="helper">
        {formatUsd(usdPrice)}
      </Text>
    </Flex>
  );
};

export const NetworkClaimAccordionItem = ({
  network,
  ethAmount,
  ethPriceUsd,
  looksAmount,
  looksPriceUsd,
  totalAmountInEth,
  onOpen,
  isLoading = false,
}: NetworkClaimAccordionProps) => {
  const { t } = useTranslation();
  const ptbAddresses = useGameAddresses();

  const handleAssertion = useAssertPtbNetwork(onOpen);
  const handleClick = () => handleAssertion(network);

  const ethValueUsd = parseFloat(fromDecimals(ethAmount)) * ethPriceUsd;
  const looksValueUsd = parseFloat(fromDecimals(looksAmount)) * looksPriceUsd;

  if (isLoading) {
    return (
      <>
        {times(3).map((n) => (
          <HStack key={n} spacing={4} height={12}>
            <Box p={2}>
              <Skeleton height={6} width={6} speed={0} startColor="text-03" />
            </Box>
            <Skeleton height={5} width="100%" flex={1} speed={0} startColor="text-03" />
          </HStack>
        ))}
      </>
    );
  }

  if (totalAmountInEth === 0n) {
    return null;
  }

  return (
    <PtbAccordionItem p={0}>
      <PtbAccordionButton icon={<ChainIcon network={network} />}>
        <Text noOfLines={1}>{capitalize(network)}</Text>
        <CurrencyValueDisplay
          total={formatToSignificant(totalAmountInEth, 6)}
          currency={zeroAddress}
          textProps={{ textStyle: "detail" }}
        />
      </PtbAccordionButton>
      <PtbAccordionPanel pl={0}>
        {ethAmount > 0n && <BalanceRow total={ethAmount} currency={zeroAddress} usdPrice={ethValueUsd} />}
        {looksAmount > 0n && <BalanceRow total={looksAmount} currency={ptbAddresses.LOOKS} usdPrice={looksValueUsd} />}
        <Button width="100%" size="sm" my={2} onClick={handleClick}>
          {t("ptb::Claim All on {{network}}", { network: capitalize(network) })}
        </Button>
      </PtbAccordionPanel>
    </PtbAccordionItem>
  );
};
