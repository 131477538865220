import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const EthArbToken = (props?: IconProps) => {
  const [uid1] = useState(uniqueId());
  const [uid2] = useState(uniqueId());
  const [uid3] = useState(uniqueId());
  const [uid4] = useState(uniqueId());

  return (
    <Icon viewBox="0 0 96 96" color="#0CE466" {...props}>
      <g clipPath={`url(#${uid1})`}>
        <mask id="mask0_2593_491" mask="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="80" height="80">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M79.8025 44C79.9331 42.6844 80 41.35 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80C41.35 80 42.6844 79.9331 44 79.8025V58C44 50.268 50.268 44 58 44H79.8025Z"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_2593_491)">
          <g clipPath={`url(#${uid2})`}>
            <circle cx="40" cy="40" r="40" fill="#DCDCDF" />
            <g clipPath={`url(#${uid3})`}>
              <path
                d="M39.9951 11.4997L39.6127 12.7987V50.4885L39.9951 50.87L57.4899 40.5287L39.9951 11.4997Z"
                fill="#767676"
              />
              <path d="M39.9953 11.4997L22.5 40.5287L39.9953 50.87V32.5764V11.4997Z" fill="#8E8E8E" />
              <path
                d="M39.9936 54.184L39.7781 54.4468V67.8725L39.9936 68.5017L57.4992 43.848L39.9936 54.184Z"
                fill="#5F5F5F"
              />
              <path d="M39.9953 68.5017V54.184L22.5 43.848L39.9953 68.5017Z" fill="#8E8E8E" />
              <path d="M39.9954 50.8694L57.4902 40.5281L39.9954 32.5758V50.8694Z" fill="#5F5F5F" />
              <path d="M22.5 40.5281L39.9953 50.8694V32.5758L22.5 40.5281Z" fill="#767676" />
            </g>
          </g>
        </g>
        <g clipPath={`url(#${uid4})`}>
          <path
            opacity="0.2"
            d="M48 60C48 53.3726 53.3726 48 60 48H84C90.6274 48 96 53.3726 96 60V84C96 90.6274 90.6274 96 84 96H60C53.3726 96 48 90.6274 48 84V60Z"
            fill="#17AAFD"
          />
          <path
            d="M74.7063 74.7784L72.936 79.6329C72.8882 79.7679 72.8882 79.9151 72.936 80.0501L75.9814 88.403L79.5037 86.3687L75.276 74.7784C75.18 74.5115 74.8021 74.5115 74.706 74.7784H74.7063Z"
            fill="#17AAFD"
          />
          <path
            d="M78.2559 66.6144C78.1598 66.3475 77.7819 66.3475 77.6858 66.6144L75.9155 71.4689C75.8677 71.6038 75.8677 71.7511 75.9155 71.886L80.905 85.5617L84.4273 83.5275L78.2555 66.6144H78.2559Z"
            fill="#17AAFD"
          />
          <path
            d="M71.997 55.984C72.0841 55.984 72.1712 56.0079 72.249 56.0501L85.6817 63.806C85.8376 63.8961 85.9337 64.0639 85.9337 64.2411V79.7499C85.9337 79.9301 85.8376 80.095 85.6817 80.185L72.249 87.9409C72.1738 87.9858 72.0841 88.0071 71.997 88.0071C71.9099 88.0071 71.8228 87.9832 71.7451 87.9409L58.3123 80.191C58.1564 80.1009 58.0604 79.9331 58.0604 79.7559V64.2441C58.0604 64.0639 58.1564 63.8991 58.3123 63.809L71.7451 56.0531C71.8232 56.0082 71.9099 55.984 71.997 55.984ZM71.997 53.7188C71.52 53.7188 71.0397 53.8417 70.611 54.0907L57.1812 61.8436C56.323 62.3385 55.7952 63.2539 55.7952 64.2438V79.7526C55.7952 80.7428 56.3233 81.6578 57.1812 82.1528L70.614 89.9087C71.0431 90.1546 71.52 90.2806 72 90.2806C72.48 90.2806 72.9573 90.1576 73.3861 89.9087L86.8188 82.1528C87.677 81.6578 88.2049 80.7424 88.2049 79.7526V64.2438C88.2049 63.2536 87.6767 62.3385 86.8188 61.8436L73.3831 54.0907C72.954 53.8417 72.474 53.7188 71.997 53.7188Z"
            fill="#17AAFD"
          />
          <path d="M63.1129 85.5797L64.3491 82.1953L66.8363 84.2624L64.5109 86.3867L63.1129 85.5797Z" fill="#17AAFD" />
          <path
            d="M70.8658 63.1339H67.4605C67.2056 63.1339 66.9775 63.2928 66.8905 63.5331L59.5906 83.5455L63.1129 85.5797L71.151 63.5421C71.2261 63.344 71.0789 63.1339 70.8658 63.1339Z"
            fill="#17AAFD"
          />
          <path
            d="M76.8247 63.1339H73.4194C73.1644 63.1339 72.9364 63.2928 72.8493 63.5331L64.5144 86.384L68.0367 88.4183L77.1099 63.5421C77.182 63.344 77.0347 63.1339 76.8247 63.1339Z"
            fill="#17AAFD"
          />
        </g>
      </g>
      <defs>
        <clipPath id={uid1}>
          <rect width="96" height="96" fill="white" />
        </clipPath>
        <clipPath id={uid2}>
          <rect width="80" height="80" fill="white" />
        </clipPath>
        <clipPath id={uid3}>
          <rect width="35" height="70" fill="white" transform="translate(22.5 5)" />
        </clipPath>
        <clipPath id={uid4}>
          <rect width="48" height="48" fill="white" transform="translate(48 48)" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default EthArbToken;
