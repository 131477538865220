import { Icon, IconProps } from "../Icon";

const User = (props: IconProps) => (
  <Icon {...props}>
    <path
      d="M15.5174 16.4828C19.2444 16.4828 22.2759 12.5159 22.2759 8.77852C22.2759 5.04118 19.2444 2 15.5174 2C11.7905 2 8.75867 5.04118 8.75867 8.77852C8.75929 12.5155 11.7908 16.4828 15.5174 16.4828Z"
      fill="currentColor"
    />
    <path
      d="M21.627 17.4483C20.1731 18.3888 18.3593 18.9484 16.3945 18.9484H15.6055C13.6404 18.9484 11.8262 18.3885 10.3726 17.4483C5.62522 18.0999 2 21.5935 2 25.8077C2 28.1227 8.26789 30 16 30C23.7318 30 30 28.1227 30 25.8077C30 21.5935 26.3744 18.0999 21.627 17.4483Z"
      fill="currentColor"
    />
  </Icon>
);

export default User;
