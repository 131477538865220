import { useQuery } from "@tanstack/react-query";
import { Address, zeroAddress } from "viem";
import { usePublicClient } from "wagmi";
import { NoPublicClientError, RQueryOptions, isAddressEqual } from "@looksrare/utils";

export const useUserEns = (address: Address, options?: RQueryOptions<string | null>) => {
  const publicClient = usePublicClient();

  return useQuery({
    queryKey: ["user-ens-data", address],
    queryFn: async () => {
      if (isAddressEqual(address, zeroAddress)) {
        return null;
      }
      if (!publicClient) {
        throw new NoPublicClientError();
      }
      const ensDomain = await publicClient.getEnsName({ address });
      return ensDomain;
    },
    staleTime: Infinity,
    gcTime: Infinity,
    refetchOnWindowFocus: false,
    ...options,
  });
};
