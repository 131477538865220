import { Icon, IconProps } from "../Icon";

const GridRelaxed = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 5C3 3.89543 3.89543 3 5 3H27C28.1046 3 29 3.89543 29 5V27C29 28.1046 28.1046 29 27 29H5C3.89543 29 3 28.1046 3 27V5ZM27 5L17 5V15H27V5ZM27 17H17V27H27V17ZM15 15V5L5 5V15H15ZM5 17H15V27H5V17Z"
    />
  </Icon>
);

export default GridRelaxed;
