import { useEffect, useMemo, useRef, useState } from "react";
import { WS_ENDPOINT } from "@looksrare/config";
import type { WSEventHandler, NonEmptyArray, WSResponse, WSTopicName, WebsocketRequest } from "./types";
import { WSMessageType } from "./types";

const isNonEmptyArray = <T>(items: T[]): items is NonEmptyArray<T> => items.length > 0;

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

class WSClient<T> {
  constructor(
    public ws: WebSocket,
    private readonly options: {
      maxConnAttempts: number;
      maxRetryInterval: number;
    }
  ) {
    this.bindAll();
  }

  /**
   * This map holds the request id for each topic subscription attempt.
   * Once a success or error event is raised, the topic/request-id is removed from the map.
   */
  private pendingSubscriptions: Map<number, WSTopicName> = new Map();

  /**
   * A list of active topics that this websocket is subscribed to.
   */
  private activeSubscriptions: Map<WSTopicName, NonEmptyArray<WSEventHandler<T>>> = new Map();

  /**
   * Holds internal state for the number of connection attempts.
   * Use for exponential backoff when dealing with connectivity issues.
   */
  private connectionAttempts = 0;

  /**
   * An incremental counter used purely for generating unique request-ids.
   */
  private requestId = 0;

  bindAll(): void {
    this.ws.onclose = this.onClose;
    this.ws.onopen = this.onOpen;
    this.ws.onerror = this.onError;
    this.ws.onmessage = this.onMessage;
  }

  private async reconnect() {
    const attempt = this.connectionAttempts++;
    if (attempt < this.options.maxConnAttempts) {
      // Exponential back-off with a max retry interval
      const delay = Math.min(Math.pow(2, attempt) * 1000, this.options.maxRetryInterval);
      await sleep(delay);

      // Attempt re-connect
      this.ws = new WebSocket(this.ws.url, this.ws.protocol !== "" ? this.ws.protocol : undefined);
      this.bindAll();
    } else {
      const allHandlers = Array.from(this.activeSubscriptions.values()).flat();

      for (const handler of allHandlers) {
        handler({ err: { code: "ws_disconnected", message: "Max connection attempts reached" } });
      }
    }
  }

  private onOpen = () => {
    this.connectionAttempts = 0;
    const topics = Array.from(this.activeSubscriptions.keys());

    for (const topic of topics) {
      this.subUnsub("subscribe", topic);
    }
  };

  private onClose = (event: CloseEvent) => {
    if (!event.wasClean) {
      return this.reconnect();
    }
  };

  private onMessage = (event: MessageEvent<string>) => {
    const response = JSON.parse(event.data) as WSResponse<T>;

    // Handle "Message" events
    if (response.type === WSMessageType.Message) {
      const topic = response.topic;

      // Heartbeat workflow.
      if (topic === "heartbeat") {
        this.send({ method: "heartbeat", data: response.data });
      } else {
        const handlers = this.activeSubscriptions.get(response.topic);

        for (const handler of handlers || []) {
          handler({ data: response.data });
        }
      }
    } else if (response.type === WSMessageType.Request) {
      // Validate "Response" events
      const requestIdTopic = this.pendingSubscriptions.get(response.requestId);

      if (requestIdTopic) {
        if (response.success) {
          this.pendingSubscriptions.delete(response.requestId);
        } else {
          // If subscription failed, send "error" event and remove subscription
          const handlers = this.activeSubscriptions.get(requestIdTopic);

          if (handlers) {
            for (const handler of handlers) {
              handler({ err: response.error });
            }
          }

          this.activeSubscriptions.delete(requestIdTopic);
        }

        this.pendingSubscriptions.delete(response.requestId);
      }
    }
  };

  private onError = (event: Event) => {
    const allHandlers = Array.from(this.activeSubscriptions.values()).flat();

    for (const handler of allHandlers) {
      handler({ err: { code: "ws_error_terminated", message: "Websocket error" } });
    }

    console.error("error", event);
  };

  /**
   * A "strongly-typed" internal "send" method.
   */
  private send(data: WebsocketRequest) {
    if (this.ws.readyState === this.ws.OPEN) {
      // If this is a sub request, store the request-id for tracking.
      if (data.method === "subscribe") {
        this.pendingSubscriptions.set(data.requestId, data.params[0]);
      }

      return this.ws.send(JSON.stringify(data));
    }
  }

  private subUnsub(method: "subscribe" | "unsubscribe", topic: WSTopicName) {
    const data: WebsocketRequest = {
      requestId: this.requestId++,
      method,
      params: [topic],
    };

    this.send(data);
  }

  /**
   * Create an idempotent subscription to a topic.
   * Multiple invocations of this method to the same topic will result in a single upstream websocket subscription.
   * Therefore, the frontend can subscribe to the same topic multiple times without worrying about multiple upstream connections.
   * If disconnected, the client will automatically reconnect and resubscribe to all previously connected topics as required.
   *
   * Call the `unsubscribe` function to cancel the subscription when done to free-up resources. ( i.e when leaving a page )
   */
  subscribe(topicName: WSTopicName, newHandler: WSEventHandler<T>): { unsubscribe: () => void } {
    const existingHandlers = this.activeSubscriptions.get(topicName);

    if (existingHandlers) {
      existingHandlers.push(newHandler);
    } else {
      this.activeSubscriptions.set(topicName, [newHandler]);
      this.subUnsub("subscribe", topicName);
    }

    return {
      unsubscribe: (): void => {
        const handlers = this.activeSubscriptions.get(topicName);

        if (!handlers) {
          console.error("InconsistentState: No subscriptions for this topic");
          return;
        }

        const filteredHandlers = handlers.filter((handler) => handler !== newHandler);

        if (isNonEmptyArray(filteredHandlers)) {
          this.activeSubscriptions.set(topicName, filteredHandlers);
        } else {
          this.activeSubscriptions.delete(topicName);
          this.subUnsub("unsubscribe", topicName);
        }
      },
    };
  }

  close(): void {
    this.ws.close();
  }
}

export interface RealtimeSubscriptionOptions<T> {
  onNewData?: (data: T) => void;
  enabled?: boolean;
}

// Singleton to handle multiple connections
let RealtimeClient: WSClient<unknown>;
const getRealtimeClient = (url: string) => {
  if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_WS_ENABLED !== "0") {
    if (!RealtimeClient) {
      RealtimeClient = new WSClient(new WebSocket(url), {
        maxConnAttempts: 10,
        maxRetryInterval: 15_000,
      });
    }
    return RealtimeClient;
  }
  return null;
};

/**
 * Subscribe to a realtime topic.
 * @param topic - A topic configuration object. If no property in `topic` is undefined, on a successful subscription we receive the latest order
 * @param options - Options for the subscription
 * @param [options.onNewData] - Callback to be invoked when new data is received
 * @param [options.enabled=true] - Whether to enable the subscription
 */
export const useRealtimeSubscription = <T>(
  topicName: WSTopicName,
  options: RealtimeSubscriptionOptions<T> = {},
  miscInvalidation: Array<unknown> = []
): T | null => {
  const [latestUpdate, setLatestUpdate] = useState<T | null>(null);
  const enabled = useMemo(() => options.enabled === undefined || options.enabled, [options.enabled]);
  const callbackRef = useRef(options.onNewData);
  callbackRef.current = options.onNewData;

  useEffect(() => {
    const RealtimeClientInstance = getRealtimeClient(WS_ENDPOINT);
    if (enabled && !!RealtimeClientInstance) {
      const { unsubscribe } = RealtimeClientInstance.subscribe(topicName, ({ data }) => {
        if (!!data) {
          callbackRef.current?.(data as T);
          setLatestUpdate(data as T);
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [
    enabled,
    topicName,
    // @todo-ptbl2 We can expose a resubscribe function instead and call that at the
    // appropriate time rather than losing the effect key static analysis.

    // We want to re-subscribe when certain underlying keys change.
    // Any key changes that should cleanup and initialize this side-effect are defined here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...miscInvalidation,
  ]);

  return latestUpdate;
};
