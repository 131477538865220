import request, { gql } from "graphql-request";
import { Address } from "viem";
import { GRAPHQL_API_ENDPOINT } from "@looksrare/config";
import { LoginPayload } from "../types";

/**
 * Direct call to the API for login
 * @param address User address
 * @param message Original message
 * @param signature Signed message
 * @returns
 */
export const login = async (address: Address, message: string, signature: string): Promise<LoginPayload["token"]> => {
  const query = gql`
    mutation LoginMutation($data: UserInput!) {
      login(data: $data) {
        address
        token
      }
    }
  `;
  const res: { login: LoginPayload } = await request(GRAPHQL_API_ENDPOINT, query, {
    data: {
      address,
      message,
      signature,
    },
  });

  return res.login.token;
};
