import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import { Address } from "viem";
import { useHandleModalStep } from "@looksrare/utils";
import { TransferManagerAbi } from "@looksrare/sdk-v2";
import { AutoTransactionStepRow, TransactionSetter } from "../AutoTransactionStepRow";

interface Props {
  onComplete: () => void;
  isStepActive: boolean;
  ctaText: string;
  operator: Address;
  transferManager: Address;
}

export const ApproveTransferManagerStep = ({ onComplete, isStepActive, ctaText, transferManager, operator }: Props) => {
  const { address } = useAccount();
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();

  const useHandleTransaction = (setTransaction: TransactionSetter) =>
    useHandleModalStep({
      onSubmit: async () => {
        if (walletClient && publicClient) {
          const hasApproved = await publicClient.readContract({
            address: transferManager,
            abi: TransferManagerAbi,
            functionName: "hasUserApprovedOperator",
            args: [address, operator],
          });

          if (!hasApproved) {
            const { request } = await publicClient.simulateContract({
              address: transferManager,
              abi: TransferManagerAbi,
              functionName: "grantApprovals",
              args: [[operator]],
              account: address,
            });

            const hash = await walletClient.writeContract(request);
            setTransaction(hash);

            const receipt = await publicClient.waitForTransactionReceipt({ hash });
            if (receipt.status === "success") {
              setTransaction(undefined);
            } else {
              throw new Error(
                `Granting transfer manager (${transferManager}) approval for ${operator} failed. Transaction hash: ${receipt.transactionHash}`
              );
            }
          }
          onComplete();
        }
      },
    });

  return (
    <AutoTransactionStepRow isStepActive={isStepActive} useHandleTransaction={useHandleTransaction} ctaText={ctaText} />
  );
};
