import { Icon, IconProps } from "../Icon";

const Gift = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M26 9.99999H23.2383C23.8892 9.03797 24.1416 7.86102 23.9426 6.71669C23.7435 5.57235 23.1083 4.54982 22.1708 3.86416C21.2332 3.17851 20.0663 2.88317 18.9154 3.04026C17.7646 3.19736 16.7195 3.79464 16 4.70649C15.2789 3.79915 14.2347 3.206 13.0861 3.05125C11.9375 2.89651 10.7736 3.19217 9.83811 3.87632C8.90261 4.56047 8.26809 5.58004 8.06745 6.72151C7.8668 7.86299 8.11558 9.03783 8.7617 9.99999H6C5.46977 10.0007 4.96145 10.2116 4.58652 10.5865C4.21159 10.9614 4.00066 11.4698 4 12V16C4.00066 16.5302 4.21159 17.0385 4.58652 17.4135C4.96145 17.7884 5.46977 17.9993 6 18V28C6.00066 28.5302 6.21159 29.0385 6.58652 29.4135C6.96145 29.7884 7.46977 29.9993 8 30H24C24.5302 29.9993 25.0386 29.7884 25.4135 29.4135C25.7884 29.0385 25.9993 28.5302 26 28V18C26.5302 17.9993 27.0386 17.7884 27.4135 17.4135C27.7884 17.0385 27.9993 16.5302 28 16V12C27.9993 11.4698 27.7884 10.9614 27.4135 10.5865C27.0386 10.2116 26.5302 10.0007 26 9.99999ZM17 7.49999C17 7.00554 17.1466 6.52219 17.4213 6.11107C17.696 5.69994 18.0865 5.37951 18.5433 5.19029C19.0001 5.00107 19.5028 4.95157 19.9877 5.04803C20.4727 5.14449 20.9181 5.38259 21.2678 5.73223C21.6174 6.08186 21.8555 6.52731 21.952 7.01227C22.0484 7.49722 21.9989 7.99989 21.8097 8.4567C21.6205 8.91352 21.3 9.30396 20.8889 9.57867C20.4778 9.85337 19.9945 9.99999 19.5 9.99999H17V7.49999ZM12.5 4.99999C13.1628 5.00079 13.7982 5.26443 14.2669 5.7331C14.7356 6.20177 14.9992 6.83719 15 7.49999V9.99999H12.5C11.837 9.99999 11.2011 9.7366 10.7322 9.26776C10.2634 8.79892 10 8.16303 10 7.49999C10 6.83695 10.2634 6.20107 10.7322 5.73223C11.2011 5.26338 11.837 4.99999 12.5 4.99999ZM6 12H15V16H6V12ZM8 18H15V28H8V18ZM24.0012 28H17V18H24L24.0012 28ZM17 16V12H26L26.0012 16H17Z"
      fill="currentColor"
    />
  </Icon>
);

export default Gift;
