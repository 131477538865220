import { useState } from "react";
import { useSafeLayoutEffect } from "@chakra-ui/react";
import { useRouter } from "next/router";

export const useGetWindowHash = () => {
  const [hash, setHash] = useState("");
  const router = useRouter();

  useSafeLayoutEffect(() => {
    const handleHashChange = () => {
      setHash(window.location.hash);
    };

    const handleNextjsHashChange = (url: string) => {
      const newHash = url?.split("#")[1]?.split("?")[0];
      setHash(newHash ? `#${newHash}` : "");
    };

    handleHashChange();
    // handles anchor tags changing hrefs
    window.addEventListener("hashchange", handleHashChange);
    // handles hash and url params both changing
    router.events.on("beforeHistoryChange", handleNextjsHashChange);
    // handles just hash changing
    router.events.on("hashChangeComplete", handleNextjsHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
      router.events.off("beforeHistoryChange", handleNextjsHashChange);
      router.events.off("hashChangeComplete", handleNextjsHashChange);
    };
  }, [setHash]);

  return hash;
};
