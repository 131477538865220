import { ThemeConfig, extendTheme } from "@chakra-ui/react";
import { semanticTokens as moonOrDoomSemanticTokens } from "@looksrare/moon-or-doom/src/theme/colors";
import { layerStyles as moonOrDoomLayerStyles } from "@looksrare/moon-or-doom/src/theme/layerStyles";
import {
  AlertTheme,
  BadgeTheme,
  ButtonTheme,
  CheckboxTheme,
  DividerTheme,
  DrawerTheme,
  InputTheme,
  KbdTheme,
  MenuTheme,
  ModalTheme,
  PopoverTheme,
  ProgressTheme,
  RadioTheme,
  SkeletonTheme,
  SpinnerTheme,
  SwitchTheme,
  TableTheme,
  TabsTheme,
  TagTheme,
  TextTheme,
  TextareaTheme,
  AccordionTheme,
} from "./components";
import { colors, semanticTokens } from "./colors";
import { global } from "./global";
import { layerStyles } from "./layerStyles";
import { radii } from "./radii";
import { shadows } from "./shadows";
import { sizes } from "./sizes";
import { textStyles } from "./textStyles";
import { typography } from "./typography";
import { breakpoints } from "./breakpoints";

const config: ThemeConfig = {
  initialColorMode: "dark",
  cssVarPrefix: "yg",
};

export const theme = extendTheme({
  colors,
  config,
  global,
  breakpoints,
  layerStyles: { ...layerStyles, ...moonOrDoomLayerStyles },
  radii,
  semanticTokens: {
    colors: { ...semanticTokens.colors, ...moonOrDoomSemanticTokens.colors },
  },
  sizes,
  shadows,
  textStyles,
  typography,
  components: {
    Accordion: AccordionTheme,
    Alert: AlertTheme,
    Badge: BadgeTheme,
    Button: ButtonTheme,
    Divider: DividerTheme,
    Drawer: DrawerTheme,
    Checkbox: CheckboxTheme,
    Input: InputTheme,
    Kbd: KbdTheme,
    Menu: MenuTheme,
    Modal: ModalTheme,
    Popover: PopoverTheme,
    Progress: ProgressTheme,
    Radio: RadioTheme,
    Skeleton: SkeletonTheme,
    Spinner: SpinnerTheme,
    Switch: SwitchTheme,
    Table: TableTheme,
    Tabs: TabsTheme,
    Tag: TagTheme,
    Text: TextTheme,
    Textarea: TextareaTheme,
  },
});
