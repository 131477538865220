import { Icon, IconProps } from "../Icon";

const EmptyState = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.75 6L30 19L28 27H4L2 19L7.25 6H24.75ZM8.59924 8L4.15693 19H11.1374L12.25 22H19.75L20.8626 19H27.8431L23.4008 8H8.59924Z"
      fill="currentColor"
    />
  </Icon>
);

export default EmptyState;
