import { Icon, IconProps } from "../Icon";

const UserAvatarFilled = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2C8.26801 2 2 8.26801 2 16C2 23.732 8.26801 30 16 30C23.732 30 30 23.732 30 16C30 12.287 28.525 8.72601 25.8995 6.1005C23.274 3.475 19.713 2 16 2ZM16 7C18.4853 7 20.5 9.01472 20.5 11.5C20.5 13.9853 18.4853 16 16 16C13.5147 16 11.5 13.9853 11.5 11.5C11.4973 10.3057 11.9706 9.15957 12.8151 8.31508C13.6596 7.47059 14.8057 6.99734 16 7ZM23.9998 24.92C19.457 29.0265 12.5427 29.0265 7.99984 24.92V24.34C7.92102 21.4904 10.1512 19.1086 12.9998 19H18.9998C21.8341 19.1138 24.0566 21.474 23.9998 24.31V24.92Z"
      fill="currentColor"
    />
  </Icon>
);

export default UserAvatarFilled;
