import { Icon, IconProps } from "../Icon";

const Sniper = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 6.04938V9H17V6.04938C21.7244 6.51844 25.4816 10.2756 25.9506 15H23V17H25.9506C25.4816 21.7244 21.7244 25.4816 17 25.9506V23H15V25.9506C10.2756 25.4816 6.51844 21.7244 6.04938 17H9V15H6.04938C6.51844 10.2756 10.2756 6.51844 15 6.04938ZM4.04107 15C4.52202 9.1702 9.1702 4.52202 15 4.04107V1H17V4.04107C22.8298 4.52202 27.478 9.1702 27.9589 15H31V17H27.9589C27.478 22.8298 22.8298 27.478 17 27.9589V31H15V27.9589C9.1702 27.478 4.52202 22.8298 4.04107 17H1V15H4.04107ZM15 15H17V17H15V15Z"
      fill="currentColor"
    />
  </Icon>
);

export default Sniper;
