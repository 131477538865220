import { BoxProps } from "@chakra-ui/react";
import { MoDAssetPair } from "@looksrare/yolo-games-gql-typegen";
import { Ufo } from "../../../components/Ufo/Ufo";
import { usePythPriceRealtime } from "../../../network/pyth/hooks";
import { getUfoState } from "../utils/getUfoState";

interface MoDUfoProps extends BoxProps {
  assetPair: MoDAssetPair;
  currentRoundLockPrice?: number;
}

export const MoDUfo = ({ assetPair, currentRoundLockPrice, ...props }: MoDUfoProps) => {
  const currentPrice = usePythPriceRealtime(assetPair);

  const ufoState = getUfoState({
    lockPrice: currentRoundLockPrice,
    currentPrice,
  });

  return <Ufo state={ufoState} {...props} />;
};
