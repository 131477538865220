import { IS_TESTNET, getExplorerUrl } from "@looksrare/config";
import { ChainId } from "@looksrare/config";

export const getExplorerLink = (
  data: string | number,
  type: "transaction" | "token" | "address" | "block" | "countdown" | "transaction-internal",
  chainId?: ChainId
): string => {
  const defaultChainId = IS_TESTNET ? ChainId.SEPOLIA : ChainId.MAINNET;
  const explorer = getExplorerUrl(chainId || defaultChainId);

  switch (type) {
    case "transaction": {
      return `${explorer}/tx/${data}`;
    }
    case "transaction-internal": {
      return `${explorer}/tx/${data}#internal`;
    }
    case "token": {
      return `${explorer}/token/${data}`;
    }
    case "block": {
      return `${explorer}/block/${data}`;
    }
    case "countdown": {
      return `${explorer}/block/countdown/${data}`;
    }
    default: {
      return `${explorer}/address/${data}`;
    }
  }
};
