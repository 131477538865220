import { type FC, type PropsWithChildren } from "react";
import { Box, BoxProps, useColorModeValue } from "@chakra-ui/react";
import { usePtbConfig } from "../config";

export const PtbBackground: FC<PropsWithChildren<BoxProps>> = (props) => {
  const colorMode = useColorModeValue("light", "dark");
  const { hideCaveBackground } = usePtbConfig();

  const dynamicProps = hideCaveBackground
    ? { bg: "ui-bg" }
    : { backgroundImage: `url('/images/poke-the-bear/pixel-cave-${colorMode}.png')`, backgroundSize: "cover" };

  return <Box minHeight="100%" height={0} {...dynamicProps} {...props} />;
};
