import { Icon, IconProps } from "../Icon";

// @todo-ptb Confirm this is in fact the arbitrum chain logo, and confirm color
const ArbitrumChainLogo = (props?: IconProps) => (
  <Icon viewBox="0 0 96 96" color="#0CE466" {...props}>
    <g clipPath="url(#clip0_2683_1945)">
      <path
        opacity="0.2"
        d="M0 24C0 10.7452 10.7452 0 24 0H72C85.2548 0 96 10.7452 96 24V72C96 85.2548 85.2548 96 72 96H24C10.7452 96 0 85.2548 0 72V24Z"
        fill="#17AAFD"
      />
      <path
        d="M53.4125 53.5569L49.872 63.2659C49.7762 63.5358 49.7762 63.8303 49.872 64.1002L55.9626 80.8059L63.0072 76.7375L54.552 53.5569C54.3599 53.023 53.604 53.023 53.4119 53.5569H53.4125Z"
        fill="#17AAFD"
      />
      <path
        d="M60.5117 37.2287C60.3196 36.6949 59.5638 36.6949 59.3717 37.2287L55.8311 46.9377C55.7353 47.2076 55.7353 47.5021 55.8311 47.772L65.81 75.1234L72.8546 71.055L60.5111 37.2287H60.5117Z"
        fill="#17AAFD"
      />
      <path
        d="M47.994 15.9679C48.1682 15.9679 48.3424 16.0158 48.4979 16.1002L75.3634 31.612C75.6752 31.7922 75.8673 32.1279 75.8673 32.4822V63.4999C75.8673 63.8602 75.6752 64.1899 75.3634 64.3701L48.4979 79.8819C48.3477 79.9716 48.1682 80.0142 47.994 80.0142C47.8199 80.0142 47.6457 79.9663 47.4901 79.8819L20.6247 64.382C20.3129 64.2019 20.1208 63.8662 20.1208 63.5118V32.4882C20.1208 32.1279 20.3129 31.7982 20.6247 31.618L47.4901 16.1062C47.6464 16.0165 47.8199 15.9679 47.994 15.9679ZM47.994 11.4375C47.0401 11.4375 46.0795 11.6835 45.2219 12.1814L18.3624 27.6872C16.646 28.6771 15.5903 30.5078 15.5903 32.4875V63.5052C15.5903 65.4855 16.6467 67.3157 18.3624 68.3055L45.2279 83.8173C46.0861 84.3093 47.0401 84.5612 48 84.5612C48.96 84.5612 49.9146 84.3153 50.7721 83.8173L77.6376 68.3055C79.354 67.3157 80.4097 65.4849 80.4097 63.5052V32.4875C80.4097 30.5072 79.3534 28.6771 77.6376 27.6872L50.7661 12.1814C49.9079 11.6835 48.948 11.4375 47.994 11.4375Z"
        fill="#17AAFD"
      />
      <path d="M30.2258 75.1593L32.6981 68.3906L37.6726 72.5248L33.0219 76.7734L30.2258 75.1593Z" fill="#17AAFD" />
      <path
        d="M45.7316 30.2679H38.921C38.4111 30.2679 37.9551 30.5856 37.7809 31.0663L23.1812 71.0909L30.2258 75.1593L46.302 31.0842C46.4522 30.688 46.1577 30.2679 45.7316 30.2679Z"
        fill="#17AAFD"
      />
      <path
        d="M57.6492 30.2679H50.8386C50.3287 30.2679 49.8727 30.5856 49.6985 31.0663L33.0287 76.7681L40.0733 80.8365L58.2196 31.0842C58.3639 30.688 58.0694 30.2679 57.6492 30.2679Z"
        fill="#17AAFD"
      />
    </g>
    <defs>
      <clipPath id="clip0_2683_1945">
        <rect width="96" height="96" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

export default ArbitrumChainLogo;
