import { useQuery } from "@tanstack/react-query";
import { type Address } from "viem";
import { gql } from "graphql-request";
import { type RQueryOptions, graphql } from "@looksrare/utils";
import { type PtbUser, ptbUserFragment } from "../graphql";

export const getPtbUserQuery = (playerAddress: Address) => ["ptb", "player", playerAddress];

export const usePtbUser = (playerAddress: Address, options?: RQueryOptions<PtbUser>) => {
  return useQuery({
    queryKey: getPtbUserQuery(playerAddress),
    queryFn: async () => {
      const query = gql`
        query PtbPlayer($address: Address!) {
          user(address: $address) {
            ...PtbUserFragment
          }
        }
        ${ptbUserFragment}
      `;
      const res = await graphql<{ user: PtbUser }>({
        query,
        params: { address: playerAddress },
      });
      return res.user;
    },
    gcTime: 60 * 1_000,
    staleTime: 60 * 1_000,
    refetchOnWindowFocus: false,
    ...options,
  });
};
