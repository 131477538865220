import { Icon, IconProps } from "../Icon";

const DownToBottom = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 19L6 8.99997L7.41 7.58997L16 16.17L24.59 7.58997L26 8.99997L16 19ZM4 23H28V25H4V23Z"
      fill="currentColor"
    />
  </Icon>
);

export default DownToBottom;
