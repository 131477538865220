import { EthHalfIcon, Text } from "@looksrare/uikit";
import { HStack, StyleProps } from "@chakra-ui/react";
import { formatToSignificant } from "@looksrare/utils";
import { MoDEntryFragment } from "@looksrare/yolo-games-gql-typegen";

const winTextStyles: StyleProps = {
  color: "transparent",
  bgGradient: "linear-gradient(180deg, #D6A800 22.92%, #F8CC32 50.52%, #DF6D04 78.65%)",
  backgroundClip: "text",
};

interface MoDHistoryYourResultProps {
  userEntry?: MoDEntryFragment;
}

export const MoDHistoryYourResult = ({ userEntry }: MoDHistoryYourResultProps) => {
  if (!userEntry) {
    return (
      <Text textStyle="detail" textAlign="end" color="text-02">
        -
      </Text>
    );
  }

  const resultAmount = userEntry.payoutAmount ?? userEntry.amount;
  const isWin = userEntry.payoutAmount !== null;

  return (
    <HStack spacing={1} justifyContent="end">
      <Text textStyle="detail" color="text-02" {...(isWin ? winTextStyles : {})}>
        {isWin ? `+` : `-`}
        {formatToSignificant(resultAmount)}
      </Text>

      <EthHalfIcon height={4} width={2} />
    </HStack>
  );
};
