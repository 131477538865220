import { FC, useCallback } from "react";
import { Address } from "viem";
import { type Currency, Addresses, ChainId } from "@looksrare/config";
import { IconProps } from "@chakra-ui/icon";
import {
  EthHalfIcon,
  TokenBoostIcon,
  LogoRoundIcon,
  UsdcIcon,
  UsdtIcon,
  WLooksTokenIcon,
  WethHalfIcon,
  UsdbIcon,
  YieldTokenIcon,
  YesTokenIcon,
  OrbitTokenIcon,
  PacTokenIcon,
  JuiceTokenIcon,
  TokenYoloIcon,
  BLooksTokenIcon,
  TokenBlastIcon,
} from "@looksrare/uikit";
import { isAddressEqual } from "../guards";
import { CurrencyConfig } from "../types";
import { useAddressesByNetwork } from "./useAddressesByNetwork";

export const WETH: CurrencyConfig = {
  symbol: "WETH",
  icon: WethHalfIcon,
  decimals: 18,
  name: "Wrapped Ether",
};

export const ETH: CurrencyConfig = {
  symbol: "ETH",
  icon: EthHalfIcon,
  decimals: 18,
  name: "Ethereum",
};

export const LOOKS: CurrencyConfig = {
  symbol: "LOOKS",
  icon: LogoRoundIcon,
  decimals: 18,
  name: "Looks",
};

export const WLOOKS: CurrencyConfig = {
  symbol: "WLOOKS",
  icon: WLooksTokenIcon,
  decimals: 18,
  name: "Wrapped Looks",
};

const USDC: CurrencyConfig = {
  symbol: "USDC",
  icon: UsdcIcon,
  decimals: 6,
  name: "USDC",
};

const USDT: CurrencyConfig = {
  symbol: "USDT",
  icon: UsdtIcon,
  decimals: 6,
  name: "USDT",
};

const BOOST: CurrencyConfig = {
  symbol: "BOOST",
  icon: TokenBoostIcon as FC<IconProps>,
  decimals: 18,
  name: "Boost",
};

const USDB: CurrencyConfig = {
  symbol: "USDB",
  icon: UsdbIcon,
  decimals: 18,
  name: "USDB",
};

const YIELD: CurrencyConfig = {
  symbol: "YIELD",
  icon: YieldTokenIcon,
  decimals: 18,
  name: "Yield",
};

const YES: CurrencyConfig = {
  symbol: "YES",
  icon: YesTokenIcon,
  decimals: 18,
  name: "Yes",
};

const ORBIT: CurrencyConfig = {
  symbol: "ORBIT",
  icon: OrbitTokenIcon,
  decimals: 18,
  name: "Orbit",
};

const PAC: CurrencyConfig = {
  symbol: "PAC",
  icon: PacTokenIcon,
  decimals: 18,
  name: "Pac",
};

const JUICE: CurrencyConfig = {
  symbol: "JUICE",
  icon: JuiceTokenIcon,
  decimals: 18,
  name: "Juice",
};

const YOLO: CurrencyConfig = {
  symbol: "YOLO",
  icon: TokenYoloIcon,
  decimals: 18,
  name: "Yolo",
};

const BLOOKS: CurrencyConfig = {
  symbol: "BLOOKS",
  icon: BLooksTokenIcon,
  decimals: 18,
  name: "Looks",
};

const BLAST: CurrencyConfig = {
  symbol: "BLAST",
  icon: TokenBlastIcon,
  decimals: 18,
  name: "Blast",
};

const getCurrencyMap = (addresses: Addresses): Record<Address, CurrencyConfig> => ({
  [addresses.WETH]: WETH,
  [addresses.ETH]: ETH,
  [addresses.LOOKS]: LOOKS,
  [addresses.WLOOKS]: WLOOKS,
  [addresses.USDC]: USDC,
  [addresses.USDT]: USDT,
  [addresses.BOOST]: BOOST,
  [addresses.USDB]: USDB,
  [addresses.YIELD]: YIELD,
  [addresses.YES]: YES,
  [addresses.ORBIT]: ORBIT,
  [addresses.PAC]: PAC,
  [addresses.JUICE]: JUICE,
  [addresses.YOLO]: YOLO,
  [addresses.BLOOKS]: BLOOKS,
  [addresses.BLAST]: BLAST,
});

export const useGetCurrencyConfig = (chainId?: ChainId) => {
  const addressesByNetwork = useAddressesByNetwork(chainId);

  return useCallback(
    (address: Address, useEthForWeth = false) => {
      const currenciesMapByAddress = getCurrencyMap(addressesByNetwork);

      if (useEthForWeth && isAddressEqual(address, addressesByNetwork.WETH)) {
        return currenciesMapByAddress[addressesByNetwork.ETH];
      }
      return currenciesMapByAddress[address] || currenciesMapByAddress[addressesByNetwork.ETH];
    },
    [addressesByNetwork]
  );
};

export const useGetCurrencyConfigBySymbol = (chainId?: ChainId) => {
  const addressesByNetwork = useAddressesByNetwork(chainId);
  return useCallback(
    (symbol: Currency) => {
      const currenciesMapByAddress = getCurrencyMap(addressesByNetwork);
      const currency = Object.values(currenciesMapByAddress).find((currencyConfig) => currencyConfig.symbol === symbol);

      if (!currency) {
        throw new Error("No currency found");
      }
      return currency;
    },
    [addressesByNetwork]
  );
};
