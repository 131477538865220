import { Icon, IconProps } from "../Icon";

const UsdtIcon = (props: IconProps) => (
  <Icon viewBox="0 0 96 96" {...props}>
    <rect width="96" height="96" rx="48" fill="#26A17B" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.0129 52.4514C53.677 52.4766 51.9419 52.5801 48.0713 52.5801C44.9928 52.5801 42.8071 52.4877 42.0403 52.4514C30.1432 51.928 21.2631 49.857 21.2631 47.3774C21.2631 44.8978 30.1432 42.8296 42.0403 42.2979V50.3888C42.8183 50.4447 45.046 50.5763 48.1245 50.5763C51.8187 50.5763 53.6686 50.4223 54.0017 50.3916V42.3035C65.8736 42.8324 74.7341 44.9034 74.7341 47.3774C74.7341 49.8514 65.8764 51.9224 54.0017 52.4486L54.0129 52.4514ZM54.0129 41.4667V34.2266H70.5809V23.1859H25.4722V34.2266H42.0375V41.4639C28.5731 42.0824 18.4476 44.7495 18.4476 47.9455C18.4476 51.1416 28.5731 53.8059 42.0375 54.4272V77.628H54.0101V54.4188C67.4436 53.8003 77.5523 51.136 77.5523 47.9427C77.5523 44.7495 67.452 42.0852 54.0101 41.4639L54.0129 41.4667Z"
      fill="white"
    />
  </Icon>
);

export default UsdtIcon;
