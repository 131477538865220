import { HStack } from "@chakra-ui/react";
import { useResizeObserver, useSlidingSelector } from "@looksrare/utils";
import { useRef } from "react";
import { EntryPosition } from "../../types";
import { PositionSwitcherButton } from "./components/PositionSwitcherButton";

interface PositionSwitcherProps {
  selected: EntryPosition;
  onChange: (selected: EntryPosition) => void;
}

const slantedSideWidth = 45;

export const PositionSwitcher = ({ selected, onChange }: PositionSwitcherProps) => {
  const isMoon = selected === "moon";

  const parentRef = useRef<HTMLDivElement | null>(null);
  const optionRefs = useRef(new Map<number, HTMLDivElement>());
  const selectedIdx = isMoon ? 0 : 1;

  const { offset: optionOffset, width: optionWidth, updatePosition } = useSlidingSelector(optionRefs, selectedIdx);
  useResizeObserver(updatePosition, parentRef.current);

  return (
    <HStack
      ref={parentRef}
      position="relative"
      overflow="hidden"
      border="1px solid"
      borderColor="ui-bg"
      borderRadius="container"
      backgroundColor="ui-bg"
      _after={{
        content: '""',
        position: "absolute",
        top: "50%",
        left: optionOffset.x - (isMoon ? slantedSideWidth : slantedSideWidth * 0.5),
        width: `${optionWidth + slantedSideWidth * 1.5}px`,
        height: "128px",
        transform: "translateY(-50%)",
        backgroundColor: isMoon ? "moondoom-moon-subtle" : "moondoom-doom-subtle",
        transition: "background-color 200ms, left 200ms, width 200ms",
        pointerEvents: "none",
        clipPath: `polygon(${slantedSideWidth}px 0, 100% 0, calc(100% - ${slantedSideWidth}px) 100%, 0 100%)`,
      }}
    >
      <PositionSwitcherButton
        ref={(node) => {
          if (node) {
            optionRefs.current.set(0, node);
          } else {
            optionRefs.current.delete(0);
          }
        }}
        type="moon"
        flex={1}
        onClick={() => onChange("moon")}
        isActive={isMoon}
      />
      <PositionSwitcherButton
        ref={(node) => {
          if (node) {
            optionRefs.current.set(1, node);
          } else {
            optionRefs.current.delete(1);
          }
        }}
        type="doom"
        flex={1}
        onClick={() => onChange("doom")}
        isActive={!isMoon}
      />
    </HStack>
  );
};
