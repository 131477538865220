import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const EthBlastToken = (props?: IconProps) => {
  const [uid1] = useState(uniqueId());
  const [uid2] = useState(uniqueId());
  const [uid3] = useState(uniqueId());
  const [uid4] = useState(uniqueId());
  const [uid5] = useState(uniqueId());

  return (
    <Icon viewBox="0 0 96 96" color="#0CE466" {...props}>
      <g clipPath={`url(#${uid2})`}>
        <mask id={uid1} style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="96" height="96">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M95.541 54.665C95.8436 52.4867 96 50.2616 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96C50.2616 96 52.4867 95.8436 54.665 95.541C52.9983 93.4804 52 90.8568 52 88V64C52 57.3726 57.3726 52 64 52H88C90.8568 52 93.4804 52.9983 95.541 54.665Z"
            fill="#D9D9D9"
          />
        </mask>
        <g mask={`url(#${uid1})`}>
          <g clipPath={`url(#${uid3})`}>
            <circle cx="48" cy="48" r="48" fill="#DCDCDF" />
            <g clipPath={`url(#${uid5})`}>
              <path
                d="M47.9941 13.7996L47.5352 15.3584V60.5862L47.9941 61.044L68.9879 48.6344L47.9941 13.7996Z"
                fill="#767676"
              />
              <path d="M47.9944 13.7996L27 48.6344L47.9944 61.0441V39.0917V13.7996Z" fill="#8E8E8E" />
              <path
                d="M47.9925 65.0208L47.7339 65.3362V81.447L47.9925 82.202L68.9992 52.6176L47.9925 65.0208Z"
                fill="#5F5F5F"
              />
              <path d="M47.9944 82.202V65.0208L27 52.6176L47.9944 82.202Z" fill="#8E8E8E" />
              <path d="M47.9946 61.0433L68.9885 48.6337L47.9946 39.091V61.0433Z" fill="#5F5F5F" />
              <path d="M27 48.6337L47.9944 61.0433V39.091L27 48.6337Z" fill="#767676" />
            </g>
          </g>
        </g>
        <g clipPath={`url(#${uid4})`}>
          <path
            opacity="0.3"
            d="M56 66C56 60.4772 60.4772 56 66 56H86C91.5228 56 96 60.4772 96 66V86C96 91.5228 91.5228 96 86 96H66C60.4772 96 56 91.5228 56 86V66Z"
            fill="#D3CA00"
          />
          <path
            d="M65.1989 65.5833L59.9368 69.75L87.0201 69.9176L85.7126 74.3621L74.6925 74.1944L73.7844 77.8055H84.5919L83.0977 83.1408H67.7816L70.7701 73.4282L67.4145 70.5833L61.8754 87.25H81.816L87.2069 84.8218L88.8879 78.8448L85.7126 76.1389L90.6784 73.6389L92.0632 68.0833L89.0167 65.5833H65.1989Z"
            fill="#D3CA00"
          />
        </g>
      </g>
      <defs>
        <clipPath id={uid2}>
          <rect width="96" height="96" fill="white" />
        </clipPath>
        <clipPath id={uid3}>
          <rect width="96" height="96" fill="white" />
        </clipPath>
        <clipPath id={uid5}>
          <rect width="42" height="84" fill="white" transform="translate(27 6)" />
        </clipPath>
        <clipPath id={uid4}>
          <rect width="40" height="40" fill="white" transform="translate(56 56)" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default EthBlastToken;
