import { FC, PropsWithChildren } from "react";
import { Box, FlexProps, Flex } from "@chakra-ui/react";

const gradients = {
  yellow: () => (
    <linearGradient id="gradient">
      <stop offset="0%" stopColor="rgba(248, 204, 50, 1)" />
      <stop offset="100%" stopColor="rgba(223, 109, 4, 1)" />
    </linearGradient>
  ),
};

type CircularCountdownBaseProps = {
  size: number;
  countdown: number;
  duration: number;
  strokeWidth: string;
  trackColor?: FlexProps["stroke"];
  trackWidth?: string;
};

type CircularCountdownOptionalProps =
  | {
      strokeColor?: FlexProps["stroke"];
      gradientProfile?: never;
    }
  | {
      strokeColor?: never;
      gradientProfile?: keyof typeof gradients;
    };

type CircularCountdownProps = CircularCountdownBaseProps & FlexProps & CircularCountdownOptionalProps;

const Svg = (props: FlexProps) => (
  <Box
    as="svg"
    position="absolute"
    top={0}
    left={0}
    width="100%"
    height="100%"
    transform="rotateY(-180deg) rotateZ(-90deg)"
    overflow="visible"
    {...props}
  />
);

export const CircularCountdown: FC<PropsWithChildren<CircularCountdownProps>> = ({
  size,
  countdown,
  duration,
  children,
  strokeWidth,
  strokeColor,
  gradientProfile,
  trackColor = "transparent",
  trackWidth = strokeWidth,
  ...props
}) => {
  const radius = size / 2;
  const circumference = size * Math.PI;
  const strokeDashOffset = circumference - (countdown / duration) * circumference;

  const sizePx = `${size}px`;
  const circleProps = {
    cx: radius,
    cy: radius,
    r: radius,
    fill: "none",
  };

  return (
    <Flex justifyContent="center" alignItems="center" position="relative" height={sizePx} width={sizePx} {...props}>
      {children}
      <Svg>
        <Box as="circle" stroke={trackColor} strokeWidth={trackWidth} {...circleProps} />
      </Svg>
      <Svg>
        {gradientProfile && gradients[gradientProfile]()}
        <Box
          as="circle"
          stroke={strokeColor || "url(#gradient)"}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashOffset}
          strokeWidth={strokeWidth}
          {...circleProps}
        />
      </Svg>
    </Flex>
  );
};
