import { useState } from "react";
import { Trans, useTranslation } from "next-i18next";
import {
  AspectRatio,
  Box,
  Flex,
  HStack,
  ModalBody,
  ModalProps,
  VStack,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { getLocalStorageItem } from "@looksrare/utils";
import {
  Button,
  CheckboxCheckedFilledIcon,
  CheckboxOutlineIcon,
  CloudinaryImage,
  NextLink,
  Text,
} from "@looksrare/uikit";
import { BASE_URL, LS_DISCLAIMER_KEY } from "../../../config";
import { EnterCaveHeader } from "./shared";

interface DisclaimerProps {
  onClose: ModalProps["onClose"];
  onAcknowledge: (dontShow: boolean) => void;
}

export const Disclaimer = ({ onClose, onAcknowledge }: DisclaimerProps) => {
  const { t } = useTranslation();
  const [doNotShowAgain, setDoNotShowAgain] = useState<boolean>(() => {
    const doNotShowAgainValue = getLocalStorageItem(LS_DISCLAIMER_KEY);
    return Boolean(doNotShowAgainValue);
  });

  const { colorMode } = useColorMode();
  const bgGradient = useColorModeValue(
    "linear-gradient(180deg, #F3F3F8 0%, white 100%)",
    "linear-gradient(180deg, #21252B 0%, #121619 100%)"
  );

  const handleAcknowledged = () => {
    onAcknowledge(doNotShowAgain);
  };

  return (
    <>
      <EnterCaveHeader bg="ui-01" onClose={onClose} />
      <Flex alignItems="center" justifyContent="center" py={6} bgGradient={bgGradient}>
        <Box width="196px">
          <AspectRatio ratio={379 / 373}>
            <CloudinaryImage
              src={`/images/poke-the-bear/ptb-logo-small-${colorMode}.png`}
              alt="ptb"
              objectFit="cover"
              layout="fill"
            />
          </AspectRatio>
        </Box>
      </Flex>
      <ModalBody bg="ui-bg" p={6}>
        <Text as="h1" textStyle="display-body" bold mb={6}>
          {t("Before We Begin...")}
        </Text>
        <Text fontWeight={400} mb={6}>
          <Trans i18nKey="ptbGmDisclaimer">
            GM. By entering a round, you indicate that you fully understand the rules of the game —{" "}
            <Text as="span" bold color="text-warning">
              if you lose the round, you&apos;ll lose your entry fee.
            </Text>{" "}
            Don&apos;t like it? Don&apos;t poke bears.
          </Trans>
        </Text>
        <HStack
          w="100%"
          spacing={2}
          cursor="pointer"
          onClick={() => setDoNotShowAgain((prevDoNotShow) => !prevDoNotShow)}
        >
          {doNotShowAgain ? (
            <CheckboxCheckedFilledIcon color="text-01" boxSize={5} />
          ) : (
            <CheckboxOutlineIcon boxSize={5} />
          )}
          <Text textStyle="detail">{t("Don’t show me this again")}</Text>
        </HStack>
        <VStack py={6} spacing={2}>
          <Button width="100%" onClick={handleAcknowledged}>
            {t("I Understand — Let’s Play")}
          </Button>
          <Button as={NextLink} href={`${BASE_URL}/faq`} width="100%" colorScheme="secondary">
            {t("Review the Rules")}
          </Button>
        </VStack>
      </ModalBody>
    </>
  );
};
