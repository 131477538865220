import { Icon, IconProps } from "../Icon";

const ArrowDown = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.59 16.59L17 24.17V2H15V24.17L7.41 16.59L6 18L16 28L26 18L24.59 16.59Z"
      fill="currentColor"
    />
  </Icon>
);

export default ArrowDown;
