import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const BlastGoldToken = (props?: IconProps) => {
  const [uid1] = useState(uniqueId());
  const [uid2] = useState(uniqueId());

  return (
    <Icon viewBox="0 0 96 96" color="#0CE466" {...props}>
      <g clipPath={`url(#${uid1})`}>
        <path
          d="M48 96C74.5097 96 96 74.5097 96 48C96 21.4903 74.5097 0 48 0C21.4903 0 0 21.4903 0 48C0 74.5097 21.4903 96 48 96Z"
          fill={`url(#${uid2})`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.6271 26.2375L70.1137 26.7839L78.6833 44.4944L46.8498 69.9613L17.1776 60.0705L25.6322 39.2158L52.6271 26.2375ZM53.3728 29.7625L32.4299 39.8312L43.6702 42.1454L63.1498 30.068L53.3728 29.7625ZM68.2709 31.0111L45.8542 44.9094L47.9086 64.632L74.3166 43.5056L68.2709 31.0111ZM44.4781 65.4814L42.3921 45.4557L28.0743 42.5079L21.8223 57.9294L44.4781 65.4814Z"
          fill="#201F19"
        />
      </g>
      <defs>
        <linearGradient id={uid2} x1="8.6613e-08" y1="48" x2="96" y2="32.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFF61F" />
          <stop offset="0.627629" stopColor="#FCFDC4" />
          <stop offset="1" stopColor="#FDF972" />
        </linearGradient>
        <clipPath id={uid1}>
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default BlastGoldToken;
