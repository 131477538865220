import { IconButton, IconButtonProps } from "@chakra-ui/react";
import { VolumeMuteFilledIcon, VolumeUpFilledIcon } from "@looksrare/uikit";
import { useGlobalSettings } from "@/hooks";

export const MuteButton = (props: IconButtonProps) => {
  const [isSoundEnabled, toggleSound] = useGlobalSettings((state) => [state.isSoundEnabled, state.toggleSound]);
  return (
    <IconButton onClick={toggleSound} color={isSoundEnabled ? "link-01" : "red.400"} {...props}>
      {isSoundEnabled ? <VolumeUpFilledIcon /> : <VolumeMuteFilledIcon />}
    </IconButton>
  );
};
