import { Icon, IconProps } from "../Icon";

const BlurCircle = (props?: IconProps) => (
  <Icon {...props}>
    <g clipPath="url(#clip0_2027_5006)">
      <path
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#FF7816"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.33337 15.3827V6.66669H14.28L16.0334 8.30135V10.504L15.5334 11.0974L16.0334 11.6374V13.796L14.2747 15.4294L7.33337 15.384V15.3827ZM13.516 13.64L14.1587 13.0467V12.3267L13.7987 12.028H9.20671V13.6147L13.5147 13.6414L13.516 13.64ZM13.7654 10.2454L14.1587 9.87869V9.05069L13.512 8.44935H9.20804V10.2454H13.7654ZM24.2507 16.5734L26 18.2094V20.532L25.4174 21.132L26 21.6627V25.236H24.1254V22.3534L23.7654 22.0534H19.176V25.2374H17.3014V16.5734H24.2507ZM23.48 18.3547H19.176V20.2747H23.7334L24.1254 19.908V18.9574L23.48 18.3547ZM25.6867 15.428V13.6454H20L19.1734 13V6.66935H17.3V13.8427L19.3294 15.428H25.6867ZM13.2907 23.5507L14.0827 22.9147V16.576H15.9574V23.7414L13.976 25.3334H9.33871L7.33337 23.744V16.576H9.20804V22.9094L10.0174 23.5507H13.2907Z"
        fill="#34383E"
      />
    </g>
    <defs>
      <clipPath id="clip0_2027_5006">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);

export default BlurCircle;
