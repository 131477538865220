import { useState } from "react";
import uniqueId from "lodash/uniqueId";
import { Icon, IconProps } from "../Icon";

const EthChainLogo = (props?: IconProps) => {
  const [uid] = useState(uniqueId());

  return (
    <Icon viewBox="0 0 96 96" color="#0CE466" {...props}>
      <g clipPath={`url(#${uid})`}>
        <path
          opacity="0.2"
          d="M0 24C0 10.7452 10.7452 0 24 0H72C85.2548 0 96 10.7452 96 24V72C96 85.2548 85.2548 96 72 96H24C10.7452 96 0 85.2548 0 72V24Z"
          fill="#726FFC"
        />
        <path
          d="M47.9941 13.7997L47.5352 15.3584V60.5862L47.9941 61.0441L68.9879 48.6344L47.9941 13.7997Z"
          fill="#726FFC"
        />
        <path d="M47.9944 13.7997L27 48.6344L47.9944 61.0441V39.0917V13.7997Z" fill="#726FFC" />
        <path
          d="M47.9925 65.0208L47.7339 65.3362V81.447L47.9925 82.202L68.9992 52.6176L47.9925 65.0208Z"
          fill="#726FFC"
        />
        <path d="M47.9944 82.202V65.0208L27 52.6176L47.9944 82.202Z" fill="#726FFC" />
        <path d="M47.9945 61.0433L68.9883 48.6337L47.9945 39.091V61.0433Z" fill="#726FFC" />
        <path d="M27 48.6337L47.9944 61.0433V39.091L27 48.6337Z" fill="#726FFC" />
      </g>
      <defs>
        <clipPath id={uid}>
          <rect width="96" height="96" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};

export default EthChainLogo;
