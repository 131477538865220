import { useTranslation } from "next-i18next";
import { ModalBody } from "@chakra-ui/react";
import { ModalHeroHeader, ModalLegacy, ModalProps, GamesFillIcon, ExternalLink, Button } from "@looksrare/uikit";

export const PlayToEarnModal = (props: Omit<ModalProps, "children">) => {
  const { t } = useTranslation();

  return (
    <ModalLegacy hideHeader {...props}>
      <ModalHeroHeader
        showCloseButton
        icon={GamesFillIcon}
        title={t("Play and Earn")}
        label={t(
          "Play games on YOLO Games to earn Points. The riskier the game, the more Points you’ll earn, so playing games like YOLO will net you more Points than Poke the Bear."
        )}
        onClose={props.onClose}
      />
      <ModalBody pt={0}>
        <Button
          as={ExternalLink}
          href="https://docs.yologames.io/rewards/points"
          isExternal
          width="full"
          colorScheme="secondary"
        >
          {t("Learn More")}
        </Button>
      </ModalBody>
    </ModalLegacy>
  );
};
