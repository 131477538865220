import { Flex, Spinner } from "@chakra-ui/react";
import { CheckmarkFilledIcon, ClockIcon, ErrorIcon, IconProps } from "../Icons";
import { type StepTimingStatus, type TransactionStatus } from "./types";

/**
 * TransactionStepStatusIcon is used to display the status of a child transaction step.
 * StepTimingStatusIcon is used to display the status of the parent step.
 */

interface TransactionStepStatusIconProps extends IconProps {
  status?: TransactionStatus;
}

export const TransactionStepStatusIcon = ({ status, ...props }: TransactionStepStatusIconProps) => {
  switch (status) {
    case "pending":
      return (
        <Flex ml={props.ml} width={props.boxSize} overflow="hidden" alignItems="flex-end">
          <Spinner color="link-01" boxSize={props.boxSize} />
        </Flex>
      );
    case "error":
      return <ErrorIcon color="text-error" {...props} />;
    case "wait":
      return <ClockIcon color="text-disabled" {...props} />;
    case "skipped":
      return <ErrorIcon color="text-disabled" {...props} />;
    case "done":
    default:
      return <CheckmarkFilledIcon color="text-disabled" {...props} />;
  }
};

export const StepTimingStatusIcon = ({ status, ...props }: { status: StepTimingStatus } & IconProps) => {
  switch (status) {
    case "current":
      return <Spinner color="interactive-03" />;
    case "future":
      return <ClockIcon color="text-03" {...props} />;
    case "past":
    default:
      return <CheckmarkFilledIcon color="text-disabled" {...props} />;
  }
};
