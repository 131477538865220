import { colors } from "@looksrare/chakra-theme";

export const DrawerTheme = {
  baseStyle: {
    overlay: {
      bg: `${colors.purple[900]}D9`,
      backdropFilter: "blur(8px)",
    },
    dialog: {
      bg: "ui-bg",
    },
    dialogContainer: {},
  },
  sizes: {
    sm: {
      dialog: { maxWidth: "22.5rem" },
    },
  },
};
