import { Icon, IconProps } from "../Icon";

const OfferFill = (props: IconProps) => (
  <Icon {...props}>
    <path
      d="M16.0001 2C17.1046 2 18.0001 2.89543 18.0001 4V16H19.0001V5C19.0001 3.89543 19.8955 3 21.0001 3C22.1046 3 23.0001 3.89543 23.0001 5V16H24.0001V10C24.0001 8.89543 24.8955 8 26.0001 8C27.1046 8 28.0001 8.89543 28.0001 10V22.4558H27.9873C27.751 26.662 24.2654 30 20.0001 30H15.0001L14.9989 29.9982C14.9709 29.9994 14.9428 30 14.9146 30C12.229 30 9.6934 28.7613 8.0427 26.6428L2.83694 19.9618C2.22354 19.1746 2.28435 18.0557 2.97945 17.3396C3.67456 16.6235 4.79119 16.5294 5.59633 17.119L9.00006 19.612V7C9.00006 5.89543 9.89549 5 11.0001 5C12.1046 5 13.0001 5.89543 13.0001 7V16H14.0001V4C14.0001 2.89543 14.8955 2 16.0001 2Z"
      fill="currentColor"
    />
  </Icon>
);

export default OfferFill;
