import { Icon, IconProps } from "@looksrare/uikit";

// @todo - ptb maybe move this to an icons folder
export const TriangleIcon = (props: IconProps) => (
  <Icon width="5px" height="14px" viewBox="0 0 5 14" fill="none" {...props}>
    <path d="M0 0l5 7-5 7V0z" fill="url(#A)" />
    <defs>
      <linearGradient id="A" x1="0" y1="14" x2="5.352" y2="13.864" gradientUnits="userSpaceOnUse">
        <stop offset=".61" stopColor="#f8cc32" />
        <stop offset="1" stopColor="#df6d04" />
      </linearGradient>
    </defs>
  </Icon>
);
