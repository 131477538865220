import { Trans } from "next-i18next";
import { PtbRoundLogType } from "../../../../types";
import type { LogTextProps, RoundLogTextGetter } from "./types";
import { LogText, LogTextHighlight } from "./LogText";

const PlayerDiedText1 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerDied1">
      Play stupid games, win stupid prizes. The bear wakes up and shreds{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      .
    </Trans>
  </LogText>
);

const PlayerDiedText2 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerDied2">
      The bear roars as he springs to life and devours{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      . Rest in pieces, fam.
    </Trans>
  </LogText>
);

const PlayerDiedText3 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerDied3">
      A low growl shakes the cave as the bear rises. Before{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>{" "}
      reacts, they&apos;re torn to shreds.
    </Trans>
  </LogText>
);

const PlayerDiedText4 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerDied4">
      What did you expect,{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      ? The bear erupts, and mauls you instantly.
    </Trans>
  </LogText>
);

const PlayerDiedText5 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerDied5">
      The bear pounces and instantly unalives{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      . GG.
    </Trans>
  </LogText>
);

const PlayerDiedText6 = ({ user1, ...props }: LogTextProps) => (
  <LogText {...props}>
    <Trans i18nKey="ptb::commentary.playerDied6">
      The bear snarls as he stands, staring through{" "}
      <LogTextHighlight>
        <>{{ user1 }}</>
      </LogTextHighlight>
      &apos;s soul. He bellows as he lunges and tears them apart.
    </Trans>
  </LogText>
);

export const playerDiedLogs: RoundLogTextGetter = ({ user1 }) => [
  <PlayerDiedText1 key={`${PtbRoundLogType.PLAYER_DIED}1`} user1={user1} />,
  <PlayerDiedText2 key={`${PtbRoundLogType.PLAYER_DIED}2`} user1={user1} />,
  <PlayerDiedText3 key={`${PtbRoundLogType.PLAYER_DIED}3`} user1={user1} />,
  <PlayerDiedText4 key={`${PtbRoundLogType.PLAYER_DIED}4`} user1={user1} />,
  <PlayerDiedText5 key={`${PtbRoundLogType.PLAYER_DIED}5`} user1={user1} />,
  <PlayerDiedText6 key={`${PtbRoundLogType.PLAYER_DIED}6`} user1={user1} />,
];
