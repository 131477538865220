import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { Button, CheckboxCheckedFilledIcon, CheckboxOutlineIcon, FilterIcon } from "@looksrare/uikit";
import { useTranslation } from "next-i18next";
import { CaveOption } from "../../views/HistoryView";

interface FilterButtonProps {
  options: CaveOption[];
  selectedOptions: CaveOption[];
  onClickOption: (option: CaveOption) => void;
}

export const FilterButton = ({ selectedOptions, options, onClickOption }: FilterButtonProps) => {
  const { t } = useTranslation();
  return (
    <Menu closeOnSelect={false}>
      <MenuButton size="sm" as={Button} leftIcon={<FilterIcon />} variant="outline" colorScheme="secondary">
        {t("Filter")}
      </MenuButton>

      <MenuList
        zIndex="dropdown"
        maxHeight={{ base: "256px", md: "512px" }}
        overflowY="auto"
        sx={{
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {options.map((option) => {
          const handleClick = () => onClickOption(option);
          const isChecked = selectedOptions.some(({ id }) => option.id === id);
          return (
            <MenuItem
              key={option.id}
              icon={isChecked ? <CheckboxCheckedFilledIcon /> : <CheckboxOutlineIcon />}
              onClick={handleClick}
              data-id={`ptb_history-${option.id}`}
            >
              {option.name || option.id}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
