import { Duration } from "date-fns";

export const getFormattedTimer = (duration?: Duration): string => {
  if (duration?.hours === undefined && duration?.minutes === undefined && duration?.seconds === undefined) {
    return "";
  }

  const hours = duration.hours && duration.hours.toLocaleString("en-US", { minimumIntegerDigits: 2 });
  const min = (duration.minutes || 0).toLocaleString("en-US", { minimumIntegerDigits: 2 });
  const sec = (duration.seconds || 0).toLocaleString("en-US", { minimumIntegerDigits: 2 });
  return [hours, min, sec].filter(Boolean).join(":");
};
