import { Icon, IconProps } from "../Icon";

const ArrowAnchor = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00003 5V18.9762C8.00003 20.0808 8.89546 20.9762 10 20.9762H21.6143L17.3639 25.2989L18.79 26.7011L25.4025 19.9762L18.79 13.2513L17.3639 14.6535L21.6143 18.9762H10V5H8.00003Z"
      fill="currentColor"
    />
  </Icon>
);

export default ArrowAnchor;
