import { FC, ReactNode, useEffect } from "react";
import { useTranslation } from "next-i18next";
import { Flex } from "@chakra-ui/react";
import { useIntersectionObserver } from "@looksrare/utils";
import { SectionPlaceholder } from "../Placeholder";
import { Text } from "../Text";

export type InfiniteLoadingRowsProps<T> = {
  data: T[];
  hasMore?: boolean;
  loadMore: () => void;
  renderRow: (item: T, index: number) => ReactNode;
  emptyContent?: ReactNode;
  noMoreContent?: ReactNode;
  isLoadingMore: boolean;
  skeleton: FC;
  intersectionMargin?: string;
  intersectionObserverRoot?: Element | null;
};
export const InfiniteLoadingRows = <T,>({
  data,
  hasMore,
  loadMore,
  renderRow,
  isLoadingMore,
  skeleton: Skeleton,
  emptyContent,
  noMoreContent,
  intersectionMargin = "200px",
  intersectionObserverRoot,
}: InfiniteLoadingRowsProps<T>) => {
  const { t } = useTranslation();

  const { observerRef, isIntersecting } = useIntersectionObserver(intersectionMargin, intersectionObserverRoot);
  const showLoader = isLoadingMore || (hasMore && isIntersecting);
  const hasReachedEnd = !hasMore;
  const hasContent = data && data.length > 0;
  const shouldLoadMore = hasContent && hasMore && isIntersecting && !isLoadingMore;

  useEffect(() => {
    if (shouldLoadMore) {
      loadMore();
    }
  }, [loadMore, shouldLoadMore]);

  const isNoDataDisplay = !hasContent && !showLoader;

  return (
    <>
      {isNoDataDisplay && emptyContent !== undefined && emptyContent}
      {isNoDataDisplay && emptyContent === undefined && (
        <SectionPlaceholder py={8}>
          <Text textStyle="detail" textColor="text-disabled">
            {t("No Data")}
          </Text>
        </SectionPlaceholder>
      )}
      {!isNoDataDisplay && (
        <>
          {data.map((element, index) => {
            return renderRow(element, index);
          })}
          {showLoader && <Skeleton />}
          {hasReachedEnd &&
            (noMoreContent !== undefined ? (
              noMoreContent
            ) : (
              <Flex alignItems="center" justifyContent="center" pt={8}>
                <Text color="text-02">{t("That's all!")}</Text>
              </Flex>
            ))}
        </>
      )}

      <div ref={observerRef} />
    </>
  );
};
