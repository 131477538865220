import { useCallback, useEffect, useRef } from "react";

interface PlayOptions {
  restartIfAlreadyPlaying?: boolean;
  loop?: boolean;
}

export const useSoundEffect = ({ path, volume, isMuted }: { path: string; volume: number; isMuted: boolean }) => {
  const audioRef = useRef<AudioContext | null>();
  const gainRef = useRef<GainNode | null>();
  const sourceRef = useRef<AudioBufferSourceNode | null>();

  const removeSource = useCallback(() => {
    if (sourceRef.current) {
      sourceRef.current.disconnect();
      sourceRef.current = null;
    }
  }, []);

  const createSource = useCallback(
    async (loop: boolean, audioContext: AudioContext, gainNode: GainNode) => {
      const source = audioContext.createBufferSource();

      const arrayBuffer = await fetch(path).then((res) => res.arrayBuffer());
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      source.buffer = audioBuffer;
      source.loop = loop;
      source.connect(gainNode);

      return source;
    },
    [path]
  );

  /** Instantiate and cleanup*/
  useEffect(() => {
    const audioContext = new AudioContext();
    const gainNode = audioContext.createGain();

    audioRef.current = audioContext;
    gainRef.current = gainNode;

    gainNode.gain.value = volume;
    gainNode.connect(audioContext.destination);

    return () => {
      gainNode.disconnect();
      audioContext.close();
    };
  }, [volume, path, createSource]);

  /** Update volume if isMuted changes */
  useEffect(() => {
    if (gainRef.current) {
      gainRef.current.gain.value = !isMuted ? volume : 0;
    }
  }, [volume, isMuted]);

  const play = useCallback(
    async (options?: PlayOptions) => {
      const { restartIfAlreadyPlaying = true, loop = false } = { ...options };

      if (!isMuted) {
        if (restartIfAlreadyPlaying && sourceRef.current) {
          removeSource();
        }

        if (!sourceRef.current && audioRef.current && gainRef.current) {
          const source = await createSource(loop, audioRef.current, gainRef.current);
          sourceRef.current = source;

          source.start();
        }
      }
    },
    [createSource, isMuted, removeSource]
  );

  return {
    audioElement: audioRef.current,
    play,
    stop: removeSource,
  };
};
