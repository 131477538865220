import { Icon, IconProps } from "@chakra-ui/react";
import React from "react";

export const Reflection = (props: IconProps) => (
  <Icon width="92" height="65" viewBox="0 0 92 65" fill="none" {...props}>
    <g clipPath="url(#clip0_1214_159788)">
      <g opacity="0.5">
        <g filter="url(#filter0_f_1214_159788)">
          <path d="M65 0L0 65" stroke="white" strokeWidth="4" />
        </g>
        <g filter="url(#filter1_f_1214_159788)">
          <path d="M92 0L27 65" stroke="white" />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_f_1214_159788"
        x="-13.4143"
        y="-13.4142"
        width="91.8286"
        height="91.8284"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="6" result="effect1_foregroundBlur_1214_159788" />
      </filter>
      <filter
        id="filter1_f_1214_159788"
        x="14.6465"
        y="-12.3535"
        width="89.707"
        height="89.707"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="6" result="effect1_foregroundBlur_1214_159788" />
      </filter>
      <clipPath id="clip0_1214_159788">
        <rect width="92" height="65" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
