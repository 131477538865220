export const PokeTheBearAbi = [
  {
    inputs: [
      { internalType: "address", name: "_owner", type: "address" },
      { internalType: "address", name: "_operator", type: "address" },
      { internalType: "address", name: "_protocolFeeRecipient", type: "address" },
      { internalType: "address", name: "wrappedNativeToken", type: "address" },
      { internalType: "address", name: "_transferManager", type: "address" },
      { internalType: "bytes32", name: "keyHash", type: "bytes32" },
      { internalType: "address", name: "vrfCoordinator", type: "address" },
      { internalType: "uint64", name: "subscriptionId", type: "uint64" },
      { internalType: "address", name: "_ethYieldConfiguration", type: "address" },
      { internalType: "address", name: "_usdbYieldConfiguration", type: "address" },
      { internalType: "address", name: "_blastPoints", type: "address" },
      { internalType: "address", name: "_blastPointsOperator", type: "address" },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  { inputs: [], name: "AccessControlBadConfirmation", type: "error" },
  {
    inputs: [
      { internalType: "address", name: "account", type: "address" },
      { internalType: "bytes32", name: "neededRole", type: "bytes32" },
    ],
    name: "AccessControlUnauthorizedAccount",
    type: "error",
  },
  { inputs: [], name: "CommitmentNotAvailable", type: "error" },
  { inputs: [], name: "ERC20TransferFail", type: "error" },
  { inputs: [], name: "ExceedsMaximumNumberOfPlayersPerRound", type: "error" },
  { inputs: [], name: "HashedPlayerIndicesDoesNotMatchCommitment", type: "error" },
  { inputs: [], name: "InactiveCave", type: "error" },
  { inputs: [], name: "IndivisibleEnterAmount", type: "error" },
  {
    inputs: [
      { internalType: "uint256", name: "caveId", type: "uint256" },
      { internalType: "uint256", name: "roundId", type: "uint256" },
    ],
    name: "IneligibleToWithdraw",
    type: "error",
  },
  { inputs: [], name: "InsufficientNumberOfPlayers", type: "error" },
  {
    inputs: [
      { internalType: "uint256", name: "caveId", type: "uint256" },
      { internalType: "uint256", name: "roundId", type: "uint256" },
    ],
    name: "InvalidCommitment",
    type: "error",
  },
  { inputs: [], name: "InvalidEnterAmount", type: "error" },
  { inputs: [], name: "InvalidEnterCurrency", type: "error" },
  { inputs: [], name: "InvalidPlayerDetails", type: "error" },
  {
    inputs: [
      { internalType: "uint256", name: "caveId", type: "uint256" },
      { internalType: "uint256", name: "roundId", type: "uint256" },
    ],
    name: "InvalidPlayerIndex",
    type: "error",
  },
  { inputs: [], name: "InvalidRoundDuration", type: "error" },
  { inputs: [], name: "InvalidRoundParameters", type: "error" },
  { inputs: [], name: "InvalidRoundStatus", type: "error" },
  { inputs: [], name: "InvalidValue", type: "error" },
  { inputs: [], name: "IsPaused", type: "error" },
  { inputs: [], name: "NotAContract", type: "error" },
  { inputs: [], name: "NotCancellable", type: "error" },
  { inputs: [], name: "NotOperator", type: "error" },
  { inputs: [], name: "NotOwner", type: "error" },
  { inputs: [], name: "NotPaused", type: "error" },
  {
    inputs: [
      { internalType: "address", name: "have", type: "address" },
      { internalType: "address", name: "want", type: "address" },
    ],
    name: "OnlyCoordinatorCanFulfill",
    type: "error",
  },
  {
    inputs: [
      { internalType: "uint256", name: "caveId", type: "uint256" },
      { internalType: "uint256", name: "roundId", type: "uint256" },
      { internalType: "address", name: "player", type: "address" },
    ],
    name: "PlayerAlreadyParticipated",
    type: "error",
  },
  { inputs: [], name: "ProtocolFeeBasisPointsTooHigh", type: "error" },
  { inputs: [], name: "RandomnessRequestAlreadyExists", type: "error" },
  { inputs: [], name: "ReentrancyFail", type: "error" },
  { inputs: [], name: "RepeatingPlayerIndex", type: "error" },
  {
    inputs: [
      { internalType: "uint256", name: "caveId", type: "uint256" },
      { internalType: "uint256", name: "roundId", type: "uint256" },
    ],
    name: "RoundCannotBeEntered",
    type: "error",
  },
  { inputs: [], name: "RoundsIncomplete", type: "error" },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint256", name: "caveId", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "enterAmount", type: "uint256" },
      { indexed: false, internalType: "address", name: "enterCurrency", type: "address" },
      { indexed: false, internalType: "uint40", name: "roundDuration", type: "uint40" },
      { indexed: false, internalType: "uint8", name: "playersPerRound", type: "uint8" },
      { indexed: false, internalType: "uint16", name: "protocolFeeBp", type: "uint16" },
    ],
    name: "CaveAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: "uint256", name: "caveId", type: "uint256" }],
    name: "CaveRemoved",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          { internalType: "uint256", name: "caveId", type: "uint256" },
          { internalType: "bytes32[]", name: "commitments", type: "bytes32[]" },
        ],
        indexed: false,
        internalType: "struct IPokeTheBear.CommitmentCalldata[]",
        name: "commitments",
        type: "tuple[]",
      },
    ],
    name: "CommitmentsSubmitted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          { internalType: "uint256", name: "caveId", type: "uint256" },
          { internalType: "uint256[]", name: "roundIds", type: "uint256[]" },
        ],
        indexed: false,
        internalType: "struct IPokeTheBear.Withdrawal[]",
        name: "deposits",
        type: "tuple[]",
      },
      { indexed: false, internalType: "address", name: "player", type: "address" },
    ],
    name: "DepositsRefunded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          { internalType: "uint256", name: "caveId", type: "uint256" },
          { internalType: "uint256[]", name: "rolledOverRoundIds", type: "uint256[]" },
          { internalType: "uint256", name: "rollingOverToRoundIdStart", type: "uint256" },
        ],
        indexed: false,
        internalType: "struct IPokeTheBear.Rollover[]",
        name: "rollovers",
        type: "tuple[]",
      },
      { indexed: false, internalType: "address", name: "player", type: "address" },
    ],
    name: "DepositsRolledOver",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }],
    name: "Paused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        components: [
          { internalType: "uint256", name: "caveId", type: "uint256" },
          { internalType: "uint256[]", name: "roundIds", type: "uint256[]" },
        ],
        indexed: false,
        internalType: "struct IPokeTheBear.Withdrawal[]",
        name: "prizes",
        type: "tuple[]",
      },
      { indexed: false, internalType: "address", name: "player", type: "address" },
    ],
    name: "PrizesClaimed",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: "address", name: "protocolFeeRecipient", type: "address" }],
    name: "ProtocolFeeRecipientUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint256", name: "caveId", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "roundId", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "requestId", type: "uint256" },
    ],
    name: "RandomnessRequested",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      { indexed: true, internalType: "bytes32", name: "previousAdminRole", type: "bytes32" },
      { indexed: true, internalType: "bytes32", name: "newAdminRole", type: "bytes32" },
    ],
    name: "RoleAdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      { indexed: true, internalType: "address", name: "account", type: "address" },
      { indexed: true, internalType: "address", name: "sender", type: "address" },
    ],
    name: "RoleGranted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "bytes32", name: "role", type: "bytes32" },
      { indexed: true, internalType: "address", name: "account", type: "address" },
      { indexed: true, internalType: "address", name: "sender", type: "address" },
    ],
    name: "RoleRevoked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint256", name: "caveId", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "roundId", type: "uint256" },
      { indexed: false, internalType: "enum IPokeTheBear.RoundStatus", name: "status", type: "uint8" },
    ],
    name: "RoundStatusUpdated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint256", name: "caveId", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "startingRoundId", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "numberOfRounds", type: "uint256" },
    ],
    name: "RoundsCancelled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: false, internalType: "uint256", name: "caveId", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "startingRoundId", type: "uint256" },
      { indexed: false, internalType: "uint256", name: "numberOfRounds", type: "uint256" },
      { indexed: false, internalType: "address", name: "player", type: "address" },
    ],
    name: "RoundsEntered",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [{ indexed: false, internalType: "address", name: "account", type: "address" }],
    name: "Unpaused",
    type: "event",
  },
  {
    inputs: [],
    name: "DEFAULT_ADMIN_ROLE",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "enterAmount", type: "uint256" },
      { internalType: "address", name: "enterCurrency", type: "address" },
      { internalType: "uint8", name: "playersPerRound", type: "uint8" },
      { internalType: "uint40", name: "roundDuration", type: "uint40" },
      { internalType: "uint16", name: "protocolFeeBp", type: "uint16" },
    ],
    name: "addCave",
    outputs: [{ internalType: "uint256", name: "caveId", type: "uint256" }],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "caveId", type: "uint256" },
      { internalType: "uint256", name: "numberOfRounds", type: "uint256" },
    ],
    name: "cancel",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "caveId", type: "uint256" }],
    name: "cancel",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "caveId", type: "uint256" },
      { internalType: "uint256", name: "roundId", type: "uint256" },
    ],
    name: "cancellable",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "caveId", type: "uint256" }],
    name: "caves",
    outputs: [
      { internalType: "uint256", name: "enterAmount", type: "uint256" },
      { internalType: "address", name: "enterCurrency", type: "address" },
      { internalType: "uint40", name: "roundsCount", type: "uint40" },
      { internalType: "uint40", name: "lastCommittedRoundId", type: "uint40" },
      { internalType: "uint40", name: "roundDuration", type: "uint40" },
      { internalType: "uint8", name: "playersPerRound", type: "uint8" },
      { internalType: "uint16", name: "protocolFeeBp", type: "uint16" },
      { internalType: "bool", name: "isActive", type: "bool" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "wethReceiver", type: "address" },
      { internalType: "address", name: "usdbReceiver", type: "address" },
    ],
    name: "claim",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          { internalType: "uint256", name: "caveId", type: "uint256" },
          {
            components: [
              { internalType: "uint256", name: "roundId", type: "uint256" },
              { internalType: "uint256", name: "playerIndex", type: "uint256" },
            ],
            internalType: "struct IPokeTheBear.PlayerWithdrawalCalldata[]",
            name: "playerDetails",
            type: "tuple[]",
          },
        ],
        internalType: "struct IPokeTheBear.WithdrawalCalldata[]",
        name: "claimPrizeCalldataArray",
        type: "tuple[]",
      },
    ],
    name: "claimPrizes",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          { internalType: "uint256", name: "caveId", type: "uint256" },
          { internalType: "bytes32[]", name: "commitments", type: "bytes32[]" },
        ],
        internalType: "struct IPokeTheBear.CommitmentCalldata[]",
        name: "commitments",
        type: "tuple[]",
      },
    ],
    name: "commit",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "caveId", type: "uint256" },
      { internalType: "uint256", name: "startingRoundId", type: "uint256" },
      { internalType: "uint256", name: "numberOfRounds", type: "uint256" },
    ],
    name: "enter",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes32", name: "role", type: "bytes32" }],
    name: "getRoleAdmin",
    outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "caveId", type: "uint256" },
      { internalType: "uint256", name: "roundId", type: "uint256" },
    ],
    name: "getRound",
    outputs: [
      { internalType: "enum IPokeTheBear.RoundStatus", name: "status", type: "uint8" },
      { internalType: "uint40", name: "cutoffTime", type: "uint40" },
      { internalType: "uint40", name: "drawnAt", type: "uint40" },
      { internalType: "bytes32", name: "commitment", type: "bytes32" },
      { internalType: "bytes32", name: "salt", type: "bytes32" },
      { internalType: "uint8[32]", name: "playerIndices", type: "uint8[32]" },
      {
        components: [
          { internalType: "address", name: "addr", type: "address" },
          { internalType: "bool", name: "isLoser", type: "bool" },
          { internalType: "bool", name: "withdrawn", type: "bool" },
        ],
        internalType: "struct IPokeTheBear.Player[]",
        name: "players",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "grantRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "hasRole",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "caveId", type: "uint256" },
      { internalType: "uint256", name: "roundId", type: "uint256" },
      { internalType: "address", name: "player", type: "address" },
    ],
    name: "isPlayerInRound",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "nextCaveId",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "protocolFeeRecipient",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "requestId", type: "uint256" }],
    name: "randomnessRequests",
    outputs: [
      { internalType: "bool", name: "exists", type: "bool" },
      { internalType: "uint40", name: "caveId", type: "uint40" },
      { internalType: "uint40", name: "roundId", type: "uint40" },
      { internalType: "uint256", name: "randomWord", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "requestId", type: "uint256" },
      { internalType: "uint256[]", name: "randomWords", type: "uint256[]" },
    ],
    name: "rawFulfillRandomWords",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          { internalType: "uint256", name: "caveId", type: "uint256" },
          {
            components: [
              { internalType: "uint256", name: "roundId", type: "uint256" },
              { internalType: "uint256", name: "playerIndex", type: "uint256" },
            ],
            internalType: "struct IPokeTheBear.PlayerWithdrawalCalldata[]",
            name: "playerDetails",
            type: "tuple[]",
          },
        ],
        internalType: "struct IPokeTheBear.WithdrawalCalldata[]",
        name: "refundCalldataArray",
        type: "tuple[]",
      },
    ],
    name: "refund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "caveId", type: "uint256" }],
    name: "removeCave",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "callerConfirmation", type: "address" },
    ],
    name: "renounceRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "requestId", type: "uint256" },
      { internalType: "uint256", name: "playerIndices", type: "uint256" },
      { internalType: "bytes32", name: "salt", type: "bytes32" },
    ],
    name: "reveal",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "bytes32", name: "role", type: "bytes32" },
      { internalType: "address", name: "account", type: "address" },
    ],
    name: "revokeRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        components: [
          { internalType: "uint256", name: "caveId", type: "uint256" },
          { internalType: "uint256", name: "startingRoundId", type: "uint256" },
          { internalType: "uint256", name: "numberOfExtraRoundsToEnter", type: "uint256" },
          {
            components: [
              { internalType: "uint256", name: "roundId", type: "uint256" },
              { internalType: "uint256", name: "playerIndex", type: "uint256" },
            ],
            internalType: "struct IPokeTheBear.PlayerWithdrawalCalldata[]",
            name: "playerDetails",
            type: "tuple[]",
          },
        ],
        internalType: "struct IPokeTheBear.RolloverCalldata[]",
        name: "rolloverCalldataArray",
        type: "tuple[]",
      },
    ],
    name: "rollover",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  { inputs: [], name: "togglePaused", outputs: [], stateMutability: "nonpayable", type: "function" },
  {
    inputs: [{ internalType: "address", name: "_protocolFeeRecipient", type: "address" }],
    name: "updateProtocolFeeRecipient",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;
