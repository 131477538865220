import { Icon, IconProps } from "../Icon";

const Logout = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 30H18C19.1041 29.9989 19.9989 29.1041 20 28V25H18V28H6V4H18V7H20V4C19.9989 2.89589 19.1041 2.0011 18 2H6C4.89589 2.0011 4.0011 2.89589 4 4V28C4.0011 29.1041 4.89589 29.9989 6 30ZM20.586 20.586L24.172 17H10V15H24.172L20.586 11.414L22 10L28 16L22 22L20.586 20.586Z"
      fill="currentColor"
    />
  </Icon>
);

export default Logout;
