import { GRAPHQL_API_ENDPOINT } from "@looksrare/config";
import request, { Variables } from "graphql-request";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";

type GraphQLParams = Record<string, any>;

export interface GraphQLOptions<TQuery, TVariables extends Variables = Variables> {
  url?: string;
  query: string | TypedDocumentNode<TQuery, TVariables>;
  onError?: (data: ErrorReturn<TQuery, TVariables>) => void;
  params?: TVariables;
  requestHeaders?: HeadersInit;
  shouldLogVerboseError?: boolean;
}

interface ErrorReturn<TQuery, TVariables extends Variables = Variables> {
  clientError: any;
  query: string;
  params?: GraphQLParams;
  requestHeaders?: GraphQLOptions<TQuery, TVariables>["requestHeaders"];
}

/**
 * General wrapper around requests to the api & subgraph to centralize error handling
 *
 * @param query GraphQL query
 * @param params GraphQL params (via the gql function)
 * @param requestHeaders HeadersInit
 * @param url string url to query
 */
export const graphql = async <TQuery, TVariables extends Variables = Variables>({
  query,
  url,
  params,
  onError,
  requestHeaders,
  shouldLogVerboseError = false,
}: GraphQLOptions<TQuery, TVariables>): Promise<TQuery> => {
  const graphqlUrl = url || GRAPHQL_API_ENDPOINT!;
  try {
    // graphql-request does not export VariablesAndRequestHeadersArgs<V>
    const vars = [params, requestHeaders] as any;
    const res = await request<TQuery, TVariables>(graphqlUrl, query, ...vars);
    return res;
  } catch (error: any) {
    onError && onError({ clientError: error, query: query.toString(), requestHeaders, params });
    const verboseErrorParams = { url, query, params, requestHeaders };
    throw { error, ...(shouldLogVerboseError && verboseErrorParams) };
  }
};
