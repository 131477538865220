import { useCallback, useEffect, useRef, useState } from "react";

interface useCarouselProgressProps {
  isAutomatic: boolean;
  isPaused: boolean;
  interval: number;
  prevAutoSlideTime: number;
}

//

/**
 * returns a number between 0 and 1 representing the progress of the current slide
 * when using an automatic carousel
 *
 * @param prevAutoSlideTime The time at which the previous automatic slide iteration occurred
 */
export const useCarouselProgress = ({
  isAutomatic,
  isPaused,
  interval,
  prevAutoSlideTime,
}: useCarouselProgressProps) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [timeToNextSlide, setTimeToNextSlide] = useState(interval);

  const step = useCallback(() => {
    const updatedTimeToNextSlide = interval - (Date.now() - prevAutoSlideTime);

    // small margin of error to prevent the progress bar from jumping back and forth
    if (updatedTimeToNextSlide > 8) {
      setTimeToNextSlide(updatedTimeToNextSlide);

      timeoutRef.current = setTimeout(step, 1000 / 60);
    } else {
      setTimeToNextSlide(interval);
    }
  }, [prevAutoSlideTime, interval]);

  useEffect(() => {
    if (isPaused) {
      clearTimeout(timeoutRef.current);
    }

    if (isAutomatic && !isPaused) {
      timeoutRef.current = setTimeout(step, 1000 / 60);
    }

    return () => clearTimeout(timeoutRef.current);
  }, [isAutomatic, isPaused, step]);

  return timeToNextSlide / interval;
};
