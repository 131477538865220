/* eslint-disable */
import { BigIntish } from "@looksrare/utils";
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `Address` scalar represents an Ethereum address (42-char hexadecimal string) with 0x prefix. */
  Address: { input: any; output: any };
  /** The `BigNumber` scalar is an object which safely allows mathematical operations on large numbers. */
  BigNumber: { input: BigIntish; output: BigIntish };
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any };
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: string; output: string };
  /** A field whose value is a JSON Web Token (JWT): https://jwt.io/introduction. */
  JWT: { input: any; output: any };
  /** The javascript `Date` as integer. Type represents date and time as number of milliseconds from start of UNIX epoch. */
  Timestamp: { input: any; output: any };
};

/** JWT Payload for user login action. */
export type AuthPayload = {
  __typename?: "AuthPayload";
  address: Scalars["Address"]["output"];
  token: Scalars["JWT"]["output"];
};

/** Token */
export type Avatar = {
  __typename?: "Avatar";
  image?: Maybe<MediaData>;
};

/** User's boost allocation amounts and proofs */
export type BoostAllocation = {
  __typename?: "BoostAllocation";
  boostFromEthWei: Scalars["BigNumber"]["output"];
  boostFromUsdWei: Scalars["BigNumber"]["output"];
  burned: Scalars["Boolean"]["output"];
  claimed: Scalars["Boolean"]["output"];
  ethDepositedWei: Scalars["BigNumber"]["output"];
  proof: Array<Scalars["String"]["output"]>;
  totalBoostWei: Scalars["BigNumber"]["output"];
  usdDepositedWei: Scalars["BigNumber"]["output"];
};

/**
 * The required OAuth2 callback information for successfully linking a
 * user or collection to the relevant social account via a 3rd-part api call.
 */
export type ConnectSocialInput = {
  /** OAuth2 callback code */
  code: Scalars["String"]["input"];
  /** Code verifier is only required for Twitter OAuth2 */
  codeVerifier?: InputMaybe<Scalars["String"]["input"]>;
  /** The uri that was used to redirect when requesting the code */
  redirectUri: Scalars["String"]["input"];
};

/** Represents a currency entity formed by a name, address, symbol and decimals. */
export type Currency = {
  __typename?: "Currency";
  address: Scalars["Address"]["output"];
  decimals: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  symbol: Scalars["String"]["output"];
};

/** The amount in wei for a given currency */
export type CurrencyAmount = {
  __typename?: "CurrencyAmount";
  amountEthWei: Scalars["BigNumber"]["output"];
  amountWei: Scalars["BigNumber"]["output"];
  currency: Currency;
};

/** Daily quests (unique psuedo-random quests) data available to a user. */
export type DailyQuest = {
  __typename?: "DailyQuest";
  claimedAt?: Maybe<Scalars["DateTime"]["output"]>;
  code: DailyQuestCode;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  goal: Scalars["BigNumber"]["output"];
  points: Scalars["Int"]["output"];
  progress: Scalars["BigNumber"]["output"];
  questDate: Scalars["Date"]["output"];
};

/** Daily Quests Available */
export type DailyQuestCode =
  | "COMPLETION_BONUS"
  | "DONT_FALL_IN_ENTER_0_5_ETH"
  | "DONT_FALL_IN_WIN_5"
  | "FLIPPER_ENTER_0_5_ETH"
  | "FLIPPER_WIN_5"
  | "LASER_BLAST_ENTER_0_5_ETH"
  | "MORD_ENTER_0_5_ETH"
  | "MORD_WIN_5"
  | "PTB_ENTER_0_5_ETH"
  | "PTB_WIN_10"
  | "QUANTUM_ENTER_0_5_ETH"
  | "QUANTUM_WIN_5"
  | "YOLO_ENTER_0_25_ETH"
  | "YOLO_WIN_1";

/**
 * Information relating to the reset timestamp of a Daily Quest set
 * and the quests available to a user for this day.
 */
export type DailyQuests = {
  __typename?: "DailyQuests";
  nextDay: Scalars["DateTime"]["output"];
  quests: Array<DailyQuest>;
};

/**
 * DontFallIn games config data
 *
 * - The `multiplier` and `maximumRevealableTiles` for the given `lavasCount`/`revealedTilesCount`.
 * - If only the `maximumRevealableTiles` is needed, then provide 1 as the `revealedTilesCount`.
 *
 * Example given `{ "lavasCount": 9, "revealedTilesCount": 1 }`:
 * ```
 * {
 *   "multiplier": 1.5625,
 *   "maximumRevealableTiles": 7
 * }
 * ```
 */
export type DontFallInConfig = {
  __typename?: "DontFallInConfig";
  maximumRevealableTiles: Scalars["Int"]["output"];
  multiplier: Scalars["Float"]["output"];
};

/** DontFallIn contract enum */
export type DontFallInContract = "DONT_FALL_IN_V1_BLAST";

/**
 * DontFallIn game data
 *
 * The `multiplier` becomes `null` when the player loses the game.
 * The `latestMap` is a number whose binary representation indicates the latest map of the game; 1s indicates already selected tiles.
 * The `hitLavas` is a number whose binary representation indicates the lavas hit by the player; null until the player loses.
 *
 * The id is the cursor to be used for pagination.
 */
export type DontFallInGame = {
  __typename?: "DontFallInGame";
  amountWonWei: Scalars["BigNumber"]["output"];
  contract: DontFallInContract;
  createdAt: Scalars["DateTime"]["output"];
  currency: Scalars["Address"]["output"];
  drawingTransactionHash: Scalars["String"]["output"];
  drawnTransactionHash?: Maybe<Scalars["String"]["output"]>;
  hitLavas?: Maybe<Scalars["BigNumber"]["output"]>;
  id: Scalars["ID"]["output"];
  latestMap: Scalars["BigNumber"]["output"];
  lavasCount: Scalars["Int"]["output"];
  liquidityPoolFeeWei?: Maybe<Scalars["BigNumber"]["output"]>;
  multiplier?: Maybe<Scalars["Float"]["output"]>;
  playAmountWei: Scalars["BigNumber"]["output"];
  points: Scalars["BigNumber"]["output"];
  protocolFeeWei?: Maybe<Scalars["BigNumber"]["output"]>;
  returnMultiplier: Scalars["Float"]["output"];
  status: DontFallInStatus;
  user: User;
  winningAmountWei?: Maybe<Scalars["BigNumber"]["output"]>;
};

/** DontFallIn games filter input */
export type DontFallInGamesFilterInput = {
  contracts: Array<DontFallInContract>;
  player?: InputMaybe<Scalars["Address"]["input"]>;
};

/**
 * DontFallIn games sort enum
 *
 * Default: NEWEST
 */
export type DontFallInSort = "LUCKY_WIN_DESC" | "NEWEST" | "WIN_AMOUNT_DESC";

/**
 * DontFallIn games status enum
 *
 * DRAWING: The game is currently drawing.
 * IN_PROGRESS: The game is currently in progress; the player can exit and claim the current `amountWonWei`.
 * WON: The player has won the game.
 * LOST: The player has lost the game.
 * CLAIMED: The player has claimed the prize for the game.
 * CANCELLED: The game has been cancelled because the randomness was delayed.
 * REFUNDED: The game has been cancelled and refunded.
 *
 * Note: A new game can start only after the CANCELLED game has been refunded.
 */
export type DontFallInStatus = "CANCELLED" | "CLAIMED" | "DRAWING" | "IN_PROGRESS" | "LOST" | "REFUNDED" | "WON";

/** Arguments for filtering flippers */
export type FilterFlipperInput = {
  contracts: Array<FlipperContract>;
  player?: InputMaybe<Scalars["Address"]["input"]>;
};

/** Arguments for filtering coin flips */
export type FilterLaserBlastInput = {
  contracts: Array<LaserBlastContract>;
  player?: InputMaybe<Scalars["Address"]["input"]>;
};

/** Arguments for filtering lotteries */
export type FilterLotteryInput = {
  player?: InputMaybe<Scalars["Address"]["input"]>;
  status?: InputMaybe<LotteryStatus>;
  type?: InputMaybe<LotteryType>;
};

export type FlipSide = "GOLD" | "SILVER";

export type Flipper = {
  __typename?: "Flipper";
  amountPerRoundWei: Scalars["BigNumber"]["output"];
  contract: FlipperContract;
  createdAt: Scalars["DateTime"]["output"];
  currency: Scalars["String"]["output"];
  drawingTransactionHash: Scalars["String"]["output"];
  drawnTransactionHash?: Maybe<Scalars["String"]["output"]>;
  eventBlock: Scalars["BigNumber"]["output"];
  /**
   * An arry of flip result, with the length of `numberOfRounds`.
   * As flips progress, their outcome will be be added to the array of flip results.
   */
  flipResults?: Maybe<Array<FlipSide>>;
  id: Scalars["ID"]["output"];
  liquidityPoolFeeWei?: Maybe<Scalars["BigNumber"]["output"]>;
  numberOfRounds: Scalars["Int"]["output"];
  numberOfRoundsPlayed?: Maybe<Scalars["Int"]["output"]>;
  pickSide: FlipSide;
  playAmountWei: Scalars["BigNumber"]["output"];
  player: User;
  points: Scalars["BigNumber"]["output"];
  protocolFeeWei?: Maybe<Scalars["BigNumber"]["output"]>;
  returnMultiplier: Scalars["Float"]["output"];
  status: FlipperStatus;
  stopGainWei: Scalars["BigNumber"]["output"];
  stopLossWei: Scalars["BigNumber"]["output"];
  winningAmountWei?: Maybe<Scalars["BigNumber"]["output"]>;
};

/** Coin flip contract enum */
export type FlipperContract = "FLIPPER_V1_BLAST";

/**
 * Coin flip sort enum
 *
 * Default: NEWEST
 */
export type FlipperSort = "LUCKY_WIN_DESC" | "NEWEST" | "WIN_AMOUNT_DESC";

export type FlipperStatus = "CANCELLED" | "DRAWING" | "DRAWN" | "REFUNDED";

export type GameName = "DONT_FALL_IN" | "FLIPPER" | "LASER_BLAST" | "MOD" | "PTB" | "QUANTUM" | "YOLO";

/** ETH and USD statistics related to games. */
export type GameStats = {
  __typename?: "GameStats";
  totalGamesPlayed: Scalars["BigNumber"]["output"];
  totalPlayers: Scalars["BigNumber"]["output"];
  volumePlayedWei: Scalars["BigNumber"]["output"];
  volumeWonWei: Scalars["BigNumber"]["output"];
};

export type GameWinResult = {
  __typename?: "GameWinResult";
  amountWonWei: Scalars["BigNumber"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  currency: Currency;
  entryAmountWei: Scalars["BigNumber"]["output"];
  game: GameName;
  winner: User;
};

/** The rakebacks grouped by placement */
export type GroupedRakebacks = {
  __typename?: "GroupedRakebacks";
  history: Array<Rakeback>;
  locked: Array<Rakeback>;
  unlocked: Array<Rakeback>;
};

export type LaserBlast = {
  __typename?: "LaserBlast";
  amountPerRoundWei: Scalars["BigNumber"]["output"];
  contract: LaserBlastContract;
  createdAt: Scalars["DateTime"]["output"];
  currency: Scalars["String"]["output"];
  drawingTransactionHash: Scalars["String"]["output"];
  drawnTransactionHash?: Maybe<Scalars["String"]["output"]>;
  eventBlock: Scalars["BigNumber"]["output"];
  id: Scalars["ID"]["output"];
  laserTravels?: Maybe<Array<Array<LaserBlastDirection>>>;
  liquidityPoolFeeWei?: Maybe<Scalars["BigNumber"]["output"]>;
  numberOfRounds: Scalars["Int"]["output"];
  numberOfRoundsPlayed?: Maybe<Scalars["Int"]["output"]>;
  payouts?: Maybe<Array<Scalars["BigNumber"]["output"]>>;
  playAmountWei: Scalars["BigNumber"]["output"];
  player: User;
  points: Scalars["BigNumber"]["output"];
  protocolFeeWei?: Maybe<Scalars["BigNumber"]["output"]>;
  returnMultiplier: Scalars["Float"]["output"];
  riskLevel: LaserBlastRiskLevel;
  rowCount: Scalars["Int"]["output"];
  status: LaserBlastStatus;
  stopGainWei: Scalars["BigNumber"]["output"];
  stopLossWei: Scalars["BigNumber"]["output"];
  winningAmountWei?: Maybe<Scalars["BigNumber"]["output"]>;
};

/** LaserBlast contract enum */
export type LaserBlastContract = "LASER_BLAST_V1_BLAST";

export type LaserBlastDirection = "LEFT" | "RIGHT";

export type LaserBlastRiskLevel = "HIGH" | "LOW" | "MEDIUM";

/**
 * Laser blast games sort enum
 *
 * Default: NEWEST
 */
export type LaserBlastSort = "LUCKY_WIN_DESC" | "NEWEST" | "WIN_AMOUNT_DESC";

export type LaserBlastStatus = "CANCELLED" | "DRAWING" | "DRAWN" | "REFUNDED";

/** Leaderboard Sort Input */
export type LeaderboardSortInput = "DAILY_RANK_ASC" | "SEASON_RANK_ASC";

/**
 * Liquidity Pool entity.
 *
 * When a wallet is not provided `user` and `liquidityPoolSeason` will be null.
 * When `isActive` is false, the pool should not be shown to the user.
 */
export type LiquidityPool = {
  __typename?: "LiquidityPool";
  assetsBalanceVum: Scalars["BigNumber"]["output"];
  contract: LiquidityPoolContract;
  currency: Currency;
  isActive: Scalars["Boolean"]["output"];
  liquidityPoolConfig: LiquidityPoolConfig;
  liquidityPoolSeason?: Maybe<UserLiquidityPoolSeason>;
  pendingDepositsAmountVum: Scalars["BigNumber"]["output"];
  user?: Maybe<User>;
  userPoolPct: Scalars["Float"]["output"];
  userSharesValueVum: Scalars["BigNumber"]["output"];
};

/** These values represent the estimated new `dailyDroplets` and `multiplier` that the user will get after the deposit or redeem is finalized. */
export type LiquidityPoolActionPreview = {
  __typename?: "LiquidityPoolActionPreview";
  dailyDroplets: Scalars["Float"]["output"];
  multiplier: Scalars["Float"]["output"];
};

/**
 * depositFeeBps: The percentage of the deposit fee in basis points.
 * timelockDelaySecs: The time in seconds that the user has to wait before finalizing the deposit.
 * finalizationForAllDelaySecs: The time in seconds that the user has before the deposit can be finalized by anyone else.
 * finalizationIncentiveEthWei: The amount of ETH that the user has to temporarily lock in order while the deposit or redeem is pending.
 * minDepositVum/maxDepositVum: The minimum/maximum amount that the user can deposit.
 * assetsLimitVum: The maximum amount that the pool can hold.
 */
export type LiquidityPoolConfig = {
  __typename?: "LiquidityPoolConfig";
  assetsLimitVum: Scalars["BigNumber"]["output"];
  depositFeeBps: Scalars["Int"]["output"];
  finalizationForAllDelaySecs: Scalars["Int"]["output"];
  finalizationIncentiveEthWei: Scalars["BigNumber"]["output"];
  maxDepositVum: Scalars["BigNumber"]["output"];
  minDepositVum: Scalars["BigNumber"]["output"];
  timelockDelaySecs: Scalars["Int"]["output"];
};

export type LiquidityPoolContract = "GAMES_ETH_LP_V1_BLAST" | "GAMES_YOLO_LP_V1_BLAST";

/**
 * assetsAmountUsdVum: The USD value at the time of the deposit, the decimals are the same as for the asset.
 * isTransfer: true if the event is a transfer, false if it is a deposit or redeem.
 *  - The user triggering the transfer will see a withdraw event.
 *  - The user receiving the transfer will see a deposit event.
 *
 * The `createdAt` field is the pagination cursor.
 */
export type LiquidityPoolEvent = {
  __typename?: "LiquidityPoolEvent";
  assetsAmountUsd?: Maybe<Scalars["Float"]["output"]>;
  assetsAmountUsdVum?: Maybe<Scalars["BigNumber"]["output"]>;
  assetsAmountVum: Scalars["BigNumber"]["output"];
  contract: LiquidityPoolContract;
  createdAt: Scalars["DateTime"]["output"];
  currency: Currency;
  isTransfer: Scalars["Boolean"]["output"];
  transactionHash: Scalars["String"]["output"];
  type: LiquidityPoolEventType;
  user: User;
};

export type LiquidityPoolEventType = "DEPOSIT" | "REDEEM";

export type LiquidityPoolGame =
  | "DONT_FALL_IN_V1_BLAST"
  | "FLIPPER_V1_BLAST"
  | "LASER_BLAST_V1_BLAST"
  | "QUANTUM_V1_BLAST";

/**
 * Statistics for a liquidity pool games given for ETH and YOLO.
 *
 * The decimals for these stats are known, that's why they use the postfix `Wei`.
 */
export type LiquidityPoolGameStats = {
  __typename?: "LiquidityPoolGameStats";
  gameContract: LiquidityPoolGame;
  previousTotalFeesEth: Scalars["Float"]["output"];
  previousTotalFeesYolo: Scalars["Float"]["output"];
  previousTotalPlayedRoundsEth: Scalars["Int"]["output"];
  previousTotalPlayedRoundsYolo: Scalars["Int"]["output"];
  previousTotalVolumeEth: Scalars["Float"]["output"];
  previousTotalVolumeYolo: Scalars["Float"]["output"];
  totalFeesEth: Scalars["Float"]["output"];
  totalFeesYolo: Scalars["Float"]["output"];
  totalPlayedRoundsEth: Scalars["Int"]["output"];
  totalPlayedRoundsYolo: Scalars["Int"]["output"];
  totalVolumeEth: Scalars["Float"]["output"];
  totalVolumeYolo: Scalars["Float"]["output"];
};

export type LiquidityPoolGranularity = "_1D" | "_1H" | "_1M" | "_1W" | "_5m";

export type LiquidityPoolInterval = "MAX" | "_1D" | "_1M" | "_1W";

export type LiquidityPoolMetric =
  | "LP_FEES_EARNED"
  | "LP_FLOW"
  | "LP_GAMES_ROUNDS_PLAYED"
  | "LP_GAMES_VOLUME"
  | "LP_NET_FLOW"
  | "LP_PNL"
  | "USER_LP_PNL"
  | "USER_LP_POSITION";

/**
 * targetEntries: The number of entries to return, at least. We will try to be as close as possible to that target.
 *
 * If there is not enough data the entries will be less, if there is enough data we can still return more data points/entries.
 */
export type LiquidityPoolMetricsFilterInput = {
  contract: LiquidityPoolContract;
  targetEntries?: InputMaybe<Scalars["Int"]["input"]>;
  timeInterval: LiquidityPoolInterval;
};

export type LiquidityPoolOverviewFilterInput = {
  timeInterval: LiquidityPoolInterval;
};

/**
 * The list of pending deposit/redeem the user has.
 *
 * There can be at most 1 pending deposit and 1 pending redeem per user (even across different pools).
 *
 * expectedAssetsAmountVum: The amount of assets that the user expects to receive after the deposit or redeem is finalized, actual can be different.
 * finalizationIncentiveEthWei: The amount of ETH that the user has to temporarily locked.
 * unlocksAt: The time when the deposit or redeem can be finalized by the user.
 * protectedUntil: The time when the deposit or redeem can be finalized by anyone else.
 */
export type LiquidityPoolPendingAction = {
  __typename?: "LiquidityPoolPendingAction";
  contract: LiquidityPoolContract;
  currency: Currency;
  expectedAssetsAmountVum: Scalars["BigNumber"]["output"];
  finalizationIncentiveEthWei: Scalars["BigNumber"]["output"];
  protectedUntil: Scalars["DateTime"]["output"];
  startedAt: Scalars["DateTime"]["output"];
  transactionHash: Scalars["String"]["output"];
  type: LiquidityPoolEventType;
  unlocksAt: Scalars["DateTime"]["output"];
};

/**
 * The timeseries data for a liquidity pool metric.
 *
 * The field `gameContract` can be null when the metric is not related to a specific game.
 * The field `lastAlignedAt` is the pagination cursor.
 * The field `data` is a list of lists of numbers for the chart. For example `[x, y]`, where x is a unix timestamp in milliseconds and y is the value.
 *
 * For the chart `LP_FLOW` the data is a list of lists of numbers for the chart. For example `[x, y1, y2]`, where y1 is the deposited amount and y2 is the redeemed amount.
 * The numeric chart values are already formatted into the correct number of decimals for the given currency.
 */
export type LiquidityPoolTimeseries = {
  __typename?: "LiquidityPoolTimeseries";
  currency: Currency;
  currentCumulativeTotal: Scalars["Float"]["output"];
  data: Array<Array<Scalars["Float"]["output"]>>;
  dataGranularity: LiquidityPoolGranularity;
  gameContract?: Maybe<LiquidityPoolGame>;
  intervalTotal: Scalars["Float"]["output"];
  lastAlignedAt: Scalars["DateTime"]["output"];
  metricType: LiquidityPoolMetric;
  previousCumulativeTotal: Scalars["Float"]["output"];
};

export type LiquidityPoolUserMetricsFilterInput = {
  contract: LiquidityPoolContract;
  targetEntries?: InputMaybe<Scalars["Int"]["input"]>;
  timeInterval: LiquidityPoolInterval;
};

/** Main lottery entity, with lottery info (mutated fields) */
export type Lottery = {
  __typename?: "Lottery";
  /** Lottery entries for all users or specific users */
  entries: Array<LotteryEntry>;
  id: Scalars["BigNumber"]["output"];
  /** Lottery execution (drawing) date-time. Includes the lottery timestamp. */
  lotteryDate: Scalars["DateTime"]["output"];
  /**
   * Lottery Prizes with associated winners if present.
   * Optional filter on the address to get the prize won by the user.
   */
  prizes: Array<LotteryPrize>;
  status: LotteryStatus;
  /** Total number of ticket entries */
  totalEntries: Scalars["BigNumber"]["output"];
  /** Total number of players participated in the lottery */
  totalPlayers: Scalars["BigNumber"]["output"];
  /** Total ETH pot value */
  totalValue: Scalars["BigNumber"]["output"];
  type: LotteryType;
  vrfLink?: Maybe<Scalars["String"]["output"]>;
};

/** Main lottery entity, with lottery info (mutated fields) */
export type LotteryEntriesArgs = {
  address?: InputMaybe<Scalars["Address"]["input"]>;
  pagination?: InputMaybe<PaginationInput>;
};

/** Main lottery entity, with lottery info (mutated fields) */
export type LotteryPrizesArgs = {
  address?: InputMaybe<Scalars["Address"]["input"]>;
};

/** Lottery entry; user and ticket entry amount */
export type LotteryEntry = {
  __typename?: "LotteryEntry";
  amount: Scalars["Int"]["output"];
  /**
   * Unique identifier of the lottery entry.
   * This field is used as the pagination cursor.
   */
  id: Scalars["Int"]["output"];
  player: User;
  rank: Scalars["Int"]["output"];
};

/** Lottery prize; prize(ETH) amount, winner (if lottery is finished) */
export type LotteryPrize = {
  __typename?: "LotteryPrize";
  amount: Scalars["BigNumber"]["output"];
  claimHash?: Maybe<Scalars["String"]["output"]>;
  rank: Scalars["Int"]["output"];
  winner?: Maybe<User>;
  winnerEntryAmount?: Maybe<Scalars["Int"]["output"]>;
};

export type LotteryStatus = "DRAWN" | "OPEN";

/** Balance remaining or `0` if no tickets have been assigned */
export type LotteryTicketAllocation = {
  __typename?: "LotteryTicketAllocation";
  earned: Scalars["Int"]["output"];
  remaining: Scalars["Int"]["output"];
  spent: Scalars["Int"]["output"];
};

export type LotteryType = "DAILY" | "WEEKLY";

/** Result of a lucky spin */
export type LuckySpinEntry = {
  __typename?: "LuckySpinEntry";
  createdAt: Scalars["DateTime"]["output"];
  drawnAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  prize?: Maybe<LuckySpinPrize>;
  ticketAmount: Scalars["Int"]["output"];
  /** Link to drand's round */
  vrfUrl?: Maybe<Scalars["String"]["output"]>;
};

/** Prize from a lucky spin. Can be an amount of ETH or season points */
export type LuckySpinPrize = {
  __typename?: "LuckySpinPrize";
  amount: Scalars["BigNumber"]["output"];
  id: Scalars["BigNumber"]["output"];
  type: PrizeType;
};

/** Aggregated prize totals for a user. */
export type LuckySpinPrizeTotals = {
  __typename?: "LuckySpinPrizeTotals";
  blastGoldPendingDistribution: Scalars["Float"]["output"];
  blastGoldTotal: Scalars["Float"]["output"];
  ethWei: Scalars["BigNumber"]["output"];
  pointsUntilNextTicket: Scalars["BigNumber"]["output"];
  seasonPoints: Scalars["BigNumber"]["output"];
  spentTickets: Scalars["BigNumber"]["output"];
  yoloWei: Scalars["BigNumber"]["output"];
};

/** Media Data */
export type MediaData = {
  __typename?: "MediaData";
  contentType?: Maybe<Scalars["String"]["output"]>;
  src: Scalars["String"]["output"];
};

/** Mid Season 2 Gold Distribution */
export type MidSeasonGoldDistribution = {
  __typename?: "MidSeasonGoldDistribution";
  dropletsGold?: Maybe<Scalars["Float"]["output"]>;
  gamesGold?: Maybe<Scalars["Float"]["output"]>;
  yYoloGold?: Maybe<Scalars["Float"]["output"]>;
};

/** Defined Types for Milestones */
export type MilestoneCode =
  | "DONT_FALL_IN_ETH"
  | "DONT_FALL_IN_ROUND"
  | "DONT_FALL_IN_WIN"
  | "DONT_FALL_IN_WIN_STREAK"
  | "FLIPPER_ETH"
  | "FLIPPER_ROUND"
  | "FLIPPER_WIN"
  | "FLIPPER_WIN_STREAK"
  | "LASER_BLAST_ETH"
  | "LASER_BLAST_ROUND"
  | "MORD_ETH"
  | "MORD_ROUND"
  | "MORD_WIN"
  | "MORD_WIN_STREAK"
  | "POINTS"
  | "PROFILE_SETUP"
  | "PTB_ETH"
  | "PTB_FUTURE_ROUND"
  | "PTB_WIN"
  | "PTB_WIN_STREAK"
  | "QUANTUM_ETH"
  | "QUANTUM_ROUND"
  | "QUANTUM_WIN"
  | "QUANTUM_WIN_STREAK"
  | "YOLO_ETH"
  | "YOLO_FUTURE_ROUND"
  | "YOLO_ROUND"
  | "YOLO_WIN";

/** Defined Codes for Milestones */
export type MilestoneLevelCode =
  | "AVATAR"
  | "BIO"
  | "DISCORD"
  | "DONT_FALL_IN_ETH_1"
  | "DONT_FALL_IN_ETH_2"
  | "DONT_FALL_IN_ETH_3"
  | "DONT_FALL_IN_ETH_4"
  | "DONT_FALL_IN_ETH_5"
  | "DONT_FALL_IN_ETH_6"
  | "DONT_FALL_IN_ETH_7"
  | "DONT_FALL_IN_ETH_8"
  | "DONT_FALL_IN_ETH_9"
  | "DONT_FALL_IN_ETH_10"
  | "DONT_FALL_IN_ETH_11"
  | "DONT_FALL_IN_ETH_12"
  | "DONT_FALL_IN_ETH_13"
  | "DONT_FALL_IN_ETH_14"
  | "DONT_FALL_IN_ETH_15"
  | "DONT_FALL_IN_ETH_16"
  | "DONT_FALL_IN_ETH_17"
  | "DONT_FALL_IN_ETH_18"
  | "DONT_FALL_IN_ETH_19"
  | "DONT_FALL_IN_ETH_20"
  | "DONT_FALL_IN_ROUND_1"
  | "DONT_FALL_IN_ROUND_2"
  | "DONT_FALL_IN_ROUND_3"
  | "DONT_FALL_IN_ROUND_4"
  | "DONT_FALL_IN_ROUND_5"
  | "DONT_FALL_IN_ROUND_6"
  | "DONT_FALL_IN_ROUND_7"
  | "DONT_FALL_IN_ROUND_8"
  | "DONT_FALL_IN_ROUND_9"
  | "DONT_FALL_IN_ROUND_10"
  | "DONT_FALL_IN_ROUND_11"
  | "DONT_FALL_IN_ROUND_12"
  | "DONT_FALL_IN_ROUND_13"
  | "DONT_FALL_IN_ROUND_14"
  | "DONT_FALL_IN_ROUND_15"
  | "DONT_FALL_IN_ROUND_16"
  | "DONT_FALL_IN_ROUND_17"
  | "DONT_FALL_IN_ROUND_18"
  | "DONT_FALL_IN_ROUND_19"
  | "DONT_FALL_IN_ROUND_20"
  | "DONT_FALL_IN_WIN_1"
  | "DONT_FALL_IN_WIN_2"
  | "DONT_FALL_IN_WIN_3"
  | "DONT_FALL_IN_WIN_4"
  | "DONT_FALL_IN_WIN_5"
  | "DONT_FALL_IN_WIN_6"
  | "DONT_FALL_IN_WIN_7"
  | "DONT_FALL_IN_WIN_8"
  | "DONT_FALL_IN_WIN_9"
  | "DONT_FALL_IN_WIN_10"
  | "DONT_FALL_IN_WIN_11"
  | "DONT_FALL_IN_WIN_12"
  | "DONT_FALL_IN_WIN_13"
  | "DONT_FALL_IN_WIN_14"
  | "DONT_FALL_IN_WIN_15"
  | "DONT_FALL_IN_WIN_16"
  | "DONT_FALL_IN_WIN_17"
  | "DONT_FALL_IN_WIN_18"
  | "DONT_FALL_IN_WIN_19"
  | "DONT_FALL_IN_WIN_20"
  | "DONT_FALL_IN_WIN_STREAK_1"
  | "DONT_FALL_IN_WIN_STREAK_2"
  | "DONT_FALL_IN_WIN_STREAK_3"
  | "DONT_FALL_IN_WIN_STREAK_4"
  | "DONT_FALL_IN_WIN_STREAK_5"
  | "DONT_FALL_IN_WIN_STREAK_6"
  | "DONT_FALL_IN_WIN_STREAK_7"
  | "DONT_FALL_IN_WIN_STREAK_8"
  | "DONT_FALL_IN_WIN_STREAK_9"
  | "DONT_FALL_IN_WIN_STREAK_10"
  | "DONT_FALL_IN_WIN_STREAK_11"
  | "DONT_FALL_IN_WIN_STREAK_12"
  | "DONT_FALL_IN_WIN_STREAK_13"
  | "DONT_FALL_IN_WIN_STREAK_14"
  | "DONT_FALL_IN_WIN_STREAK_15"
  | "DONT_FALL_IN_WIN_STREAK_16"
  | "DONT_FALL_IN_WIN_STREAK_17"
  | "DONT_FALL_IN_WIN_STREAK_18"
  | "DONT_FALL_IN_WIN_STREAK_19"
  | "DONT_FALL_IN_WIN_STREAK_20"
  | "FLIPPER_ETH_1"
  | "FLIPPER_ETH_2"
  | "FLIPPER_ETH_3"
  | "FLIPPER_ETH_4"
  | "FLIPPER_ETH_5"
  | "FLIPPER_ETH_6"
  | "FLIPPER_ETH_7"
  | "FLIPPER_ETH_8"
  | "FLIPPER_ETH_9"
  | "FLIPPER_ETH_10"
  | "FLIPPER_ETH_11"
  | "FLIPPER_ETH_12"
  | "FLIPPER_ETH_13"
  | "FLIPPER_ETH_14"
  | "FLIPPER_ETH_15"
  | "FLIPPER_ETH_16"
  | "FLIPPER_ETH_17"
  | "FLIPPER_ETH_18"
  | "FLIPPER_ETH_19"
  | "FLIPPER_ETH_20"
  | "FLIPPER_ROUND_1"
  | "FLIPPER_ROUND_2"
  | "FLIPPER_ROUND_3"
  | "FLIPPER_ROUND_4"
  | "FLIPPER_ROUND_5"
  | "FLIPPER_ROUND_6"
  | "FLIPPER_ROUND_7"
  | "FLIPPER_ROUND_8"
  | "FLIPPER_ROUND_9"
  | "FLIPPER_ROUND_10"
  | "FLIPPER_ROUND_11"
  | "FLIPPER_ROUND_12"
  | "FLIPPER_ROUND_13"
  | "FLIPPER_ROUND_14"
  | "FLIPPER_ROUND_15"
  | "FLIPPER_ROUND_16"
  | "FLIPPER_ROUND_17"
  | "FLIPPER_ROUND_18"
  | "FLIPPER_ROUND_19"
  | "FLIPPER_ROUND_20"
  | "FLIPPER_WIN_1"
  | "FLIPPER_WIN_2"
  | "FLIPPER_WIN_3"
  | "FLIPPER_WIN_4"
  | "FLIPPER_WIN_5"
  | "FLIPPER_WIN_6"
  | "FLIPPER_WIN_7"
  | "FLIPPER_WIN_8"
  | "FLIPPER_WIN_9"
  | "FLIPPER_WIN_10"
  | "FLIPPER_WIN_11"
  | "FLIPPER_WIN_12"
  | "FLIPPER_WIN_13"
  | "FLIPPER_WIN_14"
  | "FLIPPER_WIN_15"
  | "FLIPPER_WIN_16"
  | "FLIPPER_WIN_17"
  | "FLIPPER_WIN_18"
  | "FLIPPER_WIN_19"
  | "FLIPPER_WIN_20"
  | "FLIPPER_WIN_STREAK_1"
  | "FLIPPER_WIN_STREAK_2"
  | "FLIPPER_WIN_STREAK_3"
  | "FLIPPER_WIN_STREAK_4"
  | "FLIPPER_WIN_STREAK_5"
  | "FLIPPER_WIN_STREAK_6"
  | "FLIPPER_WIN_STREAK_7"
  | "FLIPPER_WIN_STREAK_8"
  | "FLIPPER_WIN_STREAK_9"
  | "FLIPPER_WIN_STREAK_10"
  | "LASER_BLAST_ETH_1"
  | "LASER_BLAST_ETH_2"
  | "LASER_BLAST_ETH_3"
  | "LASER_BLAST_ETH_4"
  | "LASER_BLAST_ETH_5"
  | "LASER_BLAST_ETH_6"
  | "LASER_BLAST_ETH_7"
  | "LASER_BLAST_ETH_8"
  | "LASER_BLAST_ETH_9"
  | "LASER_BLAST_ETH_10"
  | "LASER_BLAST_ETH_11"
  | "LASER_BLAST_ETH_12"
  | "LASER_BLAST_ETH_13"
  | "LASER_BLAST_ETH_14"
  | "LASER_BLAST_ETH_15"
  | "LASER_BLAST_ETH_16"
  | "LASER_BLAST_ETH_17"
  | "LASER_BLAST_ETH_18"
  | "LASER_BLAST_ETH_19"
  | "LASER_BLAST_ETH_20"
  | "LASER_BLAST_ROUND_1"
  | "LASER_BLAST_ROUND_2"
  | "LASER_BLAST_ROUND_3"
  | "LASER_BLAST_ROUND_4"
  | "LASER_BLAST_ROUND_5"
  | "LASER_BLAST_ROUND_6"
  | "LASER_BLAST_ROUND_7"
  | "LASER_BLAST_ROUND_8"
  | "LASER_BLAST_ROUND_9"
  | "LASER_BLAST_ROUND_10"
  | "LASER_BLAST_ROUND_11"
  | "LASER_BLAST_ROUND_12"
  | "LASER_BLAST_ROUND_13"
  | "LASER_BLAST_ROUND_14"
  | "LASER_BLAST_ROUND_15"
  | "LASER_BLAST_ROUND_16"
  | "LASER_BLAST_ROUND_17"
  | "LASER_BLAST_ROUND_18"
  | "LASER_BLAST_ROUND_19"
  | "LASER_BLAST_ROUND_20"
  | "MORD_ETH_1"
  | "MORD_ETH_2"
  | "MORD_ETH_3"
  | "MORD_ETH_4"
  | "MORD_ETH_5"
  | "MORD_ETH_6"
  | "MORD_ETH_7"
  | "MORD_ETH_8"
  | "MORD_ETH_9"
  | "MORD_ETH_10"
  | "MORD_ETH_11"
  | "MORD_ETH_12"
  | "MORD_ETH_13"
  | "MORD_ETH_14"
  | "MORD_ETH_15"
  | "MORD_ETH_16"
  | "MORD_ETH_17"
  | "MORD_ETH_18"
  | "MORD_ETH_19"
  | "MORD_ETH_20"
  /** Moon or Doom */
  | "MORD_ROUND_1"
  | "MORD_ROUND_2"
  | "MORD_ROUND_3"
  | "MORD_ROUND_4"
  | "MORD_ROUND_5"
  | "MORD_ROUND_6"
  | "MORD_ROUND_7"
  | "MORD_ROUND_8"
  | "MORD_ROUND_9"
  | "MORD_ROUND_10"
  | "MORD_ROUND_11"
  | "MORD_ROUND_12"
  | "MORD_ROUND_13"
  | "MORD_ROUND_14"
  | "MORD_ROUND_15"
  | "MORD_ROUND_16"
  | "MORD_ROUND_17"
  | "MORD_ROUND_18"
  | "MORD_ROUND_19"
  | "MORD_ROUND_20"
  | "MORD_WIN_1"
  | "MORD_WIN_2"
  | "MORD_WIN_3"
  | "MORD_WIN_4"
  | "MORD_WIN_5"
  | "MORD_WIN_6"
  | "MORD_WIN_7"
  | "MORD_WIN_8"
  | "MORD_WIN_9"
  | "MORD_WIN_10"
  | "MORD_WIN_11"
  | "MORD_WIN_12"
  | "MORD_WIN_13"
  | "MORD_WIN_14"
  | "MORD_WIN_15"
  | "MORD_WIN_16"
  | "MORD_WIN_17"
  | "MORD_WIN_18"
  | "MORD_WIN_19"
  | "MORD_WIN_20"
  | "MORD_WIN_STREAK_1"
  | "MORD_WIN_STREAK_2"
  | "MORD_WIN_STREAK_3"
  | "MORD_WIN_STREAK_4"
  | "MORD_WIN_STREAK_5"
  | "MORD_WIN_STREAK_6"
  | "MORD_WIN_STREAK_7"
  | "MORD_WIN_STREAK_8"
  | "MORD_WIN_STREAK_9"
  | "MORD_WIN_STREAK_10"
  /** Points */
  | "POINTS_1"
  | "POINTS_2"
  | "POINTS_3"
  | "POINTS_4"
  | "POINTS_5"
  | "POINTS_6"
  | "POINTS_7"
  | "POINTS_8"
  | "POINTS_9"
  | "POINTS_10"
  | "POINTS_11"
  | "POINTS_12"
  | "POINTS_13"
  | "POINTS_14"
  | "POINTS_15"
  | "POINTS_16"
  | "POINTS_17"
  | "POINTS_18"
  | "POINTS_19"
  | "POINTS_20"
  | "POINTS_21"
  | "POINTS_22"
  | "POINTS_23"
  | "POINTS_24"
  | "POINTS_25"
  /** Profile */
  | "PROFILE_SETUP"
  | "PTB_ETH_1"
  | "PTB_ETH_2"
  | "PTB_ETH_3"
  | "PTB_ETH_4"
  | "PTB_ETH_5"
  | "PTB_ETH_6"
  | "PTB_ETH_7"
  | "PTB_ETH_8"
  | "PTB_ETH_9"
  | "PTB_ETH_10"
  | "PTB_ETH_11"
  | "PTB_ETH_12"
  | "PTB_ETH_13"
  | "PTB_ETH_14"
  | "PTB_ETH_15"
  | "PTB_ETH_16"
  | "PTB_ETH_17"
  | "PTB_ETH_18"
  | "PTB_ETH_19"
  | "PTB_ETH_20"
  | "PTB_FUTURE_ROUND_1"
  | "PTB_FUTURE_ROUND_2"
  | "PTB_FUTURE_ROUND_3"
  | "PTB_FUTURE_ROUND_4"
  | "PTB_FUTURE_ROUND_5"
  | "PTB_FUTURE_ROUND_6"
  | "PTB_FUTURE_ROUND_7"
  | "PTB_FUTURE_ROUND_8"
  | "PTB_FUTURE_ROUND_9"
  | "PTB_FUTURE_ROUND_10"
  | "PTB_FUTURE_ROUND_11"
  | "PTB_FUTURE_ROUND_12"
  | "PTB_FUTURE_ROUND_13"
  | "PTB_FUTURE_ROUND_14"
  | "PTB_FUTURE_ROUND_15"
  | "PTB_FUTURE_ROUND_16"
  | "PTB_FUTURE_ROUND_17"
  | "PTB_FUTURE_ROUND_18"
  | "PTB_FUTURE_ROUND_19"
  | "PTB_FUTURE_ROUND_20"
  /** Poke The Bear */
  | "PTB_WIN_1"
  | "PTB_WIN_2"
  | "PTB_WIN_3"
  | "PTB_WIN_4"
  | "PTB_WIN_5"
  | "PTB_WIN_6"
  | "PTB_WIN_7"
  | "PTB_WIN_8"
  | "PTB_WIN_9"
  | "PTB_WIN_10"
  | "PTB_WIN_11"
  | "PTB_WIN_12"
  | "PTB_WIN_13"
  | "PTB_WIN_14"
  | "PTB_WIN_15"
  | "PTB_WIN_16"
  | "PTB_WIN_17"
  | "PTB_WIN_18"
  | "PTB_WIN_19"
  | "PTB_WIN_20"
  | "PTB_WIN_STREAK_1"
  | "PTB_WIN_STREAK_2"
  | "PTB_WIN_STREAK_3"
  | "PTB_WIN_STREAK_4"
  | "PTB_WIN_STREAK_5"
  | "PTB_WIN_STREAK_6"
  | "PTB_WIN_STREAK_7"
  | "PTB_WIN_STREAK_8"
  | "PTB_WIN_STREAK_9"
  | "PTB_WIN_STREAK_10"
  | "PTB_WIN_STREAK_11"
  | "PTB_WIN_STREAK_12"
  | "PTB_WIN_STREAK_13"
  | "PTB_WIN_STREAK_14"
  | "PTB_WIN_STREAK_15"
  | "PTB_WIN_STREAK_16"
  | "PTB_WIN_STREAK_17"
  | "PTB_WIN_STREAK_18"
  | "PTB_WIN_STREAK_19"
  | "PTB_WIN_STREAK_20"
  | "QUANTUM_ETH_1"
  | "QUANTUM_ETH_2"
  | "QUANTUM_ETH_3"
  | "QUANTUM_ETH_4"
  | "QUANTUM_ETH_5"
  | "QUANTUM_ETH_6"
  | "QUANTUM_ETH_7"
  | "QUANTUM_ETH_8"
  | "QUANTUM_ETH_9"
  | "QUANTUM_ETH_10"
  | "QUANTUM_ETH_11"
  | "QUANTUM_ETH_12"
  | "QUANTUM_ETH_13"
  | "QUANTUM_ETH_14"
  | "QUANTUM_ETH_15"
  | "QUANTUM_ETH_16"
  | "QUANTUM_ETH_17"
  | "QUANTUM_ETH_18"
  | "QUANTUM_ETH_19"
  | "QUANTUM_ETH_20"
  | "QUANTUM_ROUND_1"
  | "QUANTUM_ROUND_2"
  | "QUANTUM_ROUND_3"
  | "QUANTUM_ROUND_4"
  | "QUANTUM_ROUND_5"
  | "QUANTUM_ROUND_6"
  | "QUANTUM_ROUND_7"
  | "QUANTUM_ROUND_8"
  | "QUANTUM_ROUND_9"
  | "QUANTUM_ROUND_10"
  | "QUANTUM_ROUND_11"
  | "QUANTUM_ROUND_12"
  | "QUANTUM_ROUND_13"
  | "QUANTUM_ROUND_14"
  | "QUANTUM_ROUND_15"
  | "QUANTUM_ROUND_16"
  | "QUANTUM_ROUND_17"
  | "QUANTUM_ROUND_18"
  | "QUANTUM_ROUND_19"
  | "QUANTUM_ROUND_20"
  | "QUANTUM_WIN_1"
  | "QUANTUM_WIN_2"
  | "QUANTUM_WIN_3"
  | "QUANTUM_WIN_4"
  | "QUANTUM_WIN_5"
  | "QUANTUM_WIN_6"
  | "QUANTUM_WIN_7"
  | "QUANTUM_WIN_8"
  | "QUANTUM_WIN_9"
  | "QUANTUM_WIN_10"
  | "QUANTUM_WIN_11"
  | "QUANTUM_WIN_12"
  | "QUANTUM_WIN_13"
  | "QUANTUM_WIN_14"
  | "QUANTUM_WIN_15"
  | "QUANTUM_WIN_16"
  | "QUANTUM_WIN_17"
  | "QUANTUM_WIN_18"
  | "QUANTUM_WIN_19"
  | "QUANTUM_WIN_20"
  | "QUANTUM_WIN_STREAK_1"
  | "QUANTUM_WIN_STREAK_2"
  | "QUANTUM_WIN_STREAK_3"
  | "QUANTUM_WIN_STREAK_4"
  | "QUANTUM_WIN_STREAK_5"
  | "QUANTUM_WIN_STREAK_6"
  | "QUANTUM_WIN_STREAK_7"
  | "QUANTUM_WIN_STREAK_8"
  | "QUANTUM_WIN_STREAK_9"
  | "QUANTUM_WIN_STREAK_10"
  | "QUANTUM_WIN_STREAK_11"
  | "QUANTUM_WIN_STREAK_12"
  | "QUANTUM_WIN_STREAK_13"
  | "QUANTUM_WIN_STREAK_14"
  | "QUANTUM_WIN_STREAK_15"
  | "QUANTUM_WIN_STREAK_16"
  | "QUANTUM_WIN_STREAK_17"
  | "QUANTUM_WIN_STREAK_18"
  | "QUANTUM_WIN_STREAK_19"
  | "QUANTUM_WIN_STREAK_20"
  | "TWITTER"
  | "USERNAME"
  | "YOLO_ETH_1"
  | "YOLO_ETH_2"
  | "YOLO_ETH_3"
  | "YOLO_ETH_4"
  | "YOLO_ETH_5"
  | "YOLO_ETH_6"
  | "YOLO_ETH_7"
  | "YOLO_ETH_8"
  | "YOLO_ETH_9"
  | "YOLO_ETH_10"
  | "YOLO_ETH_11"
  | "YOLO_ETH_12"
  | "YOLO_ETH_13"
  | "YOLO_ETH_14"
  | "YOLO_ETH_15"
  | "YOLO_ETH_16"
  | "YOLO_ETH_17"
  | "YOLO_ETH_18"
  | "YOLO_ETH_19"
  | "YOLO_ETH_20"
  | "YOLO_FUTURE_ROUND_1"
  | "YOLO_FUTURE_ROUND_2"
  | "YOLO_FUTURE_ROUND_3"
  | "YOLO_FUTURE_ROUND_4"
  | "YOLO_FUTURE_ROUND_5"
  | "YOLO_FUTURE_ROUND_6"
  | "YOLO_FUTURE_ROUND_7"
  | "YOLO_FUTURE_ROUND_8"
  | "YOLO_FUTURE_ROUND_9"
  | "YOLO_FUTURE_ROUND_10"
  | "YOLO_FUTURE_ROUND_11"
  | "YOLO_FUTURE_ROUND_12"
  | "YOLO_FUTURE_ROUND_13"
  | "YOLO_FUTURE_ROUND_14"
  | "YOLO_FUTURE_ROUND_15"
  | "YOLO_FUTURE_ROUND_16"
  | "YOLO_FUTURE_ROUND_17"
  | "YOLO_FUTURE_ROUND_18"
  | "YOLO_FUTURE_ROUND_19"
  | "YOLO_FUTURE_ROUND_20"
  /** Yolo */
  | "YOLO_ROUND_1"
  | "YOLO_ROUND_2"
  | "YOLO_ROUND_3"
  | "YOLO_ROUND_4"
  | "YOLO_ROUND_5"
  | "YOLO_ROUND_6"
  | "YOLO_ROUND_7"
  | "YOLO_ROUND_8"
  | "YOLO_ROUND_9"
  | "YOLO_ROUND_10"
  | "YOLO_ROUND_11"
  | "YOLO_ROUND_12"
  | "YOLO_ROUND_13"
  | "YOLO_ROUND_14"
  | "YOLO_ROUND_15"
  | "YOLO_ROUND_16"
  | "YOLO_ROUND_17"
  | "YOLO_ROUND_18"
  | "YOLO_ROUND_19"
  | "YOLO_ROUND_20"
  | "YOLO_WIN_1"
  | "YOLO_WIN_2"
  | "YOLO_WIN_3"
  | "YOLO_WIN_4"
  | "YOLO_WIN_5"
  | "YOLO_WIN_6"
  | "YOLO_WIN_7"
  | "YOLO_WIN_8"
  | "YOLO_WIN_9"
  | "YOLO_WIN_10"
  | "YOLO_WIN_11"
  | "YOLO_WIN_12"
  | "YOLO_WIN_13"
  | "YOLO_WIN_14"
  | "YOLO_WIN_15"
  | "YOLO_WIN_16"
  | "YOLO_WIN_17"
  | "YOLO_WIN_18"
  | "YOLO_WIN_19"
  | "YOLO_WIN_20";

/** All quests (groups of milestones) available to a user. */
export type Milestones = {
  __typename?: "Milestones";
  DONT_FALL_IN_ETH: Array<UserMilestoneLevel>;
  DONT_FALL_IN_ROUND: Array<UserMilestoneLevel>;
  DONT_FALL_IN_WIN: Array<UserMilestoneLevel>;
  DONT_FALL_IN_WIN_STREAK: Array<UserMilestoneLevel>;
  FLIPPER_ETH: Array<UserMilestoneLevel>;
  FLIPPER_ROUND: Array<UserMilestoneLevel>;
  FLIPPER_WIN: Array<UserMilestoneLevel>;
  FLIPPER_WIN_STREAK: Array<UserMilestoneLevel>;
  LASER_BLAST_ETH: Array<UserMilestoneLevel>;
  LASER_BLAST_ROUND: Array<UserMilestoneLevel>;
  MORD_ETH: Array<UserMilestoneLevel>;
  MORD_ROUND: Array<UserMilestoneLevel>;
  MORD_WIN: Array<UserMilestoneLevel>;
  MORD_WIN_STREAK: Array<UserMilestoneLevel>;
  POINTS: Array<UserMilestoneLevel>;
  PROFILE_SETUP: Array<UserMilestoneLevel>;
  PTB_ETH: Array<UserMilestoneLevel>;
  PTB_FUTURE_ROUND: Array<UserMilestoneLevel>;
  PTB_WIN: Array<UserMilestoneLevel>;
  PTB_WIN_STREAK: Array<UserMilestoneLevel>;
  QUANTUM_ETH: Array<UserMilestoneLevel>;
  QUANTUM_ROUND: Array<UserMilestoneLevel>;
  QUANTUM_WIN: Array<UserMilestoneLevel>;
  QUANTUM_WIN_STREAK: Array<UserMilestoneLevel>;
  YOLO_ETH: Array<UserMilestoneLevel>;
  YOLO_FUTURE_ROUND: Array<UserMilestoneLevel>;
  YOLO_ROUND: Array<UserMilestoneLevel>;
  YOLO_WIN: Array<UserMilestoneLevel>;
};

/** MoonOrDoom asset information */
export type MoDAsset = {
  __typename?: "MoDAsset";
  contract: MoDContract;
  createdAt: Scalars["DateTime"]["output"];
  pairCode: MoDAssetPair;
  pythPriceId: Scalars["String"]["output"];
};

/** Supported asset pairs. */
export type MoDAssetPair = "BTCUSD" | "ETHUSD";

/** Supported asset pairs */
export type MoDContract = "MOON_OR_DOOM_BTCUSD_V1_BLAST" | "MOON_OR_DOOM_ETHUSD_V1_BLAST";

/** MoonOrDoom Entry */
export type MoDEntry = {
  __typename?: "MoDEntry";
  amount: Scalars["BigNumber"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  gemsEarned?: Maybe<Scalars["BigNumber"]["output"]>;
  moonPosition: Scalars["Boolean"]["output"];
  payoutAmount?: Maybe<Scalars["BigNumber"]["output"]>;
  player: User;
};

/** Moon or Doom round filter options */
export type MoDFilterInput = {
  contract?: InputMaybe<MoDContract>;
  player?: InputMaybe<Scalars["Address"]["input"]>;
  status?: InputMaybe<Array<MoDRoundStatus>>;
};

/** MoonOrDoom historical price backfill data */
export type MoDHistoricalPrice = {
  __typename?: "MoDHistoricalPrice";
  price: Scalars["BigNumber"]["output"];
  timestamp: Scalars["Int"]["output"];
};

/** Number of moons and dooms during the specific time period */
export type MoDPeriodResult = {
  __typename?: "MoDPeriodResult";
  numOfDooms: Scalars["Int"]["output"];
  numOfMoons: Scalars["Int"]["output"];
};

/** Moon or Doom result */
export type MoDResult = "DOOM" | "HOUSE" | "MOON";

/** MoonOrDoom round */
export type MoDRound = {
  __typename?: "MoDRound";
  assetPairCode: MoDAssetPair;
  closeOracleId?: Maybe<Scalars["BigNumber"]["output"]>;
  closePrice?: Maybe<Scalars["BigNumber"]["output"]>;
  closedAt?: Maybe<Scalars["DateTime"]["output"]>;
  doomAmount?: Maybe<Scalars["BigNumber"]["output"]>;
  doomPayoutRatio?: Maybe<Scalars["Float"]["output"]>;
  /** When player is provided, return the player's entry */
  entries?: Maybe<Array<MoDEntry>>;
  id: Scalars["Int"]["output"];
  lockOracleId?: Maybe<Scalars["BigNumber"]["output"]>;
  lockPrice?: Maybe<Scalars["BigNumber"]["output"]>;
  lockedAt: Scalars["DateTime"]["output"];
  moonAmount?: Maybe<Scalars["BigNumber"]["output"]>;
  moonPayoutRatio?: Maybe<Scalars["Float"]["output"]>;
  onChainId: Scalars["Int"]["output"];
  oracleCalled: Scalars["Boolean"]["output"];
  result?: Maybe<MoDRoundResult>;
  rewardAmount?: Maybe<Scalars["BigNumber"]["output"]>;
  rewardBaseCalAmount?: Maybe<Scalars["BigNumber"]["output"]>;
  /** Setting used for this round */
  setting: MoDRoundSetting;
  startedAt: Scalars["DateTime"]["output"];
  status: MoDRoundStatus;
  totalAmount: Scalars["BigNumber"]["output"];
};

/** MoonOrDoom round */
export type MoDRoundEntriesArgs = {
  player?: InputMaybe<Scalars["Address"]["input"]>;
};

/** Moon or Doom round result with payout amount */
export type MoDRoundResult = {
  __typename?: "MoDRoundResult";
  payoutRatio?: Maybe<Scalars["Float"]["output"]>;
  result: MoDResult;
};

/** MoonOrDoom round setting */
export type MoDRoundSetting = {
  __typename?: "MoDRoundSetting";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["Int"]["output"];
  minimumEnterAmount: Scalars["BigNumber"]["output"];
  roundBufferSecs: Scalars["Int"]["output"];
  roundIntervalSecs: Scalars["Int"]["output"];
  treasuryFee: Scalars["BigNumber"]["output"];
};

/** MoonOrDoom Round Status */
export type MoDRoundStatus = "CANCELLED" | "CLOSED" | "LOCKED" | "NONE" | "STARTED";

/** MoonOrDoom stats */
export type MoDStats = {
  __typename?: "MoDStats";
  oneDayResult: MoDPeriodResult;
  oneHourResult: MoDPeriodResult;
  recentRounds: Array<MoDRoundResult>;
};

/** MoonOrDoom unclaimed entry */
export type MoDUnclaimedEntry = {
  __typename?: "MoDUnclaimedEntry";
  /** Amount to be claimed (not original entry amount) after the fee and payout */
  amount: Scalars["BigNumber"]["output"];
  /** If it's about winning (rewards), then TRUE, otherwise FALSE (yes, it's refund then) */
  isRefund: Scalars["Boolean"]["output"];
  roundOnChainIds: Array<Scalars["Int"]["output"]>;
};

/**
 * Mutation type represents requests that mutate backend data.
 * This type is extended in each domain's schema definition.
 */
export type Mutation = {
  __typename?: "Mutation";
  addAffiliate: SuccessPayload;
  askSelfTimeout: SuccessPayload;
  claimDailyQuest: SuccessPayload;
  /** Request asyncronous claim of all available lottery prizes for an authenticaed user. */
  claimLotteries: SuccessPayload;
  /** Initiate a claim for a prize from a lucky spin. */
  claimLuckySpinPrizes: SuccessPayload;
  claimMilestoneLevel: SuccessPayload;
  confirmUserEmail: SuccessPayload;
  connectUserSocial: SuccessPayload;
  disconnectUserSocial: SuccessPayload;
  /** Enter lottery with certain amount of tickets. It can be also used to adjust (increase) the current entry amount */
  enterLottery: SuccessPayload;
  exitBoostMode: SuccessPayload;
  login: AuthPayload;
  logout: SuccessPayload;
  /** Spin with a certain amount of tickets. */
  playLuckySpin: Scalars["BigNumber"]["output"];
  /**
   * The off-chain poking action which can be submitted by a user.
   * Protections are in place so that only the user who's turn it is can poke.
   * If no poke mutation is recieved within 15s of their turn a poke is automatically inserted.
   */
  ptbPoke: SuccessPayload;
  subscribeEmail: SuccessPayload;
  toggleProfileImageVisibility: SuccessPayload;
  updateUser: SuccessPayload;
  updateUserEmail: SuccessPayload;
};

/**
 * Mutation type represents requests that mutate backend data.
 * This type is extended in each domain's schema definition.
 */
export type MutationAddAffiliateArgs = {
  referralCode: Scalars["String"]["input"];
};

/**
 * Mutation type represents requests that mutate backend data.
 * This type is extended in each domain's schema definition.
 */
export type MutationAskSelfTimeoutArgs = {
  timeoutUntil: Scalars["DateTime"]["input"];
};

/**
 * Mutation type represents requests that mutate backend data.
 * This type is extended in each domain's schema definition.
 */
export type MutationClaimDailyQuestArgs = {
  code: DailyQuestCode;
};

/**
 * Mutation type represents requests that mutate backend data.
 * This type is extended in each domain's schema definition.
 */
export type MutationClaimMilestoneLevelArgs = {
  code: MilestoneLevelCode;
};

/**
 * Mutation type represents requests that mutate backend data.
 * This type is extended in each domain's schema definition.
 */
export type MutationConfirmUserEmailArgs = {
  confirmationId: Scalars["String"]["input"];
};

/**
 * Mutation type represents requests that mutate backend data.
 * This type is extended in each domain's schema definition.
 */
export type MutationConnectUserSocialArgs = {
  data: ConnectSocialInput;
  platform: Social_Platform;
};

/**
 * Mutation type represents requests that mutate backend data.
 * This type is extended in each domain's schema definition.
 */
export type MutationDisconnectUserSocialArgs = {
  platform: Social_Platform;
};

/**
 * Mutation type represents requests that mutate backend data.
 * This type is extended in each domain's schema definition.
 */
export type MutationEnterLotteryArgs = {
  amount: Scalars["Int"]["input"];
  id: Scalars["BigNumber"]["input"];
};

/**
 * Mutation type represents requests that mutate backend data.
 * This type is extended in each domain's schema definition.
 */
export type MutationLoginArgs = {
  data: UserInput;
};

/**
 * Mutation type represents requests that mutate backend data.
 * This type is extended in each domain's schema definition.
 */
export type MutationPlayLuckySpinArgs = {
  tickets: Scalars["Int"]["input"];
};

/**
 * Mutation type represents requests that mutate backend data.
 * This type is extended in each domain's schema definition.
 */
export type MutationPtbPokeArgs = {
  caveOnChainId: Scalars["Int"]["input"];
  contract?: InputMaybe<PtbContract>;
  roundOnChainId: Scalars["BigNumber"]["input"];
};

/**
 * Mutation type represents requests that mutate backend data.
 * This type is extended in each domain's schema definition.
 */
export type MutationSubscribeEmailArgs = {
  email: Scalars["String"]["input"];
};

/**
 * Mutation type represents requests that mutate backend data.
 * This type is extended in each domain's schema definition.
 */
export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
};

/**
 * Mutation type represents requests that mutate backend data.
 * This type is extended in each domain's schema definition.
 */
export type MutationUpdateUserEmailArgs = {
  email?: InputMaybe<Scalars["String"]["input"]>;
};

/** Offset Based Pagination Input */
export type OffsetPaginationInput = {
  first?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Filtered rounds response with matching count */
export type PaginatedPtbRounds = {
  __typename?: "PaginatedPtbRounds";
  rounds: Array<PtbCaveRound>;
  total: Scalars["Int"]["output"];
};

/**
 * A generic pagination input which allows making queries with
 * cursor based pagination for optimal performance.
 */
export type PaginationInput = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  first: Scalars["Int"]["input"];
};

/** Leaderboard rankings and total wallet count */
export type PointsLeaderboard = {
  __typename?: "PointsLeaderboard";
  points: Array<SeasonPoints>;
  total: Scalars["BigNumber"]["output"];
};

/** The max prize available for a each prize type of lucky spin. */
export type PrizeMaxAvailable = {
  __typename?: "PrizeMaxAvailable";
  blastGoldBonus: Scalars["BigNumber"]["output"];
  blastGoldPrize: Scalars["BigNumber"]["output"];
  ethWei: Scalars["BigNumber"]["output"];
  seasonPoints: Scalars["BigNumber"]["output"];
  yoloWei: Scalars["BigNumber"]["output"];
};

export type PrizeType = "BLAST_GOLD" | "ETH" | "SEASON_POINTS" | "YOLO";

/** Poke The Bear Unique Cave */
export type PtbCave = {
  __typename?: "PtbCave";
  contract: PtbContract;
  currency: Scalars["Address"]["output"];
  enterAmount: Scalars["BigNumber"]["output"];
  id: Scalars["BigNumber"]["output"];
  isActive: Scalars["Boolean"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  /** Find next non-active round (from the current round) that has remaining slots */
  nextRoundWithSlots?: Maybe<PtbCaveRound>;
  onChainId: Scalars["BigNumber"]["output"];
  playersPerRound: Scalars["Int"]["output"];
  protocolFeeBp: Scalars["BigNumber"]["output"];
  /**
   * A specific round for a cave.
   * If no "roundId" is passed, return the current open round.
   */
  round?: Maybe<PtbCaveRound>;
  roundDurationSecs: Scalars["BigNumber"]["output"];
};

/** Poke The Bear Unique Cave */
export type PtbCaveRoundArgs = {
  onChainId?: InputMaybe<Scalars["BigNumber"]["input"]>;
};

/** Poke The Bear Cave Round */
export type PtbCaveRound = {
  __typename?: "PtbCaveRound";
  /**
   * Relationship to the parent cave this round belongs to.
   * So we can access it from the User -> Rounds relationship.
   */
  cave: PtbCave;
  cutoffTime?: Maybe<Scalars["BigNumber"]["output"]>;
  drawnTransactionHash?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["BigNumber"]["output"];
  /**
   * Cave round logs.
   * Sorted by `createdAt` timestamp descending, newest first.
   * Combined Round Logs / Player Logs / Poke Logs.
   */
  logs: Array<PtbCaveRoundLog>;
  onChainId: Scalars["BigNumber"]["output"];
  openedAt?: Maybe<Scalars["DateTime"]["output"]>;
  /**
   * Players to enter / deposit into a round.
   * Sorted by `entryIndex` ascending, first joined to last.
   */
  players: Array<PtbCaveRoundPlayer>;
  revealTransactionHash?: Maybe<Scalars["String"]["output"]>;
  revealedAt?: Maybe<Scalars["DateTime"]["output"]>;
  status: PtbRoundStatus;
};

/**
 * Expose the minumum amount of information required to display logs.
 * The FE can build i18n messages based on the `type`.
 * User is deliberately nullable if no user for action. Can be array if multiple.
 */
export type PtbCaveRoundLog = {
  __typename?: "PtbCaveRoundLog";
  timestamp: Scalars["DateTime"]["output"];
  type: PtbRoundLogType;
  user?: Maybe<Array<User>>;
};

/** Poke The Bear Round Player. */
export type PtbCaveRoundPlayer = {
  __typename?: "PtbCaveRoundPlayer";
  /** Field representing if the entry (deposit and optional profit) has been claimed. */
  claimed?: Maybe<Scalars["Boolean"]["output"]>;
  entryIndex: Scalars["Int"]["output"];
  /**
   * Count of the future `n` rounds of this cave to which the player has committed.
   * Limited to the next `5` rounds max, will be capped at 5 in which case `5+` should be displayed.
   */
  futureCommittedRoundsCount: Scalars["Int"]["output"];
  gemsEarned?: Maybe<Scalars["BigNumber"]["output"]>;
  /** Field representing if the user has lost the round. */
  lost?: Maybe<Scalars["Boolean"]["output"]>;
  /** The poke event-timings and outcome for the player in this round. */
  poke?: Maybe<PtbPoke>;
  /** Field representing if round cancelled and entry for user has been refunded. */
  refunded?: Maybe<Scalars["Boolean"]["output"]>;
  round: PtbCaveRound;
  user: User;
};

/** PTB Contract Types */
export type PtbContract = "PTB_V1_BLAST";

/** Poke status. */
export type PtbPoke = {
  __typename?: "PtbPoke";
  automated?: Maybe<Scalars["Boolean"]["output"]>;
  index?: Maybe<Scalars["Int"]["output"]>;
  isPokingUntil?: Maybe<Scalars["DateTime"]["output"]>;
  pokedAt?: Maybe<Scalars["DateTime"]["output"]>;
};

/** Logs Actions for the Cave Round. */
export type PtbRoundLogType =
  | "CAVE_RESET"
  /** Poking flow */
  | "PLAYER_COUNTDOWN"
  | "PLAYER_DIED"
  | "PLAYER_JOINED"
  | "PLAYER_PASS"
  | "PLAYER_POKE"
  | "PLAYER_READY"
  | "PLAYER_SURVIVED"
  | "POT_SPLIT"
  /** Error Paths */
  | "ROUND_CANCELLED"
  | "ROUND_DRAWING"
  | "ROUND_DRAWN"
  /** Rounds Pre-Poke flow */
  | "ROUND_OPENED"
  /** Rounds Post-Poke flow */
  | "ROUND_REVEALED";

/** Poke The Bear Round Status */
export type PtbRoundStatus = "CANCELLED" | "DRAWING" | "DRAWN" | "NONE" | "OPEN" | "REVEALED";

/** Filters available on the PTB Rounds. */
export type PtbRoundsFilterInput = {
  caveOnChainIds?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  player?: InputMaybe<Scalars["Address"]["input"]>;
  status?: InputMaybe<Array<PtbRoundStatus>>;
};

/** Poke the Bear Rounds Sorts */
export type PtbRoundsSort = "CREATED_AT_ASC" | "CREATED_AT_DESC";

/** Unclaimed winning/refund information */
export type PtbUnclaimedEntry = {
  __typename?: "PtbUnclaimedEntry";
  cave: PtbCave;
  entryIndex: Scalars["Int"]["output"];
  round: PtbCaveRound;
  user: User;
};

/** Quantum contract enum */
export type QuantumContract = "QUANTUM_V1_BLAST";

/**
 * Quantum game data
 *
 * The id is the cursor to be used for pagination.
 *
 * The stop loss/gain are set to 0 when not in use.
 */
export type QuantumGame = {
  __typename?: "QuantumGame";
  boundary: Scalars["Int"]["output"];
  contract: QuantumContract;
  createdAt: Scalars["DateTime"]["output"];
  currency: Scalars["String"]["output"];
  drawingTransactionHash: Scalars["String"]["output"];
  drawnTransactionHash?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isAbove: Scalars["Boolean"]["output"];
  liquidityPoolFeeWei?: Maybe<Scalars["BigNumber"]["output"]>;
  multiplier: Scalars["Int"]["output"];
  numberOfRounds: Scalars["Int"]["output"];
  numberOfRoundsPlayed?: Maybe<Scalars["Int"]["output"]>;
  payouts?: Maybe<Array<Scalars["BigNumber"]["output"]>>;
  playAmountWei: Scalars["BigNumber"]["output"];
  points: Scalars["BigNumber"]["output"];
  protocolFeeWei?: Maybe<Scalars["BigNumber"]["output"]>;
  results?: Maybe<Array<Scalars["Int"]["output"]>>;
  returnMultiplier: Scalars["Float"]["output"];
  status: QuantumStatus;
  stopGainWei: Scalars["BigNumber"]["output"];
  stopLossWei: Scalars["BigNumber"]["output"];
  user: User;
  winningAmountWei?: Maybe<Scalars["BigNumber"]["output"]>;
};

/** Quantum games filter input */
export type QuantumGamesFilterInput = {
  contracts: Array<QuantumContract>;
  player?: InputMaybe<Scalars["Address"]["input"]>;
};

/**
 * Quantum games sort enum
 *
 * Default: NEWEST
 */
export type QuantumSort = "LUCKY_WIN_DESC" | "NEWEST" | "WIN_AMOUNT_DESC";

/**
 * Quantum games status enum
 *
 * DRAWING: The game is currently in progress.
 * DRAWN: The game has been completed.
 * CANCELLED: The game has been cancelled because the randomness was delayed.
 * REFUNDED: The game has been cancelled and refunded.
 *
 * Note: A new game can start only after the CANCELLED game has been refunded.
 */
export type QuantumStatus = "CANCELLED" | "DRAWING" | "DRAWN" | "REFUNDED";

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type Query = {
  __typename?: "Query";
  boostAllocation?: Maybe<BoostAllocation>;
  /** Get flipper history */
  coinFlips: Array<Flipper>;
  dailyQuests: DailyQuests;
  dontFallInConfig?: Maybe<DontFallInConfig>;
  dontFallInGames: Array<DontFallInGame>;
  /** Epoch Based Gold Distribution */
  epochLeaderboard: PointsLeaderboard;
  /** Use this query to get potential points to earn for playing specific game. Works for all classic games - Flipper, DontFallIn, Quantum and LaserBlast */
  estimateGamePoints: Scalars["BigNumber"]["output"];
  gameStats: GameStats;
  isReferralCodeValid: Scalars["Boolean"]["output"];
  /** Get laser blast history */
  laserBlasts: Array<LaserBlast>;
  liquidityPoolMetrics: Array<LiquidityPoolTimeseries>;
  liquidityPools: Array<LiquidityPool>;
  liquidityPoolsOverview: Array<LiquidityPoolGameStats>;
  /** Get a list of lotteries. For lottery history, or current lotteries. */
  lotteries: Array<Lottery>;
  /** Get specific lottery */
  lottery?: Maybe<Lottery>;
  /** Get the current lucky spin bonus blast gold prize pool. */
  luckySpinBonusBlastGoldPot: Scalars["BigNumber"]["output"];
  /** Get details about a specific draw */
  luckySpinEntry?: Maybe<LuckySpinEntry>;
  /**
   * Get the max winnable lucky spin gold prize.
   * This is to prevent displaying unattainable prizes.
   */
  luckySpinMaxAvailablePrize: PrizeMaxAvailable;
  message: Scalars["String"]["output"];
  /** Get all available Moon or Doom asset pairs */
  modAssets: Array<MoDAsset>;
  /** Moon or Doom historical prices for a single asset pair during the last 10 minutes */
  modHistoricalPrices: Array<MoDHistoricalPrice>;
  /** Get a single MoonOrDoom round. If id is not provided, return the current open round */
  modRound?: Maybe<MoDRound>;
  /** List of MoonOrDoom rounds */
  modRounds: Array<MoDRound>;
  /** Moon or Doom stats about recent rounds including 1 hour and 1 day changes */
  modStats: MoDStats;
  /** Listing Points */
  pointsLeaderboard: PointsLeaderboard;
  /** Get details of the current season */
  pointsSeason: SeasonDetails;
  /** An individual PTB Cave. */
  ptbCave?: Maybe<PtbCave>;
  /**
   * The full list of "active" PTB caves.
   * Sorting dynamically on (participant / seats).
   * No need to paginate at the moment.
   */
  ptbCaves: Array<PtbCave>;
  /** Aggregate cross-cave PTB rounds. */
  ptbRounds: PaginatedPtbRounds;
  quantumGames: Array<QuantumGame>;
  /** Recent game wins across YOLO, Moon or Doom, Poke the Bear, Flipper, LaserBlast, Quantum and DontFallIn */
  recentGameWins: Array<GameWinResult>;
  /** Get a list of allocations for any given season */
  seasonAllocations: Array<SeasonAllocation>;
  /** Get details of a specific season */
  seasonDetails?: Maybe<SeasonDetails>;
  user?: Maybe<User>;
  usernameValid: Scalars["Boolean"]["output"];
  users: Array<User>;
  yoloCurrencies: Array<Scalars["Address"]["output"]>;
  yoloParticipants: Array<User>;
  yoloRound: YoloRound;
  yoloRounds: YoloPaginatedRounds;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryBoostAllocationArgs = {
  address: Scalars["Address"]["input"];
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryCoinFlipsArgs = {
  filter: FilterFlipperInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<FlipperSort>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryDailyQuestsArgs = {
  address?: InputMaybe<Scalars["Address"]["input"]>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryDontFallInConfigArgs = {
  contract: DontFallInContract;
  lavasCount: Scalars["Int"]["input"];
  revealedTilesCount: Scalars["Int"]["input"];
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryDontFallInGamesArgs = {
  filter: DontFallInGamesFilterInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<DontFallInSort>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryEpochLeaderboardArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryEstimateGamePointsArgs = {
  amountPerRoundWei: Scalars["BigNumber"]["input"];
  currency: Scalars["Address"]["input"];
  numberOfRounds: Scalars["Int"]["input"];
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryIsReferralCodeValidArgs = {
  referralCode: Scalars["String"]["input"];
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryLaserBlastsArgs = {
  filter: FilterLaserBlastInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<LaserBlastSort>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryLiquidityPoolMetricsArgs = {
  filters: LiquidityPoolMetricsFilterInput;
  previousAlignedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryLiquidityPoolsArgs = {
  user?: InputMaybe<Scalars["Address"]["input"]>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryLiquidityPoolsOverviewArgs = {
  filters: LiquidityPoolOverviewFilterInput;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryLotteriesArgs = {
  filter?: InputMaybe<FilterLotteryInput>;
  pagination?: InputMaybe<PaginationInput>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryLotteryArgs = {
  id: Scalars["BigNumber"]["input"];
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryLuckySpinEntryArgs = {
  id: Scalars["ID"]["input"];
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryMessageArgs = {
  timestamp?: InputMaybe<Scalars["Timestamp"]["input"]>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryModHistoricalPricesArgs = {
  pair: MoDAssetPair;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryModRoundArgs = {
  contract: MoDContract;
  id?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryModRoundsArgs = {
  filter: MoDFilterInput;
  pagination?: InputMaybe<PaginationInput>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryModStatsArgs = {
  contract: MoDContract;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryPointsLeaderboardArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>;
  sort?: InputMaybe<LeaderboardSortInput>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryPtbCaveArgs = {
  contract?: InputMaybe<PtbContract>;
  onChainId: Scalars["Int"]["input"];
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryPtbCavesArgs = {
  contract?: InputMaybe<PtbContract>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryPtbRoundsArgs = {
  contract?: InputMaybe<PtbContract>;
  filter: PtbRoundsFilterInput;
  pagination?: InputMaybe<OffsetPaginationInput>;
  sort?: InputMaybe<PtbRoundsSort>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryQuantumGamesArgs = {
  filter: QuantumGamesFilterInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<QuantumSort>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryRecentGameWinsArgs = {
  sort: RecentGameWinSort;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QuerySeasonAllocationsArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>;
  season: Scalars["Int"]["input"];
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QuerySeasonDetailsArgs = {
  season: Scalars["Int"]["input"];
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryUserArgs = {
  address: Scalars["Address"]["input"];
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryUsernameValidArgs = {
  username: Scalars["String"]["input"];
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryYoloCurrenciesArgs = {
  contract: YoloContract;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryYoloParticipantsArgs = {
  contracts?: InputMaybe<Array<YoloContract>>;
  pagination?: InputMaybe<OffsetPaginationInput>;
  search: SearchFilterInput;
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryYoloRoundArgs = {
  connectedWallet?: InputMaybe<Scalars["Address"]["input"]>;
  contract: YoloContract;
  onChainId: Scalars["Int"]["input"];
};

/**
 * Query type represents requests that fetch backend data.
 * This type is extended in each domain's schema definition.
 */
export type QueryYoloRoundsArgs = {
  connectedWallet?: InputMaybe<Scalars["Address"]["input"]>;
  filter?: InputMaybe<YoloRoundFilterInput>;
  pagination?: InputMaybe<OffsetPaginationInput>;
  sort?: InputMaybe<YoloRoundSort>;
};

/** Details on a specific rakeback period */
export type Rakeback = {
  __typename?: "Rakeback";
  amountWei: Scalars["BigNumber"]["output"];
  claimedAt?: Maybe<Scalars["DateTime"]["output"]>;
  currency: Currency;
  expiresAt: Scalars["DateTime"]["output"];
  status: RakebackStatus;
  unlocksAt: Scalars["DateTime"]["output"];
};

/** The data needed to claim all unlocked rakebacks */
export type RakebackClaimDetails = {
  __typename?: "RakebackClaimDetails";
  cumulativeAmountWei: Scalars["BigNumber"]["output"];
  currency: Currency;
  endsAt: Scalars["DateTime"]["output"];
  proof: Array<Scalars["String"]["output"]>;
};

/** Rakeback status for a specific period */
export type RakebackStatus = "CLAIMED" | "EXPIRED" | "LOCKED" | "UNLOCKED";

/**
 * Recent rakebacks for the user, the pending amount and the total amount of rakeback for a given currency claimed by the user so far
 *
 * The pagination cursor is `lastRoundId`.
 */
export type Rakebacks = {
  __typename?: "Rakebacks";
  lastRoundId?: Maybe<Scalars["ID"]["output"]>;
  rakebacks: GroupedRakebacks;
  totalClaimed: Array<CurrencyAmount>;
  totalPending: Array<CurrencyAmount>;
};

export type RakebacksFilterInput = {
  status: RakebackStatus;
};

export type RecentGameWinSort = "BIGGEST_WINS_DESC" | "RECENT_WINS_DESC";

/** Referrer and referrals information */
export type Referral = {
  __typename?: "Referral";
  /**
   * If there is a pending or confirmed referrer relationship to help the
   * frontend branch logic based on specific pending relationship states.
   */
  hasPendingOrConfirmedReferrer: Scalars["Boolean"]["output"];
  /** My primary referrals count */
  primaryReferralsCount: Scalars["Int"]["output"];
  /** My direct referrer, who would receive 20% of all points that I earn */
  primaryReferrer?: Maybe<User>;
  /** My secondary referrals count */
  secondaryReferralsCount: Scalars["Int"]["output"];
};

/** The scope of authentication require for a protection action */
export type Scope =
  /** 30 days token */
  | "LONG_LIVED"
  /** 1 day token */
  | "SHORT_LIVED";

/**
 * Social Media Platform's that are supported when linking a user
 * or a collection to the relevant social information via OAuth2.
 */
export type Social_Platform = "DISCORD" | "TWITTER";

/**
 * A generic search filter which allows searching a model upon some
 * plain string-like term.
 */
export type SearchFilterInput = {
  term: Scalars["String"]["input"];
};

/** Season allocations are public information meant to be shown on leaderboards, not the private detailed user-level view. */
export type SeasonAllocation = {
  __typename?: "SeasonAllocation";
  blastGoldAllocation: Scalars["BigNumber"]["output"];
  points: Scalars["BigNumber"]["output"];
  rank: Scalars["Int"]["output"];
  user: User;
  yTokenWeiAllocation: Scalars["BigNumber"]["output"];
};

/** Season Details */
export type SeasonDetails = {
  __typename?: "SeasonDetails";
  endTime: Scalars["DateTime"]["output"];
  rewardPool: Scalars["BigNumber"]["output"];
  rewardPoolBlastGold?: Maybe<Scalars["BigNumber"]["output"]>;
  season: Scalars["Int"]["output"];
  startTime: Scalars["DateTime"]["output"];
};

/** Leaderboard Ranking Position & Information */
export type SeasonPoints = {
  __typename?: "SeasonPoints";
  dailyRank: Scalars["BigNumber"]["output"];
  details?: Maybe<UserPointsDetails>;
  multiplier?: Maybe<Scalars["Float"]["output"]>;
  points24h: Scalars["BigNumber"]["output"];
  seasonPoints: Scalars["BigNumber"]["output"];
  seasonRank: Scalars["BigNumber"]["output"];
  user?: Maybe<User>;
};

/**
 * Season 1 Specific Rewards.
 * Contains Blast Gold, YOLO Tokens, and LP Vault Shares.
 */
export type SeasonReward = {
  __typename?: "SeasonReward";
  blastGoldAllocation: Scalars["BigNumber"]["output"];
  /** The blast gold allocation distribution timestamp. */
  blastGoldDistributedAt?: Maybe<Scalars["DateTime"]["output"]>;
  points: Scalars["BigNumber"]["output"];
  rank: Scalars["Int"]["output"];
  /** The amount of yTokens that were earned from shards. Null if none. */
  stakingShards?: Maybe<Scalars["BigNumber"]["output"]>;
  /** The vault share allocation claiming details. */
  vaultSharesClaimDetails: VaultSharesClaimDetails;
  /** The yToken allocation claiming details. */
  yTokenVestingClaimDetails: YTokenVestingClaimDetails;
  /** Actual allocations (including yTokens earned from shards). */
  yTokenWeiAllocation: Scalars["BigNumber"]["output"];
  yTokenWeiFromShards?: Maybe<Scalars["BigNumber"]["output"]>;
};

/**
 * Represents a successful mutation on the backend but one which
 * does not necesarrily directly relate to or affect a model.
 */
export type SuccessPayload = {
  __typename?: "SuccessPayload";
  success: Scalars["Boolean"]["output"];
};

/** Unclaimed prizes that require manual claims, in Wei */
export type UnclaimedPrizesWei = {
  __typename?: "UnclaimedPrizesWei";
  ethWei: Scalars["BigNumber"]["output"];
  yoloWei: Scalars["BigNumber"]["output"];
};

/** User */
export type User = {
  __typename?: "User";
  address: Scalars["Address"]["output"];
  avatar?: Maybe<Avatar>;
  biography?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  /** Get current flipper */
  currentFlip?: Maybe<Flipper>;
  /** Get current laser blast */
  currentLaserBlast?: Maybe<LaserBlast>;
  discordUsername?: Maybe<Scalars["String"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  /** Epoch points for the user */
  epochPoints?: Maybe<SeasonPoints>;
  /** User has participated in any game. */
  hasPlayedGames: Scalars["Boolean"]["output"];
  id: Scalars["Int"]["output"];
  isEmailVerified?: Maybe<Scalars["Boolean"]["output"]>;
  isProfileImageVisible: Scalars["Boolean"]["output"];
  isVerified: Scalars["Boolean"]["output"];
  latestDontFallInGame?: Maybe<DontFallInGame>;
  latestQuantumGame?: Maybe<QuantumGame>;
  liquidityPoolActionPreview: LiquidityPoolActionPreview;
  liquidityPoolEvents: Array<LiquidityPoolEvent>;
  liquidityPoolMetrics: Array<LiquidityPoolTimeseries>;
  liquidityPoolPendingActions: Array<LiquidityPoolPendingAction>;
  liquidityPoolSeasonDroplets?: Maybe<Scalars["BigNumber"]["output"]>;
  /** Remaining balance of lottery tickets for specific user. It should be only available for the JWT authed user */
  lotteryTickets?: Maybe<LotteryTicketAllocation>;
  /** Unclaimed lottery ETH rewards in wei. */
  lotteryUnclaimedEthWei?: Maybe<Scalars["BigNumber"]["output"]>;
  /** Get the lucky spin entries along with the result and status (claimed/unclaimed) */
  luckySpinEntries: Array<LuckySpinEntry>;
  /** Total lucky spin prizes won */
  luckySpinPrizeTotals: LuckySpinPrizeTotals;
  /** Available lucky spins for the user */
  luckySpinTickets: Scalars["BigNumber"]["output"];
  /** Get the users unclaimed ETH and YOLO won from lucky spins */
  luckySpinUnclaimedPrizesWei: UnclaimedPrizesWei;
  /** Mid Season 2 Gold Distribution */
  midSeasonGoldDistribution: MidSeasonGoldDistribution;
  milestones: Milestones;
  /** Unclaimed rewards & refunds */
  modUnclaimedEntries: Array<MoDUnclaimedEntry>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** Get the next rakeback for the user */
  nextRakeback?: Maybe<Rakeback>;
  /** Unclaimed Refunded Rounds */
  ptbUnclaimedRefunds: Array<PtbUnclaimedEntry>;
  /** Unclaimed Winning Rounds (includes principle deposit) */
  ptbUnclaimedWinnings: Array<PtbUnclaimedEntry>;
  /** Get the data needed to claim all unlocked rakebacks */
  rakebackClaimDetails: Array<Maybe<RakebackClaimDetails>>;
  /** Get recent rakebacks for the user. The pagination cursor is `lastRoundId` */
  rakebacks: Rakebacks;
  referral?: Maybe<Referral>;
  referralCode?: Maybe<Scalars["String"]["output"]>;
  referrer?: Maybe<User>;
  /** Season points for the user */
  seasonPoints?: Maybe<SeasonPoints>;
  /** Rewards per season per user */
  seasonRewards?: Maybe<SeasonReward>;
  selfTimeoutUntil?: Maybe<Scalars["DateTime"]["output"]>;
  twitterUsername?: Maybe<Scalars["String"]["output"]>;
  /** Has unclaimed Points */
  unclaimedPoints?: Maybe<Scalars["BigNumber"]["output"]>;
  updatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  yoloBoostMetrics?: Maybe<YoloBoostMetrics>;
  yoloFutureRounds: YoloFutureRounds;
  yoloMetrics?: Maybe<YoloDepositorMetrics>;
  yoloRounds: YoloPaginatedRounds;
  yoloUnclaimedPrizes: Array<YoloDeposit>;
  yoloUnclaimedRefunds: Array<YoloDeposit>;
};

/** User */
export type UserCurrentFlipArgs = {
  contract: FlipperContract;
};

/** User */
export type UserCurrentLaserBlastArgs = {
  contract: LaserBlastContract;
};

/** User */
export type UserLatestDontFallInGameArgs = {
  contract: DontFallInContract;
};

/** User */
export type UserLatestQuantumGameArgs = {
  contract: QuantumContract;
};

/** User */
export type UserLiquidityPoolActionPreviewArgs = {
  actionType: LiquidityPoolEventType;
  assetsAmountVum: Scalars["BigNumber"]["input"];
  contract: LiquidityPoolContract;
};

/** User */
export type UserLiquidityPoolEventsArgs = {
  pagination: PaginationInput;
};

/** User */
export type UserLiquidityPoolMetricsArgs = {
  filters: LiquidityPoolUserMetricsFilterInput;
  previousAlignedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/** User */
export type UserLuckySpinEntriesArgs = {
  pagination?: InputMaybe<OffsetPaginationInput>;
};

/** User */
export type UserModUnclaimedEntriesArgs = {
  contract: MoDContract;
};

/** User */
export type UserPtbUnclaimedRefundsArgs = {
  contract?: InputMaybe<PtbContract>;
};

/** User */
export type UserPtbUnclaimedWinningsArgs = {
  contract?: InputMaybe<PtbContract>;
};

/** User */
export type UserRakebacksArgs = {
  filter?: InputMaybe<RakebacksFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};

/** User */
export type UserSeasonRewardsArgs = {
  season: Scalars["Int"]["input"];
};

/** User */
export type UserYoloFutureRoundsArgs = {
  contracts: Array<YoloContract>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};

/** User */
export type UserYoloRoundsArgs = {
  filter?: InputMaybe<YoloUserRoundFilterInput>;
  pagination?: InputMaybe<OffsetPaginationInput>;
  sort?: InputMaybe<YoloRoundSort>;
};

/** User */
export type UserYoloUnclaimedPrizesArgs = {
  filter?: InputMaybe<YoloDepositFilterInput>;
};

/** User */
export type UserYoloUnclaimedRefundsArgs = {
  filter?: InputMaybe<YoloDepositFilterInput>;
};

/** Filter on verified users. */
export type UserFilterInput = {
  addresses: Array<Scalars["Address"]["input"]>;
  isVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/**
 * Login as an existing user or create a new user
 * on first login.
 */
export type UserInput = {
  address: Scalars["Address"]["input"];
  message: Scalars["String"]["input"];
  signature: Scalars["String"]["input"];
};

export type UserLiquidityPoolSeason = {
  __typename?: "UserLiquidityPoolSeason";
  currentMultiplier: Scalars["Float"]["output"];
  dailyDroplets: Scalars["Float"]["output"];
  nextIncreaseAt?: Maybe<Scalars["DateTime"]["output"]>;
  nextMultiplierIncrease: Scalars["Float"]["output"];
  totalDroplets: Scalars["Float"]["output"];
};

/** User Milestones Progress. */
export type UserMilestoneLevel = {
  __typename?: "UserMilestoneLevel";
  claimedAt?: Maybe<Scalars["DateTime"]["output"]>;
  code: MilestoneLevelCode;
  createdAt?: Maybe<Scalars["DateTime"]["output"]>;
  goal: Scalars["BigNumber"]["output"];
  milestone: MilestoneCode;
  points: Scalars["Int"]["output"];
  progress: Scalars["BigNumber"]["output"];
};

/** Points Sources. */
export type UserPointsDetails = {
  __typename?: "UserPointsDetails";
  boost: Scalars["BigNumber"]["output"];
  games: Scalars["BigNumber"]["output"];
  quests: Scalars["BigNumber"]["output"];
  referrals: Scalars["BigNumber"]["output"];
};

/** Update an existing user args. */
export type UserUpdateInput = {
  biography?: InputMaybe<Scalars["String"]["input"]>;
  instagramLink?: InputMaybe<Scalars["String"]["input"]>;
  isEmailSubscribed?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  websiteLink?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The data needed to unlock the user's Liquidty Pool shares.
 * The yTokens are already in the pool, they claim the ERC-4626 tokens.
 * This is a single claim.
 */
export type VaultSharesClaimDetails = {
  __typename?: "VaultSharesClaimDetails";
  /** The timestamp of the claim. */
  claimedAt?: Maybe<Scalars["DateTime"]["output"]>;
  proof?: Maybe<Array<Scalars["String"]["output"]>>;
  /** The vault shares claimable based on yTokens deposited. */
  vaultSharesClaimableVum: Scalars["BigNumber"]["output"];
  /** The total YOLO tokens deposited into the LP. */
  yTokensDepositedWei: Scalars["BigNumber"]["output"];
};

/** The data needed to claim each unlocked tranch of the user's YOLO token allocation (immediate + vesting) */
export type YTokenVestingClaimDetails = {
  __typename?: "YTokenVestingClaimDetails";
  /** The amount of yTokens claimed to date. */
  claimedWei: Scalars["BigNumber"]["output"];
  /** The amount that will be passed to the contract to claim */
  cumulativeAmountWei: Scalars["BigNumber"]["output"];
  currentClaimableAmountWei: Scalars["BigNumber"]["output"];
  proof?: Maybe<Array<Scalars["String"]["output"]>>;
  /** The volume in $YOLO necessary to claim the remaining vested tokens. */
  remainingVolumeToClaimWei?: Maybe<Scalars["BigNumber"]["output"]>;
  /** Total allocated yTokens for the vesting portion. */
  vestingAllocationWei: Scalars["BigNumber"]["output"];
  /** The volume played and remaining volume needed to fully unlock. */
  volumeWei?: Maybe<Scalars["BigNumber"]["output"]>;
};

/** Yolo boost progress stats */
export type YoloBoostMetrics = {
  __typename?: "YoloBoostMetrics";
  boostAllocationWei: Scalars["BigNumber"]["output"];
  boostSpentWei: Scalars["BigNumber"]["output"];
  progressPercentage: Scalars["Float"]["output"];
};

/** Yolo Contract Enum */
export type YoloContract = "YOLO_LIMITED_V1_BLAST" | "YOLO_PLAY_V1_BLAST" | "YOLO_V2_BLAST";

/** Yolo deposit data */
export type YoloDeposit = {
  __typename?: "YoloDeposit";
  amount: Scalars["BigNumber"]["output"];
  /**
   * Flag to check if the prize was claimed.
   * It's only for YoloRound.deposits, null elsewhere.
   */
  claimed?: Maybe<Scalars["Boolean"]["output"]>;
  currency?: Maybe<Scalars["Address"]["output"]>;
  depositor: User;
  gemsEarned?: Maybe<Scalars["BigNumber"]["output"]>;
  id: Scalars["Int"]["output"];
  index: Scalars["Int"]["output"];
  numberOfEntries: Scalars["BigNumber"]["output"];
  round: YoloRound;
  type: YoloTokenType;
};

/** Yolo Deposit filter input */
export type YoloDepositFilterInput = {
  contracts?: InputMaybe<Array<YoloContract>>;
  roundId?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Yolo depositor stats */
export type YoloDepositorMetrics = {
  __typename?: "YoloDepositorMetrics";
  biggestETHWin: Scalars["BigNumber"]["output"];
  biggestWinMultiple: Scalars["Float"]["output"];
  depositor?: Maybe<User>;
  netProfits: Scalars["BigNumber"]["output"];
  totalETHPlayed: Scalars["BigNumber"]["output"];
  totalETHWon: Scalars["BigNumber"]["output"];
  totalFees: Scalars["BigNumber"]["output"];
  totalRoundsPlayed: Scalars["Int"]["output"];
  totalRoundsWon: Scalars["Int"]["output"];
  winRate: Scalars["Float"]["output"];
};

/** Yolo future rounds */
export type YoloFutureRounds = {
  __typename?: "YoloFutureRounds";
  rounds: Array<YoloRound>;
  totalRoundsCount: Scalars["Int"]["output"];
  totalWalletValueWei: Scalars["BigNumber"]["output"];
};

/** Yolo paginated rounds */
export type YoloPaginatedRounds = {
  __typename?: "YoloPaginatedRounds";
  count: Scalars["Int"]["output"];
  rounds: Array<YoloRound>;
};

/** Yolo round data */
export type YoloRound = {
  __typename?: "YoloRound";
  contract: YoloContract;
  cutoffTime?: Maybe<Scalars["BigNumber"]["output"]>;
  deposits: Array<YoloDeposit>;
  drawnHash?: Maybe<Scalars["String"]["output"]>;
  entriesCount: Scalars["BigNumber"]["output"];
  lastStatusUpdate: Scalars["DateTime"]["output"];
  maximumNumberOfDeposits: Scalars["Int"]["output"];
  maximumNumberOfParticipants: Scalars["Int"]["output"];
  numberOfParticipants: Scalars["BigNumber"]["output"];
  onChainId: Scalars["Int"]["output"];
  potValue: Scalars["BigNumber"]["output"];
  protocolFeeBp: Scalars["Int"]["output"];
  protocolFeeOwed?: Maybe<Scalars["BigNumber"]["output"]>;
  /** For the wallet provided via the wallet filter */
  refundable?: Maybe<Scalars["BigNumber"]["output"]>;
  roundDuration: Scalars["Int"]["output"];
  status: YoloRoundStatus;
  valuePerEntry: Scalars["BigNumber"]["output"];
  walletEntries?: Maybe<Scalars["BigNumber"]["output"]>;
  winner?: Maybe<User>;
  winnerMultiple?: Maybe<Scalars["Float"]["output"]>;
};

/** Yolo Round filter input */
export type YoloRoundFilterInput = {
  contracts?: InputMaybe<Array<YoloContract>>;
  depositor?: InputMaybe<Scalars["Address"]["input"]>;
  statuses?: InputMaybe<Array<YoloRoundStatus>>;
  winner?: InputMaybe<Scalars["Address"]["input"]>;
};

/** Yolo Round Sort Enum ('NEWEST' as default) */
export type YoloRoundSort =
  | "NEWEST"
  | "OLDEST"
  | "PARTICIPANT_ASC"
  | "PARTICIPANT_DESC"
  | "ROUND_VALUE_ASC"
  | "ROUND_VALUE_DESC"
  | "WALLET_ENTRIES_ASC"
  | "WALLET_ENTRIES_DESC"
  | "WIN_DEPOSIT_ASC"
  | "WIN_DEPOSIT_DESC"
  | "WIN_MULTIPLE_ASC"
  | "WIN_MULTIPLE_DESC";

/** Yolo Round Status Enum */
export type YoloRoundStatus = "CANCELLED" | "DRAWING" | "DRAWN" | "NONE" | "OPEN";

/** Yolo Token Type Enum */
export type YoloTokenType = "ERC20" | "ETH";

/** User Yolo Round filter input */
export type YoloUserRoundFilterInput = {
  contracts?: InputMaybe<Array<YoloContract>>;
  isFutureRound?: InputMaybe<Scalars["Boolean"]["input"]>;
  isWinner?: InputMaybe<Scalars["Boolean"]["input"]>;
  statuses?: InputMaybe<Array<YoloRoundStatus>>;
};

export type DontFallInGameFragmentFragment = {
  __typename?: "DontFallInGame";
  id: string;
  status: DontFallInStatus;
  winningAmountWei?: BigIntish | null;
  currency: any;
  playAmountWei: BigIntish;
  points: BigIntish;
  latestMap: BigIntish;
  drawnTransactionHash?: string | null;
  drawingTransactionHash: string;
};

export type GetUserDontFallInCurrentQueryVariables = Exact<{
  contract: DontFallInContract;
  address: Scalars["Address"]["input"];
}>;

export type GetUserDontFallInCurrentQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    latestDontFallInGame?: {
      __typename?: "DontFallInGame";
      hitLavas?: BigIntish | null;
      multiplier?: number | null;
      id: string;
      status: DontFallInStatus;
      winningAmountWei?: BigIntish | null;
      currency: any;
      playAmountWei: BigIntish;
      points: BigIntish;
      latestMap: BigIntish;
      drawnTransactionHash?: string | null;
      drawingTransactionHash: string;
    } | null;
  } | null;
};

export type GetUserDontFallInHistoryQueryVariables = Exact<{
  filter: DontFallInGamesFilterInput;
  pagination?: InputMaybe<PaginationInput>;
  sort: DontFallInSort;
}>;

export type GetUserDontFallInHistoryQuery = {
  __typename?: "Query";
  dontFallInGames: Array<{
    __typename?: "DontFallInGame";
    createdAt: string;
    lavasCount: number;
    id: string;
    status: DontFallInStatus;
    winningAmountWei?: BigIntish | null;
    currency: any;
    playAmountWei: BigIntish;
    points: BigIntish;
    latestMap: BigIntish;
    drawnTransactionHash?: string | null;
    drawingTransactionHash: string;
    user: {
      __typename?: "User";
      address: any;
      name?: string | null;
      avatar?: { __typename?: "Avatar"; image?: { __typename?: "MediaData"; src: string } | null } | null;
    };
  }>;
};

export type GetDontFallInMultiplierQueryVariables = Exact<{
  contract: DontFallInContract;
  lavasCount: Scalars["Int"]["input"];
  selectedTilesCount: Scalars["Int"]["input"];
}>;

export type GetDontFallInMultiplierQuery = {
  __typename?: "Query";
  dontFallInConfig?: { __typename?: "DontFallInConfig"; multiplier: number } | null;
};

export type FlipperFragmentFragment = {
  __typename?: "Flipper";
  id: string;
  eventBlock: BigIntish;
  pickSide: FlipSide;
  numberOfRounds: number;
  numberOfRoundsPlayed?: number | null;
  amountPerRoundWei: BigIntish;
  playAmountWei: BigIntish;
  currency: string;
  contract: FlipperContract;
  stopLossWei: BigIntish;
  stopGainWei: BigIntish;
  winningAmountWei?: BigIntish | null;
  status: FlipperStatus;
  flipResults?: Array<FlipSide> | null;
  createdAt: string;
  drawingTransactionHash: string;
  protocolFeeWei?: BigIntish | null;
  liquidityPoolFeeWei?: BigIntish | null;
  points: BigIntish;
  drawnTransactionHash?: string | null;
  player: {
    __typename?: "User";
    address: any;
    avatar?: {
      __typename?: "Avatar";
      image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
    } | null;
  };
};

export type GetFlipperHistoryQueryVariables = Exact<{
  filter: FilterFlipperInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<FlipperSort>;
}>;

export type GetFlipperHistoryQuery = {
  __typename?: "Query";
  coinFlips: Array<{
    __typename?: "Flipper";
    id: string;
    eventBlock: BigIntish;
    pickSide: FlipSide;
    numberOfRounds: number;
    numberOfRoundsPlayed?: number | null;
    amountPerRoundWei: BigIntish;
    playAmountWei: BigIntish;
    currency: string;
    contract: FlipperContract;
    stopLossWei: BigIntish;
    stopGainWei: BigIntish;
    winningAmountWei?: BigIntish | null;
    status: FlipperStatus;
    flipResults?: Array<FlipSide> | null;
    createdAt: string;
    drawingTransactionHash: string;
    protocolFeeWei?: BigIntish | null;
    liquidityPoolFeeWei?: BigIntish | null;
    points: BigIntish;
    drawnTransactionHash?: string | null;
    player: {
      __typename?: "User";
      address: any;
      avatar?: {
        __typename?: "Avatar";
        image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
      } | null;
    };
  }>;
};

export type GetUserCoinFlipQueryVariables = Exact<{
  contract: FlipperContract;
  address: Scalars["Address"]["input"];
}>;

export type GetUserCoinFlipQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    currentFlip?: {
      __typename?: "Flipper";
      id: string;
      eventBlock: BigIntish;
      pickSide: FlipSide;
      numberOfRounds: number;
      numberOfRoundsPlayed?: number | null;
      amountPerRoundWei: BigIntish;
      playAmountWei: BigIntish;
      currency: string;
      contract: FlipperContract;
      stopLossWei: BigIntish;
      stopGainWei: BigIntish;
      winningAmountWei?: BigIntish | null;
      status: FlipperStatus;
      flipResults?: Array<FlipSide> | null;
      createdAt: string;
      drawingTransactionHash: string;
      protocolFeeWei?: BigIntish | null;
      liquidityPoolFeeWei?: BigIntish | null;
      points: BigIntish;
      drawnTransactionHash?: string | null;
      player: {
        __typename?: "User";
        address: any;
        avatar?: {
          __typename?: "Avatar";
          image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
        } | null;
      };
    } | null;
  } | null;
};

export type LaserBlastGameFragmentFragment = {
  __typename?: "LaserBlast";
  id: string;
  status: LaserBlastStatus;
  numberOfRounds: number;
  rowCount: number;
  winningAmountWei?: BigIntish | null;
  currency: string;
  playAmountWei: BigIntish;
  points: BigIntish;
  drawnTransactionHash?: string | null;
  drawingTransactionHash: string;
};

export type GetUserLaserBlastCurrentQueryVariables = Exact<{
  contract: LaserBlastContract;
  address: Scalars["Address"]["input"];
}>;

export type GetUserLaserBlastCurrentQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    currentLaserBlast?: {
      __typename?: "LaserBlast";
      laserTravels?: Array<Array<LaserBlastDirection>> | null;
      payouts?: Array<BigIntish> | null;
      drawingTransactionHash: string;
      id: string;
      status: LaserBlastStatus;
      numberOfRounds: number;
      rowCount: number;
      winningAmountWei?: BigIntish | null;
      currency: string;
      playAmountWei: BigIntish;
      points: BigIntish;
      drawnTransactionHash?: string | null;
    } | null;
  } | null;
};

export type GetUserLaserBlastHistoryQueryVariables = Exact<{
  filter: FilterLaserBlastInput;
  sort?: InputMaybe<LaserBlastSort>;
  pagination?: InputMaybe<PaginationInput>;
}>;

export type GetUserLaserBlastHistoryQuery = {
  __typename?: "Query";
  laserBlasts: Array<{
    __typename?: "LaserBlast";
    riskLevel: LaserBlastRiskLevel;
    createdAt: string;
    id: string;
    status: LaserBlastStatus;
    numberOfRounds: number;
    rowCount: number;
    winningAmountWei?: BigIntish | null;
    currency: string;
    playAmountWei: BigIntish;
    points: BigIntish;
    drawnTransactionHash?: string | null;
    drawingTransactionHash: string;
    player: {
      __typename?: "User";
      address: any;
      name?: string | null;
      avatar?: { __typename?: "Avatar"; image?: { __typename?: "MediaData"; src: string } | null } | null;
    };
  }>;
};

export type QuantumUserFragment = {
  __typename?: "User";
  address: any;
  name?: string | null;
  isVerified: boolean;
  avatar?: {
    __typename?: "Avatar";
    image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
  } | null;
};

export type QuantumGameFragment = {
  __typename?: "QuantumGame";
  id: string;
  isAbove: boolean;
  boundary: number;
  multiplier: number;
  results?: Array<number> | null;
  stopLossWei: BigIntish;
  stopGainWei: BigIntish;
  playAmountWei: BigIntish;
  winningAmountWei?: BigIntish | null;
  protocolFeeWei?: BigIntish | null;
  liquidityPoolFeeWei?: BigIntish | null;
  numberOfRounds: number;
  numberOfRoundsPlayed?: number | null;
  createdAt: string;
  currency: string;
  contract: QuantumContract;
  status: QuantumStatus;
  points: BigIntish;
  drawingTransactionHash: string;
  drawnTransactionHash?: string | null;
  user: {
    __typename?: "User";
    address: any;
    name?: string | null;
    isVerified: boolean;
    avatar?: {
      __typename?: "Avatar";
      image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
    } | null;
  };
};

export type GetUserLatestQuantumGameQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
  contract: QuantumContract;
}>;

export type GetUserLatestQuantumGameQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    latestQuantumGame?: {
      __typename?: "QuantumGame";
      id: string;
      isAbove: boolean;
      boundary: number;
      multiplier: number;
      results?: Array<number> | null;
      stopLossWei: BigIntish;
      stopGainWei: BigIntish;
      playAmountWei: BigIntish;
      winningAmountWei?: BigIntish | null;
      protocolFeeWei?: BigIntish | null;
      liquidityPoolFeeWei?: BigIntish | null;
      numberOfRounds: number;
      numberOfRoundsPlayed?: number | null;
      createdAt: string;
      currency: string;
      contract: QuantumContract;
      status: QuantumStatus;
      points: BigIntish;
      drawingTransactionHash: string;
      drawnTransactionHash?: string | null;
      user: {
        __typename?: "User";
        address: any;
        name?: string | null;
        isVerified: boolean;
        avatar?: {
          __typename?: "Avatar";
          image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
        } | null;
      };
    } | null;
  } | null;
};

export type GetQuantumGamesQueryVariables = Exact<{
  filter: QuantumGamesFilterInput;
  pagination?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<QuantumSort>;
}>;

export type GetQuantumGamesQuery = {
  __typename?: "Query";
  quantumGames: Array<{
    __typename?: "QuantumGame";
    id: string;
    isAbove: boolean;
    boundary: number;
    multiplier: number;
    results?: Array<number> | null;
    stopLossWei: BigIntish;
    stopGainWei: BigIntish;
    playAmountWei: BigIntish;
    winningAmountWei?: BigIntish | null;
    protocolFeeWei?: BigIntish | null;
    liquidityPoolFeeWei?: BigIntish | null;
    numberOfRounds: number;
    numberOfRoundsPlayed?: number | null;
    createdAt: string;
    currency: string;
    contract: QuantumContract;
    status: QuantumStatus;
    points: BigIntish;
    drawingTransactionHash: string;
    drawnTransactionHash?: string | null;
    user: {
      __typename?: "User";
      address: any;
      name?: string | null;
      isVerified: boolean;
      avatar?: {
        __typename?: "Avatar";
        image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
      } | null;
    };
  }>;
};

export type EstimateGamePointsQueryVariables = Exact<{
  amountPerRoundWei: Scalars["BigNumber"]["input"];
  currency: Scalars["Address"]["input"];
  numberOfRounds: Scalars["Int"]["input"];
}>;

export type EstimateGamePointsQuery = { __typename?: "Query"; estimateGamePoints: BigIntish };

export type LotteryTicketAllocationFragment = {
  __typename?: "LotteryTicketAllocation";
  remaining: number;
  spent: number;
  earned: number;
};

export type LotteryUserFragment = {
  __typename?: "User";
  address: any;
  name?: string | null;
  isVerified: boolean;
  avatar?: {
    __typename?: "Avatar";
    image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
  } | null;
};

export type LotteryEntryFragment = {
  __typename?: "LotteryEntry";
  id: number;
  amount: number;
  rank: number;
  player: {
    __typename?: "User";
    address: any;
    name?: string | null;
    isVerified: boolean;
    avatar?: {
      __typename?: "Avatar";
      image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
    } | null;
  };
};

export type LotteryPrizeFragment = {
  __typename?: "LotteryPrize";
  rank: number;
  amount: BigIntish;
  winnerEntryAmount?: number | null;
  winner?: {
    __typename?: "User";
    address: any;
    name?: string | null;
    isVerified: boolean;
    avatar?: {
      __typename?: "Avatar";
      image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
    } | null;
  } | null;
};

export type LotteryFragment = {
  __typename?: "Lottery";
  id: BigIntish;
  type: LotteryType;
  status: LotteryStatus;
  vrfLink?: string | null;
  totalPlayers: BigIntish;
  totalValue: BigIntish;
  totalEntries: BigIntish;
  lotteryDate: string;
  prizes: Array<{
    __typename?: "LotteryPrize";
    rank: number;
    amount: BigIntish;
    winnerEntryAmount?: number | null;
    winner?: {
      __typename?: "User";
      address: any;
      name?: string | null;
      isVerified: boolean;
      avatar?: {
        __typename?: "Avatar";
        image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
      } | null;
    } | null;
  }>;
  userPrize: Array<{
    __typename?: "LotteryPrize";
    rank: number;
    amount: BigIntish;
    winnerEntryAmount?: number | null;
    winner?: {
      __typename?: "User";
      address: any;
      name?: string | null;
      isVerified: boolean;
      avatar?: {
        __typename?: "Avatar";
        image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
      } | null;
    } | null;
  }>;
  userEntry: Array<{
    __typename?: "LotteryEntry";
    id: number;
    amount: number;
    rank: number;
    player: {
      __typename?: "User";
      address: any;
      name?: string | null;
      isVerified: boolean;
      avatar?: {
        __typename?: "Avatar";
        image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
      } | null;
    };
  }>;
};

export type GetLotteryQueryVariables = Exact<{
  id: Scalars["BigNumber"]["input"];
  player?: InputMaybe<Scalars["Address"]["input"]>;
}>;

export type GetLotteryQuery = {
  __typename?: "Query";
  lottery?: {
    __typename?: "Lottery";
    id: BigIntish;
    type: LotteryType;
    status: LotteryStatus;
    vrfLink?: string | null;
    totalPlayers: BigIntish;
    totalValue: BigIntish;
    totalEntries: BigIntish;
    lotteryDate: string;
    prizes: Array<{
      __typename?: "LotteryPrize";
      rank: number;
      amount: BigIntish;
      winnerEntryAmount?: number | null;
      winner?: {
        __typename?: "User";
        address: any;
        name?: string | null;
        isVerified: boolean;
        avatar?: {
          __typename?: "Avatar";
          image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
        } | null;
      } | null;
    }>;
    userPrize: Array<{
      __typename?: "LotteryPrize";
      rank: number;
      amount: BigIntish;
      winnerEntryAmount?: number | null;
      winner?: {
        __typename?: "User";
        address: any;
        name?: string | null;
        isVerified: boolean;
        avatar?: {
          __typename?: "Avatar";
          image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
        } | null;
      } | null;
    }>;
    userEntry: Array<{
      __typename?: "LotteryEntry";
      id: number;
      amount: number;
      rank: number;
      player: {
        __typename?: "User";
        address: any;
        name?: string | null;
        isVerified: boolean;
        avatar?: {
          __typename?: "Avatar";
          image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
        } | null;
      };
    }>;
  } | null;
};

export type GetLotteryEntriesQueryVariables = Exact<{
  filter?: InputMaybe<FilterLotteryInput>;
  pagination?: InputMaybe<PaginationInput>;
}>;

export type GetLotteryEntriesQuery = {
  __typename?: "Query";
  lotteries: Array<{
    __typename?: "Lottery";
    entries: Array<{
      __typename?: "LotteryEntry";
      id: number;
      amount: number;
      rank: number;
      player: {
        __typename?: "User";
        address: any;
        name?: string | null;
        isVerified: boolean;
        avatar?: {
          __typename?: "Avatar";
          image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
        } | null;
      };
    }>;
  }>;
};

export type GetLotteriesQueryVariables = Exact<{
  player?: InputMaybe<Scalars["Address"]["input"]>;
  filter?: InputMaybe<FilterLotteryInput>;
  pagination?: InputMaybe<PaginationInput>;
}>;

export type GetLotteriesQuery = {
  __typename?: "Query";
  lotteries: Array<{
    __typename?: "Lottery";
    id: BigIntish;
    type: LotteryType;
    status: LotteryStatus;
    vrfLink?: string | null;
    totalPlayers: BigIntish;
    totalValue: BigIntish;
    totalEntries: BigIntish;
    lotteryDate: string;
    prizes: Array<{
      __typename?: "LotteryPrize";
      rank: number;
      amount: BigIntish;
      winnerEntryAmount?: number | null;
      winner?: {
        __typename?: "User";
        address: any;
        name?: string | null;
        isVerified: boolean;
        avatar?: {
          __typename?: "Avatar";
          image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
        } | null;
      } | null;
    }>;
    userPrize: Array<{
      __typename?: "LotteryPrize";
      rank: number;
      amount: BigIntish;
      winnerEntryAmount?: number | null;
      winner?: {
        __typename?: "User";
        address: any;
        name?: string | null;
        isVerified: boolean;
        avatar?: {
          __typename?: "Avatar";
          image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
        } | null;
      } | null;
    }>;
    userEntry: Array<{
      __typename?: "LotteryEntry";
      id: number;
      amount: number;
      rank: number;
      player: {
        __typename?: "User";
        address: any;
        name?: string | null;
        isVerified: boolean;
        avatar?: {
          __typename?: "Avatar";
          image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
        } | null;
      };
    }>;
  }>;
};

export type GetLotteryUserDataQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
}>;

export type GetLotteryUserDataQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    lotteryUnclaimedEthWei?: BigIntish | null;
    lotteryTickets?: {
      __typename?: "LotteryTicketAllocation";
      remaining: number;
      spent: number;
      earned: number;
    } | null;
  } | null;
};

export type GetLotteryPrizePoolQueryVariables = Exact<{
  filter?: InputMaybe<FilterLotteryInput>;
}>;

export type GetLotteryPrizePoolQuery = {
  __typename?: "Query";
  lotteries: Array<{ __typename?: "Lottery"; id: BigIntish; totalValue: BigIntish }>;
};

export type EnterLotteryMutationVariables = Exact<{
  id: Scalars["BigNumber"]["input"];
  amount: Scalars["Int"]["input"];
}>;

export type EnterLotteryMutation = {
  __typename?: "Mutation";
  enterLottery: { __typename?: "SuccessPayload"; success: boolean };
};

export type ClaimWinningsMutationVariables = Exact<{ [key: string]: never }>;

export type ClaimWinningsMutation = {
  __typename?: "Mutation";
  claimLotteries: { __typename?: "SuccessPayload"; success: boolean };
};

export type MoDPeriodResultFragment = { __typename?: "MoDPeriodResult"; numOfMoons: number; numOfDooms: number };

export type MoDRoundResultFragment = { __typename?: "MoDRoundResult"; result: MoDResult; payoutRatio?: number | null };

export type MoDRoundSettingFragment = {
  __typename?: "MoDRoundSetting";
  minimumEnterAmount: BigIntish;
  roundIntervalSecs: number;
};

export type MoDStatsFragment = {
  __typename?: "MoDStats";
  oneHourResult: { __typename?: "MoDPeriodResult"; numOfMoons: number; numOfDooms: number };
  oneDayResult: { __typename?: "MoDPeriodResult"; numOfMoons: number; numOfDooms: number };
};

export type MoDUnclaimedEntryFragment = {
  __typename?: "MoDUnclaimedEntry";
  amount: BigIntish;
  roundOnChainIds: Array<number>;
  isRefund: boolean;
};

export type MoDUnclaimedEntriesFragment = {
  __typename?: "User";
  modUnclaimedEntries: Array<{
    __typename?: "MoDUnclaimedEntry";
    amount: BigIntish;
    roundOnChainIds: Array<number>;
    isRefund: boolean;
  }>;
};

export type MoDEntryFragment = {
  __typename?: "MoDEntry";
  moonPosition: boolean;
  amount: BigIntish;
  payoutAmount?: BigIntish | null;
};

export type MoDRoundFragment = {
  __typename?: "MoDRound";
  id: number;
  onChainId: number;
  startedAt: string;
  lockedAt: string;
  closedAt?: string | null;
  lockPrice?: BigIntish | null;
  closePrice?: BigIntish | null;
  oracleCalled: boolean;
  totalAmount: BigIntish;
  moonAmount?: BigIntish | null;
  moonPayoutRatio?: number | null;
  doomAmount?: BigIntish | null;
  doomPayoutRatio?: number | null;
  status: MoDRoundStatus;
  result?: { __typename?: "MoDRoundResult"; result: MoDResult; payoutRatio?: number | null } | null;
  setting: { __typename?: "MoDRoundSetting"; minimumEnterAmount: BigIntish; roundIntervalSecs: number };
  entries?: Array<{
    __typename?: "MoDEntry";
    moonPosition: boolean;
    amount: BigIntish;
    payoutAmount?: BigIntish | null;
  }> | null;
};

export type MoDRoundsQueryVariables = Exact<{
  filter: MoDFilterInput;
  player?: InputMaybe<Scalars["Address"]["input"]>;
  pagination?: InputMaybe<PaginationInput>;
}>;

export type MoDRoundsQuery = {
  __typename?: "Query";
  modRounds: Array<{
    __typename?: "MoDRound";
    id: number;
    onChainId: number;
    startedAt: string;
    lockedAt: string;
    closedAt?: string | null;
    lockPrice?: BigIntish | null;
    closePrice?: BigIntish | null;
    oracleCalled: boolean;
    totalAmount: BigIntish;
    moonAmount?: BigIntish | null;
    moonPayoutRatio?: number | null;
    doomAmount?: BigIntish | null;
    doomPayoutRatio?: number | null;
    status: MoDRoundStatus;
    result?: { __typename?: "MoDRoundResult"; result: MoDResult; payoutRatio?: number | null } | null;
    setting: { __typename?: "MoDRoundSetting"; minimumEnterAmount: BigIntish; roundIntervalSecs: number };
    entries?: Array<{
      __typename?: "MoDEntry";
      moonPosition: boolean;
      amount: BigIntish;
      payoutAmount?: BigIntish | null;
    }> | null;
  }>;
};

export type MoDRoundQueryVariables = Exact<{
  contract: MoDContract;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  player?: InputMaybe<Scalars["Address"]["input"]>;
}>;

export type MoDRoundQuery = {
  __typename?: "Query";
  modRound?: {
    __typename?: "MoDRound";
    id: number;
    onChainId: number;
    startedAt: string;
    lockedAt: string;
    closedAt?: string | null;
    lockPrice?: BigIntish | null;
    closePrice?: BigIntish | null;
    oracleCalled: boolean;
    totalAmount: BigIntish;
    moonAmount?: BigIntish | null;
    moonPayoutRatio?: number | null;
    doomAmount?: BigIntish | null;
    doomPayoutRatio?: number | null;
    status: MoDRoundStatus;
    result?: { __typename?: "MoDRoundResult"; result: MoDResult; payoutRatio?: number | null } | null;
    setting: { __typename?: "MoDRoundSetting"; minimumEnterAmount: BigIntish; roundIntervalSecs: number };
    entries?: Array<{
      __typename?: "MoDEntry";
      moonPosition: boolean;
      amount: BigIntish;
      payoutAmount?: BigIntish | null;
    }> | null;
  } | null;
};

export type MoDStatsQueryVariables = Exact<{
  contract: MoDContract;
}>;

export type MoDStatsQuery = {
  __typename?: "Query";
  modStats: {
    __typename?: "MoDStats";
    oneHourResult: { __typename?: "MoDPeriodResult"; numOfMoons: number; numOfDooms: number };
    oneDayResult: { __typename?: "MoDPeriodResult"; numOfMoons: number; numOfDooms: number };
  };
};

export type MoDHistoricalPricesQueryVariables = Exact<{
  pair: MoDAssetPair;
}>;

export type MoDHistoricalPricesQuery = {
  __typename?: "Query";
  modHistoricalPrices: Array<{ __typename?: "MoDHistoricalPrice"; timestamp: number; price: BigIntish }>;
};

export type MoDUnclaimedEntriesQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
  contract: MoDContract;
}>;

export type MoDUnclaimedEntriesQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    modUnclaimedEntries: Array<{
      __typename?: "MoDUnclaimedEntry";
      amount: BigIntish;
      roundOnChainIds: Array<number>;
      isRefund: boolean;
    }>;
  } | null;
};

export type UserBoostProgressQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
}>;

export type UserBoostProgressQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    yoloBoostMetrics?: {
      __typename?: "YoloBoostMetrics";
      boostAllocationWei: BigIntish;
      boostSpentWei: BigIntish;
      progressPercentage: number;
    } | null;
  } | null;
};

export type ConnectUserSocialMutationVariables = Exact<{
  platform: Social_Platform;
  data: ConnectSocialInput;
}>;

export type ConnectUserSocialMutation = {
  __typename?: "Mutation";
  connectUserSocial: { __typename?: "SuccessPayload"; success: boolean };
};

export type DisconnectUserSocialMutationVariables = Exact<{
  platform: Social_Platform;
}>;

export type DisconnectUserSocialMutation = {
  __typename?: "Mutation";
  disconnectUserSocial: { __typename?: "SuccessPayload"; success: boolean };
};

export type CheckUsernameValidityQueryVariables = Exact<{
  username: Scalars["String"]["input"];
}>;

export type CheckUsernameValidityQuery = { __typename?: "Query"; usernameValid: boolean };

export type UpdateUserMutationMutationVariables = Exact<{
  userData: UserUpdateInput;
}>;

export type UpdateUserMutationMutation = {
  __typename?: "Mutation";
  updateUser: { __typename?: "SuccessPayload"; success: boolean };
};

export type ToggleProfileImageVisibilityMutationVariables = Exact<{ [key: string]: never }>;

export type ToggleProfileImageVisibilityMutation = {
  __typename?: "Mutation";
  toggleProfileImageVisibility: { __typename?: "SuccessPayload"; success: boolean };
};

export type UpdateUserEmailMutationMutationVariables = Exact<{
  email?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateUserEmailMutationMutation = {
  __typename?: "Mutation";
  updateUserEmail: { __typename?: "SuccessPayload"; success: boolean };
};

export type ConfirmUserEmailMutationMutationVariables = Exact<{
  confirmationId: Scalars["String"]["input"];
}>;

export type ConfirmUserEmailMutationMutation = {
  __typename?: "Mutation";
  confirmUserEmail: { __typename?: "SuccessPayload"; success: boolean };
};

export type GetBoostAllocationQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
}>;

export type GetBoostAllocationQuery = {
  __typename?: "Query";
  boostAllocation?: {
    __typename?: "BoostAllocation";
    boostFromEthWei: BigIntish;
    boostFromUsdWei: BigIntish;
    ethDepositedWei: BigIntish;
    usdDepositedWei: BigIntish;
    totalBoostWei: BigIntish;
    claimed: boolean;
    burned: boolean;
    proof: Array<string>;
  } | null;
};

export type ExitBoostMutationVariables = Exact<{ [key: string]: never }>;

export type ExitBoostMutation = {
  __typename?: "Mutation";
  exitBoostMode: { __typename?: "SuccessPayload"; success: boolean };
};

export type SubscribeEmailMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type SubscribeEmailMutation = {
  __typename?: "Mutation";
  subscribeEmail: { __typename?: "SuccessPayload"; success: boolean };
};

export type UserFragmentFragment = {
  __typename?: "User";
  address: any;
  name?: string | null;
  biography?: string | null;
  email?: string | null;
  selfTimeoutUntil?: string | null;
  isEmailVerified?: boolean | null;
  twitterUsername?: string | null;
  discordUsername?: string | null;
  isProfileImageVisible: boolean;
  isVerified: boolean;
  luckySpinTickets: BigIntish;
  hasPlayedGames: boolean;
  avatar?: {
    __typename?: "Avatar";
    image?: { __typename?: "MediaData"; contentType?: string | null; src: string } | null;
  } | null;
  luckySpinUnclaimedPrizesWei: { __typename?: "UnclaimedPrizesWei"; ethWei: BigIntish; yoloWei: BigIntish };
};

export type UserPointsFragmentFragment = {
  __typename?: "User";
  unclaimedPoints?: BigIntish | null;
  lotteryTickets?: { __typename?: "LotteryTicketAllocation"; remaining: number; spent: number; earned: number } | null;
  seasonPoints?: {
    __typename?: "SeasonPoints";
    dailyRank: BigIntish;
    points24h: BigIntish;
    seasonPoints: BigIntish;
    seasonRank: BigIntish;
    multiplier?: number | null;
    details?: { __typename?: "UserPointsDetails"; games: BigIntish; referrals: BigIntish; quests: BigIntish } | null;
  } | null;
  epochPoints?: {
    __typename?: "SeasonPoints";
    dailyRank: BigIntish;
    points24h: BigIntish;
    seasonPoints: BigIntish;
    seasonRank: BigIntish;
    multiplier?: number | null;
    details?: { __typename?: "UserPointsDetails"; games: BigIntish; referrals: BigIntish; quests: BigIntish } | null;
  } | null;
};

export type CurrentSeasonFragmentFragment = {
  __typename?: "SeasonDetails";
  season: number;
  startTime: string;
  endTime: string;
  rewardPool: BigIntish;
  rewardPoolBlastGold?: BigIntish | null;
};

export type RakebackFragmentFragment = {
  __typename?: "Rakeback";
  status: RakebackStatus;
  amountWei: BigIntish;
  unlocksAt: string;
  expiresAt: string;
  claimedAt?: string | null;
  currency: { __typename?: "Currency"; address: any; decimals: number; name: string; symbol: string };
};

export type LuckySpinEntryQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type LuckySpinEntryQuery = {
  __typename?: "Query";
  luckySpinEntry?: {
    __typename?: "LuckySpinEntry";
    id: string;
    createdAt: string;
    drawnAt?: string | null;
    ticketAmount: number;
    vrfUrl?: string | null;
    prize?: { __typename?: "LuckySpinPrize"; id: BigIntish; amount: BigIntish; type: PrizeType } | null;
  } | null;
};

export type PlayLuckySpinMutationVariables = Exact<{
  tickets: Scalars["Int"]["input"];
}>;

export type PlayLuckySpinMutation = { __typename?: "Mutation"; playLuckySpin: BigIntish };

export type ClaimEthPrizesMutationVariables = Exact<{ [key: string]: never }>;

export type ClaimEthPrizesMutation = {
  __typename?: "Mutation";
  claimLuckySpinPrizes: { __typename?: "SuccessPayload"; success: boolean };
};

export type GetLuckySpinPrizeTotalsQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
}>;

export type GetLuckySpinPrizeTotalsQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    luckySpinPrizeTotals: {
      __typename?: "LuckySpinPrizeTotals";
      pointsUntilNextTicket: BigIntish;
      ethWei: BigIntish;
      yoloWei: BigIntish;
      seasonPoints: BigIntish;
      blastGoldTotal: number;
      blastGoldPendingDistribution: number;
      spentTickets: BigIntish;
    };
  } | null;
};

export type GetLuckySpinMaxAvailablePrizesQueryVariables = Exact<{ [key: string]: never }>;

export type GetLuckySpinMaxAvailablePrizesQuery = {
  __typename?: "Query";
  luckySpinMaxAvailablePrize: {
    __typename?: "PrizeMaxAvailable";
    blastGoldBonus: BigIntish;
    blastGoldPrize: BigIntish;
    yoloWei: BigIntish;
    ethWei: BigIntish;
    seasonPoints: BigIntish;
  };
};

export type GetUserSeasonPointsQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
}>;

export type GetUserSeasonPointsQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    unclaimedPoints?: BigIntish | null;
    lotteryTickets?: {
      __typename?: "LotteryTicketAllocation";
      remaining: number;
      spent: number;
      earned: number;
    } | null;
    seasonPoints?: {
      __typename?: "SeasonPoints";
      dailyRank: BigIntish;
      points24h: BigIntish;
      seasonPoints: BigIntish;
      seasonRank: BigIntish;
      multiplier?: number | null;
      details?: { __typename?: "UserPointsDetails"; games: BigIntish; referrals: BigIntish; quests: BigIntish } | null;
    } | null;
    epochPoints?: {
      __typename?: "SeasonPoints";
      dailyRank: BigIntish;
      points24h: BigIntish;
      seasonPoints: BigIntish;
      seasonRank: BigIntish;
      multiplier?: number | null;
      details?: { __typename?: "UserPointsDetails"; games: BigIntish; referrals: BigIntish; quests: BigIntish } | null;
    } | null;
  } | null;
};

export type GetCurrentPointsSeasonQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentPointsSeasonQuery = {
  __typename?: "Query";
  pointsSeason: {
    __typename?: "SeasonDetails";
    season: number;
    startTime: string;
    endTime: string;
    rewardPool: BigIntish;
    rewardPoolBlastGold?: BigIntish | null;
  };
};

export type GetPointsSeasonDetailsQueryVariables = Exact<{
  season: Scalars["Int"]["input"];
}>;

export type GetPointsSeasonDetailsQuery = {
  __typename?: "Query";
  seasonDetails?: {
    __typename?: "SeasonDetails";
    season: number;
    startTime: string;
    endTime: string;
    rewardPool: BigIntish;
  } | null;
};

export type GetCurrentSeasonLeaderboardQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>;
  sort?: InputMaybe<LeaderboardSortInput>;
}>;

export type GetCurrentSeasonLeaderboardQuery = {
  __typename?: "Query";
  pointsLeaderboard: {
    __typename?: "PointsLeaderboard";
    total: BigIntish;
    points: Array<{
      __typename?: "SeasonPoints";
      dailyRank: BigIntish;
      multiplier?: number | null;
      points24h: BigIntish;
      seasonRank: BigIntish;
      seasonPoints: BigIntish;
      user?: {
        __typename?: "User";
        address: any;
        name?: string | null;
        isVerified: boolean;
        avatar?: {
          __typename?: "Avatar";
          image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
        } | null;
      } | null;
    }>;
  };
};

export type GetCurrentEpochLeaderboardQueryVariables = Exact<{
  pagination?: InputMaybe<OffsetPaginationInput>;
}>;

export type GetCurrentEpochLeaderboardQuery = {
  __typename?: "Query";
  epochLeaderboard: {
    __typename?: "PointsLeaderboard";
    total: BigIntish;
    points: Array<{
      __typename?: "SeasonPoints";
      dailyRank: BigIntish;
      multiplier?: number | null;
      points24h: BigIntish;
      seasonRank: BigIntish;
      seasonPoints: BigIntish;
      user?: {
        __typename?: "User";
        address: any;
        name?: string | null;
        isVerified: boolean;
        avatar?: {
          __typename?: "Avatar";
          image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
        } | null;
      } | null;
    }>;
  };
};

export type GetSeasonAllocationsQueryVariables = Exact<{
  season: Scalars["Int"]["input"];
  pagination?: InputMaybe<OffsetPaginationInput>;
}>;

export type GetSeasonAllocationsQuery = {
  __typename?: "Query";
  seasonAllocations: Array<{
    __typename?: "SeasonAllocation";
    points: BigIntish;
    rank: number;
    blastGoldAllocation: BigIntish;
    yTokenWeiAllocation: BigIntish;
    user: {
      __typename?: "User";
      address: any;
      name?: string | null;
      isVerified: boolean;
      isProfileImageVisible: boolean;
      avatar?: {
        __typename?: "Avatar";
        image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
      } | null;
    };
  }>;
};

export type GetUserSeasonRewardsQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
  season: Scalars["Int"]["input"];
}>;

export type GetUserSeasonRewardsQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    seasonRewards?: {
      __typename?: "SeasonReward";
      rank: number;
      points: BigIntish;
      blastGoldAllocation: BigIntish;
      yTokenWeiAllocation: BigIntish;
      yTokenWeiFromShards?: BigIntish | null;
      stakingShards?: BigIntish | null;
      yTokenVestingClaimDetails: {
        __typename?: "YTokenVestingClaimDetails";
        proof?: Array<string> | null;
        vestingAllocationWei: BigIntish;
        remainingVolumeToClaimWei?: BigIntish | null;
        currentClaimableAmountWei: BigIntish;
        volumeWei?: BigIntish | null;
        claimedWei: BigIntish;
      };
      vaultSharesClaimDetails: {
        __typename?: "VaultSharesClaimDetails";
        yTokensDepositedWei: BigIntish;
        proof?: Array<string> | null;
        vaultSharesClaimableVum: BigIntish;
        claimedAt?: string | null;
      };
    } | null;
  } | null;
};

export type MilestoneFragmentFragment = {
  __typename?: "UserMilestoneLevel";
  code: MilestoneLevelCode;
  milestone: MilestoneCode;
  points: number;
  progress: BigIntish;
  goal: BigIntish;
  claimedAt?: string | null;
  createdAt?: string | null;
};

export type GetUserMilestonesQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
}>;

export type GetUserMilestonesQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    milestones: {
      __typename?: "Milestones";
      DONT_FALL_IN_ETH: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      DONT_FALL_IN_ROUND: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      DONT_FALL_IN_WIN: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      DONT_FALL_IN_WIN_STREAK: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      FLIPPER_ETH: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      FLIPPER_ROUND: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      FLIPPER_WIN: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      FLIPPER_WIN_STREAK: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      LASER_BLAST_ETH: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      LASER_BLAST_ROUND: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      QUANTUM_ETH: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      QUANTUM_WIN_STREAK: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      QUANTUM_WIN: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      QUANTUM_ROUND: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      PROFILE_SETUP: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      POINTS: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      MORD_ROUND: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      MORD_ETH: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      MORD_WIN_STREAK: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      MORD_WIN: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      PTB_FUTURE_ROUND: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      PTB_ETH: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      PTB_WIN_STREAK: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      PTB_WIN: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      YOLO_FUTURE_ROUND: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      YOLO_ROUND: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      YOLO_ETH: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
      YOLO_WIN: Array<{
        __typename?: "UserMilestoneLevel";
        code: MilestoneLevelCode;
        milestone: MilestoneCode;
        points: number;
        progress: BigIntish;
        goal: BigIntish;
        claimedAt?: string | null;
        createdAt?: string | null;
      }>;
    };
  } | null;
};

export type GetUserDailyQuestsQueryVariables = Exact<{
  address?: InputMaybe<Scalars["Address"]["input"]>;
}>;

export type GetUserDailyQuestsQuery = {
  __typename?: "Query";
  dailyQuests: {
    __typename?: "DailyQuests";
    nextDay: string;
    quests: Array<{
      __typename?: "DailyQuest";
      claimedAt?: string | null;
      createdAt?: string | null;
      code: DailyQuestCode;
      goal: BigIntish;
      points: number;
      progress: BigIntish;
    }>;
  };
};

export type ClaimMilestoneMutationVariables = Exact<{
  code: MilestoneLevelCode;
}>;

export type ClaimMilestoneMutation = {
  __typename?: "Mutation";
  claimMilestoneLevel: { __typename?: "SuccessPayload"; success: boolean };
};

export type ClaimDailyQuestMutationVariables = Exact<{
  code: DailyQuestCode;
}>;

export type ClaimDailyQuestMutation = {
  __typename?: "Mutation";
  claimDailyQuest: { __typename?: "SuccessPayload"; success: boolean };
};

export type GetUserRakebackDataQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
}>;

export type GetUserRakebackDataQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    rakebacks: {
      __typename?: "Rakebacks";
      lastRoundId?: string | null;
      totalClaimed: Array<{
        __typename?: "CurrencyAmount";
        amountWei: BigIntish;
        amountEthWei: BigIntish;
        currency: { __typename?: "Currency"; address: any; decimals: number; name: string; symbol: string };
      }>;
      totalPending: Array<{
        __typename?: "CurrencyAmount";
        amountWei: BigIntish;
        amountEthWei: BigIntish;
        currency: { __typename?: "Currency"; address: any; decimals: number; name: string; symbol: string };
      }>;
      rakebacks: {
        __typename?: "GroupedRakebacks";
        locked: Array<{
          __typename?: "Rakeback";
          status: RakebackStatus;
          amountWei: BigIntish;
          unlocksAt: string;
          expiresAt: string;
          claimedAt?: string | null;
          currency: { __typename?: "Currency"; address: any; decimals: number; name: string; symbol: string };
        }>;
        unlocked: Array<{
          __typename?: "Rakeback";
          status: RakebackStatus;
          amountWei: BigIntish;
          unlocksAt: string;
          expiresAt: string;
          claimedAt?: string | null;
          currency: { __typename?: "Currency"; address: any; decimals: number; name: string; symbol: string };
        }>;
        history: Array<{
          __typename?: "Rakeback";
          status: RakebackStatus;
          amountWei: BigIntish;
          unlocksAt: string;
          expiresAt: string;
          claimedAt?: string | null;
          currency: { __typename?: "Currency"; address: any; decimals: number; name: string; symbol: string };
        }>;
      };
    };
    nextRakeback?: {
      __typename?: "Rakeback";
      status: RakebackStatus;
      amountWei: BigIntish;
      unlocksAt: string;
      expiresAt: string;
      claimedAt?: string | null;
      currency: { __typename?: "Currency"; address: any; decimals: number; name: string; symbol: string };
    } | null;
    rakebackClaimDetails: Array<{
      __typename?: "RakebackClaimDetails";
      cumulativeAmountWei: BigIntish;
      endsAt: string;
      proof: Array<string>;
      currency: { __typename?: "Currency"; address: any; decimals: number; name: string; symbol: string };
    } | null>;
  } | null;
};

export type GetUserRakebacksQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
  filter?: InputMaybe<RakebacksFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
}>;

export type GetUserRakebacksQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    rakebacks: {
      __typename?: "Rakebacks";
      lastRoundId?: string | null;
      rakebacks: {
        __typename?: "GroupedRakebacks";
        locked: Array<{
          __typename?: "Rakeback";
          status: RakebackStatus;
          amountWei: BigIntish;
          unlocksAt: string;
          expiresAt: string;
          claimedAt?: string | null;
          currency: { __typename?: "Currency"; address: any; decimals: number; name: string; symbol: string };
        }>;
        unlocked: Array<{
          __typename?: "Rakeback";
          status: RakebackStatus;
          amountWei: BigIntish;
          unlocksAt: string;
          expiresAt: string;
          claimedAt?: string | null;
          currency: { __typename?: "Currency"; address: any; decimals: number; name: string; symbol: string };
        }>;
        history: Array<{
          __typename?: "Rakeback";
          status: RakebackStatus;
          amountWei: BigIntish;
          unlocksAt: string;
          expiresAt: string;
          claimedAt?: string | null;
          currency: { __typename?: "Currency"; address: any; decimals: number; name: string; symbol: string };
        }>;
      };
    };
  } | null;
};

export type RecentGameWinsQueryVariables = Exact<{
  sort: RecentGameWinSort;
}>;

export type RecentGameWinsQuery = {
  __typename?: "Query";
  recentGameWins: Array<{
    __typename?: "GameWinResult";
    amountWonWei: BigIntish;
    createdAt: string;
    entryAmountWei: BigIntish;
    game: GameName;
    winner: {
      __typename?: "User";
      address: any;
      name?: string | null;
      isVerified: boolean;
      avatar?: {
        __typename?: "Avatar";
        image?: { __typename?: "MediaData"; src: string; contentType?: string | null } | null;
      } | null;
    };
    currency: { __typename?: "Currency"; address: any; symbol: string; decimals: number };
  }>;
};

export type AddYgAffiliateMutationVariables = Exact<{
  referralCode: Scalars["String"]["input"];
}>;

export type AddYgAffiliateMutation = {
  __typename?: "Mutation";
  addAffiliate: { __typename?: "SuccessPayload"; success: boolean };
};

export type IsReferralCodeValidQueryVariables = Exact<{
  referralCode: Scalars["String"]["input"];
}>;

export type IsReferralCodeValidQuery = { __typename?: "Query"; isReferralCodeValid: boolean };

export type GetGameStatsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGameStatsQuery = {
  __typename?: "Query";
  gameStats: {
    __typename?: "GameStats";
    totalPlayers: BigIntish;
    totalGamesPlayed: BigIntish;
    volumePlayedWei: BigIntish;
  };
};

export type GetUserQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
}>;

export type GetUserQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    address: any;
    name?: string | null;
    biography?: string | null;
    email?: string | null;
    selfTimeoutUntil?: string | null;
    isEmailVerified?: boolean | null;
    twitterUsername?: string | null;
    discordUsername?: string | null;
    isProfileImageVisible: boolean;
    isVerified: boolean;
    luckySpinTickets: BigIntish;
    hasPlayedGames: boolean;
    avatar?: {
      __typename?: "Avatar";
      image?: { __typename?: "MediaData"; contentType?: string | null; src: string } | null;
    } | null;
    luckySpinUnclaimedPrizesWei: { __typename?: "UnclaimedPrizesWei"; ethWei: BigIntish; yoloWei: BigIntish };
  } | null;
};

export type GetReferralCodeQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
}>;

export type GetReferralCodeQuery = {
  __typename?: "Query";
  user?: { __typename?: "User"; referralCode?: string | null } | null;
};

export type UserReferrerQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
}>;

export type UserReferrerQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    referral?: {
      __typename?: "Referral";
      hasPendingOrConfirmedReferrer: boolean;
      primaryReferrer?: { __typename?: "User"; address: any } | null;
    } | null;
  } | null;
};

export type AskSelfTimeoutMutationVariables = Exact<{
  timeoutUntil: Scalars["DateTime"]["input"];
}>;

export type AskSelfTimeoutMutation = {
  __typename?: "Mutation";
  askSelfTimeout: { __typename?: "SuccessPayload"; success: boolean };
};

export type UserMidSeasonRewardsQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
}>;

export type UserMidSeasonRewardsQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    midSeasonGoldDistribution: {
      __typename?: "MidSeasonGoldDistribution";
      gamesGold?: number | null;
      dropletsGold?: number | null;
      yYoloGold?: number | null;
    };
  } | null;
};

export type LogoutMutationMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutationMutation = {
  __typename?: "Mutation";
  logout: { __typename?: "SuccessPayload"; success: boolean };
};

export type LiquidityPoolsQueryVariables = Exact<{
  user?: InputMaybe<Scalars["Address"]["input"]>;
}>;

export type LiquidityPoolsQuery = {
  __typename?: "Query";
  liquidityPools: Array<{
    __typename?: "LiquidityPool";
    contract: LiquidityPoolContract;
    isActive: boolean;
    userPoolPct: number;
    userSharesValueVum: BigIntish;
    assetsBalanceVum: BigIntish;
    pendingDepositsAmountVum: BigIntish;
    liquidityPoolConfig: {
      __typename?: "LiquidityPoolConfig";
      finalizationIncentiveEthWei: BigIntish;
      depositFeeBps: number;
      minDepositVum: BigIntish;
      maxDepositVum: BigIntish;
      timelockDelaySecs: number;
      assetsLimitVum: BigIntish;
    };
    liquidityPoolSeason?: {
      __typename?: "UserLiquidityPoolSeason";
      dailyDroplets: number;
      currentMultiplier: number;
      nextIncreaseAt?: string | null;
      nextMultiplierIncrease: number;
    } | null;
    currency: { __typename?: "Currency"; address: any; name: string; decimals: number; symbol: string };
  }>;
};

export type LiquidityPoolTotalDropletsQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
}>;

export type LiquidityPoolTotalDropletsQuery = {
  __typename?: "Query";
  user?: { __typename?: "User"; liquidityPoolSeasonDroplets?: BigIntish | null } | null;
};

export type LiquidyPoolPendingActionsQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
}>;

export type LiquidyPoolPendingActionsQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    liquidityPoolPendingActions: Array<{
      __typename?: "LiquidityPoolPendingAction";
      contract: LiquidityPoolContract;
      type: LiquidityPoolEventType;
      expectedAssetsAmountVum: BigIntish;
      finalizationIncentiveEthWei: BigIntish;
      protectedUntil: string;
      startedAt: string;
      transactionHash: string;
      unlocksAt: string;
      currency: { __typename?: "Currency"; address: any; name: string; decimals: number; symbol: string };
    }>;
  } | null;
};

export type UserLiquidityPoolEventsQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
  pagination: PaginationInput;
}>;

export type UserLiquidityPoolEventsQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    liquidityPoolEvents: Array<{
      __typename?: "LiquidityPoolEvent";
      assetsAmountUsd?: number | null;
      assetsAmountVum: BigIntish;
      contract: LiquidityPoolContract;
      createdAt: string;
      isTransfer: boolean;
      transactionHash: string;
      type: LiquidityPoolEventType;
      currency: { __typename?: "Currency"; address: any; name: string; decimals: number; symbol: string };
    }>;
  } | null;
};

export type UserLiquidityPoolMetricsQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
  filters: LiquidityPoolUserMetricsFilterInput;
}>;

export type UserLiquidityPoolMetricsQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    liquidityPoolMetrics: Array<{
      __typename?: "LiquidityPoolTimeseries";
      data: Array<Array<number>>;
      dataGranularity: LiquidityPoolGranularity;
      gameContract?: LiquidityPoolGame | null;
      currentCumulativeTotal: number;
      previousCumulativeTotal: number;
      intervalTotal: number;
      lastAlignedAt: string;
      metricType: LiquidityPoolMetric;
      currency: { __typename?: "Currency"; address: any; name: string; decimals: number; symbol: string };
    }>;
  } | null;
};

export type LiquidityPoolMetricsQueryVariables = Exact<{
  filters: LiquidityPoolMetricsFilterInput;
}>;

export type LiquidityPoolMetricsQuery = {
  __typename?: "Query";
  liquidityPoolMetrics: Array<{
    __typename?: "LiquidityPoolTimeseries";
    data: Array<Array<number>>;
    dataGranularity: LiquidityPoolGranularity;
    gameContract?: LiquidityPoolGame | null;
    intervalTotal: number;
    currentCumulativeTotal: number;
    previousCumulativeTotal: number;
    lastAlignedAt: string;
    metricType: LiquidityPoolMetric;
    currency: { __typename?: "Currency"; address: any; name: string; decimals: number; symbol: string };
  }>;
};

export type LiquidityPoolsOverviewQueryVariables = Exact<{
  filters: LiquidityPoolOverviewFilterInput;
}>;

export type LiquidityPoolsOverviewQuery = {
  __typename?: "Query";
  liquidityPoolsOverview: Array<{
    __typename?: "LiquidityPoolGameStats";
    gameContract: LiquidityPoolGame;
    totalPlayedRoundsEth: number;
    previousTotalPlayedRoundsEth: number;
    totalVolumeEth: number;
    previousTotalVolumeEth: number;
    totalFeesEth: number;
    previousTotalFeesEth: number;
    totalPlayedRoundsYolo: number;
    previousTotalPlayedRoundsYolo: number;
    totalVolumeYolo: number;
    previousTotalVolumeYolo: number;
    totalFeesYolo: number;
    previousTotalFeesYolo: number;
  }>;
};

export type UserLiquidityPoolActionPreviewQueryVariables = Exact<{
  address: Scalars["Address"]["input"];
  contract: LiquidityPoolContract;
  actionType: LiquidityPoolEventType;
  assetsAmountVum: Scalars["BigNumber"]["input"];
}>;

export type UserLiquidityPoolActionPreviewQuery = {
  __typename?: "Query";
  user?: {
    __typename?: "User";
    liquidityPoolActionPreview: {
      __typename?: "LiquidityPoolActionPreview";
      dailyDroplets: number;
      multiplier: number;
    };
  } | null;
};

export const DontFallInGameFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DontFallInGameFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "DontFallInGame" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "winningAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "playAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "latestMap" } },
          { kind: "Field", name: { kind: "Name", value: "drawnTransactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "drawingTransactionHash" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DontFallInGameFragmentFragment, unknown>;
export const FlipperFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FlipperFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Flipper" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "player" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "address" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "src" } },
                            { kind: "Field", name: { kind: "Name", value: "contentType" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "eventBlock" } },
          { kind: "Field", name: { kind: "Name", value: "pickSide" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfRounds" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfRoundsPlayed" } },
          { kind: "Field", name: { kind: "Name", value: "amountPerRoundWei" } },
          { kind: "Field", name: { kind: "Name", value: "playAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "contract" } },
          { kind: "Field", name: { kind: "Name", value: "stopLossWei" } },
          { kind: "Field", name: { kind: "Name", value: "stopGainWei" } },
          { kind: "Field", name: { kind: "Name", value: "winningAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "flipResults" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "drawingTransactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "protocolFeeWei" } },
          { kind: "Field", name: { kind: "Name", value: "liquidityPoolFeeWei" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "drawnTransactionHash" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FlipperFragmentFragment, unknown>;
export const LaserBlastGameFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LaserBlastGameFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LaserBlast" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfRounds" } },
          { kind: "Field", name: { kind: "Name", value: "rowCount" } },
          { kind: "Field", name: { kind: "Name", value: "winningAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "playAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "drawnTransactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "drawingTransactionHash" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LaserBlastGameFragmentFragment, unknown>;
export const QuantumUserFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "QuantumUser" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuantumUserFragment, unknown>;
export const QuantumGameFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "QuantumGame" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "QuantumGame" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "isAbove" } },
          { kind: "Field", name: { kind: "Name", value: "boundary" } },
          { kind: "Field", name: { kind: "Name", value: "multiplier" } },
          { kind: "Field", name: { kind: "Name", value: "results" } },
          { kind: "Field", name: { kind: "Name", value: "stopLossWei" } },
          { kind: "Field", name: { kind: "Name", value: "stopGainWei" } },
          { kind: "Field", name: { kind: "Name", value: "playAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "winningAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "protocolFeeWei" } },
          { kind: "Field", name: { kind: "Name", value: "liquidityPoolFeeWei" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfRounds" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfRoundsPlayed" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "contract" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "QuantumUser" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "drawingTransactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "drawnTransactionHash" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "QuantumUser" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QuantumGameFragment, unknown>;
export const LotteryTicketAllocationFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryTicketAllocation" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LotteryTicketAllocation" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "remaining" } },
          { kind: "Field", name: { kind: "Name", value: "spent" } },
          { kind: "Field", name: { kind: "Name", value: "earned" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LotteryTicketAllocationFragment, unknown>;
export const LotteryUserFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryUser" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LotteryUserFragment, unknown>;
export const LotteryPrizeFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryPrize" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LotteryPrize" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "rank" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "winner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryUser" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "winnerEntryAmount" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryUser" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LotteryPrizeFragment, unknown>;
export const LotteryEntryFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryEntry" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LotteryEntry" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "player" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryUser" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "rank" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryUser" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LotteryEntryFragment, unknown>;
export const LotteryFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Lottery" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Lottery" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "vrfLink" } },
          { kind: "Field", name: { kind: "Name", value: "totalPlayers" } },
          { kind: "Field", name: { kind: "Name", value: "totalValue" } },
          { kind: "Field", name: { kind: "Name", value: "totalEntries" } },
          { kind: "Field", name: { kind: "Name", value: "lotteryDate" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "prizes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryPrize" } }],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "userPrize" },
            name: { kind: "Name", value: "prizes" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "player" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryPrize" } }],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "userEntry" },
            name: { kind: "Name", value: "entries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "player" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryEntry" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryUser" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryPrize" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LotteryPrize" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "rank" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "winner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryUser" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "winnerEntryAmount" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryEntry" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LotteryEntry" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "player" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryUser" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "rank" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LotteryFragment, unknown>;
export const MoDPeriodResultFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDPeriodResult" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDPeriodResult" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "numOfMoons" } },
          { kind: "Field", name: { kind: "Name", value: "numOfDooms" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoDPeriodResultFragment, unknown>;
export const MoDStatsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDStats" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDStats" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "oneHourResult" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDPeriodResult" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "oneDayResult" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDPeriodResult" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDPeriodResult" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDPeriodResult" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "numOfMoons" } },
          { kind: "Field", name: { kind: "Name", value: "numOfDooms" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoDStatsFragment, unknown>;
export const MoDUnclaimedEntryFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDUnclaimedEntry" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDUnclaimedEntry" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "roundOnChainIds" } },
          { kind: "Field", name: { kind: "Name", value: "isRefund" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoDUnclaimedEntryFragment, unknown>;
export const MoDUnclaimedEntriesFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDUnclaimedEntries" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "modUnclaimedEntries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "contract" },
                value: { kind: "Variable", name: { kind: "Name", value: "contract" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDUnclaimedEntry" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDUnclaimedEntry" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDUnclaimedEntry" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "roundOnChainIds" } },
          { kind: "Field", name: { kind: "Name", value: "isRefund" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoDUnclaimedEntriesFragment, unknown>;
export const MoDRoundResultFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDRoundResult" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDRoundResult" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "result" } },
          { kind: "Field", name: { kind: "Name", value: "payoutRatio" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoDRoundResultFragment, unknown>;
export const MoDRoundSettingFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDRoundSetting" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDRoundSetting" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "minimumEnterAmount" } },
          { kind: "Field", name: { kind: "Name", value: "roundIntervalSecs" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoDRoundSettingFragment, unknown>;
export const MoDEntryFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDEntry" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDEntry" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "moonPosition" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "payoutAmount" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoDEntryFragment, unknown>;
export const MoDRoundFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDRound" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDRound" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "onChainId" } },
          { kind: "Field", name: { kind: "Name", value: "startedAt" } },
          { kind: "Field", name: { kind: "Name", value: "lockedAt" } },
          { kind: "Field", name: { kind: "Name", value: "closedAt" } },
          { kind: "Field", name: { kind: "Name", value: "lockPrice" } },
          { kind: "Field", name: { kind: "Name", value: "closePrice" } },
          { kind: "Field", name: { kind: "Name", value: "oracleCalled" } },
          { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
          { kind: "Field", name: { kind: "Name", value: "moonAmount" } },
          { kind: "Field", name: { kind: "Name", value: "moonPayoutRatio" } },
          { kind: "Field", name: { kind: "Name", value: "doomAmount" } },
          { kind: "Field", name: { kind: "Name", value: "doomPayoutRatio" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "result" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDRoundResult" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "setting" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDRoundSetting" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "entries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "player" },
                value: { kind: "Variable", name: { kind: "Name", value: "player" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDEntry" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDRoundResult" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDRoundResult" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "result" } },
          { kind: "Field", name: { kind: "Name", value: "payoutRatio" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDRoundSetting" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDRoundSetting" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "minimumEnterAmount" } },
          { kind: "Field", name: { kind: "Name", value: "roundIntervalSecs" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDEntry" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDEntry" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "moonPosition" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "payoutAmount" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoDRoundFragment, unknown>;
export const UserFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "biography" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "selfTimeoutUntil" } },
          { kind: "Field", name: { kind: "Name", value: "isEmailVerified" } },
          { kind: "Field", name: { kind: "Name", value: "twitterUsername" } },
          { kind: "Field", name: { kind: "Name", value: "discordUsername" } },
          { kind: "Field", name: { kind: "Name", value: "isProfileImageVisible" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "luckySpinTickets" } },
          { kind: "Field", name: { kind: "Name", value: "hasPlayedGames" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "luckySpinUnclaimedPrizesWei" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "ethWei" } },
                { kind: "Field", name: { kind: "Name", value: "yoloWei" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserFragmentFragment, unknown>;
export const UserPointsFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserPointsFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "unclaimedPoints" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lotteryTickets" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "remaining" } },
                { kind: "Field", name: { kind: "Name", value: "spent" } },
                { kind: "Field", name: { kind: "Name", value: "earned" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "seasonPoints" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dailyRank" } },
                { kind: "Field", name: { kind: "Name", value: "points24h" } },
                { kind: "Field", name: { kind: "Name", value: "seasonPoints" } },
                { kind: "Field", name: { kind: "Name", value: "seasonRank" } },
                { kind: "Field", name: { kind: "Name", value: "multiplier" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "details" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "games" } },
                      { kind: "Field", name: { kind: "Name", value: "referrals" } },
                      { kind: "Field", name: { kind: "Name", value: "quests" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "epochPoints" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dailyRank" } },
                { kind: "Field", name: { kind: "Name", value: "points24h" } },
                { kind: "Field", name: { kind: "Name", value: "seasonPoints" } },
                { kind: "Field", name: { kind: "Name", value: "seasonRank" } },
                { kind: "Field", name: { kind: "Name", value: "multiplier" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "details" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "games" } },
                      { kind: "Field", name: { kind: "Name", value: "referrals" } },
                      { kind: "Field", name: { kind: "Name", value: "quests" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserPointsFragmentFragment, unknown>;
export const CurrentSeasonFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CurrentSeasonFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SeasonDetails" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "season" } },
          { kind: "Field", name: { kind: "Name", value: "startTime" } },
          { kind: "Field", name: { kind: "Name", value: "endTime" } },
          { kind: "Field", name: { kind: "Name", value: "rewardPool" } },
          { kind: "Field", name: { kind: "Name", value: "rewardPoolBlastGold" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentSeasonFragmentFragment, unknown>;
export const RakebackFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RakebackFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rakeback" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "currency" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "address" } },
                { kind: "Field", name: { kind: "Name", value: "decimals" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "amountWei" } },
          { kind: "Field", name: { kind: "Name", value: "unlocksAt" } },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "claimedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RakebackFragmentFragment, unknown>;
export const MilestoneFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MilestoneFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UserMilestoneLevel" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "milestone" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "progress" } },
          { kind: "Field", name: { kind: "Name", value: "goal" } },
          { kind: "Field", name: { kind: "Name", value: "claimedAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MilestoneFragmentFragment, unknown>;
export const GetUserDontFallInCurrentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserDontFallInCurrent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "contract" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "DontFallInContract" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "latestDontFallInGame" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "contract" },
                      value: { kind: "Variable", name: { kind: "Name", value: "contract" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "FragmentSpread", name: { kind: "Name", value: "DontFallInGameFragment" } },
                      { kind: "Field", name: { kind: "Name", value: "hitLavas" } },
                      { kind: "Field", name: { kind: "Name", value: "multiplier" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DontFallInGameFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "DontFallInGame" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "winningAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "playAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "latestMap" } },
          { kind: "Field", name: { kind: "Name", value: "drawnTransactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "drawingTransactionHash" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserDontFallInCurrentQuery, GetUserDontFallInCurrentQueryVariables>;
export const GetUserDontFallInHistoryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserDontFallInHistory" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "DontFallInGamesFilterInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "DontFallInSort" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dontFallInGames" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "Variable", name: { kind: "Name", value: "sort" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "address" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatar" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "image" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "src" } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "lavasCount" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "DontFallInGameFragment" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DontFallInGameFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "DontFallInGame" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "winningAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "playAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "latestMap" } },
          { kind: "Field", name: { kind: "Name", value: "drawnTransactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "drawingTransactionHash" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserDontFallInHistoryQuery, GetUserDontFallInHistoryQueryVariables>;
export const GetDontFallInMultiplierDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetDontFallInMultiplier" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "contract" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "DontFallInContract" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lavasCount" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "selectedTilesCount" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dontFallInConfig" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "contract" },
                value: { kind: "Variable", name: { kind: "Name", value: "contract" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "lavasCount" },
                value: { kind: "Variable", name: { kind: "Name", value: "lavasCount" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "revealedTilesCount" },
                value: { kind: "Variable", name: { kind: "Name", value: "selectedTilesCount" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "multiplier" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetDontFallInMultiplierQuery, GetDontFallInMultiplierQueryVariables>;
export const GetFlipperHistoryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetFlipperHistory" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "FilterFlipperInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "FlipperSort" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "coinFlips" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "Variable", name: { kind: "Name", value: "sort" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "FlipperFragment" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FlipperFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Flipper" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "player" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "address" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "src" } },
                            { kind: "Field", name: { kind: "Name", value: "contentType" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "eventBlock" } },
          { kind: "Field", name: { kind: "Name", value: "pickSide" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfRounds" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfRoundsPlayed" } },
          { kind: "Field", name: { kind: "Name", value: "amountPerRoundWei" } },
          { kind: "Field", name: { kind: "Name", value: "playAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "contract" } },
          { kind: "Field", name: { kind: "Name", value: "stopLossWei" } },
          { kind: "Field", name: { kind: "Name", value: "stopGainWei" } },
          { kind: "Field", name: { kind: "Name", value: "winningAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "flipResults" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "drawingTransactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "protocolFeeWei" } },
          { kind: "Field", name: { kind: "Name", value: "liquidityPoolFeeWei" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "drawnTransactionHash" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetFlipperHistoryQuery, GetFlipperHistoryQueryVariables>;
export const GetUserCoinFlipDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserCoinFlip" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "contract" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "FlipperContract" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentFlip" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "contract" },
                      value: { kind: "Variable", name: { kind: "Name", value: "contract" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "FlipperFragment" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FlipperFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Flipper" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "player" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "address" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "avatar" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "image" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "src" } },
                            { kind: "Field", name: { kind: "Name", value: "contentType" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "eventBlock" } },
          { kind: "Field", name: { kind: "Name", value: "pickSide" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfRounds" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfRoundsPlayed" } },
          { kind: "Field", name: { kind: "Name", value: "amountPerRoundWei" } },
          { kind: "Field", name: { kind: "Name", value: "playAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "contract" } },
          { kind: "Field", name: { kind: "Name", value: "stopLossWei" } },
          { kind: "Field", name: { kind: "Name", value: "stopGainWei" } },
          { kind: "Field", name: { kind: "Name", value: "winningAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "flipResults" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "drawingTransactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "protocolFeeWei" } },
          { kind: "Field", name: { kind: "Name", value: "liquidityPoolFeeWei" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "drawnTransactionHash" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserCoinFlipQuery, GetUserCoinFlipQueryVariables>;
export const GetUserLaserBlastCurrentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserLaserBlastCurrent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "contract" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "LaserBlastContract" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentLaserBlast" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "contract" },
                      value: { kind: "Variable", name: { kind: "Name", value: "contract" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "FragmentSpread", name: { kind: "Name", value: "LaserBlastGameFragment" } },
                      { kind: "Field", name: { kind: "Name", value: "laserTravels" } },
                      { kind: "Field", name: { kind: "Name", value: "payouts" } },
                      { kind: "Field", name: { kind: "Name", value: "drawingTransactionHash" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LaserBlastGameFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LaserBlast" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfRounds" } },
          { kind: "Field", name: { kind: "Name", value: "rowCount" } },
          { kind: "Field", name: { kind: "Name", value: "winningAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "playAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "drawnTransactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "drawingTransactionHash" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserLaserBlastCurrentQuery, GetUserLaserBlastCurrentQueryVariables>;
export const GetUserLaserBlastHistoryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserLaserBlastHistory" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "FilterLaserBlastInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LaserBlastSort" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "laserBlasts" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "Variable", name: { kind: "Name", value: "sort" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "player" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "address" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatar" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "image" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "src" } }],
                              },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "riskLevel" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "FragmentSpread", name: { kind: "Name", value: "LaserBlastGameFragment" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LaserBlastGameFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LaserBlast" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfRounds" } },
          { kind: "Field", name: { kind: "Name", value: "rowCount" } },
          { kind: "Field", name: { kind: "Name", value: "winningAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "playAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "drawnTransactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "drawingTransactionHash" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserLaserBlastHistoryQuery, GetUserLaserBlastHistoryQueryVariables>;
export const GetUserLatestQuantumGameDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserLatestQuantumGame" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "contract" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QuantumContract" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "latestQuantumGame" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "contract" },
                      value: { kind: "Variable", name: { kind: "Name", value: "contract" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "QuantumGame" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "QuantumUser" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "QuantumGame" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "QuantumGame" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "isAbove" } },
          { kind: "Field", name: { kind: "Name", value: "boundary" } },
          { kind: "Field", name: { kind: "Name", value: "multiplier" } },
          { kind: "Field", name: { kind: "Name", value: "results" } },
          { kind: "Field", name: { kind: "Name", value: "stopLossWei" } },
          { kind: "Field", name: { kind: "Name", value: "stopGainWei" } },
          { kind: "Field", name: { kind: "Name", value: "playAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "winningAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "protocolFeeWei" } },
          { kind: "Field", name: { kind: "Name", value: "liquidityPoolFeeWei" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfRounds" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfRoundsPlayed" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "contract" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "QuantumUser" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "drawingTransactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "drawnTransactionHash" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserLatestQuantumGameQuery, GetUserLatestQuantumGameQueryVariables>;
export const GetQuantumGamesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetQuantumGames" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "QuantumGamesFilterInput" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "QuantumSort" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "quantumGames" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "Variable", name: { kind: "Name", value: "sort" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "QuantumGame" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "QuantumUser" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "QuantumGame" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "QuantumGame" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "isAbove" } },
          { kind: "Field", name: { kind: "Name", value: "boundary" } },
          { kind: "Field", name: { kind: "Name", value: "multiplier" } },
          { kind: "Field", name: { kind: "Name", value: "results" } },
          { kind: "Field", name: { kind: "Name", value: "stopLossWei" } },
          { kind: "Field", name: { kind: "Name", value: "stopGainWei" } },
          { kind: "Field", name: { kind: "Name", value: "playAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "winningAmountWei" } },
          { kind: "Field", name: { kind: "Name", value: "protocolFeeWei" } },
          { kind: "Field", name: { kind: "Name", value: "liquidityPoolFeeWei" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfRounds" } },
          { kind: "Field", name: { kind: "Name", value: "numberOfRoundsPlayed" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "contract" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "QuantumUser" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "drawingTransactionHash" } },
          { kind: "Field", name: { kind: "Name", value: "drawnTransactionHash" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetQuantumGamesQuery, GetQuantumGamesQueryVariables>;
export const EstimateGamePointsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "EstimateGamePoints" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "amountPerRoundWei" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "BigNumber" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "currency" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "numberOfRounds" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "estimateGamePoints" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "amountPerRoundWei" },
                value: { kind: "Variable", name: { kind: "Name", value: "amountPerRoundWei" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "currency" },
                value: { kind: "Variable", name: { kind: "Name", value: "currency" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "numberOfRounds" },
                value: { kind: "Variable", name: { kind: "Name", value: "numberOfRounds" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EstimateGamePointsQuery, EstimateGamePointsQueryVariables>;
export const GetLotteryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLottery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "BigNumber" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "player" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Address" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lottery" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "Lottery" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryUser" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryPrize" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LotteryPrize" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "rank" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "winner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryUser" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "winnerEntryAmount" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryEntry" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LotteryEntry" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "player" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryUser" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "rank" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Lottery" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Lottery" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "vrfLink" } },
          { kind: "Field", name: { kind: "Name", value: "totalPlayers" } },
          { kind: "Field", name: { kind: "Name", value: "totalValue" } },
          { kind: "Field", name: { kind: "Name", value: "totalEntries" } },
          { kind: "Field", name: { kind: "Name", value: "lotteryDate" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "prizes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryPrize" } }],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "userPrize" },
            name: { kind: "Name", value: "prizes" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "player" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryPrize" } }],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "userEntry" },
            name: { kind: "Name", value: "entries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "player" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryEntry" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLotteryQuery, GetLotteryQueryVariables>;
export const GetLotteryEntriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLotteryEntries" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "FilterLotteryInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lotteries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "entries" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pagination" },
                      value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryEntry" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryUser" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryEntry" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LotteryEntry" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "player" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryUser" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "rank" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLotteryEntriesQuery, GetLotteryEntriesQueryVariables>;
export const GetLotteriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLotteries" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "player" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Address" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "FilterLotteryInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lotteries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "Lottery" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryUser" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryPrize" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LotteryPrize" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "rank" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "winner" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryUser" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "winnerEntryAmount" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryEntry" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LotteryEntry" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "player" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryUser" } }],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "rank" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Lottery" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Lottery" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "type" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          { kind: "Field", name: { kind: "Name", value: "vrfLink" } },
          { kind: "Field", name: { kind: "Name", value: "totalPlayers" } },
          { kind: "Field", name: { kind: "Name", value: "totalValue" } },
          { kind: "Field", name: { kind: "Name", value: "totalEntries" } },
          { kind: "Field", name: { kind: "Name", value: "lotteryDate" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "prizes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryPrize" } }],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "userPrize" },
            name: { kind: "Name", value: "prizes" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "player" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryPrize" } }],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "userEntry" },
            name: { kind: "Name", value: "entries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "player" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryEntry" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLotteriesQuery, GetLotteriesQueryVariables>;
export const GetLotteryUserDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLotteryUserData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lotteryTickets" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LotteryTicketAllocation" } }],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "lotteryUnclaimedEthWei" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LotteryTicketAllocation" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LotteryTicketAllocation" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "remaining" } },
          { kind: "Field", name: { kind: "Name", value: "spent" } },
          { kind: "Field", name: { kind: "Name", value: "earned" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLotteryUserDataQuery, GetLotteryUserDataQueryVariables>;
export const GetLotteryPrizePoolDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLotteryPrizePool" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "FilterLotteryInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lotteries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "totalValue" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLotteryPrizePoolQuery, GetLotteryPrizePoolQueryVariables>;
export const EnterLotteryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "EnterLottery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "BigNumber" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "amount" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "enterLottery" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "amount" },
                value: { kind: "Variable", name: { kind: "Name", value: "amount" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EnterLotteryMutation, EnterLotteryMutationVariables>;
export const ClaimWinningsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ClaimWinnings" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "claimLotteries" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClaimWinningsMutation, ClaimWinningsMutationVariables>;
export const MoDRoundsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MoDRounds" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "MoDFilterInput" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "player" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Address" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "modRounds" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDRound" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDRoundResult" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDRoundResult" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "result" } },
          { kind: "Field", name: { kind: "Name", value: "payoutRatio" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDRoundSetting" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDRoundSetting" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "minimumEnterAmount" } },
          { kind: "Field", name: { kind: "Name", value: "roundIntervalSecs" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDEntry" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDEntry" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "moonPosition" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "payoutAmount" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDRound" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDRound" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "onChainId" } },
          { kind: "Field", name: { kind: "Name", value: "startedAt" } },
          { kind: "Field", name: { kind: "Name", value: "lockedAt" } },
          { kind: "Field", name: { kind: "Name", value: "closedAt" } },
          { kind: "Field", name: { kind: "Name", value: "lockPrice" } },
          { kind: "Field", name: { kind: "Name", value: "closePrice" } },
          { kind: "Field", name: { kind: "Name", value: "oracleCalled" } },
          { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
          { kind: "Field", name: { kind: "Name", value: "moonAmount" } },
          { kind: "Field", name: { kind: "Name", value: "moonPayoutRatio" } },
          { kind: "Field", name: { kind: "Name", value: "doomAmount" } },
          { kind: "Field", name: { kind: "Name", value: "doomPayoutRatio" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "result" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDRoundResult" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "setting" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDRoundSetting" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "entries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "player" },
                value: { kind: "Variable", name: { kind: "Name", value: "player" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDEntry" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoDRoundsQuery, MoDRoundsQueryVariables>;
export const MoDRoundDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MoDRound" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "contract" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "MoDContract" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "player" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Address" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "modRound" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "contract" },
                value: { kind: "Variable", name: { kind: "Name", value: "contract" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDRound" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDRoundResult" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDRoundResult" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "result" } },
          { kind: "Field", name: { kind: "Name", value: "payoutRatio" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDRoundSetting" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDRoundSetting" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "minimumEnterAmount" } },
          { kind: "Field", name: { kind: "Name", value: "roundIntervalSecs" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDEntry" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDEntry" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "moonPosition" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "payoutAmount" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDRound" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDRound" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "onChainId" } },
          { kind: "Field", name: { kind: "Name", value: "startedAt" } },
          { kind: "Field", name: { kind: "Name", value: "lockedAt" } },
          { kind: "Field", name: { kind: "Name", value: "closedAt" } },
          { kind: "Field", name: { kind: "Name", value: "lockPrice" } },
          { kind: "Field", name: { kind: "Name", value: "closePrice" } },
          { kind: "Field", name: { kind: "Name", value: "oracleCalled" } },
          { kind: "Field", name: { kind: "Name", value: "totalAmount" } },
          { kind: "Field", name: { kind: "Name", value: "moonAmount" } },
          { kind: "Field", name: { kind: "Name", value: "moonPayoutRatio" } },
          { kind: "Field", name: { kind: "Name", value: "doomAmount" } },
          { kind: "Field", name: { kind: "Name", value: "doomPayoutRatio" } },
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "result" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDRoundResult" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "setting" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDRoundSetting" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "entries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "player" },
                value: { kind: "Variable", name: { kind: "Name", value: "player" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDEntry" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoDRoundQuery, MoDRoundQueryVariables>;
export const MoDStatsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MoDStats" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "contract" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "MoDContract" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "modStats" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "contract" },
                value: { kind: "Variable", name: { kind: "Name", value: "contract" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDStats" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDPeriodResult" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDPeriodResult" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "numOfMoons" } },
          { kind: "Field", name: { kind: "Name", value: "numOfDooms" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDStats" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDStats" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "oneHourResult" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDPeriodResult" } }],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "oneDayResult" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDPeriodResult" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoDStatsQuery, MoDStatsQueryVariables>;
export const MoDHistoricalPricesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MoDHistoricalPrices" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pair" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "MoDAssetPair" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "modHistoricalPrices" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pair" },
                value: { kind: "Variable", name: { kind: "Name", value: "pair" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "timestamp" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoDHistoricalPricesQuery, MoDHistoricalPricesQueryVariables>;
export const MoDUnclaimedEntriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MoDUnclaimedEntries" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "contract" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "MoDContract" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDUnclaimedEntries" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDUnclaimedEntry" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "MoDUnclaimedEntry" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "roundOnChainIds" } },
          { kind: "Field", name: { kind: "Name", value: "isRefund" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MoDUnclaimedEntries" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "modUnclaimedEntries" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "contract" },
                value: { kind: "Variable", name: { kind: "Name", value: "contract" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MoDUnclaimedEntry" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MoDUnclaimedEntriesQuery, MoDUnclaimedEntriesQueryVariables>;
export const UserBoostProgressDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserBoostProgress" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "yoloBoostMetrics" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "boostAllocationWei" } },
                      { kind: "Field", name: { kind: "Name", value: "boostSpentWei" } },
                      { kind: "Field", name: { kind: "Name", value: "progressPercentage" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserBoostProgressQuery, UserBoostProgressQueryVariables>;
export const ConnectUserSocialDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ConnectUserSocial" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "platform" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "SOCIAL_PLATFORM" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ConnectSocialInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "connectUserSocial" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "platform" },
                value: { kind: "Variable", name: { kind: "Name", value: "platform" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "data" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConnectUserSocialMutation, ConnectUserSocialMutationVariables>;
export const DisconnectUserSocialDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DisconnectUserSocial" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "platform" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "SOCIAL_PLATFORM" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "disconnectUserSocial" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "platform" },
                value: { kind: "Variable", name: { kind: "Name", value: "platform" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DisconnectUserSocialMutation, DisconnectUserSocialMutationVariables>;
export const CheckUsernameValidityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "CheckUsernameValidity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "username" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "usernameValid" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "username" },
                value: { kind: "Variable", name: { kind: "Name", value: "username" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CheckUsernameValidityQuery, CheckUsernameValidityQueryVariables>;
export const UpdateUserMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateUserMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "userData" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UserUpdateInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: { kind: "Variable", name: { kind: "Name", value: "userData" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserMutationMutation, UpdateUserMutationMutationVariables>;
export const ToggleProfileImageVisibilityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ToggleProfileImageVisibility" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "toggleProfileImageVisibility" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ToggleProfileImageVisibilityMutation, ToggleProfileImageVisibilityMutationVariables>;
export const UpdateUserEmailMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateUserEmailMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateUserEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateUserEmailMutationMutation, UpdateUserEmailMutationMutationVariables>;
export const ConfirmUserEmailMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ConfirmUserEmailMutation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "confirmationId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "confirmUserEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "confirmationId" },
                value: { kind: "Variable", name: { kind: "Name", value: "confirmationId" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ConfirmUserEmailMutationMutation, ConfirmUserEmailMutationMutationVariables>;
export const GetBoostAllocationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetBoostAllocation" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "boostAllocation" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "boostFromEthWei" } },
                { kind: "Field", name: { kind: "Name", value: "boostFromUsdWei" } },
                { kind: "Field", name: { kind: "Name", value: "ethDepositedWei" } },
                { kind: "Field", name: { kind: "Name", value: "usdDepositedWei" } },
                { kind: "Field", name: { kind: "Name", value: "totalBoostWei" } },
                { kind: "Field", name: { kind: "Name", value: "claimed" } },
                { kind: "Field", name: { kind: "Name", value: "burned" } },
                { kind: "Field", name: { kind: "Name", value: "proof" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetBoostAllocationQuery, GetBoostAllocationQueryVariables>;
export const ExitBoostDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ExitBoost" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "exitBoostMode" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ExitBoostMutation, ExitBoostMutationVariables>;
export const SubscribeEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubscribeEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "subscribeEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscribeEmailMutation, SubscribeEmailMutationVariables>;
export const LuckySpinEntryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LuckySpinEntry" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "luckySpinEntry" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "drawnAt" } },
                { kind: "Field", name: { kind: "Name", value: "ticketAmount" } },
                { kind: "Field", name: { kind: "Name", value: "vrfUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "prize" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "amount" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LuckySpinEntryQuery, LuckySpinEntryQueryVariables>;
export const PlayLuckySpinDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "PlayLuckySpin" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "tickets" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "playLuckySpin" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tickets" },
                value: { kind: "Variable", name: { kind: "Name", value: "tickets" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PlayLuckySpinMutation, PlayLuckySpinMutationVariables>;
export const ClaimEthPrizesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ClaimEthPrizes" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "claimLuckySpinPrizes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClaimEthPrizesMutation, ClaimEthPrizesMutationVariables>;
export const GetLuckySpinPrizeTotalsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLuckySpinPrizeTotals" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "luckySpinPrizeTotals" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "pointsUntilNextTicket" } },
                      { kind: "Field", name: { kind: "Name", value: "ethWei" } },
                      { kind: "Field", name: { kind: "Name", value: "yoloWei" } },
                      { kind: "Field", name: { kind: "Name", value: "seasonPoints" } },
                      { kind: "Field", name: { kind: "Name", value: "blastGoldTotal" } },
                      { kind: "Field", name: { kind: "Name", value: "blastGoldPendingDistribution" } },
                      { kind: "Field", name: { kind: "Name", value: "spentTickets" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLuckySpinPrizeTotalsQuery, GetLuckySpinPrizeTotalsQueryVariables>;
export const GetLuckySpinMaxAvailablePrizesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetLuckySpinMaxAvailablePrizes" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "luckySpinMaxAvailablePrize" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "blastGoldBonus" } },
                { kind: "Field", name: { kind: "Name", value: "blastGoldPrize" } },
                { kind: "Field", name: { kind: "Name", value: "yoloWei" } },
                { kind: "Field", name: { kind: "Name", value: "ethWei" } },
                { kind: "Field", name: { kind: "Name", value: "seasonPoints" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetLuckySpinMaxAvailablePrizesQuery, GetLuckySpinMaxAvailablePrizesQueryVariables>;
export const GetUserSeasonPointsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserSeasonPoints" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "UserPointsFragment" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserPointsFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "unclaimedPoints" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "lotteryTickets" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "remaining" } },
                { kind: "Field", name: { kind: "Name", value: "spent" } },
                { kind: "Field", name: { kind: "Name", value: "earned" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "seasonPoints" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dailyRank" } },
                { kind: "Field", name: { kind: "Name", value: "points24h" } },
                { kind: "Field", name: { kind: "Name", value: "seasonPoints" } },
                { kind: "Field", name: { kind: "Name", value: "seasonRank" } },
                { kind: "Field", name: { kind: "Name", value: "multiplier" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "details" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "games" } },
                      { kind: "Field", name: { kind: "Name", value: "referrals" } },
                      { kind: "Field", name: { kind: "Name", value: "quests" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "epochPoints" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dailyRank" } },
                { kind: "Field", name: { kind: "Name", value: "points24h" } },
                { kind: "Field", name: { kind: "Name", value: "seasonPoints" } },
                { kind: "Field", name: { kind: "Name", value: "seasonRank" } },
                { kind: "Field", name: { kind: "Name", value: "multiplier" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "details" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "games" } },
                      { kind: "Field", name: { kind: "Name", value: "referrals" } },
                      { kind: "Field", name: { kind: "Name", value: "quests" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserSeasonPointsQuery, GetUserSeasonPointsQueryVariables>;
export const GetCurrentPointsSeasonDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCurrentPointsSeason" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pointsSeason" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "CurrentSeasonFragment" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CurrentSeasonFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SeasonDetails" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "season" } },
          { kind: "Field", name: { kind: "Name", value: "startTime" } },
          { kind: "Field", name: { kind: "Name", value: "endTime" } },
          { kind: "Field", name: { kind: "Name", value: "rewardPool" } },
          { kind: "Field", name: { kind: "Name", value: "rewardPoolBlastGold" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCurrentPointsSeasonQuery, GetCurrentPointsSeasonQueryVariables>;
export const GetPointsSeasonDetailsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetPointsSeasonDetails" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "season" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "seasonDetails" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "season" },
                value: { kind: "Variable", name: { kind: "Name", value: "season" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "season" } },
                { kind: "Field", name: { kind: "Name", value: "startTime" } },
                { kind: "Field", name: { kind: "Name", value: "endTime" } },
                { kind: "Field", name: { kind: "Name", value: "rewardPool" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPointsSeasonDetailsQuery, GetPointsSeasonDetailsQueryVariables>;
export const GetCurrentSeasonLeaderboardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCurrentSeasonLeaderboard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "OffsetPaginationInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LeaderboardSortInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pointsLeaderboard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "Variable", name: { kind: "Name", value: "sort" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "points" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "dailyRank" } },
                      { kind: "Field", name: { kind: "Name", value: "multiplier" } },
                      { kind: "Field", name: { kind: "Name", value: "points24h" } },
                      { kind: "Field", name: { kind: "Name", value: "seasonRank" } },
                      { kind: "Field", name: { kind: "Name", value: "seasonPoints" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "address" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "isVerified" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatar" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "image" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "src" } },
                                        { kind: "Field", name: { kind: "Name", value: "contentType" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCurrentSeasonLeaderboardQuery, GetCurrentSeasonLeaderboardQueryVariables>;
export const GetCurrentEpochLeaderboardDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCurrentEpochLeaderboard" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "OffsetPaginationInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "epochLeaderboard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "points" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "dailyRank" } },
                      { kind: "Field", name: { kind: "Name", value: "multiplier" } },
                      { kind: "Field", name: { kind: "Name", value: "points24h" } },
                      { kind: "Field", name: { kind: "Name", value: "seasonRank" } },
                      { kind: "Field", name: { kind: "Name", value: "seasonPoints" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "address" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "isVerified" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "avatar" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "image" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "src" } },
                                        { kind: "Field", name: { kind: "Name", value: "contentType" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCurrentEpochLeaderboardQuery, GetCurrentEpochLeaderboardQueryVariables>;
export const GetSeasonAllocationsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSeasonAllocations" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "season" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "OffsetPaginationInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "seasonAllocations" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "season" },
                value: { kind: "Variable", name: { kind: "Name", value: "season" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "pagination" },
                value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "points" } },
                { kind: "Field", name: { kind: "Name", value: "rank" } },
                { kind: "Field", name: { kind: "Name", value: "blastGoldAllocation" } },
                { kind: "Field", name: { kind: "Name", value: "yTokenWeiAllocation" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "address" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "isVerified" } },
                      { kind: "Field", name: { kind: "Name", value: "isProfileImageVisible" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatar" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "image" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "src" } },
                                  { kind: "Field", name: { kind: "Name", value: "contentType" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSeasonAllocationsQuery, GetSeasonAllocationsQueryVariables>;
export const GetUserSeasonRewardsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserSeasonRewards" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "season" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Int" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "seasonRewards" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "season" },
                      value: { kind: "Variable", name: { kind: "Name", value: "season" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "rank" } },
                      { kind: "Field", name: { kind: "Name", value: "points" } },
                      { kind: "Field", name: { kind: "Name", value: "blastGoldAllocation" } },
                      { kind: "Field", name: { kind: "Name", value: "yTokenWeiAllocation" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "yTokenVestingClaimDetails" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "proof" } },
                            { kind: "Field", name: { kind: "Name", value: "vestingAllocationWei" } },
                            { kind: "Field", name: { kind: "Name", value: "remainingVolumeToClaimWei" } },
                            { kind: "Field", name: { kind: "Name", value: "currentClaimableAmountWei" } },
                            { kind: "Field", name: { kind: "Name", value: "volumeWei" } },
                            { kind: "Field", name: { kind: "Name", value: "claimedWei" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "vaultSharesClaimDetails" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "yTokensDepositedWei" } },
                            { kind: "Field", name: { kind: "Name", value: "proof" } },
                            { kind: "Field", name: { kind: "Name", value: "vaultSharesClaimableVum" } },
                            { kind: "Field", name: { kind: "Name", value: "claimedAt" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "yTokenWeiFromShards" } },
                      { kind: "Field", name: { kind: "Name", value: "stakingShards" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserSeasonRewardsQuery, GetUserSeasonRewardsQueryVariables>;
export const GetUserMilestonesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserMilestones" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "milestones" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DONT_FALL_IN_ETH" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DONT_FALL_IN_ROUND" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DONT_FALL_IN_WIN" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "DONT_FALL_IN_WIN_STREAK" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FLIPPER_ETH" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FLIPPER_ROUND" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FLIPPER_WIN" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "FLIPPER_WIN_STREAK" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "LASER_BLAST_ETH" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "LASER_BLAST_ROUND" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "QUANTUM_ETH" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "QUANTUM_WIN_STREAK" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "QUANTUM_WIN" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "QUANTUM_ROUND" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PROFILE_SETUP" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "POINTS" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "MORD_ROUND" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "MORD_ETH" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "MORD_WIN_STREAK" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "MORD_WIN" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PTB_FUTURE_ROUND" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PTB_ETH" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PTB_WIN_STREAK" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "PTB_WIN" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "YOLO_FUTURE_ROUND" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "YOLO_ROUND" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "YOLO_ETH" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "YOLO_WIN" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "MilestoneFragment" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "MilestoneFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UserMilestoneLevel" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "code" } },
          { kind: "Field", name: { kind: "Name", value: "milestone" } },
          { kind: "Field", name: { kind: "Name", value: "points" } },
          { kind: "Field", name: { kind: "Name", value: "progress" } },
          { kind: "Field", name: { kind: "Name", value: "goal" } },
          { kind: "Field", name: { kind: "Name", value: "claimedAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserMilestonesQuery, GetUserMilestonesQueryVariables>;
export const GetUserDailyQuestsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserDailyQuests" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Address" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dailyQuests" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "nextDay" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "quests" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "claimedAt" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      { kind: "Field", name: { kind: "Name", value: "goal" } },
                      { kind: "Field", name: { kind: "Name", value: "points" } },
                      { kind: "Field", name: { kind: "Name", value: "progress" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserDailyQuestsQuery, GetUserDailyQuestsQueryVariables>;
export const ClaimMilestoneDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ClaimMilestone" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "MilestoneLevelCode" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "claimMilestoneLevel" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "code" },
                value: { kind: "Variable", name: { kind: "Name", value: "code" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClaimMilestoneMutation, ClaimMilestoneMutationVariables>;
export const ClaimDailyQuestDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ClaimDailyQuest" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "DailyQuestCode" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "claimDailyQuest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "code" },
                value: { kind: "Variable", name: { kind: "Name", value: "code" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClaimDailyQuestMutation, ClaimDailyQuestMutationVariables>;
export const GetUserRakebackDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserRakebackData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rakebacks" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "lastRoundId" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalClaimed" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "address" } },
                                  { kind: "Field", name: { kind: "Name", value: "decimals" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "symbol" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "amountWei" } },
                            { kind: "Field", name: { kind: "Name", value: "amountEthWei" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalPending" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "currency" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "address" } },
                                  { kind: "Field", name: { kind: "Name", value: "decimals" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "symbol" } },
                                ],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "amountWei" } },
                            { kind: "Field", name: { kind: "Name", value: "amountEthWei" } },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rakebacks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locked" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "RakebackFragment" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unlocked" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "RakebackFragment" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "history" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "RakebackFragment" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nextRakeback" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "RakebackFragment" } }],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rakebackClaimDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "cumulativeAmountWei" } },
                      { kind: "Field", name: { kind: "Name", value: "endsAt" } },
                      { kind: "Field", name: { kind: "Name", value: "proof" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "address" } },
                            { kind: "Field", name: { kind: "Name", value: "decimals" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "symbol" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RakebackFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rakeback" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "currency" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "address" } },
                { kind: "Field", name: { kind: "Name", value: "decimals" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "amountWei" } },
          { kind: "Field", name: { kind: "Name", value: "unlocksAt" } },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "claimedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserRakebackDataQuery, GetUserRakebackDataQueryVariables>;
export const GetUserRakebacksDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserRakebacks" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "RakebacksFilterInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rakebacks" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pagination" },
                      value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "lastRoundId" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rakebacks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "locked" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "RakebackFragment" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "unlocked" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "RakebackFragment" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "history" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "FragmentSpread", name: { kind: "Name", value: "RakebackFragment" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "RakebackFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Rakeback" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "status" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "currency" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "address" } },
                { kind: "Field", name: { kind: "Name", value: "decimals" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "symbol" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "amountWei" } },
          { kind: "Field", name: { kind: "Name", value: "unlocksAt" } },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "claimedAt" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserRakebacksQuery, GetUserRakebacksQueryVariables>;
export const RecentGameWinsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "RecentGameWins" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "sort" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "RecentGameWinSort" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "recentGameWins" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "sort" },
                value: { kind: "Variable", name: { kind: "Name", value: "sort" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "amountWonWei" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "entryAmountWei" } },
                { kind: "Field", name: { kind: "Name", value: "game" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "winner" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "address" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "isVerified" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "avatar" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "image" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "src" } },
                                  { kind: "Field", name: { kind: "Name", value: "contentType" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currency" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "address" } },
                      { kind: "Field", name: { kind: "Name", value: "symbol" } },
                      { kind: "Field", name: { kind: "Name", value: "decimals" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RecentGameWinsQuery, RecentGameWinsQueryVariables>;
export const AddYgAffiliateDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddYgAffiliate" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "referralCode" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addAffiliate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "referralCode" },
                value: { kind: "Variable", name: { kind: "Name", value: "referralCode" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddYgAffiliateMutation, AddYgAffiliateMutationVariables>;
export const IsReferralCodeValidDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "IsReferralCodeValid" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "referralCode" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "isReferralCodeValid" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "referralCode" },
                value: { kind: "Variable", name: { kind: "Name", value: "referralCode" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IsReferralCodeValidQuery, IsReferralCodeValidQueryVariables>;
export const GetGameStatsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetGameStats" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "gameStats" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalPlayers" } },
                { kind: "Field", name: { kind: "Name", value: "totalGamesPlayed" } },
                { kind: "Field", name: { kind: "Name", value: "volumePlayedWei" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetGameStatsQuery, GetGameStatsQueryVariables>;
export const GetUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "UserFragment" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "UserFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "User" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "biography" } },
          { kind: "Field", name: { kind: "Name", value: "email" } },
          { kind: "Field", name: { kind: "Name", value: "selfTimeoutUntil" } },
          { kind: "Field", name: { kind: "Name", value: "isEmailVerified" } },
          { kind: "Field", name: { kind: "Name", value: "twitterUsername" } },
          { kind: "Field", name: { kind: "Name", value: "discordUsername" } },
          { kind: "Field", name: { kind: "Name", value: "isProfileImageVisible" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "avatar" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "image" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "contentType" } },
                      { kind: "Field", name: { kind: "Name", value: "src" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "luckySpinTickets" } },
          { kind: "Field", name: { kind: "Name", value: "hasPlayedGames" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "luckySpinUnclaimedPrizesWei" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "ethWei" } },
                { kind: "Field", name: { kind: "Name", value: "yoloWei" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const GetReferralCodeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetReferralCode" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "referralCode" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetReferralCodeQuery, GetReferralCodeQueryVariables>;
export const UserReferrerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserReferrer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "referral" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "hasPendingOrConfirmedReferrer" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "primaryReferrer" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "Field", name: { kind: "Name", value: "address" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserReferrerQuery, UserReferrerQueryVariables>;
export const AskSelfTimeoutDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AskSelfTimeout" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "timeoutUntil" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "DateTime" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "askSelfTimeout" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "timeoutUntil" },
                value: { kind: "Variable", name: { kind: "Name", value: "timeoutUntil" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AskSelfTimeoutMutation, AskSelfTimeoutMutationVariables>;
export const UserMidSeasonRewardsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserMidSeasonRewards" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "midSeasonGoldDistribution" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "gamesGold" } },
                      { kind: "Field", name: { kind: "Name", value: "dropletsGold" } },
                      { kind: "Field", name: { kind: "Name", value: "yYoloGold" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserMidSeasonRewardsQuery, UserMidSeasonRewardsQueryVariables>;
export const LogoutMutationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LogoutMutation" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "logout" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "success" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LogoutMutationMutation, LogoutMutationMutationVariables>;
export const LiquidityPoolsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LiquidityPools" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "user" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Address" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "liquidityPools" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "user" },
                value: { kind: "Variable", name: { kind: "Name", value: "user" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "contract" } },
                { kind: "Field", name: { kind: "Name", value: "isActive" } },
                { kind: "Field", name: { kind: "Name", value: "userPoolPct" } },
                { kind: "Field", name: { kind: "Name", value: "userSharesValueVum" } },
                { kind: "Field", name: { kind: "Name", value: "assetsBalanceVum" } },
                { kind: "Field", name: { kind: "Name", value: "pendingDepositsAmountVum" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "liquidityPoolConfig" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "finalizationIncentiveEthWei" } },
                      { kind: "Field", name: { kind: "Name", value: "depositFeeBps" } },
                      { kind: "Field", name: { kind: "Name", value: "minDepositVum" } },
                      { kind: "Field", name: { kind: "Name", value: "maxDepositVum" } },
                      { kind: "Field", name: { kind: "Name", value: "timelockDelaySecs" } },
                      { kind: "Field", name: { kind: "Name", value: "assetsLimitVum" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "liquidityPoolSeason" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "dailyDroplets" } },
                      { kind: "Field", name: { kind: "Name", value: "currentMultiplier" } },
                      { kind: "Field", name: { kind: "Name", value: "nextIncreaseAt" } },
                      { kind: "Field", name: { kind: "Name", value: "nextMultiplierIncrease" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currency" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "address" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "decimals" } },
                      { kind: "Field", name: { kind: "Name", value: "symbol" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LiquidityPoolsQuery, LiquidityPoolsQueryVariables>;
export const LiquidityPoolTotalDropletsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LiquidityPoolTotalDroplets" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "liquidityPoolSeasonDroplets" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LiquidityPoolTotalDropletsQuery, LiquidityPoolTotalDropletsQueryVariables>;
export const LiquidyPoolPendingActionsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LiquidyPoolPendingActions" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "liquidityPoolPendingActions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "contract" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "address" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "decimals" } },
                            { kind: "Field", name: { kind: "Name", value: "symbol" } },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "expectedAssetsAmountVum" } },
                      { kind: "Field", name: { kind: "Name", value: "finalizationIncentiveEthWei" } },
                      { kind: "Field", name: { kind: "Name", value: "protectedUntil" } },
                      { kind: "Field", name: { kind: "Name", value: "startedAt" } },
                      { kind: "Field", name: { kind: "Name", value: "transactionHash" } },
                      { kind: "Field", name: { kind: "Name", value: "unlocksAt" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LiquidyPoolPendingActionsQuery, LiquidyPoolPendingActionsQueryVariables>;
export const UserLiquidityPoolEventsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserLiquidityPoolEvents" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "PaginationInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "liquidityPoolEvents" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "pagination" },
                      value: { kind: "Variable", name: { kind: "Name", value: "pagination" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "assetsAmountUsd" } },
                      { kind: "Field", name: { kind: "Name", value: "assetsAmountVum" } },
                      { kind: "Field", name: { kind: "Name", value: "contract" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      { kind: "Field", name: { kind: "Name", value: "isTransfer" } },
                      { kind: "Field", name: { kind: "Name", value: "transactionHash" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "address" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "decimals" } },
                            { kind: "Field", name: { kind: "Name", value: "symbol" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserLiquidityPoolEventsQuery, UserLiquidityPoolEventsQueryVariables>;
export const UserLiquidityPoolMetricsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserLiquidityPoolMetrics" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filters" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "LiquidityPoolUserMetricsFilterInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "liquidityPoolMetrics" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filters" },
                      value: { kind: "Variable", name: { kind: "Name", value: "filters" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "data" } },
                      { kind: "Field", name: { kind: "Name", value: "dataGranularity" } },
                      { kind: "Field", name: { kind: "Name", value: "gameContract" } },
                      { kind: "Field", name: { kind: "Name", value: "currentCumulativeTotal" } },
                      { kind: "Field", name: { kind: "Name", value: "previousCumulativeTotal" } },
                      { kind: "Field", name: { kind: "Name", value: "intervalTotal" } },
                      { kind: "Field", name: { kind: "Name", value: "lastAlignedAt" } },
                      { kind: "Field", name: { kind: "Name", value: "metricType" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currency" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "address" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "decimals" } },
                            { kind: "Field", name: { kind: "Name", value: "symbol" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserLiquidityPoolMetricsQuery, UserLiquidityPoolMetricsQueryVariables>;
export const LiquidityPoolMetricsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LiquidityPoolMetrics" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filters" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "LiquidityPoolMetricsFilterInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "liquidityPoolMetrics" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "filters" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "data" } },
                { kind: "Field", name: { kind: "Name", value: "dataGranularity" } },
                { kind: "Field", name: { kind: "Name", value: "gameContract" } },
                { kind: "Field", name: { kind: "Name", value: "intervalTotal" } },
                { kind: "Field", name: { kind: "Name", value: "currentCumulativeTotal" } },
                { kind: "Field", name: { kind: "Name", value: "previousCumulativeTotal" } },
                { kind: "Field", name: { kind: "Name", value: "lastAlignedAt" } },
                { kind: "Field", name: { kind: "Name", value: "metricType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currency" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "address" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "decimals" } },
                      { kind: "Field", name: { kind: "Name", value: "symbol" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LiquidityPoolMetricsQuery, LiquidityPoolMetricsQueryVariables>;
export const LiquidityPoolsOverviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "LiquidityPoolsOverview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filters" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "LiquidityPoolOverviewFilterInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "liquidityPoolsOverview" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filters" },
                value: { kind: "Variable", name: { kind: "Name", value: "filters" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "gameContract" } },
                { kind: "Field", name: { kind: "Name", value: "totalPlayedRoundsEth" } },
                { kind: "Field", name: { kind: "Name", value: "previousTotalPlayedRoundsEth" } },
                { kind: "Field", name: { kind: "Name", value: "totalVolumeEth" } },
                { kind: "Field", name: { kind: "Name", value: "previousTotalVolumeEth" } },
                { kind: "Field", name: { kind: "Name", value: "totalFeesEth" } },
                { kind: "Field", name: { kind: "Name", value: "previousTotalFeesEth" } },
                { kind: "Field", name: { kind: "Name", value: "totalPlayedRoundsYolo" } },
                { kind: "Field", name: { kind: "Name", value: "previousTotalPlayedRoundsYolo" } },
                { kind: "Field", name: { kind: "Name", value: "totalVolumeYolo" } },
                { kind: "Field", name: { kind: "Name", value: "previousTotalVolumeYolo" } },
                { kind: "Field", name: { kind: "Name", value: "totalFeesYolo" } },
                { kind: "Field", name: { kind: "Name", value: "previousTotalFeesYolo" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LiquidityPoolsOverviewQuery, LiquidityPoolsOverviewQueryVariables>;
export const UserLiquidityPoolActionPreviewDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "UserLiquidityPoolActionPreview" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "address" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Address" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "contract" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "LiquidityPoolContract" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "actionType" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "LiquidityPoolEventType" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "assetsAmountVum" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "BigNumber" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "user" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "address" },
                value: { kind: "Variable", name: { kind: "Name", value: "address" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "liquidityPoolActionPreview" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "contract" },
                      value: { kind: "Variable", name: { kind: "Name", value: "contract" } },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "actionType" },
                      value: { kind: "Variable", name: { kind: "Name", value: "actionType" } },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "assetsAmountVum" },
                      value: { kind: "Variable", name: { kind: "Name", value: "assetsAmountVum" } },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "dailyDroplets" } },
                      { kind: "Field", name: { kind: "Name", value: "multiplier" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserLiquidityPoolActionPreviewQuery, UserLiquidityPoolActionPreviewQueryVariables>;
