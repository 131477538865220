import { type BigIntish } from "./types";

export const isBigIntishInRange = (value: BigIntish) => {
  try {
    const valueAsNum = Number(value);
    return valueAsNum > Number.MIN_SAFE_INTEGER && valueAsNum < Number.MAX_SAFE_INTEGER;
  } catch {
    return false;
  }
};

/**
 * Multiply a wei value by a floating point value
 */
export const multiplyWeiByNumber = (wei: BigIntish, value: number, precision = 6) => {
  const weiBi = BigInt(wei.toString());

  // Force number of decimals based on the precision supplied e.g. 1.1234567 to 1.123457 (toPrecision rounds)
  const valueMaxPrecision = parseFloat(value.toPrecision(precision));
  const multiplier = 10 ** precision;
  const divisor = BigInt(multiplier);

  return (weiBi * BigInt(parseInt((valueMaxPrecision * multiplier).toString()))) / divisor;
};

/**
 * Divide wei by wei
 * @NOTE useful for dividing two large wei values
 */
export const divideWeiByWei = (weiNumerator: BigIntish, weiDenominator: BigIntish, precision = 18) => {
  try {
    const weiNumBi = BigInt(weiNumerator.toString());
    const weiDenBi = BigInt(weiDenominator.toString());
    const multiplier = 10 ** precision;
    return Number((weiNumBi * BigInt(multiplier)) / weiDenBi) / multiplier;
  } catch {
    return 0;
  }
};

/**
 * Divide wei by a number e.g. 1000000000000000000 / 2.2
 */
export const divideWeiByNumber = (wei: BigIntish, number: number, precision = 18) => {
  const weiBi = BigInt(wei.toString());
  const multiplier = 10 ** precision;
  return (weiBi * BigInt(multiplier)) / BigInt(number * multiplier);
};

/**
 * Return percentage as float based on two BigIntish values
 */
export const getBigIntishPercent = (total: BigIntish, number: BigIntish): number => {
  try {
    const totalInRange = isBigIntishInRange(total);
    const numberInRange = isBigIntishInRange(number);

    // Use regular operations if both values are safe
    if (totalInRange && numberInRange) {
      return (Number(number) / Number(total)) * 100;
    }
    return divideWeiByWei(number, total) * 100;
  } catch {
    return 0;
  }
};
