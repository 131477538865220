import { ChainId } from "@looksrare/config";
import { IS_TESTNET } from "@looksrare/config";
import { SupportedNetwork } from "./types";

export const getSupportedNetworkFromChainId = (chainId: ChainId): SupportedNetwork => {
  if (chainId === ChainId.HARDHAT) {
    throw new Error("Hardhat not supported");
  }
  switch (chainId) {
    case ChainId.BLAST_SEPOLIA:
    case ChainId.BLAST_MAINNET:
      return "blast";
    case ChainId.ARB_MAINNET:
    case ChainId.ARB_SEPOLIA:
      return "arbitrum";
    case ChainId.MAINNET:
    case ChainId.SEPOLIA:
    default:
      return "ethereum";
  }
};

export const getChainIdFromSupportedNetwork = (network: SupportedNetwork): ChainId => {
  if (IS_TESTNET) {
    switch (network) {
      case "blast":
        return ChainId.BLAST_SEPOLIA;
      case "arbitrum":
        return ChainId.ARB_SEPOLIA;
      default:
        return ChainId.SEPOLIA;
    }
  }

  switch (network) {
    case "blast":
      return ChainId.BLAST_MAINNET;
    case "arbitrum":
      return ChainId.ARB_MAINNET;
    default:
      return ChainId.MAINNET;
  }
};
