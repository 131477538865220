import { useEffect, useRef } from "react";
import { Box, Flex, FlexProps, useDisclosure } from "@chakra-ui/react";
import { BearAnimation } from "../BearAnimation";
import { GameActions } from "../GameActions";
import { NextRoundCountdown } from "../NextRoundCountdown";
import { useCurrentGameStatus, useCurrentRoundOnChainId, useGetCaveQueryParams } from "../../hooks";
import { CaveHeader } from "./CaveHeader";
import { RoundInfo } from "./RoundInfo";
import { PlayerListPanel } from "./PlayerListPanel";
import { PokerTimer } from "./PokeTimer";
import { Commentary } from "./Commentary";

type CaveMobileProps = FlexProps;

export const CaveMobile = (props: CaveMobileProps) => {
  const { caveOnChainId, network, roundOnChainId } = useGetCaveQueryParams();
  const { data } = useCurrentRoundOnChainId({ caveOnChainId, network });

  const hasOpenedOnPreviousRound = useRef(false); // Let users hide player list on previous rounds
  const disclosure = useDisclosure();
  const gameStatus = useCurrentGameStatus();

  // If we are viewing a past round keep the player list open
  // @todo-yg This only opens by default on first page load, we need to revisit this.
  useEffect(() => {
    if (
      !!data &&
      !!roundOnChainId &&
      !disclosure.isOpen &&
      !hasOpenedOnPreviousRound.current &&
      Number(roundOnChainId) < Number(data)
    ) {
      disclosure.onOpen();
      hasOpenedOnPreviousRound.current = true;
    }
  }, [data, disclosure, roundOnChainId, hasOpenedOnPreviousRound]);

  return (
    <Flex height="100%" flexDirection="column" {...props}>
      <Box flex="none">
        <CaveHeader />
        <Box position="relative">
          <PokerTimer />
          <BearAnimation />
          {gameStatus === "finished" && <NextRoundCountdown />}
        </Box>
      </Box>
      <Flex position="relative" height="100%" flexDirection="column" flex={1} overflow="hidden" bg="ui-bg">
        <RoundInfo onOpenPlayerList={disclosure.onOpen} />
        <Commentary />
        <Box flex="none">
          <GameActions />
        </Box>
        <PlayerListPanel isOpen={disclosure.isOpen} onClosePlayerList={disclosure.onClose} />
      </Flex>
    </Flex>
  );
};
