import { MoDUnclaimedEntries } from "../../../network/graphql/hooks";

/**
 * We calculate the total unclaimed amount by adding up
 * the calculated total unclaimed amount for each contract.
 */
export const getTotalUnclaimedAmount = (unclaimedEntries: MoDUnclaimedEntries) => {
  return Object.entries(unclaimedEntries).reduce((totalSum, [, contractUnclaimedEntries]) => {
    return contractUnclaimedEntries.reduce((contractSum, entry) => contractSum + BigInt(entry.amount), totalSum);
  }, 0n);
};
