const path = require("path");
const isProd = process.env.NODE_ENV === "production";
const isBrowser = typeof window !== "undefined";

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: "en",
    locales: ["en", "zh-Hans", "es", "fr", "ru", "ja", "tr", "vi", "ko", "th", "id", "de", "pt", "zh-Hant"],
    localeDetection: false,
  },
  localePath: path.resolve("./public/locales"),
  /** @type {string} */
  nsSeparator: "::",
  /** @type {false} */
  keySeparator: true,
  // Only upload translations not in Prod
  saveMissing: !isProd,
  serializeConfig: false,
  returnEmptyString: false, // Switch to i18next-http-backend client side during development. POST-ing missing translations to /api/i18n
  ...(isBrowser && !isProd
    ? {
        backend: {
          loadPath: "http://localhost:3000/locales/{{lng}}/{{ns}}.json",
          addPath: "http://localhost:3000/api/i18n/{{ns}}",
        },
        use: [require("i18next-http-backend/cjs")],
      }
    : {}),
};
