import { useQuery } from "@tanstack/react-query";
import { type RQueryOptions } from "@looksrare/utils";
import { PtbCaveReturn, getActiveCaves, getCaveIds } from "../graphql";
import type { PtbContractName, PtbSupportedNetwork } from "../types";

export const useCaves = (contract: PtbContractName, options?: RQueryOptions<PtbCaveReturn[]>) => {
  return useQuery({
    queryKey: ["activeCaves", contract],
    queryFn: async () => getActiveCaves(contract),
    // Refetch every 5s to ensure that the cave active progress and participant
    // count is kept up to date in fast-paced layer 2 environments.
    refetchInterval: 5 * 1000,
    ...options,
  });
};

interface CaveIdReturn {
  id: string;
  name: string;
}

export const useCaveIds = (network: PtbSupportedNetwork, options?: RQueryOptions<CaveIdReturn[]>) => {
  return useQuery({
    queryKey: ["caveIds", network],
    queryFn: async () => {
      const caves = await getCaveIds(network);
      return caves.map(({ onChainId, name }) => ({ id: onChainId, name }));
    },
    // refetch every 20s
    refetchInterval: 20 * 1000,
    ...options,
  });
};
