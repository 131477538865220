import { useCallback } from "react";
import { Address, erc721Abi } from "viem";
import { usePublicClient } from "wagmi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { RQueryOptions } from "../../../types";
import { NoPublicClientError } from "../../..";

export const useTotalSupply = (contractAddress: Address, options?: RQueryOptions<bigint>) => {
  const publicClient = usePublicClient();

  return useQuery({
    queryKey: ["totalSupply", contractAddress],
    queryFn: async () => {
      if (!publicClient) {
        throw new NoPublicClientError();
      }
      const totalSupply = await publicClient.readContract({
        abi: erc721Abi,
        functionName: "totalSupply",
        address: contractAddress,
      });
      return totalSupply;
    },
    enabled: !!contractAddress,
    ...options,
  });
};

export const useInvalidateTotalSupply = (contractAddress: Address) => {
  const queryClient = useQueryClient();

  return useCallback(
    () => queryClient.invalidateQueries({ queryKey: ["totalSupply", contractAddress] }),
    [contractAddress, queryClient]
  );
};
