import { Icon, IconProps } from "../Icon";

const Notification = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6999 20.3L25.9999 17.6V13C25.9932 7.85764 22.1131 3.5464 16.9999 3V1H14.9999V3C9.9043 3.58089 6.04264 7.87162 5.99986 13V17.6L3.29986 20.3C3.10233 20.4783 2.99271 20.734 2.99986 21V24C2.98197 24.2703 3.08157 24.5352 3.27312 24.7267C3.46468 24.9183 3.72955 25.0179 3.99986 25H10.9999C10.9999 27.7614 13.2384 30 15.9999 30C18.7613 30 20.9999 27.7614 20.9999 25H27.9999C28.2702 25.0179 28.535 24.9183 28.7266 24.7267C28.9181 24.5352 29.0177 24.2703 28.9999 24V21C29.007 20.734 28.8974 20.4783 28.6999 20.3ZM16 28C14.3432 28 13 26.6569 13 25H19C19 26.6569 17.6569 28 16 28ZM27 23H5.00002V21.4L7.70002 18.7C7.89754 18.5217 8.00716 18.266 8.00002 18V13C8.00002 8.58172 11.5817 5 16 5C20.4183 5 24 8.58172 24 13V18C23.9929 18.266 24.1025 18.5217 24.3 18.7L27 21.4V23Z"
      fill="currentColor"
    />
  </Icon>
);

export default Notification;
