import {
  forwardRef,
  Modal as ChakraModal,
  ModalProps as ChakraModalProps,
  ModalOverlay,
  ModalContent,
  ModalContentProps,
  useBreakpointValue,
  ModalOverlayProps,
} from "@chakra-ui/react";

export interface ModalProps extends ChakraModalProps {
  modalContentProps?: ModalContentProps;
  modalOverlayProps?: ModalOverlayProps;
  dataId?: string;
}

export const Modal = forwardRef<ModalProps, "div">(
  ({ initialFocusRef, isOpen, onClose, modalContentProps, modalOverlayProps, dataId, children, ...props }, ref) => {
    const breakpointSize = useBreakpointValue({
      base: "sm",
      sm: "md",
    });
    const size = props.size || breakpointSize;

    return (
      <ChakraModal
        size={size}
        initialFocusRef={initialFocusRef}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={props.scrollBehavior || size === "sm" ? "inside" : "outside"}
        isCentered={size === "md"}
        motionPreset={size === "md" ? "scale" : "slideInBottom"}
        {...props}
      >
        <ModalOverlay {...modalOverlayProps} />
        <ModalContent size={size} ref={ref} {...(dataId && { "data-id": dataId })} {...modalContentProps}>
          {children}
        </ModalContent>
      </ChakraModal>
    );
  }
);
