import { Icon, IconProps } from "@chakra-ui/react";

export const RankSecond = (props: IconProps) => (
  <Icon width="256" height="256" viewBox="0 0 256 256" fill="none" {...props}>
    <path
      d="M113 8.66026C122.282 3.30127 133.718 3.30127 143 8.66025L223.851 55.3397C233.133 60.6987 238.851 70.6025 238.851 81.3205V174.679C238.851 185.397 233.133 195.301 223.851 200.66L143 247.34C133.718 252.699 122.282 252.699 113 247.34L32.1488 200.66C22.8667 195.301 17.1488 185.397 17.1488 174.679V81.3205C17.1488 70.6025 22.8667 60.6987 32.1488 55.3397L113 8.66026Z"
      fill="url(#paint0_linear_2424_83567)"
    />
    <path
      d="M113 8.66026C122.282 3.30127 133.718 3.30127 143 8.66025L223.851 55.3397C233.133 60.6987 238.851 70.6025 238.851 81.3205V174.679C238.851 185.397 233.133 195.301 223.851 200.66L143 247.34C133.718 252.699 122.282 252.699 113 247.34L32.1488 200.66C22.8667 195.301 17.1488 185.397 17.1488 174.679V81.3205C17.1488 70.6025 22.8667 60.6987 32.1488 55.3397L113 8.66026Z"
      fill="url(#paint1_linear_2424_83567)"
    />
    <path
      d="M82.0626 48.4356C110.489 32.0235 145.512 32.0235 173.939 48.4356C202.365 64.8477 219.877 95.1785 219.877 128.003C219.877 160.827 202.365 191.158 173.939 207.57C145.512 223.982 110.489 223.982 82.0626 207.57C53.6361 191.158 36.1246 160.827 36.1246 128.003C36.1246 95.1785 53.6361 64.8477 82.0626 48.4356Z"
      fill="url(#paint2_linear_2424_83567)"
      stroke="black"
    />
    <g filter="url(#filter0_diii_2424_83567)">
      <path
        d="M80.9832 134.721L148.699 107.001C157.279 103.437 155.827 99.4769 145.399 99.4769C128.635 99.4769 108.703 99.6089 99.5952 99.7409C86.9232 99.7409 75.0432 100.929 67.7832 107.265V85.2209C74.2512 80.2049 83.8872 77.9609 93.3912 77.9609C109.363 77.9609 142.231 77.9609 164.671 77.9609C179.851 77.9609 188.167 85.8809 188.167 96.8369V98.9489C188.167 114.789 140.647 137.097 108.967 148.845V150.957H189.355V173.001H87.3192C81.5112 173.001 68.5752 168.117 68.3112 157.161L68.1792 150.033C68.1792 144.093 75.3072 136.965 80.9832 134.721Z"
        fill="#D9D9D9"
      />
      <path
        d="M80.9832 134.721L148.699 107.001C157.279 103.437 155.827 99.4769 145.399 99.4769C128.635 99.4769 108.703 99.6089 99.5952 99.7409C86.9232 99.7409 75.0432 100.929 67.7832 107.265V85.2209C74.2512 80.2049 83.8872 77.9609 93.3912 77.9609C109.363 77.9609 142.231 77.9609 164.671 77.9609C179.851 77.9609 188.167 85.8809 188.167 96.8369V98.9489C188.167 114.789 140.647 137.097 108.967 148.845V150.957H189.355V173.001H87.3192C81.5112 173.001 68.5752 168.117 68.3112 157.161L68.1792 150.033C68.1792 144.093 75.3072 136.965 80.9832 134.721Z"
        fill="url(#paint3_linear_2424_83567)"
      />
    </g>
    <defs>
      <filter
        id="filter0_diii_2424_83567"
        x="47.2908"
        y="57.4685"
        width="172.803"
        height="146.27"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="5.12311" dy="5.12311" />
        <feGaussianBlur stdDeviation="12.8078" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
        <feBlend mode="overlay" in2="BackgroundImageFix" result="effect1_dropShadow_2424_83567" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2424_83567" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.28078" dy="1.28078" />
        <feGaussianBlur stdDeviation="0.640389" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
        <feBlend mode="soft-light" in2="shape" result="effect2_innerShadow_2424_83567" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1.28078" dy="-1.28078" />
        <feGaussianBlur stdDeviation="0.640389" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.9 0" />
        <feBlend mode="overlay" in2="effect2_innerShadow_2424_83567" result="effect3_innerShadow_2424_83567" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1.28078" dy="-1.28078" />
        <feGaussianBlur stdDeviation="0.640389" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
        <feBlend mode="normal" in2="effect3_innerShadow_2424_83567" result="effect4_innerShadow_2424_83567" />
      </filter>
      <linearGradient
        id="paint0_linear_2424_83567"
        x1="172.552"
        y1="30.0183"
        x2="82.7692"
        y2="62.8076"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#33DEBF" />
        <stop offset="1" stopColor="#27796A" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2424_83567"
        x1="172.552"
        y1="30.6437"
        x2="13.8775"
        y2="160.811"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#33DEBF" />
        <stop offset="1" stopColor="#27796A" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2424_83567"
        x1="128.001"
        y1="21.3359"
        x2="128.001"
        y2="234.669"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#191B20" />
        <stop offset="1" stopColor="#3D4048" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2424_83567"
        x1="151.646"
        y1="89.8016"
        x2="69.7111"
        y2="157.525"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#33DEBF" />
        <stop offset="1" stopColor="#27796A" />
      </linearGradient>
    </defs>
  </Icon>
);

export const RankSecondGradient = (props: IconProps) => (
  <Icon width="512" height="512" viewBox="0 0 512 512" fill="none" {...props}>
    <circle opacity="0.2" cx="256" cy="256" r="256" fill="url(#paint0_radial_2424_83561)" />
    <defs>
      <radialGradient
        id="paint0_radial_2424_83561"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(256 256) rotate(90) scale(256)"
      >
        <stop stopColor="#33DEBF" />
        <stop offset="1" stopColor="#33DEBF" stopOpacity="0" />
      </radialGradient>
    </defs>
  </Icon>
);
