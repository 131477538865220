import { MoDResult } from "@looksrare/yolo-games-gql-typegen";
import { UfoState } from "../../../components/Ufo/Ufo";

interface GetUfoStateInput {
  lockPrice?: number;
  currentPrice?: number;
}

export const getUfoState = ({ lockPrice, currentPrice }: GetUfoStateInput): UfoState => {
  if (lockPrice === undefined || currentPrice === undefined) {
    return "idle";
  }

  if (currentPrice < lockPrice) {
    return "doom";
  }

  if (currentPrice > lockPrice) {
    return "moon";
  }

  return "idle";
};

export const getUfoStateByResult = (result: MoDResult): UfoState => {
  switch (result) {
    case "MOON":
      return "ending-moon";
    case "DOOM":
      return "ending-doom";
    case "HOUSE":
      return "idle";
  }
};
