import { Icon, IconProps } from "@chakra-ui/react";

export const YoloLogoColorOnDark = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 96 48" width="96" height="48" {...props}>
      <path
        d="M82.6868 0C90.022 0 95.9618 5.95007 95.9618 13.298C95.9618 20.6102 90.022 26.5602 82.6868 26.5602C75.3874 26.5602 69.4477 20.6102 69.4477 13.298C69.4477 5.95007 75.3874 0 82.6868 0ZM82.6868 19.1047C85.9072 19.1047 88.3045 16.4881 88.3045 13.298C88.3045 10.0721 85.9072 7.45551 82.6868 7.45551C79.4665 7.45551 77.0692 10.0721 77.0692 13.298C77.0692 16.4881 79.4665 19.1047 82.6868 19.1047Z"
        fill="#CCFD07"
      />
      <path d="M59.8043 18.2086H69.1075V25.8791H51.7893V0.788478H59.8043V18.2086Z" fill="#CCFD07" />
      <path
        d="M36.5966 0C43.9318 0 49.8715 5.95007 49.8715 13.298C49.8715 20.6102 43.9318 26.5602 36.5966 26.5602C29.2972 26.5602 23.3575 20.6102 23.3575 13.298C23.3575 5.95007 29.2972 0 36.5966 0ZM36.5966 19.1047C39.8169 19.1047 42.2143 16.4881 42.2143 13.298C42.2143 10.0721 39.8169 7.45551 36.5966 7.45551C33.3763 7.45551 30.9789 10.0721 30.9789 13.298C30.9789 16.4881 33.3763 19.1047 36.5966 19.1047Z"
        fill="#CCFD07"
      />
      <path
        d="M16.2115 0.788478H24.7633L16.5336 25.8433H7.98181L11.0948 16.4164H4.86883L0.0383301 0.788478H8.91213L12.2756 12.7245L16.2115 0.788478Z"
        fill="#CCFD07"
      />
      <path
        d="M96 41.7967C96 44.9359 93.3464 47.4675 90.1116 47.4675H81.2157V42.227H90.1116C90.4906 42.227 90.7434 41.9992 90.7434 41.7967C90.7434 41.5182 90.6423 41.2144 90.1116 41.2144H86.5734C83.2628 41.2144 80.685 38.7081 80.685 35.4929C80.685 32.2777 83.2628 29.7714 86.5734 29.7714H95.4693V35.0119H86.5734C86.346 35.0119 85.9164 35.1132 85.9164 35.4929C85.9164 35.7967 86.2702 35.9739 86.5734 35.9739H90.1116C93.397 35.9739 96 38.5309 96 41.7713V41.7967Z"
        fill="#F8FAFC"
      />
      <path
        d="M79.0775 35.0119H69.373V35.9992H79.0775V41.2397H69.373V42.227H79.0775V47.4675H63.8636V29.7714H79.0775V35.0119Z"
        fill="#F8FAFC"
      />
      <path
        d="M43.238 29.7714H48.5957L49.7583 47.4675H44.6785L43.238 29.7714ZM50.8955 29.7714H56.2785L54.838 47.4675H49.7583L50.8955 29.7714ZM61.7879 47.4675H56.2785V29.7714H61.7879V47.4675ZM43.238 47.4675H37.7287V29.7714H43.238V47.4675Z"
        fill="#F8FAFC"
      />
      <path
        d="M36.3178 47.4675H30.2019L27.1187 29.7714L24.0355 47.4675H17.9196L20.9775 29.7714H33.2346L36.3178 47.4675Z"
        fill="#F8FAFC"
      />
      <path
        d="M17.7411 37.2401V40.8603C17.7411 44.6071 14.0514 47.9995 9.37601 47.9995C4.22047 47.9995 0 43.7717 0 38.6072C0 33.4173 4.22047 29.2148 9.37601 29.2148C12.8383 29.2148 15.8457 31.0882 17.4631 33.8984L12.8383 36.5819C12.1812 35.3414 10.9176 34.4806 9.37601 34.4806C7.1015 34.4806 5.40826 36.3287 5.40826 38.6072C5.40826 40.8856 7.1015 42.7337 9.37601 42.7337C10.5133 42.7337 11.5494 42.2021 12.2823 41.4426L9.57819 41.4173V37.2401H17.7411Z"
        fill="#F8FAFC"
      />
    </Icon>
  );
};
