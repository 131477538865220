import type { PtbContractName } from "@looksrare/poke-the-bear";
import { YoloContractName } from "@looksrare/yolo";
import { MoDContract } from "@looksrare/yolo-games-gql-typegen";
import { BigIntish } from "../../types";
import { WSTopicName } from "./types";

export enum TopicName {
  tokenFloorAsk = "tokenFloorAsk",
  topTokenBid = "topTokenBid",
  collectionFloorAsk = "collectionFloorAsk",
  topCollectionBid = "topCollectionBid",
  yoloPendingTx = "yoloPendingTx",
  yoloActiveViews = "yoloActiveViews",
  yoloActivePlayers = "yoloActivePlayers",
  yoloContractActiveViews = "yoloContractActiveViews",
  gasPrice = "gasPrice",
  infiltrationPendingTx = "infiltrationPendingTx",
  infiltrationTx = "infiltrationTx",
  infiltrationHealCount = "infiltrationHealCount",
  ptbCaveLogs = "ptbCaveLogs",
  ptbCaveActiveViews = "ptbCaveActiveViews",
  ptbActiveViews = "ptbActiveViews",
  ptbActivePlayers = "ptbActivePlayers",
  modActiveViews = "modActiveViews",
  modContractActiveViews = "modContractActiveViews",
  modActivePlayers = "modActivePlayers",
  gameEvents = "gameEvents",
  quantumActiveViews = "quantumActiveViews",
  quantumActivePlayers = "quantumActivePlayers",
  flipperActiveViews = "flipperActiveViews",
  flipperActivePlayers = "flipperActivePlayers",
  laserBlastActiveViews = "laserBlastActiveViews",
  laserBlastActivePlayers = "laserBlastActivePlayers",
  dontFallInActiveViews = "dontFallInActiveViews",
  dontFallInActivePlayers = "dontFallInActivePlayers",
}

/**
 * Channels
 */
interface TokenFloorTopicConfig {
  name: TopicName.tokenFloorAsk;
  collection: string;
  tokenId?: string;
}

interface TokenTopBidTopicConfig {
  name: TopicName.topTokenBid;
  collection: string;
  tokenId?: string;
}

interface CollectionFloorTopicConfig {
  name: TopicName.collectionFloorAsk;
  collection?: string;
}

interface CollectionTopBidTopicConfig {
  name: TopicName.topCollectionBid;
  collection?: string;
}

interface YoloPendingTxConfig {
  name: TopicName.yoloPendingTx;
}

interface YoloActiveViewsConfig {
  name: TopicName.yoloActiveViews;
}

interface GasPriceConfig {
  name: TopicName.gasPrice;
}

interface InfiltrationPendingTxConfig {
  name: TopicName.infiltrationPendingTx;
}

interface InfiltrationTxConfig {
  name: TopicName.infiltrationTx;
}

interface InfiltrationHealCountConfig {
  name: TopicName.infiltrationHealCount;
}

interface CaveEventLogsConfig {
  name: TopicName.ptbCaveLogs;
  caveOnChainId: BigIntish;
  contract: PtbContractName;
}

interface PtbActiveCaveViewsConfig {
  name: TopicName.ptbCaveActiveViews;
  caveOnChainId: BigIntish;
  contract: PtbContractName;
}

interface PtbActiveViewsConfig {
  name: TopicName.ptbActiveViews;
}

interface YoloActivePlayersConfig {
  name: TopicName.yoloActivePlayers;
}

interface YoloContractActiveViewsConfig {
  name: TopicName.yoloContractActiveViews;
  contract: YoloContractName;
}

interface PtbActivePlayersConfig {
  name: TopicName.ptbActivePlayers;
}

interface ModActiveViewsConfig {
  name: TopicName.modActiveViews;
}

interface ModContractActiveViewsConfig {
  name: TopicName.modContractActiveViews;
  contract: MoDContract;
}

interface ModActivePlayersConfig {
  name: TopicName.modActivePlayers;
}

interface QuantumActiveViewsConfig {
  name: TopicName.quantumActiveViews;
}

interface QuantumActivePlayersConfig {
  name: TopicName.quantumActivePlayers;
}

interface FlipperActiveViewsConfig {
  name: TopicName.flipperActiveViews;
}

interface FlipperActivePlayersConfig {
  name: TopicName.flipperActivePlayers;
}

interface LaserBlastActiveViewsConfig {
  name: TopicName.laserBlastActiveViews;
}

interface LaserBlastActivePlayersConfig {
  name: TopicName.laserBlastActivePlayers;
}

interface DontFallInActiveViewsConfig {
  name: TopicName.dontFallInActiveViews;
}

interface DontFallInActivePlayersConfig {
  name: TopicName.dontFallInActivePlayers;
}

type WSTopicConfig =
  | TokenFloorTopicConfig
  | TokenTopBidTopicConfig
  | CollectionFloorTopicConfig
  | CollectionTopBidTopicConfig
  | YoloPendingTxConfig
  | YoloActiveViewsConfig
  | YoloActivePlayersConfig
  | YoloContractActiveViewsConfig
  | GasPriceConfig
  | InfiltrationPendingTxConfig
  | InfiltrationTxConfig
  | InfiltrationHealCountConfig
  | PtbActiveCaveViewsConfig
  | CaveEventLogsConfig
  | PtbActiveViewsConfig
  | PtbActivePlayersConfig
  | ModActiveViewsConfig
  | ModContractActiveViewsConfig
  | ModActivePlayersConfig
  | QuantumActiveViewsConfig
  | QuantumActivePlayersConfig
  | FlipperActiveViewsConfig
  | FlipperActivePlayersConfig
  | LaserBlastActiveViewsConfig
  | LaserBlastActivePlayersConfig
  | DontFallInActiveViewsConfig
  | DontFallInActivePlayersConfig;

export const getTopicName = (topic: WSTopicConfig): WSTopicName => {
  switch (topic.name) {
    case TopicName.tokenFloorAsk:
      return [TopicName.tokenFloorAsk, topic.collection, topic.tokenId]
        .filter(Boolean)
        .join("/") as `tokenFloorAsk/${string}`;
    case TopicName.collectionFloorAsk:
      return [TopicName.collectionFloorAsk, topic.collection]
        .filter(Boolean)
        .join("/") as `collectionFloorAsk/${string}`;
    case TopicName.topTokenBid:
      return [TopicName.topTokenBid, topic.collection, topic.tokenId]
        .filter(Boolean)
        .join("/") as `topTokenBid/${string}`;
    case TopicName.ptbCaveActiveViews:
      return [TopicName.ptbCaveActiveViews, topic.caveOnChainId, topic.contract].join(
        "/"
      ) as `${TopicName.ptbCaveActiveViews}/${string}`;
    case TopicName.ptbCaveLogs:
      return [TopicName.ptbCaveLogs, topic.caveOnChainId, topic.contract].join(
        "/"
      ) as `${TopicName.ptbCaveLogs}/${string}`;
    case TopicName.topCollectionBid:
      return [TopicName.topCollectionBid, topic.collection].filter(Boolean).join("/") as `topCollectionBid/${string}`;
    case TopicName.yoloContractActiveViews:
      return [TopicName.yoloContractActiveViews, topic.contract].join("/") as `yoloContractActiveViews/${string}`;
    case TopicName.modContractActiveViews:
      return [TopicName.modContractActiveViews, topic.contract].join("/") as `modContractActiveViews/${string}`;
    default:
      return topic.name;
  }
};
