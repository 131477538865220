import type { HttpTransportConfig } from "viem";
import { blast, blastSepolia } from "viem/chains";
import { cookieStorage, createStorage, fallback, http } from "wagmi";
import { IS_TESTNET, QUICKNODE_KEY, WALLET_CONNECT_PROJECT_ID } from "@looksrare/config";
import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { metaMaskWallet, rainbowWallet, coinbaseWallet, walletConnectWallet } from "@rainbow-me/rainbowkit/wallets";

// @todo-wagmi2 Look into supporting http and ws
const transportConfig: HttpTransportConfig = {
  batch: {
    batchSize: 100,
    wait: 500,
  },
  retryCount: 3,
  retryDelay: 1_000,
  timeout: 2_000,
};

export const walletConfig = getDefaultConfig({
  appName: "YOLO Games",
  ssr: true,
  projectId: WALLET_CONNECT_PROJECT_ID,
  chains: [IS_TESTNET ? blastSepolia : blast],
  transports: IS_TESTNET
    ? {
        [blastSepolia.id]: http(
          `https://icy-convincing-emerald.blast-sepolia.quiknode.pro/${QUICKNODE_KEY}/`,
          transportConfig
        ),
      }
    : {
        [blast.id]: fallback(
          [
            http("https://rpc.blast.io"),
            http("https://blastl2-mainnet.public.blastapi.io"),
            http("https://blast.blockpi.network/v1/rpc/public"),
            http("https://rpc.ankr.com/blast"),
          ],
          {
            ...transportConfig,
            rank: {
              interval: 30_000,
              sampleCount: 3,
              timeout: 1000,
              weights: {
                latency: 0.1,
                stability: 0.9,
              },
            },
          }
        ),
      },
  wallets: [
    {
      groupName: "Popular",
      wallets: [metaMaskWallet, rainbowWallet, coinbaseWallet, walletConnectWallet],
    },
  ],
  storage: createStorage({
    storage: cookieStorage,
  }),
});
