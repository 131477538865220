import { Icon, IconProps } from "../Icon";

const ChevronSortDown: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 13L9 5.99997L10.41 4.58997L16 10.17L21.59 4.58997L23 5.99997L16 13ZM16 19L23 26L21.59 27.41L16 21.83L10.41 27.41L9 26L16 19Z"
      fill="currentColor"
    />
  </Icon>
);

export default ChevronSortDown;
