import { Address, Hash } from "viem";
import qs from "qs";
import { useQuery } from "@tanstack/react-query";
import { ChainId } from "@looksrare/config";
import { BigIntish, RQueryOptions } from "../types";
import { useCurrentChainId } from "../hooks";

const sharedQueryOptions = {
  staleTime: Infinity,
  gcTime: Infinity,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
  refetchOnReconnect: false,
  retry: false,
};

export interface ReservoirOracleFloorPriceMessage {
  id: Hash;
  payload: Hash;
  timestamp: BigIntish;
  signature: Hash;
}

type SortedCurrentFloorQueryResponse = number[]; // floor in ETH
export type NullableSortedCurrentFloorQueryResponse = SortedCurrentFloorQueryResponse | null;
export const useCurrentFloorData = (
  collectionAddress: Address,
  options?: RQueryOptions<NullableSortedCurrentFloorQueryResponse>
) => {
  const currentChainId = useCurrentChainId();
  return useQuery({
    queryKey: ["reservoir-current-floor", collectionAddress],
    queryFn: async () => {
      const res = await fetch(`/api/reservoir/floor/${collectionAddress}?chainId=${currentChainId}`);
      const data: SortedCurrentFloorQueryResponse = await res.json();

      if (res.status !== 200) {
        return null;
      }

      return data;
    },

    staleTime: Infinity,
    gcTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    ...options,
  });
};

export interface ReservoirOracleResponse {
  price: number;
  message: ReservoirOracleFloorPriceMessage;
}

export const getOracleFloorData = async (
  collectionAddress: Address,
  chainId: ChainId,
  twapSeconds: number
): Promise<ReservoirOracleResponse> => {
  const res = await fetch(
    `/api/reservoir/oracle/${collectionAddress}?${qs.stringify({
      chainId,
      twapSeconds,
    })}`
  );
  const data = await res.json();

  return {
    price: data.price,
    message: {
      id: data.message.id,
      payload: data.message.payload,
      timestamp: data.message.timestamp,
      signature: data.message.signature,
    },
  };
};
export const useOracleFloorData = (
  collectionAddress: Address,
  twapSeconds: number,
  options?: RQueryOptions<ReservoirOracleResponse>
) => {
  const currentChainId = useCurrentChainId();
  return useQuery({
    queryKey: ["reservoir-oracle-floor", collectionAddress, currentChainId],
    queryFn: async () => await getOracleFloorData(collectionAddress, currentChainId, twapSeconds),
    ...sharedQueryOptions,
    ...options,
  });
};
