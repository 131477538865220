import { Icon, IconProps } from "../Icon";

const LightMode = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 2H17V6.96H15V2ZM21.687 8.89002L25.194 5.38402L26.608 6.79802L23.101 10.305L21.687 8.89002ZM25.04 15H30V17H25.04V15ZM21.6928 23.104L23.1068 21.69L26.6138 25.197L25.1998 26.61L21.6928 23.104ZM15 25.04H17V30H15V25.04ZM5.39584 25.202L8.90384 21.695L10.3178 23.109L6.81084 26.616L5.39584 25.202ZM2 15H6.96V17H2V15ZM5.39 6.80301L6.805 5.38901L10.312 8.89601L8.898 10.31L5.39 6.80301ZM16 10C12.6863 10 10 12.6863 10 16C10 19.3137 12.6863 22 16 22C19.3137 22 22 19.3137 22 16C22 12.6863 19.3137 10 16 10Z"
      fill="currentColor"
    />
  </Icon>
);

export default LightMode;
