import { FC, PropsWithChildren, useMemo } from "react";
import { useTranslation } from "next-i18next";
import times from "lodash/times";
import { Box, BoxProps, Flex, HStack, StackProps } from "@chakra-ui/react";
import { FireIcon, ListIcon, StatusDot, Text } from "@looksrare/uikit";
import {
  type PtbSupportedNetwork,
  getPtbContractNameFromNetwork,
  getNetworkFromPtbContractName,
  getHotCaves,
  PtbCaveReturn,
} from "../..";
import { CaveListPanel } from "../layout";
import { useCaves, useGetCaveQueryParams } from "../../hooks";
import { CaveListItem, CaveListItemSkeleton } from "../CaveListItem";

interface SingleCaveListProps extends BoxProps {
  network: PtbSupportedNetwork;
}

interface HeaderProps extends StackProps {
  leftIcon: JSX.Element;
}

// Match the accordion buttons
const CaveListHeader: FC<PropsWithChildren<HeaderProps>> = ({ children, leftIcon, ...props }) => (
  <HStack height={12} px={2} spacing={2} borderRadius="button" flex="none" {...props}>
    <Flex alignItems="center" justifyContent="center" width={10}>
      {leftIcon}
    </Flex>
    <Text bold>{children}</Text>
  </HStack>
);

export const SingleCaveList = ({ network, ...props }: SingleCaveListProps) => {
  const { t } = useTranslation();
  const { caveOnChainId, network: urlNetwork } = useGetCaveQueryParams();
  const cavesQuery = useCaves(getPtbContractNameFromNetwork(network));
  const hotCaves = useMemo(() => {
    return getHotCaves(cavesQuery.data, 5);
  }, [cavesQuery.data]);

  const runningCaves = cavesQuery.data?.filter((cave) => cave.playersPerRound === cave.round?.players.length);

  return (
    <CaveListPanel {...props}>
      <Flex
        flexDirection="column"
        overflowY="auto"
        sx={{
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box bg="ui-bg">
          <CaveListHeader leftIcon={<StatusDot isPulsing={!!runningCaves?.length} color="link-01" boxSize={3} />}>
            {t("ptb::Live Poking")}
          </CaveListHeader>
          <Box px={2}>
            {(() => {
              if (cavesQuery.isLoading) {
                return times(2).map((n) => <CaveListItemSkeleton key={n} />);
              }

              if (runningCaves?.length !== 0) {
                return runningCaves!.map((cave) => (
                  <CaveListItem
                    key={cave.id}
                    isActive={
                      caveOnChainId === cave.onChainId && urlNetwork === getNetworkFromPtbContractName(cave.contract)
                    }
                    caveOnChainId={cave.onChainId}
                    roundOnChainId={cave.round?.onChainId}
                    name={cave.name}
                    currency={cave.currency}
                    network={network}
                    enterAmount={cave.enterAmount}
                    players={cave.round?.players}
                    playersPerRound={cave.playersPerRound}
                  />
                ));
              }

              return (
                <Box pl={12} h="40px">
                  <Text color="whiteAlpha.600" textStyle="detail">
                    {t("ptb::No live caves")}
                  </Text>
                </Box>
              );
            })()}
          </Box>
        </Box>
        <Box bg="ui-bg">
          <CaveListHeader leftIcon={<FireIcon color="link-01" boxSize={6} />}>{t("ptb::Hot Caves")}</CaveListHeader>
          <Box px={2} mb={4}>
            {(() => {
              if (cavesQuery.isLoading) {
                return times(5).map((n) => <CaveListItemSkeleton key={n} />);
              }
              if (cavesQuery.isSuccess) {
                return hotCaves.map((cave) => (
                  <CaveListItem
                    key={cave.id}
                    isActive={
                      caveOnChainId === cave.onChainId && urlNetwork === getNetworkFromPtbContractName(cave.contract)
                    }
                    caveOnChainId={cave.onChainId}
                    roundOnChainId={cave.round?.onChainId}
                    name={cave.name}
                    currency={cave.currency}
                    network={network}
                    enterAmount={cave.enterAmount}
                    players={cave.round?.players}
                    playersPerRound={cave.playersPerRound}
                  />
                ));
              }
              return null;
            })()}
          </Box>
        </Box>
        <CaveListHeader leftIcon={<ListIcon boxSize={6} color="text-03" />}>{t("ptb::Other Caves")}</CaveListHeader>
        <Box flex={1} px={2}>
          {(() => {
            if (cavesQuery.isLoading) {
              return times(10).map((n) => <CaveListItemSkeleton key={n} />);
            }
            if (cavesQuery.isSuccess) {
              const hotCaveIds = new Set(hotCaves.map((cave) => cave.id));

              const sortByMostPlayed = (a: PtbCaveReturn, b: PtbCaveReturn) =>
                Number(b?.round?.onChainId ?? 0) - Number(a?.round?.onChainId ?? 0);
              const hasRemainingSlots = (cave: PtbCaveReturn) => cave.playersPerRound !== cave.round?.players.length;

              return cavesQuery.data
                .filter((cave) => !hotCaveIds.has(cave.id))
                .filter(hasRemainingSlots)
                .sort(sortByMostPlayed)
                .map((cave) => (
                  <CaveListItem
                    key={cave.id}
                    isActive={
                      caveOnChainId === cave.onChainId && urlNetwork === getNetworkFromPtbContractName(cave.contract)
                    }
                    caveOnChainId={cave.onChainId}
                    roundOnChainId={cave.round?.onChainId}
                    name={cave.name}
                    currency={cave.currency}
                    network={network}
                    enterAmount={cave.enterAmount}
                    players={cave.round?.players}
                    playersPerRound={cave.playersPerRound}
                  />
                ));
            }
            return null;
          })()}
        </Box>
      </Flex>
    </CaveListPanel>
  );
};
