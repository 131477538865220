import {
  Accordion as ChakraAccordion,
  AccordionProps as ChakraAccordionProps,
  AccordionButton as ChakraAccordionButton,
  AccordionPanel,
  AccordionItem,
  Flex,
  useAccordionItemState,
  AccordionButtonProps,
  AccordionPanelProps,
  AccordionItemProps,
  FlexProps,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { Chevron, IconProps } from "../Icons";
import { ChevronProps } from "../Icons/components/Chevron";
import { Text, TextProps } from "../Text";

interface AccordionProps extends ChakraAccordionProps {
  icon?: React.FC<React.PropsWithChildren<IconProps>>;
  leftButtonElement?: ReactNode;
  leftButtonElementWrapperProps?: FlexProps;
  rightButtonElement?: ReactNode;
  rightButtonElementWrapperProps?: FlexProps;
  buttonContent?: ReactNode;
  buttonProps?: AccordionButtonProps;
  panelProps?: AccordionPanelProps;
  accordionItemProps?: AccordionItemProps;
  showAccordionIcon?: boolean;
}

export const AccordionLabel: React.FC<React.PropsWithChildren<TextProps>> = ({ children, ...props }) => (
  <Text textStyle="detail" bold color="text-02" {...props}>
    {children}
  </Text>
);

export const AccordionIcon: React.FC<React.PropsWithChildren<ChevronProps>> = (props) => {
  const { isOpen } = useAccordionItemState();

  return <Chevron color="text-03" isRotated={isOpen} direction="down" {...props} />;
};

const AccordionButton: React.FC<React.PropsWithChildren<AccordionButtonProps>> = (props) => {
  const { isOpen } = useAccordionItemState();

  return (
    <ChakraAccordionButton
      borderBottom={isOpen ? "none" : "1px solid"}
      borderBottomColor="border-01"
      borderBottomLeftRadius={isOpen ? "none" : "container"}
      borderBottomRightRadius={isOpen ? "none" : "container"}
      {...props}
    />
  );
};

export const Accordion: React.FC<React.PropsWithChildren<AccordionProps>> = ({
  icon: Icon,
  leftButtonElement,
  leftButtonElementWrapperProps,
  rightButtonElement,
  rightButtonElementWrapperProps,
  buttonContent,
  buttonProps,
  children,
  panelProps,
  accordionItemProps,
  showAccordionIcon = true,
  ...props
}) => {
  return (
    <ChakraAccordion width="100%" allowMultiple {...props}>
      <AccordionItem {...accordionItemProps}>
        <AccordionButton width="100%" {...buttonProps}>
          <Flex width="100%" justifyContent="space-between" alignItems="center">
            <Flex alignItems="center" {...leftButtonElementWrapperProps}>
              {Icon && <Icon color="text-02" boxSize={5} mr={4} />}
              {leftButtonElement}
            </Flex>
            {buttonContent || null}
            <Flex alignItems="center" {...rightButtonElementWrapperProps}>
              {rightButtonElement}
              {showAccordionIcon && <AccordionIcon ml={rightButtonElement ? 4 : 0} />}
            </Flex>
          </Flex>
        </AccordionButton>
        <AccordionPanel pb={4} motionProps={{ unmountOnExit: true }} {...panelProps}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </ChakraAccordion>
  );
};

export default Accordion;
