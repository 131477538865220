import { Icon, IconProps } from "../Icon";

const HomeFill = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M15.9909 2C16.2161 2 16.4348 2.07523 16.6123 2.21374L31 13.4281L29.7573 14.9999L28 13.6299V25.9999C27.999 26.5301 27.788 27.0382 27.4131 27.4131C27.0383 27.7879 26.5301 27.999 26 27.9999H19V19C19 17.8954 18.1046 17 17 17H15C13.8954 17 13 17.8954 13 19V27.9999H6C5.4699 27.9989 4.96182 27.7878 4.58698 27.413C4.21215 27.0381 4.00108 26.53 4 25.9999V13.6208L2.2427 14.991L1 13.4193L15.3696 2.21374C15.5471 2.07523 15.7658 2 15.9909 2Z"
      fill="currentColor"
    />
  </Icon>
);

export default HomeFill;
