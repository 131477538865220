import { Box, BoxProps, Flex, FlexProps, useTheme } from "@chakra-ui/react";

export interface GradientBorderProps extends BoxProps {
  borderRadius?: string;
  innerProps?: FlexProps;
}

export const GradientBorder = ({ borderRadius = "container", innerProps, children, ...props }: GradientBorderProps) => {
  const theme = useTheme();

  return (
    <Box width="100%" p="1px" borderRadius={borderRadius} {...props}>
      <Flex
        width="100%"
        borderRadius={`calc(${theme.radii[borderRadius]} - 1px)`}
        alignItems="center"
        gap={2}
        p={4}
        overflow="hidden"
        {...innerProps}
      >
        {children}
      </Flex>
    </Box>
  );
};
