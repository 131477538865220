import { Center, FlexProps, HStack, IconButton } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { BackToStartIcon, Chevron, JumpToEndIcon } from "../Icons";
import { Text } from "../Text";

interface TableFooterProps extends FlexProps {
  currentPageNumber: number;
  totalPagesNumber: number;
  goToPage: (pageNumber: number) => void;
  labelPrefix: string;
}

export const TableFooter = ({
  currentPageNumber,
  totalPagesNumber,
  goToPage,
  labelPrefix,
  ...props
}: TableFooterProps) => {
  const { t } = useTranslation();
  return (
    <Center py={3} {...props}>
      <HStack spacing={1}>
        <IconButton
          aria-label={`${labelPrefix}-first-page`}
          data-id={`${labelPrefix}-first-page`}
          size="sm"
          variant="outline"
          colorScheme="secondary"
          isDisabled={currentPageNumber === 1}
          onClick={() => goToPage(1)}
        >
          <BackToStartIcon />
        </IconButton>
        <IconButton
          aria-label={`${labelPrefix}-prev-page`}
          data-id={`${labelPrefix}-to-prev-page-${currentPageNumber - 1}`}
          size="sm"
          variant="outline"
          colorScheme="secondary"
          isDisabled={currentPageNumber === 1}
          onClick={() => goToPage(currentPageNumber - 1)}
        >
          <Chevron direction="left" />
        </IconButton>
        <Text color="text-03" textStyle="detail" px={1}>
          {t("Page {{currentPage}} of {{totalPages}}", {
            currentPage: currentPageNumber,
            totalPages: totalPagesNumber,
          })}
        </Text>
        <IconButton
          aria-label={`${labelPrefix}-next-page`}
          data-id={`${labelPrefix}-to-next-page-${currentPageNumber + 1}`}
          size="sm"
          variant="outline"
          colorScheme="secondary"
          isDisabled={currentPageNumber === totalPagesNumber}
          onClick={() => goToPage(currentPageNumber + 1)}
        >
          <Chevron direction="right" />
        </IconButton>
        <IconButton
          aria-label={`${labelPrefix}-last-page`}
          data-id={`${labelPrefix}-last-page`}
          size="sm"
          variant="outline"
          colorScheme="secondary"
          isDisabled={currentPageNumber === totalPagesNumber}
          onClick={() => goToPage(totalPagesNumber)}
        >
          <JumpToEndIcon />
        </IconButton>
      </HStack>
    </Center>
  );
};
