import {
  Duration,
  formatDistanceToNowStrict,
  formatDuration as dateFnsFormatDuration,
  Locale,
  FormatDurationOptions,
} from "date-fns";
import { type BigIntish } from "@looksrare/utils";

interface CustomFormatDurationOptions extends FormatDurationOptions {
  shortLabels?: boolean;
}

interface DateFnsFormatDistanceToNowStrictOptions {
  addSuffix?: boolean;
  unit?: "second" | "minute" | "hour" | "day" | "month" | "year";
  roundingMethod?: "floor" | "ceil" | "round";
  locale?: Locale;
}

export interface TimeAgoOptions extends DateFnsFormatDistanceToNowStrictOptions {
  shortLabels?: boolean;
}

const formatDistanceLocale: Record<string, string> = {
  xSeconds: "{{count}}s",
  xMinutes: "{{count}}m",
  xHours: "{{count}}h",
  xDays: "{{count}}d",
  xMonths: "{{count}}mos",
  xYears: "{{count}}y",
};

/**
 *
 * @see https://github.com/date-fns/date-fns/blob/main/src/locale/en-US/_lib/formatDistance/index.ts#L89-L110
 */
const formatDistance: Locale["formatDistance"] = (token, count, options) => {
  const result = formatDistanceLocale[token].replace("{{count}}", count.toString());
  if (options?.addSuffix) {
    if (options.comparison && options.comparison > 0) {
      return "in " + result;
    } else {
      return result + " ago";
    }
  }

  return result;
};

/**
 * TODO: override the word formatting
 * @see https://github.com/date-fns/date-fns/blob/master/src/locale/en-US/_lib/formatDistance/index.ts
 */
export const timeAgo = (date: number | Date, options?: TimeAgoOptions) => {
  const { shortLabels, addSuffix = true, ...timeAgoOptions } = options || {};
  return formatDistanceToNowStrict(date, {
    addSuffix,
    locale: shortLabels ? { formatDistance } : undefined,
    ...timeAgoOptions,
  });
};

/**
 * Format unix timestamp value (i.e. those returned by the API) to ms
 * Includes catch for errors thrown by calling .toNumber()
 * @param timestamp BigIntish
 * @returns number
 */
export const timestampInMs = (timestamp: BigIntish) => {
  try {
    return Number(timestamp) * 1000;
  } catch (e) {
    console.error(e);
    return 0;
  }
};
/**
 * Wrapper around "formatDuration" to support shortened time labels
 * @see https://github.com/date-fns/date-fns/issues/2134#issuecomment-788212525
 */
export const formatDuration = (duration: Duration, options?: CustomFormatDurationOptions) => {
  const { shortLabels = false, ...formatDurationOptions } = options || {};
  return dateFnsFormatDuration(duration, {
    ...formatDurationOptions,
    locale: shortLabels ? { formatDistance } : undefined,
  });
};
