import { Icon, IconProps } from "../Icon";

const WethHalf = (props: IconProps) => (
  <Icon viewBox="0 0 48 96" {...props}>
    <path d="M23.9913 8.91397L23.4668 10.6955V62.3857L23.9913 62.909L47.9848 48.7262L23.9913 8.91397Z" fill="#DF5960" />
    <path d="M23.9942 8.91397L0 48.7262L23.9942 62.909V37.82V8.91397Z" fill="#EE9398" />
    <path
      d="M23.9909 67.4524L23.6953 67.8128V86.2252L23.9909 87.0881L47.9985 53.2773L23.9909 67.4524Z"
      fill="#DF5960"
    />
    <path d="M23.9935 87.0879V67.4522L0 53.2772L23.9935 87.0879Z" fill="#EE9398" />
    <path d="M23.9941 62.9063L47.987 48.7239L23.9941 37.818V62.9063Z" fill="#CF373E" />
    <path d="M0 48.7242L23.9935 62.9066V37.8183L0 48.7242Z" fill="#DF5960" />
  </Icon>
);

export default WethHalf;
