import type { CurrencySettings } from "./types";

export const currencySetting: CurrencySettings = {
  BLAST: {
    displayDecimals: {
      default: 2,
      small: 0,
    },
  },
  ETH: {
    displayDecimals: {
      default: 4,
      large: 6,
      small: 2,
    },
  },
  YOLO: {
    displayDecimals: {
      default: 2,
      small: 0,
    },
  },
};
