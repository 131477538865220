import { type FC, type PropsWithChildren } from "react";
import { useTranslation } from "next-i18next";
import { type Hash } from "viem";
import { useAccount, usePublicClient } from "wagmi";
import { useQuery } from "@tanstack/react-query";
import {
  Flex,
  HStack,
  type FlexProps,
  Spinner,
  type StackProps,
  type BoxProps,
  Box,
  ModalHeader,
  type ModalHeaderProps,
} from "@chakra-ui/react";
import { ChainId } from "@looksrare/config";
import {
  Button,
  CheckmarkFilledIcon,
  ClockIcon,
  ErrorIcon,
  ExternalLink,
  ModalCloseButton,
  ModalProps,
  Text,
  type TextProps,
} from "@looksrare/uikit";
import { JwtScope, getExplorerLink, isAuthorized } from "@looksrare/utils";
import { useGetCaveQueryParams, usePtbContractInfo } from "../../../hooks";
import { isEthereumChainId } from "../../../utils";
import { StepStatus } from "./state";

interface EnterCaveHeaderProps extends ModalHeaderProps {
  onClose: ModalProps["onClose"];
}

export const EnterCaveHeader: FC<PropsWithChildren<EnterCaveHeaderProps>> = ({ onClose, children, ...props }) => (
  <ModalHeader bg="ui-bg" {...props}>
    <Flex width="100%" justifyContent="space-between" alignItems="center">
      <Text textStyle="heading-04" bold>
        {children}
      </Text>
      <ModalCloseButton onClick={onClose} />
    </Flex>
  </ModalHeader>
);

export const Row: FC<PropsWithChildren<FlexProps>> = (props) => (
  <Flex alignItems="center" justifyContent="space-between" {...props} />
);

export const TextDetail: FC<PropsWithChildren<TextProps>> = (props) => (
  <Text color="text-03" textStyle="detail" {...props} />
);

interface StepStatusIconProps {
  stepStatus: StepStatus;
}

export const StepStatusIcon = ({ stepStatus }: StepStatusIconProps) => {
  switch (stepStatus) {
    case "past":
      return <CheckmarkFilledIcon color="text-disabled" />;
    case "current":
      return <Spinner color="interactive-03" />;
    case "future":
    default:
      return <ClockIcon color="text-03" />;
  }
};

interface StepRowProps extends StackProps {
  stepStatus: StepStatus;
}

export const StepRow: FC<PropsWithChildren<StepRowProps>> = ({ stepStatus, children, ...props }) => (
  <HStack spacing={4} {...props}>
    <StepStatusIcon stepStatus={stepStatus} />
    <Text bold color={stepStatus === "past" ? "text-03" : "text-02"}>
      {children}
    </Text>
  </HStack>
);

export const StepDetails: FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Box bg="ui-bg" borderRadius="container" p={6} mb={4} flex={1} width="100%" {...props}>
      {children}
    </Box>
  );
};

interface SubStepProps extends FlexProps {
  stepStatus: StepStatus;
}

export const SubStep: FC<PropsWithChildren<SubStepProps>> = ({ stepStatus, children, ...props }) => {
  return (
    <Row {...props}>
      <Text textStyle="detail" color="text-03">
        {children}
      </Text>
      <StepStatusIcon stepStatus={stepStatus} />
    </Row>
  );
};

interface CurrentSubStepProps extends FlexProps {
  currentStep: number;
  totalSteps: number;
  hashLinkText: string;
  hasError: boolean;
  onRetry?: () => void;
  hash?: Hash;
  chainId: ChainId;
}

export const CurrentSubStep = ({
  currentStep,
  totalSteps,
  hashLinkText,
  hasError,
  onRetry,
  hash,
  chainId,
  ...props
}: CurrentSubStepProps) => {
  const { t } = useTranslation();
  const counterDisplay = `${currentStep}/${totalSteps}`;
  return (
    <Box>
      <Text color={hasError ? "text-error" : "text-01"} textStyle="detail" bold mb={4}>
        {hasError
          ? t("You declined the transaction {{counterDisplay}}", { counterDisplay })
          : t("Confirm transaction in wallet {{counterDisplay}}", { counterDisplay })}
      </Text>
      <Row minHeight={6} {...props}>
        {hash ? (
          <ExternalLink href={getExplorerLink(hash, "transaction", chainId)} color="text-01" textStyle="detail">
            {hashLinkText}
          </ExternalLink>
        ) : (
          <Text color="text-03" textStyle="detail">
            {hashLinkText}
          </Text>
        )}
        {hasError ? <ErrorIcon color="text-error" boxSize={6} /> : <Spinner boxSize={6} color="interactive-03" />}
      </Row>
      {hasError && onRetry && (
        <Button width="100%" mt={4} onClick={onRetry}>
          Retry
        </Button>
      )}
    </Box>
  );
};

interface PreflightCheckReturn {
  hasValidJwt: boolean;
  hasTransferManagerApproved: boolean;
  erc20Allowance: bigint;
}

export const usePreflightCheck = (
  chainId: ChainId,
  { onSuccess, enabled }: { onSuccess?: (data: PreflightCheckReturn) => void; enabled?: boolean }
) => {
  const { address } = useAccount();
  const publicClient = usePublicClient();
  const { caveOnChainId, roundOnChainId } = useGetCaveQueryParams();
  const contractInfo = usePtbContractInfo();

  // @todo-ptbl2 Update all the PTB keys to include the current connected network
  return useQuery({
    queryKey: ["ptb", "preflight", chainId, caveOnChainId, roundOnChainId, address],
    queryFn: async (): Promise<PreflightCheckReturn> => {
      if (address && publicClient) {
        const hasValidJwt = isAuthorized(address, JwtScope.LongLived);
        const promises: [Promise<boolean>, Promise<bigint>?] = [
          publicClient.readContract({
            ...contractInfo[chainId].transferManager,
            functionName: "hasUserApprovedOperator",
            args: [address, contractInfo[chainId].ptb.address],
          }),
        ];

        // @todo-ptbl2 This is currently not needed on Arbitrum
        if (isEthereumChainId(chainId)) {
          promises.push(
            publicClient.readContract({
              ...contractInfo[chainId].looks,
              functionName: "allowance",
              args: [address, contractInfo[chainId].transferManager.address],
            })
          );
        }
        const [hasTransferManagerApproved, erc20Allowance] = await Promise.all(promises);
        const response: PreflightCheckReturn = {
          hasValidJwt,
          hasTransferManagerApproved,
          erc20Allowance: erc20Allowance || 0n,
        };
        onSuccess?.(response);
        return response;
      }
      return { hasValidJwt: false, hasTransferManagerApproved: false, erc20Allowance: 0n };
    },
    refetchInterval: 15 * 1_000,
    enabled,
  });
};
