import { Icon, IconProps } from "../Icon";

const YoloGamesColor = (props: IconProps) => (
  <Icon viewBox="0 0 128 128" {...props}>
    <circle cx="63.9882" cy="63.9882" r="63.9882" fill="#EE5396" />
    <path
      d="M74.0173 34.7054H96.5244L74.8649 100.32H52.3578L60.5508 75.6327H44.1649L31.4517 34.7054H53.6762L63.0934 67.8415L74.0173 34.7054Z"
      fill="#F3F752"
    />
  </Icon>
);

export default YoloGamesColor;
