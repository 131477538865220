import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { useAccount, usePublicClient } from "wagmi";
import { Address, zeroAddress } from "viem";
import { ChainId } from "@looksrare/config";
import { BigIntish, isAddressEqual, multiplyWeiByNumber } from "@looksrare/utils";
import { PlayerWithdrawalCallData, PtbContractName } from "../types";
import { getNetworkFromPtbContractName } from "../utils";
import { getActiveRoundOnChainId } from "../graphql";
import { getRolloverAndClaimData, useUnclaimedPtbFunds } from "./useUnclaimedPtbFunds";
import { usePtbContractInfo } from "./usePtbContractInfo";

interface ValidatedRolloverAndClaimData {
  caveOnChainId: BigIntish;
  chainId: ChainId;
  contractName: PtbContractName;
  currency: Address;
  enterAmount: BigIntish;
  numberOfRounds: number;
  protocolFeeBp: BigIntish;
  playersPerRound: number;
}

/**
 * This hook aims to resolve a timing issue between a "withdraw" and a subsequent "rollover".
 * The rollover balance is fetched from GraphQL, but for a brief while (say 1 minute) after a
 * "withdraw", GraphQL is not able to reflect the new balance. If GraphQL reports a rollover balance,
 * simulate the "rollover" call to confirm the tx would go through, and handle the case where the
 * transaction reverts due to insufficient rollover funds.
 */
export const useValidatedRolloverAndClaimData = ({
  caveOnChainId,
  chainId,
  contractName,
  currency,
  enterAmount,
  numberOfRounds,
  protocolFeeBp,
  playersPerRound,
}: ValidatedRolloverAndClaimData) => {
  const {
    unclaimedRefunds,
    unclaimedWinnings,
    isLoading: isLoadingGqlQuery,
  } = useUnclaimedPtbFunds(getNetworkFromPtbContractName(contractName));
  const { address } = useAccount();
  const contractInfo = usePtbContractInfo();
  const publicClient = usePublicClient();

  /**
   * This is the base data we need for rollover, enter, and the UI to behave properly.
   * The useQuery below attempts to simulate a tx with this data, and based on those results we
   * modify the data before returning a validated set of data to the consumer.
   */
  const initialRolloverAndClaimData = useMemo(
    () =>
      getRolloverAndClaimData({
        unclaimedRefunds,
        unclaimedWinnings,
        caveId: BigInt(caveOnChainId),
        enterAmount,
        protocolFeeBp,
        playersPerRound,
      }),
    [unclaimedRefunds, unclaimedWinnings, caveOnChainId, enterAmount, playersPerRound, protocolFeeBp]
  );

  const { totalRolloverAvailable, playerDetails } = initialRolloverAndClaimData;

  /**
   * If there is a rollover balance, simulate the rollover tx.
   * Returns any error message.
   */
  const isQueryEnabled =
    !isLoadingGqlQuery && !!publicClient && !!totalRolloverAvailable && totalRolloverAvailable > 0n;

  const simulatedTransactionQuery = useQuery({
    queryKey: ["ptb", "simulateRollover", caveOnChainId, numberOfRounds, address],
    queryFn: async () => {
      if (!publicClient) {
        throw new Error("No public client found");
      }
      // this condition is preempted by the enabled option, but we include it for extra safety
      if (!totalRolloverAvailable || totalRolloverAvailable === 0n || !address) {
        return null;
      }
      const roundOnChainId = await getActiveRoundOnChainId({ caveOnChainId, contract: contractName });
      if (!roundOnChainId) {
        return "No roundOnChainId found";
      }
      const isUsingEth = isAddressEqual(currency, zeroAddress);
      const totalValue = multiplyWeiByNumber(enterAmount, Number.isNaN(numberOfRounds) ? 1 : numberOfRounds);
      const payableValue = totalValue - totalRolloverAvailable < 0n ? 0n : totalValue - totalRolloverAvailable;
      // @todo-wagmiv2 "& readonly never[]" HAS to be a bug
      const rolloverPlayerDetails = playerDetails.slice(0, numberOfRounds) as PlayerWithdrawalCallData[] & never[];
      const numberOfExtraRoundsToEnter = numberOfRounds - rolloverPlayerDetails.length;

      try {
        await publicClient.simulateContract({
          ...contractInfo[chainId].ptb,
          functionName: "rollover",
          account: address,
          args: [
            [
              {
                caveId: BigInt(caveOnChainId),
                startingRoundId: BigInt(roundOnChainId),
                numberOfExtraRoundsToEnter: BigInt(numberOfExtraRoundsToEnter),
                playerDetails: rolloverPlayerDetails,
              },
            ],
          ],
          value: isUsingEth ? payableValue : undefined,
        });

        // returning no error
        return null;
      } catch (err) {
        return err;
      }
    },

    refetchInterval: 15 * 1_000,
    enabled: !!isQueryEnabled,
  });

  const returnValues = useMemo(() => {
    const transactionError = simulatedTransactionQuery?.data;

    // NO ERROR
    if (!transactionError) {
      return {
        ...initialRolloverAndClaimData,
        isLoadingValidation: !!isQueryEnabled && simulatedTransactionQuery.isLoading,
        transactionError: false,
      };
    }

    // YES ERROR
    /**
     * If there is a transaction error, we consider two cases. For now we treat both the same way.
     * 1. The error is the revertError, most likely meaning the user does not have the rolloverBalance that graphQL is reporting.
     * In this case we update the values to be returned: `totalRolloverAvailable`, `playerDetails`, `maxWinningsToClaim`.
     *
     * 2. The error is some other error. This is unlikely but certainly possible. We can improve this by handling errors explicitly as
     * they are surfaced.
     */
    console.error("Rollover simulation failed. Bypassing reported rollover balance.", transactionError);

    // Override the graphql values - returning 0n & empty arrays will let the UI behave as if there is no rollover available.
    return {
      totalRolloverAvailable: 0n,
      playerDetails: [] as PlayerWithdrawalCallData[],
      maxWinningsToClaim: 0n,
      isLoadingValidation: !!isQueryEnabled && simulatedTransactionQuery.isLoading,
      transactionError,
    };
  }, [
    initialRolloverAndClaimData,
    isQueryEnabled,
    simulatedTransactionQuery?.data,
    simulatedTransactionQuery.isLoading,
  ]);

  return returnValues;
};
