import { Icon, IconProps } from "../Icon";

const Link = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.2505 6.76009C28.1247 5.63037 26.5954 4.99536 25.0005 4.99536C23.4056 4.99536 21.8763 5.63037 20.7505 6.76009L22.1705 8.18009C23.1833 7.16724 24.6596 6.77168 26.0432 7.14241C27.4267 7.51314 28.5074 8.59383 28.8782 9.97741C29.2489 11.361 28.8533 12.8372 27.8405 13.8501L19.8405 21.8501C18.8294 22.8629 17.3548 23.2595 15.9721 22.8903C14.5895 22.5211 13.5088 21.4423 13.1371 20.0603C12.7655 18.6782 13.1594 17.2029 14.1705 16.1901L15.5805 14.7701L14.1705 13.3501L12.7505 14.7701C11.6208 15.8959 10.9858 17.4252 10.9858 19.0201C10.9858 20.615 11.6208 22.1443 12.7505 23.2701C13.882 24.3871 15.4106 25.0093 17.0005 25.0001C18.6016 25.0067 20.139 24.373 21.2705 23.2401L29.2705 15.2401C31.6046 12.892 31.5956 9.09711 29.2505 6.76009ZM4.19034 24.8201C3.43616 24.0694 3.01217 23.0492 3.01217 21.9851C3.01217 20.921 3.43616 19.9008 4.19034 19.1501L12.1903 11.1501C12.941 10.3959 13.9612 9.97192 15.0253 9.97192C16.0894 9.97192 17.1097 10.3959 17.8603 11.1501C18.61 11.9069 19.0212 12.935 19.0003 14.0001C19.0065 15.0682 18.5852 16.0944 17.8303 16.8501L15.7103 19.0001L17.1303 20.4201L19.2503 18.3001C21.6003 15.9501 21.6003 12.1401 19.2503 9.79009C16.9004 7.44011 13.0903 7.44011 10.7403 9.79009L2.74034 17.7901C1.60754 18.9163 0.970581 20.4477 0.970581 22.0451C0.970581 23.6425 1.60754 25.1739 2.74034 26.3001C3.87916 27.4086 5.41123 28.02 7.00034 28.0001C8.60344 28.0016 10.1421 27.3689 11.2803 26.2401L9.86034 24.8201C9.10966 25.5743 8.08943 25.9982 7.02534 25.9982C5.96124 25.9982 4.94101 25.5743 4.19034 24.8201Z"
      fill="currentColor"
    />
  </Icon>
);

export default Link;
