import { Icon, IconProps } from "../Icon";

const LogoRound = (props: IconProps) => (
  <Icon viewBox="0 0 48 48" color="#0CE466" {...props}>
    <circle cx="24" cy="24" r="24" fill="#0CE466" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 26.5946C21.2546 26.5946 19.027 24.3693 19.027 21.6217C19.027 18.874 21.2546 16.6487 24 16.6487C26.7453 16.6487 28.9729 18.874 28.9729 21.6217C28.9729 24.3693 26.7453 26.5946 24 26.5946ZM21.8378 21.6217C21.8378 22.8163 22.8063 23.7838 24 23.7838C25.1936 23.7838 26.1621 22.8163 26.1621 21.6217C26.1621 20.427 25.1936 19.4595 24 19.4595C22.8063 19.4595 21.8378 20.427 21.8378 21.6217Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 21.6282L17.5135 12.1082H30.4864L40 21.6282L24 37.6216L8 21.6282ZM31.3513 18.1622C27.3091 14.1022 20.6909 14.1022 16.6486 18.1622L13.1892 21.6217L16.6486 25.0812C20.6909 29.1411 27.3091 29.1411 31.3513 25.0811L34.8108 21.6217L31.3513 18.1622Z"
      fill="black"
    />
  </Icon>
);

export default LogoRound;
