import { useEffect, useMemo } from "react";
import { type Address } from "viem";
import { useElapsedTime } from "use-elapsed-time";
import { CircularCountdown, Text } from "@looksrare/uikit";
import { pokeDurSec } from "../config";
import { getStrokeColor2 } from "../utils";

interface PokeDurationTimerProps {
  size: number;
  playerAddress: Address;
  isPokingUntil?: string | null;
}

export const PokeDurationTimer2 = ({ size, playerAddress, isPokingUntil }: PokeDurationTimerProps) => {
  const pokingDur = useMemo(() => {
    return isPokingUntil ? Math.floor((new Date(isPokingUntil).getTime() - Date.now()) / 1_000) : pokeDurSec;
  }, [isPokingUntil]);
  const { elapsedTime, reset } = useElapsedTime({
    isPlaying: !!isPokingUntil,
    duration: pokingDur,
  });
  const countdown = pokingDur - elapsedTime;
  const color = getStrokeColor2(countdown, false);

  useEffect(() => {
    reset();
  }, [playerAddress, reset]);

  return (
    <CircularCountdown
      size={size}
      duration={pokingDur}
      countdown={countdown}
      strokeWidth="6px"
      strokeColor={color.stroke}
      trackColor="border-01"
    >
      <Text fontSize="6xl" bold lineHeight={14} color={color.text} sx={{ fontVariantNumeric: "tabular-nums" }}>
        {Math.floor(countdown)}
      </Text>
    </CircularCountdown>
  );
};
