import { MoDAssetPair } from "@looksrare/yolo-games-gql-typegen";

export const getAssetPairFromUrlPair = (urlPair: string): MoDAssetPair => {
  const [token, fiat] = urlPair.split("-");

  return `${token}${fiat}`.toUpperCase() as MoDAssetPair;
};

export const getUrlPairFromAssetPair = (assetPair: MoDAssetPair): string => {
  const fiatIndex = assetPair.indexOf("USD");
  const [token, fiat] = [assetPair.substring(0, fiatIndex), assetPair.substring(fiatIndex)];

  return `${token}-${fiat}`.toLowerCase();
};
