import { useTranslation } from "next-i18next";
import { PtbRoundLogType } from "../../../../types";
import { LogText } from "./LogText";
import type { LogTextProps, RoundLogTextGetter } from "./types";

const CaveResetText1 = (props: LogTextProps) => {
  const { t } = useTranslation();
  return <LogText {...props}>{t("ptb::commentary.caveReset1")}</LogText>;
};

export const caveResetLogs: RoundLogTextGetter = ({ user1 }) => [
  <CaveResetText1 key={`${PtbRoundLogType.CAVE_RESET}1`} user1={user1} />,
];
