import { Icon, IconProps } from "../Icon";

const ShoppingBag = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.7603 11.35C28.5703 11.1279 28.2927 11 28.0003 11H22.0003V7C22.0003 5.34315 20.6572 4 19.0003 4H13.0003C11.3435 4 10.0003 5.34315 10.0003 7V11H4.00034C3.70697 10.9967 3.42693 11.1224 3.23442 11.3438C3.0419 11.5652 2.95632 11.8599 3.00034 12.15L4.88034 24.3C5.02988 25.2861 5.88306 26.0113 6.88034 26H25.1403C26.1376 26.0113 26.9908 25.2861 27.1403 24.3L29.0003 12.15C29.0409 11.8611 28.9532 11.5689 28.7603 11.35ZM12 7C12 6.44772 12.4477 6 13 6H19C19.5523 6 20 6.44772 20 7V11H12V7ZM25.1405 24H6.86051L5.17051 13H26.8305L25.1405 24Z"
      fill="currentColor"
    />
  </Icon>
);

export default ShoppingBag;
