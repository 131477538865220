import { Icon, IconProps } from "../Icon";

const Binoculars = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 8V5C28 4.44772 27.5523 4 27 4H21C20.4477 4 20 4.44772 20 5V8C18.8954 8 18 8.89543 18 10V12H14V10C14 8.89543 13.1046 8 12 8V5C12 4.44772 11.5523 4 11 4H5C4.44772 4 4 4.44772 4 5V8C2.89543 8 2 8.89543 2 10V22C2.00429 22.7102 2.38491 23.3649 3 23.72V27C3 27.5523 3.44772 28 4 28H12C12.5523 28 13 27.5523 13 27V23.72C13.6151 23.3649 13.9957 22.7102 14 22V20H18V22C18.0043 22.7102 18.3849 23.3649 19 23.72V27C19 27.5523 19.4477 28 20 28H28C28.5523 28 29 27.5523 29 27V23.72C29.6151 23.3649 29.9957 22.7102 30 22V10C30 8.89543 29.1046 8 28 8ZM11 26H5V24H11V26ZM12 22H4V10H6V6H10V10H12V22ZM14 18V14H18V18H14ZM27 26H21V24H27V26ZM28 22H20V10H22V6H26V10H28V22Z"
      fill="currentColor"
    />
  </Icon>
);

export default Binoculars;
