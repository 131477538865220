import { datadogLogs } from "@datadog/browser-logs";

interface Params extends Record<string, any> {
  clientError?: any;
}

export const logDataDogError = (message: string, params: Params) => {
  // only log in client
  const isBrowser = typeof window !== "undefined";
  if (isBrowser) {
    datadogLogs.logger.error(`[LOGGER] ${message}`, params);
  }
};
