import { useTranslation } from "next-i18next";
import { HStack, IconButton, InputGroup, InputRightElement, ModalBody, ModalFooter, Stack } from "@chakra-ui/react";
import { formatToSignificant, toDecimals, useTokenBalance } from "@looksrare/utils";
import { useAccount } from "wagmi";
import { ChainId, blastMainnetAddresses, mainnetAddresses } from "@looksrare/config";
import { ArrowHorizontalIcon, LooksTokenIcon, WalletIcon } from "../../../Icons";
import { ModalHeroHeader } from "../../../Modal/ModalHeaderLegacy";
import { LooksBridgeDirection } from "../BridgeLooksModal";
import { Text } from "../../../Text";
import { NumberInput } from "../../../Input";
import { Button } from "../../../Button";

interface BridgeLooksInputViewProps {
  looksToBridge: string;
  direction: LooksBridgeDirection;
  setLooksToBridge: (val: string) => void;
  onSwitchDirection: () => void;
  onStartBridge: () => void;
  onClose: () => void;
}

export const BridgeLooksInputView = ({
  looksToBridge,
  direction,
  setLooksToBridge,
  onSwitchDirection,
  onStartBridge,
  onClose,
}: BridgeLooksInputViewProps) => {
  const { t } = useTranslation();
  const { address } = useAccount();
  const { LOOKS } = direction === LooksBridgeDirection.ETH_TO_BLAST ? mainnetAddresses : blastMainnetAddresses;
  const balanceChainId = direction === LooksBridgeDirection.ETH_TO_BLAST ? ChainId.MAINNET : ChainId.BLAST_MAINNET;

  const { data: looksBalance = 0n } = useTokenBalance({
    contractAddress: LOOKS,
    address: address!,
    chainId: balanceChainId,
    queryOptions: { enabled: !!address },
  });
  const looksToBridgeWei = toDecimals(looksToBridge);

  const isBalanceSufficient = looksBalance >= looksToBridgeWei;

  const getDirectionLabel = () => {
    if (direction === LooksBridgeDirection.ETH_TO_BLAST) {
      return t("Bridge LOOKS to Blast");
    }

    return t("Bridge bLOOKS to Ethereum");
  };

  return (
    <>
      <ModalHeroHeader
        icon={WalletIcon}
        title={t("Bridge Your LOOKS")}
        label={t("This will bridge your Ethereum LOOKS to Blast's bLOOKS.")}
        onClose={onClose}
        showCloseButton
      />

      <ModalBody>
        <Stack>
          <HStack justifyContent="space-between">
            <HStack>
              <IconButton
                onClick={onSwitchDirection}
                icon={<ArrowHorizontalIcon boxSize={5} />}
                size="xs"
                colorScheme="secondary"
                aria-label={t("Switch bridge direction")}
              />

              <Text textStyle="detail">{getDirectionLabel()}</Text>
            </HStack>

            <HStack>
              <Text textStyle="detail" color="text-03">
                {t("Balance")}: {formatToSignificant(looksBalance, 4)}
              </Text>

              <LooksTokenIcon boxSize={5} />
            </HStack>
          </HStack>

          <InputGroup size="md">
            <NumberInput value={looksToBridge} onTextChange={setLooksToBridge} />

            <InputRightElement width="max-content" pr={4}>
              <LooksTokenIcon boxSize={5} />
            </InputRightElement>
          </InputGroup>

          {!isBalanceSufficient && (
            <Text textStyle="caption" color="text-error">
              {t("Insufficient Funds")}
            </Text>
          )}
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button onClick={onStartBridge} width="100%" isDisabled={!looksToBridge || !isBalanceSufficient}>
          {t("Bridge")}
        </Button>
      </ModalFooter>
    </>
  );
};
