import {
  Box,
  Flex,
  forwardRef,
  ModalHeader as ChakraModalHeader,
  ModalHeaderProps as ChakraModalHeaderProps,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { ThemingProps } from "@chakra-ui/system";
import { Text, TextProps } from "../Text";
import { IconProps } from "../Icons/Icon";
import { ModalLegacyProps } from "./ModalLegacy";
import { ModalBackButton, ModalCloseButton } from "./ModalButtons";

export interface ModalHeaderProps
  extends ChakraModalHeaderProps,
    ThemingProps,
    Pick<
      ModalLegacyProps,
      "title" | "label" | "showCloseButton" | "showBackButton" | "onBack" | "onClose" | "leftElement" | "rightElement"
    > {
  dataId?: string;
}

export const ModalHeaderLegacy = forwardRef<ModalHeaderProps, "div">(
  (
    {
      leftElement,
      rightElement,
      size,
      title,
      label,
      showCloseButton,
      showBackButton,
      onBack,
      onClose,
      dataId,
      ...props
    },
    ref
  ) => (
    <ChakraModalHeader position="relative" size={size} ref={ref} {...props}>
      {/* Left */}
      <Flex>
        {showBackButton && <ModalBackButton onClick={onBack} />}
        <Stack spacing={1}>
          <Flex alignItems="center">
            {title && (
              <Text as="h2" textStyle="heading-04" bold>
                {title}
              </Text>
            )}
            {!!leftElement && leftElement}
          </Flex>
          {label && (
            <Text textStyle="detail" color="text-02">
              {label}
            </Text>
          )}
        </Stack>
      </Flex>
      {/* Right */}
      <Flex>
        {!!rightElement && rightElement}
        {showCloseButton && <ModalCloseButton dataId={dataId} onClick={onClose} />}
      </Flex>
    </ChakraModalHeader>
  )
);

export type ModalHeroIconHeaderProps = Omit<ModalHeaderProps, "leftElement" | "rightElement"> & {
  heroElement: ModalLegacyProps["heroElement"];
  dataId?: string;
};

export const ModalHeroElementHeader = forwardRef<ModalHeroIconHeaderProps, "div">(
  ({ heroElement, size, title, label, showCloseButton, showBackButton, onBack, onClose, dataId, ...props }, ref) => (
    <ChakraModalHeader position="relative" size={size} ref={ref} pt={6} {...props}>
      {showBackButton && <ModalBackButton position="absolute" top={4} left={4} onClick={onBack} />}
      <Box width="100%">
        <Flex flexDir="column" alignItems="center">
          {heroElement}
          <VStack width="100%" spacing={2} px={4}>
            {title && (
              <Text textAlign="center" as="h2" textStyle="heading-04" bold>
                {title}
              </Text>
            )}
            {label && (
              <Text textAlign="center" textStyle="detail" color="text-02" fontWeight="400">
                {label}
              </Text>
            )}
          </VStack>
        </Flex>
      </Box>
      {showCloseButton && <ModalCloseButton dataId={dataId} position="absolute" top={4} right={4} onClick={onClose} />}
    </ChakraModalHeader>
  )
);

export type ModalHeroHeaderProps = Omit<ModalHeaderProps, "leftElement" | "rightElement"> & {
  icon: (ip: IconProps) => JSX.Element;
  iconProps?: IconProps;
  labelProps?: TextProps;
  titleProps?: TextProps;
  dataId?: string;
};

export const ModalHeroHeader = forwardRef<ModalHeroHeaderProps, "div">(
  (
    {
      icon: Icon,
      iconProps,
      size,
      title,
      label,
      labelProps,
      showCloseButton,
      showBackButton,
      onBack,
      onClose,
      dataId,
      titleProps,
      ...props
    },
    ref
  ) => (
    <ChakraModalHeader position="relative" size={size} ref={ref} pt={4} pb={0} px={8} {...props}>
      {showBackButton && <ModalBackButton position="absolute" top={4} left={4} onClick={onBack} />}
      <Flex flexDirection="column" py={6} width="100%" gap={4} alignItems="center">
        <Icon boxSize={16} color="link-01" {...iconProps} />
        <VStack width="100%" spacing={2}>
          {title && (
            <Text textAlign="center" textStyle="heading-03" bold {...titleProps}>
              {title}
            </Text>
          )}
          {label && (
            <Text textAlign="center" textStyle="detail" fontWeight="400" {...labelProps}>
              {label}
            </Text>
          )}
        </VStack>
      </Flex>
      {showCloseButton && <ModalCloseButton dataId={dataId} position="absolute" top={4} right={4} onClick={onClose} />}
    </ChakraModalHeader>
  )
);
