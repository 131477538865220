import { useMemo } from "react";
import { Duration } from "date-fns";
import { Flex, FlexProps, Spinner } from "@chakra-ui/react";
import { semanticTokens } from "@looksrare/chakra-theme";
import { getFormattedTimer } from "@looksrare/utils";
import { RecentlyViewedIcon } from "../Icons";
import { Text } from "../Text";

export interface RoundCountdownProps extends FlexProps {
  cutoffTimeMs: number | null;
  isCancelled?: boolean;
  isFinished?: boolean;
  duration?: Duration;
}

type SemanticKeys = keyof typeof semanticTokens.colors;

export const RoundCountdown = ({ cutoffTimeMs, duration, isCancelled, isFinished, ...props }: RoundCountdownProps) => {
  const guaranteedDuration = useMemo(() => {
    // Ensure a valid and positive duration to work with
    if (duration && (duration.minutes ?? 0) >= 0 && (duration.seconds ?? 0) >= 0) {
      return duration;
    }
    return {
      minutes: 0,
      seconds: 0,
    };
  }, [duration]);

  const hasDurationLeft = guaranteedDuration.minutes! > 0 || guaranteedDuration.seconds! > 0;

  const hasTimeLeft = !!cutoffTimeMs && cutoffTimeMs * 1000 > Date.now();
  const isIdle = !!duration && cutoffTimeMs === null && !isCancelled && !isFinished;
  const shouldShowTimer = isIdle || (hasTimeLeft && hasDurationLeft);

  const colors = useMemo((): { highlight: SemanticKeys; background: SemanticKeys } => {
    if (hasTimeLeft && hasDurationLeft) {
      if (guaranteedDuration.minutes === 0) {
        if (guaranteedDuration.seconds! < 30) {
          return {
            highlight: "support-error",
            background: "support-error-bg",
          };
        } else {
          return {
            highlight: "support-warning",
            background: "support-warning-bg",
          };
        }
      }
      return {
        highlight: "support-success",
        background: "support-success-bg",
      };
    }
    return {
      highlight: "border-01",
      background: "ui-bg",
    };
  }, [guaranteedDuration.minutes, guaranteedDuration.seconds, hasDurationLeft, hasTimeLeft]);

  const node = useMemo(() => {
    if (isCancelled || isFinished) {
      return <RecentlyViewedIcon boxSize={5} />;
    }

    if (shouldShowTimer) {
      const formattedDuration = getFormattedTimer(duration);
      return (
        <Text textStyle="heading-03" color="inherit" bold style={{ fontFeatureSettings: '"tnum" on, "lnum" on' }}>
          {formattedDuration}
        </Text>
      );
    }

    return <Spinner size="sm" color="text-03" />;
  }, [duration, isCancelled, isFinished, shouldShowTimer]);

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      borderColor={colors.highlight}
      border="solid 1px"
      borderRadius="button"
      color={colors.highlight}
      bgColor={colors.background}
      minWidth="66px"
      minHeight="32px"
      width="fit-content"
      px={2}
      {...props}
    >
      {node}
    </Flex>
  );
};
