import { Icon, IconProps } from "../Icon";

const OrbitToken = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 96 96" {...props}>
      <rect width="96" height="96" rx="48" fill="#1F1D1D" />
      <path
        d="M59.25 47.75C59.25 53.9632 54.2132 59 48 59C41.7868 59 36.75 53.9632 36.75 47.75C36.75 41.5368 41.7868 36.5 48 36.5C54.2132 36.5 59.25 41.5368 59.25 47.75Z"
        fill="#FC691C"
      />
      <path
        d="M54.25 20.25C54.25 23.7018 51.4518 26.5 48 26.5C44.5482 26.5 41.75 23.7018 41.75 20.25C41.75 16.7982 44.5482 14 48 14C51.4518 14 54.25 16.7982 54.25 20.25Z"
        fill="#FC691C"
      />
      <path
        d="M54.25 75.25C54.25 78.7018 51.4518 81.5 48 81.5C44.5482 81.5 41.75 78.7018 41.75 75.25C41.75 71.7982 44.5482 69 48 69C51.4518 69 54.25 71.7982 54.25 75.25Z"
        fill="#FC691C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.0001 72.75C61.8072 72.75 73 61.5572 73 47.7501C73 33.943 61.8072 22.7501 48.0001 22.7501C34.193 22.7501 23.0001 33.943 23.0001 47.7501C23.0001 61.5572 34.193 72.75 48.0001 72.75ZM48.0001 77.75C64.5686 77.75 78 64.3186 78 47.7501C78 31.1816 64.5686 17.7501 48.0001 17.7501C31.4316 17.7501 18.0001 31.1816 18.0001 47.7501C18.0001 64.3186 31.4316 77.75 48.0001 77.75Z"
        fill="#FC691C"
      />
    </Icon>
  );
};

export default OrbitToken;
