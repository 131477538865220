import Highcharts from "highcharts";
import type { LiquidityPoolContract, LiquidityPoolInterval } from "@looksrare/yolo-games-gql-typegen";
import { gameAddresses } from "@/config";
import { getDefaultChain } from "@/utils";

export const getLiquidityPoolAddressFromContract = (contract: LiquidityPoolContract) => {
  const chainId = getDefaultChain();
  const addresses = gameAddresses[chainId];

  switch (contract) {
    case "GAMES_YOLO_LP_V1_BLAST":
      return addresses.ERC20_LIQUITIDY_POOL;
    case "GAMES_ETH_LP_V1_BLAST":
      return addresses.ETH_LIQUITIDY_POOL;
  }
};

export const liquidityChartLabelFormatter = (
  input: Highcharts.AxisLabelsFormatterContextObject,
  timeInterval: LiquidityPoolInterval
) => {
  switch (timeInterval) {
    case "MAX":
    case "_1M":
    case "_1W":
      return Highcharts.dateFormat("%b %e", Number(input.value));
    default:
      return Highcharts.dateFormat("%H:%M", Number(input.value));
  }
};
