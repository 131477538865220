import { useTranslation } from "next-i18next";
import { HStack } from "@chakra-ui/react";
import { Button } from "../Button";

interface StepErrorCtaProps {
  onSkip?: () => void;
  onRetry?: () => void;
  skipButtonDataId?: string;
  retryButtonDataId?: string;
}

export const StepErrorCta = ({ onSkip, onRetry, skipButtonDataId, retryButtonDataId }: StepErrorCtaProps) => {
  const { t } = useTranslation();

  return (
    <HStack wrap="wrap" w="100%" justifyContent="space-between">
      {!!onSkip && (
        <Button
          flex="1 0 auto"
          colorScheme="secondary"
          size="xs"
          onClick={onSkip}
          alignSelf="stretch"
          data-id={skipButtonDataId}
        >
          {t("Skip")}
        </Button>
      )}
      {!!onRetry && (
        <Button flex="1 0 auto" size="xs" onClick={onRetry} alignSelf="stretch" data-id={retryButtonDataId}>
          {t("Retry")}
        </Button>
      )}
    </HStack>
  );
};
