import { Icon, IconProps } from "../Icon";

const UserFilled = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 16C12.13 16 9 12.87 9 9C9 5.13 12.13 2 16 2C19.87 2 23 5.13 23 9C23 12.87 19.87 16 16 16ZM26 25V30H6V25C6 21.14 9.14 18 13 18H19C22.86 18 26 21.14 26 25Z"
      fill="currentColor"
    />
  </Icon>
);

export default UserFilled;
