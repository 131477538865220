import { Icon, IconProps } from "../Icon";

const Rocket = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6318 29.9301C16.7496 29.9766 16.8751 30.0004 17.0018 30.0001C17.2273 29.9988 17.4458 29.9212 17.6218 29.7801L22.6218 25.7801C22.7396 25.6869 22.835 25.5685 22.9008 25.4334C22.9666 25.2984 23.0011 25.1503 23.0018 25.0001V17.4101L26.7818 13.6401C27.8067 12.6209 28.619 11.4084 29.1718 10.0729C29.7245 8.73738 30.0066 7.30543 30.0018 5.86006V4.00006C30.0018 3.46963 29.7911 2.96092 29.416 2.58585C29.0409 2.21078 28.5322 2.00006 28.0018 2.00006H26.1418C24.6964 1.99519 23.2645 2.27731 21.9289 2.83005C20.5934 3.3828 19.3809 4.19517 18.3618 5.22006L14.5918 9.00006H7.00177C6.85075 9.00194 6.70211 9.03801 6.56702 9.10556C6.43193 9.1731 6.31389 9.27037 6.22177 9.39006L2.22177 14.3901C2.11016 14.5284 2.03743 14.6939 2.01106 14.8697C1.9847 15.0455 2.00565 15.2251 2.07177 15.3901C2.13708 15.5539 2.24456 15.6975 2.3833 15.8064C2.52204 15.9153 2.68712 15.9856 2.86177 16.0101L9.86177 17.0101L10.1418 15.0101L4.87177 14.2601L7.48177 11.0001H15.0018C15.1334 11.0008 15.2638 10.9756 15.3857 10.9258C15.5075 10.8761 15.6183 10.8027 15.7118 10.7101L19.7818 6.64006C20.6147 5.80142 21.6057 5.13629 22.6974 4.68314C23.7891 4.22999 24.9598 3.99782 26.1418 4.00006H28.0018V5.86006C28.004 7.04206 27.7719 8.21276 27.3187 9.30445C26.8655 10.3961 26.2004 11.3871 25.3618 12.2201L21.2918 16.2901C21.1991 16.3835 21.1258 16.4943 21.076 16.6162C21.0262 16.738 21.001 16.8685 21.0018 17.0001V24.5201L17.7618 27.1301L17.0118 21.8601L15.0118 22.1401L16.0118 29.1401C16.0363 29.3147 16.1065 29.4798 16.2154 29.6185C16.3243 29.7573 16.4679 29.8648 16.6318 29.9301ZM7.29004 23.2921L15.2874 15.2947L16.7016 16.709L8.70425 24.7063L7.29004 23.2921Z"
      fill="currentColor"
    />
  </Icon>
);

export default Rocket;
