import { Icon, IconProps } from "../Icon";

const TagListing = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4 29.12C16.9621 29.6828 17.7246 29.9993 18.52 30C19.3154 29.9993 20.0779 29.6828 20.64 29.12L29.12 20.64C29.6849 20.0771 30.0024 19.3125 30.0024 18.515C30.0024 17.7175 29.6849 16.9529 29.12 16.39L15.61 2.88C15.0479 2.31723 14.2854 2.0007 13.49 2H5C3.34315 2 2 3.34315 2 5V13.49C2.0007 14.2854 2.31723 15.0479 2.88 15.61L16.4 29.12ZM12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
      fill="currentColor"
    />
  </Icon>
);

export default TagListing;
