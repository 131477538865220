import { StyleProps } from "@chakra-ui/react";

interface PriceChangeColors {
  backgroundColor: StyleProps["backgroundColor"];
  color: StyleProps["color"];
}

export const getPriceChangeColors = (priceChange: number): PriceChangeColors => {
  if (priceChange > 0) {
    return {
      backgroundColor: "moondoom-moon-subtle",
      color: "moondoom-moon",
    };
  }

  if (priceChange < 0) {
    return {
      backgroundColor: "moondoom-doom-subtle",
      color: "moondoom-doom",
    };
  }

  return {
    backgroundColor: "ui-02",
    color: "text-03",
  };
};
