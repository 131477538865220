import { Icon, IconProps } from "../Icon";

const CheckmarkUnfilled = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 2.51587C8.26801 2.51587 2 8.78388 2 16.5159C2 24.2479 8.26801 30.5159 16 30.5159C23.732 30.5159 30 24.2479 30 16.5159C30 12.8028 28.525 9.24188 25.8995 6.61637C23.274 3.99086 19.713 2.51587 16 2.51587ZM16 28.5159C9.37258 28.5159 4 23.1433 4 16.5159C4 9.88845 9.37258 4.51587 16 4.51587C22.6274 4.51587 28 9.88845 28 16.5159C28 23.1433 22.6274 28.5159 16 28.5159Z"
      fill="currentColor"
    />
  </Icon>
);

export default CheckmarkUnfilled;
