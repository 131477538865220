import { Text } from "@looksrare/uikit";
import { Trans } from "next-i18next";

export const RoundEntryPointsExplanation = () => {
  return (
    <Trans i18nKey="mod::roundGemsExplanation">
      <Text textStyle="detail" color="text-inverse">
        You earn Points every time you enter a round of MOON or DOOM — the sooner you enter, the more Points you earn!
      </Text>
    </Trans>
  );
};
