import { gql } from "graphql-request";
import type { User } from "@/types";

export interface UserFragment extends User {
  hasPlayedGames: boolean;
  luckySpinTickets: number;
}

export const userFragment = gql`
  fragment UserFragment on User {
    address
    name
    biography
    email
    selfTimeoutUntil
    isEmailVerified
    twitterUsername
    discordUsername
    isProfileImageVisible
    isVerified
    avatar {
      image {
        contentType
        src
      }
    }
    luckySpinTickets
    hasPlayedGames
    luckySpinUnclaimedPrizesWei {
      ethWei
      yoloWei
    }
  }
`;

export const userPointsFragment = gql`
  fragment UserPointsFragment on User {
    unclaimedPoints
    lotteryTickets {
      remaining
      spent
      earned
    }
    seasonPoints {
      dailyRank
      points24h
      seasonPoints
      seasonRank
      multiplier
      details {
        games
        referrals
        quests
      }
    }
    epochPoints {
      dailyRank
      points24h
      seasonPoints
      seasonRank
      multiplier
      details {
        games
        referrals
        quests
      }
    }
  }
`;

export const currentSeasonFragment = gql`
  fragment CurrentSeasonFragment on SeasonDetails {
    season
    startTime
    endTime
    rewardPool
    rewardPoolBlastGold
  }
`;

export const rakebackFragment = gql`
  fragment RakebackFragment on Rakeback {
    status
    currency {
      address
      decimals
      name
      symbol
    }
    amountWei
    unlocksAt
    expiresAt
    claimedAt
  }
`;
