import { useQuery } from "@tanstack/react-query";
import type { RQueryOptions } from "@looksrare/utils";
import {
  PaginatedExtendedPtbRounds,
  OffsetPaginationInput,
  PtbRoundsFilter,
  PtbRoundsSort,
  getRounds,
} from "../graphql";
import type { PtbContractName } from "../types";

interface RoundsParams {
  filter: PtbRoundsFilter;
  pagination?: OffsetPaginationInput;
  sort?: PtbRoundsSort;
  contract?: PtbContractName;
}

export const usePtbHistory = (params: RoundsParams, options?: RQueryOptions<PaginatedExtendedPtbRounds>) => {
  return useQuery({
    queryKey: ["ptbHistory", params],
    queryFn: async () => {
      return getRounds(params);
    },
    ...options,
  });
};
