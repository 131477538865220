import { Icon, IconProps } from "../Icon";

const VolumeMuteFilled = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31 12.4099L29.59 10.9999L26 14.5899L22.41 10.9999L21 12.4099L24.59 15.9999L21 19.5899L22.41 20.9999L26 17.4099L29.59 20.9999L31 19.5899L27.41 15.9999L31 12.4099ZM18 29.9999C17.7328 29.9988 17.4771 29.8908 17.29 29.6999L9.67 21.9999H3C2.44772 21.9999 2 21.5522 2 20.9999V10.9999C2 10.4477 2.44772 9.99995 3 9.99995H9.67L17.29 2.29995C17.68 1.91222 18.31 1.91222 18.7 2.29995C18.8884 2.48458 18.9962 2.73618 19 2.99995V28.9999C19 29.5522 18.5523 29.9999 18 29.9999Z"
      fill="currentColor"
    />
  </Icon>
);

export default VolumeMuteFilled;
