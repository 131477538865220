import { Icon, IconProps } from "../Icon";

const Offer = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9997 17V18H13.9997V7.5C13.9997 6.94772 13.552 6.5 12.9997 6.5C12.4475 6.5 11.9997 6.94772 11.9997 7.5V21.9638L6.80648 18.1869C6.27698 17.8018 5.53034 17.962 5.2055 18.5305C4.94939 18.9787 5.03964 19.5447 5.42242 19.891L13.8521 27.5179C14.1951 27.8282 14.6411 28 15.1035 28H21.2272C23.863 28 25.9997 25.8633 25.9997 23.2274V12C25.9997 11.4477 25.552 11 24.9997 11C24.4494 11 24.0029 11.4445 23.9998 11.9941V18H23.9997H21.9998H21.9997V12L21.9998 11.9897V7C21.9998 6.44772 21.552 6 20.9998 6C20.4475 6 19.9998 6.44772 19.9998 7V16.5V18H17.9998V16.5V7V5C17.9998 4.44772 17.552 4 16.9998 4C16.4475 4 15.9998 4.44772 15.9998 5V17H15.9997ZM19.8947 4.21009C20.2367 4.07452 20.6095 4 20.9998 4C22.6566 4 23.9998 5.34315 23.9998 7V9.1707C24.3125 9.06015 24.6491 9 24.9997 9C26.6566 9 27.9997 10.3431 27.9997 12V23.2274C27.9997 26.9678 24.9676 30 21.2272 30H15.1035C14.1452 30 13.221 29.644 12.5103 29.001L4.08059 21.3741C3.00138 20.3977 2.74694 18.8018 3.46901 17.5382C4.38486 15.9355 6.48994 15.4837 7.98283 16.5694L9.99974 18.0362V7.5C9.99974 5.84315 11.3429 4.5 12.9997 4.5C13.3568 4.5 13.6993 4.56239 14.017 4.67685C14.1781 3.17197 15.4521 2 16.9998 2C18.3832 2 19.548 2.93647 19.8947 4.21009Z"
      fill="currentColor"
    />
  </Icon>
);

export default Offer;
