import { ChainId } from "@looksrare/config";
import { MoDContract } from "@looksrare/yolo-games-gql-typegen";

export const getMoDContractsByChainId = (chainId: ChainId): MoDContract[] | undefined => {
  switch (chainId) {
    case ChainId.BLAST_SEPOLIA:
    case ChainId.BLAST_MAINNET:
      return ["MOON_OR_DOOM_BTCUSD_V1_BLAST", "MOON_OR_DOOM_ETHUSD_V1_BLAST"];
  }
};
