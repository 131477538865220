import { Icon, IconProps } from "../Icon";

const TokenWLooks = (props: IconProps) => (
  <Icon viewBox="0 0 96 96" {...props}>
    <rect width="96" height="96" rx="48" fill="#FFF1F1" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48 53.5135C42.1661 53.5135 37.4324 48.7846 37.4324 42.9459C37.4324 37.1073 42.1661 32.3784 48 32.3784C53.8339 32.3784 58.5676 37.1073 58.5676 42.946C58.5676 48.7846 53.8339 53.5135 48 53.5135ZM43.4054 42.946C43.4054 45.4845 45.4635 47.5405 48 47.5405C50.5365 47.5405 52.5946 45.4845 52.5946 42.946C52.5946 40.4074 50.5365 38.3514 48 38.3514C45.4635 38.3514 43.4054 40.4074 43.4054 42.946Z"
      fill="#FA4D56"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 42.9599L34.2162 22.7297H61.7838L82 42.9599L48 76.946L14 42.9599ZM63.6216 35.5945C55.0319 26.9671 40.9681 26.9671 32.3784 35.5945L25.027 42.946L32.3784 50.2974C40.9681 58.9248 55.0319 58.9248 63.6216 50.2973L70.973 42.946L63.6216 35.5945Z"
      fill="#FA4D56"
    />
    <circle cx="67" cy="70" r="16" fill="#FFF1F1" />
    <circle cx="67" cy="70" r="12" fill="#FA4D56" />
    <path
      d="M62.36 77L59.0686 65H62L63.6115 71.6857C63.7372 72.2229 63.8343 72.6743 63.9029 73.04C63.9715 72.5486 64.0629 72.0914 64.1772 71.6686L65.8572 65H68.7372L70.4172 71.6686C70.5315 72.0914 70.6229 72.5486 70.6915 73.04C70.76 72.6743 70.8572 72.2229 70.9829 71.6857L72.5943 65H75.5257L72.2343 77H69.3029L67.2972 69.1657L65.2915 77H62.36Z"
      fill="#FFF1F1"
    />
  </Icon>
);

export default TokenWLooks;
