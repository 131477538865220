import { Icon, IconProps } from "../Icon";

const Logo = (props: IconProps) => (
  <Icon viewBox="0 0 148 148" {...props}>
    <path
      d="M33.379 43.7306C55.8133 21.4231 92.1867 21.4231 114.621 43.7306L134 63L114.621 82.2694C92.1867 104.577 55.8134 104.577 33.379 82.2694L14 63L33.379 43.7306Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 63.0304L44 19H104L148 63.0304L74 137L0 63.0304ZM108 46.9998C89.3047 28.2224 58.6953 28.2225 40 46.9999L24 63.0001L40 79.0002C58.6953 97.7776 89.3047 97.7775 108 79.0001L124 63.0001L108 46.9998Z"
      fill="#0CE466"
    />
    <path
      d="M74 77C66.2712 77 60 70.7351 60 63C60 55.2649 66.2712 49 74 49C81.7288 49 88 55.2649 88 63C88 70.7351 81.7288 77 74 77Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51 63C51 75.7077 61.3026 86 74 86C86.6974 86 97 75.7077 97 63C97 50.2923 86.6974 40 74 40C61.3026 40 51 50.2923 51 63ZM64 63C64 68.5251 68.4794 73 74 73C79.5206 73 84 68.5251 84 63C84 57.4749 79.5206 53 74 53C68.4794 53 64 57.4749 64 63Z"
      fill="white"
    />
  </Icon>
);

export default Logo;
