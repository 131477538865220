import type { ReactNode } from "react";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { theme } from "@/theme";
import { theme as rainbowKitTheme, walletConfig } from "@/config";

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const Providers = ({ children }: { children: ReactNode }) => (
  <QueryClientProvider client={client}>
    <WagmiProvider config={walletConfig}>
      <RainbowKitProvider theme={rainbowKitTheme}>
        <ColorModeScript initialColorMode="dark" />
        <ChakraProvider theme={theme}>{children}</ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
      </RainbowKitProvider>
    </WagmiProvider>
  </QueryClientProvider>
);
