import { Icon, IconProps } from "@chakra-ui/react";

export const BuiltOnBlast = (props: IconProps) => {
  return (
    <Icon
      viewBox="0 0 240 97"
      width="240"
      height="97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g clipPath="url(#clip0_2537_60953)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M240 45.5996H0V79.9996V87.9996C0 92.418 3.58172 95.9996 8 95.9996H219.628L240 79.9996V45.5996Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M239.2 46.3996H0.8V87.9996C0.8 91.9764 4.02355 95.1996 8 95.1996H219.351L239.2 79.6107V46.3996ZM240 79.9996L219.628 95.9996H8C3.58172 95.9996 0 92.418 0 87.9996V45.5996H240V79.9996Z"
          fill="#CCFD07"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M240 8.00056V18.775V19.4316V54.3996H0V19.4316V18.775H0.717563L14.0681 6.55832L59.2174 6.55774L69.3976 0L232 0.000556486C236.418 0.000571607 240 3.58229 240 8.00056Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M239.2 46.3996H0.8V87.9996C0.8 91.9764 4.02355 95.1996 8 95.1996H219.351L239.2 79.6107V46.3996ZM240 79.9996L219.628 95.9996H8C3.58172 95.9996 0 92.418 0 87.9996V45.5996H240V79.9996Z"
          fill="#CCFD07"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M240 8.00056V18.775V19.4316V54.3996H0V19.4316V18.775H0.717563L14.0681 6.55832L59.2174 6.55774L69.3976 0L232 0.000556486C236.418 0.000571607 240 3.58229 240 8.00056Z"
          fill="#CCFD07"
        />
        <path
          d="M47.3461 16.8008H24.9461C23.1788 16.8008 21.7461 18.2335 21.7461 20.0008V42.4008C21.7461 44.1681 23.1788 45.6008 24.9461 45.6008H47.3461C49.1134 45.6008 50.5461 44.1681 50.5461 42.4008V20.0008C50.5461 18.2335 49.1134 16.8008 47.3461 16.8008Z"
          fill="black"
        />
        <path
          d="M41.6036 31.0881L44.5486 29.6207L45.5638 26.5049L43.5335 25.0273H30.0146L26.8887 27.3491H42.7796L41.9353 29.9625H35.5628L34.9497 31.8721H41.3222L39.5331 37.3701L42.5183 35.8926L43.5837 32.5958L41.5836 31.1284L41.6036 31.0881Z"
          fill="#CCFD07"
        />
        <path
          d="M31.3819 35.0089L33.2212 29.2798L31.1808 27.752L28.1152 37.371H39.5334L40.2973 35.0089H31.3819Z"
          fill="#CCFD07"
        />
        <path
          d="M212.048 35.9378V28.0466H208.477C208.261 28.0466 208.16 27.9314 208.16 27.7442V26.4626C208.16 26.2754 208.261 26.1602 208.477 26.1602H217.938C218.154 26.1602 218.255 26.2754 218.255 26.4626V27.7442C218.255 27.9314 218.154 28.0466 217.938 28.0466H214.381V35.9378C214.381 36.125 214.251 36.2402 214.05 36.2402H212.365C212.163 36.2402 212.048 36.125 212.048 35.9378Z"
          fill="black"
        />
        <path
          d="M195.823 33.245H197.45C197.651 33.245 197.781 33.3458 197.781 33.5474V33.6914C197.781 34.1378 197.968 34.3538 198.458 34.3538H202.605C203.368 34.3538 203.555 34.1666 203.555 33.5618V32.8706C203.555 32.2658 203.368 32.0786 202.605 32.0786H197.983C196.355 32.0786 195.635 31.373 195.635 29.8322V28.4066C195.635 26.8658 196.355 26.1602 197.983 26.1602H203.339C205.067 26.1602 205.687 26.7506 205.687 28.4066V28.853C205.687 29.0546 205.571 29.1554 205.37 29.1554H203.743C203.527 29.1554 203.426 29.0546 203.426 28.853V28.709C203.426 28.2626 203.224 28.0466 202.72 28.0466H198.919C198.155 28.0466 197.968 28.2338 197.968 28.8386V29.429C197.968 30.0482 198.155 30.2354 198.919 30.2354H203.527C205.168 30.2354 205.888 30.9266 205.888 32.4818V33.9938C205.888 35.5346 205.168 36.2402 203.527 36.2402H197.853C196.125 36.2402 195.506 35.6498 195.506 33.9938V33.5474C195.506 33.3458 195.621 33.245 195.823 33.245Z"
          fill="black"
        />
        <path
          d="M190.968 35.9522L190.263 34.3106H184.82L184.128 35.9522C184.042 36.1538 183.927 36.2402 183.682 36.2402H181.896C181.709 36.2402 181.637 36.0962 181.709 35.9234L185.928 26.4482C186 26.2466 186.13 26.1602 186.375 26.1602H188.823C189.068 26.1602 189.183 26.2466 189.269 26.4482L193.488 35.9234C193.56 36.0962 193.488 36.2402 193.316 36.2402H191.415C191.156 36.2402 191.055 36.1538 190.968 35.9522ZM187.541 27.9026L185.597 32.4674H189.485L187.541 27.9026Z"
          fill="black"
        />
        <path
          d="M173.048 26.4626V34.3538H179.614C179.816 34.3538 179.931 34.469 179.931 34.6562V35.9378C179.931 36.125 179.816 36.2402 179.614 36.2402H171.032C170.816 36.2402 170.715 36.125 170.715 35.9378V26.4626C170.715 26.2754 170.816 26.1602 171.032 26.1602H172.716C172.918 26.1602 173.048 26.2754 173.048 26.4626Z"
          fill="black"
        />
        <path
          d="M167.53 28.9826C167.53 30.2354 167.083 30.9122 166.075 31.1426C167.198 31.3442 167.674 32.021 167.674 33.3314V33.9938C167.674 35.5346 166.954 36.2402 165.326 36.2402H157.68C157.464 36.2402 157.363 36.125 157.363 35.9378V26.4626C157.363 26.2754 157.464 26.1602 157.68 26.1602H165.168C166.795 26.1602 167.53 26.8658 167.53 28.4066V28.9826ZM164.29 32.0786H159.696V34.3538H164.29C165.125 34.3538 165.341 34.1522 165.341 33.4322V33.0002C165.341 32.2802 165.125 32.0786 164.29 32.0786ZM159.696 28.0466V30.2354H164.146C164.966 30.2354 165.197 30.0194 165.197 29.3138V28.9682C165.197 28.2482 164.966 28.0466 164.146 28.0466H159.696Z"
          fill="black"
        />
        <path
          d="M147.875 36.2402H144.952C144.721 36.2402 144.606 36.1826 144.505 35.9954L139.681 28.0322V35.9378C139.681 36.125 139.566 36.2402 139.365 36.2402H137.838C137.622 36.2402 137.521 36.125 137.521 35.9378V26.4626C137.521 26.2754 137.622 26.1602 137.838 26.1602H140.747C140.977 26.1602 141.093 26.2322 141.193 26.405L146.017 34.3826V26.4626C146.017 26.2754 146.147 26.1602 146.349 26.1602H147.875C148.077 26.1602 148.192 26.2754 148.192 26.4626V35.9378C148.192 36.125 148.077 36.2402 147.875 36.2402Z"
          fill="black"
        />
        <path
          d="M134.164 28.4642V33.9362C134.164 35.5202 133.415 36.2402 131.759 36.2402H125.97C124.328 36.2402 123.551 35.5202 123.551 33.9362V28.4642C123.551 26.8802 124.328 26.1602 125.97 26.1602H131.759C133.415 26.1602 134.164 26.8802 134.164 28.4642ZM131.831 33.2738V29.1266C131.831 28.2338 131.615 28.0466 130.549 28.0466H127.165C126.1 28.0466 125.884 28.2338 125.884 29.1266V33.2738C125.884 34.1666 126.1 34.3538 127.165 34.3538H130.549C131.615 34.3538 131.831 34.1666 131.831 33.2738Z"
          fill="black"
        />
        <path
          d="M109.115 35.9378V28.0466H105.543C105.327 28.0466 105.227 27.9314 105.227 27.7442V26.4626C105.227 26.2754 105.327 26.1602 105.543 26.1602H115.004C115.22 26.1602 115.321 26.2754 115.321 26.4626V27.7442C115.321 27.9314 115.22 28.0466 115.004 28.0466H111.447V35.9378C111.447 36.125 111.318 36.2402 111.116 36.2402H109.431C109.23 36.2402 109.115 36.125 109.115 35.9378Z"
          fill="black"
        />
        <path
          d="M98.4891 26.4626V34.3538H105.055C105.257 34.3538 105.372 34.469 105.372 34.6562V35.9378C105.372 36.125 105.257 36.2402 105.055 36.2402H96.4731C96.2571 36.2402 96.1562 36.125 96.1562 35.9378V26.4626C96.1562 26.2754 96.2571 26.1602 96.4731 26.1602H98.1578C98.3594 26.1602 98.4891 26.2754 98.4891 26.4626Z"
          fill="black"
        />
        <path
          d="M92.6336 26.4626V35.9378C92.6336 36.125 92.504 36.2402 92.3024 36.2402H90.6176C90.4016 36.2402 90.3008 36.125 90.3008 35.9378V26.4626C90.3008 26.2754 90.4016 26.1602 90.6176 26.1602H92.3024C92.504 26.1602 92.6336 26.2754 92.6336 26.4626Z"
          fill="black"
        />
        <path
          d="M78.9578 26.4626V33.2738C78.9578 34.1666 79.1594 34.3538 80.2392 34.3538H83.2344C84.3144 34.3538 84.5304 34.1666 84.5304 33.2738V26.4626C84.5304 26.2754 84.6456 26.1602 84.8472 26.1602H86.532C86.7336 26.1602 86.8632 26.2754 86.8632 26.4626V33.9362C86.8632 35.5202 86.1144 36.2402 84.4584 36.2402H79.0443C77.3882 36.2402 76.625 35.5202 76.625 33.9362V26.4626C76.625 26.2754 76.7258 26.1602 76.9418 26.1602H78.6266C78.8426 26.1602 78.9578 26.2754 78.9578 26.4626Z"
          fill="black"
        />
        <path
          d="M73.5121 28.9826C73.5121 30.2354 73.0657 30.9122 72.0577 31.1426C73.1809 31.3442 73.6561 32.021 73.6561 33.3314V33.9938C73.6561 35.5346 72.9361 36.2402 71.3089 36.2402H63.6625C63.4465 36.2402 63.3457 36.125 63.3457 35.9378V26.4626C63.3457 26.2754 63.4465 26.1602 63.6625 26.1602H71.1505C72.7777 26.1602 73.5121 26.8658 73.5121 28.4066V28.9826ZM70.2721 32.0786H65.6785V34.3538H70.2721C71.1073 34.3538 71.3233 34.1522 71.3233 33.4322V33.0002C71.3233 32.2802 71.1073 32.0786 70.2721 32.0786ZM65.6785 28.0466V30.2354H70.1281C70.9489 30.2354 71.1793 30.0194 71.1793 29.3138V28.9682C71.1793 28.2482 70.9489 28.0466 70.1281 28.0466H65.6785Z"
          fill="black"
        />
        <path
          d="M216.97 77.322V76.698C216.729 77.226 216.167 77.5044 215.295 77.5044H213.769C212.577 77.5044 212.061 77.082 212.061 76.1316V73.8276C212.061 72.8772 212.577 72.4548 213.769 72.4548H215.226C216.018 72.4548 216.557 72.6852 216.821 73.1268V70.5828C216.821 70.458 216.901 70.4004 217.039 70.4004H217.922C218.06 70.4004 218.14 70.458 218.14 70.5828V77.322C218.14 77.4468 218.06 77.5044 217.922 77.5044H217.199C217.05 77.5044 216.97 77.4468 216.97 77.322ZM214.343 73.3668C213.517 73.3668 213.369 73.482 213.369 74.0484V75.9108C213.369 76.4772 213.517 76.5924 214.343 76.5924H215.57C216.477 76.5924 216.821 76.3044 216.821 75.6516V74.3076C216.821 73.6548 216.477 73.3668 215.57 73.3668H214.343Z"
          fill="#CCFD07"
        />
        <path
          d="M209.051 77.5047H206.275C205.094 77.5047 204.566 77.0823 204.566 76.1319V73.8279C204.566 72.8775 205.094 72.4551 206.275 72.4551H209.051C210.141 72.4551 210.646 72.8487 210.646 73.7415V75.2199C210.646 75.3447 210.565 75.4023 210.427 75.4023H205.874V75.9207C205.874 76.4871 206.023 76.6023 206.849 76.6023H208.81C209.189 76.6023 209.372 76.5063 209.372 76.2183V76.2087C209.372 76.0935 209.441 76.0263 209.602 76.0263H210.427C210.565 76.0263 210.646 76.0935 210.646 76.2087V76.2279C210.646 77.1303 210.175 77.5047 209.051 77.5047ZM205.874 74.0391V74.5191H209.361V73.7895C209.361 73.4631 209.154 73.3575 208.764 73.3575H206.849C206.023 73.3575 205.874 73.4823 205.874 74.0391Z"
          fill="#CCFD07"
        />
        <path
          d="M201.815 77.322V76.698C201.574 77.226 201.013 77.5044 200.141 77.5044H198.615C197.422 77.5044 196.906 77.082 196.906 76.1316V73.8276C196.906 72.8772 197.422 72.4548 198.615 72.4548H200.072C200.863 72.4548 201.402 72.6852 201.666 73.1268V70.5828C201.666 70.458 201.746 70.4004 201.884 70.4004H202.767C202.905 70.4004 202.985 70.458 202.985 70.5828V77.322C202.985 77.4468 202.905 77.5044 202.767 77.5044H202.045C201.896 77.5044 201.815 77.4468 201.815 77.322ZM199.189 73.3668C198.363 73.3668 198.213 73.482 198.213 74.0484V75.9108C198.213 76.4772 198.363 76.5924 199.189 76.5924H200.416C201.322 76.5924 201.666 76.3044 201.666 75.6516V74.3076C201.666 73.6548 201.322 73.3668 200.416 73.3668H199.189Z"
          fill="#CCFD07"
        />
        <path
          d="M190.701 72.6375V75.9111C190.701 76.4775 190.839 76.5927 191.653 76.5927H192.754C193.672 76.5927 194.005 76.3047 194.005 75.6519V72.6375C194.005 72.5127 194.085 72.4551 194.234 72.4551H195.106C195.244 72.4551 195.324 72.5127 195.324 72.6375V77.3223C195.324 77.4471 195.244 77.5047 195.106 77.5047H194.383C194.234 77.5047 194.165 77.4471 194.165 77.3223V76.6599C193.948 77.2167 193.374 77.5047 192.479 77.5047H191.092C189.91 77.5047 189.371 77.0823 189.371 76.1319V72.6375C189.371 72.5127 189.463 72.4551 189.601 72.4551H190.472C190.621 72.4551 190.701 72.5127 190.701 72.6375Z"
          fill="#CCFD07"
        />
        <path
          d="M187.756 76.5924H188.032C188.169 76.5924 188.249 76.65 188.249 76.7748V77.322C188.249 77.4468 188.169 77.5044 188.032 77.5044H187.366C186.288 77.5044 185.852 77.1588 185.852 76.2948V70.5828C185.852 70.458 185.944 70.4004 186.081 70.4004H186.953C187.102 70.4004 187.171 70.458 187.171 70.5828V76.1604C187.171 76.4868 187.297 76.5924 187.756 76.5924Z"
          fill="#CCFD07"
        />
        <path
          d="M183.241 76.1991V75.9591C183.241 75.8343 183.31 75.7767 183.471 75.7767H184.296C184.434 75.7767 184.515 75.8343 184.515 75.9591V76.2279C184.515 77.1303 184.044 77.5047 182.92 77.5047H180.144C178.963 77.5047 178.436 77.0823 178.436 76.1319V73.8279C178.436 72.8775 178.963 72.4551 180.144 72.4551H182.92C184.044 72.4551 184.515 72.8295 184.515 73.7319V74.0007C184.515 74.1255 184.434 74.1831 184.296 74.1831H183.471C183.31 74.1831 183.241 74.1255 183.241 74.0007V73.7607C183.241 73.4727 183.047 73.3671 182.656 73.3671H180.718C179.892 73.3671 179.743 73.4823 179.743 74.0487V75.9111C179.743 76.4775 179.892 76.5927 180.718 76.5927H182.656C183.047 76.5927 183.241 76.4871 183.241 76.1991Z"
          fill="#CCFD07"
        />
        <path
          d="M175.613 77.3223V74.0487C175.613 73.4823 175.464 73.3671 174.649 73.3671H173.56C172.642 73.3671 172.309 73.6551 172.309 74.3079V77.3223C172.309 77.4471 172.241 77.5047 172.08 77.5047H171.22C171.059 77.5047 170.99 77.4471 170.99 77.3223V72.6375C170.99 72.5127 171.059 72.4551 171.22 72.4551H171.931C172.08 72.4551 172.149 72.5127 172.149 72.6375V73.3191C172.367 72.7527 172.917 72.4551 173.835 72.4551H175.223C176.405 72.4551 176.932 72.8775 176.932 73.8279V77.3223C176.932 77.4471 176.852 77.5047 176.714 77.5047H175.842C175.693 77.5047 175.613 77.4471 175.613 77.3223Z"
          fill="#CCFD07"
        />
        <path
          d="M169.199 70.9868V77.3036C169.199 77.438 169.107 77.5052 168.947 77.5052H168.006C167.846 77.5052 167.766 77.438 167.766 77.3036V70.9868C167.766 70.8524 167.846 70.7852 168.006 70.7852H168.947C169.107 70.7852 169.199 70.8524 169.199 70.9868Z"
          fill="#CCFD07"
        />
        <path
          d="M158.246 73.7703V74.1159C158.246 74.4135 158.429 74.5191 158.82 74.5191H161.343C162.478 74.5191 162.937 74.8167 162.937 75.7959V76.2279C162.937 77.1783 162.478 77.5047 161.343 77.5047H158.407C157.282 77.5047 156.812 77.1303 156.812 76.2375V76.1703C156.812 76.0551 156.904 75.9975 157.041 75.9975H157.868C158.017 75.9975 158.096 76.0551 158.096 76.1703V76.2183C158.096 76.5063 158.28 76.6023 158.659 76.6023H161.044C161.435 76.6023 161.618 76.4967 161.618 76.1895V75.8247C161.618 75.5271 161.435 75.4023 161.044 75.4023H158.51C157.385 75.4023 156.927 75.1047 156.927 74.1255V73.7319C156.927 72.7911 157.385 72.4551 158.51 72.4551H161.205C162.306 72.4551 162.776 72.8295 162.776 73.7223V73.7895C162.776 73.9047 162.708 73.9623 162.559 73.9623H161.721C161.584 73.9623 161.504 73.9047 161.504 73.7895V73.7415C161.504 73.4631 161.32 73.3575 160.941 73.3575H158.82C158.429 73.3575 158.246 73.4631 158.246 73.7703Z"
          fill="#CCFD07"
        />
        <path
          d="M153.898 72.4551C155.079 72.4551 155.607 72.8775 155.607 73.8279V76.1319C155.607 77.0823 155.079 77.5047 153.898 77.5047H152.441C151.65 77.5047 151.111 77.2839 150.847 76.8519V79.2807C150.847 79.4055 150.778 79.4631 150.617 79.4631H149.757C149.596 79.4631 149.527 79.4055 149.527 79.2807V72.6375C149.527 72.5127 149.596 72.4551 149.757 72.4551H150.468C150.617 72.4551 150.686 72.5127 150.686 72.6375V73.3191C150.904 72.7527 151.455 72.4551 152.372 72.4551H153.898ZM153.335 76.5927C154.139 76.5927 154.287 76.4775 154.287 75.9111V74.0487C154.287 73.4823 154.139 73.3671 153.335 73.3671H152.097C151.179 73.3671 150.847 73.6551 150.847 74.3079V75.6519C150.847 76.3047 151.179 76.5927 152.097 76.5927H153.335Z"
          fill="#CCFD07"
        />
        <path
          d="M147.958 73.8279V76.1319C147.958 77.0823 147.43 77.5047 146.249 77.5047H143.462C142.28 77.5047 141.752 77.0823 141.752 76.1319V73.8279C141.752 72.8775 142.28 72.4551 143.462 72.4551H146.249C147.43 72.4551 147.958 72.8775 147.958 73.8279ZM146.638 75.9111V74.0487C146.638 73.4823 146.49 73.3671 145.675 73.3671H144.035C143.209 73.3671 143.06 73.4823 143.06 74.0487V75.9111C143.06 76.4775 143.209 76.5927 144.035 76.5927H145.675C146.49 76.5927 146.638 76.4775 146.638 75.9111Z"
          fill="#CCFD07"
        />
        <path
          d="M140.627 73.3671H140.099C138.964 73.3671 138.516 73.7895 138.516 74.6055V77.3223C138.516 77.4471 138.448 77.5047 138.287 77.5047H137.427C137.266 77.5047 137.197 77.4471 137.197 77.3223V72.6375C137.197 72.5127 137.266 72.4551 137.427 72.4551H138.115C138.264 72.4551 138.344 72.5127 138.344 72.6375V73.3575C138.551 72.7623 139.124 72.4551 140.076 72.4551H140.627C140.776 72.4551 140.856 72.5127 140.856 72.6375V73.1847C140.856 73.3095 140.776 73.3671 140.627 73.3671Z"
          fill="#CCFD07"
        />
        <path
          d="M134.284 77.322V76.698C134.043 77.226 133.481 77.5044 132.609 77.5044H131.084C129.891 77.5044 129.375 77.082 129.375 76.1316V73.8276C129.375 72.8772 129.891 72.4548 131.084 72.4548H132.541C133.332 72.4548 133.871 72.6852 134.135 73.1268V70.5828C134.135 70.458 134.215 70.4004 134.353 70.4004H135.236C135.373 70.4004 135.454 70.458 135.454 70.5828V77.322C135.454 77.4468 135.373 77.5044 135.236 77.5044H134.513C134.365 77.5044 134.284 77.4468 134.284 77.322ZM131.657 73.3668C130.832 73.3668 130.682 73.482 130.682 74.0484V75.9108C130.682 76.4772 130.832 76.5924 131.657 76.5924H132.885C133.791 76.5924 134.135 76.3044 134.135 75.6516V74.3076C134.135 73.6548 133.791 73.3668 132.885 73.3668H131.657Z"
          fill="#CCFD07"
        />
        <path
          d="M128.248 73.3671H127.721C126.585 73.3671 126.138 73.7895 126.138 74.6055V77.3223C126.138 77.4471 126.069 77.5047 125.908 77.5047H125.048C124.887 77.5047 124.818 77.4471 124.818 77.3223V72.6375C124.818 72.5127 124.887 72.4551 125.048 72.4551H125.736C125.886 72.4551 125.966 72.5127 125.966 72.6375V73.3575C126.172 72.7623 126.746 72.4551 127.698 72.4551H128.248C128.398 72.4551 128.478 72.5127 128.478 72.6375V73.1847C128.478 73.3095 128.398 73.3671 128.248 73.3671Z"
          fill="#CCFD07"
        />
        <path
          d="M121.762 71.3882V70.6394C121.762 70.5146 121.831 70.457 121.991 70.457H122.851C123.012 70.457 123.081 70.5146 123.081 70.6394V71.3882C123.081 71.5034 123.012 71.5706 122.851 71.5706H121.991C121.831 71.5706 121.762 71.5034 121.762 71.3882ZM121.762 77.321V72.6362C121.762 72.5114 121.831 72.4538 121.991 72.4538H122.851C123.012 72.4538 123.081 72.5114 123.081 72.6362V77.321C123.081 77.4458 123.012 77.5034 122.851 77.5034H121.991C121.831 77.5034 121.762 77.4458 121.762 77.321Z"
          fill="#CCFD07"
        />
        <path
          d="M119.211 77.3228L118.545 76.094H113.785L113.12 77.3228C113.052 77.4476 112.96 77.5052 112.776 77.5052H111.767C111.629 77.5052 111.572 77.4188 111.64 77.3036L115.173 70.9676C115.242 70.8428 115.334 70.7852 115.517 70.7852H116.871C117.054 70.7852 117.146 70.8428 117.215 70.9676L120.748 77.3036C120.816 77.4188 120.759 77.5052 120.621 77.5052H119.555C119.36 77.5052 119.28 77.4476 119.211 77.3228ZM116.16 71.7164L114.324 75.0956H117.995L116.16 71.7164Z"
          fill="#CCFD07"
        />
        <path
          d="M104.34 72.6092V72.254C104.34 71.8988 104.134 71.726 103.675 71.726H101.656C100.899 71.726 100.704 71.8412 100.704 72.3596V72.4652C100.704 72.9356 100.899 73.0892 101.599 73.4444L105.189 75.2108V74.0972C105.189 73.9724 105.269 73.9052 105.418 73.9052H106.256C106.416 73.9052 106.485 73.9724 106.485 74.0972V75.854L107.528 76.3628C107.678 76.43 107.747 76.4972 107.747 76.6412V77.4188C107.747 77.5148 107.609 77.5532 107.494 77.4956L106.324 76.9292C106.095 77.342 105.613 77.5052 104.764 77.5052H100.337C99.0524 77.5052 98.502 77.0636 98.502 76.0364V75.1724C98.502 74.2028 98.9948 73.8092 100.142 73.7804C99.6028 73.4828 99.374 73.1468 99.374 72.4076V72.254C99.374 71.2268 99.9124 70.7852 101.209 70.7852H103.87C105.166 70.7852 105.636 71.1596 105.636 72.206V72.6092C105.636 72.734 105.568 72.8012 105.407 72.8012H104.569C104.409 72.8012 104.34 72.734 104.34 72.6092ZM104.524 76.574C104.833 76.574 105.028 76.4972 105.12 76.334L101.324 74.462H100.864C100.062 74.462 99.8668 74.6156 99.8668 75.182V75.854C99.8668 76.4204 100.062 76.574 100.864 76.574H104.524Z"
          fill="#CCFD07"
        />
        <path
          d="M92.5826 77.322V76.698C92.3418 77.226 91.7802 77.5044 90.9082 77.5044H89.3826C88.1898 77.5044 87.6738 77.082 87.6738 76.1316V73.8276C87.6738 72.8772 88.1898 72.4548 89.3826 72.4548H90.8394C91.6306 72.4548 92.1698 72.6852 92.4338 73.1268V70.5828C92.4338 70.458 92.5138 70.4004 92.6514 70.4004H93.5346C93.6722 70.4004 93.753 70.458 93.753 70.5828V77.322C93.753 77.4468 93.6722 77.5044 93.5346 77.5044H92.8122C92.6634 77.5044 92.5826 77.4468 92.5826 77.322ZM89.9562 73.3668C89.1306 73.3668 88.981 73.482 88.981 74.0484V75.9108C88.981 76.4772 89.1306 76.5924 89.9562 76.5924H91.1834C92.0898 76.5924 92.4338 76.3044 92.4338 75.6516V74.3076C92.4338 73.6548 92.0898 73.3668 91.1834 73.3668H89.9562Z"
          fill="#CCFD07"
        />
        <path
          d="M86.1286 76.5924H86.4038C86.5414 76.5924 86.6222 76.65 86.6222 76.7748V77.322C86.6222 77.4468 86.5414 77.5044 86.4038 77.5044H85.739C84.6606 77.5044 84.2246 77.1588 84.2246 76.2948V70.5828C84.2246 70.458 84.3166 70.4004 84.4542 70.4004H85.3262C85.475 70.4004 85.5438 70.458 85.5438 70.5828V76.1604C85.5438 76.4868 85.6702 76.5924 86.1286 76.5924Z"
          fill="#CCFD07"
        />
        <path
          d="M81.1588 77.5047H78.3829C77.2014 77.5047 76.6738 77.0823 76.6738 76.1319V73.8279C76.6738 72.8775 77.2014 72.4551 78.3829 72.4551H81.1588C82.2484 72.4551 82.7532 72.8487 82.7532 73.7415V75.2199C82.7532 75.3447 82.6724 75.4023 82.5348 75.4023H77.9814V75.9207C77.9814 76.4871 78.1305 76.6023 78.9563 76.6023H80.918C81.2964 76.6023 81.4796 76.5063 81.4796 76.2183V76.2087C81.4796 76.0935 81.5484 76.0263 81.7092 76.0263H82.5348C82.6724 76.0263 82.7532 76.0935 82.7532 76.2087V76.2279C82.7532 77.1303 82.2828 77.5047 81.1588 77.5047ZM77.9814 74.0391V74.5191H81.4684V73.7895C81.4684 73.4631 81.262 73.3575 80.8716 73.3575H78.9563C78.1305 73.3575 77.9814 73.4823 77.9814 74.0391Z"
          fill="#CCFD07"
        />
        <path
          d="M73.7773 71.3882V70.6394C73.7773 70.5146 73.8461 70.457 74.0067 70.457H74.867C75.0276 70.457 75.0964 70.5146 75.0964 70.6394V71.3882C75.0964 71.5034 75.0276 71.5706 74.867 71.5706H74.0067C73.8461 71.5706 73.7773 71.5034 73.7773 71.3882ZM73.7773 77.321V72.6362C73.7773 72.5114 73.8461 72.4538 74.0067 72.4538H74.867C75.0276 72.4538 75.0964 72.5114 75.0964 72.6362V77.321C75.0964 77.4458 75.0276 77.5034 74.867 77.5034H74.0067C73.8461 77.5034 73.7773 77.4458 73.7773 77.321Z"
          fill="#CCFD07"
        />
        <path
          d="M67.8063 77.3036V74.9708L64.2392 70.9868C64.1474 70.8908 64.1932 70.7852 64.3309 70.7852H65.5123C65.7073 70.7852 65.7991 70.814 65.8908 70.9196L68.5518 74.0204L71.2128 70.9196C71.3046 70.8044 71.4078 70.7852 71.6028 70.7852H72.7268C72.853 70.7852 72.8989 70.8908 72.8186 70.9868L69.2515 74.9612V77.3036C69.2515 77.438 69.1597 77.5052 68.9992 77.5052H68.0472C67.898 77.5052 67.8063 77.438 67.8063 77.3036Z"
          fill="#CCFD07"
        />
        <path
          d="M58.4984 77.5047H55.7226C54.5413 77.5047 54.0137 77.0823 54.0137 76.1319V73.8279C54.0137 72.8775 54.5413 72.4551 55.7226 72.4551H58.4984C59.588 72.4551 60.0927 72.8487 60.0927 73.7415V75.2199C60.0927 75.3447 60.0124 75.4023 59.8748 75.4023H55.3212V75.9207C55.3212 76.4871 55.4703 76.6023 56.2961 76.6023H58.2575C58.636 76.6023 58.8195 76.5063 58.8195 76.2183V76.2087C58.8195 76.0935 58.8884 76.0263 59.049 76.0263H59.8748C60.0124 76.0263 60.0927 76.0935 60.0927 76.2087V76.2279C60.0927 77.1303 59.6224 77.5047 58.4984 77.5047ZM55.3212 74.0391V74.5191H58.8081V73.7895C58.8081 73.4631 58.6016 73.3575 58.2117 73.3575H56.2961C55.4703 73.3575 55.3212 73.4823 55.3212 74.0391Z"
          fill="#CCFD07"
        />
        <path
          d="M48.8264 77.3415L46.4063 72.6375C46.3604 72.5319 46.4063 72.4551 46.521 72.4551H47.5303C47.7138 72.4551 47.7941 72.5031 47.84 72.6183L49.7784 76.6215L51.7283 72.6183C51.7856 72.5031 51.8659 72.4551 52.0379 72.4551H53.0015C53.1162 72.4551 53.162 72.5319 53.1161 72.6375L50.6845 77.3415C50.6272 77.4567 50.5469 77.5047 50.3748 77.5047H49.1361C48.964 77.5047 48.8952 77.4567 48.8264 77.3415Z"
          fill="#CCFD07"
        />
        <path
          d="M44.0371 71.3882V70.6394C44.0371 70.5146 44.1059 70.457 44.2665 70.457H45.1267C45.2873 70.457 45.3561 70.5146 45.3561 70.6394V71.3882C45.3561 71.5034 45.2873 71.5706 45.1267 71.5706H44.2665C44.1059 71.5706 44.0371 71.5034 44.0371 71.3882ZM44.0371 77.321V72.6362C44.0371 72.5114 44.1059 72.4538 44.2665 72.4538H45.1267C45.2873 72.4538 45.3561 72.5114 45.3561 72.6362V77.321C45.3561 77.4458 45.2873 77.5034 45.1267 77.5034H44.2665C44.1059 77.5034 44.0371 77.4458 44.0371 77.321Z"
          fill="#CCFD07"
        />
        <path
          d="M42.5087 73.3664H40.9947V75.9968C40.9947 76.496 41.1209 76.592 41.6485 76.592H42.5087C42.6579 76.592 42.7267 76.6496 42.7267 76.7744V77.3216C42.7267 77.4464 42.6579 77.504 42.5087 77.504H41.2815C40.1459 77.504 39.6756 77.1296 39.6756 76.2176V73.3664H38.9875C38.8498 73.3664 38.7695 73.3088 38.7695 73.184V72.6368C38.7695 72.512 38.8498 72.4544 38.9875 72.4544H39.6756V71.3504C39.6756 71.2256 39.7559 71.168 39.8936 71.168H40.7653C40.9144 71.168 40.9947 71.2256 40.9947 71.3504V72.4544H42.5087C42.6579 72.4544 42.7267 72.512 42.7267 72.6368V73.184C42.7267 73.3088 42.6579 73.3664 42.5087 73.3664Z"
          fill="#CCFD07"
        />
        <path
          d="M33.1568 77.5047C32.0213 77.5047 31.5625 77.1783 31.5625 76.2279V75.7959C31.5625 74.8167 32.0213 74.5191 33.1568 74.5191H36.2651V74.0391C36.2651 73.4823 36.116 73.3575 35.3017 73.3575H33.6042C33.2371 73.3575 33.0421 73.4535 33.0421 73.7415V73.7895C33.0421 73.9047 32.9733 73.9623 32.8242 73.9623H31.9983C31.8492 73.9623 31.769 73.9047 31.769 73.7895V73.7319C31.769 72.8295 32.2392 72.4551 33.3633 72.4551H35.8752C37.0451 72.4551 37.5842 72.8775 37.5842 73.8279V76.3719C37.5842 76.5255 37.6645 76.5927 37.8595 76.5927H37.9283C38.0774 76.5927 38.1463 76.6503 38.1463 76.7751V77.3223C38.1463 77.4471 38.0774 77.5047 37.9283 77.5047H37.3778C36.6781 77.5047 36.4028 77.2071 36.4028 76.7367V76.6695C36.1734 77.2167 35.6114 77.5047 34.7167 77.5047H33.1568ZM35.015 76.6023C35.9211 76.6023 36.2651 76.3047 36.2651 75.6615V75.4023H33.4665C33.088 75.4023 32.893 75.4983 32.893 75.8055V76.1895C32.893 76.4967 33.0651 76.6023 33.4665 76.6023H35.015Z"
          fill="#CCFD07"
        />
        <path
          d="M29.8558 77.5052H28.1698C27.9863 77.5052 27.906 77.4668 27.8142 77.3516L23.2148 71.774V77.3036C23.2148 77.438 23.123 77.5052 22.9624 77.5052H22.1022C21.9416 77.5052 21.8613 77.438 21.8613 77.3036V70.9868C21.8613 70.8524 21.9416 70.7852 22.1022 70.7852H23.7883C23.9603 70.7852 24.0406 70.8236 24.1324 70.9388L28.7432 76.526V70.9868C28.7432 70.8524 28.835 70.7852 28.9956 70.7852H29.8558C30.0164 70.7852 30.0967 70.8524 30.0967 70.9868V77.3036C30.0967 77.438 30.0164 77.5052 29.8558 77.5052Z"
          fill="#CCFD07"
        />
      </g>
      <defs>
        <clipPath id="clip0_2537_60953">
          <rect width="240" height="96" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
};
