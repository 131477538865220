import { ReactNode } from "react";
import { forwardRef, ModalHeaderProps, useBreakpointValue } from "@chakra-ui/react";
import { Modal as ModalUIKit, ModalProps as ModalPropsUIKit } from "../Modal";
import { ModalHeaderLegacy, ModalHeroElementHeader } from "./ModalHeaderLegacy";

export interface ModalLegacyProps extends ModalPropsUIKit {
  title?: string;
  label?: string;
  showCloseButton?: boolean;
  showBackButton?: boolean;
  onBack?: () => void;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  heroElement?: ReactNode;
  hideHeader?: boolean;
  modalHeaderProps?: ModalHeaderProps;
}

export const ModalLegacy = forwardRef<ModalLegacyProps, "div">(
  (
    {
      title,
      label,
      showCloseButton = true,
      showBackButton,
      onBack,
      leftElement,
      rightElement,
      heroElement,
      hideHeader,
      modalHeaderProps,
      dataId,
      onClose,
      children,
      ...props
    },
    ref
  ) => {
    const breakpointSize = useBreakpointValue({
      base: "sm",
      sm: "md",
    });
    const size = props.size || breakpointSize;
    return (
      <ModalUIKit onClose={onClose} {...props} ref={ref}>
        {!hideHeader && (
          <>
            {!!heroElement ? (
              <ModalHeroElementHeader
                size={size}
                title={title}
                label={label}
                showCloseButton={showCloseButton}
                showBackButton={showBackButton}
                onBack={onBack}
                onClose={onClose}
                heroElement={heroElement}
                dataId={dataId}
                {...modalHeaderProps}
              />
            ) : (
              <ModalHeaderLegacy
                size={size}
                title={title}
                label={label}
                showCloseButton={showCloseButton}
                showBackButton={showBackButton}
                onBack={onBack}
                onClose={onClose}
                leftElement={leftElement}
                rightElement={rightElement}
                dataId={dataId}
                {...modalHeaderProps}
              />
            )}
          </>
        )}
        {children}
      </ModalUIKit>
    );
  }
);
