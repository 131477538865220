import { useTranslation } from "next-i18next";
import { Box, Image } from "@chakra-ui/react";
import { Container, Text } from "@looksrare/uikit";
import { useAccordionIndex } from "../../hooks";
import { usePtbConfig } from "../..";
import { CavesAccordion } from "../CavesAccordion";
import { MobileClaimPanel } from "./MobileClaimPanel";

export const MobileLobby = () => {
  const { t } = useTranslation();
  const { accordionIndex, handleChange } = useAccordionIndex({ initialIndex: [0, 1], switchOnNetworkChange: false });
  const { mobileClaimPanelComponent, showMobileHeader, mobileLobbyProps } = usePtbConfig();
  return (
    <Box {...mobileLobbyProps}>
      {showMobileHeader && (
        <Box>
          <Box
            pt={32}
            backgroundImage="url('/images/poke-the-bear/sleeping.gif')"
            backgroundRepeat="no-repeat"
            backgroundPosition="center center"
            position="relative"
            mb={4}
          >
            <Image
              src="/images/poke-the-bear/ptb-panel-bg.svg"
              alt="ptb-panel-bg"
              position="absolute"
              top={0}
              left={4}
            />
          </Box>
          <Container>
            <Text as="h1" color="text-02">
              {t("ptb::One will die, the others survive.")}
            </Text>
            <Text color="text-03" mb={4}>
              {t("ptb::Pull up a seat, grab a stick, and take your turn to Poke the Bear.")}
            </Text>
          </Container>
        </Box>
      )}
      <Container>
        <CavesAccordion accordionProps={{ allowMultiple: true, index: accordionIndex, onChange: handleChange }} />
      </Container>
      {!!mobileClaimPanelComponent ? mobileClaimPanelComponent() : <MobileClaimPanel />}
    </Box>
  );
};
