import { useEffect, useState } from "react";
import { zeroAddress } from "viem";
import { useAccount } from "wagmi";
import { Box, Grid, HStack, Skeleton } from "@chakra-ui/react";
import { isAddressEqual } from "@looksrare/utils";
import times from "lodash/times";
import { getPlayerMeta } from "../../utils";
import { useActiveCaveRoundInfo, useCurrentGameStatus, useGameState } from "../../hooks";
import { MemoizedPlayerCard } from "./PlayerCard";
import { EmptyPlayerCard } from "./PlayerCard/shared";

export const PlayerList = () => {
  const { address } = useAccount();
  const [activeIndex, setActiveIndex] = useState(0);
  const { data, isLoading, isSuccess } = useActiveCaveRoundInfo();
  const gameStatus = useCurrentGameStatus();
  const setIsEnterCaveModalOpen = useGameState((state) => state.setIsEnterCaveModalOpen);

  const handleOpenEnterCaveModal = () => {
    setIsEnterCaveModalOpen(true);
  };

  const players = isSuccess ? data.players : [];
  const playerCount = players.length;
  const playersPerRound = isSuccess ? data.cave.playersPerRound : 0;
  const currency = isSuccess ? data.cave.currency : zeroAddress;
  const emptySlots = playersPerRound - players.length;
  const isConnectedInGame = players.some((player) => isAddressEqual(player.user.address, address));

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!isLoading && gameStatus === "shuffling") {
      const nextIndex = (dur: number) => {
        timer = setTimeout(() => {
          setActiveIndex((prevIndex) => {
            if (prevIndex === playerCount - 1) {
              return 0;
            }
            return prevIndex + 1;
          });
          nextIndex(dur * 1.01);
        }, dur);
      };
      nextIndex(300);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [gameStatus, isLoading, setActiveIndex, playerCount]);

  if (isLoading) {
    return (
      <Box>
        {times(3).map((n) => (
          <HStack height={15} spacing={3} key={n} mb={1} _last={{ mb: 0 }}>
            <Skeleton
              width={6}
              speed={0}
              startColor="text-03"
              height="100%"
              borderTopLeftRadius="button"
              borderBottomLeftRadius="button"
            />
            <Skeleton height={8} width={8} borderRadius="circular" speed={0} startColor="text-03" />
            <Box>
              <Skeleton height={3} width={20} mb={2} speed={0} startColor="text-03" />
              <Skeleton height={2} width={6} speed={0} startColor="text-03" />
            </Box>
          </HStack>
        ))}
      </Box>
    );
  }

  return (
    <Grid
      gridGap={1}
      gridTemplateColumns={{ base: "minmax(0, 1fr)", lg: "repeat(2, minmax(0, 1fr))", "2xl": "minmax(0, 1fr)" }}
    >
      {players.map((player, index) => {
        const { hasWon, hasLost, isPokingUntil } = getPlayerMeta(player.user.address, players);
        return (
          <MemoizedPlayerCard
            key={player.user.address}
            num={index + 1}
            playerAddress={player.user.address}
            currency={currency}
            prizePerPlayer={data?.meta.prizePerPlayerNet}
            roundStatus={data?.round?.status}
            enterAmount={data?.cave.enterAmount}
            isCycling={gameStatus === "shuffling" && activeIndex === index}
            hasWon={hasWon}
            hasLost={hasLost}
            isPokingUntil={isPokingUntil}
            gemsEarned={player.gemsEarned}
          />
        );
      })}
      {times(emptySlots).map((emptySlot, index) => (
        <EmptyPlayerCard
          key={emptySlot}
          isConnectedInGame={isConnectedInGame}
          onOpenEnterCaveModal={handleOpenEnterCaveModal}
          num={players.length + index + 1}
        />
      ))}
    </Grid>
  );
};
