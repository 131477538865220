import { useEffect, useRef } from "react";
import Highcharts, { Chart as HighchartsChart } from "highcharts";
import { useTranslation } from "next-i18next";
import { defaultHighchartsOptions } from "@looksrare/uikit";

const chartOptions: Highcharts.Options = {
  chart: {
    styledMode: false,
    reflow: true,
    height: "100%",
    margin: [0, 0, 0, 0],
  },
  plotOptions: {
    pie: {
      borderWidth: 0,
      borderColor: "#000000",
      dataLabels: {
        enabled: false,
      },
    },
  },
  series: [
    {
      id: "payoutRatio",
      type: "pie",
      innerSize: "44px",
      size: "64px",
      enableMouseTracking: false,
      startAngle: 90,
    },
  ],
};

export const useRoundStatsChart = () => {
  const { t } = useTranslation();
  const chartElementRef = useRef<HTMLDivElement>(null);
  const highchartsChart = useRef<HighchartsChart | null>(null);

  useEffect(() => {
    if (chartElementRef.current) {
      Highcharts.setOptions({
        lang: {
          thousandsSep: ",",
        },
      });

      Highcharts.chart(
        chartElementRef.current,
        Highcharts.merge(defaultHighchartsOptions, chartOptions),
        (chart: HighchartsChart) => {
          highchartsChart.current = chart;
        }
      );
    }
  }, [chartElementRef, t]);

  return { chartElementRef, highchartsChart };
};
