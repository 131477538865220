import { useAccount, usePublicClient } from "wagmi";
import { Address } from "viem";
import { useApprove, useGetAllowance, useHandleModalStep } from "@looksrare/utils";
import { AutoTransactionStepRow, TransactionSetter } from "../AutoTransactionStepRow";

interface Props {
  onComplete: () => void;
  isStepActive: boolean;
  ctaText: string;
  amount: bigint;
  spender: Address;
  currency: Address;
}

export const ApproveErc20SpenderStep = ({ onComplete, isStepActive, ctaText, amount, currency, spender }: Props) => {
  const { address } = useAccount();
  const publicClient = usePublicClient();

  const useHandleTransaction = (setTransaction: TransactionSetter) => {
    const getCurrencyAllowance = useGetAllowance(currency);
    const approveCurrency = useApprove(currency);

    return useHandleModalStep({
      onSubmit: async () => {
        if (!address) {
          throw new Error("No connected wallet");
        }
        if (!publicClient) {
          throw new Error("No public client found");
        }
        const allowance = await getCurrencyAllowance(address, spender);
        if (allowance < amount) {
          const hash = await approveCurrency(spender);
          setTransaction(hash);

          const receipt = await publicClient.waitForTransactionReceipt({ hash });
          if (receipt.status === "success") {
            setTransaction(undefined);
          } else {
            throw new Error(
              `Approving transfer manager as a ${currency} spender for user failed. Transaction hash: ${receipt.transactionHash}`
            );
          }
        }
        onComplete();
      },
    });
  };

  return (
    <AutoTransactionStepRow isStepActive={isStepActive} useHandleTransaction={useHandleTransaction} ctaText={ctaText} />
  );
};
