import { Global } from "@emotion/react";

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Yapari';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/woff2/Yapari-Regular.woff2') format('woff2'), url('/fonts/woff/Yapari-Regular.woff') format('woff');
      }
      @font-face {
        font-family: 'Yapari';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/fonts/woff2/Yapari-Bold.woff2') format('woff2'), url('/fonts/woff/Yapari-Bold.woff') format('woff');
      }
      @font-face {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/woff2/SpaceGrotesk-Regular.woff2') format('woff2'), url('/fonts/woff/SpaceGrotesk-Regular.woff') format('woff');
      }
      @font-face {
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/fonts/woff2/SpaceGrotesk-Bold.woff2') format('woff2'), url('/fonts/woff/SpaceGrotesk-Bold.woff') format('woff');
      }
  `}
  />
);
