import { useTranslation } from "next-i18next";
import { IconButton } from "@chakra-ui/react";
import { Button, type ButtonProps, NextLink, UndoIcon, Popover, TooltipText } from "@looksrare/uikit";
import { useCurrentRoundOnChainId, useGetCaveQueryParams } from "../../hooks";
import { getRoundUrl } from "../../utils";

/**
 * Navigate to the current round of a cave. This round is either in progress or joinable.
 */
export const CurrentButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  const { caveOnChainId, network, roundOnChainId } = useGetCaveQueryParams();
  const paramRoundOnChainId = Number(roundOnChainId);
  const baseCaveUrl = getRoundUrl({ caveOnChainId, network });

  const { data: currentRoundOnChainIdBigInt } = useCurrentRoundOnChainId({ caveOnChainId, network });
  const currentRoundOnChainId = currentRoundOnChainIdBigInt ? Number(currentRoundOnChainIdBigInt) : null;
  const currentRoundUrl = currentRoundOnChainId ? `${baseCaveUrl}/${currentRoundOnChainId}` : baseCaveUrl;

  const sharedButtonProps: Partial<ButtonProps> = {
    size: "xs",
    colorScheme: "secondary",
    isDisabled: !currentRoundOnChainId || currentRoundOnChainId === paramRoundOnChainId,
    ...(currentRoundOnChainId !== paramRoundOnChainId && {
      as: NextLink,
      href: currentRoundUrl,
    }),
  };

  return (
    <>
      {/* Hide on xl+ */}
      <Popover label={<TooltipText>{t("ptb::Current Round")}</TooltipText>}>
        <IconButton
          display={{ base: "flex", xl: "none" }}
          aria-label="poke-the-bear-current-round"
          {...sharedButtonProps}
          {...props}
        >
          <UndoIcon />
        </IconButton>
      </Popover>
      {/* Hide on mobile */}
      <Button display={{ base: "none", xl: "flex" }} {...sharedButtonProps} {...props}>
        {t("ptb::Current")}
      </Button>
    </>
  );
};
