import { useRouter } from "next/router";
import { PtbSupportedNetwork } from "../types";

type PtbQuery = {
  network: PtbSupportedNetwork;
  caveOnChainId: string;
  roundOnChainId: string;
};

export const useGetCaveQueryParams = (): PtbQuery => {
  const router = useRouter();
  const { network, caveOnChainId, roundOnChainId } = router.query as PtbQuery;
  return { network, caveOnChainId, roundOnChainId };
};
