import Icon, { IconProps } from "@chakra-ui/icon";

const FavoriteFilled = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4508 4C20.442 3.9983 18.5172 4.80571 17.1108 6.24L16.0008 7.36L14.8908 6.24C13.4827 4.80838 11.5588 4.0021 9.55075 4.0021C7.54273 4.0021 5.6188 4.80838 4.21075 6.24C1.26048 9.24333 1.26048 14.0567 4.21075 17.06L16.0008 29L27.7908 17.06C30.741 14.0567 30.741 9.24333 27.7908 6.24C26.383 4.80761 24.4591 4.00055 22.4508 4Z"
      fill="currentColor"
    />
  </Icon>
);

export default FavoriteFilled;
