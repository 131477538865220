import { gql } from "graphql-request";
import { Address } from "viem";
import { graphql } from "@looksrare/utils";
import { graphql as generatedGql } from "@looksrare/yolo-games-gql-typegen";
import { currentSeasonFragment } from "@/queries/fragments";
import { getAuthHeader } from "@/utils/graphql";

import {
  CurrentPointsSeason,
  PointsCurrentSeasonLeaderboard,
  OffsetPagination,
  SuccessPayload,
  MilestoneLevelCode,
  DailyQuestCode,
  UserMilestones,
  UserDailyQuests,
} from "@/types/points";

export const getUserSeasonPoints = async (address: Address) => {
  const query = generatedGql(/* GraphQL */ `
    query GetUserSeasonPoints($address: Address!) {
      user(address: $address) {
        ...UserPointsFragment
      }
    }
  `);

  const res = await graphql({ query, params: { address } });
  return res.user;
};

interface GetCurrentPointsSeasonResponse {
  pointsSeason: CurrentPointsSeason;
}

export const getCurrentPointsSeason = async (requestHeaders?: HeadersInit): Promise<CurrentPointsSeason> => {
  const query = gql`
    query GetCurrentPointsSeason {
      pointsSeason {
        ...CurrentSeasonFragment
      }
    }
    ${currentSeasonFragment}
  `;

  const res = await graphql<GetCurrentPointsSeasonResponse>({ query, requestHeaders });
  return res.pointsSeason;
};

export const getPointsSeasonDetails = async (season: number, requestHeaders?: HeadersInit) => {
  const query = generatedGql(/* GraphQL */ `
    query GetPointsSeasonDetails($season: Int!) {
      seasonDetails(season: $season) {
        season
        startTime
        endTime
        rewardPool
      }
    }
  `);

  const res = await graphql({ query, params: { season }, requestHeaders });
  return res.seasonDetails;
};

interface GetCurrentSeasonLeaderboardResponse {
  pointsLeaderboard: PointsCurrentSeasonLeaderboard;
}

export enum LeaderboardSortInput {
  SEASON_RANK_ASC = "SEASON_RANK_ASC",
  DAILY_RANK_ASC = "DAILY_RANK_ASC",
}

export const getCurrentSeasonLeaderboard = async ({
  pagination,
  sort,
}: {
  pagination: OffsetPagination;
  sort: LeaderboardSortInput;
}): Promise<PointsCurrentSeasonLeaderboard> => {
  const query = gql`
    query GetCurrentSeasonLeaderboard($pagination: OffsetPaginationInput, $sort: LeaderboardSortInput) {
      pointsLeaderboard(pagination: $pagination, sort: $sort) {
        total
        points {
          dailyRank
          multiplier
          points24h
          seasonRank
          seasonPoints
          user {
            address
            name
            isVerified
            avatar {
              image {
                src
                contentType
              }
            }
          }
        }
      }
    }
  `;

  const params = {
    pagination,
    sort,
  };

  const res = await graphql<GetCurrentSeasonLeaderboardResponse>({ query, params });
  return res.pointsLeaderboard;
};

interface GetCurrenEpochLeaderboardResponse {
  epochLeaderboard: PointsCurrentSeasonLeaderboard;
}

export const getCurrentEpochLeaderboard = async ({
  pagination,
}: {
  pagination: OffsetPagination;
}): Promise<PointsCurrentSeasonLeaderboard> => {
  const query = gql`
    query GetCurrentEpochLeaderboard($pagination: OffsetPaginationInput) {
      epochLeaderboard(pagination: $pagination) {
        total
        points {
          dailyRank
          multiplier
          points24h
          seasonRank
          seasonPoints
          user {
            address
            name
            isVerified
            avatar {
              image {
                src
                contentType
              }
            }
          }
        }
      }
    }
  `;

  const params = {
    pagination,
  };

  const res = await graphql<GetCurrenEpochLeaderboardResponse>({ query, params });
  return res.epochLeaderboard;
};

export const getHistoricSeasonLeaderboard = async ({
  season,
  pagination,
}: {
  season: number;
  pagination: OffsetPagination;
}) => {
  // sorted by rank
  const query = generatedGql(/* GraphQL */ `
    query GetSeasonAllocations($season: Int!, $pagination: OffsetPaginationInput) {
      seasonAllocations(season: $season, pagination: $pagination) {
        points
        rank
        blastGoldAllocation
        yTokenWeiAllocation
        user {
          address
          name
          isVerified
          isProfileImageVisible
          avatar {
            image {
              src
              contentType
            }
          }
        }
      }
    }
  `);

  const params = {
    season,
    pagination,
  };

  const res = await graphql({ query, params });
  return res.seasonAllocations;
};

/**
 * Get season reward data for a specific user
 * @param address User account address
 * @param season Season number
 * @returns UserReward
 */
export const getUserSeasonRewards = async (address: Address, season: number) => {
  const query = generatedGql(/* GraphQL */ `
    query GetUserSeasonRewards($address: Address!, $season: Int!) {
      user(address: $address) {
        seasonRewards(season: $season) {
          rank
          points
          blastGoldAllocation
          yTokenWeiAllocation
          yTokenVestingClaimDetails {
            proof
            vestingAllocationWei
            remainingVolumeToClaimWei
            currentClaimableAmountWei
            volumeWei
            claimedWei
          }
          vaultSharesClaimDetails {
            yTokensDepositedWei
            proof
            vaultSharesClaimableVum
            claimedAt
          }
          yTokenWeiFromShards
          stakingShards
        }
      }
    }
  `);

  const seasonRewardsForAccount = await graphql({
    query,
    params: { address, season },
  });
  return seasonRewardsForAccount?.user?.seasonRewards;
};

interface GetUserMilestonesResponse {
  user: { milestones: UserMilestones };
}
export const getUserMilestones = async (address: Address): Promise<UserMilestones> => {
  const query = gql`
    fragment MilestoneFragment on UserMilestoneLevel {
      code
      milestone
      points
      progress
      goal
      claimedAt
      createdAt
    }

    query GetUserMilestones($address: Address!) {
      user(address: $address) {
        milestones {
          DONT_FALL_IN_ETH {
            ...MilestoneFragment
          }
          DONT_FALL_IN_ROUND {
            ...MilestoneFragment
          }
          DONT_FALL_IN_WIN {
            ...MilestoneFragment
          }
          DONT_FALL_IN_WIN_STREAK {
            ...MilestoneFragment
          }
          FLIPPER_ETH {
            ...MilestoneFragment
          }
          FLIPPER_ROUND {
            ...MilestoneFragment
          }
          FLIPPER_WIN {
            ...MilestoneFragment
          }
          FLIPPER_WIN_STREAK {
            ...MilestoneFragment
          }
          LASER_BLAST_ETH {
            ...MilestoneFragment
          }
          LASER_BLAST_ROUND {
            ...MilestoneFragment
          }
          QUANTUM_ETH {
            ...MilestoneFragment
          }
          QUANTUM_WIN_STREAK {
            ...MilestoneFragment
          }
          QUANTUM_WIN {
            ...MilestoneFragment
          }
          QUANTUM_ROUND {
            ...MilestoneFragment
          }

          PROFILE_SETUP {
            ...MilestoneFragment
          }
          POINTS {
            ...MilestoneFragment
          }

          MORD_ROUND {
            ...MilestoneFragment
          }
          MORD_ETH {
            ...MilestoneFragment
          }
          MORD_WIN_STREAK {
            ...MilestoneFragment
          }
          MORD_WIN {
            ...MilestoneFragment
          }
          PTB_FUTURE_ROUND {
            ...MilestoneFragment
          }
          PTB_ETH {
            ...MilestoneFragment
          }
          PTB_WIN_STREAK {
            ...MilestoneFragment
          }
          PTB_WIN {
            ...MilestoneFragment
          }
          YOLO_FUTURE_ROUND {
            ...MilestoneFragment
          }
          YOLO_ROUND {
            ...MilestoneFragment
          }
          YOLO_ETH {
            ...MilestoneFragment
          }
          YOLO_WIN {
            ...MilestoneFragment
          }
        }
      }
    }
  `;

  const res = await graphql<GetUserMilestonesResponse>({ query, params: { address } });
  return res.user.milestones;
};

/**
 * @note - address is nullable @todo s3 improve comment
 */
export const getUserDailyQuests = async (address?: string): Promise<UserDailyQuests> => {
  const query = gql`
    query GetUserDailyQuests($address: Address) {
      dailyQuests(address: $address) {
        nextDay
        quests {
          claimedAt
          createdAt
          code
          goal
          points
          progress
        }
      }
    }
  `;

  const res = await graphql<{ dailyQuests: UserDailyQuests }>({ query, params: { address } });
  return res.dailyQuests;
};

interface ClaimMilestoneLevelParams {
  code: MilestoneLevelCode;
  connectedAddress: Address;
}
export const claimMilestoneLevel = async ({ code, connectedAddress }: ClaimMilestoneLevelParams): Promise<boolean> => {
  const query = gql`
    mutation ClaimMilestone($code: MilestoneLevelCode!) {
      claimMilestoneLevel(code: $code) {
        success
      }
    }
  `;

  const authHeaders = getAuthHeader(connectedAddress);
  const params = { code };
  const res = await graphql<{ claimMilestoneLevel: SuccessPayload }>({ query, params, requestHeaders: authHeaders });
  return res.claimMilestoneLevel.success;
};

interface ClaimDailyQuestParams {
  code: DailyQuestCode;
  connectedAddress: Address;
}
export const claimDailyQuest = async ({ code, connectedAddress }: ClaimDailyQuestParams): Promise<boolean> => {
  const query = gql`
    mutation ClaimDailyQuest($code: DailyQuestCode!) {
      claimDailyQuest(code: $code) {
        success
      }
    }
  `;

  const authHeaders = getAuthHeader(connectedAddress);
  const params = { code };
  const res = await graphql<{ claimDailyQuest: SuccessPayload }>({ query, params, requestHeaders: authHeaders });
  return res.claimDailyQuest.success;
};
