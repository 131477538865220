import { Flex, ModalBody } from "@chakra-ui/react";
import { CloudinaryImage } from "@looksrare/uikit";

export const CaveInfoLoader = () => (
  <ModalBody bg="ui-bg" p={6}>
    <Flex alignItems="center" justifyContent="center" height="539px" position="relative">
      <CloudinaryImage
        src="/images/poke-the-bear/ptbloader.gif"
        alt="ptb-loader"
        contentType="image/gif"
        layout="fill"
        objectFit="contain"
      />
    </Flex>
  </ModalBody>
);
