import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { gradients } from "@looksrare/chakra-theme";
import { useUnclaimedPtbFunds } from "../../../hooks";
import { ClaimFundsSuccessHandler } from "../../Modals/types";
import { ClaimFundsDisplayModal } from "../ClaimFundsDisplayModal";
import { TriggerButton } from "./shared";

export const MobileClaimPanel = () => {
  const disclosure = useDisclosure();
  const unclaimedEthFundsQuery = useUnclaimedPtbFunds("ethereum");
  const unclaimedArbFundsQuery = useUnclaimedPtbFunds("arbitrum");

  const hasOnChainUnclaimedFunds = unclaimedEthFundsQuery.hasUnclaimedFunds || unclaimedArbFundsQuery.hasUnclaimedFunds;

  // We should always expect an on-chain delay before the funds as registed as claimed.
  // Use the local state to optimistically update the UI to reflect claim success.
  const [isClaimTxSuccessful, updateClaimTxSuccessful] = useState(false);
  const hasUnclaimedFunds = hasOnChainUnclaimedFunds && !isClaimTxSuccessful;

  const handleSuccess: ClaimFundsSuccessHandler = () => {
    disclosure.onClose();
    updateClaimTxSuccessful(true);
  };

  if (!hasUnclaimedFunds) {
    return null;
  }

  return (
    <>
      <TriggerButton
        isOpen={disclosure.isOpen}
        onClick={disclosure.onToggle}
        position="fixed"
        bottom={0}
        left={0}
        bgGradient={gradients["ui-gradient-v-01"]}
      />
      <ClaimFundsDisplayModal isOpen={disclosure.isOpen} onClose={disclosure.onClose} onSuccess={handleSuccess} />
    </>
  );
};
