import { HStack, StackProps, StyleProps } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { ArrowUpRightIcon, EthHalfIcon, Text } from "@looksrare/uikit";
import { BigIntish, formatToSignificant } from "@looksrare/utils";
import { EntryPosition } from "../../types";

export interface YourEntryProps extends StackProps {
  entryAmount: BigIntish;
  entryType: EntryPosition;
  size?: "xxs" | "md" | "lg";
}

export const YourEntry = ({ entryAmount, entryType, size = "xxs", ...props }: YourEntryProps) => {
  const { t } = useTranslation();

  const { color, backgroundColor } = (() => {
    if (entryType === "moon") {
      return {
        backgroundColor: "moondoom-moon-subtle",
        color: "moondoom-moon",
      };
    }

    return {
      backgroundColor: "moondoom-doom-subtle",
      color: "moondoom-doom",
    };
  })();

  const { textStyle, boxSize, ...sizes } = ((): StyleProps => {
    switch (size) {
      case "xxs":
        return {
          px: "1.5",
          py: "0.5",
          minWidth: "auto",
          height: "auto",
          borderRadius: "circular",
          textStyle: "helper",
          boxSize: 3,
        };
      case "md":
        return {
          px: 2,
          py: "1.5",
          minWidth: "auto",
          height: "auto",
          borderRadius: "button",
          textStyle: "body",
          boxSize: 5,
        };
      case "lg":
        return {
          px: 2,
          py: "1.5",
          minWidth: "368px",
          height: "48px",
          borderRadius: "button",
          textStyle: "body",
          boxSize: 5,
        };
    }
  })();

  return (
    <HStack
      px={2}
      py="1.5"
      spacing={2}
      justifyContent="center"
      backgroundColor={backgroundColor}
      borderRadius="button"
      width="fit-content"
      transition="background-color 200ms"
      {...sizes}
      {...props}
    >
      <Text textStyle={textStyle} color={color} transition="color 200ms" bold>
        {t("Your Entry")}:
      </Text>

      <HStack spacing={1}>
        <Text textStyle={textStyle} color="text-01" bold>
          {formatToSignificant(entryAmount, 3)}
        </Text>

        <EthHalfIcon height={4} width={2} />
      </HStack>

      <ArrowUpRightIcon
        boxSize={boxSize}
        color={color}
        transform={entryType === "moon" ? "rotate(0deg)" : "rotate(90deg)"}
        transition="transform 200ms, color 200ms"
        aria-label={entryType === "moon" ? t("mod::MOON") : t("mod::DOOM")}
      />
    </HStack>
  );
};
