import { Flex, FlexProps } from "@chakra-ui/react";
import { Address, getAddress } from "viem";
import { formatAddressUsername, formatDisplayName, useUserEns } from "@looksrare/utils";
import { Text, TextProps } from "../Text";
import { IconProps, VerifiedIcon } from "../Icons";

interface UserNameDisplayProps extends FlexProps {
  address: Address;
  name?: string | null;
  disableEnsDomain?: boolean;
  maxEnsLength?: number;
  isVerified?: boolean;
  textStyle?: TextProps["textStyle"];
  bold?: TextProps["bold"];
  color?: TextProps["color"];
  textProps?: TextProps;
  verifiedIconProps?: IconProps;
  maxNameLength?: number;
  addressStartLength?: number;
  addressEndLength?: number;
}

export const UsernameDisplay = ({
  address,
  name,
  disableEnsDomain = false,
  maxEnsLength = 20,
  isVerified = false,
  textStyle = "body",
  bold = false,
  color = "text-01",
  verifiedIconProps = {},
  textProps = {},
  maxNameLength = 20,
  ...props
}: UserNameDisplayProps) => {
  const { data: userEns } = useUserEns(address, { enabled: !disableEnsDomain || !name });

  const displayName = (() => {
    if (disableEnsDomain) {
      return formatAddressUsername(getAddress(address));
    }

    return formatDisplayName({
      address,
      userName: !!name && name.length > maxNameLength ? name.slice(0, maxNameLength - 3) + "..." : name,
      ensName: !!userEns && userEns.length > maxEnsLength ? userEns.slice(0, maxEnsLength - 3) + "..." : userEns,
    });
  })();

  return (
    <Flex alignItems="center" {...props}>
      <Text title={address} textStyle={textStyle} bold={bold} color={color} {...textProps}>
        {displayName}
      </Text>
      {isVerified && <VerifiedIcon boxSize={4} ml={1} {...verifiedIconProps} />}
    </Flex>
  );
};
