import { useQuery } from "@tanstack/react-query";
import type { BigIntish, RQueryOptions } from "@looksrare/utils";
import { getPtbContractNameFromNetwork } from "../utils";
import { PtbSupportedNetwork } from "../types";
import { getActiveRoundOnChainId, getCurrentRoundOnChainId } from "../graphql";
import { useGetCaveQueryParams } from "./useGetCaveQueryParams";

/**
 * The Active round is always joinable.
 */
export const useActiveRoundOnChainId = (options?: RQueryOptions<BigIntish | null>) => {
  const { caveOnChainId, network } = useGetCaveQueryParams();
  const contractName = getPtbContractNameFromNetwork(network);

  return useQuery({
    queryKey: ["ptb", "activeRoundOnChainId", contractName, caveOnChainId],
    queryFn: async () => {
      const activeRound = await getActiveRoundOnChainId({
        caveOnChainId,
        contract: contractName,
      });
      return activeRound;
    },
    refetchInterval: 15 * 1_000,
    enabled: !!caveOnChainId && options?.enabled !== false,
    ...options,
  });
};

interface CurrentRoundArgs {
  caveOnChainId: BigIntish;
  network: PtbSupportedNetwork;
}

/**
 * Current round is the latest round in progress, or joinable.
 */
export const useCurrentRoundOnChainId = (
  { caveOnChainId, network }: CurrentRoundArgs,
  options?: RQueryOptions<BigIntish | null>
) => {
  const contractName = getPtbContractNameFromNetwork(network);

  return useQuery({
    queryKey: ["ptb", "currentRoundOnChainId", contractName, caveOnChainId],
    queryFn: async () => {
      const currentRoundOnChainId = await getCurrentRoundOnChainId({
        caveOnChainId,
        contract: contractName,
      });
      return currentRoundOnChainId;
    },
    refetchInterval: 15 * 1_000,
    enabled: !!caveOnChainId && options?.enabled !== false,
    ...options,
  });
};
