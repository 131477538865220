import { Icon, IconProps } from "../Icon";

const ArrowUp = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4L6 14L7.41 15.41L15 7.83V30H17V7.83L24.59 15.41L26 14L16 4Z"
      fill="currentColor"
    />
  </Icon>
);

export default ArrowUp;
