import { useTranslation } from "next-i18next";
import { Box, HStack, StackProps } from "@chakra-ui/react";
import { ArrowDownRightIcon, ArrowUpRightIcon, Text } from "@looksrare/uikit";
import { formatNumberToLocale } from "@looksrare/utils";
import { MoDPeriodResultFragment } from "@looksrare/yolo-games-gql-typegen";

interface RecentRoundsStatsProps extends StackProps {
  period: "Hour" | "Day";
  periodResult: MoDPeriodResultFragment;
}

export const RecentRoundsStats = ({ period, periodResult, ...props }: RecentRoundsStatsProps) => {
  const { t } = useTranslation();
  const { numOfMoons, numOfDooms } = periodResult;

  const totalResults = numOfMoons + numOfDooms;
  const moonPercentage = (numOfMoons / totalResults) * 100;

  return (
    <HStack p={2} justifyContent="space-between" backgroundColor="ui-glass" borderRadius="button" {...props}>
      <Text textStyle="helper" color="text-03" bold>
        1 {t(period)}
      </Text>

      <HStack>
        <HStack spacing={0.5}>
          <ArrowUpRightIcon boxSize={3} color="moondoom-moon" />
          <Text textStyle="helper" color="moondoom-moon" bold>
            {formatNumberToLocale(numOfMoons, 0)}
          </Text>
          H
        </HStack>

        <Box
          position="relative"
          width="128px"
          height="12px"
          borderRadius="mini"
          overflow="hidden"
          backgroundColor="moondoom-doom"
          _before={{
            content: '""',
            position: "absolute",
            inset: "0 auto 0 0",
            width: `${moonPercentage}%`,
            backgroundColor: "moondoom-moon",
          }}
        />

        <HStack spacing={0.5} minWidth="36px">
          <ArrowDownRightIcon boxSize={3} color="moondoom-doom" />

          <Text textStyle="helper" color="moondoom-doom" bold>
            {formatNumberToLocale(numOfDooms, 0)}
          </Text>
        </HStack>
      </HStack>
    </HStack>
  );
};
