import confetti, { Options } from "canvas-confetti";
import { useColorModeValue, useTheme } from "@chakra-ui/react";
import { RefObject, useCallback, useEffect, useMemo } from "react";
import { confettiColors } from "@looksrare/chakra-theme";

interface UseConfettiOptions extends Options {
  isEnabled?: boolean;
}

export const defaultConfettiOptions: UseConfettiOptions = {
  particleCount: 400,
  startVelocity: 45,
  gravity: 0.5,
  spread: 360,
  origin: {
    x: 0.5,
    y: 0.3,
  },
  isEnabled: true,
};

const defaultSmallConfettiOptions = {
  particleCount: 20,
  startVelocity: 10,
  scalar: 0.7,
  decay: 0.85,
  angle: 200,
  ticks: 180,
  gravity: 0.4,
  spread: 270,

  disableForReducedMotion: true,
};

export const useConfettiColors = () => {
  const theme = useTheme();
  const colorMode = useColorModeValue("_light", "_dark");

  return useMemo(
    () =>
      confettiColors.map((semanticToken) => {
        const tokenColor = theme.semanticTokens.colors[semanticToken][colorMode];
        const [color, shade] = tokenColor.split(".");

        return theme.colors[color]?.[shade];
      }),
    [colorMode, theme]
  );
};

export const useConfettiOnMount = (confettiOptions: UseConfettiOptions = defaultConfettiOptions, deps?: number) => {
  const options = { ...defaultConfettiOptions, ...confettiOptions };

  const theme = useTheme();
  const colors = useConfettiColors();

  useEffect(() => {
    if (!options.isEnabled) {
      return;
    }
    const timeout = setTimeout(() => confetti({ zIndex: theme.zIndices.modal - 1, colors, ...options }), 1000);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deps, options.isEnabled]);
};

export const useThrowConfettiFromCenter = (options: Options = defaultConfettiOptions) => {
  const theme = useTheme();
  const colors = useConfettiColors();

  return useCallback(() => {
    const confettiOptions = { ...options, ...defaultConfettiOptions };
    confetti({ zIndex: theme.zIndices.modal - 1, colors, ...confettiOptions });
  }, [colors, options, theme.zIndices.modal]);
};

export const useThrowConfettiFromElement = (
  elementRef: RefObject<HTMLElement>,
  options: Options = defaultSmallConfettiOptions
) => {
  const theme = useTheme();
  const colors = useConfettiColors();

  return useCallback(() => {
    if (!elementRef.current) {
      return;
    }

    const rect = elementRef.current.getBoundingClientRect();
    const centerX = (rect.left + rect.width / 2) / window.innerWidth;
    const centerY = (rect.top + rect.height / 2) / window.innerHeight;

    confetti({ zIndex: theme.zIndices.overlay - 1, colors, ...options, origin: { x: centerX, y: centerY } });
  }, [colors, elementRef, options, theme.zIndices.overlay]);
};
