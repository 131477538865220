import { type Address } from "viem";
import { Divider, VStack } from "@chakra-ui/react";
import { ChainId } from "@looksrare/config";
import { useCurrencyLabel } from "../../../hooks";
import { Step, useEnterCaveStore } from "./state";
import { CurrentSubStep, StepDetails, StepRow, SubStep } from "./shared";

const TOTAL_STEPS = 2;

interface ApprovalStepProps {
  chainId: ChainId;
  currency: Address;
  onRetryTmStep: () => void;
  onRetryErc20Step: () => void;
}

export const ApprovalStep = ({ chainId, currency, onRetryTmStep, onRetryErc20Step }: ApprovalStepProps) => {
  const [transferManagerStep, erc20Step] = useEnterCaveStore((state) => [
    state.steps[Step.APPROVE_TRANSFER_MANAGER],
    state.steps[Step.APPROVE_ERC20],
  ]);
  const isCurrentStatus = [transferManagerStep.status, erc20Step.status].some((status) => status === "current");
  const currencyLabel = useCurrencyLabel(currency);

  const combinedStatus = (() => {
    if (isCurrentStatus) {
      return "current";
    }

    if (transferManagerStep.status === "past" && erc20Step.status === "past") {
      return "past";
    }
    return "future";
  })();

  return (
    <VStack spacing={4} alignItems="start" mb={4}>
      <StepRow stepStatus={combinedStatus}>Approvals ({TOTAL_STEPS})</StepRow>
      {isCurrentStatus && (
        <StepDetails>
          {transferManagerStep.status === "current" ? (
            <CurrentSubStep
              chainId={chainId}
              hasError={transferManagerStep.hasError}
              hashLinkText="Transfer Manager"
              totalSteps={TOTAL_STEPS}
              currentStep={1}
              hash={transferManagerStep.hash}
              onRetry={onRetryTmStep}
            />
          ) : (
            <SubStep stepStatus={transferManagerStep.status}>Transfer Manager</SubStep>
          )}
          <Divider borderColor="border-01" my={4} />
          {erc20Step.status === "current" ? (
            <CurrentSubStep
              chainId={chainId}
              hasError={erc20Step.hasError}
              hashLinkText={currencyLabel}
              totalSteps={TOTAL_STEPS}
              currentStep={2}
              hash={erc20Step.hash}
              onRetry={onRetryErc20Step}
            />
          ) : (
            <SubStep stepStatus={erc20Step.status}>{currencyLabel}</SubStep>
          )}
        </StepDetails>
      )}
    </VStack>
  );
};
