import { formatNumberToLocale } from "./formatNumberToLocale";

/**
 * Format a number to a compact representation
 * @param number - The number to format
 * @param locale - The locale to use for formatting
 * @param maximumSignificantDigits - The maximum number of significant digits to display
 * @param minimumSignificantDigits - The minimum number of significant digits to display
 * @param boundary - The boundary after which to format as a compact number
 * @returns The formatted number
 */

export const formatCompactNumber = (
  number: number,
  locale = "en",
  maximumSignificantDigits = 2,
  minimumSignificantDigits = 1,
  boundary = 0
): string => {
  if (number < boundary) {
    return formatNumberToLocale(number);
  }

  // Format locale to BCP 47 language tag
  const parsedLocale = locale.startsWith("zh") ? "zh-CN" : locale;
  return new Intl.NumberFormat(parsedLocale, {
    notation: "compact",
    compactDisplay: "short",
    minimumSignificantDigits,
    maximumSignificantDigits,
  }).format(number);
};
