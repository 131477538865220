import { Icon, IconProps } from "../Icon";

const JumpToEnd = (props: IconProps) => (
  <Icon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 16L9 6L7.59 7.41L16.17 16L7.59 24.59L9 26L19 16ZM25 4L25 28L23 28L23 4L25 4Z"
      fill="currentColor"
    />
  </Icon>
);

export default JumpToEnd;
