import { useCallback } from "react";
import {
  Box,
  useToast as useToastChakra,
  UseToastOptions,
  useMediaQuery,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";
import { Alert } from "../Alert";

export interface ExtendedUseToastOptions extends UseToastOptions {
  dataIdSuffix?: string;
  onClick?: () => void;
}

/**
 * A Toast will autoclose after 5s (chakra-ui default)
 */
// @TODO-migration figure out what to do with the stylesheet
export const useToast = () => {
  const chakraToast = useToastChakra();
  const [isMobile] = useMediaQuery("(max-width: 30rem)");

  return {
    toast: useCallback(
      ({
        status = "success",
        variant = "left-accent",
        title,
        description,
        dataIdSuffix,
        onClick,
        ...rest
      }: ExtendedUseToastOptions) =>
        chakraToast({
          isClosable: true,
          status,
          variant,
          position: isMobile ? "bottom" : "top-right",
          render: ({ onClose }) => {
            const dataId = dataIdSuffix ? `toast-${status}-${dataIdSuffix}` : `toast-${status}`;
            return (
              <Alert
                data-id={dataId}
                status={status}
                variant={variant}
                onClick={onClick}
                sx={
                  onClick
                    ? {
                        cursor: "pointer",
                      }
                    : {}
                }
              >
                <Box>
                  <AlertTitle>{title}</AlertTitle>
                  {description && <AlertDescription>{description}</AlertDescription>}
                </Box>
                <CloseButton
                  onClick={(event) => {
                    // Prevent the onClick from triggering
                    event.stopPropagation();
                    onClose();
                  }}
                  size="sm"
                  position="absolute"
                  right="8px"
                  top="8px"
                />
              </Alert>
            );
          },
          ...rest,
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isMobile]
    ),
    ...chakraToast,
  };
};
