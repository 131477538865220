import { useEffect } from "react";
import { useAccount } from "wagmi";
import { TopicName, getTopicName, isAddressEqual, useRealtimeSubscription } from "@looksrare/utils";
import { PtbRoundLogType, PtbRoundStatus } from "../types";
import { nonLoggableEvents } from "../config";
import { type CaveLog } from "../graphql";
import { getPtbContractNameFromNetwork } from "../utils";
import { useActiveCaveRoundInfo, useUpdateCache } from "./useActiveCaveRoundInfo";
import { useGetCaveQueryParams } from "./useGetCaveQueryParams";
import { useGameState, useSetCurrentGameStatus } from "./useGameState";

export const useGameManager = () => {
  const { address } = useAccount();
  const { caveOnChainId, roundOnChainId, network } = useGetCaveQueryParams();
  const setNextPlayerUp = useGameState((state) => state.setNextPlayerUp);
  const updateCache = useUpdateCache();
  const setGameStatus = useSetCurrentGameStatus();

  const { data: caveInfo } = useActiveCaveRoundInfo();
  const topicName = getTopicName({
    name: TopicName.ptbCaveLogs,
    caveOnChainId,
    contract: getPtbContractNameFromNetwork(network),
  });
  const roundStatus = caveInfo?.round?.status;

  // Keep websocket open only if the cave is active and we are on the current round
  const enabled = !!caveInfo?.cave.isActive;

  useRealtimeSubscription<CaveLog>(
    topicName,
    {
      onNewData: (data) => {
        const isViewedRound = data.round === roundOnChainId;

        if (isViewedRound) {
          if (!nonLoggableEvents.includes(data.type)) {
            updateCache.updateRoundLog(data);
          }
          switch (data.type) {
            case PtbRoundLogType.PLAYER_JOINED:
              updateCache.playerJoined(data.user[0]);
              break;
            case PtbRoundLogType.PLAYER_DIED:
              updateCache.playerDied(data.user[0], data.timestamp);
              setGameStatus("mauled");
              setNextPlayerUp(null);
              break;
            case PtbRoundLogType.PLAYER_SURVIVED:
              updateCache.playerSurvived(data.user[0]);
              setGameStatus("idle");
              break;
            case PtbRoundLogType.PLAYER_PASS:
              setNextPlayerUp(data.user[1]);
              break;
            case PtbRoundLogType.PLAYER_COUNTDOWN:
              setGameStatus(isAddressEqual(address, data.user[0]) ? "countdown" : "poking");
              break;
            case PtbRoundLogType.PLAYER_READY:
              updateCache.playerIsPoking(data.user[0], new Date(data.timestamp));
              setGameStatus("poking");
              break;
            case PtbRoundLogType.ROUND_DRAWING:
              setGameStatus("shuffling");
              break;
            case PtbRoundLogType.ROUND_DRAWN:
              updateCache.roundDrawn();
              break;
            case PtbRoundLogType.ROUND_REVEALED:
              updateCache.roundRevealed();
              break;
            case PtbRoundLogType.CAVE_RESET:
              setGameStatus("finished");
              break;
          }
        }
      },
      enabled,
    },
    [roundOnChainId]
  );

  // Sets the initial game state if we are in the middle of shuffling
  useEffect(() => {
    if (roundStatus === PtbRoundStatus.DRAWING) {
      setGameStatus("shuffling");
    }
  }, [roundStatus, setGameStatus]);

  // Game cleanup
  useEffect(() => {
    return () => {
      setGameStatus("idle");
      setNextPlayerUp(null);
    };
  }, [setGameStatus, setNextPlayerUp]);
};
