import { useRef } from "react";
import { zeroAddress } from "viem";
import { Box, DarkMode, type BoxProps } from "@chakra-ui/react";
import { formatToSignificant } from "@looksrare/utils";
import { type PtbGraphQlLog } from "../../graphql";
import { useActiveCaveRoundInfo, useCurrencyLabel, useGetRoundLoser, useRoundLogUserDisplay } from "../../hooks";
import { PtbRoundLogType } from "../../types";
import { getLogComponent } from "./shared";

interface CommentaryLineProps extends BoxProps {
  ptbGraphQlLog: PtbGraphQlLog;
}

export const CommentaryLine = ({ ptbGraphQlLog, ...props }: CommentaryLineProps) => {
  const { data } = useActiveCaveRoundInfo();
  const ref = useRef<HTMLDivElement>();

  const currencyLabel = useCurrencyLabel(data?.cave.currency || zeroAddress);
  const user1 = ptbGraphQlLog.user ? ptbGraphQlLog.user[0] : null;
  const user2 = ptbGraphQlLog.user ? ptbGraphQlLog.user[1] : null;

  // POT_SPLIT, ROUND_REVEALED needs the loser
  const ptbLoser = useGetRoundLoser();
  const user1Name = useRoundLogUserDisplay(
    [PtbRoundLogType.POT_SPLIT, PtbRoundLogType.ROUND_REVEALED].includes(ptbGraphQlLog.type)
      ? ptbLoser?.user.address
      : user1?.address
  );
  const user2Name = useRoundLogUserDisplay(user2?.address);

  const logComponent = getLogComponent(ptbGraphQlLog.type, ptbGraphQlLog.timestamp, {
    user1: user1Name,
    user2: user2Name,
    proceeds: `${formatToSignificant(data?.meta.prizePerPlayerNet || "0", 6)} ${currencyLabel}`,
  });

  return (
    <Box display="inline-block" ref={ref} width="fit-content" {...props}>
      <DarkMode>{logComponent}</DarkMode>
    </Box>
  );
};
