import React, { useState } from "react";
import { useTranslation } from "next-i18next";
import { Link, ModalFooterStack, TokenYoloIcon } from "@looksrare/uikit";
import { ModalBody, Checkbox, Stack } from "@chakra-ui/react";
import { Button, Text } from "@looksrare/uikit";

interface TermsOfServiceProps {
  handleNext: () => void;
}

export const TermsOfService = ({ handleNext }: TermsOfServiceProps) => {
  const { t } = useTranslation();
  const [isTosAgreed, setIsTosAgreed] = useState(false);

  return (
    <>
      <TokenYoloIcon boxSize={24} borderTopRadius="dialog" marginTop={12} mx="auto" />
      <ModalBody pb={4}>
        <Stack textAlign="center">
          <Text bold textStyle="display-body" pb={2}>
            {t("Welcome to YOLO Games!")}
          </Text>
          <Text textStyle="body" color="text-02" pb={2}>
            {t("By using this site, you agree to the")}{" "}
            <Link isExternal fontWeight="bold" href="https://docs.yologames.io/terms-of-service">
              {t("Terms of Service.")}
            </Link>
          </Text>
        </Stack>
        <Checkbox
          data-test-id="terms-of-service-agreement-checkbox"
          isChecked={isTosAgreed}
          onChange={() => setIsTosAgreed((prev) => !prev)}
          mt={6}
        >
          {t("I agree to the terms of service")}
        </Checkbox>
      </ModalBody>
      <ModalFooterStack>
        <Button
          data-test-id="welcome-to-looksrare-continue-button"
          width="100%"
          onClick={handleNext}
          isDisabled={!isTosAgreed}
        >
          {t("Let's go")}
        </Button>
      </ModalFooterStack>
    </>
  );
};
