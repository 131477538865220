import { AnimatedSwitcher } from "@looksrare/uikit";
import { useMoDStore } from "../../stores/modStore";
import { ChartSwitcherButton } from "./components/ChartSwitcherButton";

export const ChartSwitcher = () => {
  const { selectedChart, setSelectedChart } = useMoDStore();

  const selectedIndex = selectedChart === "oracle" ? 0 : 1;

  return (
    <AnimatedSwitcher
      selectedIndex={selectedIndex}
      selectorProps={{ backgroundColor: "ui-02", transition: "left 150ms, width 150ms" }}
    >
      <ChartSwitcherButton
        chartType="oracle"
        isSelected={selectedChart === "oracle"}
        onClick={() => setSelectedChart("oracle")}
      />
      <ChartSwitcherButton
        chartType="tradingview"
        isSelected={selectedChart === "tradingview"}
        onClick={() => setSelectedChart("tradingview")}
      />
    </AnimatedSwitcher>
  );
};
