import { Icon, IconProps } from "../Icon";

const YoloGamesFullLogo = ({ ...props }: IconProps) => (
  <Icon viewBox="0 0 128 64" {...props}>
    <path
      d="M110.249 0C120.029 0 127.949 7.93342 127.949 17.7307C127.949 27.4802 120.029 35.4137 110.249 35.4137C100.516 35.4137 92.5966 27.4802 92.5966 17.7307C92.5966 7.93342 100.516 0 110.249 0ZM110.249 25.473C114.543 25.473 117.739 21.9842 117.739 17.7307C117.739 13.4295 114.543 9.94067 110.249 9.94067C105.955 9.94067 102.759 13.4295 102.759 17.7307C102.759 21.9842 105.955 25.473 110.249 25.473Z"
      fill="#CCFD07"
    />
    <path d="M79.7388 24.2781H92.143V34.5055H69.052V1.0513H79.7388V24.2781Z" fill="#CCFD07" />
    <path
      d="M48.7951 0C58.5754 0 66.495 7.93342 66.495 17.7307C66.495 27.4802 58.5754 35.4137 48.7951 35.4137C39.0626 35.4137 31.1429 27.4802 31.1429 17.7307C31.1429 7.93342 39.0626 0 48.7951 0ZM48.7951 25.473C53.0889 25.473 56.2854 21.9842 56.2854 17.7307C56.2854 13.4295 53.0889 9.94067 48.7951 9.94067C44.5013 9.94067 41.3049 13.4295 41.3049 17.7307C41.3049 21.9842 44.5013 25.473 48.7951 25.473Z"
      fill="#CCFD07"
    />
    <path
      d="M21.6151 1.0513H33.0174L22.0445 34.4577H10.6421L14.7927 21.8885H6.49144L0.0507812 1.0513H11.8825L16.3671 16.9659L21.6151 1.0513Z"
      fill="#CCFD07"
    />
    <path
      d="M128 55.7289C128 59.9145 124.462 63.29 120.149 63.29H108.288V56.3027H120.149C120.654 56.3027 120.991 55.9989 120.991 55.7289C120.991 55.3576 120.856 54.9525 120.149 54.9525H115.431C111.017 54.9525 107.58 51.6108 107.58 47.3239C107.58 43.037 111.017 39.6952 115.431 39.6952H127.292V46.6825H115.431C115.128 46.6825 114.555 46.8176 114.555 47.3239C114.555 47.7289 115.027 47.9652 115.431 47.9652H120.149C124.529 47.9652 128 51.3745 128 55.6951V55.7289Z"
      fill="#F8FAFC"
    />
    <path
      d="M105.437 46.6825H92.4973V47.999H105.437V54.9863H92.4973V56.3027H105.437V63.29H85.1515V39.6952H105.437V46.6825Z"
      fill="#F8FAFC"
    />
    <path
      d="M57.6507 39.6952H64.7943L66.3444 63.29H59.5714L57.6507 39.6952ZM67.8607 39.6952H75.038L73.1173 63.29H66.3444L67.8607 39.6952ZM82.3838 63.29H75.038V39.6952H82.3838V63.29ZM57.6507 63.29H50.3049V39.6952H57.6507V63.29Z"
      fill="#F8FAFC"
    />
    <path
      d="M48.4237 63.29H40.2692L36.1583 39.6952L32.0473 63.29H23.8928L27.97 39.6952H44.3128L48.4237 63.29Z"
      fill="#F8FAFC"
    />
    <path
      d="M23.6548 49.6535V54.4804C23.6548 59.4762 18.7352 63.9994 12.5013 63.9994C5.62729 63.9994 0 58.3623 0 51.4762C0 44.5565 5.62729 38.9531 12.5013 38.9531C17.1177 38.9531 21.1276 41.451 23.2842 45.1978L17.1177 48.7758C16.2416 47.1218 14.5568 45.9742 12.5013 45.9742C9.46867 45.9742 7.21102 48.4383 7.21102 51.4762C7.21102 54.5142 9.46867 56.9783 12.5013 56.9783C14.0177 56.9783 15.3992 56.2695 16.3764 55.2568L12.7709 55.223V49.6535H23.6548Z"
      fill="#F8FAFC"
    />
  </Icon>
);

export default YoloGamesFullLogo;
